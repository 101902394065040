import React from 'react';

const EmptyPicture = ({ color = '#fff', width, height, x = '16', y = '16' }) => {
  return (
    <svg width={width || x} height={height || y} viewBox={`0 0 ${x} ${y}`}>
      <g xmlns="http://www.w3.org/2000/svg">
        <path
          stroke="null"
          id="svg_1"
          d="m13.96008,15.94677l-11.92016,0c-1.09865,0 -1.98669,-0.88607 -1.98669,-1.9847l0,-11.92215c0,-1.10064 0.88804,-1.98669 1.98669,-1.98669l11.92016,0c1.09864,0 1.98669,0.88605 1.98669,1.98669l0,11.92215c0,1.09864 -0.88805,1.9847 -1.98669,1.9847l0,0zm0,-13.41018c0,-0.27416 -0.22052,-0.49667 -0.49667,-0.49667l-10.92682,0c-0.27615,0 -0.49667,0.22251 -0.49667,0.49667l0,10.92682c0,0.27416 0.22052,0.49667 0.49667,0.49667l10.92682,0c0.27615,0.00199 0.49667,-0.22052 0.49667,-0.49469l0,-10.9288l0,0z"
          clipRule="evenodd"
          fillRule="evenodd"
          fill={color}
        />
        <path
          stroke="null"
          id="svg_10"
          d="m11.205739,12.274316l-6.411478,0c-0.590928,0 -1.068577,-0.476589 -1.068577,-1.067507l0,-6.412548c0,-0.591999 0.477649,-1.068577 1.068577,-1.068577l6.411478,0c0.590924,0 1.068577,0.476579 1.068577,1.068577l0,6.412548c0,0.590924 -0.477654,1.067507 -1.068577,1.067507l0,0zm0,-7.212911c0,-0.147462 -0.118611,-0.267144 -0.267143,-0.267144l-5.877191,0c-0.148532,0 -0.267144,0.119682 -0.267144,0.267144l0,5.877191c0,0.147462 0.118611,0.267143 0.267144,0.267143l5.877191,0c0.148531,0.001071 0.267143,-0.118611 0.267143,-0.266079l0,-5.878255l0,0z"
          clipRule="evenodd"
          fillRule="evenodd"
          fill={color}
        />
        <path
          stroke="null"
          id="svg_11"
          d="m11.561842,12.749119l-7.123683,0c-0.65657,0 -1.187278,-0.52953 -1.187278,-1.186089l0,-7.124872c0,-0.65776 0.530708,-1.187278 1.187278,-1.187278l7.123683,0c0.656564,0 1.187278,0.529518 1.187278,1.187278l0,7.124872c0,0.656565 -0.530713,1.186089 -1.187278,1.186089l0,0z"
          clipRule="evenodd"
          fillRule="evenodd"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default EmptyPicture;
