import React from 'react';

const PinToGrid = ({ color = '#fff', width, height, x = '16', y = '16' }) => {
  return (
    <svg width={width || x} height={height || y} viewBox={`0 0 ${x} ${y}`}>
      <path
        fill={color}
        stroke="null"
        id="bindGrid"
        d="M 14.35,0.50
           C 14.35,0.50 14.35,0.50 14.35,0.50
             14.35,0.50 10.89,0.50 10.89,0.50
             10.89,0.50 10.89,0.50 10.89,0.50
             10.89,0.50 9.74,0.50 9.74,0.50
             9.74,0.50 9.74,0.50 9.74,0.50
             9.74,0.50 6.26,0.50 6.26,0.50
             6.26,0.50 6.26,0.50 6.26,0.50
             6.26,0.50 5.11,0.50 5.11,0.50
             5.11,0.50 5.11,0.50 5.11,0.50
             5.11,0.50 1.65,0.50 1.65,0.50
             1.65,0.50 1.65,0.50 1.65,0.50
             1.02,0.50 0.50,1.02 0.50,1.65
             0.50,1.65 0.50,5.11 0.50,5.11
             0.50,5.11 0.50,5.11 0.50,5.11
             0.50,5.11 0.50,6.26 0.50,6.26
             0.50,6.26 0.50,6.26 0.50,6.26
             0.50,6.26 0.50,9.74 0.50,9.74
             0.50,9.74 0.50,9.74 0.50,9.74
             0.50,9.74 0.50,10.89 0.50,10.89
             0.50,10.89 0.50,10.89 0.50,10.89
             0.50,10.89 0.50,14.35 0.50,14.35
             0.50,14.98 1.02,15.50 1.65,15.50
             1.65,15.50 5.11,15.50 5.11,15.50
             5.11,15.50 6.26,15.50 6.26,15.50
             6.26,15.50 9.74,15.50 9.74,15.50
             9.74,15.50 10.89,15.50 10.89,15.50
             10.89,15.50 14.35,15.50 14.35,15.50
             14.98,15.50 15.50,14.98 15.50,14.35
             15.50,14.35 15.50,1.65 15.50,1.65
             15.50,1.02 14.98,0.50 14.35,0.50
             14.35,0.50 14.35,0.50 14.35,0.50 Z
           M 14.35,1.65
           C 14.35,1.65 14.35,2.81 14.35,2.81
             14.35,2.81 14.35,5.11 14.35,5.11
             14.35,5.11 10.89,5.11 10.89,5.11
             10.89,5.11 10.89,1.65 10.89,1.65
             10.89,1.65 14.35,1.65 14.35,1.65
             14.35,1.65 14.35,1.65 14.35,1.65 Z
           M 14.35,9.74
           C 14.35,9.74 10.89,9.74 10.89,9.74
             10.89,9.74 10.89,6.26 10.89,6.26
             10.89,6.26 14.35,6.26 14.35,6.26
             14.35,6.26 14.35,9.74 14.35,9.74 Z
           M 9.74,9.74
           C 9.74,9.74 6.26,9.74 6.26,9.74
             6.26,9.74 6.26,6.26 6.26,6.26
             6.26,6.26 9.74,6.26 9.74,6.26
             9.74,6.26 9.74,9.74 9.74,9.74 Z
           M 9.74,1.65
           C 9.74,1.65 9.74,5.11 9.74,5.11
             9.74,5.11 6.26,5.11 6.26,5.11
             6.26,5.11 6.26,1.65 6.26,1.65
             6.26,1.65 9.74,1.65 9.74,1.65
             9.74,1.65 9.74,1.65 9.74,1.65 Z
           M 1.65,6.26
           C 1.65,6.26 5.11,6.26 5.11,6.26
             5.11,6.26 5.11,9.74 5.11,9.74
             5.11,9.74 1.65,9.74 1.65,9.74
             1.65,9.74 1.65,6.26 1.65,6.26
             1.65,6.26 1.65,6.26 1.65,6.26 Z
           M 2.81,14.35
           C 2.81,14.35 1.65,14.35 1.65,14.35
             1.65,14.35 1.65,13.19 1.65,13.19
             1.65,13.19 1.65,10.89 1.65,10.89
             1.65,10.89 5.11,10.89 5.11,10.89
             5.11,10.89 5.11,14.35 5.11,14.35
             5.11,14.35 2.81,14.35 2.81,14.35
             2.81,14.35 2.81,14.35 2.81,14.35 Z
           M 6.26,14.35
           C 6.26,14.35 6.26,10.89 6.26,10.89
             6.26,10.89 9.74,10.89 9.74,10.89
             9.74,10.89 9.74,14.35 9.74,14.35
             9.74,14.35 6.26,14.35 6.26,14.35
             6.26,14.35 6.26,14.35 6.26,14.35 Z
           M 13.19,14.35
           C 13.19,14.35 10.89,14.35 10.89,14.35
             10.89,14.35 10.89,10.89 10.89,10.89
             10.89,10.89 14.35,10.89 14.35,10.89
             14.35,10.89 14.35,13.19 14.35,13.19
             14.35,13.19 14.35,14.35 14.35,14.35
             14.35,14.35 13.19,14.35 13.19,14.35
             13.19,14.35 13.19,14.35 13.19,14.35 Z"
      />
    </svg>
  );
};

export default PinToGrid;
