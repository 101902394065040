import React, { Component } from 'react';
import * as s from './styles';

export default class Menu extends Component {
  static defaultProps = {
    isActive: true
  };

  render() {
    const { isActive, css, isVertical, color, toolBarRef, ...restProps } = this.props;
    return (
      <s.MenuWrapper
        ref={toolBarRef ? toolBarRef : null}
        css={css || 'border-top: 2px solid lightgray;'}
        color={color}
        isActive={isActive}
        isVertical={isVertical}
        {...restProps}
      >
        {this.props.children}
      </s.MenuWrapper>
    );
  }
}
