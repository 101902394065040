import React from 'react';
import IsLocalHostController from './app/IsLocalHostController';
import DataLoader from 'loaders/app/DataLoader';
import DeviceDetailsLoader from 'containers/Widgets/DeviceDetails/DeviceDetailsLoader';
import ActiveProjectLoader from 'containers/Widgets/ProjectsWidget/ActiveProjectLoader';
import ScenarioDetailsLoader from 'containers/Widgets/ScenariosEditor/ScenarioDetailsLoader';
import ScenariosWidgetsLoader from './scenario/ScenariosWidgetsLoader';
import SoundNotificationController from './app/activeProject/SoundNotificationController';
import CountdownsController from './app/activeProject/CountdownsController';
import RedirectToActivePlanController from './app/activeProject/RedirectToActivePlanController';
import PriorityModeLoader from './app/activeProject/PriorityModeLoader';

const MainLoader = () => {
  return (
    <>
      <DataLoader />
      <ActiveProjectLoader />
      <DeviceDetailsLoader />
      <ScenarioDetailsLoader />
      <IsLocalHostController />
      <ScenariosWidgetsLoader />
      <SoundNotificationController />
      <CountdownsController />
      <RedirectToActivePlanController />
      <PriorityModeLoader />
    </>
  );
};

export default React.memo(MainLoader);
