import {
  USER_GROUPS_LOAD,
  UPDATED_ENTITIES,
  isLoaded
} from 'constants/actionTypes';

/* State: {
 *   <id>: { <user group> },
 *   ...
 * }
 */
export default function userGroups(state = null, action) {
  switch (action.type) {
    case isLoaded(USER_GROUPS_LOAD, true): {
      const userGroups = action.payload;
      let userGroupsAsMap = {};
      for (const userGroup of userGroups) {
        userGroupsAsMap[userGroup.id] = userGroup;
      }
      return userGroupsAsMap;
    }

    case UPDATED_ENTITIES: {
      const updatedUserGroups = action.payload.userGroups;
      let userGroupsAsMap = state;
      if (updatedUserGroups) {
        userGroupsAsMap = { ...state };
        const { created, updated, deletedIds } = updatedUserGroups;
        if (deletedIds) {
          for (const deletedId of deletedIds)
            delete userGroupsAsMap[deletedId];
        }
        if (updated) {
          for (const userGroup of updated)
            userGroupsAsMap[userGroup.id] = userGroup;
        }
        if (created) {
          for (const userGroup of created)
            userGroupsAsMap[userGroup.id] = userGroup;
        }
      }
      return userGroupsAsMap;
    }

    default:
      return state;
  }
}

