import { DEVICE_GET_PROFILE_VIEWS, isLoaded } from 'constants/actionTypes';

export default function deviceProfileViews(state = null, { type, payload }) {
  switch (type) {
    case isLoaded(DEVICE_GET_PROFILE_VIEWS, true): {
      const deviceProfileViews = payload;
      const newState = {};
      deviceProfileViews.forEach(deviceProfileView => {
        newState[deviceProfileView.id] = deviceProfileView;
      });
      return newState;
    }

    default:
      return state;
  }
}
