/**
 * Функция для проверки успешного выполнения операций
 * @param {boolean|null} state.inProgress[key]
 * @param {string|null} state.errors[key]
 *
 * см. reducers/inProgress.js
 */
export function checkCompletion(progress, error) {
  return progress === false && !error ? true : false;
}
