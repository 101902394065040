import { takeEvery } from 'redux-saga/effects';
import {
  MONITORABLE_VALUES_GET_PROFILES,
  MONITORABLE_VALUES_GET_DEVICE_HISTORY
} from 'constants/actionTypes';

import { fetch, dispatchSuccess, dispatchFail } from 'helpers/request';
import { objToQueryString } from 'helpers/objects';

function* getMonitorableValueProfiles(action) {
  try {
    const profiles = yield fetch('/monitorable_value_profiles');

    yield dispatchSuccess(MONITORABLE_VALUES_GET_PROFILES, profiles);
  } catch (error) {
    yield dispatchFail(MONITORABLE_VALUES_GET_PROFILES, error);
  }
}

function* getMonitorableValuesHistory(action) {
  const { projectId, deviceId, params } = action.payload;
  try {
    let filterParams = '';
    if (params) {
      filterParams = objToQueryString(params);
    }
    const response = yield fetch(
      `/projects/${projectId}/devices/${deviceId}/monitorable_values${filterParams}`
    );

    yield dispatchSuccess(MONITORABLE_VALUES_GET_DEVICE_HISTORY, { deviceId, history: response, params });
  } catch (error) {
    yield dispatchFail(MONITORABLE_VALUES_GET_DEVICE_HISTORY, error);
  }
}

export default function* monitorableValues() {
  yield takeEvery(MONITORABLE_VALUES_GET_PROFILES, getMonitorableValueProfiles);
  yield takeEvery(MONITORABLE_VALUES_GET_DEVICE_HISTORY, getMonitorableValuesHistory);
}
