import {
  STATE_CATEGORY_VIEWS_LOAD,
  isLoaded,
  STATES_LOAD,
  STATE_VIEWS_LOAD
} from 'constants/actionTypes';

export function stateCategoryViewsHash(state = {}, action) {
  switch (action.type) {
    case isLoaded(STATE_CATEGORY_VIEWS_LOAD, true): {
      const stateCategoryViews = action.payload;
      const stateCategoryViewsHash = {};
      stateCategoryViews.forEach(stateCategoryView => {
        stateCategoryViewsHash[stateCategoryView.id] = { ...stateCategoryView };
      });
      return stateCategoryViewsHash;
    }

    default:
      return state;
  }
}

export function statesHash(state = {}, action) {
  switch (action.type) {
    case isLoaded(STATES_LOAD, true): {
      const states = action.payload;
      const statesHash = {};
      states.forEach(deviceState => {
        statesHash[deviceState.id] = deviceState;
      });
      return statesHash;
    }

    default:
      return state;
  }
}

export function stateViewsHash(state = {}, action) {
  switch (action.type) {
    case isLoaded(STATE_VIEWS_LOAD, true): {
      const stateViews = action.payload;
      const stateViewsHash = {};
      stateViews.forEach(deviceStateView => {
        stateViewsHash[deviceStateView.id] = deviceStateView;
      });
      return stateViewsHash;
    }

    default:
      return state;
  }
}
