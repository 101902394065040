import { Component } from 'react';
import { connect } from 'react-redux';

import { SOCKET_CLIENT_START } from 'constants/actionTypes';
import Permissions from 'constants/permissions';

import { loadLicensePermissions } from 'actions/license';
import { getDeviceProfileViews, getDeviceProfilesDictionary } from 'actions/devices';
import { loadRegionConstants } from 'actions/regions';
import { loadIcons, loadSvgs, loadSounds } from 'actions/medias';
import { projectsLoad } from 'actions/projects';
import { getMonitorableValueProfiles } from 'actions/monitorableValues';
import { loadApplicationInfo } from 'actions/dictionaries';
import { loadDeviceShapeLibraries, loadDeviceShapes } from 'actions/deviceShapeLibrary';
import { loadPermissions } from 'actions/login';
import { loadStateCategoryViews, loadStates, loadStateViews } from 'actions/states';
import { loadLogViews } from 'actions/logViews';
import { loadSoundNotifications } from 'actions/soundNotifications';
import { loadDriverAttributes, loadDriverDescription } from 'actions/drivers';
import { loadScenarioConstants } from 'actions/scenarios';
import { getCurrentUser, hasRole } from 'helpers/user';

/**
 * Компонент отвечающий за подгрузку данных на старте приложения
 * (После процедуры авторизации, если включена безопасность)
 */

class DataLoader extends Component {
  componentDidMount = () => {
    const { dispatch, currentUser } = this.props;

    dispatch(loadDriverAttributes(currentUser.driverProfileId));
    dispatch(loadDriverDescription(currentUser.driverProfileId));
    dispatch(getDeviceProfileViews());
    if (hasRole(currentUser, Permissions.REGION_READ)) dispatch(loadRegionConstants());
    if (hasRole(currentUser, Permissions.SCENARIO_READ)) dispatch(loadScenarioConstants());
    dispatch(loadLicensePermissions());
    dispatch(getDeviceProfilesDictionary());
    dispatch(loadApplicationInfo());
    dispatch(loadPermissions());
    if (hasRole(currentUser, Permissions.LOG_VIEW_READ)) dispatch(loadLogViews());
    dispatch(loadSounds());
    dispatch(loadIcons());
    dispatch(loadSvgs());
    if (hasRole(currentUser, Permissions.SOUND_NOTIFICATION_READ))
      dispatch(loadSoundNotifications());
    if (hasRole(currentUser, Permissions.DEVICE_READ)) dispatch(getMonitorableValueProfiles());
    dispatch(loadStateCategoryViews());
    dispatch(loadStates());
    dispatch(loadStateViews());
    dispatch(loadDeviceShapeLibraries());
    dispatch(loadDeviceShapes());
    dispatch(projectsLoad());
    dispatch({ type: SOCKET_CLIENT_START });
  };

  shouldComponentUpdate = () => {
    return false;
  };

  render() {
    return null;
  }
}

const mapStateToProps = state => ({ currentUser: getCurrentUser(state) });

const mapDispatchToProps = dispatch => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(DataLoader);
