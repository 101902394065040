import { connect } from 'react-redux';
import { fabric } from 'fabric';
import { useEffect } from 'react';
import { addPlanText, updatePlanText } from 'actions/plans';
import { PlanObjects } from 'containers/Widgets/PlanEditor/PlanEditor';
import { getPreparedFromCanvasTextObject } from 'helpers/planEditor';
import { bindActionCreators } from 'redux';
import { modalClose, modalOpen } from 'actions/modals';
import TextEditorForm, { TEXT_EDITOR_FORM } from 'containers/Widgets/PlanEditor/TextEditorForm';
import React from 'react';
import { cloneButton } from 'helpers/planEditor';

const { Textbox } = fabric;
export const TEXT_BOX = 'textBox';
const MIN_TEXT_WIDTH = 1;

const TextController = props => {
  const {
    projectId,
    fabricCanvas,
    isActiveTool,
    currentPlan,
    textConfigValues,
    openTextEditorModal,
    dispatch,
    isSelectable
  } = props;

  const cloneObject = (eventData, transform) => {
    const target = transform.target;
    target.clone(cloned => {
      const textBox = target.info;
      textBox.x += 30;
      textBox.y += 30;
      dispatch(addPlanText(projectId, currentPlan.id, textBox));
    });
  };
  // Обработчик нажатия
  useEffect(() => {
    if (fabricCanvas != null) {
      const onClickHandler = e => mouseDownEvent(e, textConfigValues);
      if (isActiveTool) {
        fabricCanvas.on({
          'mouse:down': onClickHandler
        });
      }
      return () => {
        fabricCanvas.off({
          'mouse:down': onClickHandler
        });
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fabricCanvas, isActiveTool, textConfigValues]);

  // открытие модального окна
  useEffect(() => {
    if (fabricCanvas != null && openTextEditorModal) {
      if (fabricCanvas.getActiveObject()) {
        openTextBoxEditorForm();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openTextEditorModal, fabricCanvas]);

  const mouseDownEvent = (event, textConfigValues) => {
    if (fabricCanvas.getActiveObject()) {
      return;
    }
    const { x, y } = fabricCanvas.getPointer(event);
    if (x < 0 || y < 0 || x > currentPlan.xSize || y > currentPlan.ySize) return;
    const textBox = new Textbox('', {
      top: y,
      left: x,
      fontSize: textConfigValues.fontSize,
      textAlign: textConfigValues.textAlign,
      fontFamily: textConfigValues.fontFamily,
      fill: textConfigValues.color,
      fontStyle: textConfigValues.fontStyle,
      fontWeight: textConfigValues.fontWeight,
      underline: textConfigValues.underline,
      bringToFront: true,
      width: 50,
      text: 'text'
    });
    textBox.controls.clone = cloneButton(cloneObject);
    textBox.MIN_TEXT_WIDTH = MIN_TEXT_WIDTH;
    textBox.splitByGrapheme = true;
    const objectTextBox = { ...textBox.toObject(), x, y, color: textConfigValues.color };
    dispatch(addPlanText(projectId, currentPlan.id, objectTextBox));
  };

  const openTextBoxEditorForm = () => {
    props.openModal(TEXT_EDITOR_FORM);
  };

  const updateTextBoxProperties = textObj => {
    if (!textObj.textKey) return;
    if (textObj.color?.hex) textObj.color = textObj.color.hex;
    textObj.id = textObj.textKey;
    dispatch(updatePlanText(projectId, currentPlan.id, textObj));
    props.closeModal(TEXT_EDITOR_FORM);
  };

  useEffect(() => {
    if (!!fabricCanvas && !!currentPlan) {
      // textObjectCanvasCounter - количество объектов типа TextBox в канвасе
      let textObjectCanvasCounter = 0;
      fabricCanvas.getObjects().forEach(obj => {
        if (obj.info?.textKey) textObjectCanvasCounter++;
      });
      // сравниваем сколько текстбоксов на канвасе и сколько пришло с бека, если число одинаковое и
      // есть выделенные объекты, то не перерисовываем текстбоксы. Необходимо, чтобы избежать
      // дополнительную обработку координат при редактировании групп объектов канваса
      if (fabricCanvas.getActiveObject() && currentPlan.texts.length === textObjectCanvasCounter)
        return;
      fabricCanvas.getObjects().forEach(obj => {
        const textKey = obj.info?.textKey;
        if (textKey && !currentPlan.texts.some(text => text.id === textKey)) {
          fabricCanvas.remove(obj);
        }
      });
      currentPlan.texts.forEach(text => {
        const object = fabricCanvas.getObjects().find(el => el.info?.textKey === text.id);
        let { id, color, x, y, width, ...other } = text;
        if (object) {
          if (object.group) {
            x -= object.group.left + object.group.width / 2;
            y -= object.group.top + object.group.height / 2;
          }
          text.info = {
            ...other,
            textKey: id,
            x,
            y,
            width,
            color
          };
          object.set({
            ...other,
            left: x,
            width,
            top: y,
            fill: color,
            info: { ...text.info },
            selectable: isSelectable
          });
          object.controls.clone = cloneButton(cloneObject);
        } else {
          const selectable = isActiveTool ? true : isSelectable;
          const textBox = new Textbox(text.text, {
            ...other,
            left: x,
            top: y,
            width,
            fill: color,
            selectable,
            info: {
              ...other,
              textKey: id,
              width,
              x,
              y,
              color
            }
          });
          textBox.MIN_TEXT_WIDTH = MIN_TEXT_WIDTH;
          textBox.splitByGrapheme = true;
          textBox.setControlsVisibility({
            bl: false,
            br: false,
            mb: false,
            mt: false,
            tl: false,
            tr: false
          });
          textBox.set({ width });
          textBox.controls.clone = cloneButton(cloneObject);
          textBox.type = TEXT_BOX;
          fabricCanvas.add(textBox);
          fabricCanvas.moveTo(textBox, PlanObjects.TEXT.layer);
        }
      });
      fabricCanvas.renderAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlan, fabricCanvas]);

  return (
    <TextEditorForm
      modalName={TEXT_EDITOR_FORM}
      onSubmit={updateTextBoxProperties}
      initialValues={getPreparedFromCanvasTextObject(fabricCanvas?.getActiveObject())}
    ></TextEditorForm>
  );
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  openModal: bindActionCreators(modalOpen, dispatch),
  closeModal: bindActionCreators(modalClose, dispatch)
});

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(TextController);
