export const MODAL_NAMES = {
  /* General. */
  LOGIN_CONFIRM: 'loginConfirmationForm',

  /* Project devices tree. */
  IMPORT_CONTROL_DEVICE_CONFIG: 'importConfig',
  CREATE_DEVICE: 'createDevice',
  UPDATE_DEVICE_FW: 'updateFW',
  READ_EVENT_LOG: 'readEventLog',
  DEVICE_PASSWORD: 'devicePassword',
  DEVICE_LINKS: 'deviceLinks',
  CONFIGURE_DEVICE: 'configureDevice',
  COPY_DEVICES: 'copyDevices',

  /* Configure devices. */
  UPLOAD_DEVICE_DB: {
    ONE: 'uploadDB',
    ALL: 'uploadAllDB'
  },
  DEVICE_CONFIG_DIFF: 'projectEntitiesDiff',
  ADDITIONAL_CONFIG_FUNCTIONS: 'additionalConfigFunctionsForm',


  /* Plan editor. */
  PLAN: {
    ADD: 'addPlanForm',
    EDIT: 'editPlanForm'
  },
  PLAN_GROUP: {
    ADD: 'addPlanGroupForm',
    EDIT: 'editPlanGroupForm'
  },

  /* Scenario time line blocks. */
  SCENARIO_TL_BLOCK_ADD: {
    EXECUTIVE: 'addExecBlock',
    TRACING: 'addTraceBlock',
    COMPUTER_ACTION: 'addActionBlock'
  },
  SCENARIO_TL_BLOCK_EDIT: {
    EXECUTIVE: 'editExecBlock',
    TRACING: 'editTraceBlock',
    COMPUTER_ACTION: 'editActionBlock'
  },

  /* Virtual indicators. */
  INDICATOR_PANEL: {
    ADD: 'addIndicatorPanel',
    UPDATE: 'updateIndicatorPanel'
  },
  INDICATOR: {
    UPDATE: 'updateIndicator'
  },
  INDICATOR_GROUP: {
    ADD: 'addIndicatorGroup',
    UPDATE: 'updateIndicatorGroup'
  },
  /* Virtual objects */
  VIRTUAL_OBJECT:{
    PASTE: 'pasteVirtualObject'
  }
};
