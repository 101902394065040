import { createAction } from 'redux-actions';

import {
  DEVICES_LOAD_ACTIVE,
  DEVICES_LOAD,
  DEVICE_GET_PROFILE_VIEWS,
  DEVICE_GET_PROFILES_DICTIONARY,
  DEVICE_UPDATE_CURRENT,
  DEVICE_CREATE,
  DEVICES_COPY_AND_PASTE,
  DEVICES_MOVE,
  DEVICE_UPDATE_TYPE,
  DEVICE_DELETE,
  DEVICES_DELETE,
  ACTIVE_DEVICE_SET_CONFIG,
  DEVICES_RESET_STATES,
  DEVICE_UPLOAD_CONFIG,
  DEVICE_DOWNLOAD_CONFIG,
  DEVICE_UPLOAD_CONTROL_DATABASE,
  DEVICE_UPDATE_ADDRESS_PATH,
  DEVICE_UPDATE_PLAN_LAYOUTS,
  DEVICES_UPDATE_PLAN_LAYOUTS,
  DEVICES_CHANGE_REGION_BINDING,
  DEVICE_SET_POLLING_STATE,
  DEVICE_SET_TIME,
  DEVICE_UPLOAD_ALL_CONTROL_DATABASES,
  DEVICE_RESET,
  DEVICE_DRAGGABLE_UPDATE,
  DEVICE_SET_DISABLED,
  DEVICE_UPDATE_FW,
  DEVICE_READ_EVENTS,
  DEVICE_SET_CONTROL_PASSWORD,
  DEVICE_UPDATE_LINKS,
  DEVICE_ADD_NOTE,
  DEVICE_PERFORM_ACTION,
  DEVICE_EXPORT_CONTOL_CONFIG,
  DEVICE_IMPORT_CONTOL_CONFIG,
  DEVICE_SET_SHAPE_LIBRARY,
  DEVICE_UPDATE_SUBSYSTEM,
  DEVICE_UPDATE_SPECIFIC_SETTINGS,
  DEVICE_GET_FULL,
  DEVICES_SET_CONFIGS_AND_PROPERTIES,
  DEVICE_ADDITIONAL_CONFIG_FUNCTION
} from 'constants/actionTypes';

export const loadActiveDevices = createAction(DEVICES_LOAD_ACTIVE, projectId => ({ projectId }));

export const getFullDevice = createAction(DEVICE_GET_FULL, (projectId, deviceId) => ({
  projectId,
  deviceId
}));

export const loadDevices = createAction(DEVICES_LOAD, projectId => ({ projectId }));

export const getDeviceProfileViews = createAction(DEVICE_GET_PROFILE_VIEWS);

export const updateCurrentDevice = createAction(DEVICE_UPDATE_CURRENT, device => device);

export const setActiveDeviceConfig = createAction(
  ACTIVE_DEVICE_SET_CONFIG,
  (projectId, deviceId, deviceConfig, callback) => ({ projectId, deviceId, deviceConfig, callback })
);

export const createDevice = createAction(DEVICE_CREATE, (projectId, device) => ({
  projectId,
  device
}));

/**
 * Копирует указанные устройства с потомками
 * @param options - опции, состоят из:
 *  <br/> projectId {String} - идентификатор проекта
 *  <br/> parentId {String} - идентификатор родителя для вставляемых устройств
 *  <br/> newLineNo {Number} - новый номер адресной линии, в которую будут вставлены новые устройства
 *  <br/> newAddress {Number} - новый номер адреса на линии, по которому будут вставлены новые устройства
 *  <br/> deviceIds {Array} - список идентификаторов устройств, которые нужно скопировать
 *  <br/> callback {Function(result, error)} - функция, которая будет вызвана по завершении запроса (опционально)
 */
export const copyAndPasteDevices = createAction(DEVICES_COPY_AND_PASTE, options => options);

/**
 * Вставить свойства и конфиг устройства
 * @param projectId - индентификатор проекта
 * @param devicesAndProperties - объект сотстоящий из:
 * <br/> deviceIds{Array} - идентификаторы устройств
 * <br/> propertyValue{Map} - свойства устройства
 * <br/> configSettings{Map} - конфиг устройства
 */
export const setDevicesConfigsAndProperties = createAction(
  DEVICES_SET_CONFIGS_AND_PROPERTIES,
  (projectId, devicesAndProperties, callback, isSaveAction = false) => ({
    projectId,
    devicesAndProperties,
    callback,
    isSaveAction
  })
);
/**
 * Вырезает и вставляет указанные устройства с потомками
 * @param options смотри {@link copyAndPasteDevices}
 */
export const moveDevices = createAction(DEVICES_MOVE, options => options);

export const updateDeviceType = createAction(
  DEVICE_UPDATE_TYPE,
  (projectId, deviceId, newDeviceProfileId) => ({ projectId, deviceId, newDeviceProfileId })
);

export const updateDeviceAddressPath = createAction(
  DEVICE_UPDATE_ADDRESS_PATH,
  (projectId, deviceId, newDeviceAddressPath) => ({
    projectId,
    deviceId,
    newDeviceAddressPath
  })
);

export const updateDevicePlanLayouts = createAction(
  DEVICE_UPDATE_PLAN_LAYOUTS,
  (projectId, deviceId, planLayouts, callback) => ({ projectId, deviceId, planLayouts, callback })
);

export const updateDevicesPlanLayouts = createAction(
  DEVICES_UPDATE_PLAN_LAYOUTS,
  (updatedDevices, projectId) => ({
    updatedDevices,
    projectId
  })
);
export const changeDeviceBindingToRegion = createAction(
  DEVICES_CHANGE_REGION_BINDING,
  (updatedDevices, projectId) => ({
    updatedDevices,
    projectId
  })
);

export const deleteDevice = createAction(DEVICE_DELETE, (projectId, deviceId) => ({
  projectId,
  deviceId
}));

export const deleteDevices = createAction(DEVICES_DELETE, (projectId, deviceIds) => ({
  projectId,
  deviceIds
}));

export const resetDevicesStates = createAction(DEVICES_RESET_STATES, (projectId, resetAction) => ({
  projectId,
  resetAction
}));

export const uploadDeviceConfig = createAction(DEVICE_UPLOAD_CONFIG, (projectId, deviceId) => ({
  projectId,
  deviceId
}));

export const downloadDeviceConfig = createAction(DEVICE_DOWNLOAD_CONFIG, (projectId, deviceId) => ({
  projectId,
  deviceId
}));

export const uploadDeviceControlDatabase = createAction(
  DEVICE_UPLOAD_CONTROL_DATABASE,
  (projectId, deviceId, params) => ({ projectId, deviceId, params })
);

export const uploadAllDeviceControlDatabases = createAction(
  DEVICE_UPLOAD_ALL_CONTROL_DATABASES,
  (projectId, params) => ({ projectId, params })
);

export const setDevicePollingState = createAction(
  DEVICE_SET_POLLING_STATE,
  (projectId, deviceId, regionId, state) => ({
    projectId,
    deviceId,
    regionId,
    state
  })
);

export const setDeviceTime = createAction(DEVICE_SET_TIME, (projectId, deviceId) => ({
  projectId,
  deviceId
}));

export const resetDevice = createAction(DEVICE_RESET, (projectId, deviceId) => ({
  projectId,
  deviceId
}));

export const updateDeviceFw = createAction(
  DEVICE_UPDATE_FW,
  (projectId, deviceId, fwFile, updateByUSB, all, forcedWrite, needClearDatabase) => ({
    projectId,
    deviceId,
    fwFile,
    updateByUSB,
    all,
    forcedWrite,
    needClearDatabase
  })
);

export const readEvents = createAction(DEVICE_READ_EVENTS, (projectId, deviceId, logTypes) => ({
  projectId,
  deviceId,
  logTypes
}));

export const updateDraggableDevice = createAction(DEVICE_DRAGGABLE_UPDATE, deviceId => deviceId);

export const setDeviceDisabled = createAction(
  DEVICE_SET_DISABLED,
  (projectId, deviceId, disabled) => ({
    projectId,
    deviceId,
    disabled
  })
);

export const setDeviceControlPassword = createAction(
  DEVICE_SET_CONTROL_PASSWORD,
  (projectId, deviceId, username, password) => ({ projectId, deviceId, username, password })
);

export const updateDeviceLinks = createAction(
  DEVICE_UPDATE_LINKS,
  (projectId, deviceId, deviceLinks) => ({ projectId, deviceId, deviceLinks })
);

export const addDeviceNote = createAction(DEVICE_ADD_NOTE, (projectId, deviceId, messageText) => ({
  projectId,
  deviceId,
  messageText
}));

export const performDeviceAction = createAction(
  DEVICE_PERFORM_ACTION,
  (projectId, deviceId, actionId, actionParameters) => ({
    projectId,
    deviceId,
    actionId,
    actionParameters
  })
);

export const exportControlDeviceConfig = createAction(
  DEVICE_EXPORT_CONTOL_CONFIG,
  (projectId, deviceId) => ({ projectId, deviceId })
);

export const importControlDeviceConfig = createAction(
  DEVICE_IMPORT_CONTOL_CONFIG,
  (projectId, entitiesOfControlDevice, blob, importParam) => ({
    projectId,
    entitiesOfControlDevice,
    blob,
    importParam
  })
);

export const setDeviceShapeLibrary = createAction(
  DEVICE_SET_SHAPE_LIBRARY,
  (projectId, deviceId, deviceShapeLibraryId, callback) => ({
    projectId,
    deviceId,
    deviceShapeLibraryId,
    callback
  })
);

export const getDeviceProfilesDictionary = createAction(DEVICE_GET_PROFILES_DICTIONARY);

export const updateDeviceSpecificSettings = createAction(
  DEVICE_UPDATE_SPECIFIC_SETTINGS,
  (projectId, device, settingsByDeviceIds) => ({ projectId, device, settingsByDeviceIds })
);

export const updateDeviceSubsystem = createAction(
  DEVICE_UPDATE_SUBSYSTEM,
  (projectId, deviceId, subsystem) => ({ projectId, deviceId, subsystem })
);

export const deviceAdditionalConfigFunction = createAction(
  DEVICE_ADDITIONAL_CONFIG_FUNCTION,
  (projectId, deviceId, issueAction, paramName, value) => ({
    projectId,
    deviceId,
    issueAction,
    paramName,
    value
  })
);
