import {
  PERMISSIONS_LOAD,
  isLoaded
} from 'constants/actionTypes';

export default function permissions(state = null, action) {
  switch(action.type){
    case isLoaded(PERMISSIONS_LOAD, true): {
      return {...action.payload }
    }
    default:
      return state;
  }
}
