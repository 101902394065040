import React from 'react';

const ZoomIn = ({ color = "#FFF", x = "20", y = "20", isActive = true }) => {
  return (
    <svg width={x} height={y} viewBox={`0 0 ${x} ${y}`}>
      <path
        id="zoom_in"
        fill={color} 
        fillOpacity={isActive ? '1' : '.5'}
        fillRule="evenodd"
        d="M14.407 12.162l4.128 4.128a1.588 1.588 0 0 1-2.245 2.245l-4.128-4.128a.364.364 0 0 1-.033-.04.48.48 0 0 0-.025-.03 7.812 7.812 0 1 1 2.233-2.232l.03.024a.25.25 0 0 1 .04.033zm-7.189-8.59h1.087c.3 0 .543.244.543.544V6.47H11.2c.3 0 .544.243.544.543V8.1c0 .3-.243.543-.544.543H8.848v2.354c0 .3-.244.543-.543.543H7.218a.543.543 0 0 1-.543-.543V8.643H4.32a.544.544 0 0 1-.543-.543V7.013c0-.3.243-.543.543-.543h2.354V4.116c0-.3.243-.543.543-.543z"/>
    </svg>
  )
}

export default ZoomIn;