import {
  PROJECT_LOAD_ACTIVE,
  PROJECT_UPDATE_STATUS,
  PROJECT_LOAD_CURRENT,
  isLoaded
} from 'constants/actionTypes';
import { Status } from 'constants/project';

/**
 * state = virtualStates: {
 *    <virtualStateId>: {
 *      id: <string>,
 *      ...
 *    }
 * }
 */
export default function virtualCounters(state = null, { type, payload }) {
  switch (type) {
    case isLoaded(PROJECT_LOAD_ACTIVE, true): {
      const { virtualCounters } = payload;
      return getStatesByVirtualCounters(virtualCounters);
    }
    case isLoaded(PROJECT_UPDATE_STATUS, true): {
      const { project, virtualCounters } = payload;
      if (project.status === Status.ACTIVE) {
        return getStatesByVirtualCounters(virtualCounters);
      } else {
        return null;
      }
    }
    case isLoaded(PROJECT_LOAD_CURRENT, true): {
      const { project, virtualCounters } = payload;
      if (project.status === Status.ACTIVE) {
        return getStatesByVirtualCounters(virtualCounters);
      } else {
        return state;
      }
    }
    default: {
      return state;
    }
  }
}

function getStatesByVirtualCounters(virtualCounters) {
  const newState = {};
  virtualCounters.forEach(virtualCounter => {
    newState[virtualCounter.id] = virtualCounter;
  });
  return newState;
}
