import { ActiveCountdown } from 'backendToTSTypes/Countdown';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { decrementCountdownsDelay } from 'actions/countdowns';

function CountdownsController() {
  const countdowns = useSelector((state: any) => state.activeProject.countdowns) as Record<
    string,
    ActiveCountdown
  >;
  const dispatch = useDispatch();
  const isNeedToDecrementCountdowns = !!Object.keys(countdowns).length;
  useEffect(() => {
    let intervalId: NodeJS.Timer;
    if (isNeedToDecrementCountdowns) {
      let startTime = Date.now();
      intervalId = setInterval(() => {
        const currentTime = Date.now();
        dispatch(decrementCountdownsDelay(currentTime - startTime));
        startTime = currentTime;
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [isNeedToDecrementCountdowns, dispatch]);
  return null;
}

export default React.memo(CountdownsController);
