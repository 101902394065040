import { SET_CURRENT_NOTIFICATION } from 'constants/actionTypes';

export default function currentSoundNotification(state = '', { type, payload }) {
  switch (type) {
    case SET_CURRENT_NOTIFICATION: {
      let maxStateCategoryId;
      const { subsystems, stateCategoryViews } = payload;
      if (subsystems.length && Object.values(stateCategoryViews).length) {
        maxStateCategoryId = getMaxStateCategoryId(subsystems, stateCategoryViews);
      }
      return maxStateCategoryId ? maxStateCategoryId : state;
    }
    default:
      return state;
  }
}

function getMaxStateCategoryId(subsystems, stateCategoryViews) {
  let maxPriority;
  let maxStateCategoryId;
  subsystems.forEach(({ counters }) => {
    Object.keys(counters).forEach(stateCategoryId => {
      const stateCategoryView = stateCategoryViews[stateCategoryId];
      if (
        counters[stateCategoryId].value > 0 &&
        stateCategoryView &&
        (maxPriority === undefined || stateCategoryView.stateCategory.priority < maxPriority)
      ) {
        maxStateCategoryId = stateCategoryView.id;
        maxPriority = stateCategoryView.stateCategory.priority;
      }
    });
  });
  return maxStateCategoryId;
}
