import {
  PROJECT_LOAD_ACTIVE,
  PROJECT_UPDATE_STATUS,
  REGIONS_LOAD_ACTIVE,
  ACTIVE_REGION_UPDATED,
  ACTIVE_REGIONS_UPDATED,
  PROJECT_LOAD_CURRENT,
  isLoaded
} from 'constants/actionTypes';

export default function regions(state = null, action) {
  switch (action.type) {
    case isLoaded(REGIONS_LOAD_ACTIVE, true): {
      const regions = action.payload;
      const newState = {};
      regions.forEach(region => {
        newState[region.id] = region;
      });
      return newState;
    }

    case ACTIVE_REGION_UPDATED: {
      if (!state) return null;
      const updatedRegion = action.payload;
      if (!state[updatedRegion.id]) return state;
      const newState = { ...state };
      newState[updatedRegion.id] = updatedRegion;
      return newState;
    }

    case ACTIVE_REGIONS_UPDATED: {
      if (!state) return null;
      const updatedRegions = action.payload;
      if (updatedRegions.length === 0) return state;

      const regionsHash = { ...state };
      updatedRegions.forEach(updatedRegion => {
        if (regionsHash[updatedRegion.id]) regionsHash[updatedRegion.id] = updatedRegion;
      });
      return regionsHash;
    }

    case isLoaded(PROJECT_LOAD_ACTIVE, true): {
      const { regions } = action.payload;
      const newState = {};
      regions.forEach(region => {
        newState[region.id] = region;
      });
      return newState;
    }
    case isLoaded(PROJECT_LOAD_CURRENT, true): {
      const { project, regions } = action.payload;
      if (project.status === 'ACTIVE') {
        const newState = {};
        regions.forEach(region => {
          newState[region.id] = region;
        });
        return newState;
      } else {
        return state;
      }
    }
    case isLoaded(PROJECT_UPDATE_STATUS, true): {
      const { project, regions } = action.payload;
      if (project.status === 'ACTIVE') {
        const newState = {};
        regions.forEach(region => {
          newState[region.id] = region;
        });
        return newState;
      } else {
        return null;
      }
    }

    default:
      return state;
  }
}
