import { combineReducers } from 'redux';

import {
  PROJECT_LOAD_ACTIVE,
  PROJECT_UPDATE_STATUS,
  DEVICE_UPLOAD_CONFIG,
  DEVICE_DOWNLOAD_CONFIG,
  DEVICE_UPLOAD_CONTROL_DATABASE,
  SUBSYSTEM_STATUS_UPDATED,
  PROJECT_LOAD_CURRENT,
  MONITORABLE_VALUES_GET_DEVICE_HISTORY,
  PROJECT_ACTIVE_STATUS_UPDATED,
  MONITORABLE_VALUES_RESET_HISTORY,
  isLoaded
} from 'constants/actionTypes';

import textures from './activeProject/textures';
import logEvents from './activeProject/logEvents';
import devices from './activeProject/devices';
import regions from './activeProject/regions';
import countdowns from './activeProject/countdowns';
import project from './activeProject/project';
import issues from './activeProject/issues';
import scenarios from './activeProject/scenarios';
import archiveEvents from './activeProject/archiveEvents';
import indicators from './activeProject/indicators';
import virtualStates from './activeProject/virtualStates';
import plans, { selectedPlanId, findDeviceOnPlan } from './activeProject/plans';
import planGroups from './activeProject/planGroups';
import exportProjectComparisonData from './activeProject/exportProjectComparisonData';
import scenarioComputerAction from './activeProject/scenarioComputerAction';
import { SKUD_OBSERVER_GET, SKUD_OBSERVER_SET_AUTOUPDATE } from 'constants/actionTypeModules/skud';
import virtualCounters from './activeProject/virtualCounters';
import soundCondition from './activeProject/soundCondition';
import currentSoundNotification from './activeProject/currentSoundNotification';
import CPIU from './activeProject/CPIU';
import priorityMode from './activeProject/priorityMode';

function activeProject(state = null, action) {
  switch (action.type) {
    case isLoaded(PROJECT_LOAD_ACTIVE, true): {
      const { activeProject } = action.payload;
      return { ...activeProject };
    }
    case isLoaded(PROJECT_LOAD_CURRENT, true): {
      const { project } = action.payload;
      if (project.status === 'ACTIVE') {
        const { activeProject } = action.payload;
        return { ...activeProject };
      } else {
        return state;
      }
    }
    case isLoaded(PROJECT_UPDATE_STATUS, true): {
      const { project: updatedProject } = action.payload;
      if (updatedProject.status === 'ACTIVE') {
        const { activeProject } = action.payload;
        return { ...activeProject };
      }
      /*
       * Если пришел неактивный проект с идентифкатором текущего активного проекта,
       * то считаем, что больше нет активного проекта
       */
      if (state) {
        const project = state;
        if (project.id === updatedProject.id) {
          return null;
        }
      }

      return state;
    }

    case PROJECT_ACTIVE_STATUS_UPDATED: {
      if (!state) return state;
      const activeProject = action.payload;
      return { ...activeProject };
    }

    default:
      return state;
  }
}

function deviceIssueSuccess(state = null, action, actionType) {
  switch (action.type) {
    case isLoaded(actionType, true): {
      return true;
    }
    case isLoaded(actionType, false): {
      return false;
    }

    default:
      return state;
  }
}

function subsystems(state = [], action) {
  switch (action.type) {
    case isLoaded(PROJECT_LOAD_ACTIVE, true): {
      const { subsystems } = action.payload;
      return [...subsystems];
    }
    case isLoaded(PROJECT_LOAD_CURRENT, true): {
      const { project, subsystems } = action.payload;
      if (project.status === 'ACTIVE') {
        return [...subsystems];
      } else {
        return state;
      }
    }
    case isLoaded(PROJECT_UPDATE_STATUS, true): {
      const { project, subsystems } = action.payload;
      if (project.status === 'ACTIVE') {
        return [...subsystems];
      } else {
        return [];
      }
    }
    case SUBSYSTEM_STATUS_UPDATED: {
      const updatedSubsystem = action.payload;
      // Вернуть новый массив подсистем, с обновлением полученной подсистемы:
      return state.map(previousSubsystem => {
        return previousSubsystem.subsystem === updatedSubsystem.subsystem
          ? { ...updatedSubsystem }
          : previousSubsystem;
      });
    }

    default:
      return state;
  }
}

function monitorableValuesHistory(state = {}, action) {
  switch (action.type) {
    case isLoaded(MONITORABLE_VALUES_GET_DEVICE_HISTORY, true): {
      const { deviceId, history, params } = action.payload;
      const historyHash = {};
      historyHash[deviceId] = { ...history, params };
      return historyHash;
    }
    case MONITORABLE_VALUES_RESET_HISTORY: {
      return {};
    }
    default:
      return state;
  }
}

function skudObserver(state = null, { type, payload }) {
  switch (type) {
    case isLoaded(SKUD_OBSERVER_GET, true):
    case isLoaded(SKUD_OBSERVER_SET_AUTOUPDATE, true): {
      const skudObserver = payload;
      return skudObserver;
    }

    case isLoaded(PROJECT_UPDATE_STATUS, true): {
      const { project } = payload;
      if (project.status !== 'ACTIVE') return null;
      else return state;
    }

    default:
      return state;
  }
}

export default combineReducers({
  project,
  activeProject,
  devices,
  deviceUploadConfigSuccess: (state, action) =>
    deviceIssueSuccess(state, action, DEVICE_UPLOAD_CONFIG),
  deviceDownloadConfigSuccess: (state, action) =>
    deviceIssueSuccess(state, action, DEVICE_DOWNLOAD_CONFIG),
  deviceUploadControlDatabaseSuccess: (state, action) =>
    deviceIssueSuccess(state, action, DEVICE_UPLOAD_CONTROL_DATABASE),
  subsystems,
  logEvents,
  archiveEvents,
  regions,
  virtualStates,
  virtualCounters,
  plans,
  findDeviceOnPlan,
  currentPlanId: selectedPlanId,
  planGroups,
  monitorableValuesHistory,
  textures,
  issues,
  countdowns,
  scenarioComputerAction,
  scenarios,
  indicators,
  exportProjectComparisonData,
  skudObserver,
  soundCondition,
  currentSoundNotification,
  CPIU,
  priorityMode
});
