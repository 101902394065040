/**
 * Проекты.
 * Все REST-методы, на которые ссылаются ниже описанные action-ы, описаны в тех. проекте:
 * (https://docs.google.com/document/d/19fDadv_CBaeP-RlnnSvsjpdx7BCqSeax4MlCHjeEW28/edit#heading=h.bgq2qvxpr9r1).
 * Все входные параметры (payload), поступающие в sagas, совпадают с параметрами соответствующих REST-методов.
 * Все входные параметры (payload), поступающие в reducers, совпадают с ответом соответствующих REST-методов.
 */

export const PROJECTS_LOAD = 'PROJECTS_LOAD';
export const PROJECT_CREATE = 'PROJECT_CREATE';
export const PROJECT_UPDATE = 'PROJECT_UPDATE';
export const PROJECTS_UPDATE = 'PROJECTS_UPDATE';
export const PROJECT_DELETE = 'PROJECT_DELETE';
export const PROJECT_IMPORT = 'PROJECT_IMPORT';
export const PROJECT_EXPORT = 'PROJECT_EXPORT';
// Обновить проект, если был изменен его статус на другой вкладки
export const PROJECT_SYNC_STATUS = 'PROJECT_SYNC_STATUS';
// Изменить админ-статус проекта
export const PROJECT_UPDATE_STATUS = 'PROJECT_UPDATE_STATUS';
// Загрузить активный проект со всем его содержимым
export const PROJECT_LOAD_ACTIVE = 'PROJECT_LOAD_ACTIVE';
// Выбрать проект в навигаторе проектов
export const PROJECT_SELECT = 'PROJECT_SELECT';
// Выбрать проект для редактора проекта
export const PROJECT_SET_CURRENT = 'PROJECT_SET_CURRENT';
// Загрузить проект для редактора проектов со всем его содержимым
export const PROJECT_LOAD_CURRENT = 'PROJECT_LOAD_CURRENT';
// Уведомление об изменении опер-статуса активного проекта
export const PROJECT_ACTIVE_STATUS_UPDATED = 'PROJECT_ACTIVE_STATUS_UPDATED';
// Выполнить валидацию проекта
export const PROJECT_VALIDATE = 'PROJECT_VALIDATE';
// Загрузить сообщения валидации
export const PROJECT_VALIDATE_MESSAGE_LOAD = "PROJECT_VALIDATE_MESSAGE_LOAD";
// Удаление сообщения валидации
export const PROJECT_VALIDATE_DELETE = "PROJECT_VALIDATE_DELETE";
