import { createAction } from 'redux-actions';

import {
  MEDIAS_LOAD_ICONS,
  MEDIAS_LOAD_SVGS,
  MEDIAS_LOAD_SOUNDS,
  SOUND_SELECT,
  MEDIA_ADD,
  MEDIA_UPDATE,
  MEDIA_DELETE
} from 'constants/actionTypes';

export const loadIcons = createAction(MEDIAS_LOAD_ICONS);

export const loadSvgs = createAction(MEDIAS_LOAD_SVGS);

export const loadSounds = createAction(MEDIAS_LOAD_SOUNDS);

export const selectSound = createAction(SOUND_SELECT, sound => ({ sound }));

export const addMedia = createAction(MEDIA_ADD, newMedia => newMedia);
export const updateMedia = createAction(MEDIA_UPDATE, updatedMedia => updatedMedia);
export const deleteMedia = createAction(MEDIA_DELETE, mediaId => mediaId);
