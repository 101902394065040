import { takeEvery } from 'redux-saga/effects';
import message from 'components/message';

import {
  VIRTUAL_STATES_LOAD,
  VIRTUAL_STATE_CREATE,
  VIRTUAL_STATE_UPDATE,
  VIRTUAL_STATE_DELETE,
  VIRTUAL_STATES_DELETE,
  SCENARIOS_UPDATED,
  INDICATOR_PANEL_UPDATE,
  PROJECT_VALIDATE_DELETE,
  VIRTUAL_STATE_PASTE
} from 'constants/actionTypes';

import { fetch, request, dispatch, dispatchSuccess, dispatchFail } from 'helpers/request';
import i18next from 'i18next';

function* loadVirtualStates(action) {
  const projectId = action.payload;
  try {
    const virtualStates = yield fetch(`/projects/${projectId}/virtual_states`);

    yield dispatchSuccess(VIRTUAL_STATES_LOAD, {
      projectId: projectId,
      virtualStates: virtualStates
    });
  } catch (error) {
    yield dispatchFail(VIRTUAL_STATES_LOAD, error.message);
  }
}

function* createVirtualState(action) {
  const { projectId, params } = action.payload;
  try {
    const virtualStates = yield request(
      `/projects/${projectId}/virtual_states/multiple?count=${params.count || 1}`,
      'POST',
      params
    );

    yield dispatchSuccess(VIRTUAL_STATE_CREATE, {
      projectId: projectId,
      newVirtualStates: virtualStates
    });
    message('success', i18next.t('messages.virtualStateAdded'));
  } catch (error) {
    yield dispatchFail(VIRTUAL_STATE_CREATE, error.message);
  }
}

function* updateVirtualState(action) {
  const { projectId, virtualStateId, params } = action.payload;
  try {
    const virtualState = yield request(
      `/projects/${projectId}/virtual_states/${virtualStateId}`,
      'PUT',
      params
    );

    yield dispatchSuccess(VIRTUAL_STATE_UPDATE, {
      projectId: projectId,
      updatedVirtualStates: [virtualState]
    });
    message('success', i18next.t('messages.virtualStateParamsChange'));
  } catch (error) {
    yield dispatchFail(VIRTUAL_STATE_UPDATE, error.message);
  }
}

function* deleteVirtualState(action) {
  const { projectId, virtualStateIds: ids } = action.payload;
  try {
    const response = yield request(
      `/projects/${projectId}/virtual_states/`,
      'DELETE',
      ids
    );

    if (response.updatedScenarios && response.updatedScenarios.length) {
      yield dispatch(SCENARIOS_UPDATED, response.updatedScenarios);
    }
    if (response.updatedIndicatorPanels && response.updatedIndicatorPanels.length) {
      yield dispatchSuccess(INDICATOR_PANEL_UPDATE, {
        projectId,
        updatedIndicatorPanels: response.updatedIndicatorPanels
      });
    }
    if (response.removedValidateMessageIds && response.removedValidateMessageIds.length) {
      yield dispatchSuccess(PROJECT_VALIDATE_DELETE, {
        projectId,
        validateMessageIds: response.removedValidateMessageIds
      });
    }

    yield dispatchSuccess(VIRTUAL_STATES_DELETE, {
      projectId,
      virtualStateIds: response.removedVirtualStateIds
    });
    message('success', i18next.t('messages.virtualStateDeleted'));
  } catch (error) {
    yield dispatchFail(VIRTUAL_STATE_DELETE, error.message);
  }
}

function* pasteVirtualStates(action) {
  const { projectId, virtualStates, deviceId, count } = action.payload;
  try {
    const response = yield request(
      `/projects/${projectId}/virtual_states/paste?deviceId=${deviceId}&count=${count}`,
      'POST',
      virtualStates
    );

    yield dispatchSuccess(VIRTUAL_STATE_PASTE, {
      projectId: projectId,
      newVirtualStates: response
    });
    message('success', i18next.t('messages.pasted'));
  } catch (error) {
    yield dispatchFail(VIRTUAL_STATE_PASTE, error.message);
  }
}

export default function* virtualStatesSagas() {
  yield takeEvery(VIRTUAL_STATES_LOAD, loadVirtualStates);
  yield takeEvery(VIRTUAL_STATE_CREATE, createVirtualState);
  yield takeEvery(VIRTUAL_STATE_UPDATE, updateVirtualState);
  yield takeEvery(VIRTUAL_STATE_DELETE, deleteVirtualState);
  yield takeEvery(VIRTUAL_STATE_PASTE, pasteVirtualStates);
}
