import React from 'react';

import * as s from './styles';

export const Tab = props => (
  <s.Tab className={props.active ? 'active' : null} {...props}>
    { props.children }
  </s.Tab>
);

const Tabs = props => (
  <s.Tabs {...props}>
    { props.children }
  </s.Tabs>
);

export default Tabs;
