import {
    UPDATE_SELECTED_DEVICE_ID,
    INDICATOR_SELECTED_NUMBER
} from 'constants/actionTypes';

import { createAction } from 'redux-actions';

export const updateSelectedDeviceId = createAction(UPDATE_SELECTED_DEVICE_ID);

export const selectIndicatorByIndicatorsPanel = createAction(
    INDICATOR_SELECTED_NUMBER, indicatorNum => indicatorNum
);
