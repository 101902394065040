import styled, { css } from 'styled-components';

export const MenuWrapper = styled.div`
  display: flex;
  background-color: ${p => (p.isActive ? p => (p.color ? p.color : 'var(--panel-color)') : '#ccc')};
  transition: ease-out 0.2s;
  ${p =>
    p.isVertical
      ? css`
          justify-content: start;
          flex-direction: column;
          min-width: ${p.width ? p.width : '40px'};
          max-width: ${p.width ? p.width : '40px'};
          width: ${p.width ? p.width : '40px'};
          height: 100%;
        `
      : css`
          justify-content: space-between;
          align-items: center;
          min-height: ${p.height ? p.height : '35px'};
          max-height: ${p.height ? p.height : '35px'};
          height: ${p.height ? p.height : '35px'};
          width: 100%;
        `};
  &:hover {
    background-color: ${p => (p.isActive ? p.color ? p.color : 'var(--panel-color)': '')};
  }

  ${p => p.css}
`;
