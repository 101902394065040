import { combineReducers } from 'redux';
import {
  isLoaded,
  APPLICATION_INFO_LOAD,
  DEVICE_GET_PROFILES_DICTIONARY
} from 'constants/actionTypes';

function application(state = {}, action) {
  switch (action.type) {
    case isLoaded(APPLICATION_INFO_LOAD, true): {
      const appInfo = action.payload;
      return { ...state, ...appInfo };
    }

    default:
      return state;
  }
}

function deviceProfilesDictionary(state = {}, { type, payload }) {
  switch (type) {
    case isLoaded(DEVICE_GET_PROFILES_DICTIONARY, true): {
      return payload;
    }

    default: {
      return state;
    }
  }
}

export default combineReducers({
  application,
  deviceProfilesDictionary
});
