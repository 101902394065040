import { takeEvery } from 'redux-saga/effects';
import {
  USER_GROUPS_LOAD,
  USER_GROUP_CREATE,
  USER_GROUP_UPDATE,
  USER_GROUP_DELETE
} from 'constants/actionTypes';

import { fetch, request, dispatchSuccess, dispatchFail } from 'helpers/request';

function* loadUserGroups() {
  try {
    const userGroups = yield fetch('/user_groups/');

    yield dispatchSuccess(USER_GROUPS_LOAD, userGroups);
  } catch (error) {
    yield dispatchFail(USER_GROUPS_LOAD, error);
  }
}

function* createUserGroup(action) {
  const userGroup = action.payload;
  try {
    yield request(`/user_groups`, 'POST', userGroup);
  } catch (error) {
    yield dispatchFail(USER_GROUP_CREATE, error.message);
  }
}

function* updateUserGroup(action) {
  const { userGroupId, userGroup } = action.payload;
  try {
    yield request(`/user_groups/${userGroupId}`, 'PUT', userGroup);
  } catch (error) {
    yield dispatchFail(USER_GROUP_UPDATE, error.message);
  }
}

function* deleteUserGroup(action) {
  const userGroupId = action.payload;
  try {
    yield request(`/user_groups/${userGroupId}`, 'DELETE');
    yield dispatchSuccess(USER_GROUP_DELETE, userGroupId);
  } catch (error) {
    yield dispatchFail(USER_GROUP_DELETE, error.message);
  }
}

export default function* userGroups() {
  yield takeEvery(USER_GROUPS_LOAD, loadUserGroups);
  yield takeEvery(USER_GROUP_CREATE, createUserGroup);
  yield takeEvery(USER_GROUP_UPDATE, updateUserGroup);
  yield takeEvery(USER_GROUP_DELETE, deleteUserGroup);
}