import { createAction } from 'redux-actions';

import {
  LOG_VIEWS_LOAD,
  LOG_VIEW_SELECT,
  LOG_VIEW_ADD,
  LOG_VIEW_UPDATE,
  LOG_VIEW_DELETE
} from 'constants/actionTypes';

export const loadLogViews = createAction(LOG_VIEWS_LOAD);

export const selectLogView = createAction(LOG_VIEW_SELECT, logView => ({ logView }));

export const addLogView = createAction(LOG_VIEW_ADD, logView => logView);

export const updateLogView = createAction(LOG_VIEW_UPDATE, updatedlogView => updatedlogView);

export const deleteLogView = createAction(LOG_VIEW_DELETE, logViewId => logViewId);
