import {
  INDICATOR_SELECT,
  INDICATOR_UPDATE,
  INDICATOR_GROUP_ADD,
  INDICATOR_GROUP_SELECT,
  INDICATOR_GROUP_DELETE,
  INDICATOR_PANEL_ADD,
  INDICATOR_PANEL_DELETE,
  INDICATOR_PANEL_SELECT,
  INDICATOR_RESET_SELECTED,
  isLoaded, INDICATOR_PAGE_SELECT
} from 'constants/actionTypes';

export default function selectedIndicator(state = {}, { type, payload }) {
  switch (type) {
    case INDICATOR_SELECT: {
      const indicator = payload;
      return indicator;
    }
    case INDICATOR_PAGE_SELECT:
    case INDICATOR_PANEL_SELECT:
    case INDICATOR_GROUP_SELECT:
    case INDICATOR_RESET_SELECTED:
    case isLoaded(INDICATOR_UPDATE, true):
    case isLoaded(INDICATOR_PANEL_ADD, true):
    case isLoaded(INDICATOR_PANEL_DELETE, true):
    case isLoaded(INDICATOR_GROUP_ADD, true):
    case isLoaded(INDICATOR_GROUP_DELETE, true): {
      return {};
    }
    default: {
      return state;
    }
  }
}
