import { createAction } from 'redux-actions';
import {
  COUNTDOWNS_UPDATE_TIME,
  COUNTDOWNS_DELETE,
  COUNTDOWNS_DECREMENT_DELAY
} from 'constants/actionTypeModules/countdowns';

export const updateCountdownDelay = createAction(COUNTDOWNS_UPDATE_TIME, (countdown, newTime) => ({
  countdown,
  newTime
}));

export const deleteCountdown = createAction(COUNTDOWNS_DELETE, id => ({
  id
}));

export const decrementCountdownsDelay = createAction(
  COUNTDOWNS_DECREMENT_DELAY,
  startTime => startTime
);
