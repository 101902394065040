import {
  USERS_LOAD,
  UPDATED_ENTITIES,
  isLoaded
} from 'constants/actionTypes';

/* State: {
 *   <id>: { <user> },
 *   ...
 * }
 */
export default function users(state = null, action) {
  switch (action.type) {
    case isLoaded(USERS_LOAD, true): {
      const users = action.payload;
      let usersAsMap = {};
      for (const user of users) {
        usersAsMap[user.id] = user;
      }
      return usersAsMap;
    }

    case UPDATED_ENTITIES: {
      const updatedUsers = action.payload.users;
      let usersAsMap = state;
      if (updatedUsers) {
        usersAsMap = { ...state };
        for (const updatedUser of updatedUsers) {
          usersAsMap[updatedUser.id] = updatedUser;
        }
      }
      return usersAsMap;
    }

    default:
      return state;
  }
}

