/**
 * Действия с медиа-контентом
 * REST: api/v1/medias
 * store: state.medias
 */

// Добавление медиа
export const MEDIA_ADD = 'MEDIA_ADD';
// Обновление медиа
export const MEDIA_UPDATE = 'MEDIA_UPDATE';
// Удаление медиа
export const MEDIA_DELETE = 'MEDIA_DELETE';

/** Загрузка списка иконок */
export const MEDIAS_LOAD_ICONS = 'MEDIAS_LOAD_ICONS';
/** Загрузка SVG-media */
export const MEDIAS_LOAD_SVGS = 'MEDIAS_LOAD_SVGS';
/** Загрузка звуков */
export const MEDIAS_LOAD_SOUNDS = 'MEDIAS_LOAD_SOUNDS';

// Выбор звука
export const SOUND_SELECT = 'SOUND_SELECT';
