import React from 'react';
import PropTypes from 'prop-types';
import * as icons from './icons';

export default class Icon extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string
  };

  render() {
    let { name } = this.props;

    //React компоненты пишутся с большой буквы:
    name = name.charAt(0).toUpperCase() + name.slice(1);

    //Проверяем существует ли иконка:
    const FoundIcon = icons[name] ? icons[name] : icons['Default'];

    return <FoundIcon {...this.props} />;
  }
}
