import React from 'react';

const Servers = ({ color = '#5FA2DD', x = '16', y = '16' }) => {
  return (
    <svg width={x} height={y} viewBox={`0 0 ${x} ${y}`}>
      <g stroke="null" id="svg_1">
        <path
          stroke="null"
          fill={color}
          id="svg_2"
          d="m15.14886,6.41136c0.47659,0 0.79432,-0.31773 0.79432,-0.79432l0,-4.76591c0,-0.47659 -0.31773,-0.79432 -0.79432,-0.79432l-14.29772,0c-0.47659,0 -0.79432,0.31773 -0.79432,0.79432l0,4.76591c0,0.47659 0.31773,0.79432 0.79432,0.79432l1.58864,0l0,0.79432l-1.58864,0c-0.47659,0 -0.79432,0.31773 -0.79432,0.79432l0,4.76591c0,0.47659 0.31773,0.79432 0.79432,0.79432l6.35454,0l0,0.79432l-3.17727,0c-0.47659,0 -0.79432,0.31773 -0.79432,0.79432s0.31773,0.79432 0.79432,0.79432l7.94318,0c0.47659,0 0.79432,-0.31773 0.79432,-0.79432s-0.31773,-0.79432 -0.79432,-0.79432l-3.17727,0l0,-0.79432l6.35454,0c0.47659,0 0.79432,-0.31773 0.79432,-0.79432l0,-4.76591c0,-0.47659 -0.31773,-0.79432 -0.79432,-0.79432l-1.58864,0l0,-0.79432l1.58864,0zm-0.79432,2.38295l0,3.17727l-12.70909,0l0,-3.17727l12.70909,0zm-10.32613,-1.58864l0,-0.79432l7.94318,0l0,0.79432l-7.94318,0zm-2.38295,-2.38295l0,-3.17727l12.70909,0l0,3.17727l-12.70909,0z"
        />
        <path
          stroke="null"
          fill={color}
          id="svg_3"
          d="m4.82273,2.43978l-0.79432,0c-0.47659,0 -0.79432,0.31773 -0.79432,0.79432s0.31773,0.79432 0.79432,0.79432l0.79432,0c0.47659,0 0.79432,-0.31773 0.79432,-0.79432s-0.31773,-0.79432 -0.79432,-0.79432z"
        />
        <path
          stroke="null"
          fill={color}
          id="svg_4"
          d="m4.02841,11.17727l0.79432,0c0.47659,0 0.79432,-0.31773 0.79432,-0.79432s-0.31773,-0.79432 -0.79432,-0.79432l-0.79432,0c-0.47659,0 -0.79432,0.31773 -0.79432,0.79432s0.31773,0.79432 0.79432,0.79432z"
        />
      </g>
    </svg>
  );
};

export default Servers;
