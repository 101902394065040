import Unit from 'constants/unit.js';

/**
 * Преобразует [unitFrom] единицы измерения в [unitTo] и возвращает преобразованное значение
 */
export function convertUnit(unitFrom, unitTo, value) {
  if (unitFrom === Unit.MILLIMETERS && unitTo === Unit.PIXELS) {
    return value * 3.7795280352161;
  } else if (unitFrom === Unit.CENTIMETERS && unitTo === Unit.PIXELS) {
    return value * (3.7795280352161 * 1000);
  } else if (unitFrom === Unit.INCHES && unitTo === Unit.PIXELS) {
    return value * 96.00001209449;
  } else if (unitFrom === Unit.POINTS && unitTo === Unit.PIXELS) {
    return value * 1.3333343412075;
  }
  return value;
}
