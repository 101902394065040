import React from 'react';
import { UserTimeWrapper, UserTimeElement } from './styles';
import { getDateTimestamp } from 'helpers/time';
import { loadServerTime } from '../../actions/systemInfo';
import { connect } from 'react-redux';

class UserTime extends React.Component {
  state = {
    currentTimeMs: 0,
    currentTimeStamp: ''
  };

  updateTime() {
    let { currentTimeMs } = this.state;
    if (!currentTimeMs) return;
    currentTimeMs += 1000;
    this.setState({ currentTimeMs, currentTimeStamp: getDateTimestamp(currentTimeMs) });
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(loadServerTime());
    this.timeLoaderIntervalId = setInterval(() => dispatch(loadServerTime()), 5000);
    this.interval = setInterval(() => this.updateTime(), 1000);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { serverTimeMs } = this.props;
    if (prevProps.serverTimeMs !== serverTimeMs) {
      this.setState({
        currentTimeMs: serverTimeMs
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.timeLoaderIntervalId);
  }

  render() {
    const { currentTimeStamp } = this.state;
    return (
      <UserTimeWrapper>
        {currentTimeStamp ? <UserTimeElement>{currentTimeStamp}</UserTimeElement> : null}
      </UserTimeWrapper>
    );
  }
}

const mapDispatchToProps = dispatch => ({ dispatch });

const mapStateToProps = state => {
  return {
    serverTimeMs: state.systemInfo.serverTime
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTime);
