import { takeEvery } from 'redux-saga/effects';
import {
  USERS_LOAD,
  USER_CREATE,
  USER_UPDATE,
  CURRENT_USER_UPDATE,
  USER_DELETE
} from 'constants/actionTypes';

import { fetch, request, dispatchSuccess, dispatchFail } from 'helpers/request';

function* loadUsers() {
  try {
    const users = yield fetch('/users/');

    yield dispatchSuccess(USERS_LOAD, users);
  } catch (error) {
    yield dispatchFail(USERS_LOAD, error);
  }
}

function* createUser(action) {
  const user = action.payload;
  try {
    const response = yield request(`/users`, 'POST', user);
    yield dispatchSuccess(USER_CREATE, response);
  } catch (error) {
    yield dispatchFail(USER_CREATE, error.message);
  }
}

function* updateUser(action) {
  const { userId, user } = action.payload;
  try {
    const response = yield request(`/users/${userId}`, 'PUT', user);
    yield dispatchSuccess(USER_UPDATE, response);
  } catch (error) {
    yield dispatchFail(USER_UPDATE, error.message);
  }
}

function* updateCurrentUser(action) {
  const user = action.payload;
  try {
    const response = yield request(`/users/currentUser`, 'PUT', user);
    yield dispatchSuccess(CURRENT_USER_UPDATE, response);
  } catch (error) {
    yield dispatchFail(CURRENT_USER_UPDATE, error.message);
  }
}

function* deleteUser(action) {
  const { userId, active } = action.payload;
  try {
    const response = yield request(`/users/${userId}?active=${active}`, 'PUT');
    yield dispatchSuccess(USER_DELETE, response);
  } catch (error) {
    yield dispatchFail(USER_DELETE, error.message);
  }
}

export default function* users() {
  yield takeEvery(USERS_LOAD, loadUsers);
  yield takeEvery(USER_CREATE, createUser);
  yield takeEvery(USER_UPDATE, updateUser);
  yield takeEvery(CURRENT_USER_UPDATE, updateCurrentUser);
  yield takeEvery(USER_DELETE, deleteUser);
}
