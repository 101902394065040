import React, { Component } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import styles from './styles.module.css';
import i18next from 'i18next';

export default class Manual extends Component {
  render() {
    return (
      <div
        onClick={() => {
          window.open('/public/file/manual.pdf');
        }}
        className={styles.manual}
        title={i18next.t('manual')}
      >
        <QuestionCircleOutlined className={styles.question_circle_outlined} />
      </div>
    );
  }
}
