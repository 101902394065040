import {
  isLoaded,
  LOGIN_CHECK_CONFIRMATION,
  LOGIN_UPDATE_CONFIRMATION,
  LOGOUT_REQUEST
} from 'constants/actionTypes';

export default function lastLoginConfirmation(state = null, { type, payload }) {
  switch (type) {
    case LOGIN_UPDATE_CONFIRMATION:
    case isLoaded(LOGIN_CHECK_CONFIRMATION, true): {
      if (payload) return 0;
      return new Date().getTime();
    }
    case isLoaded(LOGIN_CHECK_CONFIRMATION):
    case isLoaded(LOGOUT_REQUEST, true): {
      return null;
    }
    default: {
      return state;
    }
  }
}
