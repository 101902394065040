import { combineReducers } from 'redux';

import { EVENTS_SET_ARCHIVE_FILTERS, PROJECT_UPDATE_STATUS } from 'constants/actionTypes';

function filters(state = [], action) {
  switch (action.type) {
    case EVENTS_SET_ARCHIVE_FILTERS: {
      return [...action.payload];
    }
    case PROJECT_UPDATE_STATUS: {
      return [];
    }
    default:
      return state;
  }
}

export default combineReducers({
  filters
});
