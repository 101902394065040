import { takeEvery } from 'redux-saga/effects';

import {
  INDICATOR_GROUP_ADD,
  INDICATOR_GROUP_DELETE,
  INDICATOR_GROUP_UPDATE,
  INDICATOR_PANEL_ADD,
  INDICATOR_PANEL_UPDATE,
  INDICATOR_PANEL_DELETE,
  INDICATOR_UPDATE,
  INDICATOR_CHANGE_DEVICES_POLLING_STATE,
  INDICATOR_CHANGE_REGIONS_POLLING_STATE,
  INDICATOR_CHANGE_REGIONS_GUARD,
  INDICATOR_PERFORM_SCENARIO_ACTION,
  INDICATOR_PERFORM_DEVICE_ACTION,
  SET_LAST_ENTERED_PASSWORD_DATA
} from 'constants/actionTypes';

import { request, dispatchSuccess, dispatchFail, dispatch } from 'helpers/request';

function* addIndicatorGroup({ type, payload }) {
  const { projectId, newIndicatorGroup } = payload;
  try {
    const response = yield request(
      `/projects/${projectId}/indicator_groups`,
      'POST',
      newIndicatorGroup
    );
    yield dispatchSuccess(type, { projectId, newIndicatorGroup: response });
  } catch (err) {
    yield dispatchFail(type, err.message);
  }
}

function* deleteIndicatorGroup({ type, payload }) {
  const { projectId, indicatorGroupId, nextIndicatorGroupId } = payload;
  try {
    yield request(`/projects/${projectId}/indicator_groups/${indicatorGroupId}`, 'DELETE');
    yield dispatchSuccess(type, {
      projectId,
      deletedIndicatorGroupId: indicatorGroupId,
      nextIndicatorGroupId
    });
  } catch (err) {
    yield dispatchFail(type, err.message);
  }
}

function* updateIndicatorGroup({ type, payload }) {
  const { projectId, indicatorGroupId, updatedIndicatorGroup } = payload;
  try {
    const response = yield request(
      `/projects/${projectId}/indicator_groups/${indicatorGroupId}`,
      'PUT',
      updatedIndicatorGroup
    );
    yield dispatchSuccess(type, { projectId, updatedIndicatorGroup: response });
  } catch (err) {
    yield dispatchFail(type, err.message);
  }
}

function* addIndicatorPanel({ type, payload }) {
  const { projectId, newIndicatorPanel } = payload;
  try {
    const response = yield request(
      `/projects/${projectId}/indicator_panels`,
      'POST',
      newIndicatorPanel
    );
    yield dispatchSuccess(type, { projectId, newIndicatorPanel: response });
  } catch (err) {
    yield dispatchFail(type, err.message);
  }
}

function* updateIndicatorPanel({ type, payload }) {
  const { projectId, updatedIndicatorPanel } = payload;
  try {
    const response = yield request(
      `/projects/${projectId}/indicator_panels/${updatedIndicatorPanel.id}`,
      'PUT',
      updatedIndicatorPanel
    );
    yield dispatchSuccess(type, { projectId, updatedIndicatorPanels: [response] });
  } catch (err) {
    yield dispatchFail(type, err.message);
  }
}

function* updateIndicator({ type, payload }) {
  const { projectId, indicatorPanelId, updatedIndicator } = payload;
  try {
    const response = yield request(
      `/projects/${projectId}/indicator_panels/${indicatorPanelId}?row=${updatedIndicator.rowNo}&col=${updatedIndicator.colNo}`,
      'PUT',
      updatedIndicator
    );
    yield dispatchSuccess(type, { projectId, updatedIndicatorPanels: [response] });
  } catch (err) {
    yield dispatchFail(type, err.message);
  }
}

function* deleteIndicatorPanel({ type, payload }) {
  const { projectId, indicatorPanelId } = payload;
  try {
    yield request(`/projects/${projectId}/indicator_panels/${indicatorPanelId}`, 'DELETE');
    yield dispatchSuccess(type, { projectId, deletedIndicatorPanelId: indicatorPanelId });
  } catch (err) {
    yield dispatchFail(type, err.message);
  }
}

function* changeIndicatorDevicesPollingState({ type, payload }) {
  const { projectId, indicatorPanelId, indicator, state, password } = payload;
  const { colNo, rowNo, entityType, entityIds } = indicator;
  const issue = {
    action: `${state ? 'ENABLE' : 'DISABLE'}_INDICATOR_DEVICES_POLLING_STATE`,
    projectId,
    parameters: {
      indicatorPanelId,
      colNo,
      rowNo,
      entityType,
      entityIds
    }
  };
  try {
    const passwordQueryString = password ? `?password=${password}` : '';
    const response = yield request(`/issues${passwordQueryString}`, 'POST', issue);
    yield dispatchSuccess(type, response);
    yield password && dispatch(SET_LAST_ENTERED_PASSWORD_DATA, { password, time: Date.now() });
  } catch (err) {
    yield dispatchFail(type, err.message);
  }
}

function* changeIndicatorRegionsPollingState({ type, payload }) {
  const { projectId, indicatorPanelId, indicator, state, password } = payload;
  const { colNo, rowNo, entityType, entityIds } = indicator;
  const issue = {
    action: `${state ? 'ENABLE' : 'DISABLE'}_INDICATOR_REGIONS_POLLING_STATE`,
    projectId,
    parameters: {
      indicatorPanelId,
      colNo,
      rowNo,
      entityType,
      entityIds
    }
  };
  try {
    const passwordQueryString = password ? `?password=${password}` : '';
    const response = yield request(`/issues${passwordQueryString}`, 'POST', issue);
    yield dispatchSuccess(type, response);
    yield password && dispatch(SET_LAST_ENTERED_PASSWORD_DATA, { password, time: Date.now() });
  } catch (err) {
    yield dispatchFail(type, err.message);
  }
}

function* changeIndicatorRegionsGuard({ type, payload }) {
  const { projectId, indicatorPanelId, indicator, state, password } = payload;
  const { colNo, rowNo, entityType, entityIds } = indicator;
  const issue = {
    action: `${state ? 'ENABLE' : 'DISABLE'}_INDICATOR_REGIONS_GUARD`,
    projectId,
    parameters: {
      indicatorPanelId,
      colNo,
      rowNo,
      entityType,
      entityIds
    }
  };
  try {
    const passwordQueryString = password ? `?password=${password}` : '';
    const response = yield request(`/issues${passwordQueryString}`, 'POST', issue);
    yield dispatchSuccess(type, response);
    yield password && dispatch(SET_LAST_ENTERED_PASSWORD_DATA, { password, time: Date.now() });
  } catch (err) {
    yield dispatchFail(type, err.message);
  }
}

function* performIndicatorScenarioAction({ type, payload }) {
  const { projectId, indicatorPanelId, indicator, actionId, password } = payload;
  const { colNo, rowNo, entityType, entityIds } = indicator;
  const issue = {
    action: 'PERFORM_INDICATOR_SCENARIO_ACTION',
    projectId,
    parameters: {
      indicatorPanelId,
      colNo,
      rowNo,
      entityType,
      entityIds,
      actionId
    }
  };
  try {
    const passwordQueryString = password ? `?password=${password}` : '';
    const response = yield request(`/issues${passwordQueryString}`, 'POST', issue);
    yield dispatchSuccess(type, response);
    yield password && dispatch(SET_LAST_ENTERED_PASSWORD_DATA, { password, time: Date.now() });
  } catch (err) {
    yield dispatchFail(type, err.message);
  }
}

function* performIndicatorDeviceAction({ type, payload }) {
  const { projectId, indicatorPanelId, indicator, actionId, actionClass, password } = payload;
  const { colNo, rowNo, entityType, entityIds } = indicator;
  const issue = {
    action: 'PERFORM_INDICATOR_DEVICE_ACTION',
    projectId,
    parameters: {
      indicatorPanelId,
      colNo,
      rowNo,
      entityType,
      entityIds,
      actionId,
      actionClass
    }
  };
  try {
    const passwordQueryString = password ? `?password=${password}` : '';
    const response = yield request(`/issues${passwordQueryString}`, 'POST', issue);
    yield dispatchSuccess(type, response);
    yield password && dispatch(SET_LAST_ENTERED_PASSWORD_DATA, { password, time: Date.now() });
  } catch (err) {
    yield dispatchFail(type, err.message);
  }
}

export default function* indicatorsSaga() {
  yield takeEvery(INDICATOR_GROUP_ADD, addIndicatorGroup);
  yield takeEvery(INDICATOR_GROUP_DELETE, deleteIndicatorGroup);
  yield takeEvery(INDICATOR_GROUP_UPDATE, updateIndicatorGroup);
  yield takeEvery(INDICATOR_PANEL_ADD, addIndicatorPanel);
  yield takeEvery(INDICATOR_PANEL_UPDATE, updateIndicatorPanel);
  yield takeEvery(INDICATOR_PANEL_DELETE, deleteIndicatorPanel);
  yield takeEvery(INDICATOR_UPDATE, updateIndicator);
  yield takeEvery(INDICATOR_CHANGE_DEVICES_POLLING_STATE, changeIndicatorDevicesPollingState);
  yield takeEvery(INDICATOR_CHANGE_REGIONS_POLLING_STATE, changeIndicatorRegionsPollingState);
  yield takeEvery(INDICATOR_CHANGE_REGIONS_GUARD, changeIndicatorRegionsGuard);
  yield takeEvery(INDICATOR_PERFORM_SCENARIO_ACTION, performIndicatorScenarioAction);
  yield takeEvery(INDICATOR_PERFORM_DEVICE_ACTION, performIndicatorDeviceAction);
}
