import styles from 'components/Table/styles.module.css';
import { Filters } from 'components/index';
import i18next from 'i18next';
import React from 'react';


class UpperMenu extends React.Component {
  render() {
    const {  width, isEnabled, onSaveFilters, customElements, filters, ...rest } = this.props;
    return (
    isEnabled ? (
      <div className={styles.upper_menu} style={{ width, ...rest.styles }}>
        {customElements && !customElements.length ? customElements : null}
        {customElements && customElements.length ? customElements.map(e => e) : null}
        {filters && filters.length ? (
          <Filters
            key={'filters'}
            filters={filters}
            onSaveFilters={onSaveFilters}
            title={i18next.t('filter', { context: 'plural' })}
          />
        ) : null}
      </div>
    ) : null
    );
  }
}

export default UpperMenu;