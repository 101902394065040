const theme = {
  colors: {
    'main': '#5FA2DD',
    'secondary': '#25374B',
    'active': '#fff',
    'background': '#F6F6F6',
    'border': '#ECECEC',
    'accent': '#FFEFBB'
  }
};

export default theme;
