import React, { useEffect, useMemo, useRef, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { Empty, Table } from 'antd';
import { isEqual } from 'lodash';
import i18next from 'i18next';
import { TableHeader } from './TableHeader';

import styled, { css } from 'styled-components';
import { defaultSpinProps } from 'components/Spin';
import styles from './styles.module.css';
import { Table as VirtualTable, Column as VirtualColumn, AutoSizer } from 'react-virtualized';
import { KeyCodes } from 'constants/keyboard';
import { getSelectedItemsByPressedKeys } from 'helpers';
import { getActiveValue } from 'helpers/filters';
import { EMPTY_OBJECT } from 'constants/common';
import { localStorage } from 'helpers/storage';
import UpperMenu from 'components/Menu/UpperMenu';
import isEmptyJSObject from 'helpers/isEmptyJSObject';

const TableWrapper = styled.div`
  width: 100%;
  flex: 1;
  background: white;
  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & .ant-table-wrapper {
    height: auto !important;
  }
  & .ant-table {
    font-size: var(--table-font-size, 12px);
  }
  & .ant-table.ant-table-small .ant-table-title,
  .ant-table.ant-table-middle .ant-table-title {
    padding: 0px;
  }
  & .ant-table-middle .ant-table-thead > tr > th:not(.ant-table-selection-column),
  .ant-table-middle .ant-table-tbody > tr > td {
    max-width: 0;
    padding: 0px 8px;
    border-right: 1px solid #e9e9e9;
  }
  & .ant-table-middle .ant-table-tbody > tr > td {
    padding: 0 8px 1px 8px;
    border-right: 1px solid #e9e9e9;
  }
  .ant-table-column-sorters {
    padding-left: 10px;
    padding-right: 10px;
  }
  & .logTd {
    background: red;
  }
  & .hidden-expand {
    & .ant-table-row-expand-icon {
      display: none;
    }
  }

  & .ReactVirtualized__Table__rowColumn {
    border-right: 1px solid #e8e8e8;
    border-left: 0 !important;
  }
  & .ReactVirtualized__Table__row {
    padding-right: 0 !important;
    border-left: 0 !important;
  }

  ${p =>
    p.className
      ? css`
          td {
            padding: 0 !important;
            height: 1px !important;
          }
        `
      : null} ${p => p.css};
`;

const TABLE_HEADER_HEIGHT = 27;
const UPPER_MENU_HEIGHT = 35;
const COUNTER_HEIGHT = 25;
const PAGINATION_HEIGHT = 25;
const MIN_DEFAULT_COLUMN_WIDTH = 50;
const MIN_DEFAULT_TABLE_WIDTH = 500;

/**
 * Таблица для больших данных.
 *
 * @property {String} uniqueKey - ключ для параметров отображения таблицы в localStorage
 * @property {Array} dataSource - список данных для построения таблицы
 * @property {Array} columns - список колонок для построения таблицы
 *  (структура колонок соглано документации antd Table https://ant.design/components/table/#Column)
 *
 * @property {Array} filters - список фильтров для дерева {@link Filters}
 * @property fireRightClick - разрешать ли событие нажатия правой кнопкой мыши на элемент
 * @property loading - отображать ли загрузку данных
 * @property emptyText - текст в случае отсутствия данных в таблице
 * @property minimalColumnWidth - минимально возможная ширина колонки
 * @property needToShowUpperMenu - нужно ли отображать меню над таблицей
 * @property upperMenuElements - список элементов, которые нужно добавить в верхнее меню
 * @property upperMenuStyles - дополнительные стили для верхнего меню
 * @property onSetFilters - вызывается когда были установлены фильтры
 * @property isShowCounters - показывать ли счетчики элементов внизу таблицы
 * @property rowHeight - высота строк таблицы
 * @property onKeyDown - вызывается при нажатии клавишей над таблицей
 *      @param event - стандартный объект события
 *      @param selectedIds - список идентификаторов выделенных объектов
 */
TableComponent.propsTypes = {
  uniqueKey: PropTypes.string,
  dataSource: PropTypes.array,
  onRowClick: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  onContextMenu: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  fireRightClick: PropTypes.bool,
  loading: PropTypes.bool,
  emptyText: PropTypes.string,
  minimalColumnWidth: PropTypes.number,
  needToShowUpperMenu: PropTypes.bool,
  upperMenuStyles: PropTypes.object,
  upperMenuElements: PropTypes.object,
  filters: PropTypes.array,
  onSetFilters: PropTypes.func,
  isShowCounters: PropTypes.bool,
  rowHeight: PropTypes.number,
  isMultipleSelection: PropTypes.bool,
  isFirstColumnSortable: PropTypes.bool
};

TableComponent.defaultProps = {
  dataSource: [],
  onRowClick: () => {},
  onRowDoubleClick: () => {},
  onContextMenu: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  fireRightClick: false,
  loading: false,
  emptyText: i18next.t('noData'),
  minColumnWidth: 50,
  needToShowUpperMenu: false,
  isShowCounters: true,
  rowHeight: 25,
  isMultipleSelection: false,
  pagination: false,
  upperMenuStyles: { height: '35px' },
  isFirstColumnSortable: false
};

const pressedKeys = new Set();

function TableComponent(props) {
  const {
    uniqueKey,
    className,
    wrapperCss,
    tableStyle,
    onRowClick,
    onRowDoubleClick,
    rowClassName,
    fireRightClick,
    loading,
    emptyText,
    columns = props.children,
    isVirtualized,
    scroll = EMPTY_OBJECT,
    minColumnWidth,
    upperMenuStyles,
    needToShowUpperMenu,
    dataSource,
    isShowCounters = props.pagination ? false : props.isShowCounters,
    height,
    width,
    isMultipleSelection,
    pagination,
    isFirstColumnSortable,
    isDefaultColumnsWidth,
    parentSelection,
    isInactiveTab,
    ...restProps
  } = props;
  const paramsFromStorage = useMemo(() => {
    let fromStorage = null;
    if (uniqueKey) {
      fromStorage = localStorage.getItem(uniqueKey);
      if (fromStorage) fromStorage = JSON.parse(fromStorage);
    }
    return fromStorage;
  }, [uniqueKey]);
  const columNumbers = columns ? columns.length : 0;
  const minTableWidth = useMemo(() => {
    const mindDefaultWidth = minColumnWidth ? minColumnWidth : MIN_DEFAULT_COLUMN_WIDTH;
    let minWidth = 0;
    for (const column in columns) {
      minWidth += columns[column].minWidth || mindDefaultWidth;
    }
    return minWidth;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columNumbers, columns, minColumnWidth]);

  const [tableWidth, setTableWidth] = useState(width);
  const [sizes, setSizes] = useState(
    paramsFromStorage && paramsFromStorage.sizes ? paramsFromStorage.sizes : EMPTY_OBJECT
  );
  const [filters, setFilters] = useState(props.filters || []);
  const [activeFilterValues, setActiveFilterValues] = useState(new Map());
  const [data, setData] = useState(dataSource ? dataSource : []);
  const [selections, setSelections] = useState(props.selections ? props.selections : []);
  const [scrollData, setScrollData] = useState({});
  const [lastSelectedId, setLastSelectedId] = useState('');
  const tableScroll = { y: height, ...scroll };

  const tableWrapper = useRef(null);
  const sizerTimeoutId = useRef(null);
  const isKeyDownInProgress = useRef(true);

  //Выставляет selections, когда меняется props.selections
  useEffect(() => {
    if (!props.selections) return;
    if (isEqual(selections, props.selections)) return;

    dataSource.find((e, index) => {
      const compare = e.id === props.selections[0];
      if (compare) {
        setScrollData(c => ({ ...c, index }));
        setSelections([e.id]);
      }
      return compare;
    });
  }, [dataSource, props.selections, selections]);

  useEffect(() => {
    if (filters.length && filters.find(f => f.active && f.active.length)) {
      setData(null); // говорим таблице, что применяется фильтрация
    }
    applyFilters(dataSource, filters).then(newData => {
      setData(newData);
      if (newData && newData.length > 0) setLastSelectedId(newData[0].id);
    });
  }, [dataSource, filters]);

  const applyFilters = async (data, filters) => {
    if (!filters || !filters.length) return data;
    const notEmptyFilters = filters.filter(f => f.active && f.active.length);
    if (!notEmptyFilters.length) return data;

    const newData = [];
    data.forEach(it => {
      const finalFilters = notEmptyFilters.filter(f => f.check(f, it));
      if (notEmptyFilters.length === finalFilters.length) newData.push(it);
    });
    return newData;
  };

  const onColumnSizeChanged = (columnKey, nextColumnKey, delta) => {
    const columnWidth = sizes[columnKey];
    const nextColumnWidth = sizes[nextColumnKey];
    const newSizes = { ...sizes };
    newSizes[columnKey] = columnWidth + delta;
    newSizes[nextColumnKey] = nextColumnWidth - delta;
    setSizes(newSizes);

    if (uniqueKey)
      localStorage.setItem(uniqueKey, JSON.stringify({ ...paramsFromStorage, sizes: newSizes }));
    return true;
  };

  const preparedColumns = useMemo(() => {
    return tableWidth && sizes ? prepareColumns(columns, sizes) : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, sizes]);

  const gridRef = useRef();
  const [connectObject] = useState(() => {
    const obj = {};
    Object.defineProperty(obj, 'scrollLeft', {
      get: () => null,
      set: scrollLeft => {
        if (gridRef.current) {
          gridRef.current.scrollTo({
            scrollLeft
          });
        }
      }
    });
    return obj;
  });

  const renderVirtualList = (rawData, { scrollbarSize, ref, onScroll }, width, height) => {
    ref.current = connectObject;
    return (
      <VirtualTable
        style={{ width, ...tableStyle }}
        ref={gridRef}
        className={styles.virtual_table}
        rowClassName={({ index }) => getRowClassName(rawData[index])}
        columnCount={preparedColumns.length}
        height={height}
        width={width}
        rowCount={rawData.length}
        rowHeight={() => props.rowHeight}
        headerHeight={0}
        disableHeader={true}
        scrollToIndex={scrollData.index}
        rowGetter={({ index }) => rawData[index]}
        onRowClick={({ event, rowData }) => onCustomRowClick(rowData, event)}
        onRowDoubleClick={({ rowData }) => onRowDoubleClick(rowData, [rowData.id])}
        onRowRightClick={({ event, rowData }) => {
          if (fireRightClick && !selections.includes(rowData.id)) {
            onCustomRowClick(rowData, event);
          }
        }}
        rowStyle={{ borderBottom: '1px solid #e8e8e8' }}
      >
        {preparedColumns.map((column, i) => {
          return (
            <VirtualColumn
              label={null}
              dataKey={column.dataIndex}
              key={column.dataIndex}
              width={column.width}
              cellRenderer={data => column.render(data.cellData, data.rowData, data.rowIndex)}
              headerRenderer={() => null}
            />
          );
        })}
      </VirtualTable>
    );
  };

  //Если "сверху" пришли измененные фильтры, то применим повторно активные значения фильтров,
  //которые применялись при последнем вызове onSaveFilters
  useEffect(() => {
    const newFilters = props.filters;
    if (!newFilters || !newFilters.length) return;
    const mergedFilters = [...newFilters];
    mergedFilters.forEach(filter => {
      filter.active = getActiveValue(activeFilterValues, filter);
    });
    setFilters(mergedFilters);

    // Ставить activeFilterValues в зависимость не надо, иначе применение активных значений будет выполняться 2 раза.
    // Если применение убрать из onSaveFilters, то применение будет выполняться единожды, но через 2 обновления компонента.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filters]);
  const onSaveFilters = newActiveFilterValuesMap => {
    const { onSetFilters } = props;
    const newData = [...filters];
    newData.forEach(filter => {
      filter.active = getActiveValue(newActiveFilterValuesMap, filter);
    });
    setFilters(newData);
    setActiveFilterValues(newActiveFilterValuesMap);
    if (onSetFilters) onSetFilters(newData);
  };

  const onCustomRowClick = (record, event) => {
    let itemsIds = [record.id];
    if (isMultipleSelection) {
      // Необходимо смотреть флаги события, т.к. иногда pressedKeys остается пустым
      if (event.ctrlKey) pressedKeys.add(KeyCodes.CTRL);
      if (event.shiftKey) pressedKeys.add(KeyCodes.SHIFT);
      itemsIds = getSelectedItemsByPressedKeys(
        data,
        selections,
        lastSelectedId,
        pressedKeys,
        record
      );
    }

    setSelections(itemsIds);
    setLastSelectedId(record.id);
    setScrollData({ index: undefined });

    const items = getSelectedItems(itemsIds);
    if (isMultipleSelection) props.onRowClick(items, itemsIds);
    else props.onRowClick(record, record.id);
    if (event) {
      event.nativeEvent.preventDefault();
      event.nativeEvent.stopPropagation();
    }
  };

  //Если нет выделенных элементов при инициализации, то выделяем первый
  //Если есть выделенный элемент, а родительский компонент об этом не знает, то сообщаем родителю
  useEffect(() => {
    if (isInactiveTab || !(data && data.length)) return;
    if (!selections.length) {
      const item = data[0];
      setSelections([item.id]);
      if (isMultipleSelection) onRowClick([item], [item.id]);
      else onRowClick(item, item.id);
    } else if (parentSelection && !parentSelection.length) {
      const currentSelectedItemIndex = selections[0];
      const item = data[currentSelectedItemIndex] || data[0];
      if (isMultipleSelection) onRowClick([item], [item.id]);
      else onRowClick(item, item.id);
    }
    // eslint-disable-next-line
  }, [data, isMultipleSelection, onRowClick, selections, isInactiveTab]);

  //Если компонент не имеет выделенного (выбранного) элемента, а в родительском подразумевается, что он есть, то выбираем его текущем компоненте
  const selectedParentItem = Array.isArray(parentSelection) && parentSelection[0];
  useEffect(() => {
    if (!selectedParentItem || !selections.length || !parentSelection.length) return;
    const itemForSelecting = dataSource.find(dataItem => dataItem.id === selectedParentItem);
    if (!itemForSelecting) return;
    setSelections([itemForSelecting.id]);
    // eslint-disable-next-line
  }, [selectedParentItem]);

  const getRowClassName = record => {
    if (typeof rowClassName === 'string') return rowClassName;
    if (rowClassName) return rowClassName(record || {}, selections);
    if (!record) return styles.default_row;
    const defName = styles.default_row;
    if (selections.includes(record.id)) return `${defName} ${styles.selected_row}`;
    else return defName;
  };

  const renderEmpty = (rawData, parentProps, height) => {
    return (
      <Empty
        className={styles.empty}
        style={{ height }}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={emptyText}
      />
    );
  };

  const getSelectedItems = ids => data.filter(it => ids.includes(it.id));

  const onKeyDown = event => {
    pressedKeys.add(event.keyCode);

    if (props.onKeyDown) props.onKeyDown(event, selections);
    if (!selections.length || !data || !data.length) return;

    switch (event.keyCode) {
      case KeyCodes.ARROW_DOWN:
      case KeyCodes.ARROW_UP: {
        //Если не фокусироваться, то иногда случается баг с потерей фокуса при навигации клавиатурой
        tableWrapper.current.focus();

        let index = -1;
        data.find((it, i) => {
          const compare = it.id === selections[0];
          if (compare && index < 0) index = i;
          return compare;
        });
        const nextIndex = event.keyCode === KeyCodes.ARROW_UP ? index - 1 : index + 1;

        if (index !== -1 && data[nextIndex]) {
          // Добавлен setTimeout для того, что бы при зажатии стрелок
          // вверх или вниз переключение строк происходило
          // последовательно, без зависания

          if (isKeyDownInProgress.current) {
            isKeyDownInProgress.current = false;
            setSelections([data[nextIndex].id]);
            setLastSelectedId(data[nextIndex].id);
            setScrollData({ ...scrollData, index: nextIndex });
            setTimeout(() => {
              isKeyDownInProgress.current = true;
            }, 50);
          }
        }

        event.nativeEvent.preventDefault();
        event.nativeEvent.stopPropagation();
        break;
      }
      case KeyCodes.A: {
        if (!event.ctrlKey || !isMultipleSelection) break;
        const newSelections = data.map(item => item.id);
        setSelections(newSelections);
        const objects = getSelectedItems(newSelections);
        if (isMultipleSelection) {
          setLastSelectedId(newSelections[newSelections.length - 1]);
          props.onRowClick(objects, newSelections);
        } else {
          props.onRowClick(objects[0], newSelections[0]);
        }

        event.nativeEvent.preventDefault();
        event.nativeEvent.stopPropagation();
        break;
      }
      default:
        return;
    }
  };

  const onKeyPress = event => pressedKeys.add(event.keyCode);
  const onKeyUp = event => {
    if (
      (event.keyCode === KeyCodes.ARROW_UP && pressedKeys.has(KeyCodes.ARROW_UP)) ||
      (event.keyCode === KeyCodes.ARROW_DOWN && pressedKeys.has(KeyCodes.ARROW_DOWN))
    ) {
      const objects = getSelectedItems(selections);
      if (isMultipleSelection) {
        props.onRowClick(objects, selections);
      } else {
        props.onRowClick(objects[0], selections[0]);
      }
    }
    pressedKeys.delete(event.keyCode);
  };

  const nColumns = columns ? columns.length : 0;

  const setDefaultSizes = () => {
    const newSizes = getColumnsDefaultWidths(columns, tableWidth, isDefaultColumnsWidth);
    setSizes(newSizes);
    if (uniqueKey)
      localStorage.setItem(uniqueKey, JSON.stringify({ ...paramsFromStorage, sizes: newSizes }));
  };

  useEffect(() => {
    const newSizes = calculateColumnsSizes(
      columns,
      tableWidth,
      sizes,
      minTableWidth,
      isDefaultColumnsWidth
    );
    if (newSizes !== sizes) setSizes(newSizes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableWidth, nColumns]);

  return (
    <AutoSizer
      onResize={arg => {
        if (!arg.width) return;
        clearTimeout(sizerTimeoutId.current);
        sizerTimeoutId.current = setTimeout(() => {
          let width = props.width ? props.width : arg.width;
          width = width > minTableWidth ? width : minTableWidth;
          setTableWidth(width);
        }, 250);
      }}
    >
      {({ width, height }) => {
        width = props.width ? props.width : width;
        height = props.height ? props.height : height;
        width = width < MIN_DEFAULT_TABLE_WIDTH ? MIN_DEFAULT_TABLE_WIDTH : width;
        height = height - TABLE_HEADER_HEIGHT;

        if (needToShowUpperMenu) height -= UPPER_MENU_HEIGHT;
        if (isShowCounters) height -= COUNTER_HEIGHT;
        if (pagination) height -= PAGINATION_HEIGHT;
        const contentHeight = props.dataSource.length * props.rowHeight;
        const headerSizerHeight =
          contentHeight > height
            ? height + TABLE_HEADER_HEIGHT
            : contentHeight + TABLE_HEADER_HEIGHT;
        return (
          <div
            ref={tableWrapper}
            className={`${props.className ? props.className : ''} ${styles.table}`}
            onKeyDown={onKeyDown}
            onKeyPress={onKeyPress}
            onKeyUp={onKeyUp}
            tabIndex="0"
          >
            <UpperMenu
              width={width}
              filters={filters}
              isEnabled={needToShowUpperMenu}
              onSaveFilters={onSaveFilters}
              styles={upperMenuStyles}
              customElements={props.upperMenuElements}
            />
            <TableWrapper css={wrapperCss} className={className} style={{ width }}>
              <TableHeader
                onSizeChanged={onColumnSizeChanged}
                isFirstColumnSortable={isFirstColumnSortable}
                setDefaultSizes={setDefaultSizes}
                columns={preparedColumns}
                height={headerSizerHeight}
                onSort={restProps.onChange}
                minColumnWidth={minColumnWidth}
              />
              <Table
                rowKey={record => record.id}
                dataSource={data || []}
                style={{ ...tableStyle, width, height }}
                size="middle"
                showSorterTooltip={false}
                pagination={pagination}
                showHeader={false}
                bordered={true}
                rowClassName={getRowClassName}
                onRow={record => ({
                  onClick: e => onCustomRowClick(record, e),
                  onDoubleClick: () => onRowDoubleClick(record, [record.id]),
                  onContextMenu: () => {
                    if (fireRightClick) onCustomRowClick(record, [record.id]);
                  }
                })}
                loading={getLoadingProps(loading, data)}
                columns={preparedColumns}
                components={
                  !data || !data.length
                    ? { body: (r, p) => renderEmpty(r, p, height) }
                    : isVirtualized
                    ? {
                        body: (rawData, restParam) =>
                          renderVirtualList(rawData, restParam, width, height)
                      }
                    : undefined
                }
                scroll={tableScroll}
                {...restProps}
              />
            </TableWrapper>
            {isShowCounters && data && data.length ? (
              <Footer
                allData={dataSource}
                visibleData={data}
                selections={selections}
                width={width}
                isMultipleSelection={isMultipleSelection}
              />
            ) : null}
          </div>
        );
      }}
    </AutoSizer>
  );
}

function getLoadingProps(loading, data) {
  let tip = defaultSpinProps.tip;
  if (!loading && data === null) tip = i18next.t('filtersApplying');
  return loading || data === null ? { ...defaultSpinProps, tip } : false;
}

const prepareColumns = (columns, sizes) => {
  if (!columns) return [];
  const newColumns = [];
  columns.forEach(column => {
    if (column.hidden) return;
    const columnSize = isNaN(sizes[column.key]) ? MIN_DEFAULT_COLUMN_WIDTH : sizes[column.key];
    if (!column.title || !column.render || (!!columnSize && columnSize !== column.width)) {
      const newColumn = { ...column };
      if (columnSize) newColumn.width = columnSize;
      newColumn.render = newColumn.render
        ? newColumn.render
        : text => <span className={styles.cell}>{text}</span>;
      newColumn.filterDropdownVisible = false;
      newColumns.push(newColumn);
    } else {
      newColumns.push(column);
    }
  });
  return newColumns;
};

function getColumnsWidthAfterSizeReducing(
  prevWidth,
  tableWidth,
  columns,
  prevColumnsWidths,
  nColumns
) {
  const newColumnsWidths = {};
  const widthDiff = (tableWidth - prevWidth) / nColumns;
  const keysWithoutWidth = [];
  let sumOfWidth = tableWidth;

  columns.forEach(({ key, hidden, minWidth }) => {
    if (hidden) return;
    if (prevColumnsWidths[key]) {
      const newWidth = prevColumnsWidths[key] + widthDiff;
      sumOfWidth -= newWidth;
      newColumnsWidths[key] = newWidth;
    } else {
      keysWithoutWidth.push(key);
    }
    keysWithoutWidth.length &&
      keysWithoutWidth.forEach(key => {
        newColumnsWidths[key] = sumOfWidth / keysWithoutWidth.length;
        if (newColumnsWidths[key] < MIN_DEFAULT_COLUMN_WIDTH) {
          newColumnsWidths[key] = minWidth ? minWidth : MIN_DEFAULT_COLUMN_WIDTH;
        }
      });
  });
  return newColumnsWidths;
}
function getColumnsWidthAfterSizeIncreasing(
  prevWidth,
  tableWidth,
  columns,
  prevColumnsWidths,
  nColumns
) {
  const newColumnsWidths = {};
  let restDiff = prevWidth - tableWidth;
  let averageDiff = restDiff / nColumns;

  columns.forEach(({ key, hidden, minWidth }, index) => {
    minWidth = minWidth ? minWidth : MIN_DEFAULT_COLUMN_WIDTH;
    const newWidth = prevColumnsWidths[key] - averageDiff;
    const minWidthCheck = newWidth > minWidth;
    if (hidden) return;

    if (minWidthCheck) {
      newColumnsWidths[key] = newWidth;
    } else {
      restDiff += prevColumnsWidths[key] - minWidth;
      averageDiff = restDiff / (nColumns - index + 1);
      newColumnsWidths[key] = minWidth;
    }
  });
  return newColumnsWidths;
}

function getColumnsDefaultWidths(columns, tableWidth, isDefaultColumnsWidth) {
  const newColumnsWidths = {};
  columns.forEach(({ key, hidden, minWidth, width }, index) => {
    if (!hidden) {
      if (index === columns.length - 1) {
        newColumnsWidths[key] = tableWidth;
        return;
      }
      let newWidth;
      if (isDefaultColumnsWidth) newWidth = width || minWidth || MIN_DEFAULT_COLUMN_WIDTH;
      else newWidth = minWidth || width || MIN_DEFAULT_COLUMN_WIDTH;
      newColumnsWidths[key] = newWidth;
      tableWidth -= newWidth;
    }
  });
  return newColumnsWidths;
}

function calculateColumnsSizes(columns, tableWidth, prevColumnsWidths, isDefaultColumnsWidth) {
  if (!columns || !tableWidth || !columns.length) return prevColumnsWidths;
  let prevWidth = 0;
  for (let item in prevColumnsWidths) {
    prevWidth += prevColumnsWidths[item];
  }
  const nColumns = columns.length;

  if (isEmptyJSObject(prevColumnsWidths)) {
    return getColumnsDefaultWidths(columns, tableWidth, isDefaultColumnsWidth);
  } else if (prevWidth < tableWidth) {
    return getColumnsWidthAfterSizeReducing(
      prevWidth,
      tableWidth,
      columns,
      prevColumnsWidths,
      nColumns
    );
  } else {
    return getColumnsWidthAfterSizeIncreasing(
      prevWidth,
      tableWidth,
      columns,
      prevColumnsWidths,
      nColumns
    );
  }
}

function Footer({ width, allData, visibleData, selections, isMultipleSelection }) {
  const allDataLength = allData ? allData.length : 0;
  const sLength = selections.length > 1 && isMultipleSelection ? selections.length : 0;
  const vDataLength = visibleData ? visibleData.length : 0;
  return (
    <div className={styles.footer} style={{ width }}>
      <span>{`${i18next.t('totalItems')}:  ${allDataLength}`}</span>
      <span>{`${sLength ? `${i18next.t('highlighted')}: ` + sLength : ''}`}</span>
      <span>{`${
        allDataLength !== vDataLength ? `${i18next.t('filtered')}: ` + vDataLength : ''
      }`}</span>
    </div>
  );
}

export default memo(TableComponent);
