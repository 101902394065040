import { createSelector } from 'reselect';
import SUBSYSTEMS from 'constants/subsystems';
import { getCurrentDriverAttributes } from 'helpers/drivers';
import i18next from 'i18next';
import { getCurrentUser } from './user';
import { filterEntities } from './filtration';

/**
 * Получить список доступных подсистем.
 */
export const getAvailableSubsystems = createSelector(
  [({ drivers }) => drivers, ({ currentUser }) => currentUser],
  (drivers, currentUser) => {
    if (
      currentUser &&
      currentUser.driverProfileId &&
      drivers &&
      drivers[currentUser.driverProfileId] &&
      drivers[currentUser.driverProfileId].attributes
    ) {
      const attributes = drivers[currentUser.driverProfileId].attributes;
      return Object.values(SUBSYSTEMS)
        .filter(subsystem => {
          if (subsystem.id === SUBSYSTEMS.UNKNOWN.id) return false;
          if (!attributes.fireSubsystem && subsystem.id === SUBSYSTEMS.FIRE.id) return false;
          if (!attributes.securitySubsystem && subsystem.id === SUBSYSTEMS.SECURITY.id)
            return false;
          if (!attributes.skudSubsystem && subsystem.id === SUBSYSTEMS.SKUD.id) return false;
          return true;
        })
        .map(subsystem => ({ ...subsystem, name: i18next.t(`subsystems.${subsystem.id}`) }))
        .sort((a, b) => a.index - b.index);
    }
    return [];
  }
);

/**
 * Получить список доступных подсистем с учетом настроек фильтрации доступа к оперативным данным у текущего пользователя.
 */
export const getOperativeAvailableSubsystems = createSelector(
  [getCurrentUser, getAvailableSubsystems],
  (user, subsystems) => {
    const subsystemsWithTags = subsystems.map(subsystem => ({
      ...subsystem,
      filterTags: { tags: [subsystem.id] }
    }));
    return filterEntities(user, subsystemsWithTags);
  }
);

export const getAvailableSubsystemsHash = createSelector([getAvailableSubsystems], subsystems => {
  const subsystemsHash = {};
  for (const subsystem of subsystems) {
    subsystemsHash[subsystem.id] = subsystem;
  }
  return subsystemsHash;
});

/**
 * Получить идентификаторы доступных подсистем.
 */
export const getAvailableSubsystemIds = createSelector(getAvailableSubsystems, subsystems =>
  subsystems.map(subsystem => subsystem.id)
);

/**
 * Получить список подсистем являющимися фильтрующими тегами.
 */
export const getSubsystemTags = createSelector(getCurrentDriverAttributes, driverAttributes => {
  return Object.values(SUBSYSTEMS)
    .filter(subsystem => {
      if (
        subsystem.default ||
        (driverAttributes && driverAttributes[subsystem.driverAttributeFiledName])
      ) {
        return subsystem.isFilterTag;
      } else return false;
    })
    .map(subsystem => ({ ...subsystem, name: i18next.t(`subsystems.${subsystem.id}`) }))
    .sort((t1, t2) => t1.index - t2.index);
});
