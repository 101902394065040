import React from 'react';

const Rect = ({ color = '#fff', width, height, x = '16', y = '16' }) => {
  return (
    <svg width={width || x} height={height || y} viewBox={`0 0 ${x} ${y}`}>
      <path
        fill={color}
        stroke="null"
        id="rect"
        d="m12.614552,2.810735l-9.158654,0c-1.556971,0 -2.747596,1.190625 -2.747596,2.747596l0,5.495192c0,1.556971 1.190625,2.747596 2.747596,2.747596l9.158654,0c1.556971,0 2.747596,-1.190625 2.747596,-2.747596l0,-5.495192c0,-1.556971 -1.190625,-2.747596 -2.747596,-2.747596zm0.915865,8.242789c0,0.549519 -0.366346,0.915865 -0.915865,0.915865l-9.158654,0c-0.549519,0 -0.915865,-0.366346 -0.915865,-0.915865l0,-5.495192c0,-0.549519 0.366346,-0.915865 0.915865,-0.915865l9.158654,0c0.549519,0 0.915865,0.366346 0.915865,0.915865l0,5.495192z"
      />
      <text
        xmlns="http://www.w3.org/2000/svg"
        textAnchor="start"
        fontSize="10"
        id="rect_text"
        y="11.2"
        x="5.5"
        fillOpacity="null"
        strokeOpacity="null"
        strokeWidth="0"
        stroke={color}
        fill={color}
      >
        z
      </text>
    </svg>
  );
};

export default Rect;
