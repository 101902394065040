import React from 'react';

const Access = ({ color = '#5FA2DD', x = '16', y = '16' }) => {
  return (
    <svg width={x} height={y} viewBox={`0 0 ${x} ${y}`}>
      <path
        stroke="null"
        id="access"
        fill={color}
        d="m12.233103,6.970939l-0.529627,0l0,-2.496811c0,-2.042118 -1.661449,-3.703385 -3.703385,-3.703385c-2.0423,0 -3.703567,1.661267 -3.703567,3.703385l0,2.496811l-0.529263,0c-0.645482,0 -1.169107,0.523079 -1.169107,1.168562l0,5.921197c0,0.645482 0.523625,1.168562 1.169107,1.168562l8.465842,0c0.645482,0 1.168743,-0.523079 1.168743,-1.168562l0,-5.921015c0,-0.645482 -0.523261,-1.168743 -1.168743,-1.168743zm-3.875623,4.49637l0,1.388633c0,0.197701 -0.160234,0.357753 -0.357389,0.357753c-0.197519,0 -0.357753,-0.160052 -0.357753,-0.357753l0,-1.388633c-0.404313,-0.146411 -0.69368,-0.532537 -0.69368,-0.987594c0,-0.58037 0.470698,-1.051433 1.051433,-1.051433c0.580189,0 1.051614,0.471062 1.051614,1.051433c0,0.455603 -0.289912,0.841728 -0.694226,0.987594zm1.943541,-4.49637l-4.602041,0l0,-2.496811c0,-1.268776 1.032335,-2.301111 2.301111,-2.301111c1.268594,0 2.300929,1.032335 2.300929,2.301111l0,2.496811z"
      />
    </svg>
  );
};

export default Access;
