//Тип сущностей
export const ENTITY_TYPE = {
  NONE: 'NONE',
  DEVICE: 'DEVICE',
  CONTROL_DEVICE: 'CONTROL_DEVICE',
  REGION: 'REGION',
  SCENARIO: 'SCENARIO',
  VIRTUAL_STATE: 'VIRTUAL_STATE',
  VIRTUAL_COUNTER: 'VIRTUAL_COUNTER',
  VIRTUAL_INDICATOR: 'VIRTUAL_INDICATOR',
  EMPLOYEE: 'EMPLOYEE',
  ACCESS_KEY: 'ACCESS_KEY',
  WORK_SCHEDULE: 'WORK_SCHEDULE',
  VIRTUAL_INDICATOR_PANEL: 'VIRTUAL_INDICATOR_PANEL',
  VIRTUAL_INDICATOR_PANEL_GROUP: 'VIRTUAL_INDICATOR_PANEL_GROUP'
};
