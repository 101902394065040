import React from 'react';

const Cross = ({ color = "#5FA2DD", x = "16", y = "13" }) => {
  return (
    <svg width={x} height={y} viewBox={`0 0 ${x} ${y}`}>
      <path fill={color} fillRule="evenodd" d="M7.852 5.138c.25.348.485.675.728.975.843-.93 1.902-1.593 3.926-1.723l-.622 1.575a.155.155 0 0 0 .24.181l3.815-2.934a.155.155 0 0 0 0-.246L12.124.032a.155.155 0 0 0-.24.181l.64 1.618c-2.74.144-4.356 1.09-5.494 2.211.305.375.573.748.822 1.096zM5.121 7.097c.4.559.832 1.159 1.367 1.729C5.268 10.23 3.49 11.508 0 11.508V8.952c2.767 0 3.888-.917 4.879-2.191l.242.336zm6.908-.459c.033 0 .066.01.095.032l3.815 2.934a.154.154 0 0 1 0 .247l-3.815 2.933a.155.155 0 1 1-.24-.18l.64-1.619c-4.251-.223-5.797-2.375-7.05-4.123C4.271 5.187 3.322 3.864 0 3.864V1.309c4.635 0 6.25 2.253 7.55 4.064 1.139 1.589 2.059 2.868 4.956 3.053l-.622-1.575a.155.155 0 0 1 .145-.213z" />
    </svg>
  )
}

export default Cross;
