import React, { Component } from 'react';
import { connect } from 'react-redux';

import OperativeTask from './OperativeTask';
import NavLink from 'components/Nav/NavLink';
import Tabs, { Tab } from 'components/Tabs';

import { includesInUserFilterTags } from 'helpers/filtration';
import { getCurrentDriverAttributes } from 'helpers/drivers';
import i18next from 'i18next';

class HeaderNav extends Component {
  render() {
    const { activeProject, currentUser, driverAttributes } = this.props;
    const isAvailableActiveProject = activeProject && activeProject.status === 'ACTIVE';
    let adminVisible = true;
    let skudVisible = driverAttributes && driverAttributes.skudSubsystem;
    let isAvailableSkudPage = !currentUser;
    if (currentUser) {
      let allPermissions = [
        ...(currentUser.groupPermissions || []),
        ...(currentUser.permissionIds || [])
      ];
      adminVisible &= allPermissions.includes('ADMINISTRATE');
      skudVisible &= allPermissions.includes('SKUD_READ');
      isAvailableSkudPage =
        isAvailableActiveProject && includesInUserFilterTags(currentUser, 'SKUD');
    }

    return (
      <Tabs>
        <NavLink to="/projects/" exact activeClassName="active">
          <Tab>{i18next.t('projects.name')}</Tab>
        </NavLink>
        <NavLink
          isActive={(match, location) =>
            location.pathname.includes(`/projects/${activeProject && activeProject.id}`)
          }
          to={`/projects/${activeProject && activeProject.id}/plan`}
          disabled={!activeProject}
          activeClassName="active"
        >
          <Tab className={activeProject ? '' : 'disabled'}>{i18next.t('activeProject.name')}</Tab>
        </NavLink>
        <OperativeTask />
        <NavLink strict to="/config/" disabled={!isAvailableActiveProject} activeClassName="active">
          <Tab className={isAvailableActiveProject ? '' : 'disabled'}>
            {i18next.t('deviceConfigurator.name')}
          </Tab>
        </NavLink>
        {skudVisible ? (
          <NavLink
            isActive={(match, location) => location.pathname.includes('/skud/')}
            to="/skud/employees"
            disabled={!isAvailableSkudPage}
            activeClassName="active"
          >
            <Tab className={isAvailableSkudPage ? '' : 'disabled'}>{i18next.t('PACS.name')}</Tab>
          </NavLink>
        ) : null}
        {adminVisible ? (
          <NavLink
            isActive={(match, location) => location.pathname.includes('/admin/')}
            to="/admin/users/"
            activeClassName="active"
          >
            <Tab>{i18next.t('admin.name')}</Tab>
          </NavLink>
        ) : null}
      </Tabs>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser.id ? state.currentUser : false,
    activeProject: state.activeProject.activeProject,
    driverAttributes: getCurrentDriverAttributes(state)
  };
};

export default connect(mapStateToProps)(HeaderNav);
