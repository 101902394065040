const DEFAULT_USER_PARAMS = { filterTagsOn: false, filterTags: { tags: ['GENERAL'] } };
const securityOn = process.env.REACT_APP_SECURITY_ON === 'true';

/**
 * Проверка проходит ли сущность фильтрацию по тегам
 * @param {Object} user - Пользователь
 * @param {Object} entity - Активная сущность имеющая теги фильтрации (устройство, зона и т.д.)
 * @return {bool}
 */
export const availableByFilterTags = securityOn
  ? (user = DEFAULT_USER_PARAMS, entity = {}) => {
      if (!entity.filterTags || !user.filterTagsOn) return true;
      const userTags = user.filterTags.tags;
      const entityTags = entity.filterTags.tags;
      return entityTags.length === 0 || entityTags.some(tag => userTags.includes(tag));
    }
  : () => true;

/**
 * Фильтрация списков
 * @param {Object} user - Пользователь
 * @param {Array.<Array>} entitiesLists - Списки фильтруемых сущностей
 * @return {Array.<Array>|Array.<Object>}
 */
export const filterEntities = securityOn
  ? (user, ...entitiesLists) => {
      if (entitiesLists.length === 1)
        return entitiesLists[0].filter(entity => availableByFilterTags(user, entity));
      else
        return entitiesLists.map(entities =>
          entities.filter(entity => availableByFilterTags(user, entity))
        );
    }
  : (user, ...entities) => entities;

/**
 * Проверка на наличие метки в метках пользователя
 * @param {Object} user - Пользователь
 * @param {String} tag - Метка
 */
export const includesInUserFilterTags = securityOn
  ? (user = DEFAULT_USER_PARAMS, tag) => {
      if (!user.filterTagsOn) return true;
      const userTags = user.filterTags.tags;
      return userTags.includes(tag);
    }
  : () => true;
