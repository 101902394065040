import {
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  CURRENT_USER_REQUEST,
  PERMISSIONS_LOAD,
  LOGIN_CHECK_CONFIRMATION,
  LOGIN_UPDATE_CONFIRMATION
} from 'constants/actionTypes';

import { createAction } from 'redux-actions';

export const loginUser = createAction(LOGIN_REQUEST, creds => creds);

export const logoutUser = createAction(LOGOUT_REQUEST);

export const loadCurrentUserInfo = createAction(CURRENT_USER_REQUEST);

export const loadPermissions = createAction(PERMISSIONS_LOAD);

export const checkLoginConfirmation = createAction(LOGIN_CHECK_CONFIRMATION, creds => creds);

export const updateLoginConfirmation = createAction(LOGIN_UPDATE_CONFIRMATION, needClear => needClear);
