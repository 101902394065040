import React from 'react';

const Panel = ({ color = "#5FA2DD", x = "18", y = "18" }) => {
  return (
    <svg width={x} height={y} viewBox={`0 0 ${x} ${y}`}>
      <path id="panel" fill={color} fillRule="evenodd"
            d="M 7.52,12.13
           C 7.52,12.13 11.09,12.13 11.09,12.13
             11.09,12.13 11.09,15.96 11.09,15.96
             11.09,15.96 7.52,16.00 7.52,16.00
             7.52,16.00 7.52,12.13 7.52,12.13 Z
           M 13.09,12.13
           C 13.09,12.13 16.43,12.13 16.43,12.13
             16.43,12.13 16.39,15.96 16.39,15.96
             16.39,15.96 13.04,15.91 13.04,15.91
             13.04,15.91 13.09,12.13 13.09,12.13 Z
           M 2.09,12.17
           C 2.09,12.17 5.52,12.17 5.52,12.17
             5.52,12.17 5.52,15.91 5.52,15.91
             5.52,15.91 2.09,16.00 2.09,16.00
             2.09,16.00 2.09,12.17 2.09,12.17 Z
           M 12.96,7.13
           C 12.96,7.13 16.22,7.13 16.22,7.13
             16.22,7.13 16.26,10.48 16.26,10.48
             16.26,10.48 12.96,10.48 12.96,10.48
             12.96,10.48 12.96,7.13 12.96,7.13 Z
           M 7.30,7.04
           C 7.30,7.04 11.17,7.00 11.17,7.00
             11.17,7.00 11.17,10.43 11.17,10.43
             11.17,10.43 7.30,10.43 7.30,10.43
             7.30,10.43 7.30,7.04 7.30,7.04 Z
           M 2.09,7.04
           C 2.09,7.04 5.48,7.04 5.48,7.04
             5.48,7.04 5.43,10.57 5.43,10.57
             5.43,10.57 2.13,10.52 2.13,10.52
             2.13,10.52 2.09,7.04 2.09,7.04 Z
           M 7.24,1.94
           C 7.24,1.94 11.04,1.91 11.04,1.91
             11.04,1.91 11.04,5.65 11.04,5.65
             11.04,5.65 7.26,5.61 7.26,5.61
             7.26,5.61 7.24,1.94 7.24,1.94 Z
           M 2.00,1.97
           C 2.00,1.97 5.48,2.00 5.48,2.00
             5.48,2.00 5.43,5.61 5.43,5.61
             5.43,5.61 1.96,5.61 1.96,5.61
             1.96,5.61 2.00,1.97 2.00,1.97 Z
           M 12.87,1.96
           C 12.87,1.96 16.22,1.87 16.22,1.87
             16.22,1.87 16.17,5.70 16.17,5.70
             16.17,5.70 12.87,5.74 12.87,5.74
             12.87,5.74 12.87,1.96 12.87,1.96 Z
           M 17.06,0.56
           C 17.25,0.56 17.40,0.71 17.40,0.90
             17.40,4.21 17.40,13.79 17.40,17.10
             17.40,17.29 17.25,17.44 17.06,17.44
             13.77,17.44 4.23,17.44 0.94,17.44
             0.75,17.44 0.60,17.29 0.60,17.10
             0.60,13.79 0.60,4.21 0.60,0.90
             0.60,0.71 0.75,0.56 0.94,0.56
             4.23,0.56 13.77,0.56 17.06,0.56 Z"/>
    </svg>
  )
};

export default Panel;
