import { DEVICE_UPDATE_CURRENT } from 'constants/actionTypes';

export default function currentDevice(state = {}, { type, payload }) {
  switch (type) {
    case DEVICE_UPDATE_CURRENT: {
      if (Array.isArray(payload)) return [...payload];
      else return { ...payload };
    }
    default:
      return state;
  }
}
