/**
 * Устройства.
 * Все REST-методы, на которые ссылаются ниже описанные action-ы, описаны в тех. проекте:
 * (https://docs.google.com/document/d/19fDadv_CBaeP-RlnnSvsjpdx7BCqSeax4MlCHjeEW28/edit#heading=h.lufxadcquovm).
 * Все входные параметры (payload), поступающие в sagas, совпадают с параметрами соответствующих REST-методов.
 * Все входные параметры (payload), поступающие в reducers, совпадают с ответом соответствующих REST-методов.
 */
export const DEVICES_LOAD = 'DEVICES_LOAD';
export const DEVICES_UPDATED = 'DEVICES_UPDATED';
export const DEVICE_GET_FULL = 'DEVICE_GET_FULL';
export const ACTIVE_DEVICE_UPDATED = 'ACTIVE_DEVICE_UPDATED';
export const ACTIVE_DEVICES_UPDATED = 'ACTIVE_DEVICES_UPDATED';
export const DEVICE_UPDATE_CURRENT = 'DEVICE_UPDATE_CURRENT';
export const DEVICE_CREATE = 'DEVICE_CREATE';
export const DEVICES_COPY_AND_PASTE = 'DEVICES_COPY_AND_PASTE';
export const DEVICES_COPY_AND_PASTE_ON_PLAN = 'DEVICES_COPY_AND_PASTE_ON_PLAN';
export const DEVICES_SET_CONFIGS_AND_PROPERTIES = 'DEVICES_SET_CONFIGS_AND_PROPERTIES';
export const DEVICES_MOVE = 'DEVICE_MOVE';
export const DEVICE_UPDATE_TYPE = 'DEVICE_UPDATE_TYPE';
// Обновление адреса устройства
export const DEVICE_UPDATE_ADDRESS_PATH = 'DEVICE_UPDATE_ADDRESS_PATH';
// Обновление расположения устройства на планах помещений
export const DEVICE_UPDATE_PLAN_LAYOUTS = 'DEVICE_UPDATE_PLAN_LAYOUTS';
// Обновление расположения устройств на планах помещений
export const DEVICES_UPDATE_PLAN_LAYOUTS = 'DEVICES_UPDATE_PLAN_LAYOUTS';
// Обновление привязки устройств к регионам, в том числе и отвязка
export const DEVICES_CHANGE_REGION_BINDING = 'DEVICES_CHANGE_REGION_BINDING';
// Удаление устройства из дерева устройств проекта:
export const DEVICE_DELETE = 'DEVICE_DELETE';
// Удаление устройств из дерева устройств проекта:
export const DEVICES_DELETE = 'DEVICES_DELETE';
// Изменить (передать на сервер) свойства устройства
export const DEVICE_SET_PROPERTIES = 'DEVICE_SET_PROPERTIES';
// Синхронизировать время прибора
export const DEVICE_SET_TIME = 'DEVICE_SET_TIME';
// Перезапустить прибор
export const DEVICE_RESET = 'DEVICE_RESET';
// Обновить прошивку прибора
export const DEVICE_UPDATE_FW = 'DEVICE_UPDATE_FW';
// Прочитать события прибора
export const DEVICE_READ_EVENTS = 'DEVICE_READ_EVENTS';
// Обновить устройство перетаскиваемое на план
export const DEVICE_DRAGGABLE_UPDATE = 'DEVICE_DRAGGABLE_UPDATE';
/**
 * Вкл./Откл. устройство
 *
 * payload: {
 *  projectId: <string>
 *  deviceId: <string>
 *  state: <bool>
 * }
 */
export const DEVICE_SET_DISABLED = 'DEVICE_SET_DISABLED';
/**
 * Задать прибору пароль
 *
 * payload: {
 *  projectId: <string>
 *  deviceId: <string>
 *  username: <string>
 *  password: <string>
 * }
 */
export const DEVICE_SET_CONTROL_PASSWORD = 'DEVICE_SET_CONTROL_PASSWORD';

/* Действия с устройствами активного проекта, используемые в ОЗ */

/**
 * Загрузить устройства активного проекта.
 *
 * Payload: {
 *  projectId: <string>
 * }
 *
 * Store: state.activeProject.devices
 **/
export const DEVICES_LOAD_ACTIVE = 'DEVICES_LOAD_ACTIVE';
// Изменить (передать на сервер) конфигурацию устройства
export const DEVICE_SET_CONFIG = 'DEVICE_SET_CONFIG';
// Изменить (передать на сервер) конфигурацию автивного устройства
export const ACTIVE_DEVICE_SET_CONFIG = 'ACTIVE_DEVICE_SET_CONFIG';
// Сбросить состояния устройств
export const DEVICES_RESET_STATES = 'DEVICES_RESET_STATES';
// Передать конфигурацию на устройство
export const DEVICE_UPLOAD_CONFIG = 'DEVICE_UPLOAD_CONFIG';
// Прочитать конфигурацию с устройства
export const DEVICE_DOWNLOAD_CONFIG = 'DEVICE_DOWNLOAD_CONFIG';
// Передать базу данных на контрольный прибор (панель)
export const DEVICE_UPLOAD_CONTROL_DATABASE = 'DEVICE_UPLOAD_CONTROL_DATABASE';
// Передать базу данных на все контрольные приборы (панели)
export const DEVICE_UPLOAD_ALL_CONTROL_DATABASES = 'DEVICE_UPLOAD_ALL_CONTROL_DATABASES';

/**
 * Отключение/включение опроса устройства
 *
 * Payload: {
 *  projectId: <string>
 *  deviceId: <string>
 *  state: <bool>
 * }
 **/
export const DEVICE_SET_POLLING_STATE = 'DEVICE_SET_POLLING_STATE';

// Поменять способ отображения поддерева устройств в привязке к зонам на странице Оперативной Задачи:
export const DEVICE_TREE_CHANGE_VIEW_MODE = 'DEVICE_TREE_CHANGE_VIEW_MODE';
/**
 * Обновление ссылок на устройства виртуального контейнера
 * Payload: {
 * 	projectId: <String>
 *	deviceId: <String>
 *	deviceLinks: <Array>
 * }
 **/
export const DEVICE_UPDATE_LINKS = 'DEVICE_UPDATE_LINKS';
/**
 * Добавить запись в блокноте устройства
 * Payload: {
 * 	projectId: <String>
 *	deviceId: <String>
 *	messageText: <String>
 * }
 **/
export const DEVICE_ADD_NOTE = 'DEVICE_ADD_NOTE';
/**
 * Выполнение команды управления устройством
 * Payload: {
 *  projectId: <string>,
 *  deviceId: <string>,
 *  actionId: <string>,
 *  actionParameters: {
 *     [идентификатор параметра>]: <значение параметра>,
 *     ...
 *   }
 * }
 **/
export const DEVICE_PERFORM_ACTION = 'DEVICE_PERFORM_ACTION';
// Экспорт кофигурации прибора
export const DEVICE_EXPORT_CONTOL_CONFIG = 'DEVICE_EXPORT_CONTOL_CONFIG';
// Импорт кофигурации прибора
export const DEVICE_IMPORT_CONTOL_CONFIG = 'DEVICE_IMPORT_CONTOL_CONFIG';
// Данные для сравнения конфигурации прибора
export const DEVICE_COMPARISON_CONTROL_ENTITIES = 'DEVICE_COMPARISON_CONTROL_ENTITIES';
// Установить библиотеку шейпов для устройства
export const DEVICE_SET_SHAPE_LIBRARY = 'DEVICE_SET_SHAPE_LIBRARY';
// Получить справочник профилей устройств
export const DEVICE_GET_PROFILES_DICTIONARY = 'DEVICE_GET_PROFILES_DICTIONARY';
/**
 * Обновление подсистемы устройства
 * Payload: {
 * 	projectId: <String>
 *	deviceId: <String>
 *	subsystem: <String>
 * }
 **/
export const DEVICE_UPDATE_SUBSYSTEM = 'DEVICE_UPDATE_SUBSYSTEM';
//Специфические настройки устройства
export const DEVICE_UPDATE_SPECIFIC_SETTINGS = 'DEVICE_UPDATE_SPECIFIC_SETTINGS';
// Выполнить дополнительные действия
export const DEVICE_ADDITIONAL_CONFIG_FUNCTION = 'DEVICE_ADDITIONAL_CONFIG_FUNCTION';
