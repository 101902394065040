import React from 'react';

const Popup = ({ color = "#5FA2DD", x = "9", y = "5" }) => {
  return (
    <svg width={x} height={y} viewBox={`0 0 ${x} ${y}`}>
      <path fill={color} fillRule="evenodd" d="M4.896 4.836a.563.563 0 0 1-.792 0L.166.946A.549.549 0 0 1 0 .556C0 .251.254 0 .562 0h7.876C8.745 0 9 .252 9 .555a.549.549 0 0 1-.166.391l-3.938 3.89z"/>
    </svg>
  )
}

export default Popup;