// Статусы сценария
export const SCENARIO_STATUSES = {
  NONE: 'NONE',
  STARTED: 'STARTED',
  LOCKED: 'LOCKED',
  DELAY_ACTIVE: 'DELAY_ACTIVE',
  DELAY_PAUSED: 'DELAY_PAUSED',
  EXECUTING: 'EXECUTING'
};

// Действия сценариев
export const SCENARIO_ACTIONS = {
  START: 'START',
  STOP: 'STOP',
  LOCK: 'LOCK',
  UNLOCK: 'UNLOCK',
  IMMEDIATE_START: 'IMMEDIATE_START',
  PAUSE_DELAY: 'PAUSE_DELAY',
  RESUME_DELAY: 'RESUME_DELAY',
  INCREASE_DELAY: 'INCREASE_DELAY'
};
