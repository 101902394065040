import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Tooltip, Dropdown, Menu as DropdownMenu } from 'antd';

import { getCurrentProjectRegions } from 'helpers/currentProject';

import Modal from 'containers/Modal';
import RegionsSelect from 'containers/Selects/RegionsSelect';

import { ItemLayoutWithLabel, ItemLayoutWithoutLabel } from 'components/Form';
import { FormItem } from 'components';
import { SettingTwoTone, PlusCircleTwoTone } from '@ant-design/icons';
import i18next from 'i18next';

const REGION_SELECT_STYLES = {
  width: 200,
  maxWidth: 300
};

class UpdateDeviceRegionForm extends React.Component {
  static propTypes = {
    regions: PropTypes.array,
    modalName: PropTypes.string,
    onSubmit: PropTypes.func
  };

  state = {
    selectedRegionId: '',
    regions: null,
    value: undefined
  };

  CreateButton = props => {
    return (
      <Tooltip title={i18next.t('zones.createNewZone')}>
        <Button
          {...props}
          type="link"
          icon={<PlusCircleTwoTone style={{ fontSize: '18pt' }} twoToneColor="#52c41a" />}
        />
      </Tooltip>
    );
  };

  resetSelectedRegion = () => {
    const { onClose } = this.props;
    this.setState({ selectedRegionId: '' });
    onClose && onClose();
  };

  selectRegion = (id, force = false) => {
    if (this.state.selectedRegionId && !force) return;
    this.setState({ selectedRegionId: id });
  };

  selectRegionByRegionIndex = index => {
    const region = this.props.regions.find(r => r.index === index);
    if (region) this.setState({ selectedRegionId: region.id });
  };

  handleSubmit = () => {
    const { onSubmit, isEditorForm } = this.props;
    const { selectedRegionId } = this.state;
    onSubmit(selectedRegionId, isEditorForm);
    this.resetSelectedRegion();
  };

  renderCreateSubmenu(submenu) {
    if (!submenu) return null;
    const CreateButton = this.CreateButton;
    return (
      <Dropdown
        overlay={
          <DropdownMenu>
            {submenu.map((subItem, idx) => (
              <DropdownMenu.Item key={idx} disabled={!subItem.isActive} onClick={subItem.onClick}>
                {subItem.title}
              </DropdownMenu.Item>
            ))}
          </DropdownMenu>
        }
        trigger={['click']}
        placement="bottomLeft"
      >
        <CreateButton />
      </Dropdown>
    );
  }

  renderCreateButton() {
    const CreateButton = this.CreateButton;
    return <CreateButton onClick={this.props.onNewRegionButtonClick} />;
  }

  handleSearch = value => {
    let regions = null;
    if (value) {
      regions = this.props.regions.filter(region =>
        region.name.toLowerCase().includes(value.toLowerCase())
      );
    }
    this.setState({ regions });
  };

  render = () => {
    const {
      modalName,
      regionInfo,
      isEditorForm,
      onEditRegionButtonClick,
      createSubmenu
    } = this.props;
    const { selectedRegionId, regions } = this.state;
    const isAnyRegion = this.props.regions?.length || regions?.length || false;
    return (
      <Modal
        width={550}
        name={modalName}
        title={isEditorForm ? i18next.t('zones.replaceZone') : i18next.t('zones.bindZone')}
        onClose={this.resetSelectedRegion}
      >
        <FormItem {...ItemLayoutWithLabel} label={i18next.t('zones.selectZone')}>
          <RegionsSelect
            value={selectedRegionId}
            style={REGION_SELECT_STYLES}
            regions={regions ? regions : this.props.regions}
            placeholder={i18next.t('zones.selectZone')}
            onChange={regionId => this.setState({ selectedRegionId: regionId })}
            filterOption={false}
            onSearch={this.handleSearch}
            showArrow={isAnyRegion}
            showSearch={isAnyRegion}
          />
          <Tooltip title={i18next.t('zones.changeZoneParameters')}>
            <Button
              hidden={!selectedRegionId}
              shape="circle"
              type="link"
              icon={<SettingTwoTone twoToneColor="#ffab00" style={{ fontSize: '18pt' }} />}
              onClick={() => onEditRegionButtonClick(selectedRegionId)}
            />
          </Tooltip>
          {createSubmenu && createSubmenu.length
            ? this.renderCreateSubmenu(createSubmenu)
            : this.renderCreateButton()}
        </FormItem>
        <FormItem {...ItemLayoutWithoutLabel}>
          <Button
            type="primary"
            disabled={
              !selectedRegionId ||
              (isEditorForm && selectedRegionId && selectedRegionId === regionInfo.regionId)
            }
            onClick={this.handleSubmit}
          >
            {isEditorForm ? i18next.t('buttons.replace') : i18next.t('buttons.bind')}
          </Button>
        </FormItem>
      </Modal>
    );
  };
}

const mapStateToProps = state => ({
  regions: getCurrentProjectRegions(state)
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(UpdateDeviceRegionForm);
