import { combineReducers } from 'redux';

import users from './admin/users';
import userGroups from './admin/userGroups';
import permissions from './admin/permissions';
import isLocalHostUser from './admin/isLocalHostUser';

export default combineReducers({
  users,
  userGroups,
  permissions,
  isLocalHostUser
});
