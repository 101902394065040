import React, { useState } from 'react';
import Draggable from 'react-draggable';
import styles from './styles.module.css';

export const TreeHeader = ({
  onSizeChanged,
  columns,
  sizes,
  height,
  minColumnWidth,
  innerRef,
  minRowWidth
}) => {
  const [draggingHeader, setDraggingHeader] = useState(null);
  const [draggingRange, setDraggingRange] = useState(0);
  return (
    <div
      ref={innerRef ? innerRef : null}
      className={styles.columns_header}
      style={{
        minWidth: `${minRowWidth ? minRowWidth + 'px' : 'unset'}`
      }}
    >
      {columns.map((column, index) => {
        const colWidth = sizes ? sizes[column.key].width : column.width || minColumnWidth;
        const isShortTitle = colWidth <= (column.minWidth || minColumnWidth);
        return (
          <div
            key={`head_cell_${column.dataIndex}`}
            style={{
              width: `${colWidth}px`,
              position: 'relative',
              textAlign: 'center',
              borderRight: '1px solid #e8e8e8'
            }}
          >
            <div className={styles.columns_header_cell}>
              <Title
                popup={isShortTitle ? column.title : ''}
                title={isShortTitle ? '...' : column.title}
              />
            </div>
            {index !== columns.length - 1 && (
              <Draggable
                axis="x"
                onDrag={(e, { deltaX }) => {
                  if (!draggingHeader) setDraggingHeader(column.key);
                  const resultDiff = draggingRange + deltaX;
                  const currentColumnWidth = sizes ? sizes[column.key].width : column.width;
                  const nextColumnWidth = sizes
                    ? sizes[columns[index + 1].key].width
                    : columns[index + 1].width;
                  if (currentColumnWidth + resultDiff < minColumnWidth) return;
                  if (nextColumnWidth - resultDiff < minColumnWidth) return;
                  setDraggingRange(prevRange => prevRange + deltaX);
                }}
                onStop={() => {
                  onSizeChanged &&
                    onSizeChanged(
                      column.key,
                      columns[index + 1].key,
                      draggingRange,
                      sizes ? sizes[column.key].width : column.width,
                      sizes ? sizes[columns[index + 1].key].width : columns[index + 1].width
                    );
                  setDraggingHeader(null);
                  setDraggingRange(0);
                }}
                position={{ x: 0 }}
              >
                <span
                  style={{
                    zIndex: 5,
                    height: height ? `${height}px` : '100%',
                    right: `${-3 - draggingRange}px`,
                    width: `${draggingHeader === column.key ? '4px' : ''}`,
                    backgroundColor: `${draggingHeader === column.key ? '#f6f2f2' : ''}`,
                    boxShadow: `${draggingHeader === column.key ? '0px 0px 4px 1px #e8e8e8' : ''}`
                  }}
                  className={styles.column_dragger}
                />
              </Draggable>
            )}
          </div>
        );
      })}
    </div>
  );
};

const Title = ({ title, popup }) =>
  typeof title === 'string' ? (
    <span title={popup} style={{ verticalAlign: 'super' }}>
      {title}
    </span>
  ) : (
    <span style={{ color: '#0000ff00', verticalAlign: 'super' }}>{'_'}</span>
  );
