import {
  LICENSE_LOAD_PERMISSIONS,
  LICENSE_UPDATE_PERMISSIONS,
  isLoaded
} from 'constants/actionTypes';

export default function license(state = null, { type, payload }) {
  switch (type) {
    case LICENSE_UPDATE_PERMISSIONS:
    case isLoaded(LICENSE_LOAD_PERMISSIONS, true): {
      const permissions = payload;
      return { ...permissions };
    }
    default:
      return state;
  }
}
