import styled from 'styled-components';

const UserTimeWrapper = styled.div`
  display: flex;
  align-items: center;
  color: white;
`;
const UserTimeElement = styled.div`
  margin-left: 6px;
  margin-right: 2px;
  line-height: 1;
  text-align: center;
`;

export { UserTimeWrapper, UserTimeElement };
