import {
  SCENARIO_CONSTANTS_LOAD,
  SCENARIOS_LOAD,
  SCENARIO_CREATE,
  SCENARIO_UPDATE_BASIC_PARAMS,
  SCENARIO_UPDATE_ADVANCED_PARAMS,
  SCENARIO_DELETE,
  SCENARIO_ADD_TL_BLOCK,
  SCENARIO_UPDATE_TL_BLOCK,
  SCENARIO_REMOVE_TL_BLOCK,
  SCENARIO_UPDATE_START_LOGIC,
  SCENARIO_UPDATE_STOP_LOGIC,
  SCENARIO_SELECT,
  SCENARIO_SELECT_TL_BLOCK,
  SCENARIO_SELECT_SUB_LOGIC,
  PERFORM_SCENARIO_ACTION,
  SCENARIO_START_TIMER,
  SCENARIO_GET_FULL
} from 'constants/actionTypes';

import { createAction } from 'redux-actions';

export const loadScenarioConstants = createAction(SCENARIO_CONSTANTS_LOAD);

export const loadScenarios = createAction(SCENARIOS_LOAD, projectId => projectId);

export const getFullScenario = createAction(SCENARIO_GET_FULL, (projectId, scenarioId) => ({
  projectId,
  scenarioId
}));

export const createScenario = createAction(SCENARIO_CREATE, (projectId, scenarioBasicParams) => ({
  projectId,
  scenarioBasicParams
}));
export const updateScenarioBasics = createAction(
  SCENARIO_UPDATE_BASIC_PARAMS,
  (projectId, scenarioId, scenarioBasicParams) => ({ projectId, scenarioId, scenarioBasicParams })
);
export const updateScenarioAdvanced = createAction(
  SCENARIO_UPDATE_ADVANCED_PARAMS,
  (projectId, scenarioId, scenarioAdvancedParams) => ({
    projectId,
    scenarioId,
    scenarioAdvancedParams
  })
);
export const deleteScenario = createAction(SCENARIO_DELETE, (projectId, scenarioId) => ({
  projectId,
  scenarioId
}));

export const addScenarioTimeLineBlock = createAction(
  SCENARIO_ADD_TL_BLOCK,
  (projectId, scenarioId, timeLineBlock) => ({ projectId, scenarioId, timeLineBlock })
);

export const updateScenarioTimeLineBlock = createAction(
  SCENARIO_UPDATE_TL_BLOCK,
  (projectId, scenarioId, timeLineBlockNo, timeLineBlock) => ({
    projectId,
    scenarioId,
    timeLineBlockNo,
    timeLineBlock
  })
);

export const removeScenarioTimeLineBlock = createAction(
  SCENARIO_REMOVE_TL_BLOCK,
  (projectId, scenarioId, timeLineBlockNo) => ({ projectId, scenarioId, timeLineBlockNo })
);

export const updateScenarioStartLogic = createAction(
  SCENARIO_UPDATE_START_LOGIC,
  (projectId, scenarioId, logicBlock) => ({
    projectId,
    scenarioId,
    logicBlock
  })
);

export const updateScenarioStopLogic = createAction(
  SCENARIO_UPDATE_STOP_LOGIC,
  (projectId, scenarioId, logicBlock) => ({
    projectId,
    scenarioId,
    logicBlock
  })
);

export const selectScenario = createAction(SCENARIO_SELECT, scenarioId => scenarioId);

export const selectScenarioTimeLineBlock = createAction(
  SCENARIO_SELECT_TL_BLOCK,
  timeLineBlockNo => timeLineBlockNo
);

export const selectScenarioSubLogic = createAction(
  SCENARIO_SELECT_SUB_LOGIC,
  subLogicKey => subLogicKey
);

export const performScenarioAction = createAction(
  PERFORM_SCENARIO_ACTION,
  (projectId, scenarioNo, actionId, password) => ({
    projectId,
    scenarioNo,
    actionId,
    password
  })
);

export const startScenarioTimer = createAction(
  SCENARIO_START_TIMER,
  computerAction => computerAction
);
