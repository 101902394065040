import {
  PROJECT_LOAD_ACTIVE,
  PROJECT_UPDATE_STATUS,
  PLAN_GROUPS_LOAD_ACTIVE,
  PROJECT_LOAD_CURRENT,
  isLoaded
} from 'constants/actionTypes';

export default function planGroups(state = null, action) {
  switch (action.type) {
    case isLoaded(PLAN_GROUPS_LOAD_ACTIVE, true): {
      const planGroups = action.payload;
      const newState = {};
      planGroups.forEach(planGroup => {
        newState[planGroup.id] = planGroup;
      });
      return newState;
    }
    case isLoaded(PROJECT_LOAD_ACTIVE, true): {
      const { planGroups } = action.payload;
      const newState = {};
      planGroups.forEach(planGroup => {
        newState[planGroup.id] = planGroup;
      });
      return newState;
    }
    case isLoaded(PROJECT_LOAD_CURRENT, true): {
      const { project, planGroups } = action.payload;
      if (project.status === 'ACTIVE') {
        const newState = {};
        planGroups.forEach(planGroup => {
          newState[planGroup.id] = planGroup;
        });
        return newState;
      } else {
        return state;
      }
    }
    case isLoaded(PROJECT_UPDATE_STATUS, true): {
      const { project, planGroups } = action.payload;
      if (project.status === 'ACTIVE') {
        const newState = {};
        planGroups.forEach(planGroup => {
          newState[planGroup.id] = planGroup;
        });
        return newState;
      } else {
        return null;
      }
    }
    default:
      return state;
  }
}
