import React, { Component } from 'react';
import * as s from './styles';

import Login from 'containers/Forms/Login';

export default class LoginPage extends Component {
  render() {
    return (
      <s.LoginContainer>
        <Login/>
      </s.LoginContainer>
    );
  }
}
