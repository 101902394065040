import { combineReducers } from 'redux';

import {
  ACTIVE_SCENARIOS_SET_FILTER,
  ADD_FILTER,
  DEVICE_DRAGGABLE_UPDATE,
  isLoaded,
  REGION_CREATE,
  REGION_DELETE,
  REGION_UPDATE,
  SELECT_REGIONS,
  REGIONS_CHANGE_FILTER,
  SCENARIO_DELETE,
  SCENARIO_REMOVE_TL_BLOCK,
  SCENARIO_SELECT,
  SCENARIO_SELECT_SUB_LOGIC,
  SCENARIO_SELECT_TL_BLOCK,
  SET_FILTERS,
  UPDATED_ENTITIES,
  USER_DELETE,
  USER_GROUP_DELETE,
  USER_GROUP_SELECT,
  USER_SELECT,
  VIRTUAL_STATE_DELETE,
  VIRTUAL_STATE_SELECT,
  SET_TIMESTAMP,
  VIRTUAL_COUNTER_SELECT,
  VIRTUAL_COUNTER_DELETE,
  VIRTUAL_COUNTERS_DELETE,
  VIRTUAL_STATES_DELETE,
  SCENARIO_CREATE
} from 'constants/actionTypes';

import deviceTree from './widgets/deviceTree';
import projects from './widgets/projects';
import archiveEventsWidget from './widgets/archiveEventsWidget';
import deviceShapeLibrary from './widgets/deviceShapeLibrary';
import selectedLogView from './widgets/selectedLogView';
import selectedIndicatorGroupId from './widgets/selectedIndicatorGroupId';
import selectedIndicatorPanelId from './widgets/selectedIndicatorPanelId';
import currentDevice from './widgets/currentDevice';
import selectedIndicator from './widgets/selectedIndicator';
import currentEntityInfo from './widgets/currentEntityInfo';
import skudTree from './widgets/skudTree';
import workSchedulesTree from './widgets/workSchedulesTree';
import selectedSound from './widgets/selectedSound';
import indicatorsPanel from './widgets/indicatorsPanelWidget';
import controlPanel from './widgets/controlPanel';
import selectedSoundNotificationId from './widgets/selectedSoundNotificationId';
import { ALL_SUBSYSTEMS } from 'constants/subsystems';
import { PROJECT_SET_CURRENT } from 'constants/actionTypeModules/projects';

/** Редюсер хранит зону без отображения. */
function selectedRegionIds(state = [], { type, payload }) {
  switch (type) {
    case PROJECT_SET_CURRENT:
    case isLoaded(REGION_DELETE, true): {
      return [];
    }
    case SELECT_REGIONS: {
      return [...payload];
    }
    case isLoaded(REGION_CREATE, true): {
      const { newRegions } = payload;
      if (!newRegions[0]) return state;
      return [newRegions[0].region.id];
    }
    case isLoaded(REGION_UPDATE, true): {
      const { updatedRegion } = payload;
      if (updatedRegion.id === state.id) return [updatedRegion.region.id];
      else return state;
    }
    default:
      return state;
  }
}

/* regionsFilter содержит либо id подсистемы, либо id типа определения состояния пожар, либо ALL.  */
function regionsFilter(state = ALL_SUBSYSTEMS, { type, payload }) {
  switch (type) {
    case REGIONS_CHANGE_FILTER: {
      return payload;
    }
    default: {
      return state;
    }
  }
}

function currentScenarioId(state = null, action) {
  switch (action.type) {
    case isLoaded(SCENARIO_CREATE, true): {
      return action.payload.scenario.id;
    }
    case SCENARIO_SELECT: {
      const scenarioId = (' ' + action.payload).slice(1);
      return scenarioId;
    }
    case isLoaded(SCENARIO_DELETE, true): {
      /* Сбрасываем выделение при удалении сценария */
      return null;
    }
    case ACTIVE_SCENARIOS_SET_FILTER: {
      return null;
    }
    default:
      return state;
  }
}

function currentScenarioTLBlockNo(state = -1, action) {
  const timeLineBlockNo = action.payload;
  switch (action.type) {
    case SCENARIO_SELECT_TL_BLOCK: {
      return timeLineBlockNo;
    }
    case isLoaded(SCENARIO_REMOVE_TL_BLOCK, true):
    case isLoaded(SCENARIO_DELETE, true): {
      /* Сбрасываем выделение при удалении сценария или блока времени */
      return -1;
    }
    case SCENARIO_SELECT: {
      /* Сбрасываем выделение при переключение сценария */
      return -1;
    }
    case ACTIVE_SCENARIOS_SET_FILTER:
    case '@@router/LOCATION_CHANGE': {
      /* Сбрасываем выделение при смене странички */
      return -1;
    }

    default:
      return state;
  }
}

function currentSubLogicKey(state = 'root', action) {
  switch (action.type) {
    case SCENARIO_SELECT_SUB_LOGIC: {
      const subLogicKey = (' ' + action.payload).slice(1);
      return subLogicKey;
    }

    /* Сбрасываем выделение при смене странички, переключение сценария или удалении */
    case '@@router/LOCATION_CHANGE':
    case SCENARIO_SELECT:
    case isLoaded(SCENARIO_DELETE, true): {
      return 'root';
    }

    default:
      return state;
  }
}

function selectedVirtualStateIds(state = null, action) {
  switch (action.type) {
    case VIRTUAL_STATE_SELECT: {
      return [...action.payload];
    }
    case isLoaded(VIRTUAL_STATE_DELETE, true): {
      return null;
    }
    case isLoaded(VIRTUAL_STATES_DELETE, true): {
      return null;
    }

    default:
      return state;
  }
}

function selectedVirtualCounterIds(state = null, action) {
  switch (action.type) {
    case VIRTUAL_COUNTER_SELECT: {
      return [...action.payload];
    }
    case isLoaded(VIRTUAL_COUNTER_DELETE, true): {
      return null;
    }
    case isLoaded(VIRTUAL_COUNTERS_DELETE, true): {
      return null;
    }
    default:
      return state;
  }
}

function selectedUserId(state = null, action) {
  switch (action.type) {
    case USER_SELECT: {
      const userId = action.payload + '';
      return userId;
    }
    case isLoaded(USER_DELETE, true): {
      return null;
    }

    case UPDATED_ENTITIES: {
      const updatedUsers = action.payload.users;
      if (updatedUsers && updatedUsers.length) return updatedUsers[0].id;
      else return state;
    }

    default:
      return state;
  }
}

function selectedUserGroupId(state = null, action) {
  switch (action.type) {
    case USER_GROUP_SELECT: {
      const userGroupId = action.payload + '';
      return userGroupId;
    }
    case isLoaded(USER_GROUP_DELETE, true): {
      return null;
    }

    case UPDATED_ENTITIES: {
      const userGroupsData = action.payload.userGroups;
      if (userGroupsData && userGroupsData.created && userGroupsData.created.length)
        return userGroupsData.created[0].id;
      else return state;
    }

    default:
      return state;
  }
}

function draggableDeviceId(state = null, action) {
  switch (action.type) {
    case DEVICE_DRAGGABLE_UPDATE: {
      return action.payload;
    }
    default:
      return state;
  }
}

function activeScenariosFilter(state = 'ALL', { type, payload }) {
  switch (type) {
    case ACTIVE_SCENARIOS_SET_FILTER: {
      return payload;
    }
    default:
      return state;
  }
}

/**
 * @param state - Map<String, Array>, где
 *   key - Название фильтра, value - массив с идентификаторами для фильтрации
 * @param action - текущий экшен
 * @returns {Map<String, Array>} - {@see state}
 */
function filters(state = new Map(), action) {
  switch (action.type) {
    case SET_FILTERS: {
      return new Map(action.payload);
    }
    case ADD_FILTER: {
      const newState = new Map(state);
      newState.set(action.payload.name, action.payload.values);
      return newState;
    }
    default:
      return state;
  }
}

/**
 * @param state - {Map<String, Number>}, где
 *   key - название, value - значение времени
 * @param action - текущий экшн
 * @returns {Map<String, Number>} - {@see state}
 */
function timestamps(state = new Map(), action) {
  switch (action.type) {
    case SET_TIMESTAMP: {
      const { name, value } = action.payload;
      const newState = new Map(state);
      newState.set(name, value);
      return newState;
    }
    default:
      return state;
  }
}

export default combineReducers({
  projects,
  currentDevice,
  deviceTree,
  selectedRegionIds,
  regionsFilter,
  currentScenarioId,
  currentScenarioTLBlockNo,
  currentSubLogicKey,
  selectedVirtualStateIds,
  selectedVirtualCounterIds,
  archiveEventsWidget,
  selectedUserId,
  selectedUserGroupId,
  draggableDeviceId,
  deviceShapeLibrary,
  activeScenariosFilter,
  selectedLogView,
  currentEntityInfo,
  selectedIndicatorGroupId,
  selectedIndicatorPanelId,
  selectedIndicator,
  selectedSound,
  selectedSoundNotificationId,
  skudTree,
  workSchedulesTree,
  indicatorsPanel,
  controlPanel,
  filters,
  timestamps
});
