export * from './actionTypeModules/devices';
export * from './actionTypeModules/deviceShapes';
export * from './actionTypeModules/events';
export * from './actionTypeModules/indicators';
export * from './actionTypeModules/issues';
export * from './actionTypeModules/logViews';
export * from './actionTypeModules/medias';
export * from './actionTypeModules/plans';
export * from './actionTypeModules/projects';
export * from './actionTypeModules/regions';
export * from './actionTypeModules/scenarios';
export * from './actionTypeModules/users';
export * from './actionTypeModules/virtualStates';
export * from './actionTypeModules/virtualCounters';
export * from './actionTypeModules/skud';
export * from './actionTypeModules/panelIndicators';
export * from './actionTypeModules/controlPanelUsers';
export * from './actionTypeModules/other';
export * from './actionTypeModules/countdowns';
export * from './actionTypeModules/CPIU';

export const isLoaded = (constant, type) => {
  if (type) return constant + '_SUCCEEDED';
  else return constant + '_FAILED';
};
