import { takeEvery } from 'redux-saga/effects';

import {
  STATE_CATEGORY_VIEWS_LOAD, STATES_LOAD, STATE_VIEWS_LOAD,
} from 'constants/actionTypes';

import { fetch, dispatchSuccess, dispatchFail } from 'helpers/request';

function* loadStateCategories(action) {
  try {
    const response = yield fetch(`/states/categories`);

    yield dispatchSuccess(STATE_CATEGORY_VIEWS_LOAD, response );
  } catch (error) {
    yield dispatchFail(STATE_CATEGORY_VIEWS_LOAD, error.message);
  }
}

function* loadStates(action) {
  try {
    const response = yield fetch(`/states`);

    yield dispatchSuccess(STATES_LOAD, response );
  } catch (error) {
    yield dispatchFail(STATES_LOAD, error.message);
  }
}

function* loadStateViews(action) {
  try {
    const response = yield fetch(`/states/views`);

    yield dispatchSuccess(STATE_VIEWS_LOAD, response );
  } catch (error) {
    yield dispatchFail(STATE_VIEWS_LOAD, error.message);
  }
}

export default function* states() {
  yield takeEvery(STATE_CATEGORY_VIEWS_LOAD, loadStateCategories);
  yield takeEvery(STATES_LOAD, loadStates);
  yield takeEvery(STATE_VIEWS_LOAD, loadStateViews);
}
