import { takeEvery, select } from 'redux-saga/effects';

import {
  EVENTS_LOAD_LOG,
  EVENTS_LOAD_ARCHIVE,
  EVENTS_LOAD_TYPE_VIEWS
} from 'constants/actionTypes';
import { fetch, dispatchSuccess, dispatchFail } from 'helpers/request';
import { getCurrentUser } from 'helpers/user';

function* loadLogEvents(action) {
  const { pageSize, pageNo, sort, filters, logViewId } = action.payload;

  try {
    let filterParams = '';
    if (filters) Object.keys(filters).forEach(key => (filterParams += `&${key}=${filters[key]}`));
    // Добавляем метки фильтрации
    const currentUser = yield select(getCurrentUser);
    if (currentUser && currentUser.filterTagsOn) {
      filterParams += `&tags=${currentUser.filterTags.tags.join(',')}`;
    }

    const { error, content } = yield fetch(
      `/events?size=${pageSize || 1000}&page=${pageNo || 0}&sort=${sort ||
        'id,desc'}${filterParams}`
    );
    if (error) throw new Error(error);
    yield dispatchSuccess(EVENTS_LOAD_LOG, { logViewId, events: content });
  } catch (error) {
    yield dispatchFail(EVENTS_LOAD_LOG, error.message);
  }
}

function* loadArchiveEvents(action) {
  const { pageSize, pageNo, sort, filters, requestCountForced } = action.payload;

  try {
    let filterParams = '';
    if (filters) Object.keys(filters).forEach(key => (filterParams += `&${key}=${filters[key]}`));
    // Добавляем метки фильтрации
    const currentUser = yield select(getCurrentUser);
    if (currentUser && currentUser.filterTagsOn) {
      filterParams += `&tags=${currentUser.filterTags.tags.join(',')}`;
    }

    /**
     * У нас всегда на этот запрос будет успешный ответ (если ответит),
     * потому что мы просто возвращаем результат поиска в БД.
     * Ошибка будет в том случае, если в объекте Page будет ошибки от монго
     */
    const { error, content } = yield fetch(
      `/events?size=${pageSize}&page=${pageNo}${sort ? `&sort=${sort}` : ''}${filterParams}`
    );
    if (error) throw new Error(error);

    /* Запросим общее кол-во событий, если запрашивается первая страница архива */
    if (pageNo === 0 || requestCountForced) {
      const { error, eventsCount } = yield fetch(`/events/count?${filterParams}`);
      if (error) throw new Error(error);

      yield dispatchSuccess(EVENTS_LOAD_ARCHIVE, {
        events: content,
        totalEventsCount: eventsCount
      });
    } else {
      yield dispatchSuccess(EVENTS_LOAD_ARCHIVE, { events: content });
    }
  } catch (error) {
    yield dispatchFail(EVENTS_LOAD_ARCHIVE, error.message);
  }
}

function* loadEventTypeViews(action) {
  try {
    const response = yield fetch('/event_type_views');
    yield dispatchSuccess(EVENTS_LOAD_TYPE_VIEWS, response);
  } catch (error) {
    yield dispatchFail(EVENTS_LOAD_TYPE_VIEWS, error.message);
  }
}

export default function* projects() {
  yield takeEvery(EVENTS_LOAD_LOG, loadLogEvents);
  yield takeEvery(EVENTS_LOAD_ARCHIVE, loadArchiveEvents);
  yield takeEvery(EVENTS_LOAD_TYPE_VIEWS, loadEventTypeViews);
}
