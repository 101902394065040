import React from 'react';

import { Input as AntdInput, InputNumber as InputNumberAntd } from 'antd';

function TextArea(props) {
  let { input, value, onChange, allowClear, ...restProps } = props;
  if (input) {
    if (input.onChange) onChange = input.onChange;
    if (input.value !== undefined) value = input.value;
  }
  return (
    <AntdInput.TextArea
      onChange={onChange}
      value={value}
      autoSize={{ minRows: 2, maxRows: 8 }}
      allowClear={allowClear !== false}
      {...restProps}
    />
  );
}

function Password(props) {
  let { input, value, onChange, ...restProps } = props;
  if (input) {
    if (input.onChange) onChange = input.onChange;
    if (input.value !== undefined) value = input.value;
  }
  return <AntdInput.Password onChange={onChange} value={value} {...restProps} />;
}

export function InputNumber(props) {
  let { input, value, onChange, ...restProps } = props;
  if (input) {
    if (input.onChange) onChange = input.onChange;
    if (input.value !== undefined) value = input.value;
  }
  return <InputNumberAntd onChange={onChange} value={value} {...restProps} />;
}

Input.Number = InputNumber;
Input.Password = Password;
Input.TextArea = TextArea;

export default function Input(props) {
  let { input, value, onChange, meta, ...restProps } = props;
  if (input) {
    if (input.onChange) onChange = input.onChange;
    if (input.value !== undefined) value = input.value;
  }
  return <AntdInput onChange={onChange} value={value} {...restProps} />;
}
