import React, { PureComponent } from 'react';
import { Button, Progress, Row } from 'antd';
import {
  ClockCircleTwoTone,
  PauseCircleTwoTone,
  ExclamationCircleOutlined,
  CloseOutlined
} from '@ant-design/icons';
import { notification, Modal, Tooltip } from 'components';
import PropTypes from 'prop-types';
import { PicRightOutlined } from '@ant-design/icons';
import styles from './Timers.module.css';
import i18next from 'i18next';
import moment from 'moment';

export const COUNTDOWN_STATES = {
  SCHEDULED: 'SCHEDULED',
  STARTED: 'STARTED',
  CANCELLED: 'CANCELLED',
  POSTPONED: 'POSTPONED',
  RESUMED: 'RESUMED',
  INCREASED: 'INCREASED'
};

export const COUNTDOWN_TYPES = {
  DEVICE: 'DEVICE',
  COMPUTER_ACTION: 'COMPUTER_ACTION',
  SCENARIO: 'SCENARIO'
};

class Countdowns extends PureComponent {
  static propTypes = {
    visibleCountdowns: PropTypes.object,
    onClose: PropTypes.func,
    onFinish: PropTypes.func
  };

  static defaultProps = {
    visibleCountdowns: {},
    onClose: () => {},
    onFinish: () => {}
  };

  openedCountdowns = new Set();

  getColor(percent) {
    let color;
    switch (Math.floor(percent / 10)) {
      case 0:
        color = '#ff5722';
        break;
      case 1:
        color = '#e65100';
        break;
      case 2:
        color = '#f57f17';
        break;
      case 3:
        color = '#fbc02d';
        break;
      case 4:
        color = '#fdd835';
        break;
      case 5:
      case 6:
      case 7:
        color = '#69dd18';
        break;
      case 8:
        color = '#64dd17';
        break;
      default:
        color = '#00c853';
    }
    return color;
  }

  Description = ({ countdown, time, isPause, color }) => {
    const { cpiuSpecialData, handleModeScenariosByIdMap, isLocalHostUser } = this.props;
    const isHandleModeScenario =
      cpiuSpecialData &&
      countdown.entityId &&
      countdown.entityType === cpiuSpecialData.entityType &&
      countdown.entityId !== cpiuSpecialData.entityId &&
      handleModeScenariosByIdMap?.[countdown.entityId];
    return (
      <div>
        <Progress
          strokeColor={color}
          percent={time ? time / (countdown.delayStart / 100) : 100}
          width={40}
          style={{ width: '90%' }}
          format={() =>
            time ? (
              <b>{moment.utc(time * 1000).format('HH:mm:ss')}</b>
            ) : countdown.delay ? (
              <b>{moment.utc(countdown.delay * 1000).format('HH:mm:ss')}</b>
            ) : (
              ''
            )
          }
          success={{ percent: 1 }}
          status={isPause ? 'exception' : 'active'}
        />
        <strong>{this.props.getCountdownName(countdown)}</strong>
        <CloseOutlined
          id={countdown.key}
          key={countdown.key}
          className={styles.close_icon}
          onClick={e => {
            if (this.isModalConfirmOpen) {
              e.preventDefault();
              e.stopPropagation();
              return;
            }
            this.closeNotification(countdown, true);
          }}
        />
        {isLocalHostUser
          ? isHandleModeScenario
            ? this.getSentScenarioToCPIUButton(countdown)
            : this.getCountdownActions(countdown)
          : null}
      </div>
    );
  };

  getSentScenarioToCPIUButton = countdown => {
    const { onSentScenarioToCPIU } = this.props;
    return (
      <Row align="center" justify="center">
        <Button
          type="primary"
          className={styles.cpiu_button}
          onClick={() => {
            onSentScenarioToCPIU(countdown.entityId);
            notification.close(countdown.id);
          }}
        >
          <span className={styles.button_name_container}>
            <PicRightOutlined />
            {i18next.t('CPIU.manageTheScenario')}
          </span>
        </Button>
      </Row>
    );
  };

  getCountdownActions = countdown => {
    const { onActionClick, getIconByActionId, isDisabledAction } = this.props;
    const { actions } = countdown;
    if (!actions) return null;
    const newActions = [];
    for (const action of actions) {
      const { title, actionId } = action;
      newActions.push(
        <Tooltip placement="bottom" title={title}>
          <Button
            disabled={isDisabledAction(countdown, actionId)}
            onClick={() => onActionClick(countdown, actionId)}
            icon={getIconByActionId(actionId)}
          />
        </Tooltip>
      );
    }
    return <div>{newActions}</div>;
  };

  componentDidMount() {
    this.controlCountdowns();
  }

  openModalConfirm(success) {
    this.isModalConfirmOpen = true;
    Modal.confirm({
      title: i18next.t('confirmation'),
      icon: <ExclamationCircleOutlined />,
      content: i18next.t('questions.closeCountdown'),
      okText: i18next.t('yes'),
      cancelText: i18next.t('no'),
      onOk: success,
      onCancel: () => (this.isModalConfirmOpen = false)
    });
  }

  closeNotification = (countdown, byClick = false, isAutoClose = false) => {
    const { onClose, onDeleteCountDown } = this.props;

    const callback = () => {
      this.isModalConfirmOpen = false;
      if (countdown.id === 'example') {
        onDeleteCountDown(countdown.id);
      } else if (!isAutoClose) onClose(countdown.id);
      notification.close(countdown.id);
      this.openedCountdowns.delete(countdown.id);
    };

    if (byClick && countdown.needClosingConfirmation) {
      this.openModalConfirm(callback);
    } else callback();
  };

  /**
   * HACK: Из-за того что нет возможности использовать текущий onClose (в нем нет объекта Event)
   * была реализована кастомная кнопка закрытия уведомления
   * @see Description
   */
  openNotification = (message, description, key, color, duration, isPause) => {
    const Icon = isPause ? PauseCircleTwoTone : ClockCircleTwoTone;
    const args = {
      className: styles.notification_timer,
      icon: <Icon twoToneColor={color} />,
      key,
      message: <b>{message}</b>,
      description,
      duration: isPause ? 0 : duration
    };
    notification.open(args);
    this.openedCountdowns.add(key);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.closeNotificationsIfNeed(prevProps);
    this.controlCountdowns();
  }

  closeNotificationsIfNeed(prevProps) {
    const { visibleCountdowns, cpiuSpecialData } = this.props;
    if (
      Object.keys(this.props.visibleCountdowns).length !==
      Object.keys(prevProps.visibleCountdowns).length
    )
      for (const countdown of Object.values(prevProps.visibleCountdowns)) {
        if (!this.props.visibleCountdowns[countdown.entityId]) {
          this.closeNotification(countdown);
        }
      }

    if (cpiuSpecialData?.entityId && this.openedCountdowns.size) {
      const countdown = visibleCountdowns[cpiuSpecialData.entityId];
      if (this.openedCountdowns.has(countdown.id)) this.closeNotification(countdown, false, true);
    }
  }

  isCountdownVisible = (countdown, cpiuSpecialData, hiddenCountdownsList) => {
    if (
      countdown.activeDelay > 0 &&
      countdown.entityId &&
      !hiddenCountdownsList.includes(countdown.id) &&
      !(
        cpiuSpecialData &&
        countdown.entityId === cpiuSpecialData.entityId &&
        countdown.entityType === cpiuSpecialData.entityType
      )
    )
      return true;
    return false;
  };

  controlCountdowns() {
    const { visibleCountdowns, cpiuSpecialData, hiddenCountdownsList } = this.props;
    const Description = this.Description;
    const countdownsList = Object.values(visibleCountdowns).sort(
      (a, b) => a.activeDelay - b.activeDelay
    );
    for (const countdown of countdownsList) {
      if (this.isCountdownVisible(countdown, cpiuSpecialData, hiddenCountdownsList)) {
        const roundedDelay = Math.floor(countdown.activeDelay);
        const percent = (countdown.delayStart / roundedDelay) * 100;
        const isPause = countdown.status === COUNTDOWN_STATES.POSTPONED;
        const color = isPause ? this.getColor(30) : this.getColor(percent);
        this.openNotification(
          i18next.t('countdown'),
          <Description countdown={countdown} time={roundedDelay} isPause={isPause} color={color} />,
          countdown.id,
          color,
          roundedDelay,
          isPause
        );
      }
    }
  }

  render() {
    return null;
  }

  componentWillUnmount() {
    for (const countdown of Object.values(this.props.visibleCountdowns)) {
      notification.close(countdown.id);
    }
  }
}

export default Countdowns;
