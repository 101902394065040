import {
  DEVICES_LOAD,
  REGION_DELETE,
  isLoaded,
  DEVICE_CREATE,
  DEVICE_UPDATE_TYPE,
  DEVICE_DELETE,
  DEVICES_DELETE,
  DEVICE_UPDATE_ADDRESS_PATH,
  DEVICE_UPDATE_PLAN_LAYOUTS,
  DEVICES_UPDATE_PLAN_LAYOUTS,
  DEVICES_CHANGE_REGION_BINDING,
  DEVICE_SET_PROPERTIES,
  DEVICE_SET_CONFIG,
  PROJECT_LOAD_CURRENT,
  PROJECT_LOAD_ACTIVE,
  PROJECT_UPDATE_STATUS,
  PROJECT_DELETE,
  ACTIVE_DEVICE_SET_CONFIG,
  ACTIVE_DEVICE_UPDATED,
  ACTIVE_DEVICES_UPDATED,
  DEVICE_SET_DISABLED,
  DEVICE_UPDATE_LINKS,
  DEVICE_ADD_NOTE,
  DEVICE_SET_SHAPE_LIBRARY,
  DEVICE_IMPORT_CONTOL_CONFIG,
  PLAN_DELETE,
  REGION_NEW_DEVICE,
  REGION_DELETE_DEVICE,
  REGION_CREATE_AND_CONNECT_DEVICE,
  DEVICE_UPDATE_SUBSYSTEM,
  DEVICE_UPDATE_SPECIFIC_SETTINGS,
  DEVICE_GET_FULL,
  REGION_NEW_DEVICE_LIST,
  REGION_DELETE_DEVICE_LIST,
  REGIONS_DELETE_ALL_DEVICE_LISTS,
  DEVICES_UPDATED,
  DEVICES_SET_CONFIGS_AND_PROPERTIES,
  DEVICES_COPY_AND_PASTE_ON_PLAN,
  SCENARIO_DELETE
} from 'constants/actionTypes';

import {
  createDeviceTree,
  addDeviceTreeItems,
  updateDeviceTreeItems,
  updateOneDeviceTreeItem,
  deleteDeviceTreeItems
} from 'helpers/device';

/**
 * state = devices: {
 *    <projectId>: {
 *      hash: {
 *        <deviceId>: {
 *          id: <string>,
 *          ...
 *          }
 *      },
 *      tree: [
 *        {
 *          id: <string>,
 *          children: <deviceList>,
 *          ...
 *        },
 *        ...
 *      ]
 *    }
 * }
 * (см. метод GET api/v1/projects/<projectId>/devices в тех. проекте)
 */
export default function devices(state = {}, action) {
  switch (action.type) {
    case isLoaded(DEVICES_LOAD, true): {
      const { projectId, devices } = action.payload;
      const allDevices = { ...state };
      allDevices[projectId] = createDeviceTree(devices);
      return allDevices;
    }

    case ACTIVE_DEVICE_UPDATED: {
      const updatedDevice = action.payload;
      const projectId = updatedDevice.projectId;
      if (!state[projectId]) {
        return state;
      }
      const devicesHash = { ...state };
      devicesHash[projectId].hash = { ...devicesHash[projectId].hash };
      devicesHash[projectId].tree = [...devicesHash[projectId].tree];
      devicesHash[projectId] = { ...devicesHash[projectId] };

      updateOneDeviceTreeItem(
        devicesHash[projectId].hash,
        devicesHash[projectId].tree,
        updatedDevice
      );

      return devicesHash;
    }

    case DEVICES_UPDATED:
    case ACTIVE_DEVICES_UPDATED: {
      const updatedDevices = action.payload;
      if (updatedDevices.length === 0) return state;
      /* Предполагаем, что обновления активных устройств сразу по нескольким проектам прийти не могут */
      const projectId = updatedDevices[0].projectId;
      if (!state[projectId]) return state;

      const devicesHash = { ...state };
      devicesHash[projectId].hash = { ...devicesHash[projectId].hash };
      devicesHash[projectId].tree = [...devicesHash[projectId].tree];
      devicesHash[projectId] = { ...devicesHash[projectId] };

      updateDeviceTreeItems(
        devicesHash[projectId].hash,
        devicesHash[projectId].tree,
        updatedDevices
      );
      return devicesHash;
    }

    case isLoaded(DEVICE_IMPORT_CONTOL_CONFIG, true): {
      const {
        projectId,
        devices: { created: createdDevices, updated: updatedDevices }
      } = action.payload;
      if (!state[projectId] || (!createdDevices && !updatedDevices)) {
        return state;
      }
      const devicesHash = { ...state };
      devicesHash[projectId].hash = { ...devicesHash[projectId].hash };
      devicesHash[projectId].tree = [...devicesHash[projectId].tree];
      devicesHash[projectId] = { ...devicesHash[projectId] };

      if (createdDevices && createdDevices.length) {
        addDeviceTreeItems(
          devicesHash[projectId].hash,
          devicesHash[projectId].tree,
          createdDevices
        );
      }
      if (updatedDevices && updatedDevices.length) {
        updateDeviceTreeItems(
          devicesHash[projectId].hash,
          devicesHash[projectId].tree,
          updatedDevices
        );
      }

      return devicesHash;
    }
    case isLoaded(DEVICE_UPDATE_SPECIFIC_SETTINGS, true):
    case isLoaded(DEVICES_SET_CONFIGS_AND_PROPERTIES, true):
    case isLoaded(DEVICE_UPDATE_SUBSYSTEM, true):
    case isLoaded(DEVICE_UPDATE_LINKS, true):
    case isLoaded(DEVICE_UPDATE_TYPE, true):
    case isLoaded(DEVICE_CREATE, true): {
      const { projectId } = action.payload;
      if (!state[projectId]) return state;

      const devicesHash = { ...state };
      devicesHash[projectId].hash = { ...devicesHash[projectId].hash };
      devicesHash[projectId].tree = [...devicesHash[projectId].tree];
      devicesHash[projectId] = { ...devicesHash[projectId] };

      savePayloadInNewState(action.payload, devicesHash, projectId);

      return devicesHash;
    }
    case isLoaded(DEVICES_COPY_AND_PASTE_ON_PLAN, true): {
      const { projectId, copyDevicesResults } = action.payload;
      if (!state[projectId]) {
        return state;
      }
      const devicesHash = { ...state };
      devicesHash[projectId].hash = { ...devicesHash[projectId].hash };
      devicesHash[projectId].tree = [...devicesHash[projectId].tree];
      devicesHash[projectId] = { ...devicesHash[projectId] };

      copyDevicesResults.forEach(copyDevicesResult => {
        savePayloadInNewState(copyDevicesResult, devicesHash, projectId);
      });
      return devicesHash;
    }
    case isLoaded(REGION_CREATE_AND_CONNECT_DEVICE, true):
    case isLoaded(REGION_NEW_DEVICE, true):
    case isLoaded(REGION_NEW_DEVICE_LIST, true):
    case isLoaded(REGION_DELETE_DEVICE_LIST, true):
    case isLoaded(REGIONS_DELETE_ALL_DEVICE_LISTS, true):
    case isLoaded(PLAN_DELETE, true):
    case isLoaded(REGION_DELETE, true):
    case isLoaded(SCENARIO_DELETE, true): {
      const { projectId, updatedDevices } = action.payload;
      if (!state[projectId] || !updatedDevices.length) {
        return state;
      }
      const devicesHash = { ...state };
      devicesHash[projectId].hash = { ...devicesHash[projectId].hash };
      devicesHash[projectId].tree = [...devicesHash[projectId].tree];
      devicesHash[projectId] = { ...devicesHash[projectId] };

      if (updatedDevices && updatedDevices.length) {
        updateDeviceTreeItems(
          devicesHash[projectId].hash,
          devicesHash[projectId].tree,
          updatedDevices
        );
      }

      return devicesHash;
    }
    case isLoaded(DEVICE_GET_FULL, true):
    case isLoaded(DEVICE_UPDATE_ADDRESS_PATH, true):
    case isLoaded(REGION_DELETE_DEVICE, true):
    case isLoaded(DEVICE_SET_SHAPE_LIBRARY, true):
    case isLoaded(DEVICE_ADD_NOTE, true):
    case isLoaded(ACTIVE_DEVICE_SET_CONFIG, true):
    case isLoaded(DEVICE_SET_CONFIG, true):
    case isLoaded(DEVICE_SET_PROPERTIES, true):
    case isLoaded(DEVICE_UPDATE_PLAN_LAYOUTS, true): {
      const { projectId, device: updatedDevice } = action.payload;
      if (!state[projectId]) return state;

      const currentDevice = state[projectId].hash[updatedDevice.id];
      if (!currentDevice || currentDevice.revision > updatedDevice.revision) return state;

      const devices = { ...state };
      devices[projectId].hash = { ...devices[projectId].hash };
      devices[projectId].tree = [...devices[projectId].tree];

      updateOneDeviceTreeItem(devices[projectId].hash, devices[projectId].tree, updatedDevice);

      return devices;
    }
    case isLoaded(DEVICES_UPDATE_PLAN_LAYOUTS, true):
    case isLoaded(DEVICES_CHANGE_REGION_BINDING, true): {
      const { projectId, devices } = action.payload;
      if (!state[projectId]) return state;

      const newState = { ...state };
      newState[projectId].hash = { ...newState[projectId].hash };
      newState[projectId].tree = [...newState[projectId].tree];

      updateDeviceTreeItems(newState[projectId].hash, newState[projectId].tree, devices);
      return newState;
    }
    case isLoaded(DEVICE_SET_DISABLED, true): {
      const { projectId, devices: updatedDevices } = action.payload;
      if (!state[projectId]) {
        return state;
      }
      const devices = { ...state };
      devices[projectId].hash = { ...devices[projectId].hash };
      devices[projectId].tree = [...devices[projectId].tree];

      updateDeviceTreeItems(devices[projectId].hash, devices[projectId].tree, updatedDevices);

      return devices;
    }
    case isLoaded(DEVICES_DELETE, true):
    case isLoaded(DEVICE_DELETE, true): {
      const { projectId, removedDeviceIds, updatedDevices } = action.payload;
      if (!state[projectId] || (!removedDeviceIds.length && !updatedDevices.length)) {
        return state;
      }
      const devicesHash = { ...state };
      devicesHash[projectId].hash = { ...devicesHash[projectId].hash };
      devicesHash[projectId].tree = [...devicesHash[projectId].tree];
      devicesHash[projectId] = { ...devicesHash[projectId] };

      if (updatedDevices && updatedDevices.length) {
        updateDeviceTreeItems(
          devicesHash[projectId].hash,
          devicesHash[projectId].tree,
          updatedDevices
        );
      }
      if (removedDeviceIds && removedDeviceIds.length) {
        deleteDeviceTreeItems(
          devicesHash[projectId].hash,
          devicesHash[projectId].tree,
          removedDeviceIds
        );
      }
      return devicesHash;
    }
    case isLoaded(PROJECT_LOAD_CURRENT, true):
    case isLoaded(PROJECT_LOAD_ACTIVE, true):
    case isLoaded(PROJECT_UPDATE_STATUS, true): {
      const { project, devices } = action.payload;
      const devicesHash = { ...state };
      devicesHash[project.id] = createDeviceTree(devices);
      return devicesHash;
    }
    case isLoaded(PROJECT_DELETE, true): {
      const projectId = action.payload;
      if (!state[projectId]) {
        return state;
      }
      const devicesHash = { ...state };
      delete devicesHash[projectId];
      return devicesHash;
    }
    default:
      return state;
  }
}

function savePayloadInNewState(payload, devicesHash, projectId) {
  const { createdDevices, updatedDevices, removedDeviceIds } = payload;

  if (createdDevices?.length)
    addDeviceTreeItems(devicesHash[projectId].hash, devicesHash[projectId].tree, createdDevices);

  if (updatedDevices?.length)
    updateDeviceTreeItems(devicesHash[projectId].hash, devicesHash[projectId].tree, updatedDevices);

  if (removedDeviceIds?.length)
    deleteDeviceTreeItems(
      devicesHash[projectId].hash,
      devicesHash[projectId].tree,
      removedDeviceIds
    );
}
