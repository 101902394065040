/* События */

/**
 * Загрузить события для журнала ОЗ.
 * Payload: {
 *  pageSize: <int>,
 *  pageNo: <int>,
 *  sort: <property_name,ASC|DESC>,
 *  filters: {
 *    subsystem: <string>,
 *    occurredAfter: <YYYY-MM-DDTHH:mm:ss.SSSZ>, //ISO-DATE
 *    receivedAfter: <YYYY-MM-DDTHH:mm:ss.SSSZ>, //ISO-DATE
 *    eventTypeIds: <string id1,id2,id4,...idN>,
 *    controlDeviceIds: <string id1,id2,id4,...idN>,
 *    deviceIds: <string id1,id2,id4,...idN>,
 *    regionIds: <string id1,id2,id4,...idN>,
 *    virtualStateIds: <string id1,id2,id4,...idN>
 *  }
 * }
 * store: state.activeProject.logEvents
 */
export const EVENTS_LOAD_LOG = 'EVENTS_LOAD_LOG';
/**
 * Загрузить события для архива.
 * Payload: {
 *  pageSize: <int>,
 *  pageNo: <int>,
 *  sort: <property_name,ASC|DESC>,
 *  filters: {
 *    subsystem: <string>,
 *    occurredAfter: <YYYY-MM-DDTHH:mm:ss.SSSZ>, //ISO-DATE
 *    receivedAfter: <YYYY-MM-DDTHH:mm:ss.SSSZ>, //ISO-DATE
 *    eventTypeIds: <string id1,id2,id4,...idN>,
 *    controlDeviceIds: <string id1,id2,id4,...idN>,
 *    deviceIds: <string id1,id2,id4,...idN>,
 *    regionIds: <string id1,id2,id4,...idN>,
 *    virtualStateIds: <string id1,id2,id4,...idN>
 *  }
 * }
 * store: state.activeProject.archiveEvents = {
 *  totalCount: <long>
 *  filteredCount: <long>
 *  events: [{...}, ...]
 * }
 */
export const EVENTS_LOAD_ARCHIVE = 'EVENTS_LOAD_ARCHIVE';

/**
 * Загрузить отображения типов событий.
 * Payload: -
 * Store: state.eventTypeViews: [
 *  {
 *    id: <string>,
 *    name: <string>,
 *    description: <string>
 *  },
 *  ...
 * ]
 */
export const EVENTS_LOAD_TYPE_VIEWS = 'EVENTS_LOAD_TYPE_VIEWS';

/**
 * Удалить все события из архива.
 */
export const EVENTS_CLEAN_ARCHIVE = 'EVENTS_CLEAN_ARCHIVE';

/**
 * Установить параметры фильтрации архива событий (локальный action).
 * Payload: [
 *  {
 *    id: <string>,
 *    value: <string>
 *  },
 *  ...
 * ]
 * Cм. возможные варианты в п. 5 описания REST журнала событий в ТП,
 *    https://docs.google.com/document/d/19fDadv_CBaeP-RlnnSvsjpdx7BCqSeax4MlCHjeEW28/edit#heading=h.8fanaw8bn3r3)
 * Store: state.widgets.archiveEventsWidget.filters
 */
export const EVENTS_SET_ARCHIVE_FILTERS = 'EVENTS_SET_ARCHIVE_FILTERS';
