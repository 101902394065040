import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Form, Field, initialize, change, formValueSelector } from 'redux-form';
import { Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { Input, FormItem, Layout, message, Tooltip, Space } from 'components';
import Modal from '../Modal';
import { CATEGORY } from 'constants/device';
import { MAX_LINE_DEVICES_COUNT } from 'constants/device';
import styles from './CopyDeviceForm.module.css';
import { usePrevious } from 'customHooks/usePrevious';
import i18next from 'i18next';
import { isEmpty } from 'lodash';
import { getDevicesTree } from 'helpers/device';

const FORM_NAME = 'copyDeviceForm';
export const MESSAGE_KEY = 'copy_device_error';

const formItemLayout = {
  labelCol: { span: 14 },
  wrapperCol: { span: 8 }
};

const tailLayout = {
  wrapperCol: { offset: 14, span: 16 }
};

const Fields = {
  LINE_NO: 'lineNo',
  ADDRESS: 'address',
  PARENT: 'parentId'
};

/**
 * Проверяет устройства для последующей вставки
 * @param data объект, содеражащий:
 * devicesHash - хэш устройств;
 * deviceProfileViewsHash - хэш профилей устройств;
 * deviceIds - список идентификаторов устройств, которые будут вставлены в дерево;
 * parentId - идентификатор родителя для вставляемых устройств;
 * newLineNo - номер адресной линии;
 * newAddress - номер адреса на линии;
 * @returns {Promise<void>}
 */
export async function checkSelectedDevices(data) {
  const { devicesHash, deviceProfileViewsHash, deviceIds, parentId, newLineNo, newAddress } = data;

  if (!deviceIds.length)
    throw new Error(i18next.t('devices.errors.noDeviceSelected', { context: 'plural' }));

  const preparedIds = prepareDeviceIds(deviceIds, devicesHash);

  const devices = preparedIds.map(id => devicesHash[id]);
  const parent = devicesHash[parentId];
  const count = countAddresses(devices);
  const parentProfileView = parent ? deviceProfileViewsHash[parent.deviceProfileId] : null;
  const parentProfile = parentProfileView ? parentProfileView.deviceProfile : null;

  checkDevices(parent, devices);
  checkAddressesAccessibility(parent, parentProfile, devices, newLineNo, newAddress, count);

  return Promise.resolve(preparedIds);
}

function countAddresses(devices) {
  return devices.reduce((sum, dev) => {
    if (dev.deviceCategory === CATEGORY.CONTAINER.id) sum += dev.children.length;
    else sum++;
    return sum;
  }, 0);
}

function checkDevices(parent, devices) {
  for (const device of devices) {
    if (device.embedded === true) {
      const msg = i18next.t('devices.errors.deviceEmbeddedAndCannotBeCopied', {
        name: `${device.name} (${device.fullAddressPath})`
      });
      throw new Error(msg);
    }
  }
  if (parent && parent.disabled) {
    throw new Error(
      i18next.t('devices.errors.deviceDisabled', {
        name: `${parent.name} (${parent.fullAddressPath})`
      })
    );
  }
}

function checkAddressesAccessibility(parent, parProfile, devices, newLineNo, newAddr, addrCount) {
  const lineCount = parProfile ? parProfile.lineCount : 1;
  const lineDeviceCount = parProfile ? parProfile.lineDeviceCount : MAX_LINE_DEVICES_COUNT;

  if (newLineNo % 1 !== 0)
    throw new Error(
      i18next.t('devices.errors.wrongFormat', { field: i18next.t('devices.lineNumber') })
    );
  if (newAddr % 1 !== 0)
    throw new Error(i18next.t('devices.errors.wrongFormat', { field: i18next.t('address') }));

  if (newLineNo < 1)
    throw new Error(
      i18next.t('devices.errors.minimumLineNumber', {
        num: 1
      })
    );
  if (newLineNo > lineCount)
    throw new Error(
      i18next.t('devices.errors.maximumLineNumber', {
        num: lineCount
      })
    );
  if (lineDeviceCount < 1)
    throw new Error(
      i18next.t('devices.errors.minimumAccessibleAddress', {
        num: 1
      })
    );

  if (!parent || !parent.lineAddressRanges) return;
  const ranges = parent.lineAddressRanges[newLineNo];
  const finalAddr = newAddr + addrCount;
  for (const range of ranges) {
    if (newAddr >= range.firstAddress && finalAddr <= range.firstAddress + range.addressCount) {
      return;
    }
  }

  const msg1 = i18next.t('errors.addressAlreadyOccupied');
  const msg2 = i18next.t('devices.errors.notEnoughFreeAddresses');
  throw new Error(devices.length === 1 ? msg1 : msg2);
}

function prepareDeviceIds(ids, hash) {
  return ids.filter(id => {
    const device = hash[id];
    if (device && device.parentDeviceId) {
      return !ids.includes(device.parentDeviceId);
    } else return true;
  });
}

export function initializeForm(dispatch, lineNo, address, parentId) {
  dispatch(initialize(FORM_NAME, { lineNo, address, parentId }));
}

const CopyDeviceForm = props => {
  const {
    modalName,
    submitting,
    handleSubmit,
    selectedDevice,
    devicesHash,
    deviceProfileViewsHash,
    deviceIds,
    dispatch,
    isModalOpen,
    parentId
  } = props;
  const [isDataCalculating, setDataCalculating] = useState(false);
  const [haveErrors, setErrorsStatus] = useState(false);
  const [parent, setParent] = useState(null);
  const [selectedProfile, setSelectedProfile] = useState(null);

  const [isPastToParent, setIsPastToParent] = useState(false);

  useEffect(() => {
    if (!devicesHash || isEmpty(selectedDevice) || !deviceIds) return;
    const copiedDevices = deviceIds.reduce((prev, deviceId) => {
      const device = devicesHash[deviceId];
      if (device) {
        const { children, ...justDevice } = device;
        prev.push(justDevice);
      }
      return prev;
    }, []);
    const copiedDevicesTree = getDevicesTree(copiedDevices);
    const copiedDeviceProfileIds = copiedDevicesTree.map(device => device.deviceProfileId);
    const selectedDeviceProfileView = deviceProfileViewsHash[selectedDevice.deviceProfileId];
    const selectedDeviceProfile = selectedDeviceProfileView
      ? selectedDeviceProfileView.deviceProfile
      : null;
    const isPastToSelectDevice =
      selectedDeviceProfile && copiedDeviceProfileIds
        ? copiedDeviceProfileIds.every(
            el =>
              selectedDeviceProfile.acceptableDeviceProfileIds &&
              selectedDeviceProfile.acceptableDeviceProfileIds.includes(el)
          )
        : false;
    const parent = devicesHash[selectedDevice.parentDeviceId];
    setParent(parent);
    setSelectedProfile(selectedDeviceProfile);
    setIsPastToParent(!isPastToSelectDevice);
  }, [selectedDevice, deviceIds, deviceProfileViewsHash, devicesHash]);

  const submit = data => checkData(data, () => props.onSubmit(data, data.parentId));

  const setErrorMessage = msg => {
    message.error({ key: MESSAGE_KEY, content: msg, duration: 0 });
    setErrorsStatus(true);
  };

  const deviceFieldFormatter = deviceID => {
    if (!deviceID) {
      return;
    }
    const device = devicesHash[deviceID];
    if (!device) {
      return;
    }
    const deviceInfo = device.name + ' (' + device.fullAddressPath + ')';
    return deviceInfo;
  };

  const checkData = (data, onSuccess = () => {}) => {
    const { parentId } = props;
    setDataCalculating(true);
    message.destroy(MESSAGE_KEY);
    const opt = {
      devicesHash,
      deviceProfileViewsHash,
      deviceIds,
      parentId,
      newLineNo: data.lineNo,
      newAddress: data.address
    };
    checkSelectedDevices(opt)
      .then(() => {
        onSuccess();
        setErrorsStatus(false);
      })
      .catch(err => setErrorMessage(err.message))
      .finally(() => setDataCalculating(false));
  };

  const checkDataMemoized = useCallback(checkData, [checkData]);

  const lastModalState = usePrevious(isModalOpen);

  useEffect(() => {
    if (!lastModalState && isModalOpen) {
      checkDataMemoized({ lineNo: props.lineNo, address: props.address, parentId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkDataMemoized, isModalOpen, lastModalState, props.address, props.lineNo]);

  const setNextAccessibleAddress = () => {
    const { lineNo, address, rootDeviceAddresses } = props;
    message.destroy(MESSAGE_KEY);

    const parentId = isPastToParent ? (parent ? parent.id : null) : selectedDevice.id;
    if (parentId) {
      const deviceAccepted = devicesHash[parentId];
      const devices = deviceIds.map(id => devicesHash[id]);
      const count = countAddresses(devices);
      const ranges = deviceAccepted.lineAddressRanges[lineNo].filter(
        range => range.addressCount >= count
      );
      let next = address + 1;
      while (next !== address && ranges.length)
        for (let i = 0; i < ranges.length; i++) {
          const range = ranges[i];
          if (
            next >= range.firstAddress &&
            next + count <= range.firstAddress + range.addressCount
          ) {
            dispatch(change(FORM_NAME, Fields.ADDRESS, next));
            setErrorsStatus(false);
            return;
          }
          if (i === ranges.length - 1) {
            next = next + 1;
          }
        }

      if (next === address && !haveErrors) return;

      const msg = i18next.t('devices.errors.noFreeAddressesFoundOnLine', { num: lineNo });
      message.error({ key: MESSAGE_KEY, content: msg, duration: 3 });
      setErrorsStatus(true);
    } else {
      const lastBondedAddress = Math.max.apply(null, rootDeviceAddresses);
      const next = address >= lastBondedAddress + 1 ? address + 1 : lastBondedAddress + 1;
      dispatch(change(FORM_NAME, Fields.ADDRESS, next));
      setErrorsStatus(false);
    }
  };

  const acceptedProfile = parentId
    ? deviceProfileViewsHash?.[devicesHash?.[parentId]?.deviceProfileId]?.deviceProfile
    : selectedProfile;

  return (
    <Modal
      name={modalName}
      title={`${i18next.t('buttons.paste')} (${deviceIds.length} ${i18next.t(
        'devices.nameShort'
      )})`}
      width={300}
      onClose={() => message.destroy(MESSAGE_KEY)}
    >
      <Form onSubmit={handleSubmit(submit)}>
        <Layout.Row>
          <Layout.Col>
            <FormItem {...formItemLayout} label={i18next.t('device')}>
              <Field
                component={Input}
                name={Fields.PARENT}
                disabled={true}
                format={deviceFieldFormatter}
              />
            </FormItem>
            <FormItem {...formItemLayout} required={true} label={i18next.t('devices.lineNumber')}>
              <Field
                component={Input.Number}
                name={Fields.LINE_NO}
                min={1}
                max={acceptedProfile ? acceptedProfile.lineCount : 1}
                disabled={acceptedProfile?.lineCount ? acceptedProfile.lineCount === 1 : true}
                onChange={value => checkData({ lineNo: value, address: props.address })}
              />
            </FormItem>
            <FormItem {...formItemLayout} required={true} label={i18next.t('address')}>
              <Space>
                <Field
                  component={Input.Number}
                  name={Fields.ADDRESS}
                  min={1}
                  max={MAX_LINE_DEVICES_COUNT}
                  onChange={value => checkData({ lineNo: props.lineNo, address: value })}
                />
                <Tooltip title={i18next.t('devices.findNextAvailableAddress')}>
                  <Button
                    className={styles.btn_search}
                    shape="circle"
                    icon={<SearchOutlined />}
                    onClick={setNextAccessibleAddress}
                    disabled={isDataCalculating}
                  />
                </Tooltip>
              </Space>
            </FormItem>
            <FormItem {...tailLayout}>
              <Button
                type="primary"
                htmlType="submit"
                loading={isDataCalculating}
                disabled={submitting || haveErrors}
              >
                {i18next.t('buttons.paste')}
              </Button>
            </FormItem>
          </Layout.Col>
        </Layout.Row>
      </Form>
    </Modal>
  );
};

const CopyForm = reduxForm({ form: FORM_NAME })(CopyDeviceForm);
const selector = formValueSelector(FORM_NAME);

const mapStateToProps = (state, props) => {
  const items = props.selectedItems;
  const deviceIds = props.copiedDeviceIds.length ? props.copiedDeviceIds : props.cutDeviceIds;
  return {
    lineNo: selector(state, Fields.LINE_NO),
    address: selector(state, Fields.ADDRESS),
    selectedDevice: items.length ? items[items.length - 1] : {},
    deviceIds,
    devicesHash: props.devicesHash,
    deviceProfileViewsHash: props.deviceProfileViewsHash,
    isModalOpen: !!state.modals[props.modalName]
  };
};
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CopyForm);
