import { takeEvery, call } from 'redux-saga/effects';
import message from 'components/message';

import {
  PLANS_LOAD_ACTIVE,
  PLAN_GROUPS_LOAD_ACTIVE,
  PLAN_GROUP_CREATE,
  PLAN_GROUP_DELETE,
  PLAN_GROUP_UPDATE,
  PLAN_CREATE,
  PLAN_DELETE,
  PLAN_UPDATE,
  PLANS_LOAD,
  PLAN_GROUPS_LOAD,
  PLAN_LOAD_BACKGROUNDS,
  PLAN_ADD_BACKGROUNDS,
  PLAN_DELETE_BACKGROUND,
  PLAN_DELETE_OBJECTS,
  PLAN_ADD_TEXT,
  PLAN_DELETE_TEXT,
  PLAN_UPDATE_TEXT
} from 'constants/actionTypes';

import { fetch, request, dispatchSuccess, dispatchFail } from 'helpers/request';
import i18next from 'i18next';

function* loadPlans(action) {
  const { projectId } = action.payload;
  try {
    const plans = yield fetch(`/projects/${projectId}/plans`);
    if (!Array.isArray(plans)) {
      throw new Error(i18next.t('errors.responseNotArray'));
    }

    yield dispatchSuccess(PLANS_LOAD, { projectId, plans });
  } catch (error) {
    yield dispatchFail(PLANS_LOAD, error.message);
  }
}

function* loadPlanGroups(action) {
  const { projectId } = action.payload;
  try {
    const planGroups = yield fetch(`/projects/${projectId}/plan_groups`);
    if (!Array.isArray(planGroups)) {
      throw new Error(i18next.t('errors.responseNotArray'));
    }

    yield dispatchSuccess(PLAN_GROUPS_LOAD, { projectId, planGroups });
  } catch (error) {
    yield dispatchFail(PLAN_GROUPS_LOAD, error.message);
  }
}

function* loadActivePlans(action) {
  const { projectId } = action.payload;
  try {
    const activePlans = yield fetch(`/projects/${projectId}/active_plans`);
    if (!Array.isArray(activePlans)) {
      throw new Error(i18next.t('errors.responseNotArray'));
    }

    yield dispatchSuccess(PLANS_LOAD_ACTIVE, activePlans);
  } catch (error) {
    yield dispatchFail(PLANS_LOAD_ACTIVE, error.message);
  }
}

function* loadActivePlanGroups(action) {
  const { projectId } = action.payload;
  try {
    const activePlanGroups = yield fetch(`/projects/${projectId}/plan_groups`);
    if (!Array.isArray(activePlanGroups)) {
      throw new Error(i18next.t('errors.responseNotArray'));
    }

    yield dispatchSuccess(PLAN_GROUPS_LOAD_ACTIVE, activePlanGroups);
  } catch (error) {
    yield dispatchFail(PLAN_GROUPS_LOAD_ACTIVE, error.message);
  }
}

function* createPlanGroup(action) {
  const { planGroup, projectId } = action.payload;
  try {
    const newPlanGroup = yield request(`/projects/${projectId}/plan_groups/`, 'POST', planGroup);
    yield dispatchSuccess(PLAN_GROUP_CREATE, { newPlanGroup, projectId });
    message('success', i18next.t('messages.groupCreated'));
  } catch (error) {
    yield dispatchFail(PLAN_GROUP_CREATE, error.message);
  }
}

function* updatePlanGroup(action) {
  const { planGroup, projectId } = action.payload;
  try {
    const updatedPlanGroup = yield request(
      `/projects/${projectId}/plan_groups/${planGroup.id}`,
      'PUT',
      planGroup
    );
    if (updatedPlanGroup && updatedPlanGroup.error) {
      throw new Error(updatedPlanGroup.error);
    }
    yield dispatchSuccess(PLAN_GROUP_UPDATE, { updatedPlanGroup, projectId });
    message('success', i18next.t('messages.groupUpdated'));
  } catch (error) {
    yield dispatchFail(PLAN_GROUP_UPDATE, error.message);
  }
}

function* deletePlanGroup(action) {
  const { planGroupId, projectId } = action.payload;
  try {
    const response = yield request(`/projects/${projectId}/plan_groups/${planGroupId}`, 'DELETE');
    yield dispatchSuccess(PLAN_GROUP_DELETE, {
      planGroupId,
      projectId,
      updatedPlans: response.plans
    });
    message('success', i18next.t('messages.groupDeleted'));
  } catch (error) {
    yield dispatchFail(PLAN_GROUP_DELETE, error.message);
  }
}

function* createPlan(action) {
  const { plan, projectId } = action.payload;
  try {
    const newPlan = yield request(`/projects/${projectId}/plans`, 'POST', plan);
    if (!newPlan) {
      throw new Error(i18next.t('errors.unknownError'));
    }
    if (newPlan && newPlan.error) {
      throw new Error(newPlan.error);
    }
    yield dispatchSuccess(PLAN_CREATE, { newPlan, projectId });
    message('success', i18next.t('messages.planCreated'));
  } catch (error) {
    yield dispatchFail(PLAN_CREATE, error.message);
  }
}

export function* updatePlan(action) {
  const { plan, projectId } = action.payload;
  try {
    const updatedPlan = yield request(`/projects/${projectId}/plans/${plan.id}`, 'PUT', plan);
    if (updatedPlan && updatedPlan.error) {
      throw new Error(updatedPlan.error);
    }
    yield dispatchSuccess(PLAN_UPDATE, { updatedPlan, projectId });
    message('success', i18next.t('messages.planUpdated'));
  } catch (error) {
    yield dispatchFail(PLAN_UPDATE, error.message);
  }
}

function* deletePlan(action) {
  const { planId, projectId } = action.payload;
  try {
    const response = yield request(`/projects/${projectId}/plans/${planId}`, 'DELETE');
    yield dispatchSuccess(PLAN_DELETE, {
      planId,
      projectId,
      updatedDevices: response.devices,
      updatedRegions: response.regions
    });
    message('success', i18next.t('messages.planDeleted'));
  } catch (error) {
    yield dispatchFail(PLAN_DELETE, error.message);
  }
}

function* loadPlanBackgrounds(action) {
  const { projectId, planId, callback = () => {} } = action.payload;
  try {
    const response = yield fetch(`/projects/${projectId}/plans/${planId}/backgrounds`);
    if (!response || response.error) throw new Error(response ? response.error : '');
    yield call(callback, response, null);
    yield dispatchSuccess(PLAN_LOAD_BACKGROUNDS);
  } catch (error) {
    yield call(callback, null, error);
    yield dispatchFail(PLAN_LOAD_BACKGROUNDS, error.message);
  }
}

function* addPlanBackgrounds(action) {
  const { projectId, planId, backgrounds, callback = () => {} } = action.payload;
  try {
    const response = yield request(
      `/projects/${projectId}/plans/${planId}/backgrounds/list`,
      'POST',
      backgrounds
    );
    if (!response || response.error) throw new Error(response ? response.error : '');
    yield call(callback, response, null);
    yield dispatchSuccess(PLAN_ADD_BACKGROUNDS);
    yield dispatchSuccess(PLAN_UPDATE, { updatedPlan: response.plan, projectId });
  } catch (error) {
    yield call(callback, null, error);
    yield dispatchFail(PLAN_ADD_BACKGROUNDS, error.message);
  }
}

function* deletePlanBackground(action) {
  const { projectId, planId, backgroundId, callback = () => {} } = action.payload;
  try {
    const response = yield request(
      `/projects/${projectId}/plans/${planId}/backgrounds/${backgroundId}`,
      'DELETE'
    );
    if (!response || response.error) throw new Error(response ? response.error : '');
    yield dispatchSuccess(PLAN_DELETE_BACKGROUND);
    yield dispatchSuccess(PLAN_UPDATE, { updatedPlan: response.plan, projectId });
    yield call(callback, response, null);
  } catch (error) {
    yield dispatchFail(PLAN_DELETE_BACKGROUND, error.message);
    yield call(callback, null, error);
  }
}

export function* deletePlanObjects(action) {
  const { projectId, planId, objects } = action.payload;
  try {
    // TODO Реализовать функционал удаления группы объектов на беке по строке запроса (см. ниже) и методу DELETE
    // Бек должен возвращать Plan (C:\Work place\fireback\frontend-server\src\main\java\ru\rubezh\firesec\nt\rest\PlanController.java)
    // без удалённых объектов

    const { texts, backgrounds } = objects;

    //----------------------------раскомментить после реализации метода на беке----------------------------
    // const objectsIds = [...texts, ...backgrounds];
    // const response = yield request(
    //   `/projects/${projectId}/plans/${planId}/objects/${objectsIds}`,
    //   'DELETE'
    // );
    // if (!response || response.error) throw new Error(response ? response.error : '');

    //----------------------------раскомментить после реализации метода на беке----------------------------

    //----------------------------удалить после реализации метода на беке----------------------------
    const textResponse = [];
    let backgroundResponse = [];

    for (const text of texts) {
      textResponse.push(
        yield request(`/projects/${projectId}/plans/${planId}/texts/${text}`, 'DELETE')
      );
    }
    textResponse.forEach(plan => {
      if (!plan || plan.error) throw new Error(plan ? plan.error : '');
    });

    for (const background of backgrounds) {
      backgroundResponse.push(
        yield request(`/projects/${projectId}/plans/${planId}/backgrounds/${background}`, 'DELETE')
      );
    }
    backgroundResponse.forEach(plan => {
      if (!plan || plan.error) throw new Error(plan ? plan.error : '');
    });

    backgroundResponse = backgroundResponse.map(background => background.plan);

    const result = [...textResponse, ...backgroundResponse].at(-1);

    //----------------------------удалить после реализации метода на беке----------------------------

    yield dispatchSuccess(PLAN_DELETE_OBJECTS, { updatedPlan: result, projectId });
  } catch (error) {
    yield dispatchFail(PLAN_DELETE_OBJECTS, error.message);
  }
}

function* addPlanText(action) {
  const { projectId, planId, text } = action.payload;
  try {
    const response = yield request(`/projects/${projectId}/plans/${planId}/texts`, 'POST', text);
    yield dispatchSuccess(PLAN_ADD_TEXT, { updatedPlan: response, projectId: response.projectId });
  } catch (error) {
    yield dispatchFail(PLAN_ADD_TEXT, error.message);
  }
}

function* updatePlanText(action) {
  const { projectId, planId, text } = action.payload;
  try {
    const response = yield request(`/projects/${projectId}/plans/${planId}/texts`, 'PUT', text);
    yield dispatchSuccess(PLAN_UPDATE_TEXT, {
      updatedPlan: response,
      projectId: response.projectId
    });
  } catch (error) {
    yield dispatchFail(PLAN_UPDATE_TEXT, error.message);
  }
}

function* deletePlanText(action) {
  const { projectId, planId, textId } = action.payload;
  try {
    const response = yield request(
      `/projects/${projectId}/plans/${planId}/texts/${textId}`,
      'DELETE'
    );
    yield dispatchSuccess(PLAN_DELETE_TEXT, {
      updatedPlan: response,
      projectId: response.projectId
    });
  } catch (error) {
    yield dispatchFail(PLAN_DELETE_TEXT, error.message);
  }
}

export default function* plansSagas() {
  yield takeEvery(PLANS_LOAD, loadPlans);
  yield takeEvery(PLAN_GROUPS_LOAD, loadPlanGroups);
  yield takeEvery(PLANS_LOAD_ACTIVE, loadActivePlans);
  yield takeEvery(PLAN_GROUPS_LOAD_ACTIVE, loadActivePlanGroups);
  yield takeEvery(PLAN_GROUP_CREATE, createPlanGroup);
  yield takeEvery(PLAN_GROUP_UPDATE, updatePlanGroup);
  yield takeEvery(PLAN_GROUP_DELETE, deletePlanGroup);
  yield takeEvery(PLAN_CREATE, createPlan);
  yield takeEvery(PLAN_UPDATE, updatePlan);
  yield takeEvery(PLAN_DELETE, deletePlan);
  yield takeEvery(PLAN_LOAD_BACKGROUNDS, loadPlanBackgrounds);
  yield takeEvery(PLAN_ADD_BACKGROUNDS, addPlanBackgrounds);
  yield takeEvery(PLAN_DELETE_BACKGROUND, deletePlanBackground);
  yield takeEvery(PLAN_DELETE_OBJECTS, deletePlanObjects);
  yield takeEvery(PLAN_ADD_TEXT, addPlanText);
  yield takeEvery(PLAN_UPDATE_TEXT, updatePlanText);
  yield takeEvery(PLAN_DELETE_TEXT, deletePlanText);
}
