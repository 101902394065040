import { takeEvery } from 'redux-saga/effects';
import { fetchLoginRequest, fetchLogoutRequest } from 'helpers/fetch';
import {
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  CURRENT_USER_REQUEST,
  PERMISSIONS_LOAD,
  LOGIN_CHECK_CONFIRMATION
} from 'constants/actionTypes';

import { fetch, dispatchSuccess, dispatchFail } from 'helpers/request';
import { initI18next, getLanguageFromUserSettings } from 'containers/AppLanguageController';
import i18next from "i18next";

function* loginUser({ type, payload }) {
  const { username, password } = payload;
  try {
    /** TODO: Такой вид передачи данных не очень безопасный,
     * поэтому данные лучше перенести в тело запроса.
     */
    yield fetchLoginRequest(`/login?username=${username}&password=${password}`);

    const currentUser = yield fetch('/users/currentUser');
    const langTag = yield getLanguageFromUserSettings(currentUser && currentUser.language);
    yield initI18next(langTag);
    yield dispatchSuccess(type, currentUser);
  } catch (error) {
    yield dispatchFail(type, error.message);
  }
}

function* logoutUser(action) {
  try {
    yield fetchLogoutRequest('/logout');
    yield dispatchSuccess(LOGOUT_REQUEST, {});
  } catch (error) {
    yield dispatchFail(LOGOUT_REQUEST, error);
  }
}

function* loadCurrentUserInfo(action) {
  try {
    const currentUser = yield fetch('/users/currentUser');

    yield dispatchSuccess(CURRENT_USER_REQUEST, currentUser);
  } catch (error) {
    yield dispatchFail(CURRENT_USER_REQUEST, error);
  }
}

function* loadPermissions(action) {
  try {
    const permissions = yield fetch('/permissions');
    yield dispatchSuccess(PERMISSIONS_LOAD, permissions);
  } catch (error) {
    yield dispatchFail(PERMISSIONS_LOAD, error);
  }
}

function* checkLoginConfirmation({ type, payload }) {
  const { password } = payload;
  try {
    const response = yield fetch(`/users/currentUser?checkPassword=${password}`);
    if (!response.passwordMatched) throw new Error(i18next.t('errors.invalidLoginOrPassword'));
    yield dispatchSuccess(type);
  } catch (err) {
    yield dispatchFail(type, err.message);
  }
}

export default function* authorization() {
  yield takeEvery(LOGIN_REQUEST, loginUser);
  yield takeEvery(LOGOUT_REQUEST, logoutUser);
  yield takeEvery(CURRENT_USER_REQUEST, loadCurrentUserInfo);
  yield takeEvery(PERMISSIONS_LOAD, loadPermissions);
  yield takeEvery(LOGIN_CHECK_CONFIRMATION, checkLoginConfirmation);
}
