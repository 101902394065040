import { combineReducers } from 'redux';
import {
  SKUD_ENTITIES,
  isLoaded,
  getAddActionType,
  getUpdateActionType,
  getRemoveActionType,
  PROJECT_LOAD_CURRENT,
  PROJECT_LOAD_ACTIVE,
  PROJECT_UPDATE_STATUS,
  PROJECT_DELETE
} from 'constants/actionTypes';

const reducers = {
  /* 
   * <entityType (employees | accessKeys | workSchedules)>: {
   *   <projectId>: {
   *     <entityId>: {
   *      ...
   *     },
   *     <entityId>: {
   *      ...
   *     },
   *     ...
   *   },
   *   ...
   * },
   * ...
   */
};

Object.keys(SKUD_ENTITIES).forEach(entityName => {
  reducers[entityName] = (state = {}, { type, payload }) => {
    switch (type) {
      case isLoaded(PROJECT_LOAD_CURRENT, true):
      case isLoaded(PROJECT_LOAD_ACTIVE, true):
      case isLoaded(PROJECT_UPDATE_STATUS, true): {
        const { project } = payload;
        const newState = { ...state };
        newState[project.id] = {};
        payload[entityName].forEach(entity => {
          newState[project.id][entity.id] = entity;
        });
        return newState;
      }

      case isLoaded(PROJECT_DELETE, true): {
        const projectId = payload;
        if (state[projectId]) {
          const newState = { ...state };
          delete newState[projectId];
          return newState;
        }
        return state;
      }

      case isLoaded(getAddActionType(entityName), true):
      case isLoaded(getUpdateActionType(entityName), true): {
        const entities = payload;
        const newState = { ...state };
        let updated = false;
        entities.forEach(entity => {
          if (newState[entity.projectId]) {
            newState[entity.projectId] = { ...newState[entity.projectId] };
            newState[entity.projectId][entity.id] = entity;
            updated = true;
          }
        });
        if (updated) return newState;
        else return state;
      }

      case isLoaded(getRemoveActionType(entityName), true): {
        const entities = payload;
        const newState = { ...state };
        let updated = false;
        entities.forEach(entity => {
          if (newState[entity.projectId]) {
            newState[entity.projectId] = { ...newState[entity.projectId] };
            delete newState[entity.projectId][entity.id];
            updated = true;
          }
        });
        if (updated) return newState;
        else return state;
      }

      default:
        return state;
    }
  };
});

export default combineReducers(reducers);
