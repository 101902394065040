import { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getCurrentDevice } from 'helpers/widgets';
import { getFullDevice, updateCurrentDevice } from 'actions/devices';
import { getCurrentProjectDevicesHash, getCurrentProject } from 'helpers/currentProject';
import { getActiveDevicesHash, getActiveProject } from 'helpers/activeProject';
import { isEqual } from 'lodash';
import { EMPTY_OBJECT } from 'constants/common';
import { isDeviceTruncated } from 'helpers/device';

/**
 * Компонент отвечает за автоматическую подгрузку деталей текущего устройства.
 */
class DeviceDetailsLoader extends PureComponent {
  static propTypes = {
    currentDevice: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  };

  componentDidUpdate(prevProps) {
    const {
      currentDevice,
      getFullDeviceInProgress,
      getFullDeviceError,
      devicesHash,
      dispatch
    } = this.props;

    if (Array.isArray(currentDevice)) {
      return;
    }

    if (
      !!Object.keys(devicesHash).length &&
      !!devicesHash[currentDevice.id] &&
      !isEqual(devicesHash[currentDevice.id], currentDevice)
    ) {
      dispatch(updateCurrentDevice(devicesHash[currentDevice.id]));
      return;
    }

    if (!!Object.keys(devicesHash).length && !devicesHash[currentDevice.id]) {
      dispatch(updateCurrentDevice({}));
      return;
    }

    if (
      !getFullDeviceInProgress &&
      !getFullDeviceError &&
      !!currentDevice &&
      !!currentDevice.id &&
      isDeviceTruncated(currentDevice)
    ) {
      dispatch(getFullDevice(currentDevice.projectId, currentDevice.id));
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => {
  const currentDevice = getCurrentDevice(state);
  let devicesHash = EMPTY_OBJECT;
  if (currentDevice && currentDevice.projectId) {
    const currentProject = getCurrentProject(state);
    const activeProject = getActiveProject(state);

    if (currentProject && currentDevice.projectId === currentProject.id)
      devicesHash = getCurrentProjectDevicesHash(state);
    else if (activeProject && currentDevice.projectId === activeProject.id)
      devicesHash = getActiveDevicesHash(state);
  }

  return {
    currentDevice: getCurrentDevice(state),
    getFullDeviceInProgress: state.inProgress.getFullDevice,
    getFullDeviceError: state.errors.getFullDevice,
    devicesHash
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch: dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDetailsLoader);
