import { TEXTURES_ADD, TEXTURES_REMOVE, TEXTURES_CLEAR } from 'constants/actionTypes';

export default function textures(state = {}, action) {
  switch (action.type) {
    case TEXTURES_ADD: {
      const { textureId, texture } = action.payload;
      const textures = { ...state };
      textures[textureId] = texture;
      return textures;
    }

    case TEXTURES_REMOVE: {
      const { textureId } = action.payload;
      const textures = { ...state };
      delete textures[textureId];
      return textures;
    }

    case TEXTURES_CLEAR: {
      return {};
    }

    default:
      return state;
  }
}
