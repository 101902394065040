import moment from 'moment';

import {
  isLoaded,
  PROJECT_UPDATE_STATUS,
  SCENARIO_START_TIMER,
  COUNTDOWNS_UPDATE,
  COUNTDOWNS_DELETE,
  PROJECT_LOAD_ACTIVE,
  COUNTDOWNS_DECREMENT_DELAY
} from 'constants/actionTypes';
import { ENTITY_TYPE } from 'constants/entityType';
import { COUNTDOWN_TYPES } from 'components/Countdowns';
import { Status } from 'constants/project';
import i18next from 'i18next';
import { SCENARIO_ACTIONS } from 'constants/scenario';
import { COUNTDOWN_STATES } from 'components/Countdowns';

/**
 * countdowns: {
 *    devices: {
 *      [deviceId]: {
 *        deviceId: <string>, Идентификатор устройства
 *        regionId: <string>, Идентификатор зоны, к которой привязано устройство
 *        event: <string, SCHEDULED|STARTED|CANCELLED|POSTPONED|RESUMED>, Событие
 *        delayStart: <uint>, Начальное значение задержки,
 *        delay: <uint>, Время до начала тушения пожара (обновляется при постановке на паузу),
 *        timeStart: <uint> Время начала отсчета (время когда событие дошло до клиента)
 *      },
 *      ...
 *    },
 *    computerActions: {
 *      [index] : {
 *        countdownTimeSec: <uint>, Время обратного отсчета
 *        message: <string>, - Подсказка таймера
 *        name: <string>, - Название блока действия
 *        timeDelaySec: <uint>, Задержка до начала отсчета
 *        delayStart: <uint>, Начальное значение задержки
 *        ...
 *      }
 *    }
 * }
 */
export default function countdowns(state = {}, { type, payload }) {
  switch (type) {
    case SCENARIO_START_TIMER: {
      const newState = { ...state };
      newState[payload.id] = {
        ...payload,
        delay: payload.countdownTimeSec,
        activeDelay: payload.countdownTimeSec,
        actualDelay: payload.countdownTimeSec,
        delayStart: payload.countdownTimeSec,
        timeStart: moment().unix(),
        entityId: payload.id,
        type: COUNTDOWN_TYPES.COMPUTER_ACTION
      };

      return newState;
    }
    case isLoaded(PROJECT_UPDATE_STATUS, true): {
      return {};
    }
    case COUNTDOWNS_UPDATE: {
      const newState = { ...state };
      const countdowns = payload;
      for (const countdown of countdowns) {
        updateCountdown(countdown, newState);
      }
      return newState;
    }
    case COUNTDOWNS_DELETE: {
      const newState = { ...state };
      delete newState[payload.id];
      return newState;
    }
    case COUNTDOWNS_DECREMENT_DELAY: {
      const newState = { ...state };
      decrementCountdownsDelay(newState, payload);
      return newState;
    }
    case isLoaded(PROJECT_LOAD_ACTIVE, true): {
      const { countdowns, project } = payload;
      if (project.status === Status.ACTIVE) {
        const newState = { ...state };
        for (const countdown of countdowns) {
          updateCountdown(countdown, newState);
        }
        return newState;
      } else {
        return state;
      }
    }
    default:
      return state;
  }
}

function updateCountdown(countdown, state) {
  const { id, entityType, initialDelay: delayStart, actualDelay } = countdown;

  if (state[id] && countdown.revision === state[id].revision) return;
  if (
    countdown.status === COUNTDOWN_STATES.CANCELLED ||
    countdown.status === COUNTDOWN_STATES.STARTED
  ) {
    delete state[id];
    return;
  }

  const needClosingConfirmation = isNeedClosingConfirmation(entityType);
  const actions = getCountdownActions(entityType);
  state[id] = {
    ...countdown,
    type: entityType,
    activeDelay: actualDelay,
    delayStart,
    needClosingConfirmation,
    actions
  };
}

function getCountdownActions(entityType) {
  switch (entityType) {
    case ENTITY_TYPE.SCENARIO:
      return [
        {
          title: i18next.t('scenarios.manageActions.names.IMMEDIATE_START'),
          actionId: SCENARIO_ACTIONS.IMMEDIATE_START
        },
        {
          title: i18next.t('scenarios.manageActions.names.PAUSE_DELAY'),
          actionId: SCENARIO_ACTIONS.PAUSE_DELAY
        },
        {
          title: i18next.t('scenarios.manageActions.names.RESUME_DELAY'),
          actionId: SCENARIO_ACTIONS.RESUME_DELAY
        },
        {
          title: i18next.t('scenarios.manageActions.names.INCREASE_DELAY'),
          actionId: SCENARIO_ACTIONS.INCREASE_DELAY
        }
      ];
    default:
      return [];
  }
}

function isNeedClosingConfirmation(entityType) {
  switch (entityType) {
    case ENTITY_TYPE.DEVICE:
      return true;
    default:
      return false;
  }
}

function decrementCountdownsDelay(state, diff) {
  for (const currentCountdown of Object.values(state)) {
    if (currentCountdown.status === COUNTDOWN_STATES.POSTPONED) continue;
    if (currentCountdown.activeDelay > 1) {
      currentCountdown.activeDelay = currentCountdown.activeDelay - diff / 1000;
    } else {
      delete state[currentCountdown.id];
    }
  }
}
