import React, { Component } from 'react';
import styles from 'containers/Header/styles.module.css';
import i18next from 'i18next';
import { FileProtectOutlined } from '@ant-design/icons';

export default class Certificate extends Component {
  render() {
    return (
      <div
        onClick={() => {
          window.open('/public/file/rootCA.crt');
        }}
        className={ styles.certificate }
        title={i18next.t('downloadCertificate')}
      >
        <FileProtectOutlined className={styles.file_protect_outlined} />
      </div>
    );
  }
}
