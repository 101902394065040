import { useEffect } from 'react';
import { connect } from 'react-redux';
import fetch from 'isomorphic-fetch';
import { restApi } from 'helpers/fetch';
import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { getCurrentUser } from 'helpers/user';

const defaultLanguage = 'en-US';

const loadLanguageFromServer = callback => {
  return fetch(restApi + `/system_info/language/`, {
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' }
  })
    .then(response => {
      if (response.ok) {
        response
          .text()
          .then(lang => {
            initI18nextWithCallback(lang, callback);
          })
          .catch(() => initI18nextWithCallback(defaultLanguage, callback));
      }
    })
    .catch(() => initI18nextWithCallback(defaultLanguage, callback));
};

export const initI18next = lng => {
  return i18next.use(Backend)
      .use(intervalPlural)
      .init({
    initImmediate: true,
    lng,
    backend: {
      loadPath: `/public/locales/${lng}.json`
    }
  });
};

const initI18nextWithCallback = (lng, callback = () => {}) => {
  initI18next(lng)
    .then(() => callback(lng))
    .catch(() => callback(null, true));
};

export const getLanguageFromUserSettings = lang => {
  switch (lang) {
    case 'RUSSIAN':
      return 'ru-RU';
    default:
      return 'en-US';
  }
};
let firstLoad = false;
const AppLanguageController = ({ languageApplied, currentUser }) => {
  useEffect(() => {
    if (firstLoad) return;
    firstLoad = true;

    if (!currentUser.language) loadLanguageFromServer(languageApplied);
    else {
      const lang = getLanguageFromUserSettings(currentUser.language);
      if (lang === initI18next.lng) return;
      initI18nextWithCallback(lang, languageApplied);
    }
  }, [languageApplied, currentUser.language]);

  return null;
};

const mapStateToProps = state => {
  return {
    currentUser: getCurrentUser(state)
  };
};

export default connect(mapStateToProps, null)(AppLanguageController);
