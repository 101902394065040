import { call, takeEvery } from 'redux-saga/effects';
import message from 'components/message';

import {
  VIRTUAL_COUNTERS_LOAD,
  VIRTUAL_COUNTER_CREATE,
  VIRTUAL_COUNTER_UPDATE,
  VIRTUAL_COUNTER_DELETE,
  VIRTUAL_COUNTERS_DELETE,
  SCENARIOS_UPDATED,
  INDICATOR_PANEL_UPDATE,
  PROJECT_VALIDATE_DELETE, VIRTUAL_COUNTER_PASTE
} from 'constants/actionTypes';

import { fetch, request, dispatch, dispatchSuccess, dispatchFail } from 'helpers/request';
import i18next from 'i18next';

function* loadVirtualCounters(action) {
  const { projectId, callback } = action.payload;
  try {
    const virtualCounters = yield fetch(`/projects/${projectId}/virtual_counters`);

    yield call(callback, virtualCounters, null);
    yield dispatchSuccess(VIRTUAL_COUNTERS_LOAD, {
      projectId,
      virtualCounters
    });
  } catch (error) {
    yield dispatchFail(VIRTUAL_COUNTERS_LOAD, error.message);
  }
}

function* createVirtualCounter(action) {
  const { projectId, params } = action.payload;
  try {
    const virtualCounters = yield request(
      `/projects/${projectId}/virtual_counters/multiple?count=${params.count || 1}`,
      'POST',
      params
    );

    yield dispatchSuccess(VIRTUAL_COUNTER_CREATE, {
      projectId: projectId,
      newVirtualCounters: virtualCounters
    });
    message('success', i18next.t('messages.virtualCounterAdded'));
  } catch (error) {
    yield dispatchFail(VIRTUAL_COUNTER_CREATE, error.message);
  }
}

function* updateVirtualCounter(action) {
  const { projectId, virtualCounterId, params } = action.payload;
  try {
    const virtualCounter = yield request(
      `/projects/${projectId}/virtual_counters/${virtualCounterId}`,
      'PUT',
      params
    );

    yield dispatchSuccess(VIRTUAL_COUNTER_UPDATE, {
      projectId: projectId,
      updatedVirtualCounters: [virtualCounter]
    });
    message('success', i18next.t('messages.virtualCounterParamsChange'));
  } catch (error) {
    yield dispatchFail(VIRTUAL_COUNTER_UPDATE, error.message);
  }
}

function* deleteVirtualCounters(action) {
  const { projectId, virtualCounterIds: ids  } = action.payload;
  try {
    const response = yield request(
      `/projects/${projectId}/virtual_counters/`,
      'DELETE',
      ids
    );

    if (response.updatedScenarios && response.updatedScenarios.length) {
      yield dispatch(SCENARIOS_UPDATED, response.updatedScenarios);
    }
    if (response.updatedIndicatorPanels && response.updatedIndicatorPanels.length) {
      yield dispatchSuccess(INDICATOR_PANEL_UPDATE, {
        projectId,
        updatedIndicatorPanels: response.updatedIndicatorPanels
      });
    }
    if (response.removedValidateMessageIds && response.removedValidateMessageIds.length) {
      yield dispatchSuccess(PROJECT_VALIDATE_DELETE, {
        projectId,
        validateMessageIds: response.removedValidateMessageIds
      });
    }

    yield dispatchSuccess(VIRTUAL_COUNTERS_DELETE, {
      projectId,
      virtualCounterIds: response.removedVirtualCounterIds
    });
    message('success', i18next.t('messages.virtualCounterDeleted'));
  } catch (error) {
    yield dispatchFail(VIRTUAL_COUNTERS_DELETE, error.message);
  }
}

function* pasteVirtualCounters(action) {
  const { projectId, virtualCounters, deviceId, count } = action.payload;
  try {
    const response = yield request(
      `/projects/${projectId}/virtual_counters/paste?deviceId=${deviceId}&count=${count}`,
      'POST',
      virtualCounters
    );

    yield dispatchSuccess(VIRTUAL_COUNTER_PASTE, {
      projectId: projectId,
      newVirtualCounters: response
    });
    message('success', i18next.t('messages.pasted'));
  } catch (error) {
    yield dispatchFail(VIRTUAL_COUNTER_PASTE, error.message);
  }
}

export default function* virtualCountersSagas() {
  yield takeEvery(VIRTUAL_COUNTERS_LOAD, loadVirtualCounters);
  yield takeEvery(VIRTUAL_COUNTER_CREATE, createVirtualCounter);
  yield takeEvery(VIRTUAL_COUNTER_UPDATE, updateVirtualCounter);
  yield takeEvery(VIRTUAL_COUNTER_DELETE, deleteVirtualCounters);
  yield takeEvery(VIRTUAL_COUNTER_PASTE, pasteVirtualCounters);
}
