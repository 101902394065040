import { takeEvery } from 'redux-saga/effects';
import {
  DEVICE_SHAPE_LIBRARIES_LOAD,
  DEVICE_SHAPE_LIBRARY_ADD,
  DEVICE_SHAPE_LIBRARY_UPDATE,
  DEVICE_SHAPE_LIBRARY_REMOVE,
  DEVICE_SHAPES_LOAD,
  DEVICE_SHAPE_ADD,
  DEVICE_SHAPE_UPDATE,
  DEVICE_SHAPE_REMOVE
} from 'constants/actionTypes';

import { fetch, request, dispatchSuccess, dispatchFail } from 'helpers/request';

function* loadDeviceShapeLibraries() {
  try {
    const response = yield fetch('/device_shape_libraries');
    yield dispatchSuccess(DEVICE_SHAPE_LIBRARIES_LOAD, response);
  } catch (error) {
    yield dispatchFail(DEVICE_SHAPE_LIBRARIES_LOAD, error);
  }
}

function* addDeviceShapeLibrary(action) {
  try {
    const { deviceShapeLibrary } = action.payload;
    yield request('/device_shape_libraries', 'POST', deviceShapeLibrary);
  } catch (error) {
    yield dispatchFail(DEVICE_SHAPE_LIBRARY_ADD, error);
  }
}

function* updateDeviceShapeLibrary(action) {
  try {
    const { deviceShapeLibraryId, deviceShapeLibrary } = action.payload;
    yield request(
      `/device_shape_libraries/${deviceShapeLibraryId}`,
      'PUT',
      deviceShapeLibrary
    );
  } catch (error) {
    yield dispatchFail(DEVICE_SHAPE_LIBRARY_UPDATE, error);
  }
}

function* removeDeviceShapeLibrary(action) {
  try {
    const { deviceShapeLibraryId } = action.payload;
   yield request(`/device_shape_libraries/${deviceShapeLibraryId}`, 'DELETE');
  } catch (error) {
    yield dispatchFail(DEVICE_SHAPE_LIBRARY_REMOVE, error);
  }
}

function* loadDeviceShapes() {
  try {
    const response = yield fetch('/device_shapes');
    yield dispatchSuccess(DEVICE_SHAPES_LOAD, response);
  } catch (error) {
    yield dispatchFail(DEVICE_SHAPES_LOAD, error);
  }
}

function* addDeviceShape(action) {
  try {
    const { deviceShape } = action.payload;
    yield request('/device_shapes', 'POST', deviceShape);
  } catch (error) {
    yield dispatchFail(DEVICE_SHAPE_ADD, error);
  }
}

function* updateDeviceShape(action) {
  try {
    const { deviceShapeId, deviceShape } = action.payload;
    yield request(`/device_shapes/${deviceShapeId}`, 'PUT', deviceShape);
  } catch (error) {
    yield dispatchFail(DEVICE_SHAPE_UPDATE, error);
  }
}

function* removeDeviceShape(action) {
  try {
    const { deviceShapeId } = action.payload;
    yield request(`/device_shapes/${deviceShapeId}`, 'DELETE');
  } catch (error) {
    yield dispatchFail(DEVICE_SHAPE_REMOVE, error);
  }
}

export default function* deviceShapeLibrary() {
  yield takeEvery(DEVICE_SHAPE_LIBRARIES_LOAD, loadDeviceShapeLibraries);
  yield takeEvery(DEVICE_SHAPE_LIBRARY_ADD, addDeviceShapeLibrary);
  yield takeEvery(DEVICE_SHAPE_LIBRARY_UPDATE, updateDeviceShapeLibrary);
  yield takeEvery(DEVICE_SHAPE_LIBRARY_REMOVE, removeDeviceShapeLibrary);
  yield takeEvery(DEVICE_SHAPES_LOAD, loadDeviceShapes);
  yield takeEvery(DEVICE_SHAPE_ADD, addDeviceShape);
  yield takeEvery(DEVICE_SHAPE_UPDATE, updateDeviceShape);
  yield takeEvery(DEVICE_SHAPE_REMOVE, removeDeviceShape);
}
