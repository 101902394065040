import React from 'react';
import PropTypes from 'prop-types';
import styles from './ContentNotFound.module.css';
import i18next from 'i18next';

ContentNotFound.propsTypes = {
  text: PropTypes.string,
  image: PropTypes.object,
  color: PropTypes.string,
  fontSize: PropTypes.oneOf([PropTypes.number, PropTypes.string])
};

ContentNotFound.defaultProps = {
  text: i18next.t('noData'),
  image: null,
  color: '#f8f8f8',
  fontSize: '2em'
};

export default function ContentNotFound({ text, image, color, fontSize }) {
  return (
    <div className={styles.empty} style={{ color, fontSize }}>
      {image}
      {text}
    </div>
  );
}
