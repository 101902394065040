export const INDICATOR_COLORS = [
  'NONE',
  'RED',
  'GREEN',
  'ORANGE',
  'RED_FLASHING',
  'GREEN_FLASHING',
  'ORANGE_FLASHING'
];

export const INDICATOR_ACTION_TYPES = {
  NOT_MANAGE: 'NOT_MANAGE',
  ON_OFF: 'ON_OFF',
  SECURE_DISARM: 'SECURE_DISARM',
  SECURE: 'SECURE',
  DISARM: 'DISARM'
};

export const INDICATOR_DEFAULT_VALUES = {
  DEVICE: {
    entityIds: [],
    sound: false,
    enable: 'RED',
    disable: 'GREEN',
    malfunction: 'ORANGE',
    connectionLost: 'ORANGE',
    indicatorType: 'DEVICE',
    action: INDICATOR_ACTION_TYPES.NOT_MANAGE
  },
  REGION: {
    entityIds: [],
    sound: false,
    indicatorType: 'REGION',
    action: INDICATOR_ACTION_TYPES.NOT_MANAGE
  },
  SCENARIO: {
    entityIds: [],
    indicatorType: 'SCENARIO'
  }
};

/** Идентификатор одиночного (не дублируемого) направления. */
export const SINGLE_DIRECTION_ID = 0;

export const Mro2MAction = {
  ADD: 'ADD',
  REPLACE: 'REPLACE'
};

export const Mro2MMessage = {
  MESSAGE_1: {
    id: 'MESSAGE_1',
    validActions: [Mro2MAction.ADD, Mro2MAction.REPLACE]
  },
  MESSAGE_2: {
    id: 'MESSAGE_2',
    validActions: [Mro2MAction.ADD, Mro2MAction.REPLACE]
  },
  MESSAGE_3: {
    id: 'MESSAGE_3',
    validActions: [Mro2MAction.ADD, Mro2MAction.REPLACE]
  },
  MESSAGE_4: {
    id: 'MESSAGE_4',
    validActions: [Mro2MAction.ADD, Mro2MAction.REPLACE]
  },
  MESSAGE_5: {
    id: 'MESSAGE_5',
    validActions: [Mro2MAction.ADD, Mro2MAction.REPLACE]
  },
  MESSAGE_6: {
    id: 'MESSAGE_6',
    validActions: [Mro2MAction.ADD, Mro2MAction.REPLACE]
  },
  MESSAGE_7: {
    id: 'MESSAGE_7',
    validActions: [Mro2MAction.ADD, Mro2MAction.REPLACE]
  },
  MESSAGE_8: {
    id: 'MESSAGE_8',
    validActions: [Mro2MAction.ADD, Mro2MAction.REPLACE]
  },
  LINE_INPUT: { id: 'LINE_INPUT', validActions: [Mro2MAction.REPLACE] }
};

export const INDICATOR_PANEL_TYPE = {
  OIP: 'OIP',
  BI: 'BI',
  BIU: 'BIU',
  PDU: 'PDU',
  PDU_PT: 'PDU_PT'
};
