export const KeyCodes = {
  A: 65,
  CTRL: 17,
  C: 67,
  X: 88,
  V: 86,
  Z: 90,
  SHIFT: 16,
  ENTER: 13,
  DELETE: 46,
  ESC: 27,
  PLUS: 187,
  NUM_KEYBOARD_PLUS: 107,
  MINUS: 189,
  NUM_KEYBOARD_MINUS: 109,
  DEL: 110,
  SPACE: 32,
  PAGE_UP: 33,
  PAGE_DOWN: 34,
  END: 35,
  HOME: 36,
  ARROW_LEFT: 37,
  ARROW_UP: 38,
  ARROW_RIGHT: 39,
  ARROW_DOWN: 40
};
