import { combineReducers } from 'redux';

import {
  PROJECT_LOAD_CURRENT,
  PROJECT_LOAD_ACTIVE,
  PROJECT_UPDATE_STATUS,
  INDICATOR_GROUP_ADD,
  INDICATOR_GROUP_DELETE,
  INDICATOR_GROUP_UPDATE,
  INDICATOR_PANEL_ADD,
  INDICATOR_PANEL_DELETE,
  INDICATOR_PANEL_UPDATE,
  INDICATOR_UPDATE,
  DEVICE_DELETE,
  REGION_DELETE,
  SCENARIO_DELETE,
  isLoaded
} from 'constants/actionTypes';

function groups(state = {}, { type, payload }) {
  switch (type) {
    case isLoaded(PROJECT_LOAD_CURRENT, true):
    case isLoaded(PROJECT_LOAD_ACTIVE, true):
    case isLoaded(PROJECT_UPDATE_STATUS, true): {
      const { project, indicatorGroups } = payload;
      const newState = { ...state };
      newState[project.id] = {};
      if (indicatorGroups.length) {
        indicatorGroups.forEach(indicatorGroup => {
          newState[project.id][indicatorGroup.id] = indicatorGroup;
        });
      }
      return newState;
    }
    case isLoaded(INDICATOR_GROUP_ADD, true): {
      const { projectId, newIndicatorGroup } = payload;
      if (!state[projectId]) return state;
      const newState = { ...state };
      newState[projectId][newIndicatorGroup.id] = newIndicatorGroup;
      newState[projectId] = { ...newState[projectId] };
      return newState;
    }
    case isLoaded(INDICATOR_GROUP_DELETE, true): {
      const { projectId, deletedIndicatorGroupId } = payload;
      if (!state[projectId]) return state;
      if (!state[projectId][deletedIndicatorGroupId]) return state;
      const newState = { ...state };
      delete newState[projectId][deletedIndicatorGroupId];
      newState[projectId] = { ...newState[projectId] };
      return newState;
    }
    case isLoaded(INDICATOR_GROUP_UPDATE, true): {
      const { projectId, updatedIndicatorGroup } = payload;
      if (!state[projectId]) return state;
      const newState = { ...state };
      newState[projectId][updatedIndicatorGroup.id] = updatedIndicatorGroup;
      newState[projectId] = { ...newState[projectId] };
      return newState;
    }
    default: {
      return state;
    }
  }
}

function panels(state = {}, { type, payload }) {
  switch (type) {
    case isLoaded(PROJECT_LOAD_CURRENT, true):
    case isLoaded(PROJECT_LOAD_ACTIVE, true):
    case isLoaded(PROJECT_UPDATE_STATUS, true): {
      const { project, indicatorPanels } = payload;
      const newState = { ...state };
      if (!newState[project.id]) newState[project.id] = {};
      if (indicatorPanels.length) {
        indicatorPanels.forEach(
          indicatorGroup => (newState[project.id][indicatorGroup.id] = indicatorGroup)
        );
      }
      return newState;
    }
    case isLoaded(INDICATOR_GROUP_DELETE, true): {
      const { projectId, deletedIndicatorGroupId } = payload;
      if (!state[projectId]) return state;
      let isUpdatedState = false;
      for (const indicatorId in state[projectId]) {
        if (state[projectId][indicatorId].indicatorGroupId === deletedIndicatorGroupId) {
          delete state[projectId][indicatorId];
          isUpdatedState = true;
        }
      }
      if (isUpdatedState) return { ...state };
      return state;
    }
    case isLoaded(INDICATOR_PANEL_ADD, true): {
      const { projectId, newIndicatorPanel } = payload;
      if (!state[projectId]) return state;
      const newState = { ...state };
      newState[projectId][newIndicatorPanel.id] = newIndicatorPanel;
      newState[projectId] = { ...newState[projectId] };
      return newState;
    }
    case isLoaded(INDICATOR_UPDATE, true):
    case isLoaded(INDICATOR_PANEL_UPDATE, true): {
      const { projectId, updatedIndicatorPanels } = payload;
      if (!state[projectId]) return state;
      const newState = { ...state };
      if (updatedIndicatorPanels && updatedIndicatorPanels.length) {
        updatedIndicatorPanels.forEach(indicatorPanel => {
          newState[projectId][indicatorPanel.id] = indicatorPanel;
        });
      }
      newState[projectId] = { ...newState[projectId] };
      return newState;
    }
    case isLoaded(SCENARIO_DELETE, true):
    case isLoaded(REGION_DELETE, true):
    case isLoaded(DEVICE_DELETE, true): {
      const { projectId, updatedIndicatorPanels } = payload;
      if (!state[projectId]) return state;
      if (!updatedIndicatorPanels) return state;
      if (!updatedIndicatorPanels.length) return state;
      const newState = { ...state };
      updatedIndicatorPanels.forEach(indicatorPanel => {
        newState[projectId][indicatorPanel.id] = indicatorPanel;
      });
      newState[projectId] = { ...newState[projectId] };
      return newState;
    }
    case isLoaded(INDICATOR_PANEL_DELETE, true): {
      const { projectId, deletedIndicatorPanelId } = payload;
      if (!state[projectId]) return state;
      if (!state[projectId][deletedIndicatorPanelId]) return state;
      const newState = { ...state };
      delete newState[projectId][deletedIndicatorPanelId];
      newState[projectId] = { ...newState[projectId] };
      return newState;
    }
    default:
      return state;
  }
}

export default combineReducers({
  groups,
  panels
});
