import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Countdowns, { COUNTDOWN_TYPES } from 'components/Countdowns';

import {
  getActiveDevicesHash,
  getActiveProjectId,
  getActiveScenariosHash
} from 'helpers/activeProject';
import { getDeviceProfileViewsHash } from 'helpers/deviceProfileViews';
import { getHandleModeScenariosMap } from 'helpers/activeProject';
import { getCountdownsByEntityIdMap } from 'helpers/countdowns';
import { getIsPasswordTimeDelayExpired } from 'helpers/getIsPasswordTimeDelayExpired';
import { setCurrentScenario } from 'actions/CPIU';
import { modalOpen } from 'actions/modals';
import { deleteCountdown } from 'actions/countdowns';
import { PASSWORD_FORM_NAME } from 'containers/Forms/PasswordModalForm';
import { initialize } from 'redux-form';
import { performScenarioAction } from 'actions/scenarios';
import { SCENARIO_ACTIONS, SCENARIO_STATUSES } from 'constants/scenario';
import {
  PauseCircleOutlined,
  PlayCircleOutlined,
  PlusCircleOutlined,
  ClockCircleOutlined
} from '@ant-design/icons';
import { isEmpty } from 'lodash';
import i18next from 'i18next';

const BUTTON_PRESSED_DELAY = 7000;

class CountdownWidget extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { isAnyButtonRecentlyPressed: {}, hiddenCountdownsList: [] };
  }

  onCountdownClose = id => {
    const newHiddenCountdownsList = [...this.state.hiddenCountdownsList, id];
    this.setState({ hiddenCountdownsList: newHiddenCountdownsList });
  };

  isDisabledAction = (countdown, actionId) => {
    const { type, entityId } = countdown;
    if (this.state.isAnyButtonRecentlyPressed[entityId] === true) return true;
    switch (type) {
      case COUNTDOWN_TYPES.SCENARIO:
        const { scenarioHash } = this.props;
        const scenario = scenarioHash[entityId];
        if (!scenario) return false;

        switch (actionId) {
          case SCENARIO_ACTIONS.PAUSE_DELAY:
            return scenario.statuses.includes(SCENARIO_STATUSES.DELAY_PAUSED);
          case SCENARIO_ACTIONS.RESUME_DELAY:
            return !scenario.statuses.includes(SCENARIO_STATUSES.DELAY_PAUSED);
          default:
            return false;
        }
      default:
        break;
    }
  };

  onPerformScenarioAction = (countdown, actionId) => {
    const { dispatch, activeProjectId, scenarioHash } = this.props;
    const { type, entityId } = countdown;
    this.setState({
      isAnyButtonRecentlyPressed: { ...this.state.isAnyButtonRecentlyPressed, [entityId]: true }
    });
    setTimeout(
      () =>
        this.setState({
          isAnyButtonRecentlyPressed: {
            ...this.state.isAnyButtonRecentlyPressed,
            [entityId]: false
          }
        }),
      BUTTON_PRESSED_DELAY
    );
    switch (type) {
      case COUNTDOWN_TYPES.SCENARIO:
        const scenario = scenarioHash[entityId];
        dispatch(performScenarioAction(activeProjectId, scenario.globalNo, actionId));
        break;
      default:
        break;
    }
  };

  getIconByActionId = actionId => {
    switch (actionId) {
      case SCENARIO_ACTIONS.IMMEDIATE_START:
        return <PlayCircleOutlined />;
      case SCENARIO_ACTIONS.PAUSE_DELAY:
        return <PauseCircleOutlined />;
      case SCENARIO_ACTIONS.RESUME_DELAY:
        return <ClockCircleOutlined />;
      case SCENARIO_ACTIONS.INCREASE_DELAY:
        return <PlusCircleOutlined />;
      default:
        return null;
    }
  };

  getCountdownName = countdown => {
    const { scenarioHash, devices } = this.props;
    const { type, entityId } = countdown;
    switch (type) {
      case COUNTDOWN_TYPES.DEVICE:
        const device = devices[entityId];
        return device ? `${device.name} (${device.fullAddressPath})` : '';
      case COUNTDOWN_TYPES.SCENARIO:
        const scenario = scenarioHash[entityId];
        if (!scenario?.statuses) return '';
        const isLocked = scenario.statuses.includes(SCENARIO_STATUSES.LOCKED);
        const isRunning = scenario
          ? scenario.statuses.includes(SCENARIO_STATUSES.STARTED) ||
            scenario.statuses.includes(SCENARIO_STATUSES.EXECUTING) ||
            scenario.statuses.includes(SCENARIO_STATUSES.DELAY_ACTIVE)
          : false;
        const state = isLocked
          ? i18next.t('scenarios.locked')
          : isRunning
          ? i18next.t('scenarios.started')
          : i18next.t('scenarios.stopped');
        return `${scenario.name}: ${state}`;
      case COUNTDOWN_TYPES.COMPUTER_ACTION:
        const { name, message } = countdown;
        return `${name}(${message})`;
      default:
        break;
    }
  };

  onSentScenarioToCPIU = id => {
    const { dispatch, lastEnteredPasswordData, handleModeScenariosByIdMap } = this.props;
    const isNeedToShowModal = lastEnteredPasswordData
      ? getIsPasswordTimeDelayExpired(lastEnteredPasswordData.time)
      : true;
    const scenarioWithHandleMod = handleModeScenariosByIdMap
      ? handleModeScenariosByIdMap[id]
      : null;
    const isPasswordProtected = scenarioWithHandleMod
      ? scenarioWithHandleMod.advancedParams?.passwordPresence
      : false;
    if (isPasswordProtected && isNeedToShowModal) {
      dispatch(
        initialize(PASSWORD_FORM_NAME, {
          actionType: 'sentScenarioToCPIU',
          currentScenarioId: id
        })
      );
      dispatch(modalOpen(PASSWORD_FORM_NAME));
    } else {
      dispatch(setCurrentScenario(id, lastEnteredPasswordData?.password));
    }
  };

  onDeleteCountDown = id => {
    const { dispatch } = this.props;
    dispatch(deleteCountdown(id));
  };

  render() {
    const { countdowns, cpiuSpecialData, handleModeScenariosByIdMap, isLocalHostUser } = this.props;
    const { hiddenCountdownsList } = this.state;
    if (!isEmpty(countdowns)) {
      return (
        <Countdowns
          visibleCountdowns={countdowns}
          hiddenCountdownsList={hiddenCountdownsList}
          onClose={this.onCountdownClose}
          onDeleteCountDown={this.onDeleteCountDown}
          isLocalHostUser={isLocalHostUser}
          onActionClick={this.onPerformScenarioAction}
          getIconByActionId={this.getIconByActionId}
          isDisabledAction={this.isDisabledAction}
          getCountdownName={this.getCountdownName}
          cpiuSpecialData={cpiuSpecialData}
          handleModeScenariosByIdMap={handleModeScenariosByIdMap}
          onSentScenarioToCPIU={this.onSentScenarioToCPIU}
        />
      );
    } else return null;
  }
}

const mapStateToProps = state => {
  return {
    devices: getActiveDevicesHash(state),
    countdowns: getCountdownsByEntityIdMap(state),
    baseCountdowns: state.activeProject.countdowns,
    deviceProfileViewsHash: getDeviceProfileViewsHash(state),
    activeProjectId: getActiveProjectId(state),
    scenarioHash: getActiveScenariosHash(state),
    loadActiveProjectInProgress: state.inProgress.loadActiveProject,
    cpiuSpecialData: state.activeProject.CPIU.device?.specialData || null,
    handleModeScenariosByIdMap: getHandleModeScenariosMap(state),
    isLocalHostUser: state.admin.isLocalHostUser,
    lastEnteredPasswordData: state.activeProject.CPIU.lastEnteredPasswordData
  };
};

const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(CountdownWidget);
