import React, { Component } from 'react';

import { Input } from 'antd';

export function TextArea(props) {
  const { input, allowClear, ...restProps } = props;

  const onChange = input ? input.onChange : null;
  const value = input ? input.value : null;
  return (
    <Input.TextArea
      onChange={onChange}
      value={value}
      autoSize={{ minRows: 2, maxRows: 8 }}
      allowClear={allowClear !== false}
      {...restProps}
    />
  );
}

function Password(props) {
  const { input, allowClear, ...restProps } = props;

  const onChange = input ? input.onChange : null;
  const value = input ? input.value : null;
  return <Input.Password onChange={onChange} value={value} {...restProps} />;
}

export default class InputToForm extends Component {
  static TextArea = TextArea;
  static Password = Password;
  render() {
    const { input, meta, ...restProps } = this.props;

    const onChange = input ? input.onChange : null;
    const value = input ? input.value : null;
    return <Input onChange={onChange} value={value} {...restProps} />;
  }
}
