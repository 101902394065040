import { takeEvery } from 'redux-saga/effects';

import { LICENSE_LOAD_PERMISSIONS } from 'constants/actionTypes';

import { fetch, dispatchSuccess, dispatchFail } from 'helpers/request';

function* loadLicensePermissions({ type }) {
  try {
    const response = yield fetch('/driver/license');

    yield dispatchSuccess(type, response);
  } catch (error) {
    yield dispatchFail(type, error.message);
  }
}

export default function* license() {
  yield takeEvery(LICENSE_LOAD_PERMISSIONS, loadLicensePermissions);
}
