import { combineReducers } from 'redux';
import * as actionTypes from 'constants/actionTypes';

// Редюсер для отслеживания состояния выполнения

/**
 * Функция отслеживания состояния выполения операций,
 * которые могут вызываться многократно
 */
function progressReduxer(actionName) {
  return (state = null, action) => {
    switch (action.type) {
      case actionName: {
        return true;
      }
      case actionTypes.isLoaded(actionName, false):
      case actionTypes.isLoaded(actionName, true): {
        return false;
      }
      default:
        return state;
    }
  };
}

/**
 * Функция отслеживания состояния выполения операций,
 * состояние которых нужно для закрытия некоторых модальных окон
 */
function statusReduxer(actionName) {
  return (state = null, action) => {
    switch (action.type) {
      case actionName: {
        return true;
      }
      case actionTypes.MODAL_CLOSE:
      case actionTypes.isLoaded(actionName, false): {
        return null;
      }
      case actionTypes.isLoaded(actionName, true): {
        return false;
      }
      default:
        return state;
    }
  };
}

export default combineReducers({
  // CREATE
  createProject: statusReduxer(actionTypes.PROJECT_CREATE),
  createPlan: statusReduxer(actionTypes.PLAN_CREATE),
  createPlanGroup: statusReduxer(actionTypes.PLAN_GROUP_CREATE),
  createDevice: statusReduxer(actionTypes.DEVICE_CREATE),
  createDeviceCopies: statusReduxer(actionTypes.DEVICES_COPY_AND_PASTE),
  createControlPanelUser: statusReduxer(actionTypes.CREATE_CONTROL_PANEL_USER),
  createRegion: statusReduxer(actionTypes.REGION_CREATE),
  createRegionAndConnectDevice: statusReduxer(actionTypes.REGION_CREATE_AND_CONNECT_DEVICE),
  createVirtualCounter: statusReduxer(actionTypes.VIRTUAL_COUNTER_CREATE),
  createVirtualState: statusReduxer(actionTypes.VIRTUAL_STATE_CREATE),
  // GET
  getMonitorableValuesHistory: progressReduxer(actionTypes.MONITORABLE_VALUES_GET_DEVICE_HISTORY),
  // LOAD
  loadActiveProject: progressReduxer(actionTypes.PROJECT_LOAD_ACTIVE),
  loadCurrentProject: progressReduxer(actionTypes.PROJECT_LOAD_CURRENT),
  loadActiveDevices: progressReduxer(actionTypes.DEVICES_LOAD_ACTIVE),
  loadDevices: progressReduxer(actionTypes.DEVICES_LOAD),
  getFullDevice: progressReduxer(actionTypes.DEVICE_GET_FULL),
  loadPlans: progressReduxer(actionTypes.PLANS_LOAD),
  loadPlanGroups: progressReduxer(actionTypes.PLAN_GROUPS_LOAD),
  loadActivePlans: progressReduxer(actionTypes.PLANS_LOAD_ACTIVE),
  loadActivePlanGroups: progressReduxer(actionTypes.PLAN_GROUPS_LOAD_ACTIVE),
  loadRegions: progressReduxer(actionTypes.REGIONS_LOAD),
  getFullScenario: progressReduxer(actionTypes.SCENARIO_GET_FULL),
  skudObserver: progressReduxer(actionTypes.SKUD_OBSERVER_GET),
  loadValidateMessages: progressReduxer(actionTypes.PROJECT_VALIDATE_MESSAGE_LOAD),
  loadLogEvents: progressReduxer(actionTypes.EVENTS_LOAD_LOG),
  // UPDATE
  updateProject: statusReduxer(actionTypes.PROJECT_UPDATE),
  updateProjectStatus: progressReduxer(actionTypes.PROJECT_UPDATE_STATUS),
  updateRegion: statusReduxer(actionTypes.REGION_UPDATE),
  updateControlPanelUsers: statusReduxer(actionTypes.UPDATE_CONTROL_PANEL_USERS),
  settingDeviceConfig: progressReduxer(actionTypes.ACTIVE_DEVICE_SET_CONFIG),
  regionUpdatePlanLayouts: statusReduxer(actionTypes.REGION_UPDATE_PLAN_LAYOUTS),
  updateSpecificSettings: statusReduxer(actionTypes.DEVICE_UPDATE_SPECIFIC_SETTINGS),
  updateDevicesPlanLayouts: statusReduxer(actionTypes.DEVICES_UPDATE_PLAN_LAYOUTS),
  // IMPORT
  importConfig: statusReduxer(actionTypes.DEVICE_IMPORT_CONTOL_CONFIG),
  importProject: progressReduxer(actionTypes.PROJECT_IMPORT),
  // REQUEST
  requestCurrentUser: progressReduxer(actionTypes.CURRENT_USER_REQUEST),
  requestLogin: progressReduxer(actionTypes.LOGIN_REQUEST)
});
