export type UseStorageItemsType = {
  key: string;
  onSet: (value: any) => void;
  onCheck: (value: any) => boolean;
  value: any;
  defaultValue: any | null;
};

/**
 * Функция-хелпер, позволяющая создавать однотипные
 * объекты для использования хука @name useStorage.
 * @param {string} key - ключ в LocalStorage
 * @param {function} onSet - функция для сохранения данных, аргументом принимающая
 * распарсенные данные, полученные из LocalStorage по указанному ключу
 * @param {function} onCheck - функция проверки данных с LocalStorage, аргументом принимающая
 * распарсенные данные, полученные из LocalStorage по указанному ключу  *
 * @param {any} value - данные, для сохранения в LocalStorage
 * @param {any|null} value - данные, для сохранения с помощью @name onSet, если требуется
 * инициализировать значение при неудачной проверке LocalStorage.
 *
 */
export default function getStorageItem(
  key: string,
  onSet: (value: any) => void,
  onCheck: (value: any) => boolean,
  value: any,
  defaultValue: any
) {
  return {
    key,
    onSet,
    onCheck,
    value,
    defaultValue
  };
}
