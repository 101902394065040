import i18next from 'i18next';

export const getSettings = () => ({
  iconSizes: [
    {
      label: i18next.t('componentSettings.iconSizes.vertSmall'),
      value: 12
    },
    {
      label: i18next.t('componentSettings.iconSizes.small'),
      value: 16
    },
    {
      label: i18next.t('componentSettings.iconSizes.normal'),
      value: 23,
      active: true
    },
    {
      label: i18next.t('componentSettings.iconSizes.large'),
      value: 25
    },
    {
      label: i18next.t('componentSettings.iconSizes.vertLarge'),
      value: 30
    }
  ],
  locale: i18next.t('componentSettings.locale'),
  timeFormat: 'DD.MM.YYYY HH:mm:ss',
  chartsSettings: {
    global: {
      useUTC: false
    },
    lang: {
      loading: i18next.t('loading'),
      months: [
        i18next.t('componentSettings.month.january'),
        i18next.t('componentSettings.month.february'),
        i18next.t('componentSettings.month.march'),
        i18next.t('componentSettings.month.april'),
        i18next.t('componentSettings.month.may'),
        i18next.t('componentSettings.month.june'),
        i18next.t('componentSettings.month.july'),
        i18next.t('componentSettings.month.august'),
        i18next.t('componentSettings.month.september'),
        i18next.t('componentSettings.month.october'),
        i18next.t('componentSettings.month.november'),
        i18next.t('componentSettings.month.december')
      ],
      weekdays: [
        i18next.t('componentSettings.weekdays.sunday'),
        i18next.t('componentSettings.weekdays.monday'),
        i18next.t('componentSettings.weekdays.tuesday'),
        i18next.t('componentSettings.weekdays.wednesday'),
        i18next.t('componentSettings.weekdays.thursday'),
        i18next.t('componentSettings.weekdays.friday'),
        i18next.t('componentSettings.weekdays.saturday')
      ],
      shortMonths: [
        i18next.t('componentSettings.shortMonths.jun'),
        i18next.t('componentSettings.shortMonths.feb'),
        i18next.t('componentSettings.shortMonths.mar'),
        i18next.t('componentSettings.shortMonths.apr'),
        i18next.t('componentSettings.shortMonths.may'),
        i18next.t('componentSettings.shortMonths.june'),
        i18next.t('componentSettings.shortMonths.july'),
        i18next.t('componentSettings.shortMonths.aug'),
        i18next.t('componentSettings.shortMonths.sept'),
        i18next.t('componentSettings.shortMonths.oct'),
        i18next.t('componentSettings.shortMonths.nov'),
        i18next.t('componentSettings.shortMonths.dec')
      ],
      exportButtonTitle: i18next.t('componentSettings.exportButtonTitle'),
      printButtonTitle: i18next.t('componentSettings.printButtonTitle'),
      rangeSelectorFrom: i18next.t('componentSettings.rangeSelectorFrom'),
      rangeSelectorTo: i18next.t('componentSettings.rangeSelectorTo'),
      rangeSelectorZoom: i18next.t('componentSettings.rangeSelectorZoom'),
      downloadPNG: i18next.t('buttons.downloadSomething', {
        something: i18next.t('imageFormats.PNG')
      }),
      downloadJPEG: i18next.t('buttons.downloadSomething', {
        something: i18next.t('imageFormats.JPEG')
      }),
      downloadPDF: i18next.t('buttons.downloadSomething', {
        something: i18next.t('imageFormats.PDF')
      }),
      downloadSVG: i18next.t('buttons.downloadSomething', {
        something: i18next.t('imageFormats.SVG')
      }),
      printChart: i18next.t('componentSettings.printChart')
    }
  },
  NEW_EVENTS_RETENTION_TIME_MS: 5000,
  NEW_EVENTS_NOTIFICATIONS_DURATION_S: 10,
  MAX_NEW_NOTIFICATIONS_COUNT_ON_SCREEN: 4
});

export const timeFormat = getSettings().timeFormat;
