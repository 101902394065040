import { takeEvery } from 'redux-saga/effects';
import message from 'components/message';

import {
  SCENARIO_CONSTANTS_LOAD,
  SCENARIOS_LOAD,
  SCENARIO_CREATE,
  SCENARIO_UPDATE_BASIC_PARAMS,
  SCENARIO_UPDATE_ADVANCED_PARAMS,
  SCENARIO_DELETE,
  SCENARIO_ADD_TL_BLOCK,
  SCENARIO_UPDATE_TL_BLOCK,
  SCENARIO_REMOVE_TL_BLOCK,
  SCENARIO_UPDATE_START_LOGIC,
  SCENARIO_UPDATE_STOP_LOGIC,
  PERFORM_SCENARIO_ACTION,
  SCENARIO_GET_FULL,
  ACTIVE_SCENARIO_UPDATED,
  PROJECT_VALIDATE_DELETE,
  SET_LAST_ENTERED_PASSWORD_DATA
} from 'constants/actionTypes';

import { fetch, request, dispatchSuccess, dispatchFail, dispatch } from 'helpers/request';
import i18next from 'i18next';
import { ISSUE_ACTIONS } from '../constants/issueActions';

function* loadScenarioConstants(action) {
  try {
    let dictionaries = yield fetch(`/scenario_dictionaries`);
    let triggerTypes = yield fetch(`/scenario_trigger_types`);
    let actionTypes = yield fetch(`/scenario_action_types`);

    if (!dictionaries) dictionaries = {};
    if (!triggerTypes || !triggerTypes.length) triggerTypes = [];
    if (!actionTypes || !actionTypes.length) actionTypes = [];

    yield dispatchSuccess(SCENARIO_CONSTANTS_LOAD, { dictionaries, triggerTypes, actionTypes });
  } catch (error) {
    yield dispatchFail(SCENARIO_CONSTANTS_LOAD, error.message);
  }
}

function* loadScenarios(action) {
  const projectId = action.payload;
  try {
    const scenarios = yield fetch(`/projects/${projectId}/scenarios`);

    yield dispatchSuccess(SCENARIOS_LOAD, { projectId: projectId, scenarios: scenarios });
  } catch (error) {
    yield dispatchFail(SCENARIOS_LOAD, error.message);
  }
}

function* getFullScenario(action) {
  const { projectId, scenarioId } = action.payload;
  try {
    const response = yield fetch(`/projects/${projectId}/scenarios/${scenarioId}`);

    yield dispatchSuccess(SCENARIO_GET_FULL, { projectId, scenario: response });
    if (response.active) yield dispatch(ACTIVE_SCENARIO_UPDATED, response);
  } catch (error) {
    yield dispatchFail(SCENARIO_GET_FULL, error.message);
  }
}

function* createScenario(action) {
  const { projectId, scenarioBasicParams } = action.payload;
  try {
    const scenario = yield request(`/projects/${projectId}/scenarios`, 'POST', scenarioBasicParams);

    yield dispatchSuccess(SCENARIO_CREATE, { projectId: projectId, scenario: scenario });
    message('success', i18next.t('messages.scenarioAdded'));
  } catch (error) {
    yield dispatchFail(SCENARIO_CREATE, error.message);
  }
}

function* deleteScenario(action) {
  const { projectId, scenarioId } = action.payload;
  try {
    const response = yield request(`/projects/${projectId}/scenarios/${scenarioId}`, 'DELETE');

    if (response.removedValidateMessageIds && response.removedValidateMessageIds.length) {
      yield dispatchSuccess(PROJECT_VALIDATE_DELETE, {
        projectId,
        validateMessageIds: response.removedValidateMessageIds
      });
    }

    yield dispatchSuccess(SCENARIO_DELETE, {
      projectId: projectId,
      scenarioId: scenarioId,
      updatedDevices: response.updatedDevices,
      updatedIndicatorPanels: response.updatedIndicatorPanels
    });
    message('success', i18next.t('messages.scenarioDeleted'));
  } catch (error) {
    yield dispatchFail(SCENARIO_DELETE, error.message);
  }
}

function getScenarioUpdateSaga(actionType, entityName, httpMethod, urlTail, msgId) {
  return function*(action) {
    const { projectId, scenarioId } = action.payload;
    const entity = action.payload[entityName];
    try {
      const data = yield request(
        `/projects/${projectId}/scenarios/${scenarioId}${urlTail}`,
        httpMethod,
        entity
      );

      const isArray = data && data.length;
      yield dispatchSuccess(actionType, {
        projectId: projectId,
        scenario: !isArray ? data : null,
        scenarios: isArray ? data : null
      });
      message.success(i18next.t(msgId));
    } catch (error) {
      yield dispatchFail(actionType, error.message);
    }
  };
}

function* updateScenarioTimeLineBlock(action) {
  const { projectId, scenarioId, timeLineBlockNo, timeLineBlock } = action.payload;
  try {
    const scenario = yield request(
      `/projects/${projectId}/scenarios/${scenarioId}/time_line_blocks/${timeLineBlockNo}`,
      'PUT',
      timeLineBlock
    );

    yield dispatchSuccess(SCENARIO_UPDATE_TL_BLOCK, { projectId: projectId, scenario: scenario });
    message('success', i18next.t('messages.scenarioBlockUpdated'));
  } catch (error) {
    yield dispatchFail(SCENARIO_UPDATE_TL_BLOCK, error.message);
  }
}

function* removeScenarioTimeLineBlock(action) {
  const { projectId, scenarioId, timeLineBlockNo } = action.payload;
  try {
    const scenario = yield request(
      `/projects/${projectId}/scenarios/${scenarioId}/time_line_blocks/${timeLineBlockNo}`,
      'DELETE'
    );

    yield dispatchSuccess(SCENARIO_REMOVE_TL_BLOCK, { projectId: projectId, scenario: scenario });
    message('success', i18next.t('messages.scenarioBlockDeleted'));
  } catch (error) {
    yield dispatchFail(SCENARIO_REMOVE_TL_BLOCK, error.message);
  }
}

function* performScenarioAction({ type, payload }) {
  const { projectId, scenarioNo, actionId, password } = payload;
  const newIssue = {
    action: ISSUE_ACTIONS.PERFORM_SCENARIO_ACTION,
    projectId,
    parameters: {
      scenarioNo,
      actionId
    }
  };
  try {
    const passwordQueryString = password ? `?password=${password}` : '';
    const response = yield request(`/issues${passwordQueryString}`, 'POST', newIssue);

    yield dispatchSuccess(type, response);
    yield password && dispatch(SET_LAST_ENTERED_PASSWORD_DATA, { password, time: Date.now() });
  } catch (error) {
    yield dispatchFail(type, error);
  }
}

export default function* scenariosSagas() {
  yield takeEvery(SCENARIO_CONSTANTS_LOAD, loadScenarioConstants);
  yield takeEvery(SCENARIOS_LOAD, loadScenarios);
  yield takeEvery(SCENARIO_GET_FULL, getFullScenario);
  yield takeEvery(SCENARIO_CREATE, createScenario);
  yield takeEvery(SCENARIO_DELETE, deleteScenario);
  yield takeEvery(
    SCENARIO_UPDATE_BASIC_PARAMS,
    getScenarioUpdateSaga(
      SCENARIO_UPDATE_BASIC_PARAMS,
      'scenarioBasicParams',
      'PUT',
      '?basic_params',
      'messages.scenarioParamsUpdated'
    )
  );
  yield takeEvery(
    SCENARIO_UPDATE_ADVANCED_PARAMS,
    getScenarioUpdateSaga(
      SCENARIO_UPDATE_ADVANCED_PARAMS,
      'scenarioAdvancedParams',
      'PUT',
      '?advanced_params',
      'messages.scenarioParamsUpdated'
    )
  );
  yield takeEvery(
    SCENARIO_ADD_TL_BLOCK,
    getScenarioUpdateSaga(
      SCENARIO_ADD_TL_BLOCK,
      'timeLineBlock',
      'POST',
      '/time_line_blocks',
      'messages.blockAddedToScenario'
    )
  );
  yield takeEvery(SCENARIO_UPDATE_TL_BLOCK, updateScenarioTimeLineBlock);
  yield takeEvery(SCENARIO_REMOVE_TL_BLOCK, removeScenarioTimeLineBlock);
  yield takeEvery(
    SCENARIO_UPDATE_START_LOGIC,
    getScenarioUpdateSaga(
      SCENARIO_UPDATE_START_LOGIC,
      'logicBlock',
      'PUT',
      '?start_logic',
      'messages.logicChanged'
    )
  );
  yield takeEvery(
    SCENARIO_UPDATE_STOP_LOGIC,
    getScenarioUpdateSaga(
      SCENARIO_UPDATE_STOP_LOGIC,
      'logicBlock',
      'PUT',
      '?stop_logic',
      'messages.logicChanged'
    )
  );
  yield takeEvery(PERFORM_SCENARIO_ACTION, performScenarioAction);
}
