import { createSelector } from 'reselect';
import SUBSYSTEMS from 'constants/subsystems';

const securityOn = process.env.REACT_APP_SECURITY_ON === 'true';

/**
 * Получить список прав групп переданного пользователя
 * @param user пользователь
 * @param userGroups список (map<groupId>: <group>) групп пользователей
 * @return список прав групп переданного пользователя
 */
export function getUserGroupPermissions(user, userGroups) {
  let permissions = [];
  if (user && userGroups) {
    for (let groupId of user.userGroupIds) {
      if (userGroups[groupId]) permissions = [...permissions, ...userGroups[groupId].permissionIds];
    }
  }
  return permissions;
}

/**
 * Получить текущего пользователя
 * @param {Object} state
 * @return {Object|null}
 */
export const getCurrentUser = createSelector(
  [({ currentUser }) => currentUser, ({ drivers }) => drivers],
  (currentUser, drivers) => {
    if (!currentUser) return null;

    const driverAttributes =
      currentUser.driverProfileId && drivers[currentUser.driverProfileId]
        ? drivers[currentUser.driverProfileId].attributes
        : null;
    if (!driverAttributes || Object.keys(driverAttributes).length === 0) return currentUser;

    /*
     * Чтобы учесть подсистемы, заданные драйвером, подменим объект пользователя,
     * соединив заданную в нем фильтрацию с фильтрацией по подсистемам, заданным в атрибутах драйвера.
     */
    const substitutedUser = { ...currentUser };
    substitutedUser.filterTags = { ...currentUser.filterTags };
    substitutedUser.filterTags.tags = [...currentUser.filterTags.tags];
    if (substitutedUser.filterTagsOn) {
      substitutedUser.filterTags.tags = substitutedUser.filterTags.tags.filter(tag => {
        if (tag === SUBSYSTEMS.FIRE.id && !driverAttributes.fireSubsystem) return false;
        if (tag === SUBSYSTEMS.SECURITY.id && !driverAttributes.securitySubsystem) return false;
        if (tag === SUBSYSTEMS.SKUD.id && !driverAttributes.skudSubsystem) return false;
        return true;
      });
    } else if (
      !driverAttributes.fireSubsystem ||
      !driverAttributes.securitySubsystem ||
      !driverAttributes.skudSubsystem ||
      !driverAttributes.autoSubsystem
    ) {
      substitutedUser.filterTagsOn = true;
      substitutedUser.filteringOnlyByDriver = true;
      substitutedUser.filterTags.tags = [SUBSYSTEMS.GENERAL.id];
      if (driverAttributes.fireSubsystem) substitutedUser.filterTags.tags.push(SUBSYSTEMS.FIRE.id);
      if (driverAttributes.securitySubsystem)
        substitutedUser.filterTags.tags.push(SUBSYSTEMS.SECURITY.id);
      if (driverAttributes.skudSubsystem) substitutedUser.filterTags.tags.push(SUBSYSTEMS.SKUD.id);
    }
    return substitutedUser;
  }
);

/**
 * Узнать имеет ли пользователь доступ
 * @param {Object|null} user - пользователь
 * @param {String} role - роль
 * @return {boolean}
 */
export const hasRole = securityOn
  ? (user, role) => {
      if (!securityOn) return true;
      if (user) {
        const allPermissions = [...(user.groupPermissions || []), ...(user.permissionIds || [])];
        return allPermissions.includes(role);
      } else return false;
    }
  : () => true;

/**
 * Получить допустимые данные системных привелегий.
 * @returns {{permissions: array, targets: array, accessLevels: array}}
 */
export const getSystemPermissionsData = createSelector(
  [
    ({ currentUser }) => currentUser,
    ({ drivers }) => drivers,
    ({ admin: { permissions } }) => permissions
  ],
  (currentUser, drivers, permissionsData) => {
    if (
      currentUser &&
      currentUser.driverProfileId &&
      drivers &&
      drivers[currentUser.driverProfileId] &&
      drivers[currentUser.driverProfileId].attributes &&
      permissionsData
    ) {
      const attributes = drivers[currentUser.driverProfileId].attributes;
      const { permissions, targets, accessLevels } = permissionsData;
      const filteredPermData = { accessLevels };
      filteredPermData.targets = targets.filter(target => {
        if (target.subsystem === SUBSYSTEMS.GENERAL.id) return true;
        if (!attributes.fireSubsystem && target.subsystem === SUBSYSTEMS.FIRE.id) return false;
        if (!attributes.securitySubsystem && target.subsystem === SUBSYSTEMS.SECURITY.id)
          return false;
        if (!attributes.skudSubsystem && target.subsystem === SUBSYSTEMS.SKUD.id) return false;
        return true;
      });
      filteredPermData.permissions = permissions.filter(
        perm =>
          !perm.targetId || !!filteredPermData.targets.find(target => target.id === perm.targetId)
      );
      return filteredPermData;
    }
    return permissionsData;
  }
);

/**
 * Получить список пользователей
 */
export const getUsers = createSelector(
  (state) => state.admin.users,
  (users) => users
);
