import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Status } from 'constants/project';
import { LOCAL_STORAGE } from 'constants/keys';
import { DEFAULT } from 'containers/Widgets/PlanViewer/PlanRenderer';
import { setPriorityMode } from 'actions/widgets';

const PriorityModeLoader = () => {
  const activeProject = useSelector((state: any) => state.activeProject.activeProject);
  const dispatch = useDispatch();
  const isStorageDataLoaded = useRef(false);

  useEffect(() => {
    if (isStorageDataLoaded.current) return;
    if (activeProject?.status === Status.ACTIVE && activeProject.id) {
      const storagePriorityMode = localStorage.getItem(LOCAL_STORAGE.PLAN_VIEWER_KEYS.PRIORITY_MOD);
      const parsedStorageData = storagePriorityMode && JSON.parse(storagePriorityMode);
      if (typeof parsedStorageData === 'boolean') dispatch(setPriorityMode(parsedStorageData));
      else dispatch(setPriorityMode(DEFAULT.PRIORITY_MODE));
      isStorageDataLoaded.current = true;
    }
  }, [activeProject, dispatch]);

  return null;
};

export default React.memo(PriorityModeLoader);
