import {
  PLAN_GROUP_CREATE,
  PLAN_GROUP_UPDATE,
  PLAN_GROUP_DELETE,
  PLAN_CREATE,
  PLAN_UPDATE,
  PLAN_DELETE,
  PLAN_LOAD_BACKGROUNDS,
  PLAN_ADD_BACKGROUNDS,
  PLAN_DELETE_BACKGROUND,
  PLAN_DELETE_OBJECTS,
  PLAN_FIND_DEVICE,
  PLAN_SELECTED,
  PLAN_ADD_TEXT,
  COPY_AND_PASTE_OBJECTS_ON_PLAN,
  CUT_AND_PASTE_OBJECTS_ON_PLAN,
  PLAN_DELETE_TEXT,
  PLAN_UPDATE_TEXT
} from 'constants/actionTypes';

import { createAction } from 'redux-actions';

export const createPlanGroup = createAction(PLAN_GROUP_CREATE, (planGroup, projectId) => ({
  planGroup,
  projectId
}));
export const updatePlanGroup = createAction(PLAN_GROUP_UPDATE, (planGroup, projectId) => ({
  planGroup,
  projectId
}));
export const deletePlanGroup = createAction(PLAN_GROUP_DELETE, (planGroupId, projectId) => ({
  planGroupId,
  projectId
}));
export const createPlan = createAction(PLAN_CREATE, (plan, projectId) => ({
  plan,
  projectId
}));
export const updatePlan = createAction(PLAN_UPDATE, (plan, projectId) => ({
  plan,
  projectId
}));
export const copyAndPasteObjectsOnPlan = createAction(
  COPY_AND_PASTE_OBJECTS_ON_PLAN,
  (objects, projectId, planId, rebindDevices, callback) => ({
    planId,
    projectId,
    objects,
    rebindDevices,
    callback
  })
);
export const cutAndPasteObjectsOnPlan = createAction(
  CUT_AND_PASTE_OBJECTS_ON_PLAN,
  (objects, projectId, callback) => ({
    projectId,
    objects,
    callback
  })
);
export const deletePlan = createAction(PLAN_DELETE, (planId, projectId) => ({
  planId,
  projectId
}));

export const loadPlanBackgrounds = createAction(
  PLAN_LOAD_BACKGROUNDS,
  (projectId, planId, callback) => ({
    planId,
    projectId,
    callback
  })
);

export const addPlanBackgrounds = createAction(
  PLAN_ADD_BACKGROUNDS,
  (projectId, planId, backgrounds, callback) => ({
    planId,
    projectId,
    backgrounds,
    callback
  })
);

export const deletePlanBackground = createAction(
  PLAN_DELETE_BACKGROUND,
  (projectId, planId, backgroundId, callback) => ({
    projectId,
    planId,
    backgroundId,
    callback
  })
);

export const deletePlanObjects = createAction(
  PLAN_DELETE_OBJECTS,
  (projectId, planId, objects) => ({
    projectId,
    planId,
    objects
  })
);

export const findDeviceOnPlanById = createAction(PLAN_FIND_DEVICE, deviceId => deviceId);

export const selectedPlanId = createAction(PLAN_SELECTED, planId => planId);

export const addPlanText = createAction(PLAN_ADD_TEXT, (projectId, planId, text) => ({
  planId,
  projectId,
  text
}));

export const updatePlanText = createAction(PLAN_UPDATE_TEXT, (projectId, planId, text) => ({
  planId,
  projectId,
  text
}));

export const deletePlanText = createAction(PLAN_DELETE_TEXT, (projectId, planId, textId) => ({
  projectId,
  planId,
  textId
}));
