import React from 'react';
import styled from 'styled-components';

import ConnectedRow from 'components/Table/ConnectedRow';

const getCellWidth = p => {
  return `flex: ${p.width ? `0 0 ${p.width}px` : '1 0 150px'};`;
};

const WrappedHeader = ({ getRef = () => null, ...restProps }) => (
  <div {...restProps} ref={getRef} />
);

const TableHeader = styled(WrappedHeader)`
  display: flex;
  height: 25px;
  overflow-y: scroll !important;
  background-color: #e1e1e1;
  overflow-x: hidden !important;
`;

const TableHeaderCell = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  align-items: center;
  height: 24px;
  padding: 0 0 0 8px;
  ${getCellWidth};
`;

const Row = styled(ConnectedRow)`
  display: flex;
  flex-flow: row nowrap;
  ${p => `background: ${p.color || '#fff'}`} !important;
  ${p => (p.rowWidth ? `min-width:${p.rowWidth - 55}px` : '')};
  ${p => (p.width ? `width:${p.width - 55}px` : '')};
`;

const Cell = styled.div`
  display: flex;
  flex-flow: row nowrap;
  ${getCellWidth};
  height: 25px;
  padding: 0 8px;
  align-items: center;
  border-left: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  overflow: hidden;
  & .cell-content {
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    ${p => {
      return p.steps ? `padding-left: ${20 * p.steps}px;` : '';
    }};
  }
`;

export { Row, Cell, TableHeader, TableHeaderCell };
