import {
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  CURRENT_USER_REQUEST,
  CURRENT_USER_UPDATE,
  isLoaded
} from 'constants/actionTypes';

const initialState = JSON.parse(localStorage.getItem('currentUser') || '{}');

export default function currentUser(state = initialState, { type, payload }) {
  switch (type) {
    case isLoaded(CURRENT_USER_REQUEST, false): {
      localStorage.setItem('currentUser', JSON.stringify({}));
      return {};
    }
    case isLoaded(CURRENT_USER_REQUEST, true): {
      localStorage.setItem('currentUser', JSON.stringify(payload));
      return payload;
    }
    case isLoaded(LOGIN_REQUEST, true): {
      localStorage.setItem('currentUser', JSON.stringify(payload));
      return payload;
    }
    case isLoaded(LOGIN_REQUEST, false): {
      localStorage.setItem('currentUser', JSON.stringify({}));
      return { error: payload };
    }
    case isLoaded(LOGOUT_REQUEST, true): {
      localStorage.setItem('currentUser', JSON.stringify({}));
      return payload;
    }
    case isLoaded(LOGOUT_REQUEST, false): {
      localStorage.setItem('currentUser', JSON.stringify({}));
      return { error: payload };
    } 
    case isLoaded(CURRENT_USER_UPDATE, true): {
      localStorage.setItem('currentUser', JSON.stringify(payload));
      return payload;
    }

    default:
      return state;
  }
}
