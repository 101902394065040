/**
 * Пользователи
 */
// загрузка списка пользователей
export const USERS_LOAD = 'USERS_LOAD';
// создание пользователя
export const USER_CREATE = 'USER_CREATE';
// изменение пользователя
export const USER_UPDATE = 'USER_UPDATE';
// изменение текущего пользователя
export const CURRENT_USER_UPDATE = 'CURRENT_USER_UPDATE';
// удаление пользователя
export const USER_DELETE = 'USER_DELETE';
// выбрать пользователя
export const USER_SELECT = 'USER_SELECT';
// установить находится ли текущий пользователь на localhost
export const SET_LOCAL_HOST_USER = 'SET_LOCAL_HOST_USER';

/**
 * Группы пользователей
 */
// загрузка списка групп пользователей
export const USER_GROUPS_LOAD = 'USER_GROUPS_LOAD';
// создание группы пользователей
export const USER_GROUP_CREATE = 'USER_GROUP_CREATE';
// изменение группы пользователей
export const USER_GROUP_UPDATE = 'USER_GROUP_UPDATE';
// удаление группы пользователей
export const USER_GROUP_DELETE = 'USER_GROUP_DELETE';
// выбрать группу пользователей
export const USER_GROUP_SELECT = 'USER_GROUP_SELECT';
