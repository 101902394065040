/**
* Возвращает вес строки в байтах
* @param {string} string
* @return {number}
*/
export function getStringByteLength(string) {
  let i = 0,
    byteLength = 0,
    ch = 0;
  for (i; i < string.length; i++) {
    ch = string.charCodeAt(i);
    if (ch <= 127) {
      byteLength++;
    } else if (ch <= 2047) {
      byteLength += 2;
    } else if (ch <= 65535) {
      byteLength += 3;
    } else if (ch <= 2097151) {
      byteLength += 4;
    } else if (ch <= 67108863) {
      byteLength += 5;
    } else {
      byteLength += 6;
    }
  }
  return byteLength;
}

/* Взято здесь: https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/ */
export function idHash(str) {
  var hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}
