import { combineReducers } from 'redux';

import {
  SET_CPIU_PIN_TO_PANEL_STATUS,
  SET_CPIU_LOGIC_ENTITIES,
  GET_CPIU_HANDLE_MODE_SCENARIOS,
  SET_CPIU_FILTERS_VISIBILITY,
  SET_CPIU_ENTITIES_BY_PLAN_ID,
  ACTIVE_DEVICES_UPDATED,
  ACTIVE_DEVICE_UPDATED,
  UPDATE_CPIU_DEVICE,
  isLoaded,
  SET_CPIU_CURRENT_SCENARIO,
  SET_CPIU_QUICK_ACCESS_SCENARIOS,
  PROJECT_UPDATE_STATUS,
  PROJECT_LOAD_ACTIVE,
  PROJECT_LOAD_CURRENT,
  SET_LAST_ENTERED_PASSWORD_DATA,
  SET_SCENARIO_NAME_BLINKING
} from 'constants/actionTypes';
import { ActiveFieldOptionsType } from 'containers/Widgets/CPIU/localHelpers/getCPIUScenarioFilters';
import { ActiveScenarioView } from 'backendToTSTypes/ScenarioBasicParams';
import { TreeItemDeviceView } from 'backendToTSTypes/TreeItemDeviceView';
import { LabeledValue } from 'antd/lib/select';

const CPIU_TYPE = 'CPIU';

type CPIUActionType<T> = {
  type: string;
  payload: T;
};

type CPIUDeviceActionType =
  | {
      type: typeof ACTIVE_DEVICE_UPDATED;
      payload: TreeItemDeviceView;
    }
  | {
      type: typeof SET_CPIU_CURRENT_SCENARIO | typeof UPDATE_CPIU_DEVICE;
      payload: TreeItemDeviceView | null;
    }
  | {
      type: typeof ACTIVE_DEVICES_UPDATED;
      payload: TreeItemDeviceView[];
    };

export type LastEnteredPasswordDataType = { password: string; time: number };

export type SelectedScenario = {
  name: string;
  id: string;
} | null;

export type ScenariosListByEntitiesIdMapType = {
  regionsByScenariosMap: Record<string, Set<string>>;
  devicesByScenariosMap: Record<string, Set<string>>;
} | null;

export type EntitiesByPlanIdMapType = Record<string, Record<'devicesIds' | 'regionsIds', string[]>>;

export type FiltersType = {
  isAutofilterOn: boolean;
  filterEntities: ActiveFieldOptionsType;
};

function isScenariosFiltersVisible(state = true, { type, payload }: CPIUActionType<boolean>) {
  switch (type) {
    case SET_CPIU_FILTERS_VISIBILITY: {
      return payload;
    }
    case isLoaded(PROJECT_UPDATE_STATUS, true):
    case isLoaded(PROJECT_LOAD_ACTIVE, true):
    case isLoaded(PROJECT_LOAD_CURRENT, true): {
      const { scenarios, plans } = payload as any;
      if (!scenarios.length || !plans.length) return false;
      if (!state) return true;
      return state;
    }
    default: {
      return state;
    }
  }
}

function isPinToPanel(state = false, { type, payload }: CPIUActionType<boolean>) {
  switch (type) {
    case SET_CPIU_PIN_TO_PANEL_STATUS: {
      return payload;
    }
    default: {
      return state;
    }
  }
}

function logicEntities(
  state = null,
  { type, payload }: CPIUActionType<ScenariosListByEntitiesIdMapType>
) {
  switch (type) {
    case SET_CPIU_LOGIC_ENTITIES: {
      return payload;
    }
    case isLoaded(PROJECT_UPDATE_STATUS, true): {
      const { project } = payload as any;
      if (project.status !== 'ACTIVE') {
        return null;
      } else return state;
    }
    default: {
      return state;
    }
  }
}

function handleModeScenarios(state = [], { type, payload }: CPIUActionType<ActiveScenarioView[]>) {
  switch (type) {
    case isLoaded(GET_CPIU_HANDLE_MODE_SCENARIOS, true): {
      return payload;
    }
    default: {
      return state;
    }
  }
}

function entitiesByPlanId(
  state = null,
  { type, payload }: CPIUActionType<EntitiesByPlanIdMapType>
) {
  switch (type) {
    case SET_CPIU_ENTITIES_BY_PLAN_ID: {
      return payload;
    }
    case isLoaded(PROJECT_UPDATE_STATUS, true): {
      const { project } = payload as any;
      if (project.status !== 'ACTIVE') {
        return null;
      } else return state;
    }
    default: {
      return state;
    }
  }
}

function quickAccessScenariosList(state = [], { type, payload }: CPIUActionType<LabeledValue[]>) {
  switch (type) {
    case SET_CPIU_QUICK_ACCESS_SCENARIOS: {
      return payload;
    }
    case isLoaded(PROJECT_UPDATE_STATUS, true): {
      const { project } = payload as any;
      if (project.status !== 'ACTIVE') return [];
      else return state;
    }
    default: {
      return state;
    }
  }
}

function lastEnteredPasswordData(
  state = null,
  { type, payload }: CPIUActionType<LastEnteredPasswordDataType | null>
) {
  switch (type) {
    case SET_LAST_ENTERED_PASSWORD_DATA: {
      return payload;
    }
    default: {
      return state;
    }
  }
}

function device(state: TreeItemDeviceView | null = null, { type, payload }: CPIUDeviceActionType) {
  switch (type) {
    case isLoaded(PROJECT_UPDATE_STATUS, true): {
      const { project } = payload as any;
      if (project.status !== 'ACTIVE') return null;
      else {
        const { devices } = payload as any;
        return getCPIUDevice(devices);
      }
    }
    case isLoaded(PROJECT_LOAD_ACTIVE, true):
    case isLoaded(PROJECT_LOAD_CURRENT, true): {
      const { devices } = payload as any;
      return getCPIUDevice(devices);
    }
    case ACTIVE_DEVICES_UPDATED: {
      if (!state) return state;
      const CPIUDevice = payload.find(device => device.id === state.id);
      if (CPIUDevice) return CPIUDevice;
      return state;
    }
    case ACTIVE_DEVICE_UPDATED: {
      if (!state || state.id !== payload.id) return state;
      return payload;
    }
    case isLoaded(SET_CPIU_CURRENT_SCENARIO, true):
    case UPDATE_CPIU_DEVICE: {
      return payload;
    }
    default: {
      return state;
    }
  }
}

function isScenarioNameBlinking(state = false, { type, payload }: CPIUActionType<boolean>) {
  switch (type) {
    case SET_SCENARIO_NAME_BLINKING: {
      return payload;
    }
    default: {
      return state;
    }
  }
}

export default combineReducers({
  isScenariosFiltersVisible,
  isScenarioNameBlinking,
  isPinToPanel,
  device,
  logicEntities,
  handleModeScenarios,
  entitiesByPlanId,
  lastEnteredPasswordData,
  quickAccessScenariosList
});

function getCPIUDevice(devices: TreeItemDeviceView[]) {
  let updatedCPIU;
  for (const device of devices) {
    if (device.specificSettings?.type === CPIU_TYPE) {
      updatedCPIU = device;
      break;
    }
  }
  if (updatedCPIU) return updatedCPIU;
  return null;
}
