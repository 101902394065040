import { createSelector } from 'reselect';

/**
 * Получить список профилей устройств
 * @param {Object{}} state
 * @return {Array|null}
 */
export const getDeviceProfileViewsList = createSelector(getDeviceProfileViewsHash, hash =>
  Object.values(hash)
);

export function getDeviceProfileViewsHash({ deviceProfileViews }) {
  if (!deviceProfileViews) return {};
  return deviceProfileViews;
}
