import React, { Component } from 'react';
import Icon from '@ant-design/icons';

export default class ToggleButton extends Component {
  render() {
    const {
      input: { onChange, value },
      variants,
      icon,
      ...restProps
    } = this.props;
    let isPicked = true;
    let nextValue = !value;

    if (variants) {
      const [variantA, VariantB] = variants;
      if (value === variantA) {
        nextValue = VariantB;
        isPicked = false;
      } else nextValue = variantA;
    } else isPicked = value;

    const onClick = () => {
      onChange(nextValue);
    };

    return (
      <Icon
        component={icon}
        style={{
          borderWidth: `${isPicked ? '0px' : '1px'}`,
          backgroundColor: `${isPicked ? 'silver' : 'inherit'}`
        }}
        onClick={onClick}
        {...restProps}
      />
    );
  }
}
