import { takeEvery } from 'redux-saga/effects';
import {
  LOAD_CONTROL_PANEL_USERS,
  CREATE_CONTROL_PANEL_USER,
  UPDATE_CONTROL_PANEL_USERS,
  DELETE_CONTROL_PANEL_USERS,
  DEVICE_UPDATE_SPECIFIC_SETTINGS
} from 'constants/actionTypes';

import { request, dispatchSuccess, dispatchFail, fetch } from 'helpers/request';

function* loadUsers(action) {
  const { projectId } = action.payload;
  try {
    const users = yield fetch(`/projects/${projectId}/control_panel/users/`);
    yield dispatchSuccess(LOAD_CONTROL_PANEL_USERS, users);
  } catch (error) {
    yield dispatchFail(LOAD_CONTROL_PANEL_USERS, error);
  }
}

function* createUser(action) {
  const { projectId, user } = action.payload;
  user.projectId = projectId;
  try {
    const path = `/projects/${projectId}/control_panel/users/`;
    const response = yield request(path, 'POST', user);
    yield dispatchSuccess(CREATE_CONTROL_PANEL_USER, response);
  } catch (error) {
    yield dispatchFail(CREATE_CONTROL_PANEL_USER, error.message);
  }
}

function* updateUser(action) {
  const { projectId, users } = action.payload;
  try {
    const path = `/projects/${projectId}/control_panel/users/`;
    const response = yield request(path, 'PUT', users);
    yield dispatchSuccess(UPDATE_CONTROL_PANEL_USERS, response);
  } catch (error) {
    yield dispatchFail(UPDATE_CONTROL_PANEL_USERS, error.message);
  }
}

function* deleteUsers(action) {
  const { projectId, usersIds } = action.payload;
  try {
    const path = `/projects/${projectId}/control_panel/users/`;
    const response = yield request(path, 'DELETE', usersIds);
    yield dispatchSuccess(DELETE_CONTROL_PANEL_USERS, response.userIds);
    if (response.controlPanels) {
      yield dispatchSuccess(DEVICE_UPDATE_SPECIFIC_SETTINGS, {
        projectId,
        createdDevices: [],
        updatedDevices: response.controlPanels
      });
    }
  } catch (error) {
    yield dispatchFail(DELETE_CONTROL_PANEL_USERS, error.message);
  }
}

export default function* controlPanelUsers() {
  yield takeEvery(LOAD_CONTROL_PANEL_USERS, loadUsers);
  yield takeEvery(CREATE_CONTROL_PANEL_USER, createUser);
  yield takeEvery(UPDATE_CONTROL_PANEL_USERS, updateUser);
  yield takeEvery(DELETE_CONTROL_PANEL_USERS, deleteUsers);
}
