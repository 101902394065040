/**
 * СКУД
 */

export const SKUD_ENTITIES = {
  employees: {
    entityType: 'EMPLOYEE',
    entityPath: 'employees'
  },
  accessKeys: {
    entityType: 'ACCESS_KEY',
    entityPath: 'access_keys'
  },
  workSchedules: {
    entityType: 'WORK_SCHEDULE',
    entityPath: 'work_schedules'
  }
};

export const getAddActionType = entityName => `${SKUD_ENTITIES[entityName].entityType}_ADD`;
export const getUpdateActionType = entityName => `${SKUD_ENTITIES[entityName].entityType}_UPDATE`;
export const getRemoveActionType = entityName => `${SKUD_ENTITIES[entityName].entityType}_REMOVE`;

export const SKUD_TREE_ITEM_SELECT = 'SKUD_TREE_ITEM_SELECT';
export const WORK_SCHEDULES_TREE_ITEM_SELECT = 'WORK_SCHEDULES_TREE_ITEM_SELECT';

export const WORK_SCHEDULE_DAY_ADD = 'WORK_SCHEDULE_DAY_ADD';
export const WORK_SCHEDULE_DAY_UPDATE = 'WORK_SCHEDULE_DAY_UPDATE';
export const WORK_SCHEDULE_DAY_REMOVE = 'WORK_SCHEDULE_DAY_REMOVE';

export const ACCESS_GRANTED_TIME_ADD = 'ACCESS_GRANTED_TIME_ADD';
export const ACCESS_GRANTED_TIME_UPDATE = 'ACCESS_GRANTED_TIME_UPDATE';
export const ACCESS_GRANTED_TIME_REMOVE = 'ACCESS_GRANTED_TIME_REMOVE';

export const SKUD_WRITE_DB_TO_CONTROL_DEVICES = 'SKUD_WRITE_DB_TO_CONTROL_DEVICES';

/* Начать ожидание ключа доступа для чтения его значения */
export const ACCESS_KEY_START_READ_VALUE = 'ACCESS_KEY_START_READ_VALUE';
/* Остановить ожидание ключа доступ */
export const ACCESS_KEY_STOP_READ_VALUE = 'ACCESS_KEY_STOP_READ_VALUE';
/* Значение (код) ключа доступа прочитано */
export const ACCESS_KEY_VALUE_READ = 'ACCESS_KEY_VALUE_READ';
/** Получить сущность наблюдателя СКУД */
export const SKUD_OBSERVER_GET = 'SKUD_OBSERVER_GET';
/** Установить значение флага автообновления БД СКУД */
export const SKUD_OBSERVER_SET_AUTOUPDATE = 'SKUD_OBSERVER_SET_AUTOUPDATE';
