import { EVENTS_LOAD_TYPE_VIEWS, isLoaded } from 'constants/actionTypes';

export default function eventTypeViews(state = null, action) {
  switch (action.type) {
    case isLoaded(EVENTS_LOAD_TYPE_VIEWS, true): {
      return action.payload;
    }

    default:
      return state;
  }
}
