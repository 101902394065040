import { useEffect } from 'react';
import { connect } from 'react-redux';
import { CURSOR_STYLES } from 'constants/cursor';
import PlanEditor, { PlanObjects } from 'containers/Widgets/PlanEditor/PlanEditor';
import { fabric } from 'fabric';

const { Group, Line } = fabric;

const GridController = props => {
  const { fabricCanvas, isShow, currentPlan, gridSize } = props;

  useEffect(() => {
    if (!fabricCanvas) return;
    //Отключаем привязку к сетке
    if (isShow) {
      drawGrid(fabricCanvas);
    } else removeGrid(fabricCanvas);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow]);

  const getGridObject = fabricCanvas => {
    return fabricCanvas
      .getObjects()
      .find(object => object.info && object.info.gridKey === PlanObjects.GRID.key);
  };

  useEffect(() => {
    if (!fabricCanvas || !isShow) return;
    const oldGrid = getGridObject(fabricCanvas);
    if (oldGrid) {
      fabricCanvas.remove(oldGrid);
    }
    drawGrid(fabricCanvas);
    PlanEditor.scaleCanvasObjects(fabricCanvas);
    fabricCanvas.renderAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow, gridSize]);

  const drawGrid = (fabricCanvas) => {
    const { xSize, ySize } = currentPlan;
    const params = {
      stroke: 'black',
      strokeWidth: 1,
      selectable: false,
      hasControls: false
    };
    const gridLines = [];
    for (let x = 0; x <= xSize; x += gridSize) gridLines.push(new Line([x, 0, x, ySize], params));
    for (let y = 0; y <= ySize; y += gridSize) gridLines.push(new Line([0, y, xSize, y], params));
    const grid = new Group(gridLines, {
      info: { gridKey: PlanObjects.GRID.key },
      lockMovementX: true,
      lockMovementY: true,
      lockRotation: true,
      lockScalingX: true,
      lockScalingY: true,
      lockUniScaling: true,
      // закомментировать нижнюю строку для повышения производительности,
      // сетка будет размытая при приближении, но производительность улучшится
      objectCaching: false,
      hoverCursor: CURSOR_STYLES.DEFAULT,
      evented: false,
      hasControls: false,
      opacity: 0.4
    });
    grid.selectable = false;
    fabricCanvas.add(grid);
    grid.moveTo(PlanObjects.GRID.layer);
  };

  const removeGrid = fabricCanvas => {
    const oldGrid = getGridObject(fabricCanvas);
    if (oldGrid) {
      fabricCanvas.remove(oldGrid);
    }
  };

  return null;
};

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  GridController
);
