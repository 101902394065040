import { useRef } from 'react';

/**
 * Хук, позволяющий получить ссылку на значение передаваемого в хук
 * аргумента. Ссылка на полученный объект будет неизменна при
 * каждом рендере компонента в котором он будет использоваться
 */
export function useLatest<Value>(value: Value) {
  const valueRef = useRef(value);

  valueRef.current = value;

  return valueRef;
}
