import {
  REGIONS_LOAD,
  REGION_CREATE,
  REGION_DELETE,
  REGION_UPDATE,
  REGIONS_UPDATE_PLAN_LAYOUTS,
  isLoaded,
  PROJECT_DELETE,
  PROJECT_LOAD_CURRENT,
  PROJECT_LOAD_ACTIVE,
  PROJECT_UPDATE_STATUS,
  PLAN_DELETE,
  DEVICE_IMPORT_CONTOL_CONFIG,
  REGION_CREATE_AND_CONNECT_DEVICE,
  COPY_AND_PASTE_REGIONS_ON_PLAN
} from 'constants/actionTypes';

/**
 * state = regions: {
 *    <projectId>: {
 *        <regionId>: {
 *          id: <string>,
 *          ...
 *       }
 *    }
 * }
 * (см. метод GET api/v1/projects/<projectId>/region_views в тех. проекте)
 */
export default function regions(state = {}, action) {
  switch (action.type) {
    case isLoaded(REGIONS_LOAD, true): {
      const { projectId, regions } = action.payload;
      const newState = { ...state };
      if (!newState[projectId]) {
        newState[projectId] = {};
      }
      newState[projectId] = {};
      regions.forEach(region => {
        newState[projectId][region.id] = region;
      });
      return newState;
    }
    case isLoaded(DEVICE_IMPORT_CONTOL_CONFIG, true): {
      const {
        projectId,
        regions: { created: createdRegions }
      } = action.payload;
      if (!state[projectId] || !createdRegions) {
        return state;
      }
      const newState = { ...state };
      newState[projectId] = { ...newState[projectId] };
      if (createdRegions && createdRegions.length) {
        createdRegions.forEach(
          createdRegion => (newState[projectId][createdRegion.id] = createdRegion)
        );
      }
      return newState;
    }
    case isLoaded(PLAN_DELETE, true): {
      const { projectId, updatedRegions } = action.payload;
      if (!state[projectId] || !updatedRegions.length) {
        return state;
      }
      const newState = { ...state };
      newState[projectId] = { ...newState[projectId] };
      updatedRegions.forEach(
        updatedRegion => (newState[projectId][updatedRegion.id] = updatedRegion)
      );
      return newState;
    }
    case isLoaded(REGION_CREATE_AND_CONNECT_DEVICE, true):
    case isLoaded(COPY_AND_PASTE_REGIONS_ON_PLAN, true):
    case isLoaded(REGION_CREATE, true): {
      const { projectId, newRegions } = action.payload;
      if (!state[projectId] || !newRegions.length) return state;
      const newState = { ...state };
      newRegions.forEach(r => (newState[projectId][r.id] = r));
      newState[projectId] = { ...newState[projectId] };
      return newState;
    }
    case isLoaded(REGION_DELETE, true): {
      const { regionIds, projectId, updatedRegions } = action.payload;
      if (!state[projectId] || !regionIds.length) return state;
      const newState = { ...state };
      newState[projectId] = { ...newState[projectId] };
      regionIds.forEach(id => delete newState[projectId][id]);
      for (const region of updatedRegions) {
        newState[projectId][region.id] = region;
      }
      return newState;
    }
    case isLoaded(REGION_UPDATE, true): {
      const { updatedRegion, projectId } = action.payload;
      if (!state[projectId]) return state;
      if (!state[projectId][updatedRegion.id]) return state;
      const newState = { ...state };
      newState[projectId] = { ...newState[projectId] };
      newState[projectId][updatedRegion.id] = updatedRegion;
      return newState;
    }
    case isLoaded(REGIONS_UPDATE_PLAN_LAYOUTS, true): {
      const { updatedRegions, projectId } = action.payload;
      if (!state[projectId]) return state;

      const newState = { ...state };
      updatedRegions.forEach(region => {
        if (!state[projectId][region.id]) return;
        newState[projectId] = { ...newState[projectId] };
        newState[projectId][region.id] = region;
      });

      return newState;
    }
    case isLoaded(PROJECT_LOAD_CURRENT, true):
    case isLoaded(PROJECT_LOAD_ACTIVE, true):
    case isLoaded(PROJECT_UPDATE_STATUS, true): {
      const { project, regions } = action.payload;
      const newState = { ...state };
      if (!newState[project.id]) {
        newState[project.id] = {};
      }
      newState[project.id] = {};
      regions.forEach(region => {
        newState[project.id][region.id] = region;
      });
      newState[project.id] = { ...newState[project.id] };
      return newState;
    }
    case isLoaded(PROJECT_DELETE, true): {
      const projectId = action.payload;
      if (!state[projectId]) return state;
      const newState = { ...state };
      delete newState[projectId];
      return newState;
    }
    default: {
      return state;
    }
  }
}
