import i18next from "i18next";

export const ENTITY_TYPE = {
  DEVICE_PROFILE: 'DEVICE_PROFILE',
  DEVICE_SHAPE_LIBRARY: 'DEVICE_SHAPE_LIBRARY',
  DEVICE_SHAPE: 'DEVICE_SHAPE',
  NEW_DEVICE_SHAPE_LIBRARY: 'NEW_DEVICE_SHAPE_LIBRARY',
  NEW_DEVICE_SHAPE: 'NEW_DEVICE_SHAPE'
};

export const getAnimationTypes = () => ({
  NONE: { id: 'NONE', name: i18next.t('admin.shapeLibrary.noAnim'), min: 0, max: 0, needReverse: false },
  BLINKING: { id: 'BLINKING', name: i18next.t('admin.shapeLibrary.flickering'), min: 0.3, max: 1, needReverse: true },
  RESIZING: { id: 'RESIZING', name: i18next.t('admin.shapeLibrary.changingSize'), min: 0.7, max: 1.3, needReverse: true },
  ROTATING: { id: 'ROTATING', name: i18next.t('admin.shapeLibrary.rotation'), min: 0, max: 360, needReverse : false }
});

export const ANIMATION_SPEED = {
  MIN: 500,
  MAX: 5000
};
