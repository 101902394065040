import React from 'react';

const MovePicture = ({ color = '#fff', width, height, x = '16', y = '16' }) => {
  return (
    <svg width={width || x} height={height || y} viewBox={`0 0 ${x} ${y}`}>
      <path
        id="svg_1"
        d="m13.96008,15.94677l-11.92016,0c-1.09865,0 -1.98669,-0.88607 -1.98669,-1.9847l0,-11.92215c0,-1.10064 0.88804,-1.98669 1.98669,-1.98669l11.92016,0c1.09864,0 1.98669,0.88605 1.98669,1.98669l0,11.92215c0,1.09864 -0.88805,1.9847 -1.98669,1.9847l0,0zm0,-13.41018c0,-0.27416 -0.22052,-0.49667 -0.49667,-0.49667l-10.92682,0c-0.27615,0 -0.49667,0.22251 -0.49667,0.49667l0,10.92682c0,0.27416 0.22052,0.49667 0.49667,0.49667l10.92682,0c0.27615,0.00199 0.49667,-0.22052 0.49667,-0.49469l0,-10.9288l0,0z"
        clipRule="evenodd"
        stroke="null"
        fill={color}
      />
      <path
        stroke="null"
        id="svg_4"
        d="m12.431678,7.117092l-1.528843,-0.883057l0,1.241198l-2.378068,0l0,-2.378068l1.241197,0l-0.882907,-1.529291l-0.883057,-1.529439l-0.882908,1.529439l-0.883056,1.529291l1.241197,0l0,2.378068l-2.378068,0l0,-1.241198l-1.52929,0.883057l-1.529439,0.882907l1.529439,0.883057l1.52929,0.882908l0,-1.241198l2.378068,0l0,2.378068l-1.241197,0l0.883056,1.528843l0.882908,1.529887l0.883057,-1.529887l0.882907,-1.528843l-1.241197,0l0,-2.378068l2.378068,0l0,1.241198l1.528843,-0.882908l1.529886,-0.883057"
        strokeOpacity="null"
        strokeLinecap="null"
        strokeLinejoin="null"
        strokeWidth="null"
        fill={color}
      />
    </svg>
  );
};

export default MovePicture;
