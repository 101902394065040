/**
 * Задачи
 */

/**
 * Загрузка списка задач
 * REST-метод: GET /api/v1/issues
 */
export const ISSUES_LOAD = 'ISSUES_LOAD';
/**
 * Удаление всех завершённых задач
 * REST-метод: DELETE /api/v1/issues?finished
 */
export const ISSUES_DELETE_FINISHED = 'ISSUES_DELETE_FINISHED';
/**
 * Удаление всех завершённых с ошибками задач
 * REST-метод: DELETE /api/v1/issues?error
 */
export const ISSUES_DELETE_ERROR = 'ISSUES_DELETE_ERROR';
/**
 * Удаление всех не начатых задач из очереди
 * REST-метод: DELETE /api/v1/issues?queue
 */
export const ISSUES_DELETE_IN_QUEUE = 'ISSUES_DELETE_IN_QUEUE';
/**
 * Удаление задачи
 * REST-метод: DELETE /api/v1/issues/<issueId>
 * Payload: {
 *   issueId: <string>
 * }
 */
export const ISSUE_DELETE = 'ISSUE_DELETE';
/**
 * Разрешить выполнение задачи
 * REST-метод: PUT /api/v1/issues/<issueId>?play
 * Payload: {
 *   issueId: <string>
 * }
 */
export const ISSUE_PLAY = 'ISSUE_PLAY';
/**
 * Остановка выполнения задачи
 * REST-метод: PUT /api/v1/issues/<issueId>?pause
 * Payload: {
 *   issueId: <string>
 * }
 */
export const ISSUE_PAUSE = 'ISSUE_PAUSE';
/**
 * Уведомление о созданных задачах.
 * Может приходить по WS в сообщении updatedEntities.
 */
export const ISSUES_CREATED = 'ISSUES_CREATED';
/**
 * Уведомление об измененных задачах.
 * Может приходить по WS в сообщении updatedEntities.
 */
export const ISSUES_UPDATED = 'ISSUES_UPDATED';
/**
 * Уведомление об удаленных задачах.
 * Может приходить по WS в сообщении updatedEntities.
 */
export const ISSUES_DELETED = 'ISSUES_DELETED';
