import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class ConnectedRow extends React.Component {
  constructor(props) {
    super(props);
    this.div = null;
  }

  static prosTypes = {
    children: PropTypes.func,
    className: PropTypes.string,
    node: PropTypes.object,
    onClick: PropTypes.func,
    onDoubleClick: PropTypes.func,
    onKeyPress: PropTypes.func,
    onContextMenu: PropTypes.func
  };

  static defaultProps = {
    children: () => {},
    className: '',
    node: {},
    onClick: () => {},
    onDoubleClick: () => {},
    onKeyPress: () => {},
    onContextMenu: () => {}
  };

  render = () => {
    const {
      children,
      className,
      node,
      onClick,
      onDoubleClick,
      onKeyPress,
      onContextMenu,
      item
    } = this.props;
    return (
      <div
        key={node.id}
        className={className}
        onClick={e => onClick(e, node)}
        onDoubleClick={e => onDoubleClick(e, node)}
        onKeyPress={e => onKeyPress(e, node)}
        onContextMenu={e => onContextMenu(e, node)}
      >
        {children(node, item)}
      </div>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  if (ownProps.entitiesHash) {
    const { node, entitiesHash } = ownProps;
    const hash = entitiesHash || {};
    return {
      node: hash[node.id] || node,
      item: node
    };
  }

  return {};
};

export default connect(mapStateToProps)(ConnectedRow);
