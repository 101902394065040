import { takeEvery } from 'redux-saga/effects';
import {
  ISSUES_LOAD,
  ISSUES_DELETE_FINISHED,
  ISSUES_DELETE_ERROR,
  ISSUES_DELETE_IN_QUEUE,
  ISSUE_DELETE,
  ISSUE_PLAY,
  ISSUE_PAUSE
} from 'constants/actionTypes';

import { fetch, request, dispatchSuccess, dispatchFail } from 'helpers/request';

function* loadIssues() {
  try {
    let issues = yield fetch('/issues');
    yield dispatchSuccess(ISSUES_LOAD, issues);
  } catch (error) {
    yield dispatchFail(ISSUES_LOAD, error);
  }
}

function* deleteFinishedIssues() {
  try {
    yield request(`/issues?finished`, 'DELETE');
  } catch (error) {
    yield dispatchFail(ISSUES_DELETE_FINISHED, error);
  }
}

function* deleteErrorIssues() {
  try {
    yield request(`/issues?error`, 'DELETE');
  } catch (error) {
    yield dispatchFail(ISSUES_DELETE_ERROR, error);
  }
}

function* deleteAllIssuesInQueue() {
  try {
    yield request(`/issues?queue`, 'DELETE');
  } catch (error) {
    yield dispatchFail(ISSUES_DELETE_IN_QUEUE, error);
  }
}

function* deleteIssue(action) {
  const { issueId } = action.payload;
  try {
    yield request(`/issues/${issueId}`, 'DELETE');
  } catch (error) {
    yield dispatchFail(ISSUE_DELETE, error);
  }
}

function* playIssue(action) {
  const { issueId } = action.payload;
  try {
    yield request(`/issues/${issueId}?play`, 'PUT');
  } catch (error) {
    yield dispatchFail(ISSUE_PLAY, error);
  }
}

function* pauseIssue(action) {
  const { issueId } = action.payload;
  try {
    yield request(`/issues/${issueId}?pause`, 'PUT');
  } catch (error) {
    yield dispatchFail(ISSUE_PAUSE, error);
  }
}

export default function* issues() {
  yield takeEvery(ISSUES_LOAD, loadIssues);
  yield takeEvery(ISSUES_DELETE_FINISHED, deleteFinishedIssues);
  yield takeEvery(ISSUES_DELETE_ERROR, deleteErrorIssues);
  yield takeEvery(ISSUES_DELETE_IN_QUEUE, deleteAllIssuesInQueue);
  yield takeEvery(ISSUE_DELETE, deleteIssue);
  yield takeEvery(ISSUE_PLAY, playIssue);
  yield takeEvery(ISSUE_PAUSE, pauseIssue);
}
