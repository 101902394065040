import React from 'react';

const Team = ({ color = '#5FA2DD', x = '16', y = '16' }) => {
  return (
    <svg width={x} height={y} viewBox={`0 0 ${x} ${y}`}>
      <g stroke="null" id="svg_2">
        <path
          fill={color}
          stroke="null"
          id="svg_3"
          d="m4.95477,7.09638c0.54734,0 1.0491,-0.25304 1.45498,-0.64184c-0.42573,-0.75991 -0.66875,-1.61962 -0.66875,-2.44455c0,-0.69959 0.16955,-1.3595 0.46797,-1.94221c-0.37013,-0.22593 -0.80274,-0.35834 -1.26678,-0.35834c-1.35636,0 -2.45575,1.1102 -2.45575,2.47952c0,1.36952 1.11196,2.90741 2.46833,2.90741z"
        />
        <path
          fill={color}
          stroke="null"
          id="svg_4"
          d="m6.60661,8.27259c-0.23968,-0.05147 -0.48781,-0.07996 -0.74282,-0.07996l-1.82629,0c-1.94338,0 -3.519,1.57325 -3.519,3.51408l0.00825,0.41551c0,0.54223 0.44027,0.98191 0.98328,0.98191l1.80075,0c0.08841,-2.15929 1.41785,-4.00013 3.29582,-4.83155z"
        />
        <path
          fill={color}
          stroke="null"
          id="svg_5"
          d="m9.98022,7.61504c1.6817,0 3.02961,-1.90704 3.02961,-3.60504c0,-1.69801 -1.36343,-3.0746 -3.04513,-3.0746c-1.6817,0 -3.04513,1.3766 -3.04513,3.0746c0.0002,1.69801 1.37895,3.60504 3.06065,3.60504z"
        />
        <path
          fill={color}
          stroke="null"
          id="svg_6"
          d="m15.47089,13.33183c0,-2.40664 -1.9536,-4.35749 -4.36338,-4.35749l-2.2646,0c-2.40978,0 -4.36338,1.95085 -4.36338,4.35749l0.01041,0.51532c0,0.67229 0.54577,1.21746 1.21923,1.21746l8.55309,0c0.67327,0 1.21923,-0.54518 1.21923,-1.21746l-0.01061,-0.51532z"
        />
      </g>
    </svg>
  );
};

export default Team;
