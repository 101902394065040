const Permissions = {
  /* Администрирование */
  ADMINISTRATE: 'ADMINISTRATE',
  /* Пользователи для пульта (OCP) */
  CONTROL_PANEL_USER_CREATE: 'CONTROL_PANEL_USER_CREATE',
  CONTROL_PANEL_USER_READ: 'CONTROL_PANEL_USER_READ',
  CONTROL_PANEL_USER_UPDATE: 'CONTROL_PANEL_USER_UPDATE',
  CONTROL_PANEL_USER_REMOVE: 'CONTROL_PANEL_USER_REMOVE',
  /* Устройства */
  DEVICE_READ: 'DEVICE_READ',
  DEVICE_CREATE: 'DEVICE_CREATE',
  DEVICE_UPDATE: 'DEVICE_UPDATE',
  DEVICE_REMOVE: 'DEVICE_REMOVE',
  /* Индикаторные панели */
  INDICATORS_PANEL_READ: 'INDICATORS_PANEL_READ',
  INDICATORS_PANEL_CREATE: 'INDICATORS_PANEL_CREATE',
  INDICATORS_PANEL_UPDATE: 'INDICATORS_PANEL_UPDATE',
  INDICATORS_PANEL_REMOVE: 'INDICATORS_PANEL_REMOVE',
  /* Виртуальные индикаторы */
  INDICATORS_PANELS_GROUP_CREATE: 'INDICATORS_PANELS_GROUP_CREATE',
  INDICATORS_PANELS_GROUP_UPDATE: 'INDICATORS_PANELS_GROUP_UPDATE',
  INDICATORS_PANELS_GROUP_REMOVE: 'INDICATORS_PANELS_GROUP_REMOVE',
  /* Задачи */
  ISSUE_READ: 'ISSUE_READ',
  ISSUE_CREATE: 'ISSUE_CREATE',
  ISSUE_UPDATE: 'ISSUE_UPDATE',
  ISSUE_REMOVE: 'ISSUE_REMOVE',
  /* Журнал событий */
  LOG_VIEW_READ: 'LOG_VIEW_READ',
  LOG_VIEW_CREATE: 'LOG_VIEW_CREATE',
  LOG_VIEW_UPDATE: 'LOG_VIEW_UPDATE',
  LOG_VIEW_REMOVE: 'LOG_VIEW_REMOVE',
  /* События */
  EVENTS_READ: 'EVENTS_READ',
  /* Медиа */
  MEDIA_CREATE: 'MEDIA_CREATE',
  MEDIA_UPDATE: 'MEDIA_UPDATE',
  MEDIA_REMOVE: 'MEDIA_REMOVE',
  /* Проекты */
  PROJECT_READ: 'PROJECT_READ',
  PROJECT_CREATE: 'PROJECT_CREATE',
  PROJECT_UPDATE: 'PROJECT_UPDATE',
  PROJECT_REMOVE: 'PROJECT_REMOVE',
  PROJECT_STATE_UPDATE: 'PROJECT_STATE_UPDATE',
  PROJECT_EXPORT: 'PROJECT_EXPORT',
  PROJECT_IMPORT: 'PROJECT_IMPORT',
  PROJECT_ACTIVE_READ: 'PROJECT_ACTIVE_READ',
  /* Зоны */
  REGION_READ: 'REGION_READ',
  REGION_CREATE: 'REGION_CREATE',
  REGION_UPDATE: 'REGION_UPDATE',
  REGION_REMOVE: 'REGION_REMOVE',
  /* Сценарии */
  SCENARIO_READ: 'SCENARIO_READ',
  SCENARIO_CREATE: 'SCENARIO_CREATE',
  SCENARIO_UPDATE: 'SCENARIO_UPDATE',
  SCENARIO_REMOVE: 'SCENARIO_REMOVE',
  /* Уведомления */
  SOUND_NOTIFICATION_READ: 'SOUND_NOTIFICATION_READ',
  SOUND_NOTIFICATION_CREATE: 'SOUND_NOTIFICATION_CREATE',
  SOUND_NOTIFICATION_UPDATE: 'SOUND_NOTIFICATION_UPDATE',
  /* Пользователи */
  USER_READ: 'USER_READ',
  USER_CREATE: 'USER_CREATE',
  USER_UPDATE: 'USER_UPDATE',
  USER_REMOVE: 'USER_REMOVE',
  USER_RESTORE: 'USER_RESTORE',
  USER_SELF_UPDATE: 'USER_SELF_UPDATE',
  USER_BUILTIN_UPDATE: 'USER_BUILTIN_UPDATE',
  /* Группы пользователей */
  USER_GROUP_READ: 'USER_GROUP_READ',
  USER_GROUP_CREATE: 'USER_GROUP_CREATE',
  USER_GROUP_UPDATE: 'USER_GROUP_UPDATE',
  USER_GROUP_REMOVE: 'USER_GROUP_REMOVE',
  /* Виртуальные состояния */
  VIRTUAL_OBJECT_READ: 'VIRTUAL_OBJECT_READ',
  VIRTUAL_OBJECT_CREATE: 'VIRTUAL_OBJECT_CREATE',
  VIRTUAL_OBJECT_UPDATE: 'VIRTUAL_OBJECT_UPDATE',
  VIRTUAL_OBJECT_REMOVE: 'VIRTUAL_OBJECT_REMOVE'
};

export default Permissions;
