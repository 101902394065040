import React, { useRef } from 'react';
import { setPinCPIUToPanel, setQuickAccessScenariosList } from 'actions/CPIU';
import { LabeledValue } from 'antd/lib/select';
import { UseStorageItemsType, useStorage } from 'customHooks/useStorage';
import { getActiveProjectId } from 'helpers/activeProject';
import { useSelector, useDispatch } from 'react-redux';
import getStorageItem from 'helpers/getStorageItem';

const PANEL_PIN = 'PANEL_PIN';
const QUICK_ACCESS_SCENARIO_LIST = 'QUICK_ACCESS_SCENARIO_LIST';

const CPIULoader = () => {
  const activeProjectId = useSelector(state => getActiveProjectId(state)) as string;
  const isPinToPanel = useSelector((state: any) => state.activeProject.CPIU.isPinToPanel);
  const quickAccessScenariosList = useSelector(
    (state: any) => state.activeProject.CPIU.quickAccessScenariosList
  ) as LabeledValue[];
  const dispatch = useDispatch();
  const storageItems = useRef({
    items: [] as UseStorageItemsType[],
    key: activeProjectId
  });

  storageItems.current = {
    items: [
      getStorageItem(
        PANEL_PIN,
        (isPin: boolean) => dispatch(setPinCPIUToPanel(isPin)),
        (value: boolean) => typeof value === 'boolean',
        isPinToPanel,
        false
      ),
      getStorageItem(
        QUICK_ACCESS_SCENARIO_LIST,
        (newFavoriteList: LabeledValue[]) => dispatch(setQuickAccessScenariosList(newFavoriteList)),
        list => !!list.length,
        quickAccessScenariosList,
        []
      )
    ],
    key: activeProjectId
  };

  //сохраняем/загружаем состояние панели в/из LocalStorage
  useStorage(storageItems);

  return null;
};

export default React.memo(CPIULoader);
