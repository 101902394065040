import {
  USERS_LOAD,
  USER_CREATE,
  USER_UPDATE,
  CURRENT_USER_UPDATE,
  USER_DELETE,
  USER_SELECT,
  SET_LOCAL_HOST_USER
} from 'constants/actionTypes';
import { createAction } from 'redux-actions';

export const loadUsers = createAction(USERS_LOAD);
export const createUser = createAction(USER_CREATE, user => user);
export const updateUser = createAction(USER_UPDATE, (userId, user) => ({ userId, user }));
export const updateCurrentUser = createAction(CURRENT_USER_UPDATE, user => user);
export const deleteUser = createAction(USER_DELETE, (userId, active) => ({ userId, active }));
export const selectUser = createAction(USER_SELECT, userId => userId);
export const setLocalHostUser = createAction(
  SET_LOCAL_HOST_USER,
  isLocalHostUser => isLocalHostUser
);
