import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { loadActiveProject } from 'actions/projects';
import { getActiveProject } from 'helpers/activeProject';
import { getCurrentUser, hasRole } from 'helpers/user';
import Permissions from 'constants/permissions';

/**
 * Таймаут запроса активного проекта.
 *
 * Значение выбрано исходя из требования, чтобы даже при больших системных тормозах
 * с высокой (но не 100%-ной) вероятностью выполнить проверку необходимости запроса
 * активного проекта уже после монтирования видимых компонентов. Чтобы не начать
 * его загрузку одновременно с одним из компонентов. При этом чтобы пользователь
 * не слишком долго ждал, когда активируется оперативная задача.
 */
const LOAD_ACTIVE_PROJECT_TO = 5000;

class ActiveProjectLoader extends PureComponent {
  projectRequestedByMe = false;

  componentDidMount() {
    setTimeout(() => this.tryLoad(), LOAD_ACTIVE_PROJECT_TO);
  }

  componentDidUpdate(prevProps, prevState) {
    const { loadActiveProjectInProgress } = this.props;
    if (
      loadActiveProjectInProgress !== prevProps.loadActiveProjectInProgress &&
      this.projectRequestedByMe
    )
      this.projectRequestedByMe = loadActiveProjectInProgress;
    else setTimeout(() => this.tryLoad(), LOAD_ACTIVE_PROJECT_TO);
  }

  tryLoad = () => {
    const {
      currentUser,
      activeProject,
      loadActiveProjectInProgress,
      loadCurrentProjectInProgress,
      updateProjectStatusInProgress,
      dispatch
    } = this.props;

    if (
      !activeProject &&
      hasRole(currentUser, Permissions.PROJECT_ACTIVE_READ) &&
      !loadActiveProjectInProgress &&
      !loadCurrentProjectInProgress &&
      !updateProjectStatusInProgress
    ) {
      this.projectRequestedByMe = true;
      dispatch(loadActiveProject(true));
    }
  };

  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
  activeProject: getActiveProject(state),
  loadActiveProjectInProgress: state.inProgress.loadActiveProject,
  loadCurrentProjectInProgress: state.inProgress.loadCurrentProject,
  updateProjectStatusInProgress: state.inProgress.updateProjectStatus
});

const mapDispatchToProps = dispatch => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(ActiveProjectLoader);
