import styled from 'styled-components';
import loginBackground from 'assets/wallpapers/LoginBackground.png';

export const LoginContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  height: 100%;
  background-image: url(${loginBackground});
  background-repeat: round;
`;
