import React from 'react';

import { Checkbox } from 'antd';
import i18next from 'i18next';
export default class CustomCheckbox extends React.Component {
  static defaultProps = {
    isActive: true,
    disabled: false,
    trueValues: ['1', 'true', 1, true, 'yes', 'on', '%%yes%%'],
    undefinedValues: [undefined, '', null]
  };

  toBool = a => {
    const { trueValues } = this.props;
    const value = typeof a === 'string' ? a.toLowerCase() : a;
    return !!a && trueValues.indexOf(value) > -1;
  };

  isUndefined = a => {
    const { undefinedValues } = this.props;
    return undefinedValues.indexOf(a) > -1;
  };

  render() {
    let { isActive, value, disabled, input, onChange, label, children, ...restProps } = this.props;

    if (input) {
      onChange = input.onChange;
      value = input.value;
      if (input.label) label = input.label;
    }

    return isActive ? (
      <Checkbox
        checked={this.toBool(value)}
        disabled={disabled}
        onChange={onChange}
        label={label}
        {...restProps}
      >
        {children || label}
      </Checkbox>
    ) : (
      <div>
        {this.isUndefined(value) ? '' : this.toBool(value) ? i18next.t('yes') : i18next.t('no')}
      </div>
    );
  }
}
