import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentNotification } from 'actions/soundNotifications';
import { Status } from 'constants/project';

const SoundNotificationController = () => {
  const subsystems = useSelector((state: any) => state.activeProject.subsystems);
  const stateCategoryViews = useSelector((state: any) => state.stateCategoryViewsHash);
  const activeProject = useSelector((state: any) => state.activeProject.activeProject);
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeProject?.status === Status.ACTIVE)
      dispatch(setCurrentNotification({ subsystems, stateCategoryViews }));
  }, [subsystems, stateCategoryViews, activeProject, dispatch]);

  return null;
};

export default SoundNotificationController;
