import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getCurrentDriverDescription, getCurrentDriverAttributes } from 'helpers/drivers';
import { Tooltip } from 'antd';
import i18next from 'i18next';
import styles from './AppInfo.module.css';
import { BugOutlined } from '@ant-design/icons';

const AppWrapper = styled.div`
  display: flex;
  align-items: center;
  color: aqua;
`;
const AppElement = styled.div`
  margin-left: 6px;
  margin-right: 20px;
  line-height: 1;
  display: flex;
  align-items: center;
`;

const LICENSE_PRESENT = {
  color: 'aqua'
};

const LICENSE_ABSENT = {
  color: 'red',
  fontWeight: 'bold'
};

const Item = ({ name, attr }) => {
  let availableTitle = i18next.t('available');
  let notAvailableTitle = i18next.t('notAvailable');
  return (
    <tr>
      <td>{name}:</td>
      <td className={attr ? styles.status_ok : styles.status_no}>
        {attr ? availableTitle : notAvailableTitle}
      </td>
    </tr>
  );
};

const NumericalItem = ({ name, value }) => {
  return (
    <tr>
      <td>{name}:</td>
      <td className={value < 0 ? styles.status_ok : styles.status_no}>
        {value >= 0 ? value : i18next.t('license.unlimited')}
      </td>
    </tr>
  );
};
const Debug = () => {
  return (
    <span className={styles.debug_mode}>
      debug <BugOutlined rotate={45} style={{ color: 'orange', fontSize: '20px' }} /> mode
    </span>
  );
};

const AppInfo = ({ driverAttributes, driverDescription, appInfo, license }) => {
  let licensePresent = false;
  let licenseTitle = i18next.t('license.absent');
  if (license && license.control && license.skud) {
    licensePresent =
      license.control.engineeringEnabled ||
      license.control.firefightingEnabled ||
      license.skud.staffEnabled ||
      license.skud.checkpointEnabled ||
      license.skud.reportsEnabled;

    licenseTitle = (
      <table className={styles.license_tooltip_content}>
        <tbody>
          {license.common && [
            <NumericalItem
              key="numberOfClients"
              name={i18next.t('license.numberOfClients')}
              value={license.common.numberOfClients}
            />,
            <NumericalItem
              key="numberOfControlDevices"
              name={i18next.t('license.numberOfControlDevices')}
              value={license.common.numberOfControlDevices}
            />
          ]}
          {driverAttributes &&
            driverAttributes.fireSubsystem &&
            license.control && [
              <Item
                key="fireExtinguishingControl"
                name={i18next.t('license.fireExtinguishingControl')}
                attr={license.control.firefightingEnabled}
              />,
              <Item
                key="engineeringManagement"
                name={i18next.t('license.engineeringManagement')}
                attr={license.control.engineeringEnabled}
              />
            ]}
          {driverAttributes &&
            driverAttributes.skudSubsystem &&
            license.skud && [
              <Item
                key="PACSManagement"
                name={i18next.t('license.PACSManagement')}
                attr={license.skud.staffEnabled}
              />,
              <Item
                key="PACSCheckpoint"
                name={i18next.t('license.PACSCheckpoint')}
                attr={license.skud.checkpointEnabled}
              />,
              <Item
                key="PACSReports"
                name={i18next.t('license.PACSReports')}
                attr={license.skud.reportsEnabled}
              />,
              <Item
                key="PACSAdmissionOffice"
                name={i18next.t('license.PACSAdmissionOffice')}
                attr={license.skud.admissionOffice}
              />
            ]}
        </tbody>
      </table>
    );
  }
  return (
    <AppWrapper>
      <AppElement>
        <span className={styles.app_info_text}>
          {i18next.t('version')}: {appInfo.version}
        </span>
        <Tooltip title={driverDescription.description}>
          {' '}
          <span className={styles.app_info_text}>{driverDescription.name}</span>
        </Tooltip>
        {process.env.NODE_ENV === 'development' ? <Debug /> : null}
      </AppElement>
      <AppElement>
        <Tooltip title={licenseTitle} overlayClassName={styles.license_tooltip}>
          <span className={styles.app_info_text_container}>
            <span className={styles.app_info_text}>{i18next.t('license.name')}: </span>
            <span
              className={styles.app_info_text}
              style={licensePresent ? LICENSE_PRESENT : LICENSE_ABSENT}
            >
              {licensePresent ? i18next.t('yes') : i18next.t('no')}{' '}
            </span>
          </span>
        </Tooltip>
      </AppElement>
    </AppWrapper>
  );
};

const mapStateToProps = state => {
  return {
    driverAttributes: getCurrentDriverAttributes(state),
    driverDescription: getCurrentDriverDescription(state),
    appInfo: state.dictionaries.application,
    license: state.license
  };
};

export default connect(mapStateToProps)(AppInfo);
