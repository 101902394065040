import { REGION_CONSTANTS_LOAD, isLoaded } from 'constants/actionTypes';

/*
 * state = regionConstants: {
 *    securityTypes: {
 *      (см. метод GET api/v1/security_dictionaries в тех. проекте)
 *    }
 *  }
 */
export default function regionConstants(state = {}, action) {
  switch (action.type) {
    case isLoaded(REGION_CONSTANTS_LOAD, true): {
      const constants = action.payload;
      return { ...constants };
    }

    default:
      return state;
  }
}
