import React from 'react';

const Network = ({ color = "#5FA2DD", x = "16", y = "15", isActive = true }) => {
  return (
    <svg width={x} height={y} viewBox={`0 0 ${x} ${y}`}>
      <path fill={color} fillRule="evenodd" d="M7.286 6.059v-.235a2.945 2.945 0 0 1-2.365-2.883C4.92 1.319 6.24 0 7.86 0c1.622 0 2.941 1.32 2.941 2.94a2.945 2.945 0 0 1-2.365 2.884v.235a.149.149 0 0 1-.149.148h-.853a.149.149 0 0 1-.149-.148zM0 9.232c0-.318.258-.575.575-.575h4.731c.318 0 .575.257.575.575v4.73a.575.575 0 0 1-.575.576H.576A.575.575 0 0 1 0 13.963V9.232zm15.148-.575h-4.731a.575.575 0 0 0-.575.575v4.73c0 .319.257.576.575.576h4.73a.575.575 0 0 0 .576-.575V9.232a.575.575 0 0 0-.575-.575zM2.909 8.084l-.395-.002a.148.148 0 0 1-.149-.15v-.574c0-.318.258-.576.576-.576h9.841c.318 0 .575.258.575.576v.575a.148.148 0 0 1-.148.149h-.853a.149.149 0 0 1-.149-.15H3.516c0 .159-.1.158-.607.152z"/>
    </svg>
  )
}

export default Network;
