import { takeEvery, call, all } from 'redux-saga/effects';
import { request, dispatchSuccess, dispatchFail } from 'helpers/request';
import i18next from 'i18next';
import message from 'components/message';

import {
  COPY_AND_PASTE_BACKGROUNDS_ON_PLAN,
  COPY_AND_PASTE_TEXTS_ON_PLAN,
  COPY_AND_PASTE_OBJECTS_ON_PLAN,
  CUT_AND_PASTE_OBJECTS_ON_PLAN
} from 'constants/actionTypes';

import { REGION_CREATE, COPY_AND_PASTE_REGIONS_ON_PLAN } from 'constants/actionTypes';

import { DEVICES_COPY_AND_PASTE_ON_PLAN } from 'constants/actionTypes';

import { MESSAGE_KEY } from '../containers/Forms/CopyDeviceForm';

import { updateDevicesPlanLayouts } from './devices';
import { groupUpdatePlanLayouts } from './regions';
import { deletePlanObjects, updatePlan } from './plans';

function* copyAndPasteObjectsOnPlan(action) {
  const {
    objects: { pastingTexts, pastingBackgrounds, pastingRegions, pastingDevices },
    callback = () => {},
    rebindDevices,
    planId,
    projectId
  } = action.payload;
  const key = MESSAGE_KEY;
  try {
    message.loading({ key, content: i18next.t('messages.insertionInProgress'), duration: 0 });
    yield pastingBackgrounds &&
      call(copyAndPasteBackgroundsOnPlan, pastingBackgrounds, planId, projectId);
    yield pastingRegions && call(copyAndPasteRegionOnPlan, pastingRegions, projectId);
    yield pastingDevices && call(copyAndPasteDevicesOnPlan, pastingDevices, projectId);
    yield pastingDevices && rebindDevices && call(rebindDevices);
    yield pastingTexts && call(copyAndPasteTextsOnPlan, pastingTexts, planId, projectId);
    yield dispatchSuccess(COPY_AND_PASTE_OBJECTS_ON_PLAN);
    yield call(callback);
    yield message.success({ key, content: i18next.t('messages.insertDone'), duration: 1 });
  } catch (error) {
    message.destroy(key);
    yield dispatchFail(COPY_AND_PASTE_OBJECTS_ON_PLAN, error.message);
  }
}

function* copyAndPasteBackgroundsOnPlan(backgrounds, planId, projectId) {
  const { pastingBackgrounds, newLayouts, callback } = backgrounds;
  let index = 0;
  try {
    const response = yield request(
      `/projects/${projectId}/plans/${planId}/backgrounds/list`,
      'POST',
      pastingBackgrounds
    );
    if (!response || response.error) throw new Error(response ? response.error : '');
    const pastedBackground = response.plan.backgrounds.map(background => {
      if (!background.height && !background.width) {
        return { ...newLayouts[index++], id: background.id, info: { ...background.info } };
      }
      return background;
    });
    const plan = response.plan;
    plan.backgrounds = pastedBackground;
    const updatedPlan = yield request(`/projects/${projectId}/plans/${planId}`, 'PUT', plan);
    if (updatedPlan && updatedPlan.error) {
      throw new Error(updatedPlan.error);
    }
    yield call(callback, response, null);
    yield dispatchSuccess(COPY_AND_PASTE_BACKGROUNDS_ON_PLAN, { updatedPlan, projectId });
  } catch (error) {
    yield dispatchFail(COPY_AND_PASTE_BACKGROUNDS_ON_PLAN, error.message);
  }
}

function* copyAndPasteTextsOnPlan(pastingTexts, planId, projectId) {
  try {
    let lastPlan;
    for (const text of pastingTexts) {
      lastPlan = yield request(`/projects/${projectId}/plans/${planId}/texts`, 'POST', text);
    }
    yield dispatchSuccess(COPY_AND_PASTE_TEXTS_ON_PLAN, {
      updatedPlan: lastPlan,
      projectId: lastPlan.projectId
    });
  } catch (error) {
    yield dispatchFail(COPY_AND_PASTE_TEXTS_ON_PLAN, error.message);
  }
}

function* copyAndPasteRegionOnPlan(pastingRegions, projectId) {
  try {
    let createdRegions = yield all([
      ...Object.values(pastingRegions).map(({ newRegion }) =>
        request(`/projects/${projectId}/region_views/`, 'POST', newRegion)
      )
    ]);
    createdRegions = createdRegions.flat(1);

    const createdRegionsWithUpdatedLayout = yield all([
      ...createdRegions.map(({ region }) =>
        request(
          `/projects/${projectId}/region_views/${region.id}?plan_layouts`,
          'PUT',
          pastingRegions[region.index].planLayouts
        )
      )
    ]);

    if (createdRegionsWithUpdatedLayout && !createdRegionsWithUpdatedLayout.length)
      throw new Error(i18next.t('errors.noResponseFromServer'));
    yield dispatchSuccess(COPY_AND_PASTE_REGIONS_ON_PLAN, {
      projectId,
      newRegions: createdRegionsWithUpdatedLayout
    });
  } catch (error) {
    yield dispatchFail(REGION_CREATE, error.message);
  }
}

function* copyAndPasteDevicesOnPlan({ copiedDevices, devicesLayouts }, projectId) {
  try {
    const copyDevicesResults = yield all([
      ...copiedDevices.map(({ projectId, parentId, newLineNo, newAddress, deviceIds }) => {
        const parentParam = parentId ? `parentId=${parentId}&` : '';
        const params = parentParam + `newLineNo=${newLineNo}&newAddress=${newAddress}`;
        const path = `/projects/${projectId}/devices/create_copies?` + params;
        return request(path, 'POST', deviceIds);
      })
    ]);
    const updatedDevices = getDevicesToUpdateLayout(copyDevicesResults, devicesLayouts);

    yield dispatchSuccess(DEVICES_COPY_AND_PASTE_ON_PLAN, {
      copyDevicesResults,
      projectId
    });

    yield updatedDevices.length &&
      call(updateDevicesPlanLayouts, { payload: { updatedDevices, projectId } });
  } catch (error) {
    yield dispatchFail(DEVICES_COPY_AND_PASTE_ON_PLAN, error.message);
  }
}

function* cutAndPasteObjectsOnPlan(action) {
  const {
    objects: { pasteObjects, deleteObjects, cutRegions, cutDevices, updateObjects },
    callback = () => {},
    projectId
  } = action.payload;
  const key = MESSAGE_KEY;
  try {
    message.loading({ key, content: i18next.t('messages.insertionInProgress'), duration: 0 });
    yield deleteObjects &&
      call(deletePlanObjects, {
        payload: {
          projectId: deleteObjects.projectId,
          planId: deleteObjects.planId,
          objects: deleteObjects.objects
        }
      });

    yield pasteObjects?.objects.pasteBackgrounds?.pastingBackgrounds.length &&
      call(
        copyAndPasteBackgroundsOnPlan,
        pasteObjects.objects.pasteBackgrounds,
        pasteObjects.planId,
        pasteObjects.projectId
      );

    yield pasteObjects?.objects.pasteTexts?.length &&
      call(
        copyAndPasteTextsOnPlan,
        pasteObjects.objects.pasteTexts,
        pasteObjects.planId,
        pasteObjects.projectId
      );

    yield updateObjects && call(updatePlan, { payload: { plan: updateObjects, projectId } });
    yield cutRegions &&
      call(groupUpdatePlanLayouts, { payload: { regionsProperties: cutRegions, projectId } });
    yield cutDevices &&
      call(updateDevicesPlanLayouts, { payload: { updatedDevices: cutDevices, projectId } });
    yield dispatchSuccess(CUT_AND_PASTE_OBJECTS_ON_PLAN);
    yield message.success({ key, content: i18next.t('messages.insertDone'), duration: 1 });
    yield call(callback);
  } catch (error) {
    message.destroy(key);
    yield dispatchFail(CUT_AND_PASTE_OBJECTS_ON_PLAN, error.message);
  }
}

function getDevicesToUpdateLayout(copyDevicesResult, devicesLayouts) {
  const devicesToUpdateLayout = [];
  copyDevicesResult.forEach(copyDeviceResult => {
    const newByOldDeviceIdMap = copyDeviceResult.oldDeviceIdByNewDeviceId;
    const createdDevices = copyDeviceResult.createdDevices;
    for (const createdDevice of createdDevices) {
      const idOfDeviceToUpdateLayout = newByOldDeviceIdMap[createdDevice.id];
      if (idOfDeviceToUpdateLayout && devicesLayouts[idOfDeviceToUpdateLayout]) {
        devicesToUpdateLayout.push({
          deviceId: createdDevice.id,
          deviceLayout: devicesLayouts[idOfDeviceToUpdateLayout],
          deviceRevision: createdDevice.revision
        });
      }
    }
  });

  return devicesToUpdateLayout;
}

export default function* copyAndPaste() {
  yield takeEvery(COPY_AND_PASTE_OBJECTS_ON_PLAN, copyAndPasteObjectsOnPlan);
  yield takeEvery(CUT_AND_PASTE_OBJECTS_ON_PLAN, cutAndPasteObjectsOnPlan);
}
