import {
  SCENARIOS_LOAD,
  REGION_DELETE,
  SCENARIO_CREATE,
  SCENARIO_UPDATE_BASIC_PARAMS,
  SCENARIO_UPDATE_ADVANCED_PARAMS,
  SCENARIO_DELETE,
  SCENARIO_ADD_TL_BLOCK,
  SCENARIO_UPDATE_TL_BLOCK,
  SCENARIO_REMOVE_TL_BLOCK,
  SCENARIO_UPDATE_START_LOGIC,
  SCENARIO_UPDATE_STOP_LOGIC,
  isLoaded,
  PROJECT_DELETE,
  PROJECT_LOAD_CURRENT,
  PROJECT_LOAD_ACTIVE,
  PROJECT_UPDATE_STATUS,
  DEVICE_IMPORT_CONTOL_CONFIG,
  SCENARIOS_UPDATED,
  SCENARIO_GET_FULL
} from 'constants/actionTypes';

/**
 * state = regions: {
 *    <projectId>: {
 *        <scenarioId>: {
 *          id: <string>,
 *          ...
 *       }
 *    }
 * }
 * (см. метод GET api/v1/projects/<projectId>/scenarios в тех. проекте)
 */
export default function scenarios(state = [], action) {
  switch (action.type) {
    case isLoaded(SCENARIOS_LOAD, true): {
      const { projectId, scenarios } = action.payload;
      const newState = { ...state };
      if (!newState[projectId]) {
        newState[projectId] = {};
      }
      newState[projectId] = {};
      scenarios.forEach(scenario => {
        newState[projectId][scenario.id] = scenario;
      });
      return newState;
    }

    case isLoaded(SCENARIO_GET_FULL, true): {
      const { projectId, scenario } = action.payload;
      if (!state[projectId]) return state;

      const newState = { ...state };
      newState[projectId] = { ...newState[projectId] };
      newState[projectId][scenario.id] = scenario;
      return newState;
    }
    case SCENARIOS_UPDATED: {
      const updatedScenarios = action.payload;
      if (updatedScenarios.length === 0) return state;

      const projectId = updatedScenarios[0].projectId;
      if (!state[projectId]) return state;

      const newState = { ...state };
      newState[projectId] = { ...newState[projectId] };
      updatedScenarios.forEach(
        updatedScenario => (newState[projectId][updatedScenario.id] = updatedScenario)
      );
      return newState;
    }

    case isLoaded(REGION_DELETE, true): {
      const { projectId, updatedScenarios } = action.payload;
      if (!state[projectId]) return state;
      if (updatedScenarios.length === 0) return state;
      const newState = { ...state };
      newState[projectId] = { ...newState[projectId] };
      updatedScenarios.forEach(
        updatedScenario => (newState[projectId][updatedScenario.id] = updatedScenario)
      );
      return newState;
    }
    case isLoaded(SCENARIO_CREATE, true): {
      const { projectId, scenario } = action.payload;
      if (!state[projectId]) return state;
      const newState = { ...state };
      newState[projectId][scenario.id] = scenario;
      newState[projectId] = { ...newState[projectId] };
      return newState;
    }
    case isLoaded(DEVICE_IMPORT_CONTOL_CONFIG, true): {
      const {
        projectId,
        scenarios: { created: createdScenarios }
      } = action.payload;
      if (!state[projectId] || !createdScenarios) {
        return state;
      }
      const newState = { ...state };
      newState[projectId] = { ...newState[projectId] };
      if (createdScenarios && createdScenarios.length) {
        createdScenarios.forEach(
          createdScenario => (newState[projectId][createdScenario.id] = createdScenario)
        );
      }
      return newState;
    }
    case isLoaded(SCENARIO_UPDATE_STOP_LOGIC, true):
    case isLoaded(SCENARIO_UPDATE_START_LOGIC, true):
    case isLoaded(SCENARIO_REMOVE_TL_BLOCK, true):
    case isLoaded(SCENARIO_UPDATE_TL_BLOCK, true):
    case isLoaded(SCENARIO_ADD_TL_BLOCK, true):
    case isLoaded(SCENARIO_UPDATE_ADVANCED_PARAMS, true): {
      const { projectId, scenario } = action.payload;
      if (!state[projectId]) return state;
      if (!state[projectId][scenario.id]) return state;
      const newState = { ...state };
      newState[projectId][scenario.id] = scenario;
      newState[projectId] = { ...newState[projectId] };
      return newState;
    }
    case isLoaded(SCENARIO_UPDATE_BASIC_PARAMS, true): {
      const {projectId, scenarios} = action.payload;
      if (!state[projectId]) return state;
      const newState = {...state};
      for (const scenario of scenarios) {
        newState[projectId][scenario.id] = scenario;
      }
      newState[projectId] = {...newState[projectId]};
      return newState;
    }
    case isLoaded(SCENARIO_DELETE, true): {
      const { projectId, scenarioId } = action.payload;
      if (!state[projectId]) return state;
      if (!state[projectId][scenarioId]) return state;
      const newState = { ...state };
      delete newState[projectId][scenarioId];
      newState[projectId] = { ...newState[projectId] };
      return newState;
    }
    case isLoaded(PROJECT_LOAD_CURRENT, true):
    case isLoaded(PROJECT_LOAD_ACTIVE, true):
    case isLoaded(PROJECT_UPDATE_STATUS, true): {
      const { project, scenarios } = action.payload;
      const newState = { ...state };
      if (!newState[project.id]) {
        newState[project.id] = {};
      }
      newState[project.id] = {};
      scenarios.forEach(scenario => {
        newState[project.id][scenario.id] = scenario;
      });
      return newState;
    }

    case isLoaded(PROJECT_DELETE, true): {
      const projectId = action.payload;
      if (!state[projectId]) return state;
      const newState = { ...state };
      delete newState[projectId];
      return newState;
    }

    default:
      return state;
  }
}
