import styled from 'styled-components';

export const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${p => (p.small ? '30px' : '35px')};
  padding: 0 ${p => (p.small ? '10px' : '17px')};
  color: ${p => (p.color ? p.color : 'white')};
  font-size: ${p => (p.small ? '12px' : '13px')};
  cursor: pointer;
  line-height: 1;
`;

export const Tabs = styled.div`
  display: flex;
  align-items: flex-end;
  box-sizing: border-box;
  text-align: center;
  & a.active,
  & div.active {
    font-weight: bold;
    background: ${p => `rgba(${p.light ? '0, 100' : '95, 162'}, 221, 0.7)`};
    div {
      height: 35px;
    }
  }
  & .disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
