import { takeEvery } from 'redux-saga/effects';
import {
  APPLICATION_INFO_LOAD,
} from 'constants/actionTypes';

import { fetch, dispatchSuccess, dispatchFail } from 'helpers/request';


function* loadApplicationInfo() {
  try {
    let appInfo = yield fetch('/dictionaries/application');
    yield dispatchSuccess(APPLICATION_INFO_LOAD, appInfo);
  } catch (error) {
    yield dispatchFail(APPLICATION_INFO_LOAD, error);
  }
}

export default function* dictionaries() {
  yield takeEvery(APPLICATION_INFO_LOAD, loadApplicationInfo);
}