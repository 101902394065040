import { createSelector } from 'reselect';

export const getCurrentDriverAttributes = createSelector(
  [({ currentUser }) => currentUser, ({ drivers }) => drivers],
  (currentUser, drivers) => {
    if (currentUser.driverProfileId && drivers[currentUser.driverProfileId]) {
      const attributes = drivers[currentUser.driverProfileId].attributes;
      if (attributes) {
        let nSubsystems = 0;
        if (attributes.fireSubsystem) ++nSubsystems;
        if (attributes.securitySubsystem) ++nSubsystems;
        if (attributes.skudSubsystem) ++nSubsystems;
        if (attributes.autoSubsystem) ++nSubsystems;
        attributes.nSubsystems = nSubsystems;
      }
      return attributes;
    }
    return {};
  }
);

export const getCurrentDriverDescription = createSelector(
  [({ currentUser }) => currentUser, ({ drivers }) => drivers],
  (currentUser, drivers) => {
    if (currentUser.driverProfileId && drivers[currentUser.driverProfileId]) {
      return drivers[currentUser.driverProfileId].description || {};
    }
    return {};
  }
);
