import {
  PROJECT_LOAD_ACTIVE,
  PROJECT_UPDATE_STATUS,
  PROJECT_LOAD_CURRENT,
  isLoaded
} from 'constants/actionTypes';

export default function project(state = null, action) {
  switch (action.type) {
    case isLoaded(PROJECT_LOAD_ACTIVE, true): {
      const { project } = action.payload;
      return { ...project };
    }
    case isLoaded(PROJECT_LOAD_CURRENT, true): {
      const { project } = action.payload;
      if (project.status === 'ACTIVE') {
        return { ...project };
      } else {
        return state;
      }
    }
    case isLoaded(PROJECT_UPDATE_STATUS, true): {
      const { project: updatedProject } = action.payload;
      if (updatedProject.status === 'ACTIVE') {
        return { ...updatedProject };
      }
      /*
       * Если пришел неактивный проект с идентифкатором текущего активного проекта,
       * то считаем, что больше нет активного проекта
       */
      if (state) {
        const project = state;
        if (project.id === updatedProject.id) {
          return null;
        }
      }

      return state;
    }

    default:
      return state;
  }
}
