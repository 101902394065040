import { useEffect } from 'react';
import { useLatest } from './useLatest';

type GetWindowEvent<Type extends string> = Type extends keyof WindowEventMap
  ? WindowEventMap[Type]
  : Event;

function useWindowEvent<Type extends string>(
  type: Type,
  cb: (event: GetWindowEvent<Type>) => void
): void;

/**
 * Хук, позволяющий подписываться на событие @name type
 * один раз при маунте компонента и отписываться при его
 * размаунте, с получением доступа к актуальным данным в
 * передаваемом колбеке, который обновляется при каждом
 * рендере компонента
 */
// eslint-disable-next-line
function useWindowEvent(type: string, cb: (event: Event) => void) {
  const latestCb = useLatest(cb);

  useEffect(() => {
    const handler = (event: Event) => {
      latestCb.current(event);
    };
    window.addEventListener(type, handler);
    return () => {
      window.removeEventListener(type, handler);
    };
  }, [type, latestCb]);
}

export default useWindowEvent;
