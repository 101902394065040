import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'components';
import { changeSoundCondition } from 'actions/soundCondition';

import { base64ToBuffer } from 'helpers/audio';
import i18next from 'i18next';
import SoundButton from 'components/SoundButton';

const NOTIFICATION_KEY = 'sound_notification_message';

const audioCtx = window.AudioContext || window.webkitAudioContext || false;
const notificationAudioCtx = audioCtx ? new audioCtx() : false;
let audioSource = null;
let isPlaying = false;
let isFirstTimePlaying = true;

const SoundSystemNotifications = () => {
  const sounds = useSelector(state => state.medias.sounds);
  const soundNotifications = useSelector(state => state.soundNotifications);
  const soundCondition = useSelector(state => state.activeProject.soundCondition);
  const currentSoundNotification = useSelector(
    state => state.activeProject.currentSoundNotification
  );
  const dispatch = useDispatch();
  const switchSoundCondition = useCallback(
    newCondition => dispatch(changeSoundCondition(newCondition)),
    [dispatch]
  );
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (!isDataLoaded) {
      const checkIsDataLoaded =
        Object.keys(soundNotifications).length &&
        Object.keys(sounds).length &&
        currentSoundNotification;
      if (checkIsDataLoaded) setIsDataLoaded(checkIsDataLoaded);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [soundNotifications, sounds, currentSoundNotification]);

  useEffect(() => {
    if (!audioCtx) {
      notification.warning(soundsNotSupportNotificationParams());
    }
  }, []);

  useEffect(() => {
    if (isDataLoaded) {
      if (soundCondition) {
        const sound = getSound(currentSoundNotification, sounds, soundNotifications);
        playSound(sound, switchSoundCondition);
      } else {
        stopSound();
      }
    }
  }, [
    currentSoundNotification,
    soundNotifications,
    sounds,
    soundCondition,
    isDataLoaded,
    switchSoundCondition
  ]);

  return (
    <SoundButton onClick={() => switchSoundCondition(!soundCondition)} isMute={!soundCondition} />
  );
};

function soundsNotSupportNotificationParams() {
  return {
    key: 'sound_notification_message',
    message: i18next.t('messages.attention'),
    description: (
      <div>
        <div>{i18next.t('admin.soundNotifications.browserNotSupported')}</div>
        <div>{i18next.t('admin.soundNotifications.recommendBrowser')}</div>
      </div>
    ),
    duration: 0
  };
}

function soundNotificationParams(switchSoundCondition) {
  return {
    key: NOTIFICATION_KEY,
    message: i18next.t('admin.soundNotifications.autoPlaySounds'),
    description: (
      <div>
        <div>{i18next.t('admin.soundNotifications.browserBlock')}</div>
        <div>{i18next.t('admin.soundNotifications.closeToUnlock')}</div>
      </div>
    ),
    duration: 0,
    onClose: switchSoundCondition
  };
}

function getSound(stateCategoryId, sounds, soundNotifications) {
  const soundNotification = soundNotifications[stateCategoryId];
  if (soundNotification && soundNotification.mediaId) {
    const sound = sounds[soundNotification.mediaId];
    if (sound && sound.content) {
      return { ...sound, loop: soundNotification.unstoppable };
    }
  }
  return null;
}

function stopSound() {
  if (audioSource && isPlaying) {
    audioSource.stop(0);
    isPlaying = false;
  }
}
function playSound(sound, switchSoundCondition) {
  if (sound && notificationAudioCtx) {
    stopSound();
    notificationAudioCtx.decodeAudioData(base64ToBuffer(sound.content)).then(buffer => {
      stopSound();
      audioSource = notificationAudioCtx.createBufferSource();
      audioSource.buffer = buffer;
      audioSource.loop = sound.loop;
      audioSource.connect(notificationAudioCtx.destination);
      audioSource.start(0);
      isPlaying = true;
      if (audioSource.context.state === 'suspended' && isFirstTimePlaying) {
        isFirstTimePlaying = false;
        const playSoundAfterConfirm = () => {
          notification.close(NOTIFICATION_KEY);
          switchSoundCondition(true);
          playSound(sound, switchSoundCondition);
        };
        notification.warning(soundNotificationParams(playSoundAfterConfirm));
      }
    });
  } else stopSound();
}

export default SoundSystemNotifications;
