import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { UserOutlined } from '@ant-design/icons';
import { notification } from 'components';
import moment from 'moment';
import styles from './styles.module.css';
import { getIconByStateCategory } from 'helpers/icons';
import { getSettings } from 'constants/settings';
import { useLocation } from 'react-router-dom';
import { usePrevious } from 'customHooks/usePrevious';
import { PATHS } from 'constants/routes';
import { FIRE_SUBSYSTEM_STATES_LIST_CAUSING_REDIRECTION } from 'constants/stateCategories';
import SUBSYSTEMS from 'constants/subsystems';
import i18next from 'i18next';

function EventsNotifier({
  events,
  user,
  stateCategoryViewsHash,
  dispatch,
  activeProject,
  priorityMode
}) {
  const [isManyActive, setManyActive] = useState(false);
  const [visibleGroupEvents, setVisibleGroupEvents] = useState([]);

  const location = useLocation();
  const isOsPlanPage =
    location.pathname === PATHS.OP.PLAN || location.pathname === PATHS.OP.PLAN + '/';
  const isAvailableActiveProject = activeProject && activeProject.status === 'ACTIVE';

  const oldEvents = usePrevious(events);

  const Title = ({ event }) => {
    if (event.id === 'many') return <b>{i18next.t('messages.newEvent', { context: 'plural' })}</b>;
    return <b>{i18next.t('messages.newEvent')}</b>;
  };

  const getEntityInfo = event => {
    if (event.deviceEventInfo) {
      return `${event.deviceEventInfo.name} (${event.deviceEventInfo.address})`;
    }
    if (event.controlDeviceEventInfo) {
      return `${event.controlDeviceEventInfo.name} (${event.controlDeviceEventInfo.address})`;
    }
    if (event.regionEventInfo) return event.regionEventInfo.name;
    if (event.virtualObjectEventInfo) return event.virtualObjectEventInfo.name;
    if (event.scenarioEventInfo) return event.scenarioEventInfo.name;
    if (event.indicatorEventInfo) return event.indicatorEventInfo.name;
    if (event.employeeEventInfo) return event.employeeEventInfo.name;
    if (event.projectEventInfo) return event.projectEventInfo.name;
    return '';
  };

  const getDescription = useCallback(event => {
    if (!event.userEventInfo) return event.name;
    return (
      <div>
        <p>{`${event.name} "${getEntityInfo(event)}"`}</p>
        <div className={styles.notification_footer}>
          <div className={styles.notification_user}>
            <UserOutlined />
            <span className={styles.notification_user_name}>{event.userEventInfo.name}</span>
          </div>
          <b>{`${getTime(event.received)}`}</b>
        </div>
      </div>
    );
  }, []);

  const onClick = useCallback(
    id => {
      notification.close(id);
      dispatch(push(PATHS.OP.PLAN));
    },
    [dispatch]
  );

  const buildNotification = useCallback(
    event => {
      const Icon = getIconByStateCategory(event.stateCategoryId);
      notification.open({
        key: event.id,
        message: <Title event={event} />,
        description: getDescription(event),
        placement: 'bottomRight',
        onClick: () => onClick(event.id),
        icon: Icon ? <Icon /> : null,
        duration: event?.duration ?? getSettings().NEW_EVENTS_NOTIFICATIONS_DURATION_S,
        style: { backgroundColor: event.color }
      });
    },
    [getDescription, onClick]
  );

  const getTime = timestamp => {
    const timeFormat = 'HH:mm:ss';
    if (!timestamp) return moment().format(timeFormat);
    return moment(timestamp).format(timeFormat);
  };

  //Сбрасываем счетчик если проект не активирован или перешли во владку План
  useEffect(() => {
    if (isOsPlanPage || !isAvailableActiveProject) {
      setManyActive(false);
      setVisibleGroupEvents([]);
      notification.close('many');
    }
  }, [isAvailableActiveProject, isOsPlanPage]);

  useEffect(() => {
    // Если находимся в ОЗ то не показываем уведомления
    if (!events || isOsPlanPage) return;
    if (oldEvents === events) return;
    //редирект на план если пожар
    if (
      priorityMode &&
      events.find(
        event =>
          event.subsystem === SUBSYSTEMS.FIRE.id &&
          FIRE_SUBSYSTEM_STATES_LIST_CAUSING_REDIRECTION.includes(event.stateCategoryId)
      )
    ) {
      dispatch(push(PATHS.OP.PLAN));
      return;
    }
    const newEvents = events.filter(event => event.userEventInfo?.idRef !== user.id);
    if (newEvents.length === 0) return;

    const newGroupEvents = [...visibleGroupEvents];
    newGroupEvents.push(...newEvents);
    setVisibleGroupEvents(newGroupEvents);
    if (isManyActive) return;
    // Если видимых событий больше 5, то показываем общее уведомление
    if (newGroupEvents.length > getSettings().MAX_NEW_NOTIFICATIONS_COUNT_ON_SCREEN) {
      setManyActive(true);
      return;
    }
    for (const event of newEvents) {
      buildNotification(event);
    }
  }, [
    buildNotification,
    events,
    isOsPlanPage,
    oldEvents,
    user,
    visibleGroupEvents,
    isManyActive,
    priorityMode,
    dispatch
  ]);

  useEffect(() => {
    if (isManyActive) {
      const colorByPriority = visibleGroupEvents.reduce((prev, cur) => {
        prev[stateCategoryViewsHash[cur.stateCategoryId].stateCategory.priority] =
          stateCategoryViewsHash[cur.stateCategoryId].color;
        return prev;
      }, {});
      const maxPriority = Math.min.apply(null, Object.keys(colorByPriority));
      const options = {
        id: 'many',
        color: colorByPriority[maxPriority],
        name: i18next.t('messages.newEventsReceived', { count: visibleGroupEvents.length }),
        duration: getSettings().NEW_EVENTS_NOTIFICATIONS_DURATION_S
      };
      buildNotification(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildNotification, isManyActive, visibleGroupEvents]);
  return null;
}

const mapStateToProps = state => {
  return {
    events: state.activeProject.logEvents.newEvents,
    user: state.currentUser ? state.currentUser : null,
    stateCategoryViewsHash: state.stateCategoryViewsHash,
    activeProject: state.activeProject.activeProject,
    priorityMode: state.activeProject.priorityMode
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(EventsNotifier);
