import React, { Component } from 'react';
import styles from './styles.module.css';

import { MenuItem } from 'react-contextmenu';
import { LockOutlined } from '@ant-design/icons';
import { Modal } from 'components';
import PropTypes from 'prop-types';

export default class PopupItem extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    disabled: PropTypes.bool,
    isLocked: PropTypes.bool
  };

  static defaultProps = {
    visible: true,
    disabled: false,
    isLocked: false
  };

  onClick = e => {
    const { isLocked, onClick, disabled } = this.props;
    if (disabled) {
      e.preventDefault();
      e.stopPropagation();
    } else if (isLocked) {
      Modal.accessDenied();
      e.preventDefault();
    } else onClick(e);
  };

  getClassName(visible, isLocked, disabled) {
    if (visible === false) {
      return styles.invisible_item;
    } else if (isLocked === true) {
      return styles.wrapper;
    } else if (disabled === true) {
      return 'disabled';
    } else return styles.wrapper;
  }

  render() {
    const { isLocked, visible, disabled, onHover, onLeave, ...restProps } = this.props;
    return (
      <div
        onMouseLeave={() => onLeave && onLeave()}
        onMouseOver={() => onHover && onHover()}
        className={this.getClassName(visible, isLocked, disabled)}
      >
        <MenuItem
          className={isLocked ? styles.locked_item : styles.item}
          disabled={disabled}
          {...restProps}
          onClick={this.onClick}
        >
          {this.props.children}
        </MenuItem>
        {isLocked ? <LockOutlined className={styles.lock_icon} /> : null}
      </div>
    );
  }
}
