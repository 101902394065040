import { SOUND_NOTIFICATIONS_LOAD, UPDATED_ENTITIES, isLoaded } from 'constants/actionTypes';

function soundNotifications(state = {}, { type, payload }) {
  switch (type) {
    case isLoaded(SOUND_NOTIFICATIONS_LOAD, true): {
      const soundNotifications = payload;
      const newState = {};
      soundNotifications.forEach(
        soundNotification => (newState[soundNotification.stateCategoryId] = soundNotification)
      );
      return newState;
    }
    case UPDATED_ENTITIES: {
      const { soundNotifications } = payload;
      if (!soundNotifications) return state;
      let stateUpdated = false;
      const newState = { ...state };
      if (soundNotifications.updated.length) {
        soundNotifications.updated.forEach(
          updatedSoundNotification =>
            (newState[updatedSoundNotification.stateCategoryId] = updatedSoundNotification)
        );
        stateUpdated = true;
      }
      if (soundNotifications.created.length) {
        soundNotifications.created.forEach(
          newSoundNotification =>
            (newState[newSoundNotification.stateCategoryId] = newSoundNotification)
        );
        stateUpdated = true;
      }

      return stateUpdated ? newState : state;
    }
    default:
      return state;
  }
}

export default soundNotifications;
