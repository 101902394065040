import React from 'react';

const ZoomOut = ({ color = "#FFF", x = "20", y = "20", isActive = true }) => {
  return (
    <svg width={x} height={y} viewBox={`0 0 ${x} ${y}`}>
      <path
        id="zoom_out"
        fill={color} 
        fillOpacity={isActive ? '1' : '.5'}
        fillRule="evenodd"
        d="M14.407 12.162l4.128 4.128a1.588 1.588 0 0 1-2.245 2.245l-4.128-4.128a.364.364 0 0 1-.033-.04.48.48 0 0 0-.025-.03 7.812 7.812 0 1 1 2.233-2.232l.03.024a.25.25 0 0 1 .04.033zM8.885 6.728H4.358c-.3 0-.543.243-.543.543v1.087c0 .3.243.543.543.543h6.88c.3 0 .544-.243.544-.543V7.27c0-.3-.243-.543-.544-.543H8.885z"/>
    </svg>
  )
}

export default ZoomOut;