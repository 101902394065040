import React, { useState, useEffect } from 'react';
import { Button, Popover, Tooltip, Select, Title, Badge, Checkbox } from 'components';
import styles from './styles.module.css';
import {
  SaveTwoTone,
  ReloadOutlined,
  FilterOutlined,
  QuestionCircleTwoTone
} from '@ant-design/icons';
import { isEmpty, isEqual } from 'lodash';
import i18next from 'i18next';
import { getFiltersCount } from 'helpers/filters';

export const FILTER_TYPES = {
  CHECKBOX: 'CHECKBOX',
  SELECT: 'SELECT',
  INPUT: 'INPUT'
};

/**
 * Содержит имя окна и меню с кнопками сохранения или сброса состояния фильтров
 */
export function PopoverTitle(props) {
  return (
    <div className={styles.popover_title}>
      <span>{props.text}</span>
      <div className={styles.title_buttons}>
        <Tooltip title={i18next.t('buttons.resetAll')}>
          <Button
            isActive={props.isResetActive}
            icon={() => (
              <ReloadOutlined
                className={styles.popout_button}
                style={{
                  fontSize: 22,
                  color: props.isResetActive ? 'var(--main-color)' : '#9e9593'
                }}
              />
            )}
            onClick={props.onClickReset}
          />
        </Tooltip>
        <Tooltip title={i18next.t('buttons.save')}>
          <Button
            isActive={props.isSaveActive}
            icon={() => (
              <SaveTwoTone
                className={styles.popout_button}
                twoToneColor={props.isSaveActive ? '' : '#9e9593'}
                style={{ fontSize: 22 }}
              />
            )}
            onClick={props.onClickSave}
          />
        </Tooltip>
      </div>
    </div>
  );
}

export function FiltersSelect({ filter, onChange, values, onDropdownVisibleChange }) {
  const entities = filter.getEntities(filter);
  const hasEntities = entities && !isEmpty(entities);
  const isInput = filter.type === FILTER_TYPES.INPUT;
  return (
    <Select
      mode={isInput ? 'tags' : 'multiple'}
      allowClear
      style={{ width: '100%' }}
      placeholder={`${
        hasEntities || filter.customOptions
          ? i18next.t('messages.choose')
          : isInput
          ? i18next.t('messages.enter')
          : i18next.t('messages.notFound')
      } "${filter.name}"`}
      showArrow={hasEntities || filter.customOptions}
      optionFilterProp={'label'}
      value={values}
      onChange={(value, option) => onChange(filter, value, option)}
      disabled={!isInput && (!filter || !hasEntities)}
      onPopupScroll={options => {
        options.nativeEvent.preventDefault();
        options.nativeEvent.stopPropagation();
      }}
      onDropdownVisibleChange={open => onDropdownVisibleChange(open, filter.name)}
      options={
        filter && hasEntities && !filter.customOptions
          ? Object.values(entities).map(entity => ({ value: entity.id, label: entity.name }))
          : undefined
      }
    >
      {filter.customOptions ? filter.customOptions(entities) : null}
    </Select>
  );
}

export function FiltersCheckbox({ filter, value, onChange }) {
  return <Checkbox value={value} onChange={e => onChange(filter, e.target.checked)} />;
}

export const initData = filters => {
  const newData = new Map();
  filters.forEach(f => {
    if (f.active && (f.active.length || f.type === FILTER_TYPES.CHECKBOX))
      newData.set(f.key, f.active);
  });
  return newData;
};

export function prepareChanges(filter, filtersChanges, value) {
  const changes = new Map(filtersChanges);
  if (value && (value.length || filter.type === FILTER_TYPES.CHECKBOX))
    changes.set(filter.key, value);
  else changes.delete(filter.key);
  return changes;
}

export function needSaveChanges(filters, changes) {
  for (const filter of filters) {
    if (
      !(filter.active && (filter.active.length || filter.type === FILTER_TYPES.CHECKBOX)) &&
      !changes.get(filter.key)
    )
      continue;
    if (!isEqual(filter.active, changes.get(filter.key))) return true;
  }
  return false;
}

export function FilterName({ filter }) {
  return (
    <Title needTooltip={false} className={styles.filter_name} color={'black'}>
      {filter.name}
      {filter.helpInfo ? (
        <Tooltip
          placement="top"
          title={typeof filter.helpInfo === 'string' ? filter.helpInfo : <filter.helpInfo />}
        >
          <QuestionCircleTwoTone className={styles.helper} />
        </Tooltip>
      ) : null}
    </Title>
  );
}

/**
 * Показывает доступные фильтры
 * @param {Function} onSaveFilters - вызывается, когда нажата кнопка сохранить
 * @param {Array} children - список дочерних элементов
 * @param {Array} filters - список фильтров
 * @param {Number} activeFiltersCount - количество активных фильтров
 */
function FiltersPopover({
  onSaveFilters,
  children,
  filters,
  activeFiltersCount,
  placement = 'rightTop',
  additionalItem,
  isCustomFilter,
  filtersVisibility
}) {
  const [visible, setVisible] = useState(false);
  const [filtersChanges, setFiltersChanges] = useState(new Map());
  const [dropdownActiveName, setDropdownActiveName] = useState('');

  useEffect(() => {
    if (isCustomFilter) {
      setFiltersChanges(initData(filters));
    }
  }, [filters, isCustomFilter]);
  return (
    <Popover
      content={
        <div className={styles.popover_content}>
          {filters.map(filter =>
            filter.hidden ||
            (filter.type === FILTER_TYPES.SELECT &&
              filter.getEntities(filter).length < 2) ? null : (
              <div key={filter.key} className={styles.row}>
                <FilterName filter={filter} />
                {filter.type === FILTER_TYPES.CHECKBOX && (
                  <FiltersCheckbox
                    filter={filter}
                    value={filtersChanges.has(filter.key) ? filtersChanges.get(filter.key) : false}
                    onChange={(filter, value) => {
                      setFiltersChanges(prepareChanges(filter, filtersChanges, value));
                    }}
                  />
                )}
                {(filter.type === FILTER_TYPES.INPUT || filter.type === FILTER_TYPES.SELECT) && (
                  <FiltersSelect
                    values={filtersChanges.has(filter.key) ? filtersChanges.get(filter.key) : []}
                    filter={filter}
                    onChange={(filter, value) => {
                      setFiltersChanges(prepareChanges(filter, filtersChanges, value));
                    }}
                    onDropdownVisibleChange={(isOpen, name) => {
                      //Для предотвращения внезапного сворачивания окна с фильтрами во время прокручивания меню
                      if (isOpen) setDropdownActiveName(name);
                      else if (!isOpen && dropdownActiveName === name) setDropdownActiveName('');
                    }}
                  />
                )}
              </div>
            )
          )}
          {additionalItem && additionalItem}
        </div>
      }
      placement={placement}
      title={
        <PopoverTitle
          text={i18next.t('filtersSettings')}
          isSaveActive={needSaveChanges(filters, filtersChanges)}
          isResetActive={!!(filtersChanges.size || activeFiltersCount)}
          onClickSave={() => onSaveFilters(filtersChanges)}
          onClickReset={() => {
            onSaveFilters(new Map());
            setFiltersChanges(new Map());
          }}
        />
      }
      trigger="click"
      visible={isCustomFilter === true ? filtersVisibility : visible}
      onVisibleChange={value => {
        if (!dropdownActiveName && !isCustomFilter) {
          if (value) setFiltersChanges(initData(filters));
          else setFiltersChanges(new Map());
          setVisible(value);
        }
      }}
    >
      {children}
    </Popover>
  );
}

export function MenuButton({ icon, tooltipText, ...rest }) {
  return (
    <Tooltip title={tooltipText}>
      <Button icon={icon} iconStyles={{ fontSize: 20 }} {...rest}>
        {rest.title}
      </Button>
    </Tooltip>
  );
}

/**
 * Компонент для настройки фильтрации
 * @param onSaveFilters - вызывается при сохранении фильтров
 * @param filters - список фильтров, где каждый элемент это объект с полями:
 *   - name: {String}(обязательный) - имя фильтра, для отображения
 *   - key: {String}(обязательный) - уникальный ключ фильтра
 *   - type: {String}(обязательный) - тип фильтра
 *   - getEntities: {Function}(обязательный) - должна возвращать сущности для выбора
 *     пользователем из списка. Пока поддерживается только хэш данных
 *   - check: {Function} (filter, node) => {Boolean}(обязательный) - Вызывается, в момент
 *     фильтрации. Должна возвращать true, если сущность удовлетворяет условиям, иначе false
 *   - helpInfo - {String | Function}(опциональный) - подсказка рядом с полем фильтра
 *   - customOptions - {Function}(опциональный) - список настраиваемых опций для Select поля
 *   - hidden - {Boolean}(опциональный) - должен ли фильтр показываться пользователю
 *
 * @param title - название, которое показывается рядом с иконкой фильтрации
 */
function Filters({
  onSaveFilters,
  filters,
  title,
  placement,
  additionalItem,
  isCustomFilter,
  onVisibilityChange,
  isFilterOn,
  filtersVisibility
}) {
  const count = getFiltersCount(filters);
  const filterIconClassName = isCustomFilter === true && isFilterOn ? styles.filter_icon_on : '';
  return (
    <FiltersPopover
      onSaveFilters={onSaveFilters}
      filters={filters}
      activeFiltersCount={count}
      placement={placement}
      additionalItem={additionalItem}
      isCustomFilter={isCustomFilter}
      filtersVisibility={filtersVisibility}
    >
      <Badge
        count={count}
        overflowCount={99}
        offset={[-7, 12]}
        size={'small'}
        style={{ backgroundColor: '#52c41a' }}
      >
        <MenuButton
          icon={FilterOutlined}
          tooltipText={title ? null : i18next.t('filter', { context: 'plural' })}
          title={title}
          className={filterIconClassName}
          onClick={() => {
            if (isCustomFilter) onVisibilityChange(!filtersVisibility);
          }}
        />
      </Badge>
    </FiltersPopover>
  );
}

export default Filters;
