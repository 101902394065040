import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, Form, getFormSyncErrors, propTypes, reduxForm } from 'redux-form';

import { Button } from 'antd';
import Modal from 'containers/Modal';
import { ItemLayoutWithLabel, ItemLayoutWithoutLabel } from 'components/Form';
import { FormItem, Input, Select } from 'components';
import i18next from 'i18next';
import { fontsStyle, fontsWeight, textFonts } from 'containers/Menus/PlanEditorToolbar';
import ColorPicker from 'components/Form/Colorpicker';
import ToggleButton from 'components/Form/ToggleButton';
import styles from './styles.module.css';

export const TEXT_EDITOR_FORM = 'textEditorForm';

class TextEditorForm extends Component {
  static propTypes = {
    ...propTypes,
    modalName: PropTypes.string,
    onSubmit: PropTypes.func
  };

  render() {
    const { modalName, modalProps, handleSubmit, onSubmit, dirty } = this.props;
    return (
      <Modal
        name={modalName ? modalName : TEXT_EDITOR_FORM}
        title={i18next.t('plans.settingUpText')}
        width="400px"
        {...modalProps}
      >
        <Form onSubmit={handleSubmit}>
          <FormItem {...ItemLayoutWithLabel} label={i18next.t('fontSize')}>
            <Field
              name="fontSize"
              className={styles.font_size}
              component={Input.Number}
              min={1}
              max={250}
            />
          </FormItem>
          <FormItem {...ItemLayoutWithLabel} label={i18next.t('fontColor')}>
            <Field
              name="color"
              value={this.props?.initialValues?.color}
              component={ColorPicker}
              blockStyles={{ width: '100%' }}
              popup={true}
            />
          </FormItem>
          <FormItem {...ItemLayoutWithLabel} label={i18next.t('font')}>
            <Field
              name="fontFamily"
              component={Select}
              options={textFonts.values.map(item => ({
                value: item,
                label: item
              }))}
            />
          </FormItem>
          <FormItem {...ItemLayoutWithLabel} label={i18next.t('fontStyle')}>
            <div className={styles.font_style_container}>
              <Field
                name="fontStyle"
                className={styles.font_style}
                component={ToggleButton}
                variants={['normal', 'italic']}
                icon={fontsStyle.icon}
              />
              <Field
                name="fontWeight"
                className={styles.font_style}
                component={ToggleButton}
                variants={['normal', 'bold']}
                icon={fontsWeight.icon}
              />
            </div>
          </FormItem>
          <FormItem {...ItemLayoutWithoutLabel}>
            <Button type="primary" htmlType="submit" disabled={!dirty} onClick={onSubmit}>
              {i18next.t('buttons.save')}
            </Button>
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

const validate = values => {};

TextEditorForm = reduxForm({
  validate,
  enableReinitialize: true
})(TextEditorForm);

const mapStateToProps = (state, props) => {
  const form = props.form ? props.form : TEXT_EDITOR_FORM;
  return {
    form,
    errors: getFormSyncErrors(form)(state)
  };
};

TextEditorForm = connect(mapStateToProps, null)(TextEditorForm);

export default TextEditorForm;
