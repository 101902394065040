import {
  isLoaded,
  PLAN_GROUPS_LOAD,
  PLAN_GROUP_CREATE,
  PLAN_GROUP_UPDATE,
  PLAN_GROUP_DELETE,
  PROJECT_DELETE,
  PROJECT_LOAD_CURRENT,
  PROJECT_LOAD_ACTIVE,
  PROJECT_UPDATE_STATUS
} from 'constants/actionTypes';

/*
 * state = planGroups: {
 *    <projectId>: {
 *      <planGroupId>: {
 *          id: <string>,
 *          ...
 *      },
 *      ...
 *    },
 *    ...
 * }
 * (см. сущность, возвращаемую методом GET api/v1/projects/<projectId>/plan_groups в тех. проекте)
 */
export default function planGroups(state = {}, action) {
  switch (action.type) {
    case isLoaded(PLAN_GROUPS_LOAD, true): {
      const { projectId, planGroups } = action.payload;
      const newState = { ...state };
      if (!newState[projectId]) {
        newState[projectId] = {};
      }
      newState[projectId] = {};
      planGroups.forEach(planGroup => {
        newState[projectId][planGroup.id] = planGroup;
      });
      return newState;
    }
    case isLoaded(PLAN_GROUP_CREATE, true): {
      const { projectId, newPlanGroup } = action.payload;
      if (!state[projectId]) return state;
      const newState = { ...state };
      newState[projectId][newPlanGroup.id] = newPlanGroup;
      newState[projectId] = { ...newState[projectId] };
      return newState;
    }
    case isLoaded(PLAN_GROUP_DELETE, true): {
      const { planGroupId, projectId } = action.payload;
      if (!state[projectId]) return state;
      if (!state[projectId][planGroupId]) return state;
      const newState = { ...state };
      delete newState[projectId][planGroupId];
      newState[projectId] = { ...newState[projectId] };
      return newState;
    }
    case isLoaded(PLAN_GROUP_UPDATE, true): {
      const { updatedPlanGroup, projectId } = action.payload;
      if (!state[projectId]) return state;
      if (!state[projectId][updatedPlanGroup.id]) return state;
      const newState = { ...state };
      newState[projectId][updatedPlanGroup.id] = updatedPlanGroup;
      newState[projectId] = { ...newState[projectId] };
      return newState;
    }
    case isLoaded(PROJECT_LOAD_CURRENT, true):
    case isLoaded(PROJECT_LOAD_ACTIVE, true):
    case isLoaded(PROJECT_UPDATE_STATUS, true): {
      const { project, planGroups } = action.payload;
      const newState = { ...state };
      if (!newState[project.id]) {
        newState[project.id] = {};
      }
      newState[project.id] = {};
      planGroups.forEach(planGroup => {
        newState[project.id][planGroup.id] = planGroup;
      });
      newState[project.id] = { ...newState[project.id] };
      return newState;
    }
    case isLoaded(PROJECT_DELETE, true): {
      const projectId = action.payload;
      if (!state[projectId]) return state;
      const newState = { ...state };
      delete newState[projectId];
      return newState;
    }
    default: {
      return state;
    }
  }
}
