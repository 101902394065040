import React from 'react';

const ZoomReset = ({ color = "#FFF", x = "20", y = "20", isActive = true }) => {
  return (
    <svg width={x} height={y} viewBox={`0 0 ${x} ${y}`}>
      <path
        id="zoom_reset"
        fill={color} 
        fillOpacity={isActive ? '1' : '.5'}
        fillRule="evenodd"
        d="M14.407 12.162l4.128 4.128a1.588 1.588 0 0 1-2.245 2.245l-4.128-4.128a.25.25 0 0 1-.033-.04.453.453 0 0 0-.025-.03 7.812 7.812 0 1 1 2.233-2.232l.03.024a.25.25 0 0 1 .04.033zm-6.572-5.95L9.567 4.48a.574.574 0 0 1 .4-.165c.148 0 .295.059.401.165l.802.801a.574.574 0 0 1 .165.4c0 .148-.06.295-.165.401L9.437 7.815l1.733 1.732a.573.573 0 0 1 0 .802l-.802.8a.571.571 0 0 1-.8 0L7.834 9.418 6.102 11.15a.57.57 0 0 1-.8 0l-.802-.8a.573.573 0 0 1 0-.801l1.732-1.733L4.5 6.082a.574.574 0 0 1-.165-.4c0-.147.06-.295.165-.401l.8-.801a.573.573 0 0 1 .801 0l1.733 1.732z"/>
    </svg>
  )
}

export default ZoomReset;