import { takeEvery, select, call, all } from 'redux-saga/effects';
import message from 'components/message';
import {
  DEVICE_CREATE,
  DEVICES_COPY_AND_PASTE,
  DEVICES_MOVE,
  DEVICE_UPDATE_TYPE,
  DEVICE_SET_CONFIG,
  DEVICE_DELETE,
  DEVICES_DELETE,
  DEVICES_RESET_STATES,
  DEVICE_UPLOAD_CONFIG,
  DEVICE_DOWNLOAD_CONFIG,
  DEVICE_UPLOAD_CONTROL_DATABASE,
  DEVICE_GET_PROFILE_VIEWS,
  DEVICE_GET_PROFILES_DICTIONARY,
  DEVICE_UPDATE_ADDRESS_PATH,
  DEVICES_LOAD_ACTIVE,
  DEVICE_UPDATE_PLAN_LAYOUTS,
  DEVICES_UPDATE_PLAN_LAYOUTS,
  DEVICES_CHANGE_REGION_BINDING,
  DEVICES_LOAD,
  ACTIVE_DEVICE_SET_CONFIG,
  DEVICE_SET_PROPERTIES,
  DEVICE_SET_POLLING_STATE,
  DEVICE_SET_TIME,
  DEVICE_UPLOAD_ALL_CONTROL_DATABASES,
  DEVICE_RESET,
  DEVICE_SET_DISABLED,
  DEVICE_UPDATE_FW,
  DEVICE_READ_EVENTS,
  DEVICE_SET_CONTROL_PASSWORD,
  DEVICE_UPDATE_LINKS,
  DEVICE_ADD_NOTE,
  DEVICE_PERFORM_ACTION,
  DEVICE_EXPORT_CONTOL_CONFIG,
  DEVICE_IMPORT_CONTOL_CONFIG,
  DEVICE_SET_SHAPE_LIBRARY,
  DEVICE_UPDATE_SUBSYSTEM,
  DEVICE_UPDATE_SPECIFIC_SETTINGS,
  DEVICE_GET_FULL,
  ACTIVE_DEVICE_UPDATED,
  SCENARIOS_UPDATED,
  INDICATOR_PANEL_UPDATE,
  VIRTUAL_STATES_DELETE,
  PROJECT_VALIDATE_DELETE,
  VIRTUAL_COUNTER_UPDATE,
  VIRTUAL_STATE_UPDATE,
  DEVICES_SET_CONFIGS_AND_PROPERTIES,
  DEVICE_ADDITIONAL_CONFIG_FUNCTION,
  MODAL_CLOSE
} from 'constants/actionTypes';

import { fetch, request, dispatch, dispatchSuccess, dispatchFail } from 'helpers/request';
import i18next from 'i18next';
import { MESSAGE_KEY } from '../containers/Forms/CopyDeviceForm';
import { MODAL_NAMES } from '../constants/modals';

function* getDeviceProfileViews({ type }) {
  try {
    const response = yield fetch('/device_profile_views');
    if (!Array.isArray(response)) {
      throw new Error(i18next.t('invalidDeviceProfilesFormat'));
    }

    yield dispatchSuccess(type, response);
  } catch (error) {
    yield dispatchFail(type, error.message);
  }
}

function* getDeviceProfilesDictionary({ type }) {
  try {
    const response = yield fetch('/device_profile_views/device_profiles_dictionary');

    yield dispatchSuccess(type, response);
  } catch (error) {
    yield dispatchFail(type, error.message);
  }
}

function* loadDevices(action) {
  const { projectId } = action.payload;
  try {
    const response = yield fetch(`/projects/${projectId}/devices/`);
    if (!Array.isArray(response)) {
      throw new Error(i18next.t('errors.incorrectResponseReceived', { response: response }));
    }
    yield dispatchSuccess(DEVICES_LOAD, { projectId, devices: response });
  } catch (error) {
    yield dispatchFail(DEVICES_LOAD, error.message);
  }
}

function* getFullDevice(action) {
  const { projectId, deviceId } = action.payload;
  try {
    const response = yield fetch(`/projects/${projectId}/devices/${deviceId}`);
    if (!response) throw new Error(i18next.t('errors.errorGettingParams'));
    yield dispatchSuccess(DEVICE_GET_FULL, { projectId, device: response });
    /* Если получили оперативную сущность, то отправим еще экшен для обновления устройства в активном проекте.  */
    if (response.activeStateViews) yield dispatch(ACTIVE_DEVICE_UPDATED, { ...response });
  } catch (error) {
    yield dispatchFail(DEVICE_GET_FULL, error.message);
  }
}

function* createDevice(action) {
  const { projectId, device } = action.payload;
  try {
    /* добавляем новое устройство REST-запросом */
    const response = yield request(`/projects/${projectId}/devices`, 'POST', device);
    yield dispatchSuccess(DEVICE_CREATE, response);
    message('success', i18next.t('messages.added'));
  } catch (error) {
    yield dispatchFail(DEVICE_CREATE, error.message);
  }
}

function* copyAndPasteDevices(action) {
  const { projectId, parentId, newLineNo, newAddress, deviceIds } = action.payload;
  const key = MESSAGE_KEY;
  try {
    message.loading({ key, content: i18next.t('messages.insertionInProgress'), duration: 0 });
    const parentParam = parentId ? `parentId=${parentId}&` : '';
    const params = parentParam + `newLineNo=${newLineNo}&newAddress=${newAddress}`;
    const path = `/projects/${projectId}/devices/create_copies?` + params;
    const response = yield request(path, 'POST', deviceIds);
    yield dispatchSuccess(DEVICE_CREATE, response);
    yield dispatch(MODAL_CLOSE, MODAL_NAMES.COPY_DEVICES);
    message.success({ key, content: i18next.t('messages.insertDone'), duration: 1 });
  } catch (error) {
    message.destroy(key);
    yield dispatchFail(DEVICES_COPY_AND_PASTE, error.message);
  }
}

function* setDevicesConfigsAndProperties({ type, payload }) {
  const { projectId, devicesAndProperties, callback = () => {}, isSaveAction } = payload;
  const key = MESSAGE_KEY;
  try {
    if (!isSaveAction)
      message.loading({
        key,
        content: i18next.t('messages.insertionInProgress'),
        duration: 1
      });
    const path = `/projects/${projectId}/devices?allProperties`;
    const response = yield request(path, 'PUT', devicesAndProperties);
    yield dispatchSuccess(type, { projectId, createdDevices: [], updatedDevices: response });
    yield message.success(i18next.t('messages.updatedDeviceParams'));
    yield call(callback, response, null);
  } catch (error) {
    message.destroy(key);
    yield dispatchFail(type, error.message);
    yield call(callback, null, error);
  }
}

function* moveDevices(action) {
  const {
    projectId,
    parentId,
    newLineNo,
    newAddress,
    deviceIds,
    callback = () => {}
  } = action.payload;

  const parentDeviceId = parentId ? parentId : null;
  try {
    const params = `parentId=${parentDeviceId}&newLineNo=${newLineNo}&newAddress=${newAddress}`;
    const path = `/projects/${projectId}/devices/move?` + params;
    const response = yield request(path, 'POST', deviceIds);

    const {
      createdDevices,
      removedDeviceIds,
      removedValidateMessageIds,
      updatedDevices,
      updatedIndicatorPanels,
      updatedScenarios,
      updatedVirtualCounters,
      updatedVirtualStates
    } = response;

    yield dispatchSuccess(DEVICE_CREATE, {
      projectId,
      createdDevices,
      updatedDevices,
      removedDeviceIds
    });

    if (removedValidateMessageIds.length) {
      yield dispatchSuccess(PROJECT_VALIDATE_DELETE, {
        projectId,
        validateMessageIds: removedValidateMessageIds
      });
    }
    if (updatedIndicatorPanels.length) {
      yield dispatchSuccess(INDICATOR_PANEL_UPDATE, {
        projectId,
        updatedIndicatorPanels
      });
    }
    if (updatedScenarios.length) {
      yield dispatch(SCENARIOS_UPDATED, updatedScenarios);
    }

    if (updatedVirtualCounters.length) {
      yield dispatchSuccess(VIRTUAL_COUNTER_UPDATE, {
        projectId,
        updatedVirtualCounter: updatedVirtualCounters
      });
    }

    if (updatedVirtualStates.length) {
      yield dispatchSuccess(VIRTUAL_STATE_UPDATE, {
        projectId,
        updatedVirtualStates
      });
    }

    yield call(callback, response, null);
  } catch (error) {
    yield dispatchFail(DEVICES_MOVE, error.message);
    yield call(callback, null, error);
  }
}

function* updateDeviceType(action) {
  const { projectId, deviceId, newDeviceProfileId } = action.payload;
  try {
    const deviceRevision = yield select(state => state.devices[projectId].hash[deviceId].revision);

    /* изменяем тип устройства REST-запросом */
    const response = yield request(
      `/projects/${projectId}/devices/${deviceId}?device_profile_id=${newDeviceProfileId}`,
      'PUT',
      {},
      { ETag: deviceRevision }
    );
    if (response.updatedScenarios && response.updatedScenarios.length) {
      yield dispatch(SCENARIOS_UPDATED, response.updatedScenarios);
    }
    if (response.updatedIndicatorPanels && response.updatedIndicatorPanels.length) {
      yield dispatchSuccess(INDICATOR_PANEL_UPDATE, {
        projectId,
        updatedIndicatorPanels: response.updatedIndicatorPanels
      });
    }
    if (response.removedVirtualStateIds && response.removedVirtualStateIds.length) {
      yield dispatchSuccess(VIRTUAL_STATES_DELETE, {
        projectId,
        virtualStateIds: response.removedVirtualStateIds
      });
    }
    if (response.removedValidateMessageIds && response.removedValidateMessageIds.length) {
      yield dispatchSuccess(PROJECT_VALIDATE_DELETE, {
        projectId,
        validateMessageIds: response.removedValidateMessageIds
      });
    }
    if (response.removedDeviceIds && response.removedDeviceIds.length) {
      yield dispatchSuccess(DEVICES_DELETE, response);
    }

    yield dispatchSuccess(DEVICE_UPDATE_TYPE, response);
  } catch (error) {
    yield dispatchFail(DEVICE_UPDATE_TYPE, error.message);
  }
}

function* updateDeviceAddressPath(action) {
  const { projectId, deviceId, newDeviceAddressPath } = action.payload;
  try {
    const deviceRevision = yield select(state => state.devices[projectId].hash[deviceId].revision);

    /* изменяем адрес устройства REST-запросом */
    const response = yield request(
      `/projects/${projectId}/devices/${deviceId}?address=${newDeviceAddressPath}`,
      'PUT',
      {},
      { ETag: deviceRevision }
    );

    yield dispatchSuccess(DEVICE_UPDATE_ADDRESS_PATH, { projectId, device: response });
  } catch (error) {
    yield dispatchFail(DEVICE_UPDATE_ADDRESS_PATH, error.message);
  }
}

function* updateDevicePlanLayouts(action) {
  const { projectId, deviceId, planLayouts, callback = () => {} } = action.payload;
  try {
    const deviceRevision = yield select(state => state.devices[projectId].hash[deviceId].revision);

    /* изменяем расположение устройства на планах REST-запросом */
    const response = yield request(
      `/projects/${projectId}/devices/${deviceId}?plan_layouts`,
      'PUT',
      planLayouts,
      { ETag: deviceRevision }
    );
    yield dispatchSuccess(DEVICE_UPDATE_PLAN_LAYOUTS, { projectId, device: response });
    yield call(callback, response);
    message('success', i18next.t('messages.deviceLocationChanged'));
  } catch (error) {
    yield dispatchFail(DEVICE_UPDATE_PLAN_LAYOUTS, error.message);
    yield call(callback, null, error);
  }
}

export function* updateDevicesPlanLayouts(action) {
  const { updatedDevices, projectId } = action.payload;
  try {
    /* изменяем расположение устройств на планах REST-запросом */

    // TODO Реализовать функционал на беке, принимающий массив устройств со следующими полями {deviceLayout: {coordinatePoint:{x:Float,y:Float}, planId:String}[], deviceId: Number, deviceRevision: Number}[]
    // Бек должен возвращать массив этих же устройств со всеми их полями

    //----------------------------раскомментить после реализации метода на беке----------------------------

    // const response = yield request(
    //   `/projects/${projectId}/devices/group_devices?plan_layouts`,
    //   'PATCH',
    //   updatedDevices
    // );

    //----------------------------раскомментить после реализации метода на беке----------------------------

    //----------------------------удалить после реализации метода на беке----------------------------

    const response = yield all([
      ...updatedDevices.map(({ deviceId, deviceLayout, deviceRevision }) => {
        return request(
          `/projects/${projectId}/devices/${deviceId}?plan_layouts`,
          'PUT',
          deviceLayout,
          { ETag: deviceRevision }
        );
      })
    ]);
    //----------------------------удалить после реализации метода на беке----------------------------
    yield dispatchSuccess(DEVICES_UPDATE_PLAN_LAYOUTS, { projectId, devices: response });
    message('success', i18next.t('messages.deviceLocationChanged', { context: 'plural' }));
  } catch (error) {
    yield dispatchFail(DEVICES_UPDATE_PLAN_LAYOUTS, error.message);
  }
}

function* deleteDevice(action) {
  const { projectId, deviceId } = action.payload;
  const key = MESSAGE_KEY;
  try {
    const deviceRevision = yield select(state => state.devices[projectId].hash[deviceId].revision);
    message.loading({ key, content: i18next.t('messages.deletingDeviceInProgress'), duration: 0 });
    const response = yield request(
      `/projects/${projectId}/devices/${deviceId}`,
      'DELETE',
      {},
      { ETag: deviceRevision }
    );
    if (response.updatedScenarios && response.updatedScenarios.length) {
      yield dispatch(SCENARIOS_UPDATED, response.updatedScenarios);
    }
    if (response.updatedIndicatorPanels && response.updatedIndicatorPanels.length) {
      yield dispatchSuccess(INDICATOR_PANEL_UPDATE, {
        projectId,
        updatedIndicatorPanels: response.updatedIndicatorPanels
      });
    }
    if (response.removedVirtualStateIds && response.removedVirtualStateIds.length) {
      yield dispatchSuccess(VIRTUAL_STATES_DELETE, {
        projectId,
        virtualStateIds: response.removedVirtualStateIds
      });
    }
    if (response.removedValidateMessageIds && response.removedValidateMessageIds.length) {
      yield dispatchSuccess(PROJECT_VALIDATE_DELETE, {
        projectId,
        validateMessageIds: response.removedValidateMessageIds
      });
    }

    yield dispatchSuccess(DEVICE_DELETE, response);
    message.success({ key, content: i18next.t('messages.devicesRemoved'), duration: 1 });
  } catch (error) {
    message.destroy(key);
    yield dispatchFail(DEVICE_DELETE, error.message);
  }
}

function* deleteDevices(action) {
  const { projectId, deviceIds } = action.payload;
  const key = MESSAGE_KEY;
  try {
    message.loading({ key, content: i18next.t('messages.deletingDeviceInProgress'), duration: 0 });
    const response = yield request(`/projects/${projectId}/devices/`, 'DELETE', deviceIds);
    if (response.updatedScenarios && response.updatedScenarios.length) {
      yield dispatch(SCENARIOS_UPDATED, response.updatedScenarios);
    }
    if (response.updatedIndicatorPanels && response.updatedIndicatorPanels.length) {
      yield dispatchSuccess(INDICATOR_PANEL_UPDATE, {
        projectId,
        updatedIndicatorPanels: response.updatedIndicatorPanels
      });
    }
    if (response.removedVirtualStateIds && response.removedVirtualStateIds.length) {
      yield dispatchSuccess(VIRTUAL_STATES_DELETE, {
        projectId,
        virtualStateIds: response.removedVirtualStateIds
      });
    }
    if (response.removedValidateMessageIds && response.removedValidateMessageIds.length) {
      yield dispatchSuccess(PROJECT_VALIDATE_DELETE, {
        projectId,
        validateMessageIds: response.removedValidateMessageIds
      });
    }

    yield dispatchSuccess(DEVICES_DELETE, response);
    message.success({ key, content: i18next.t('messages.devicesRemoved'), duration: 1 });
  } catch (error) {
    message.destroy(key);
    yield dispatchFail(DEVICES_DELETE, error.message);
  }
}

function* loadActiveDevices(action) {
  const { projectId } = action.payload;
  try {
    const response = yield fetch(`/projects/${projectId}/active_devices/`);
    if (!Array.isArray(response)) {
      throw new Error(i18next.t('errors.incorrectResponseReceived', { response: response }));
    }
    yield dispatchSuccess(DEVICES_LOAD_ACTIVE, response);
  } catch (error) {
    yield dispatchFail(DEVICES_LOAD_ACTIVE, error.message);
  }
}

function* setDeviceConfig(action) {
  const { projectId, deviceId, deviceConfig, callback = () => {} } = action.payload;
  try {
    const deviceRevision = yield select(state => state.devices[projectId].hash[deviceId].revision);

    const response = yield request(
      `/projects/${projectId}/devices/${deviceId}?config`,
      'PUT',
      deviceConfig,
      { ETag: deviceRevision }
    );

    yield dispatchSuccess(DEVICE_SET_CONFIG, { projectId, device: response });
    yield call(callback, response, null);
  } catch (error) {
    yield dispatchFail(DEVICE_SET_CONFIG, error.message);
    yield call(callback, null, error);
  }
}

function* setActiveDeviceConfig(action) {
  const { projectId, deviceId, deviceConfig, callback = () => {} } = action.payload;
  try {
    const response = yield request(
      `/projects/${projectId}/active_devices/${deviceId}?config`,
      'PUT',
      deviceConfig
    );
    yield dispatchSuccess(ACTIVE_DEVICE_SET_CONFIG, { projectId, device: response });
    yield call(callback, response, null);
  } catch (error) {
    yield dispatchFail(ACTIVE_DEVICE_SET_CONFIG, error.message);
    yield call(callback, null, error);
  }
}

function* deviceResetState(action) {
  const { resetAction, projectId } = action.payload;
  const newIssue = {
    action: 'RESET_STATE_ON_ALL_DEVICES',
    projectId: projectId,
    parameters: {
      manualResetStateGroup: resetAction
    }
  };
  try {
    const response = yield request(`/issues`, 'POST', newIssue);

    yield dispatchSuccess(DEVICES_RESET_STATES, response);
  } catch (error) {
    yield dispatchFail(DEVICES_RESET_STATES, error);
  }
}

function* uploadAllDeviceControlDatabases({ type, payload }) {
  const { projectId, params } = payload;
  const newIssue = {
    action: 'WRITE_ALL_CONTROL_DEVICES_DATABASE',
    projectId: projectId,
    parameters: params
  };
  try {
    const response = yield request(`/issues`, 'POST', newIssue);

    yield dispatchSuccess(type, response);
  } catch (error) {
    yield dispatchFail(type, error);
  }
}

function getDeviceIssueSaga(actionType, actionId) {
  return function*(action) {
    const { projectId, deviceId } = action.payload;
    const newIssue = {
      action: actionId,
      projectId,
      deviceId
    };
    try {
      const response = yield request(`/issues`, 'POST', newIssue);

      yield dispatchSuccess(actionType, response);
    } catch (error) {
      yield dispatchFail(actionType, error.message);
    }
  };
}

function* setDevicePollingState(action) {
  const { projectId, deviceId, regionId, state } = action.payload;
  const newIssue = {
    action:
      (state === true ? 'ENABLE' : 'DISABLE') +
      `${deviceId ? '_DEVICE' : '_REGION_DEVICES'}` +
      '_POLLING_STATE',
    projectId,
    deviceId,
    parameters: {
      regionId
    }
  };
  try {
    const response = yield request(`/issues`, 'POST', newIssue);

    yield dispatchSuccess(DEVICE_SET_POLLING_STATE, response);
  } catch (error) {
    yield dispatchFail(DEVICE_SET_POLLING_STATE, error.message);
  }
}

function* setDeviceProperties(action) {
  const { projectId, deviceId, deviceProperties, callback = () => {} } = action.payload;
  try {
    const deviceRevision = yield select(state => state.devices[projectId].hash[deviceId].revision);
    const response = yield request(
      `/projects/${projectId}/devices/${deviceId}?properties`,
      'PUT',
      deviceProperties,
      { ETag: deviceRevision }
    );

    yield dispatchSuccess(DEVICE_SET_PROPERTIES, { projectId, device: response });
    yield call(callback, response, null);
  } catch (error) {
    yield dispatchFail(DEVICE_SET_PROPERTIES, error.message);
    yield call(callback, null, error);
  }
}

function* setDeviceDisabled({ type, payload }) {
  const { projectId, deviceId, disabled } = payload;
  try {
    const deviceRevision = yield select(state => state.devices[projectId].hash[deviceId].revision);
    const response = yield request(
      `/projects/${projectId}/devices/${deviceId}?disabled=${disabled}`,
      'PUT',
      {},
      { ETag: deviceRevision }
    );

    if (response.updatedScenarios && response.updatedScenarios.length) {
      yield dispatch(SCENARIOS_UPDATED, response.updatedScenarios);
    }
    if (response.updatedIndicatorPanels && response.updatedIndicatorPanels.length) {
      yield dispatchSuccess(INDICATOR_PANEL_UPDATE, {
        projectId,
        updatedIndicatorPanels: response.updatedIndicatorPanels
      });
    }
    if (response.removedVirtualStateIds && response.removedVirtualStateIds.length) {
      yield dispatchSuccess(VIRTUAL_STATES_DELETE, {
        projectId,
        virtualStateIds: response.removedVirtualStateIds
      });
    }
    if (response.removedDeviceIds && response.removedDeviceIds.length) {
      yield dispatchSuccess(DEVICES_DELETE, response);
    }
    yield dispatchSuccess(DEVICE_SET_DISABLED, { projectId, devices: response.updatedDevices });
  } catch (err) {
    yield dispatchFail(DEVICE_SET_DISABLED);
  }
}

function* updateDeviceFw(action) {
  const {
    projectId,
    deviceId,
    fwFile,
    updateByUSB,
    all,
    forcedWrite,
    needClearDatabase
  } = action.payload;
  const newIssue = {
    action: all ? 'UPDATE_ALL_CONTROL_DEVICE_FIRMWARE' : 'UPDATE_CONTROL_DEVICE_FIRMWARE',
    projectId,
    deviceId,
    parameters: {
      fwFile,
      updateByUSB,
      forcedWrite,
      needClearDatabase
    }
  };
  try {
    const response = yield request(`/issues`, 'POST', newIssue);

    yield dispatchSuccess(DEVICE_UPDATE_FW, response);
  } catch (error) {
    yield dispatchFail(DEVICE_UPDATE_FW, error.message);
  }
}

function* readEvents(action) {
  const { projectId, deviceId, logTypes } = action.payload;
  const newIssue = {
    action: 'READ_EVENTS',
    projectId,
    deviceId,
    parameters: {
      logIds: logTypes
    }
  };
  try {
    const response = yield request(`/issues`, 'POST', newIssue);

    yield dispatchSuccess(DEVICE_READ_EVENTS, response);
  } catch (error) {
    yield dispatchFail(DEVICE_READ_EVENTS, error.message);
  }
}

function* setDeviceControlPassword({ type, payload }) {
  const { projectId, deviceId, username, password } = payload;
  const newIssue = {
    action: 'SET_CONTROL_DEVICE_USER_PASSWORD',
    projectId,
    deviceId,
    parameters: { username, password }
  };
  try {
    const response = yield request(`/issues`, 'POST', newIssue);
    yield dispatchSuccess(DEVICE_SET_CONTROL_PASSWORD, response);
  } catch (err) {
    yield dispatchFail(DEVICE_SET_CONTROL_PASSWORD);
  }
}

function* updateDeviceLinks({ type, payload }) {
  const { projectId, deviceId, deviceLinks } = payload;
  try {
    const deviceRevision = yield select(state => state.devices[projectId].hash[deviceId].revision);
    const response = yield request(
      `/projects/${projectId}/devices/${deviceId}?links`,
      'PUT',
      deviceLinks,
      { ETag: deviceRevision }
    );
    yield dispatchSuccess(type, { projectId, createdDevices: [], updatedDevices: response });
  } catch (err) {
    yield dispatchFail(type);
  }
}

function* addDeviceNote({ type, payload }) {
  const { projectId, deviceId, messageText } = payload;
  try {
    const response = yield request(
      `/projects/${projectId}/active_devices/${deviceId}?notepad`,
      'POST',
      { message: messageText }
    );
    yield dispatchSuccess(type, { projectId, device: response });
  } catch (error) {
    yield dispatchFail(type, error.message);
  }
}

function* performDeviceAction({ type, payload }) {
  const { projectId, deviceId, actionId, actionParameters } = payload;
  const newIssue = {
    action: 'PERFORM_DEVICE_ACTION',
    projectId,
    deviceId,
    parameters: {
      actionId,
      actionParameters
    }
  };
  try {
    const response = yield request(`/issues`, 'POST', newIssue);

    yield dispatchSuccess(type, response);
  } catch (error) {
    yield dispatchFail(type, error);
  }
}

function* uploadDeviceControlDatabases({ type, payload }) {
  const { projectId, deviceId, params } = payload;
  const newIssue = {
    action: 'WRITE_CONTROL_DEVICE_DATABASE',
    projectId,
    deviceId,
    parameters: params
  };
  try {
    const response = yield request(`/issues`, 'POST', newIssue);

    yield dispatchSuccess(type, response);
  } catch (error) {
    yield dispatchFail(type, error.message);
  }
}

function* exportControlDeviceConfig({ type, payload }) {
  const { projectId, deviceId } = payload;
  const newIssue = {
    action: 'READ_CONTROL_DEVICE_DATABASE',
    projectId,
    deviceId
  };
  try {
    const response = yield request('/issues', 'POST', newIssue);

    yield dispatchSuccess(type, { issue: response[0] });
  } catch (err) {
    yield dispatchFail(type, err.message);
  }
}

function* importControlDeviceConfig({ type, payload }) {
  const { projectId, entitiesOfControlDevice, blob, importParam } = payload;
  try {
    let response;
    if (blob) {
      response = yield request(
        `/projects/${projectId}/entitiesCompressed?` +
          `parentDeviceId=${importParam.parentDevice.id}` +
          `&lineNo=${importParam.lineNo}` +
          `&lineAddress=${importParam.lineAddress}`,
        'POST',
        blob,
        { 'Content-Type': 'application/octet-stream' }
      );
    } else {
      response = yield request(
        `/projects/${projectId}/entities?` +
          `parentDeviceId=${importParam.parentDevice.id}` +
          `&lineNo=${importParam.lineNo}` +
          `&lineAddress=${importParam.lineAddress}`,
        'POST',
        entitiesOfControlDevice,
        {}
      );
    }
    yield dispatchSuccess(type, { projectId, ...response });
  } catch (err) {
    yield dispatchFail(type, err.message);
  }
}

function* setDeviceShapeLibrary({ type, payload }) {
  const { projectId, deviceId, deviceShapeLibraryId, callback = () => {} } = payload;
  try {
    const deviceRevision = yield select(state => state.devices[projectId].hash[deviceId].revision);

    const response = yield request(
      `/projects/${projectId}/devices/${deviceId}?shape_library=${deviceShapeLibraryId}`,
      'PUT',
      {},
      { ETag: deviceRevision }
    );
    yield dispatchSuccess(type, { projectId, device: response });
    yield call(callback, response, null);
  } catch (err) {
    yield dispatchFail(type);
    yield call(callback, null, err);
  }
}

function* updateDeviceSubsystem({ type, payload }) {
  const { projectId, deviceId, subsystem } = payload;
  try {
    const deviceRevision = yield select(state => state.devices[projectId].hash[deviceId].revision);

    const response = yield request(
      `/projects/${projectId}/devices/${deviceId}?custom_subsystem=${subsystem}`,
      'PUT',
      {},
      { ETag: deviceRevision }
    );

    if (response.updatedScenarios && response.updatedScenarios.length) {
      yield dispatch(SCENARIOS_UPDATED, response.updatedScenarios);
    }
    yield dispatchSuccess(type, response);
  } catch (error) {
    yield dispatchFail(type, error.message);
  }
}

function* updateDeviceSpecificSettings({ type, payload }) {
  const { projectId, device, settingsByDeviceIds } = payload;
  try {
    /* изменяем настройки устройства REST-запросом */
    let response;
    if (device) {
      response = yield request(
        `/projects/${projectId}/devices/${device.id}?specific_settings=`,
        'PUT',
        device.specificSettings,
        { ETag: device.revision }
      );
    } else {
      response = yield request(
        `/projects/${projectId}/devices?specific_settings=`,
        'PUT',
        settingsByDeviceIds
      );
    }

    if (!Array.isArray(response)) response = [response];
    yield dispatchSuccess(type, { projectId, createdDevices: [], updatedDevices: response });
  } catch (error) {
    yield dispatchFail(type, error.message);
  }
}

function* additionalConfigFunction({ type, payload }) {
  const { projectId, deviceId, issueAction, paramName, value } = payload;
  const newIssue = {
    action: issueAction,
    projectId,
    deviceId,
    parameters: {
      [paramName]: value
    }
  };
  try {
    const response = yield request('/issues', 'POST', newIssue);

    yield dispatchSuccess(type, { issue: response[0] });
  } catch (err) {
    yield dispatchFail(type, err.message);
  }
}

export function* changeDeviceBindingToRegion(action) {
  const { updatedDevices, projectId } = action.payload;
  try {
    // TODO Реализовать функционал на беке, принимающий массив устройств со следующими полями {regionId: Number || null, deviceId: Number,deviceRevision: Number}[]
    // если regionId Number, перепривязываем к указанной зоне, если Null, то отвязываем. Бек должен возвращать массив этих же устройств со всеми их полями
    //----------------------------раскомментить после реализации метода на беке----------------------------

    // const response = yield request(
    //   `/projects/${projectId}/devices/,
    //   'PATCH',
    //   updatedDevices
    // );

    // yield dispatchSuccess(DEVICES_CHANGE_REGION_BINDING, {
    //   projectId,
    //   devices: response
    // });

    //----------------------------раскомментить после реализации метода на беке----------------------------

    //----------------------------удалить после реализации метода на беке----------------------------

    const deleteRegionDeviceList = updatedDevices.filter(device => device.isDeleting);
    const changingRegionDeviceList = updatedDevices.filter(device => !device.isDeleting);
    const deleteResponse = yield all([
      ...deleteRegionDeviceList.map(({ deviceId, regionId, deviceRevision }) => {
        return request(
          `/projects/${projectId}/regions/${regionId}/devices/${deviceId}`,
          'DELETE',
          {},
          { ETag: deviceRevision }
        );
      })
    ]);

    const changeResponse = yield all([
      ...changingRegionDeviceList.map(({ deviceId, regionId, deviceRevision }) => {
        return request(
          `/projects/${projectId}/regions/${regionId}/devices/${deviceId}`,
          'PUT',
          {},
          { ETag: deviceRevision }
        );
      })
    ]);
    yield dispatchSuccess(DEVICES_CHANGE_REGION_BINDING, {
      projectId,
      devices: deleteResponse.concat(changeResponse.flat())
    });

    //----------------------------удалить после реализации метода на беке----------------------------
  } catch (error) {
    yield dispatchFail(DEVICES_CHANGE_REGION_BINDING, error.message);
  }
}

export default function* deviceSagas() {
  yield takeEvery(ACTIVE_DEVICE_SET_CONFIG, setActiveDeviceConfig);
  yield takeEvery(DEVICE_SET_PROPERTIES, setDeviceProperties);
  yield takeEvery(DEVICE_GET_PROFILE_VIEWS, getDeviceProfileViews);
  yield takeEvery(DEVICE_GET_PROFILES_DICTIONARY, getDeviceProfilesDictionary);
  yield takeEvery(DEVICE_GET_FULL, getFullDevice);
  yield takeEvery(DEVICE_CREATE, createDevice);
  yield takeEvery(DEVICES_COPY_AND_PASTE, copyAndPasteDevices);
  yield takeEvery(DEVICES_SET_CONFIGS_AND_PROPERTIES, setDevicesConfigsAndProperties);
  yield takeEvery(DEVICES_MOVE, moveDevices);
  yield takeEvery(DEVICE_UPDATE_TYPE, updateDeviceType);
  yield takeEvery(DEVICE_UPDATE_ADDRESS_PATH, updateDeviceAddressPath);
  yield takeEvery(DEVICE_UPDATE_PLAN_LAYOUTS, updateDevicePlanLayouts);
  yield takeEvery(DEVICES_UPDATE_PLAN_LAYOUTS, updateDevicesPlanLayouts);
  yield takeEvery(DEVICES_CHANGE_REGION_BINDING, changeDeviceBindingToRegion);
  yield takeEvery(DEVICE_DELETE, deleteDevice);
  yield takeEvery(DEVICES_DELETE, deleteDevices);
  yield takeEvery(DEVICES_LOAD, loadDevices);
  yield takeEvery(DEVICES_LOAD_ACTIVE, loadActiveDevices);
  yield takeEvery(DEVICE_SET_CONFIG, setDeviceConfig);

  yield takeEvery(DEVICES_RESET_STATES, deviceResetState);
  yield takeEvery(
    DEVICE_UPLOAD_CONFIG,
    getDeviceIssueSaga(DEVICE_UPLOAD_CONFIG, 'WRITE_CONFIG_TO_DEVICE')
  );
  yield takeEvery(
    DEVICE_DOWNLOAD_CONFIG,
    getDeviceIssueSaga(DEVICE_DOWNLOAD_CONFIG, 'READ_CONFIG_FROM_DEVICE')
  );
  yield takeEvery(DEVICE_SET_POLLING_STATE, setDevicePollingState);
  yield takeEvery(DEVICE_SET_TIME, getDeviceIssueSaga(DEVICE_SET_TIME, 'SET_CONTROL_DEVICE_TIME'));
  yield takeEvery(DEVICE_RESET, getDeviceIssueSaga(DEVICE_RESET, 'RESET_CONTROL_DEVICE'));
  yield takeEvery(DEVICE_UPLOAD_ALL_CONTROL_DATABASES, uploadAllDeviceControlDatabases);
  yield takeEvery(DEVICE_SET_DISABLED, setDeviceDisabled);
  yield takeEvery(DEVICE_UPDATE_FW, updateDeviceFw);
  yield takeEvery(DEVICE_READ_EVENTS, readEvents);
  yield takeEvery(DEVICE_SET_CONTROL_PASSWORD, setDeviceControlPassword);
  yield takeEvery(DEVICE_UPDATE_LINKS, updateDeviceLinks);
  yield takeEvery(DEVICE_ADD_NOTE, addDeviceNote);
  yield takeEvery(DEVICE_PERFORM_ACTION, performDeviceAction);
  yield takeEvery(DEVICE_UPLOAD_CONTROL_DATABASE, uploadDeviceControlDatabases);
  yield takeEvery(DEVICE_EXPORT_CONTOL_CONFIG, exportControlDeviceConfig);
  yield takeEvery(DEVICE_IMPORT_CONTOL_CONFIG, importControlDeviceConfig);
  yield takeEvery(DEVICE_SET_SHAPE_LIBRARY, setDeviceShapeLibrary);
  yield takeEvery(DEVICE_UPDATE_SUBSYSTEM, updateDeviceSubsystem);
  yield takeEvery(DEVICE_UPDATE_SPECIFIC_SETTINGS, updateDeviceSpecificSettings);
  yield takeEvery(DEVICE_ADDITIONAL_CONFIG_FUNCTION, additionalConfigFunction);
}
