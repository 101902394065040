import { createAction } from 'redux-actions';
import { DRIVER_ATTRIBUTES_LOAD, DRIVER_DESCRIPTION_LOAD } from 'constants/actionTypeModules/other';

export const loadDriverAttributes = createAction(DRIVER_ATTRIBUTES_LOAD, driverProfileId => ({
  driverProfileId
}));

export const loadDriverDescription = createAction(DRIVER_DESCRIPTION_LOAD, driverProfileId => ({
  driverProfileId
}));
