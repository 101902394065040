import { select, takeEvery } from 'redux-saga/effects';
import {
  GET_CPIU_HANDLE_MODE_SCENARIOS,
  SET_CPIU_CURRENT_SCENARIO,
  SET_LAST_ENTERED_PASSWORD_DATA,
  SET_CPIU_AUTO_RESET_SCENARIO_STATUS
} from 'constants/actionTypes';
import { dispatchSuccess, dispatchFail, fetch, request, dispatch } from 'helpers/request';
import { getActiveProjectId } from 'helpers/activeProject';
import message from 'components/message';
import i18next from 'i18next';

function* getHandleModeScenarios(action) {
  const projectId = action.payload;
  try {
    const scenarios = yield fetch(`/projects/${projectId}/scenarios?manualStartStopAllowed=true`);
    yield dispatchSuccess(GET_CPIU_HANDLE_MODE_SCENARIOS, scenarios);
  } catch (error) {
    message('error', i18next.t(error.message));
    yield dispatchFail(GET_CPIU_HANDLE_MODE_SCENARIOS, error.message);
  }
}

function* setCurrentScenario(action) {
  const { scenarioId, password } = action.payload;
  try {
    const CPIUDevice = yield select(state => state.activeProject.CPIU.device);
    const projectId = yield select(state => getActiveProjectId(state));
    if (
      !CPIUDevice ||
      !CPIUDevice.id ||
      !CPIUDevice.specialData ||
      !projectId ||
      typeof scenarioId !== 'string'
    )
      throw new Error('Ошибка выбора сценария');
    const passwordQueryString = password ? `?password=${password}` : '';
    const specialData = {
      ...CPIUDevice.specialData,
      entityId: scenarioId,
      autoResetTimePaused: false
    };
    const response = yield request(
      `/projects/${projectId}/active_devices/${CPIUDevice.id}/specialData${passwordQueryString}`,
      'PUT',
      specialData
    );
    yield dispatchSuccess(SET_CPIU_CURRENT_SCENARIO, response);
    yield password && dispatch(SET_LAST_ENTERED_PASSWORD_DATA, { password, time: Date.now() });
  } catch (error) {
    yield dispatchFail(SET_CPIU_CURRENT_SCENARIO, error.message);
  }
}

function* setCurrentScenarioAutoResetStatus(action) {
  const specialData = action.payload;
  try {
    const projectId = yield select(state => getActiveProjectId(state));
    const CPIUDevice = yield select(state => state.activeProject.CPIU.device);
    if (!specialData || !CPIUDevice || !CPIUDevice.id || !projectId)
      throw new Error('Ошибка выбора сценария');
    const response = yield request(
      `/projects/${projectId}/active_devices/${CPIUDevice.id}/specialData`,
      'PUT',
      specialData
    );
    yield dispatchSuccess(SET_CPIU_CURRENT_SCENARIO, response);
  } catch (error) {
    yield dispatchFail(SET_CPIU_CURRENT_SCENARIO, error.message);
  }
}

export default function* CPIU() {
  yield takeEvery(GET_CPIU_HANDLE_MODE_SCENARIOS, getHandleModeScenarios);
  yield takeEvery(SET_CPIU_CURRENT_SCENARIO, setCurrentScenario);
  yield takeEvery(SET_CPIU_AUTO_RESET_SCENARIO_STATUS, setCurrentScenarioAutoResetStatus);
}
