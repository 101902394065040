import { createSelector } from 'reselect';

export const getCountdownsByEntityIdMap = createSelector(getCountdowns, countdowns => {
  const countdownsByEntityIdMap = {};
  for (const countdown of Object.values(countdowns)) {
    countdownsByEntityIdMap[countdown.entityId] = countdown;
  }
  return countdownsByEntityIdMap;
});

function getCountdowns({ activeProject: { countdowns } }) {
  return countdowns;
}
