import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createSelector } from 'reselect';
import { isEmpty, isEqual } from 'lodash';
import i18next from 'i18next';

import { ANIMATION_SPEED, getAnimationTypes } from 'constants/deviceShapeLibrary';

import { updateCurrentDevice } from 'actions/devices';
import { clearTextures } from 'actions/textures';
import { updateCurrentEntityInfo } from 'actions/widgets';
import { hideMenu } from 'components/Popup';
import message from 'components/message';
import styles from './styles.module.css';

import {
  getActiveDevicesHash,
  getActivePlansHash,
  getActiveRegionsHash,
  getTextures
} from 'helpers/activeProject';
import { getDeviceProfileViewsHash } from 'helpers/deviceProfileViews';
import { getCurrentUser } from 'helpers/user';
import { availableByFilterTags } from 'helpers/filtration';

import * as PIXI from 'pixi.js-legacy'; //Реализация canvas теперь только в пакете pixi.js-legacy
import { Container, Graphics, Point, Polygon, Sprite, Text, Texture } from 'pixi.js-legacy';
import { Viewport } from 'pixi-viewport';
import { CURSOR_STYLES } from 'constants/cursor';
import { ContextMenuTrigger } from 'react-contextmenu';
import PlanViewerContextMenu from 'containers/ContextMenus/PlanViewerContextMenu';
import { getBase64FromSvgString } from 'helpers/planEditor';
import { ENTITY_TYPE } from 'constants/entityType';
import { findDeviceOnPlanById, loadPlanBackgrounds } from 'actions/plans';

window.PIXI = PIXI;
/*
 * Важно: импорт pixi-layers должен быть после объявления константы PIXI,
 * т.к. внутри pixi-layers происходит работа с ней.
 */
require('pixi-layers');
const display = require('pixi.js-legacy').display;

const Loader = PIXI.Loader.shared;

export const DEFAULT = {
  SAVE_ZOOM_ENABLED: false,
  DEVICE_SIZE: 0.4,
  DEVICE_ICON_WIDTH: 50,
  PRIORITY_MODE: true,
  MINIMAL_ZOOM: 0.2,
  MAXIMAL_ZOOM: 50.0,
  DEVICE_ICON_SIZE: 50,
  ZOOM_STEP: 0.2,
  ZOOM_PADDING_IN_PERCENTS: 5,
  LINE_WIDTH: 1,
  SELECTED_ITEM_LINE_WIDTH: 2,
  SELECTED_ITEM_LINE_COLOR: 0x0260e8,
  UNSELECTED_ITEM_LINE_COLOR: 0x000000,
  WORK_SPACE_LINE_WIDTH: 1,
  POPUP_PADDING: 10,
  CONTEXT_MENU_ID: 'planViewerContextMenu'
};

class PlanRenderer extends React.Component {
  static propTypes = {
    /* Из redux-store */
    plan: PropTypes.object, // Текущий план
    regionsHash: PropTypes.object, // Хеш зон
    devicesHash: PropTypes.object // Хеш устройств
  };

  constructor(props) {
    super(props);
    // Анимируемые в текущий момент сущности в виде Map(), где
    // key = entityId + layoutId, value = { renderer, animatedParams }
    this.animatedEntities = new Map();
    this.devicesLayout = null;
    this.devicesGroup = null;
    this.regionsLayout = null;
    this.regionsGroup = null;
    this.backgroundsLayout = null;
    this.backgroundsGroup = null;
    this.workSpaceLayout = null;
    this.workSpaceGroup = null;
    this.textLayout = null;
    this.textGroup = null;
    this.canvas = null;
    this.pixiApp = null;
    this.mainLayout = null;
    this.viewport = {};
    this.isInit = false;
    this.container = React.createRef();
  }

  createPixiApp = plan => {
    if (plan) {
      const { canvasId } = this.props;
      PIXI.utils.skipHello();
      PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.NEAREST;
      PIXI.settings.MIPMAP_TEXTURES = PIXI.MIPMAP_MODES.ON;
      const container = this.container.current;
      const resolution = window.devicePixelRatio || 1;
      const pixiApp = new PIXI.Application({
        width: container.offsetWidth,
        height: container.offsetHeight,
        backgroundColor: 0xd3d3d3,
        forceFXAA: false,
        antialias: true,
        forceCanvas: true, //Если нужен рендер только canvas
        //NOTE: WEB-GL renderer сильно размывает SVG
        resizeTo: container,
        resolution
      });
      pixiApp.renderer.plugins.interaction.cursorStyles.move = CURSOR_STYLES.MOVE;
      document.body.appendChild(pixiApp.view);
      const viewport = new Viewport({
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight,
        worldWidth: plan.xSize,
        worldHeight: plan.ySize,
        interaction: pixiApp.renderer.plugins.interaction
      });
      pixiApp.stage.addChild(viewport);
      this.zoomOptions = this.calculateZoom(pixiApp, plan);
      viewport
        .drag()
        .pinch()
        .wheel()
        .clampZoom(this.zoomOptions)
        .on('wheel', this.onMouseWheel)
        .on('drag-start', this.onCanvasDragStart)
        .on('drag-end', this.onCanvasDragEnd)
        .on('click', () => this.controlViewPort());

      //NOTE: для работы перетаскивания за пределами области видимости холста
      // https://github.com/davidfig/pixi-viewport/issues/228
      viewport.removeListener('pointerout', viewport.input.up);

      const Group = display.Group;
      const workSpaceGroup = new Group(-2, false);
      const backgroundsGroup = new Group(-1, false);
      const textGroup = new Group(-1, false);
      const regionsGroup = new Group(0, false);
      const devicesGroup = new Group(1, false);

      const Layer = display.Layer;
      pixiApp.stage = new display.Stage();
      viewport.addChild(new Layer(workSpaceGroup));
      viewport.addChild(new Layer(backgroundsGroup));
      viewport.addChild(new Layer(textGroup));
      viewport.addChild(new Layer(regionsGroup));
      viewport.addChild(new Layer(devicesGroup));
      pixiApp.stage.addChild(viewport);
      const mainLayout = new Graphics();
      mainLayout.parentGroup = backgroundsGroup;

      viewport.addChild(mainLayout);
      const canvas = document.getElementById(canvasId);
      canvas.appendChild(pixiApp.view);
      pixiApp.render();
      this.canvas = canvas;
      this.viewport = viewport;
      this.pixiApp = pixiApp;
      this.backgroundsGroup = backgroundsGroup;
      this.regionsGroup = regionsGroup;
      this.devicesGroup = devicesGroup;
      this.textGroup = textGroup;
      this.workSpaceGroup = workSpaceGroup;
      this.mainLayout = mainLayout;
    }
  };

  calculateZoom(canvas, plan) {
    const minScale = this.getZoom(canvas, plan, DEFAULT.MINIMAL_ZOOM);
    const maxScale = this.getZoom(canvas, plan, DEFAULT.MAXIMAL_ZOOM);
    return { minScale, maxScale };
  }

  getZoom(canvas, plan, value) {
    const view = canvas.renderer.view;
    const delta1 = view.width / plan.xSize;
    const delta2 = view.height / plan.ySize;
    const defValue = delta1 < delta2 ? delta1 : delta2;
    return defValue * value;
  }

  onMouseWheel = options => {
    if (this.props.onZoomChanged) {
      this.props.onZoomChanged(this.viewport.scaled, this.zoomOptions);
    }
    this.ignoreZoomForSomeObjects();
  };

  getScaleOptions = () => {
    return this.zoomOptions;
  };

  ignoreZoomForSomeObjects = () => {
    const scale = this.viewport.scaled;

    if (this.devicesLayout && this.devicesLayout.children)
      this.devicesLayout.children.forEach(renderer => {
        renderer.scale.x = renderer.scale.y = 1 / scale;
      });

    if (this.regionsLayout && this.regionsLayout.children)
      this.regionsLayout.children.forEach(renderer => {
        this.setConstLineWidth(renderer, scale, DEFAULT.LINE_WIDTH);
      });

    if (this.workSpaceLayout && this.workSpaceLayout.children)
      this.workSpaceLayout.children.forEach(renderer => {
        this.setConstLineWidth(renderer, scale, DEFAULT.WORK_SPACE_LINE_WIDTH);
      });
  };

  setConstLineWidth(renderer, scale, lineWidth) {
    let GD = renderer.geometry.graphicsData;
    if (GD.length) {
      GD[0].lineStyle.width = lineWidth * (1 / scale);
      renderer.geometry.invalidate();
    }
  }

  onCanvasDragStart = () => {
    this.isDragged = true;
    this.pixiApp.renderer.plugins.interaction.cursorStyles.default = CURSOR_STYLES.MOVE;
    this.pixiApp.renderer.plugins.interaction.setCursorMode(CURSOR_STYLES.MOVE);
  };

  onCanvasDragEnd = () => {
    this.isDragged = false;
    this.pixiApp.renderer.plugins.interaction.cursorStyles.default = CURSOR_STYLES.INHERIT;
    this.pixiApp.renderer.plugins.interaction.setCursorMode(CURSOR_STYLES.INHERIT);
  };

  fitByWindow = () => {
    const { plan } = this.props;
    if (!this.container.current || !plan || !Object.keys(this.viewport).length) return;
    const width = this.container.current.offsetWidth;
    const height = this.container.current.offsetHeight;
    this.viewport.resize(width, height, plan.xSize, plan.ySize);

    const paddingWidth = (plan.xSize / 100) * DEFAULT.ZOOM_PADDING_IN_PERCENTS;
    const paddingHeight = (plan.ySize / 100) * DEFAULT.ZOOM_PADDING_IN_PERCENTS;
    this.viewport.fit(true, plan.xSize + paddingWidth, plan.ySize + paddingHeight);
    this.viewport.moveCenter(plan.xSize / 2, plan.ySize / 2);
    this.ignoreZoomForSomeObjects();
  };

  getZoomConfig = () => {
    if (!this.viewport?.scaled)
      return {
        zoom: 0,
        centerY: 0,
        centerX: 0
      };
    return {
      zoom: +this.viewport.scaled.toFixed(3),
      centerY: +this.viewport.center.y.toFixed(0),
      centerX: +this.viewport.center.x.toFixed(0)
    };
  };

  zoomIn = () => {
    this.viewport.zoomPercent(DEFAULT.ZOOM_STEP, true);
    this.ignoreZoomForSomeObjects();
    if (this.props.onZoomChanged) this.props.onZoomChanged(this.viewport.scaled, this.zoomOptions);
  };

  zoomOut = () => {
    this.viewport.zoomPercent(-DEFAULT.ZOOM_STEP, true);
    this.ignoreZoomForSomeObjects();
    if (this.props.onZoomChanged) this.props.onZoomChanged(this.viewport.scaled, this.zoomOptions);
  };

  /**
   * HELPERS AND UTILS
   */

  isCurrentEntityCheck = (entityType, entityId) => {
    const { currentEntityInfo } = this.props;
    if (!currentEntityInfo) return false;
    return currentEntityInfo.entityType === entityType && currentEntityInfo.entityId === entityId;
  };

  highlightObject = (target, isHighlight) => {
    target.serviceData.isHighlight = isHighlight;
    if (!isHighlight && target.serviceData.isCurrent) return;
    if (target.serviceData.deviceId) this.setObjectSelection(target, isHighlight, true);
    else if (target.serviceData.regionId) this.setObjectSelection(target, isHighlight);
  };

  clearAllSelections = () => {
    if (this.devicesLayout?.children)
      this.devicesLayout.children.forEach(renderer => {
        renderer.serviceData.isCurrent = false;
        this.setObjectSelection(renderer, false, true);
      });
    if (this.regionsLayout !== null) {
      this.regionsLayout.children.forEach(renderer => {
        renderer.serviceData.isCurrent = false;
        this.setObjectSelection(renderer, false);
      });
    }
  };

  setObjectSelection = (renderer, isSelect, isIgnoreZoom = false) => {
    let GD = renderer.geometry.graphicsData;
    const zoom = isIgnoreZoom ? 1 : 1 / this.viewport.scaled;
    if (GD.length) {
      if (isSelect) {
        GD[0].lineStyle.width = DEFAULT.SELECTED_ITEM_LINE_WIDTH * zoom;
        GD[0].lineStyle.color = DEFAULT.SELECTED_ITEM_LINE_COLOR;
      } else {
        GD[0].lineStyle.width = DEFAULT.LINE_WIDTH * zoom;
        GD[0].lineStyle.color = DEFAULT.UNSELECTED_ITEM_LINE_COLOR;
      }
      renderer.geometry.invalidate();
    }
  };

  onRegionClick = options => {
    const { dispatch, regionsHash } = this.props;
    const entityId = options.target.serviceData.entity.id;
    const currentRegion = regionsHash[options.target.serviceData.entity.id] || {};
    this.clearAllSelections();
    this.setObjectSelection(options.target, true, false);
    hideMenu({ id: DEFAULT.CONTEXT_MENU_ID });
    options.target.serviceData.isCurrent = true;
    dispatch(
      updateCurrentEntityInfo({
        entityType: 'REGION',
        entityName: currentRegion.name,
        entityId,
        subsystem: currentRegion.subsystem,
        onGuard: currentRegion.onGuard
      })
    );
  };

  onDeviceClick = options => {
    const { devicesHash, dispatch } = this.props;
    const entityId = options.target.serviceData.entity.id;
    const currentDevice = devicesHash[options.target.serviceData.entity.id] || {};
    this.clearAllSelections();
    this.setObjectSelection(options.target, true, true);
    options.target.serviceData.isCurrent = true;
    dispatch(
      updateCurrentEntityInfo({
        entityType: 'DEVICE',
        entityId,
        entityName: `${currentDevice.name} ${currentDevice.fullAddressPath}`,
        deviceProfileId: currentDevice.deviceProfileId,
        deviceCategory: currentDevice.deviceCategory,
        deviceGroup: currentDevice.deviceGroup,
        statePolling: currentDevice.statePolling
      })
    );
    dispatch(updateCurrentDevice(currentDevice));
  };

  onObjectOver = options => {
    if (this.isDragged) return;
    const target = options.currentTarget;
    if (target && (target.serviceData.deviceId || target.serviceData.regionId)) {
      this.objectHoveredId = target.serviceData.deviceId || target.serviceData.regionId;
    }
    this.highlightObject(options.currentTarget, true);
    this.showPopup(options.data, options.target.serviceData);
  };

  onMouseMove = options => {
    if (this.isDragged) return;
  };

  onObjectOut = options => {
    if (this.isDragged) return;
    this.objectHoveredId = null;
    this.highlightObject(options.currentTarget, false);
    this.hidePopup();
  };

  showPopup = (data, serviceData) => {
    if (!this.popupIntervalId) {
      this.popupIntervalId = setTimeout(() => {
        this.popupItem = document.createElement('span');
        this.popupItem.innerText = this.getPopupText(serviceData);
        this.popupItem.className = styles.popup_block;
        this.popupItem.style.left = `${data.originalEvent.clientX + DEFAULT.POPUP_PADDING}px`;
        this.popupItem.style.top = `${data.originalEvent.clientY + DEFAULT.POPUP_PADDING}px`;
        document.body.appendChild(this.popupItem);
        this.popupIsDrawn = true;
      }, 600);
    }
  };

  hidePopup = () => {
    if (this.popupIntervalId) {
      clearInterval(this.popupIntervalId);
      this.popupIntervalId = null;
      if (this.popupIsDrawn) {
        document.body.removeChild(this.popupItem);
        this.popupIsDrawn = false;
      }
    }
  };

  destroyDeviceTextures = () => {
    const { textures } = this.props;
    if (textures)
      for (let key in textures) {
        if (textures.hasOwnProperty(key) && textures[key]) {
          textures[key].destroy(true);
          delete Loader.resources[textures[key]];
        }
      }
  };

  /* ANIMATIONS */
  updateAnimations = time => {
    if (this.animatedEntities.size && this.animationFrameId) {
      this.recalculateAnimatedEntityParams(time);
      this.animationFrameId = requestAnimationFrame(this.updateAnimations);
    }
  };

  recalculateAnimatedEntityParams = time => {
    const invertedZoom = 1 / this.viewport.scaled;
    for (const { renderer, animationParams } of this.animatedEntities.values()) {
      const value = this.calculateAnimationValue(animationParams, time);

      // eslint-disable-next-line default-case
      switch (animationParams.animationType) {
        case getAnimationTypes().RESIZING.id: {
          renderer.scale.set(value * invertedZoom);
          break;
        }
        case getAnimationTypes().BLINKING.id: {
          renderer.alpha = value;
          break;
        }
        case getAnimationTypes().ROTATING.id: {
          renderer.angle = value;
          break;
        }
      }
    }
  };

  /**
   *  Вычисляет текущее значение анимации относительно текущего времени
   *  При {@code params.direction === true} значение вычисляется линейной функцией
   *  y = k * t + minValue, где k = (maxValue - minValue) / maxTime
   *  а при {@code params.direction === false} с помощью линейной функции
   *  y = -k * t + с, где с = k * maxTime + minValue
   * @param params - параметры анимации
   * @param time - время от начала открытия окна браузера
   * @returns {Number} - результат
   */
  calculateAnimationValue(params, time) {
    const currentAnimTime = time - params.startTime;
    if (currentAnimTime > params.time) {
      params.startTime = time;
      if (params.needReverse) params.direction = params.direction ? 0 : 1;
      return params.direction ? params.min : params.max;
    }

    const k = (params.max - params.min) / params.time;

    if (params.direction) {
      return k * currentAnimTime + params.min;
    } else {
      return -k * currentAnimTime + (k * params.time + params.min);
    }
  }

  startUpdateAnimations = () => {
    if (this.animationFrameId) return;
    this.animationFrameId = requestAnimationFrame(this.updateAnimations);
  };

  removeDeviceAnimation = renderer => {
    const { deviceId, layoutNo } = renderer.serviceData;
    const key = `${deviceId}_${layoutNo}`;
    this.animatedEntities.delete(key);
    renderer.alpha = 1.0;
    renderer.rotation = 0;
    renderer.scale.set(1.0);
    renderer.serviceData.animationType = null;
  };

  addDeviceAnimation = (renderer, media) => {
    const {
      serviceData: { deviceId, layoutNo }
    } = renderer;
    const key = `${deviceId}_${layoutNo}`;
    const data = this.animatedEntities.get(key);
    const animationParams = data ? data.animationParams : this.getAnimationParams(media);
    this.animatedEntities.set(key, { renderer, animationParams });
    this.startUpdateAnimations();
  };

  findDeviceStartAnim = deviceId => {
    const animationParams = this.getFindDeviceAnimParams();
    for (const renderer of this.devicesLayout.children) {
      if (renderer.serviceData.deviceId !== deviceId) continue;
      const key = `${deviceId}_${renderer.serviceData.layoutNo}_find_device`;

      this.animatedEntities.set(key, { renderer, animationParams });
    }
    this.findDeviceAnimationFrameId = requestAnimationFrame(this.updateFindDeviceAnimations);
  };

  updateFindDeviceAnimations = time => {
    if (this.animatedEntities.size && this.findDeviceAnimationFrameId) {
      this.recalculateAnimatedEntityParams(time);
      this.findDeviceAnimationFrameId = requestAnimationFrame(this.updateFindDeviceAnimations);
    }
  };

  getFindDeviceAnimParams = () => {
    const { id: animationType, min, max, needReverse } = getAnimationTypes().RESIZING;
    return {
      animationType,
      min,
      max,
      needReverse,
      direction: 1,
      startTime: 0,
      time: ANIMATION_SPEED.MIN
    };
  };

  getAnimationParams = media => {
    const needReverse = getAnimationTypes()[media.animationType].needReverse;
    const time = media.animationSpeed ? media.animationSpeed : ANIMATION_SPEED.MIN;
    return {
      animationType: media.animationType,
      min: getAnimationTypes()[media.animationType].min, // Максимальное значение
      max: getAnimationTypes()[media.animationType].max, // Минимальное значение
      needReverse, // Нужно ли анимацию отражать зеркально
      direction: 1, // Направление анимации (1 - прямое, 0 - обратное)
      startTime: 0, // Время старта анимации (ms) (сервисное свойство)
      time: needReverse ? time / 2 : time // Время выполнения анимации (ms)
    };
  };

  destroyDeviceAnimations = () => {
    if (this.animationFrameId) cancelAnimationFrame(this.animationFrameId);
    this.animationFrameId = null;
    this.animatedEntities.clear();
  };

  destroyDeviceAnimationsFindDevice = deviceId => {
    if (this.findDeviceAnimationFrameId) cancelAnimationFrame(this.findDeviceAnimationFrameId);
    this.findDeviceAnimationFrameId = null;
    for (const renderer of this.devicesLayout.children) {
      if (renderer.serviceData.deviceId !== deviceId) continue;
      const key = `${deviceId}_${renderer.serviceData.layoutNo}_find_device`;
      this.animatedEntities.delete(key);
    }
    this.ignoreZoomForSomeObjects();
  };

  /**
   * DRAW BACKGROUNDS
   */

  drawBackground = (plan, background) => {
    const resource = Loader.resources[background.crc];
    const texture = resource ? resource.texture : null;
    if (!texture || !texture.valid) {
      this.createImgLoadingSkeleton(background);
      return;
    }
    const picture = new Sprite(texture);
    this.setBackgroundSize(plan, picture, background);
    this.backgroundsLayout.addChild(picture);
  };

  setBackgroundSize(plan, sprite, background) {
    const coords = background.topLeftPoint;
    // Если размеры изображения не заданы, то масштабируем под размер плана
    // Для старых типов фоновых рисунков
    if (background.width === 0 || background.height === 0) {
      const texture = sprite.texture.baseTexture;
      const scaleX = plan.xSize / texture.width;
      const scaleY = plan.ySize / texture.height;
      sprite.height = texture.height;
      sprite.width = texture.width;
      const scale = scaleX < scaleY ? scaleX : scaleY;
      sprite.setTransform(coords.x, coords.y, scale, scale);
    } else {
      sprite.setTransform(coords.x, coords.y, background.scaleX, background.scaleY);
    }
  }

  createImgLoadingSkeleton(background) {
    const layout = this.backgroundsLayout;
    const renderer = this.getLoadingIconBorder(background);
    layout.addChild(renderer);
  }

  getLoadingIconBorder({ topLeftPoint, height, width, scaleX, scaleY }) {
    const renderer = new Graphics();
    renderer
      .beginFill(0xffffff)
      .lineStyle(DEFAULT.WORK_SPACE_LINE_WIDTH, 0x000000, 0.4)
      .drawRect(topLeftPoint.x, topLeftPoint.y, width * scaleX, height * scaleY)
      .endFill();
    return renderer;
  }

  drawWorkSpace = plan => {
    const workSpaceLayout = new Container();
    workSpaceLayout.parentGroup = this.workSpaceGroup;
    if (this.mainLayout) this.mainLayout.addChild(workSpaceLayout);

    const renderer = new Graphics();
    if (!plan) return;
    renderer
      .beginFill(0xffffff)
      .lineStyle(DEFAULT.WORK_SPACE_LINE_WIDTH, 0x000000, 0.4)
      .drawRect(0, 0, plan.xSize, plan.ySize)
      .endFill();
    workSpaceLayout.addChild(renderer);
    this.workSpaceLayout = workSpaceLayout;
  };

  addBackgroundsToLoader = (plan, texturesMap) => {
    try {
      for (let background of plan.backgrounds) {
        const texture = texturesMap.get(background.id);
        background.crc = texture.crc + '';
        this.addBackgroundToLoader(texture);
      }
      if (this.isResourcesLoding) return;
      this.isResourcesLoding = true;
      Loader.onLoad.add((loader, resource) => {
        if (plan.id === this.props.plan.id) this.drawBackgrounds(plan);
      });
      Loader.load((loader, resources) => {
        this.isResourcesLoding = false;
        if (plan.id === this.props.plan.id) {
          this.clearBackgrounds();
          this.drawBackgrounds(this.props.plan);
        }
      });
    } catch (e) {
      message('error', i18next.t('errors.loadBackground'));
    }
  };

  drawBackgrounds = (plan = this.props.plan) => {
    if (plan && plan.backgrounds && plan.backgrounds.length) {
      this.createBackgroundLayoutIfNeed();
      plan.backgrounds.forEach(background => this.drawBackground(plan, background));
    }
  };

  createBackgroundLayoutIfNeed() {
    if (this.backgroundsLayout) return;
    const newBackgroundsLayout = new Container();
    newBackgroundsLayout.parentGroup = this.backgroundsGroup;
    this.mainLayout.addChild(newBackgroundsLayout);
    this.backgroundsLayout = newBackgroundsLayout;
  }

  addBackgroundToLoader = texture => {
    const resourceId = texture.crc + '';
    const prevResource = Loader.resources[resourceId];
    if (prevResource && !prevResource.error) {
      return;
    } else if (prevResource && prevResource.error) {
      Loader.resources[resourceId] = undefined;
    }

    let content;
    let dataFormat;

    if (texture.dataFormat === 'raw' && texture.type === 'image/svg+xml') {
      content = getBase64FromSvgString(texture.content);
      dataFormat = 'base64';
    } else {
      content = texture.content;
      dataFormat = texture.dataFormat;
    }

    const data = `data:${texture.type};${dataFormat},${content}`;
    Loader.add(resourceId, data);
  };

  clearBackgrounds = () => {
    if (this.backgroundsLayout) {
      this.backgroundsLayout.destroy({ children: true });
      this.mainLayout.removeChild(this.backgroundsLayout);
      this.backgroundsLayout = null;
    }
  };

  clearWorkSpace = () => {
    if (this.workSpaceLayout) {
      this.workSpaceLayout.destroy({ children: true, texture: true, baseTexture: true });
      this.mainLayout.removeChild(this.workSpaceLayout);
      this.workSpaceLayout = null;
    }
  };

  drawRegion = renderer => {
    const { shape, stateCategoryView, isHighlight, isCurrent, layout } = renderer.serviceData;
    const color = parseInt(stateCategoryView.color.substring(1), 16);
    const fontColor = parseInt(stateCategoryView.fontColor.substring(1), 16);
    const invertedZoom = 1 / this.viewport.scaled;
    const lineWidth =
      (isHighlight || isCurrent ? DEFAULT.SELECTED_ITEM_LINE_WIDTH : DEFAULT.LINE_WIDTH) *
      invertedZoom;
    const lineColor = isHighlight || isCurrent ? DEFAULT.SELECTED_ITEM_LINE_COLOR : fontColor;
    renderer
      .beginFill(color, stateCategoryView.regionFillAlpha)
      .lineStyle(lineWidth, lineColor, 1, 0.5, true)
      .drawShape(shape)
      .endFill();

    if (!layout.bounds) layout.bounds = renderer.getBounds(true);
    const x = layout.bounds.x;
    const y = layout.bounds.y;
    renderer.setTransform(
      x,
      y,
      layout.scaleX,
      layout.scaleY,
      layout.angle,
      layout.skewX,
      layout.skewY,
      x,
      y
    );
  };

  getRegionShape = layout => {
    const x = layout.points[0] ? layout.points[0].x : 0;
    const y = layout.points[0] ? layout.points[0].y : 0;
    return new Polygon([
      ...layout.points.map(point => new Point(point.x, point.y)),
      new Point(x, y)
    ]);
  };

  drawRegions = props => {
    const { plan, regionsHash, currentUser } = props;
    const regions = Object.values(regionsHash);
    if (plan && regions.length) {
      const newRegionsLayout = new Container();
      newRegionsLayout.parentGroup = this.regionsGroup;
      regions.forEach(region => {
        if (availableByFilterTags(currentUser, region))
          region.planLayouts
            .filter(planLayout => planLayout.planId === plan.id)
            .forEach(layout => {
              const renderer = new Graphics();
              renderer.serviceData = {
                shape: this.getRegionShape(layout),
                layout,
                regionId: region.id,
                stateCategoryView: region.generalStateCategoryView,
                isHighlight: false,
                isCurrent: this.isCurrentEntityCheck('REGION', region.id),
                entity: region
              };
              renderer.interactive = true;
              renderer.cursor = 'pointer';
              renderer
                .on('pointerdown', this.onRegionClick)
                .on('pointerover', this.onObjectOver)
                .on('pointerout', this.onObjectOut)
                .on('pointermove', this.onMouseMove);
              this.drawRegion(renderer);
              newRegionsLayout.addChild(renderer);
            });
      });
      this.mainLayout.addChild(newRegionsLayout);
      this.regionsLayout = newRegionsLayout;
    }
  };

  updateRegionViews() {
    const { regionsHash } = this.props;
    if (this.regionsLayout) {
      this.regionsLayout.children.forEach(renderer => {
        const { regionId, stateCategoryView, isHighlight } = renderer.serviceData;
        const updatedRegion = regionsHash[regionId];
        const isCurrentEntity = this.isCurrentEntityCheck('REGION', regionId);
        if (this.needViewUpdate(renderer, updatedRegion, isCurrentEntity, stateCategoryView)) {
          renderer.serviceData.isCurrent = isCurrentEntity;
          renderer.serviceData.stateCategoryView = updatedRegion.generalStateCategoryView;
          renderer.clear();
          this.drawRegion(renderer);

          if (isHighlight && this.popupIntervalId) {
            clearInterval(this.popupIntervalId);
          }
        }
      });
    }
  }

  needViewUpdate(renderer, updatedObj, isCurrent, oldStateCategoryView) {
    return (
      (updatedObj && !isEqual(oldStateCategoryView, updatedObj.generalStateCategoryView)) ||
      (isCurrent && !renderer.serviceData.isCurrent) ||
      (!isCurrent && renderer.serviceData.isCurrent)
    );
  }

  clearRegions = () => {
    if (this.regionsLayout) {
      this.regionsLayout.destroy({ children: true });
      this.mainLayout.removeChild(this.regionsLayout);
      this.regionsLayout = null;
    }
  };

  /**
   * DRAW DEVICES
   */

  getDevicePicture = (device, layout) => {
    const { generalStateCategoryView } = device;
    const { deviceShapesHash, svgsHash } = this.props;
    const deviceShapeLibraryId =
      device.deviceShapeLibraryId || `${device.deviceProfileId}_default_shlib`;
    let textureMedia = null;
    const deviceShapeLibrary =
      deviceShapesHash[deviceShapeLibraryId] ||
      deviceShapesHash[`${device.deviceProfileId}_default_shlib`];
    const deviceShape = deviceShapeLibrary
      ? deviceShapeLibrary[generalStateCategoryView.id] || deviceShapeLibrary['']
      : null;
    if (deviceShape && deviceShape.builtinMediaId && svgsHash[deviceShape.builtinMediaId]) {
      textureMedia = svgsHash[deviceShape.builtinMediaId];
      const { animationSpeed, animationType, shapeBorder } = deviceShape;
      textureMedia = { ...textureMedia, animationSpeed, animationType, shapeBorder };
    } else if (deviceShape && deviceShape.svgContent) {
      textureMedia = deviceShape;
    }
    //если у устройства своя иконка
    if (textureMedia) {
      const { textures, deviceSize } = this.props;
      let texture = null;
      if (textures[textureMedia.id]) {
        if (textures[textureMedia.id] && textures[textureMedia.id].valid) {
          texture = textures[textureMedia.id];
        }
      }
      let content = '';
      if (!texture) {
        if (textureMedia.svgContent) content = textureMedia.svgContent;
        else if (textureMedia.mediaType === 'SVG_CONTENT') content = textureMedia.content;

        if (content.length) {
          const options = {
            scale: deviceSize,
            autoLoad: true
          };
          const source = 'data:image/svg+xml;base64,' + getBase64FromSvgString(content);
          const resource = new PIXI.SVGResource(source, options);
          texture = new Texture(new PIXI.BaseTexture(resource));
        }
      }
      if (texture) {
        const sprite = new Sprite(texture);
        const spritePosition = -(DEFAULT.DEVICE_ICON_WIDTH * deviceSize) / 2;
        sprite.position.set(spritePosition, spritePosition);
        return { sprite, media: textureMedia };
      }
    }
    /* Если с текстурой не получилось - нарисуем первые 2 буквы из названия  */
    const { deviceSize } = this.props;
    const text = new PIXI.Text(device.name.substring(0, 2), {
      fontFamily: 'Helvetica, Arial, sans-serif',
      fontSize: 24 * deviceSize,
      fill: 0xffffff
    });
    const textPosition = -(50 * deviceSize) / 2 + 12 * deviceSize;
    text.position.set(textPosition - 2, textPosition);
    return { sprite: text };
  };

  drawDeviceIconBorder = renderer => {
    const { deviceSize } = this.props;
    const { stateCategoryView, shapeBorder } = renderer.serviceData;
    const color = parseInt(stateCategoryView.color.substring(1), 16);
    const lineColor = parseInt(stateCategoryView.fontColor.substring(1), 16);
    const side = DEFAULT.DEVICE_ICON_WIDTH;
    if (shapeBorder) {
      renderer
        .beginFill(color)
        .lineStyle(DEFAULT.LINE_WIDTH, lineColor, side)
        .drawRect(
          -((side * deviceSize) / 2),
          -((side * deviceSize) / 2),
          side * deviceSize,
          side * deviceSize
        )
        .endFill();
    }
    renderer.children.forEach(
      sprite => (sprite.tint = `0x${stateCategoryView.fontColor.substring(1)}`)
    );
  };

  updateDeviceTexture = (renderer, updatedDevice, layout) => {
    renderer.removeChildAt(0);
    const { sprite, media } = this.getDevicePicture(updatedDevice, layout);
    renderer.addChild(sprite);
    if (media && media.animationType && media.animationType !== getAnimationTypes().NONE.id) {
      renderer.serviceData['animationType'] = media.animationType;
      this.addDeviceAnimation(renderer, media);
    } else this.removeDeviceAnimation(renderer);
  };

  drawDevices = props => {
    const { plan, devicesHash, deviceShapesHash, svgsHash } = props;
    const devices = Object.values(devicesHash);
    if (plan && devices.length && !isEmpty(deviceShapesHash) && !isEmpty(svgsHash)) {
      const { currentUser } = props;
      const newDevicesLayout = new Container();
      newDevicesLayout.parentGroup = this.devicesGroup;
      devices.forEach(device => {
        if (availableByFilterTags(currentUser, device))
          if (device.planLayouts.length) {
            device.planLayouts
              .filter(planLayout => planLayout.planId === plan.id)
              .forEach((layout, layoutNo) => {
                const renderer = new Graphics();
                const { sprite, media } = this.getDevicePicture(device, layout);
                renderer.x = layout.coordinatePoint.x;
                renderer.y = layout.coordinatePoint.y;
                renderer.serviceData = {
                  deviceId: device.id,
                  shapeBorder: media.shapeBorder,
                  layoutNo, // Нужен для уникальности отображения устройства
                  stateCategoryView: device.generalStateCategoryView,
                  layout,
                  isHighlight: false,
                  isCurrent: this.isCurrentEntityCheck('DEVICE', device.id),
                  entity: device
                };
                renderer.interactive = true;
                renderer.cursor = 'pointer';
                renderer
                  .on('pointerdown', this.onDeviceClick)
                  .on('pointerover', this.onObjectOver)
                  .on('pointerout', this.onObjectOut)
                  .on('pointermove', this.onMouseMove);
                renderer.addChild(sprite);
                this.drawDeviceIconBorder(renderer);
                if (media && media.animationType && media.animationType !== 'NONE') {
                  renderer.serviceData['animationType'] = media.animationType;
                  this.addDeviceAnimation(renderer, media);
                }
                newDevicesLayout.addChild(renderer);
              });
          }
      });
      this.mainLayout.addChild(newDevicesLayout);
      this.devicesLayout = newDevicesLayout;
    }
  };

  updateDeviceViews = () => {
    const { devicesHash } = this.props;
    if (!this.devicesLayout || !this.devicesLayout.children) return;
    this.devicesLayout.children.forEach(renderer => {
      const { deviceId, layout, stateCategoryView, isHighlight } = renderer.serviceData;
      const updatedDevice = devicesHash[deviceId];
      const isCurrentEntity = this.isCurrentEntityCheck(ENTITY_TYPE.DEVICE, deviceId);
      if (this.needViewUpdate(renderer, updatedDevice, isCurrentEntity, stateCategoryView)) {
        renderer.serviceData.isCurrent = isCurrentEntity;
        renderer.serviceData.stateCategoryView = updatedDevice.generalStateCategoryView;
        this.updateDeviceTexture(renderer, updatedDevice, layout);
        this.drawDeviceIconBorder(renderer);
        if (isHighlight && this.popupIntervalId) {
          clearInterval(this.popupIntervalId);
        }
      }
    });
  };

  clearDevices = () => {
    if (this.devicesLayout) {
      this.devicesLayout.destroy({ children: true });
      this.mainLayout.removeChild(this.devicesLayout);
      this.devicesLayout = null;
    }
  };

  /**
   *  DRAW POPUP
   */

  getPopupText = data => {
    if (data.regionId) {
      const { regionsHash } = this.props;
      const currentRegion = regionsHash[data.regionId];
      return `${currentRegion.index}.${currentRegion.name}\n${currentRegion.generalStateCategoryView.name}`;
    } else if (data.deviceId) {
      const { devicesHash } = this.props;
      const currentDevice = devicesHash[data.deviceId];
      return `${currentDevice.middleAddressPath} - ${currentDevice.name}\n${
        currentDevice.generalStateCategoryView.name
      }${currentDevice.monitorableValueViews.map(value => {
        return `\n${value.name} : ${value.value} ${value.unit}`;
      })}${currentDevice.activeStateViews.length ? ':' : ''} ${currentDevice.activeStateViews
        .map(state => `\n-${state.name}`)
        .join(' ')}
         `;
    }
  };

  /**
   *  DRAW TEXT
   */

  drawText = plan => {
    if (!plan?.texts?.length) return;
    const newTextLayout = new Container();
    newTextLayout.parentGroup = this.textGroup;
    const { texts } = plan;
    const coordOffset = 0.5;
    texts.forEach(textParams => {
      const {
        fontFamily,
        fontSize,
        fontStyle,
        fontWeight,
        textAlign,
        color,
        angle,
        x,
        y,
        scaleX,
        scaleY,
        text,
        width
      } = textParams;
      const style = new PIXI.TextStyle({
        fontFamily,
        fontSize,
        fontStyle,
        fontWeight,
        align: textAlign,
        fill: color,
        //разрыв слова по ширине блока
        breakWords: true,
        //перенос слов по ширине блока
        wordWrap: true,
        wordWrapWidth: width,
        //интервал между строками
        leading: 7
      });
      const currentAngle = angle ? (angle * Math.PI) / 180 : 0;
      const TextElement = new Text(text, style);
      TextElement.setTransform(x + coordOffset, y + coordOffset, scaleX, scaleY, currentAngle);
      //чёткость/резкость текста
      TextElement.resolution = 10;
      newTextLayout.addChild(TextElement);
    });
    this.mainLayout.addChild(newTextLayout);
    this.textLayout = newTextLayout;
  };

  clearText = () => {
    if (this.textLayout) {
      this.textLayout.destroy({ children: true });
      this.mainLayout.removeChild(this.textLayout);
      this.textLayout = null;
    }
  };

  componentDidMount = () => {
    const { plan } = this.props;
    this.createPixiApp(plan);
    this.initLayouts(this.props);
  };

  changeSize = () => {
    if (!this.container.current) return;
    const width = this.container.current.offsetWidth;
    const height = this.container.current.offsetHeight;
    const plan = this.props.plan;
    this.viewport.resize(width, height, plan.xSize, plan.ySize);
    this.pixiApp.renderer.resize(width, height);
  };

  initLayouts(props) {
    const { planConfigs, plan, deviceProfileViewsHash, deviceShapesHash, projectId } = props;
    this.loadBackgrounds(projectId, plan);
    if (isEmpty(deviceShapesHash) || isEmpty(deviceProfileViewsHash)) return;
    this.drawWorkSpace(plan);
    this.drawText(plan);
    this.drawRegions(this.props);
    this.drawDevices(this.props);
    this.initZoom(plan, planConfigs);
    this.isInit = true;
  }

  initZoom = (plan, configs) => {
    if (configs && configs.zoom && !this.props.isAllwaysFitByWindow) {
      this.viewport.resize(
        this.container.current.offsetWidth,
        this.container.current.offsetHeight,
        plan.xSize,
        plan.ySize
      );
      this.viewport.setZoom(configs.zoom);
      const point = new PIXI.Point(configs.centerX, configs.centerY);
      this.viewport.moveCenter(point);
    } else {
      this.fitByWindow();
    }
    this.ignoreZoomForSomeObjects();
    if (this.props.onZoomChanged) {
      this.props.onZoomChanged(this.viewport.scaled, this.zoomOptions);
    }
  };

  loadBackgrounds(projectId, plan) {
    if (this.isBackgroundsLoading) return;
    this.isBackgroundsLoading = true;
    const callback = (result, error) => {
      this.isBackgroundsLoading = false;
      if (error) message.error(i18next.t('errors.loadBackground'));
      else {
        const texturesMap = new Map();
        result.forEach(b => texturesMap.set(b.id, b));
        this.addBackgroundsToLoader(plan, texturesMap);
      }
    };

    this.clearBackgrounds();
    this.drawBackgrounds();
    if (this.wereResourcesLoad()) {
      this.isBackgroundsLoading = false;
    } else {
      this.props.dispatch(loadPlanBackgrounds(projectId, plan.id, callback));
    }
  }

  wereResourcesLoad = () => {
    const plan = this.props.plan;
    const backgrounds = plan?.backgrounds ? plan.backgrounds : [];
    for (let bg of backgrounds) {
      if (!bg.crc || !Loader.resources[bg.crc]) return false;
    }
    return true;
  };

  shouldComponentUpdate(nextProps, nextState) {
    const { deviceShapesHash, deviceProfileViewsHash } = nextProps;
    if (isEmpty(deviceShapesHash) || isEmpty(deviceProfileViewsHash)) return false;
    return true;
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { plan, planId, planConfigs, projectId, findDeviceOnPlan, dispatch } = this.props;

    if (planId !== prevProps.planId || !this.isInit) {
      this.planDidChange(projectId, plan, planConfigs);
      if (!this.isInit) this.isInit = true;
      this.controlViewPort(true);
    } else this.updatePlanObjectsIfNeed(prevProps);
    if (findDeviceOnPlan !== null && prevProps.findDeviceOnPlan !== findDeviceOnPlan) {
      this.findDeviceStartAnim(findDeviceOnPlan);
      setTimeout(() => {
        this.destroyDeviceAnimationsFindDevice(findDeviceOnPlan);
        dispatch(findDeviceOnPlanById(null));
      }, 5000);
    }
  };

  planDidChange(projectId, plan, planConfigs) {
    this.viewport.resize(
      this.container.current.offsetWidth,
      this.container.current.offsetHeight,
      plan.xSize,
      plan.ySize
    );
    this.zoomOptions = this.calculateZoom(this.pixiApp, plan);
    this.viewport.clampZoom(this.zoomOptions);
    this.destroyDeviceAnimations();
    this.clearText();
    this.clearDevices();
    this.clearRegions();
    this.clearBackgrounds();
    this.clearWorkSpace();
    this.drawWorkSpace(plan);
    this.loadBackgrounds(projectId, plan);
    this.drawRegions(this.props);
    this.drawDevices(this.props);
    this.drawText(plan);
    this.initZoom(plan, planConfigs);
  }

  updatePlanObjectsIfNeed(prevProps) {
    const { deviceSize } = this.props;
    if (this.props.regionsHash !== prevProps.regionsHash) {
      this.updateRegionViews();
      this.ignoreZoomForSomeObjects();
    }
    if (this.props.devicesHash !== prevProps.devicesHash) {
      this.updateDeviceViews();
      this.ignoreZoomForSomeObjects();
    }
    if (deviceSize !== prevProps.deviceSize) this.redrawDevices();
  }

  redrawDevices() {
    this.destroyDeviceAnimations();
    this.clearDevices();
    this.destroyDeviceTextures();
    this.drawDevices(this.props);
    this.ignoreZoomForSomeObjects();
  }

  componentWillUnmount = () => {
    const { dispatch } = this.props;
    this.destroyDeviceAnimations();
    this.clearBackgrounds();
    this.clearRegions();
    this.clearDevices();
    this.clearText();
    Loader.destroy();
    dispatch(clearTextures());
    if (this.mainLayout?.destroy) this.mainLayout.destroy({ children: true });
    if (this.viewport?.destroy) this.viewport.destroy();
    if (this.pixiApp?.destroy) this.pixiApp.destroy();
  };

  controlContextMenu = () => {
    const { currentEntityInfo, dispatch } = this.props;
    if (currentEntityInfo && this.objectHoveredId === currentEntityInfo.entityId) return;
    dispatch(updateCurrentEntityInfo(null));
    this.clearAllSelections();
  };

  controlViewPort = pause => {
    if (Object.keys(this.viewport).length) {
      if (pause) {
        this.viewport.plugins.pause('wheel');
        if (!this.isDragged) this.viewport.plugins.pause('drag');
      } else {
        this.viewport.plugins.resume('wheel');
        if (!this.isDragged) this.viewport.plugins.resume('drag');
      }
    }
  };

  render() {
    const { canvasId, projectId } = this.props;
    return (
      <div
        className={styles.plan_viewer}
        ref={this.container}
        onMouseOver={() => this.controlViewPort()}
        onMouseOut={() => this.controlViewPort(true)}
      >
        <PlanViewerContextMenu
          menuName={DEFAULT.CONTEXT_MENU_ID}
          projectId={projectId ? projectId : ''}
          deletePlanObject={this.deletePlanObject}
          openChangeRegionModal={this.openUpdateRegionWindow}
          openEditRegionModal={this.openEditRegionModal}
          editView={this.editView}
          showInDevicesTree={this.showDeviceInTree}
        />
        <ContextMenuTrigger
          holdToDisplay={-1}
          collect={props => props}
          id={DEFAULT.CONTEXT_MENU_ID}
        >
          <div
            onClick={this.controlContextMenu}
            onContextMenu={this.controlContextMenu}
            id={canvasId}
          />
        </ContextMenuTrigger>
      </div>
    );
  }
}

export const getDeviceShapesGroupedByLibraryAndStateCategory = createSelector(
  ({ deviceShapeLibrary: { deviceShapesHash } }) => deviceShapesHash,
  deviceShapesHash =>
    Object.values(deviceShapesHash).reduce((groupedShapes, shape) => {
      if (!groupedShapes[shape.deviceShapeLibraryId]) {
        groupedShapes[shape.deviceShapeLibraryId] = {};
      }
      groupedShapes[shape.deviceShapeLibraryId][shape.stateCategoryId] = shape;
      return groupedShapes;
    }, {})
);

const getCurrentEntityInfo = state => state.widgets.currentEntityInfo;

const mapStateToProps = state => {
  const planId = state.activeProject.currentPlanId;
  const plansHash = getActivePlansHash(state);
  return {
    plan: plansHash[planId],
    planId,
    deviceProfileViewsHash: getDeviceProfileViewsHash(state),
    deviceShapesHash: getDeviceShapesGroupedByLibraryAndStateCategory(state),
    textures: getTextures(state),
    svgsHash: state.medias.svgsHash,
    regionsHash: getActiveRegionsHash(state),
    devicesHash: getActiveDevicesHash(state) || {},
    currentUser: getCurrentUser(state),
    currentEntityInfo: getCurrentEntityInfo(state),
    findDeviceOnPlan: state.activeProject.findDeviceOnPlan
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  PlanRenderer
);
