import React, { Component } from 'react';
import styled from 'styled-components';

import { ContextMenu } from 'react-contextmenu';
export { hideMenu } from 'react-contextmenu';

const PopupWrapper = styled.div`
  nav {
    z-index: 101;
    background: white;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  }
  .disabled,
  .disabled > * {
    cursor: not-allowed !important;
    color: #ccc !important;
  }
`;

export default class Popup extends Component {
  render() {
    const { id, ...restProps } = this.props;

    return (
      <PopupWrapper>
        <ContextMenu id={id} {...restProps}>
          {this.props.children}
        </ContextMenu>
      </PopupWrapper>
    );
  }
}
