import {
  SELECT_REGIONS,
  REGION_CREATE,
  REGION_NEW_DEVICE,
  REGION_DELETE_DEVICE,
  REGION_DELETE,
  REGION_UPDATE,
  REGIONS_LOAD_ACTIVE,
  REGION_CHANGE_GUARD_STATUS,
  REGION_CONSTANTS_LOAD,
  REGION_UPDATE_PLAN_LAYOUTS,
  REGIONS_UPDATE_PLAN_LAYOUTS,
  REGION_NEW_DEVICE_LIST,
  REGION_DELETE_DEVICE_LIST,
  REGIONS_DELETE_ALL_DEVICE_LISTS,
  REGION_CREATE_AND_CONNECT_DEVICE
} from 'constants/actionTypes';

import { createAction } from 'redux-actions';

export const selectRegions = createAction(SELECT_REGIONS, regionIds => regionIds);

export const createRegion = createAction(REGION_CREATE, (newRegion, projectId) => ({
  newRegion,
  projectId
}));
export const createRegions = createAction(REGION_CREATE, (newRegion, projectId, count) => ({
  newRegion,
  projectId,
  count
}));
export const newDeviceRegion = createAction(REGION_NEW_DEVICE, connection => connection);
export const deleteRegion = createAction(REGION_DELETE, (currentProjectId, regionId) => ({
  projectId: currentProjectId,
  regionId: regionId
}));
export const deleteRegions = createAction(REGION_DELETE, (projectId, regionIds) => ({
  projectId,
  regionIds
}));

export const deleteDeviceRegion = createAction(REGION_DELETE_DEVICE, connection => connection);
export const updateRegion = createAction(REGION_UPDATE, updatedRegion => updatedRegion);

export const loadActiveRegions = createAction(REGIONS_LOAD_ACTIVE, projectId => ({ projectId }));
export const changeRegionGuardStatus = createAction(
  REGION_CHANGE_GUARD_STATUS,
  (projectId, regionId, onGuard) => ({ projectId, regionId, onGuard })
);
export const loadRegionConstants = createAction(REGION_CONSTANTS_LOAD);
export const updateRegionPlanLayouts = createAction(
  REGION_UPDATE_PLAN_LAYOUTS,
  (projectId, regionId, planLayouts) => ({ projectId, regionId, planLayouts })
);
export const updateRegionsPlanLayouts = createAction(
  REGIONS_UPDATE_PLAN_LAYOUTS,
  (regionsProperties, projectId) => ({ regionsProperties, projectId })
);
export const newDeviceListRegion = createAction(
  REGION_NEW_DEVICE_LIST,
  (projectId, regionId, deviceIds) => ({
    projectId,
    regionId,
    deviceIds
  })
);
export const deleteDeviceListFromRegion = createAction(
  REGION_DELETE_DEVICE_LIST,
  (projectId, regionId, deviceIds = []) => ({ projectId, regionId, deviceIds })
);

export const deleteAllDeviceListFromRegions = createAction(
  REGIONS_DELETE_ALL_DEVICE_LISTS,
  (projectId, regionIds) => ({ projectId, regionIds })
);

export const createRegionAndConnectDevice = createAction(
  REGION_CREATE_AND_CONNECT_DEVICE,
  (projectId, region, deviceId) => ({ projectId, region, deviceId })
);
