import React from 'react';

import Toolbar from 'components/Toolbar';
import styles from 'containers/Menus/ZoomToolbar.module.css';
import { KeyCodes } from 'constants/keyboard';
import i18next from 'i18next';

export const TOOLBAR_ITEMS = {
  ZOOM_IN_BUTTON: 'zoomIn',
  ZOOM_OUT_BUTTON: 'zoomOut',
  FIT_TO_WINDOW_BUTTON: 'fitToWindow',
  ALWAYS_FIT_BY_WINDOW: 'isAlwaysFitByWindow'
};

const getTools = () => [
  {
    key: TOOLBAR_ITEMS.ZOOM_IN_BUTTON,
    title: i18next.t('plans.tools.zoomIn'),
    icon: 'zoomIn',
    customIcon: true,
    hotKeys: [KeyCodes.CTRL, KeyCodes.PLUS],
    hotKeysAlt: [KeyCodes.CTRL, KeyCodes.NUM_KEYBOARD_PLUS],
    selectable: false,
    type: 'button'
  },
  {
    key: TOOLBAR_ITEMS.ZOOM_OUT_BUTTON,
    title: i18next.t('plans.tools.zoomOut'),
    icon: 'zoomOut',
    customIcon: true,
    hotKeys: [KeyCodes.CTRL, KeyCodes.MINUS],
    hotKeysAlt: [KeyCodes.CTRL, KeyCodes.NUM_KEYBOARD_MINUS],
    selectable: false,
    type: 'button'
  },
  {
    key: TOOLBAR_ITEMS.FIT_TO_WINDOW_BUTTON,
    title: i18next.t('plans.tools.fitToWindow'),
    icon: 'scan',
    customIcon: false,
    selectable: false,
    type: 'button'
  },
  {
    key: TOOLBAR_ITEMS.ALWAYS_FIT_BY_WINDOW,
    title: i18next.t('plans.tools.fitToWindowWhenPlanOpen'),
    altTitle: i18next.t('plans.tools.fitToWindowWhenPlanOpen'),
    icon: 'pushpin',
    customIcon: false,
    selectable: false,
    type: 'flag'
  }
];
const ZoomToolbar = props => (
  <div className={styles.zoom_toolbar}>
    <Toolbar name={'ZoomToolbar'} tools={getTools()} {...props} />
  </div>
);

export default ZoomToolbar;
