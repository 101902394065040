import React from 'react';
import { loadHandleModeScenarios } from 'actions/CPIU';
import { getActiveProjectId } from 'helpers/activeProject';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CPIUActiveScenarioController from 'loaders/CPIU/CPIUActiveScenarioController';
import CPIULoader from 'loaders/CPIU/CPIULoader';
import useEntitiesByPlanId from 'containers/Widgets/CPIU/hooks/useEntitiesByPlanId';
import useScenarioLogicEntity from 'containers/Widgets/CPIU/hooks/useScenarioLogicEntity';

const ScenariosWidgetsLoaderLogic = () => {
  const activeProjectId = useSelector(state => getActiveProjectId(state)) as string | null;
  const dispatch = useDispatch();

  //запрашиваем сценарии с ручным управлением
  useEffect(() => {
    if (!activeProjectId) return;
    dispatch(loadHandleModeScenarios(activeProjectId));
  }, [activeProjectId, dispatch]);

  //сохраняем в стейт мапы устройств и зон, используемые в логике сценариев
  useScenarioLogicEntity(activeProjectId);

  //создаём мапу id устройств и зон по id планов
  useEntitiesByPlanId(activeProjectId);

  return null;
};

const ScenariosWidgetsLoaderMemo = React.memo(ScenariosWidgetsLoaderLogic);

/**
 * Подготавливает/запрашивает данные необходимые для
 * отображения виджетов связанных со сценариями в проекте
 */
const ScenariosWidgetsLoader = () => {
  const isCPIUDeviceInProject = useSelector(
    (state: any) => !!state.activeProject.CPIU.device
  ) as boolean;
  return (
    <>
      <ScenariosWidgetsLoaderMemo />
      {isCPIUDeviceInProject && <CPIUActiveScenarioController />}
      {isCPIUDeviceInProject && <CPIULoader />}
    </>
  );
};

export default ScenariosWidgetsLoader;
