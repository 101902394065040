import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';

export default class CustomNavLink extends PureComponent {
  render() {
    const { disabled, children, onClick, ...restProps } = this.props;
    return (
      <NavLink
        onClick={e => {
          if (disabled) e.preventDefault();
          else if (onClick) onClick(e);
        }}
        {...restProps}
      >
        {children}
      </NavLink>
    );
  }
}
