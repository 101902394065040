export const DATA_FETCHED = 'DATA_FETCHED';
export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_CLOSE = 'MODAL_CLOSE';

/**
 * Получить отображения профилей устройств
 * REST-метод:/api/v1/device_profile_views
 */
export const DEVICE_GET_PROFILE_VIEWS = 'DEVICE_GET_PROFILE_VIEWS';

/**
 * Авторизация пользователей
 */
// Авторизация
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
// Выход из системы
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
// Подгрузить данные автиризованного пользователя
export const CURRENT_USER_REQUEST = 'CURRENT_USER_REQUEST';
// Подгрузить список прав
export const PERMISSIONS_LOAD = 'PERMISSIONS_LOAD';
// Подтверждение авторизации
export const LOGIN_CHECK_CONFIRMATION = 'LOGIN_CHECK_CONFIRMATION';
// Обновление подтверждения авторизации
export const LOGIN_UPDATE_CONFIRMATION = 'LOGIN_UPDATE_CONFIRMATION';

/**
 * Лицензия
 */
// Получить список прав
export const LICENSE_LOAD_PERMISSIONS = 'LICENSE_LOAD_PERMISSIONS';
// Обновление списка прав
export const LICENSE_UPDATE_PERMISSIONS = 'LICENSE_UPDATE_PERMISSIONS';

/**
 * Работа с кешированием текстур (ОЗ: Планы)
 */
/**
 * Добавить текстуру в хранилище
 *
 * Payload: {
 *  textureId: <string>,
 *  texture: <PIXI.Texture>
 * }
 *
 * Store: state.activeProject.textures
 */
export const TEXTURES_ADD = 'TEXTURES_ADD';
/**
 * Удалить текстуру из хранилища
 *
 * Payload: {
 *  textureId: <string>
 * }
 *
 * Store: state.activeProject.textures
 */
export const TEXTURES_REMOVE = 'TEXTURES_REMOVE';
/**
 * Очистить хранилище текстур
 *
 * Payload: -
 * Strore: state.activeProject.textures
 */
export const TEXTURES_CLEAR = 'TEXTURES_CLEAR';

/**
 *Наблюдаемые параметры
 */
// Загрузить все профили наблюдаемых параметров
export const MONITORABLE_VALUES_GET_PROFILES = 'MONITORABLE_VALUES_GET_PROFILES';
/**
 * Получить историю наблюдаемых параметров устройства
 * Payload: {
 *   projectId: <string>,
 *   deviceId: <string>,
 *   params: {
 *     from: <string>,
 *     to: <string>,
 *     size: <uint>
 *   }
 * }
 */
export const MONITORABLE_VALUES_GET_DEVICE_HISTORY = 'MONITORABLE_VALUES_GET_DEVICE_HISTORY';
// Сбросить историю наблюдаемых параметров
export const MONITORABLE_VALUES_RESET_HISTORY = 'MONITORABLE_VALUES_RESET_HISTORY';
/**
 * Прочее.
 */

/**
 * Загрузить классы состояний
 * REST-метод: GET api/v1/states/categories
 */
export const STATE_CATEGORY_VIEWS_LOAD = 'STATE_CATEGORY_VIEWS_LOAD';

/**
 * Загрузить возможные состояния
 * REST-метод: GET api/v1/states
 */
export const STATES_LOAD = 'STATES_LOAD';

/**
 * Загрузить отображения возможных состояний
 * REST-метод: GET api/v1/states/views
 */
export const STATE_VIEWS_LOAD = 'STATE_VIEWS_LOAD';

/** Обновление состояния подсистем */
export const SUBSYSTEM_STATUS_UPDATED = 'SUBSYSTEM_STATUS_UPDATED';

/**
 * Действия с WS
 */
export const SOCKET_CLIENT_START = 'SOCKET_CLIENT_START';
export const SOCKET_CLIENT_RESTART = 'SOCKET_CLIENT_RESTART';
export const SOCKET_CLIENT_STOP = 'SOCKET_CLIENT_STOP';

/** Обновление данных по WS
 *  Data: {
 *    events: [<event>, ...],
 *    users: [<user>, ...],
 *    userGroups: {
 *      created: [<new user group>, ...],
 *      updated: [<updated user group>, ...],
 *      deletedIds: [<string id>, ...]
 *    }
 *  }
 */
export const UPDATED_ENTITIES = 'UPDATED_ENTITIES';

//Включение/отключение звука в приложении
export const CHANGE_SOUND_CONDITION = 'CHANGE_SOUND_CONDITION';

/** Получение информации о приложении */
export const APPLICATION_INFO_LOAD = 'APPLICATION_INFO_LOAD';

/**
 * Информация о текущей сущности
 * нужно для отображения действий в ОЗ
 * payload: {
 *   entityType: <string DEVICE|REGION>,
 *   entityId: <string>
 * }
 */
export const CURRENT_ENTITY_INFO_UPDATE = 'CURRENT_ENTITY_INFO_UPDATE';

/**
 * Справочник звуковых оповещений
 */
// Выбор звукового оповещения
export const SOUND_NOTIFICATION_SELECT = 'SOUND_NOTIFICATION_SELECT';
// Загрузку звуковых оповещений
export const SOUND_NOTIFICATIONS_LOAD = 'SOUND_NOTIFICATIONS_LOAD';
// Добавление звукового оповещения
export const SOUND_NOTIFICATION_ADD = 'SOUND_NOTIFICATION_ADD';
// Обновление звукового оповещения
export const SOUND_NOTIFICATION_UPDATE = 'SOUND_NOTIFICATIONS_UPDATE';

// Установка текущего оповещения
export const SET_CURRENT_NOTIFICATION = 'SOUND_CURRENT_NOTIFICATION';

/** Получение атрибутов драйвера. */
export const DRIVER_ATTRIBUTES_LOAD = 'DRIVER_ATTRIBUTES_LOAD';
/** Получение описания драйвера. */
export const DRIVER_DESCRIPTION_LOAD = 'DRIVER_DESCRIPTION_LOAD';

/** Добавление фильтра. */
export const ADD_FILTER = 'ADD_FILTER';

/** Установка фильтров. */
export const SET_FILTERS = 'SET_FILTERS';

/** Установка временной метки */
export const SET_TIMESTAMP = 'SET_TIMESTAMP';

/** Получение времени сервера */
export const SERVER_TIME_LOAD = 'SEVER_TIME_LOAD';

/** Переключение режима перехода на страницу с планом при появлении событий */
export const SET_PRIORITY_MODE = 'SET_PRIORITY_MODE';
