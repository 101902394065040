const SUBSYSTEMS = {
  GENERAL: {
    id: 'GENERAL',
    color: '#000',
    index: 0,
    default: true,
    isFilterTag: true
  },
  FIRE: {
    id: 'FIRE',
    color: '#33CC33',
    index: 1,
    default: false,
    isFilterTag: true,
    driverAttributeFiledName: 'fireSubsystem'
  },
  SECURITY: {
    id: 'SECURITY',
    color: '#CCCC00',
    index: 2,
    default: false,
    isFilterTag: true,
    driverAttributeFiledName: 'securitySubsystem'
  },
  SKUD: {
    id: 'SKUD',
    color: '#0066FF',
    index: 3,
    default: false,
    isFilterTag: true,
    driverAttributeFiledName: 'skudSubsystem'
  },
  UNKNOWN: {
    id: 'UNKNOWN',
    color: '#808080',
    index: 4,
    default: false,
    isFilterTag: false
  }
};

export default SUBSYSTEMS;

export const ALL_SUBSYSTEMS = 'ALL';
