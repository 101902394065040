import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fabric } from 'fabric';
import styles from './styles.module.css';

import { modalClose, modalOpen } from 'actions/modals';
import {
  deleteDeviceRegion,
  newDeviceRegion,
  updateRegionPlanLayouts,
  updateRegionsPlanLayouts
} from 'actions/regions';
import {
  updateDevicePlanLayouts,
  updateDevicesPlanLayouts,
  updateDraggableDevice,
  updateCurrentDevice,
  changeDeviceBindingToRegion
} from 'actions/devices';
import {
  updatePlan,
  addPlanBackgrounds,
  loadPlanBackgrounds,
  deletePlanBackground,
  deletePlanObjects,
  deletePlanText,
  updatePlanText,
  copyAndPasteObjectsOnPlan,
  cutAndPasteObjectsOnPlan
} from 'actions/plans';

import PlanEditorToolbar, {
  TOOLBAR_ITEMS as commonToolbarItems
} from 'containers/Menus/PlanEditorToolbar';
import ZoomToolbar, { TOOLBAR_ITEMS as zoomToolbarItems } from 'containers/Menus/ZoomToolbar';

import PlanRegionController from 'containers/Forms/PlanRegionController';
import NewPlanBackgroundForm from 'containers/Forms/NewPlanBackgroundForm';
import UpdateDeviceRegionForm from 'containers/Forms/UpdateDeviceRegionForm';

import SUBSYSTEMS from 'constants/subsystems';
import {
  getCurrentProject,
  getCurrentProjectDeviceList,
  getCurrentProjectDevicesHash,
  getCurrentProjectPlanGroups,
  getCurrentProjectPlans,
  getCurrentProjectPlansHash,
  getCurrentProjectRegionsHash,
  getCurrentProjectRegionViews,
  getCurrentProjectDeviceTree
} from 'helpers/currentProject';
import { getNewRegionIdx } from 'helpers/regions';
import {
  filterSamePoints,
  getObjLeftPosition,
  getObjTopPosition,
  getPolygonPoints,
  getTargetIntersectionsWithObjects,
  getValidxPosition,
  getValidyPosition,
  removePlanObjects,
  setObjectSelectable,
  getBase64FromSvgString,
  controlScaling,
  editPolygon,
  addPointToPolygon,
  getIndexesByDistanceToPoint,
  removePointFromPolygon,
  getPreparedFromCanvasTextObject
} from 'helpers/planEditor';
import { getClockIcon } from 'helpers/icons';
import { CURSOR_STYLES } from 'constants/cursor';
import { KeyCodes } from 'constants/keyboard';
import { ContextMenuTrigger } from 'react-contextmenu';
import PlanEditorContextMenu from 'containers/ContextMenus/PlanEditorContextMenu';
import { hideMenu } from 'components/Popup';
import { Status } from 'constants/project';
import { message, Modal } from 'components';
import i18next from 'i18next';
import GridController from 'containers/Widgets/PlanEditor/GridController';
import TextController, { TEXT_BOX } from 'containers/Widgets/PlanEditor/TextController';

const { Canvas, Text, Point, Rect, Polygon, Group, Line } = fabric;

const DEFAULT = {
  CREATE_STROKE_WIDTH: 1,
  SELECT_STROKE_WIDTH: 2,
  SELECTED_STROKE_COLOR: 'rgb(0,0,255, 0.8)',
  CORNER_COLOR: 'blue'
};

const TOOLBAR_ITEMS = { ...commonToolbarItems, ...zoomToolbarItems };

const defaultFields = {
  originX: 'left',
  originY: 'top',
  flipX: false,
  flipY: false,
  angle: 0,
  cornerSize: 8,
  borderColor: '#000',
  cornerColor: 'rgba(0,0,255,0.5)',
  opacity: 0.6,
  scaleX: 1,
  scaleY: 1,
  clipTo: null,
  perPixelTargetFind: true,
  strokeDashArray: null,
  strokeUniform: true,
  strokeLineCap: 'butt',
  strokeLineJoin: 'miter',
  transformMatrix: null,
  globalCompositeOperation: 'source-over',
  visible: true,
  fillRule: 'nonzero',
  shadow: null,
  startAngle: 0,
  endAngle: 0,
  strokeMiterLimit: 10,
  lockScalingFlip: true
};
//Типы объектов на плане
const WORK_SPACE = 'workSpace';
const ACTIVE_SELECTION = 'activeSelection';
const REGION = 'zone';
const DEVICE = 'device';
const IMAGE = 'image';
const PASTE_AREA = 'pasteArea';

const CUT_ACTION_TYPE = 'cut';
const COPY_ACTION_TYPE = 'copy';

const polygonFields = {
  ...defaultFields,
  type: REGION,
  isPolygon: true,
  objectCaching: false,
  hasControls: false,
  hasBorders: false,
  cornerColor: DEFAULT.CORNER_COLOR
};

const CONTEXT_MENU_ID = 'planEditorContextMenu';
const REACT_CONTEXT_MENU_CLASS = 'react-contextmenu-item';

const DEVICE_ICON_STANDARD_SIZE = 48;
const DEVICE_ICON_STANDARD_SCALE = 0.5;
const RIGHT_CLICK = 3;

const MAX_TEXT_LENGTH = 256;

const ZOOM = {
  MIN: 0.2,
  MAX: 5,
  STEP: 0.1,
  FOR_CANVAS_SIZE: 1000,
  MOUSE_WHEEL_DELTA: 1000,
  PADDING: 10
};

let canCanvasMove = false;
let isCanvasFocused = false;
let isMoveCanvasButtonActive = false;
let mousePointer = null;

// TODO Реализовать копирование/вставку объектов плана через буфер обмена
let originalPlan = null;
let originalBackgroundsMap = null;
let copiedObject = null;
let groupSelectionParams = null;
let currentPasteType = null;

export const PlanObjects = {
  WORKSPACE: { layer: 0, key: WORK_SPACE },
  BACKGROUND: { layer: 1 },
  GRID: { layer: 100, key: 'grid' },
  REGION: { layer: 101 },
  DEVICE: { layer: 102 },
  TEXT: { layer: 103 }
};

class PlanEditor extends Component {
  static propTypes = {
    config: PropTypes.object
  };

  static defaultProps = {
    config: {}
  };

  constructor(props) {
    super(props);
    const isActiveProject = props.project.status === Status.ACTIVE;
    const config = props.config;
    this.state = {
      activeTool: TOOLBAR_ITEMS.SELECT_ELEMENTS_BUTTON,
      inactiveTools: [],
      planConfig: config,
      plan: this.props.currentPlan ? this.props.currentPlan : {},
      planId: this.props.currentPlanId,
      pinToGrid: null,
      isShowGrid: null,
      gridSize: null,
      textConfigValues: null,
      isChecked: [],
      zoomMin: ZOOM.MIN,
      mouseZoom: ZOOM.MOUSE_WHEEL_DELTA,
      isActiveProject,

      /* Объект, включающий в себя данные о перемещаемом на план/е устройстве
       *  Может содержать в себе поля:
       *  device - объект устройства проекта
       *  options - свойство объекта event fabric.js
       *  target - объект устройства fabric.js с координатами и пр.
       *  availableRegions - зоны, к которым можно привязать устройство
       *  regionIds -  идентификаторы зон, к которым можно привязать устройство
       */
      draggableDeviceData: {},
      selectedRegionInfo: {},
      textEditable: false,
      lastRightClickCoords: { x: 0, y: 0 },
      openTextEditorModal: false
    };
    this.backgroundsMap = new Map();
    this.regionsGroup = new Map();
    this.planDevicesMap = [];
    this.resetPolygonData(true, true);
    this.screen = {
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight
    };

    this.popupEl = React.createRef();
    this.pane = React.createRef();
    this.editor = React.createRef();
    this.regionController = React.createRef();
    this.regionController = React.createRef();
    this.textSettingsPopupRef = React.createRef();
    this.deleteObjectRef = React.createRef();
    this.toolBarRef = React.createRef();

    this.regionsToUpdateLayout = {};
    this.devicesToUpdateLayout = {};
    this.objectsToUpdateLayout = { backgrounds: {}, texts: {} };

    this.devicesToUpdateOwnRegion = {};

    this.regionsToDeleteFromPlan = {};
    this.devicesToDeleteFromPlan = {};
    this.objectsToDeleteFromPlan = { texts: [], backgrounds: [] };

    this.isGroupSelected = false;

    this.currentObjectOffsets = {};
    this.currentObjectDragDirection = '';
    this.navigationTimeoutId = null;
  }

  getCheckedActiveTools(config) {
    let selected = [];
    if (config.isShowGrid) selected.push(TOOLBAR_ITEMS.GRID_SHOW_FLAG);
    if (config.pinToGrid) selected.push(TOOLBAR_ITEMS.PIN_TO_GRID_FLAG);
    return selected;
  }

  componentDidMount() {
    const { currentPlan, project, callbacks, config, devices } = this.props;
    const { inactiveTools } = this.state;
    const fabricCanvas = new Canvas('c', {
      width: this.pane.current.offsetWidth,
      height: this.pane.current.offsetHeight,
      selectionFullyContained: true,
      fireRightClick: true,
      stateful: true,
      selectionKey: 'ctrlKey'
    });
    this.bindActions(fabricCanvas); // Устанавливает события для canvas
    const isActiveProject = project.status === Status.ACTIVE;
    const { zoomMin, mouseZoom } = PlanEditor.calculateMinZoom(fabricCanvas, currentPlan);

    const state = {
      fabricCanvas,
      plan: currentPlan,
      isActiveProject,
      zoomMin,
      mouseZoom,
      pinToGrid: config.pinToGrid,
      isShowGrid: config.isShowGrid,
      gridSize: config.gridSize,
      textConfigValues: config.textConfigValues,
      isChecked: this.getCheckedActiveTools(config),
      openTextEditorModal: false,
      canvasTarget: null,
      pastedArea: null
    };

    const tools = PlanEditor.getZoomInactiveTools(config.zoom, inactiveTools, zoomMin);
    if (tools) state.inactiveTools = tools;

    this.setState(state, () => {
      PlanEditor.initZoom(fabricCanvas, currentPlan, config);
      this.init(this.props, fabricCanvas);
      PlanEditor.scaleCanvasObjects(fabricCanvas);
    });

    window.addEventListener('keydown', this.onKeyDown, true);
    window.addEventListener('keyup', this.onKeyUp, true);
    window.addEventListener('mousedown', this.mouseDown, true);

    if (devices) this.planDevicesMap = this.getPlanDevices(devices, currentPlan.id);

    if (callbacks) {
      callbacks.currentPlanCanBeChanged = this.currentPlanCanBeChanged;
    }
    setObjectSelectable(fabricCanvas, !isActiveProject);
    fabricCanvas.selection = !isActiveProject;

    if (originalPlan && currentPlan && originalPlan.projectId !== currentPlan.projectId) {
      originalPlan = null;
      originalBackgroundsMap = null;
      copiedObject = null;
      groupSelectionParams = null;
      currentPasteType = null;
    }
  }

  loadBackgrounds(project, plan, canvas) {
    this.drawBackgrounds(canvas, plan);
    const callback = (result, error) => {
      if (error) {
        message.error(i18next.t('errors.loadBackgrounds'));
      } else {
        result.forEach(b => this.backgroundsMap.set(b.id, b));
        if (plan.id === this.props.currentPlanId) {
          this.deleteBackgrounds(canvas);
          this.drawBackgrounds(canvas, plan);
        }
      }
    };
    this.props.dispatch(loadPlanBackgrounds(project.id, plan.id, callback));
  }

  mouseDown = e => {
    if (!isCanvasFocused && !this.isModalOpen()) {
      const { fabricCanvas } = this.state;
      const { doUpdateDraggableDevice, draggableDeviceId } = this.props;
      this.resetCurrentActions(fabricCanvas, e);
      if (draggableDeviceId) doUpdateDraggableDevice(null);
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      currentPlan,
      project,
      regions,
      devices,
      config,
      updateDevicesPlanLayoutsProgress,
      doChangeDeviceBindingToRegion
    } = this.props;
    const { fabricCanvas, activeTool } = this.state;
    const isActiveProject = project.status === Status.ACTIVE;
    const isBuildProject = project.status === Status.BUILD;

    if (project.status !== prevProps.project.status) {
      setObjectSelectable(fabricCanvas, !isActiveProject);
      fabricCanvas.selection = !isActiveProject;
      if (isActiveProject) {
        this.changeCursorStyle(true, fabricCanvas);
        this.resetPolygonData(true, true, fabricCanvas);
        fabricCanvas.discardActiveObject();
        fabricCanvas.hoverCursor = CURSOR_STYLES.DEFAULT;
      } else if (isBuildProject) {
        this.changeCursorStyle(false, fabricCanvas);
      }
    }

    if (prevState.activeTool !== activeTool) {
      const isSelectButtonActive = activeTool === TOOLBAR_ITEMS.SELECT_ELEMENTS_BUTTON;
      setObjectSelectable(fabricCanvas, isSelectButtonActive);
      fabricCanvas.selection = isSelectButtonActive;
    }

    if (this.isZoomChanged(prevProps.config, config)) {
      PlanEditor.initZoom(fabricCanvas, currentPlan, config);
    }

    if (PlanEditor.isPlanNeedRewrite(currentPlan, prevState.plan)) {
      if (prevProps.currentPlanId !== this.props.currentPlanId) {
        this.init(this.props, fabricCanvas);
        PlanEditor.initZoom(fabricCanvas, currentPlan, config);
      } else if (PlanEditor.isSizePlanChanged(prevProps.currentPlan, currentPlan)) {
        this.resizeWorkSpace(fabricCanvas, currentPlan);
        PlanEditor.fitEditorSize(fabricCanvas, currentPlan);
        this.updateBackgrounds(fabricCanvas, currentPlan);
        fabricCanvas.renderAll();
      }
    } else if (!isActiveProject && currentPlan) {
      this.updatePlanObjectsIfNeed(fabricCanvas, currentPlan, regions, devices, prevProps);

      // обновление привязок устройств к зонам после их группового перемещения на плане (колбеки вынесены из саги в компонент)
      if (
        updateDevicesPlanLayoutsProgress === false &&
        Object.keys(this.devicesToUpdateOwnRegion).length
      ) {
        doChangeDeviceBindingToRegion(Object.values(this.devicesToUpdateOwnRegion), project.id);
        this.devicesToUpdateOwnRegion = {};
      }
    }
  }

  isZoomChanged(prevConfig, config) {
    return (
      prevConfig.zoom !== config.zoom || prevConfig.x !== config.x || prevConfig.y !== config.y
    );
  }

  deleteBackgrounds(fabricCanvas) {
    fabricCanvas
      .getObjects()
      .filter(b => this.isBackground(b))
      .forEach(b => fabricCanvas.remove(b));
  }

  updatePlanObjectsIfNeed(fabricCanvas, currentPlan, regions, devices, prevProps) {
    let needRender = false;

    if (regions && prevProps.regions !== regions) {
      const regionsGroup = this.getPlanRegions(regions, currentPlan.id);
      this.updateRegions(fabricCanvas, regionsGroup);
      needRender = true;
    }
    if (currentPlan.backgrounds !== prevProps.currentPlan.backgrounds) {
      this.updateBackgrounds(fabricCanvas, currentPlan);
      needRender = true;
    }
    if (devices && prevProps.devices !== devices) {
      this.planDevicesMap = this.getPlanDevices(devices, currentPlan.id);
      this.updateDevices(fabricCanvas, this.planDevicesMap, currentPlan);
      needRender = true;
    }
    if (needRender) fabricCanvas.renderAll();
  }

  changeCursorStyle(isActiveProject, fabricCanvas) {
    fabricCanvas.getObjects().forEach(object => {
      if (object.info && object.info.gridKey !== PlanObjects.GRID.key) {
        object.hoverCursor = isActiveProject ? CURSOR_STYLES.DEFAULT : CURSOR_STYLES.POINTER;
      }
    });
  }

  currentPlanCanBeChanged = (updatePlan, callback) => {
    const { fabricCanvas } = this.state;
    const { currentPlan } = this.props;
    if (updatePlan.xSize < currentPlan.xSize || updatePlan.ySize < currentPlan.ySize) {
      this.cutBackgroundsIfNeed(currentPlan, updatePlan);
    }
    this.checkRegionsAndDevicesPosition(updatePlan, fabricCanvas, callback);
  };

  cutBackgroundsIfNeed(prevPlan, newPlan) {
    const planWidthDiff = prevPlan.xSize - newPlan.xSize;
    const planHeightDiff = prevPlan.ySize - newPlan.ySize;
    newPlan.backgrounds.forEach((background, index) => {
      const xSize = background.topLeftPoint.x + background.width * background.scaleX;
      const ySize = background.topLeftPoint.y + background.height * background.scaleY;
      if (xSize > newPlan.xSize) {
        if (background.topLeftPoint.x - planWidthDiff > 0) {
          const oldBackground = prevPlan.backgrounds[index];
          background.topLeftPoint.x = newPlan.xSize - oldBackground.width * oldBackground.scaleX;
        } else {
          background.topLeftPoint.x = 0;
          background.scaleX = newPlan.xSize / background.width;
        }
      }
      if (ySize > newPlan.ySize) {
        if (background.topLeftPoint.y - planHeightDiff > 0) {
          const oldBackground = prevPlan.backgrounds[index];
          background.topLeftPoint.y = newPlan.ySize - oldBackground.height * oldBackground.scaleY;
        } else {
          background.topLeftPoint.y = 0;
          background.scaleY = newPlan.ySize / background.height;
        }
      }
    });
  }

  updateCanvasSize = () => {
    const { fabricCanvas } = this.state;
    const plan = this.props.currentPlan;
    if (!this.pane.current) return;
    fabricCanvas.setHeight(this.pane.current.offsetHeight);
    fabricCanvas.setWidth(this.pane.current.offsetWidth);
    const { zoomMin, mouseZoom } = PlanEditor.calculateMinZoom(fabricCanvas, plan);
    this.setState({ zoomMin, mouseZoom });
    fabricCanvas.renderAll();
  };

  static getDerivedStateFromProps(props, state) {
    const { project, currentPlanId, currentPlan, draggableDeviceId, config } = props;
    let { activeTool, plan, fabricCanvas, isChecked } = state;
    const isActiveProject = project.status === Status.ACTIVE;
    if (isActiveProject || draggableDeviceId) {
      isMoveCanvasButtonActive = false;
      canCanvasMove = false;
    }
    const newState = {
      isActiveProject,
      activeTool,
      isChecked,
      [TOOLBAR_ITEMS.ADD_TEXT_BUTTON]: false
    };

    if (currentPlanId && currentPlanId !== state.plan.id) {
      const data = PlanEditor.updateConfigs(currentPlan, fabricCanvas, config);
      Object.assign(newState, data, { plan: currentPlan, planId: currentPlanId });

      const tools = PlanEditor.getZoomInactiveTools(config.zoom, [], data.zoomMin);
      newState.inactiveTools = tools ? tools : [];
    } else if (PlanEditor.isSizePlanChanged(currentPlan, plan)) {
      const data = PlanEditor.calculateMinZoom(fabricCanvas, currentPlan);
      Object.assign(newState, data);
    }

    if (currentPlan !== plan) newState.plan = currentPlan;
    return newState;
  }

  static isPlanNeedRewrite(currentPropsPlan, currentStatePlan) {
    if (!currentPropsPlan || !currentStatePlan) return false;
    return (
      currentPropsPlan.id !== currentStatePlan.id ||
      currentStatePlan.xSize !== currentPropsPlan.xSize ||
      currentStatePlan.ySize !== currentPropsPlan.ySize
    );
  }

  static updateConfigs(currentPlan, fabricCanvas, config) {
    const { zoomMin, mouseZoom } = PlanEditor.calculateMinZoom(fabricCanvas, currentPlan);
    return {
      zoomMin,
      mouseZoom,
      gridSize: config.gridSize,
      pinToGrid: config.pinToGrid,
      isShowGrid: config.isShowGrid,
      textConfigValues: config.textConfigValues
    };
  }

  static isSizePlanChanged(prevPlan, plan) {
    return plan && prevPlan && (plan.xSize !== prevPlan.xSize || plan.ySize !== prevPlan.ySize);
  }

  checkRegionsAndDevicesPosition(updatingPlan, fabricCanvas, callback) {
    const regionsOutside = [];
    const devicesOutside = [];
    fabricCanvas
      .getObjects()
      .filter(obj => this.isRegionOrDevice(obj))
      .forEach(obj => {
        if (this.isObjectOutside(obj, updatingPlan)) {
          if (this.isRegion(obj)) regionsOutside.push(obj);
          if (this.isDevice(obj)) devicesOutside.push(obj);
        }
      });

    if (regionsOutside.length || devicesOutside.length) {
      this.buildModal(
        i18next.t('plans.sizeOfObjectsLargerThenPlan') + i18next.t('questions.proceed'),
        () => {
          this.deleteOutsideObjectsIfNeed(devicesOutside, regionsOutside);
          fabricCanvas.renderAll();
          callback(true); //сообщаем PlanEditorWidget что план можно обновить
        },
        () => {
          callback(false); //сообщаем PlanEditorWidget что план не нужно обновлять
        }
      );
    } else callback(true); //все зоны и устройства в пределах размеров нового плана
  }

  isObjectOutside(obj, plan) {
    return obj.left + obj.width >= plan.xSize || obj.top + obj.height >= plan.ySize;
  }

  deleteOutsideObjectsIfNeed(devicesOutside, regionsOutside) {
    if (devicesOutside.length) devicesOutside.forEach(dev => this.deletePlanDevice(dev));
    if (regionsOutside.length) regionsOutside.forEach(reg => this.deletePlanRegion(reg));
    //TODO реализовать множественное удаление зон и устройств
  }

  buildModal(title, okCallback, cancelCallback) {
    Modal.confirm({
      title: i18next.t('plans.resizingPlan'),
      content: <span>{title}</span>,
      okText: i18next.t('buttons.ok'),
      onCancel: cancelCallback,
      onOk: okCallback,
      cancelText: i18next.t('buttons.cancel')
    });
  }

  isRegionOrDevice = obj => obj.info && !!(obj.info.deviceId || obj.info.regionId);
  isRegion = obj => obj.info && !!obj.info.regionKey;
  isTextBox = obj => obj.info && !!obj.info.textKey;
  isDevice = obj => obj.info && !!obj.info.deviceKey;
  isBackground = (obj, key) =>
    obj.info && obj.info.backgroundKey && (key ? obj.info.backgroundKey === key : true);
  isCopyableObjectType = type => {
    return (
      type === ACTIVE_SELECTION ||
      type === REGION ||
      type === DEVICE ||
      type === IMAGE ||
      type === TEXT_BOX
    );
  };

  openForm = name => {
    this.setState({ openedModal: name });
    this.props.modalOpen(name);
  };

  closeForm = modalName => {
    const resultModalName = modalName ? modalName : this.state.openedModal;
    this.props.modalClose(resultModalName);
    this.setState({ openedModal: null });
  };

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown, true);
    window.removeEventListener('keyup', this.onKeyUp, true);
    window.removeEventListener('mousedown', this.mouseDown, true);
    fabric.util.removeListener(window, 'dblclick', this.stopPolygonDrawing);
    fabric.util.removeListener(window, 'contextmenu', this.onRightClick);
    fabric.util.removeListener(window, 'click', this.resetSelectedElementByClickOutOfPlan);
  }

  // Инициализирует события вызываемые FabricJS
  bindActions = fabricCanvas => {
    fabricCanvas.on({
      'object:moving': this.objMoving,
      'object:scaling': this.objScaling,
      'object:modified': this.objModified,
      'text:changed': this.textChangeHandler,
      'mouse:up': this.mouseUpEvent,
      'mouse:down': this.mouseDownEvent,
      'mouse:move': this.mouseMoveEvent,
      'mouse:over': this.mouseOverEvent,
      'mouse:out': this.mouseOutEvent,
      'mouse:wheel': this.onMouseWheel,
      'mouse:dblclick': this.onMouseDbClick,
      'selection:created': this.createSelection,
      'selection:cleared': () => {
        this.selectedGroupScales = null;
      }
    });
    fabric.util.addListener(window, 'dblclick', this.stopPolygonDrawing);
    fabric.util.addListener(window, 'contextmenu', this.onRightClick);
    fabric.util.addListener(window, 'click', this.resetSelectedElementByClickOutOfPlan);
  };

  objScaling = e => {
    const { pinToGrid, gridSize, plan, fabricCanvas } = this.state;
    const { target } = e;
    if (!target) return;
    this.isOnlyFrameCreation = false;
    if (target.type !== WORK_SPACE || target.type !== TEXT_BOX) {
      controlScaling(e, plan, gridSize, pinToGrid);
    }
    if (target.type === ACTIVE_SELECTION) {
      const invertedZoom = 1 / fabricCanvas.getZoom();
      const scaleX = 1 / target.scaleX;
      const scaleY = 1 / target.scaleY;
      const devicesList = [];
      const textsList = [];
      target._objects.forEach(obj => {
        if (obj.info.deviceId) devicesList.push(obj);
        if (obj.type === TEXT_BOX) textsList.push(obj);
      });
      if (devicesList.length) {
        devicesList.forEach(device => {
          device.set({
            scaleX: DEVICE_ICON_STANDARD_SCALE * scaleX * invertedZoom,
            scaleY: DEVICE_ICON_STANDARD_SCALE * scaleY * invertedZoom
          });
        });
      }
      if (textsList.length) {
        textsList.forEach(text => {
          text.set({
            scaleX,
            scaleY
          });
        });
      }
    }
  };

  resetSelectedElementByClickOutOfPlan = options => {
    const { fabricCanvas } = this.state;
    if (
      options.target.tagName !== 'CANVAS' &&
      !this.isSelectionCreating &&
      !options.target.classList.contains(REACT_CONTEXT_MENU_CLASS)
    ) {
      fabricCanvas.discardActiveObject();
      fabricCanvas.renderAll();
    } else {
      this.isSelectionCreating = false;
    }
  };

  onMouseDbClick = options => {
    if (canCanvasMove || !options.target) return;
    const { fabricCanvas, isActiveProject, pinToGrid, gridSize, activeTool } = this.state;
    const { target } = options;
    if (target) {
      if (target.isPolygon) {
        if (isActiveProject) return;
        editPolygon(
          fabricCanvas,
          this.props.currentPlan,
          pinToGrid,
          gridSize,
          this._getPointer(options)
        );
        this.setState({ fabricCanvas });
        options.e.preventDefault();
        options.e.stopPropagation();
      } else if (
        target.info &&
        target.info.deviceKey &&
        activeTool === TOOLBAR_ITEMS.SELECT_ELEMENTS_BUTTON
      ) {
        this.showDeviceInTree(target);
      }
    }
  };

  showDeviceInTree = target => {
    const { dispatch, devicesHash } = this.props;
    const device = devicesHash[target.info.deviceId];
    if (device) dispatch(updateCurrentDevice(device));
  };

  openUpdateRegionWindow = target => {
    const { isActiveProject } = this.state;
    if (!isActiveProject && target.info && target.info.regionKey && target.selectable) {
      this.setState({ selectedRegionInfo: target.info });
      this.regionController.current.openPlanRegionModal(true);
    }
  };

  openEditRegionModal = target => {
    this.regionController.current.openUpdateRegionDialog(target.info.regionId);
  };

  openEditTextBoxModal = target => {
    this.setState({ openTextEditorModal: true });
  };

  editView = (target, byPoints) => {
    const { fabricCanvas, pinToGrid, gridSize } = this.state;
    let object = fabricCanvas.getActiveObject();
    object.action = byPoints ? 1 : 0;
    editPolygon(fabricCanvas, this.props.currentPlan, pinToGrid, gridSize);
    this.setState({ fabricCanvas });
  };

  addPoint = (target, coords, e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!target?.info?.regionKey) return;
    const { x, y } = coords;
    const { pinToGrid, gridSize, fabricCanvas } = this.state;
    const point = new Point(x, y);
    const pointIndexByDistance = getIndexesByDistanceToPoint(target, point);
    addPointToPolygon(
      target,
      pointIndexByDistance,
      point,
      this.props.currentPlan,
      pinToGrid,
      gridSize,
      fabricCanvas
    );
  };

  removePoint = (target, coords, e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!target?.info?.regionKey) return;
    const { x, y } = coords;
    const { pinToGrid, gridSize, fabricCanvas } = this.state;
    const point = new Point(x, y);
    const pointIndexByDistance = getIndexesByDistanceToPoint(target, point);
    removePointFromPolygon(
      target,
      pointIndexByDistance,
      this.props.currentPlan,
      pinToGrid,
      gridSize,
      fabricCanvas
    );
  };

  static scaleCanvasObjects(fabricCanvas) {
    if (!fabricCanvas) return;
    const objects = fabricCanvas.getObjects();
    const invertedZoom = 1 / fabricCanvas.getZoom();
    objects
      .filter(obj => PlanEditor.isScalableObject(obj))
      .forEach(obj => {
        if (obj.info.gridKey === PlanObjects.GRID.key) {
          PlanEditor.scaleCanvasGrid(obj, invertedZoom);
        } else if (obj.info.regionKey && obj.stroke) {
          obj.strokeWidth = DEFAULT.SELECT_STROKE_WIDTH * invertedZoom;
        } else if (obj.info.deviceId) {
          obj.set({
            scaleX: DEVICE_ICON_STANDARD_SCALE * invertedZoom,
            scaleY: DEVICE_ICON_STANDARD_SCALE * invertedZoom
          });
        }
      });
  }

  static isScalableObject(obj) {
    return obj && obj.info && (obj.info.deviceId || obj.info.gridKey || obj.info.regionKey);
  }

  static scaleCanvasGrid(group, invertedZoom) {
    const children = group.getObjects();
    if (!children) return;
    children.forEach(obj => {
      obj.set({ strokeWidth: DEFAULT.CREATE_STROKE_WIDTH * invertedZoom });
    });
  }

  onMouseWheel = opt => {
    const { fabricCanvas, zoomMin, inactiveTools, mouseZoom } = this.state;
    const zoom = fabricCanvas.getZoom();
    let delta = opt.e.deltaY;
    let modZoom = zoom - delta / mouseZoom;
    if (modZoom > ZOOM.MAX) modZoom = ZOOM.MAX;

    const tools = PlanEditor.getZoomInactiveTools(modZoom, inactiveTools, zoomMin);
    if (tools) this.setState({ inactiveTools: tools });

    if (modZoom < zoomMin) modZoom = zoomMin;
    fabricCanvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, modZoom);
    PlanEditor.scaleCanvasObjects(fabricCanvas);

    opt.e.preventDefault();
    opt.e.stopPropagation();
  };

  static getZoomInactiveTools = (zoom, inactiveTools, zoomMin) => {
    if (zoom >= ZOOM.MAX && !inactiveTools.includes(TOOLBAR_ITEMS.ZOOM_IN_BUTTON)) {
      return [TOOLBAR_ITEMS.ZOOM_IN_BUTTON];
    } else if (zoom <= zoomMin && !inactiveTools.includes(TOOLBAR_ITEMS.ZOOM_OUT_BUTTON)) {
      return [TOOLBAR_ITEMS.ZOOM_OUT_BUTTON];
    } else if (inactiveTools.length && zoom < ZOOM.MAX && zoom > zoomMin) {
      return [];
    }
    return null;
  };

  onUpdatePlanRegionLayout = regionId => {
    const { selectedRegionInfo } = this.state;
    const { regionsHash, project, doUpdateRegionPlanLayouts } = this.props;
    const selectedLayoutIndex = selectedRegionInfo.regionKey;
    const extendedPlanLayouts = [
      ...(regionsHash[regionId].planLayouts || []),
      {
        planId: selectedRegionInfo.planId,
        points: selectedRegionInfo.points
      }
    ];
    const reducedPlanLayouts = regionsHash[selectedRegionInfo.regionId].planLayouts.filter(
      layout => layout.id !== selectedLayoutIndex
    );
    doUpdateRegionPlanLayouts(project.id, regionId, extendedPlanLayouts);
    doUpdateRegionPlanLayouts(project.id, selectedRegionInfo.regionId, reducedPlanLayouts);
    this.regionController.current.closePlanRegionModal();
    this.setState({ selectedRegionInfo: {} });
  };

  onRightClick = options => {
    if (this.polygonLines.length) {
      this.stopPolygonDrawing(options);
    }
  };

  isModalOpen = () => {
    const { modals } = this.props;
    for (const key in modals) {
      if (modals.hasOwnProperty(key) && modals[key] === true) return true;
    }
    return false;
  };

  onKeyDown = e => {
    let { fabricCanvas, pinToGrid, gridSize } = this.state;
    let stepLength = 10;
    if (e.ctrlKey) {
      stepLength = 1;
      pinToGrid = false;
    } else if (pinToGrid) {
      stepLength = gridSize;
    }
    if (!isCanvasFocused || this.isModalOpen()) return;
    const activeSelection = fabricCanvas.getActiveObject();
    switch (e.keyCode) {
      case KeyCodes.SPACE: {
        if (!fabricCanvas?.getActiveObject()?.isEditing) {
          this.controlStartMoveCanvas(e, fabricCanvas);
        }
        break;
      }
      case KeyCodes.ARROW_LEFT: {
        if (activeSelection)
          this.moveObjectByArrow(
            'left',
            getObjLeftPosition,
            -stepLength,
            pinToGrid,
            activeSelection
          );
        break;
      }
      case KeyCodes.ARROW_RIGHT: {
        if (activeSelection)
          this.moveObjectByArrow(
            'left',
            getObjLeftPosition,
            stepLength,
            pinToGrid,
            activeSelection
          );
        break;
      }
      case KeyCodes.ARROW_UP: {
        if (activeSelection)
          this.moveObjectByArrow('top', getObjTopPosition, -stepLength, pinToGrid, activeSelection);
        break;
      }
      case KeyCodes.ARROW_DOWN: {
        if (activeSelection)
          this.moveObjectByArrow('top', getObjTopPosition, stepLength, pinToGrid, activeSelection);
        break;
      }
      default:
        break;
    }
    this.zoomControlByKeys(e);
  };

  moveObjectByArrow = (offsetName, getPosition, stepLength, pinToGrid, activeSelection) => {
    clearTimeout(this.navigationTimeoutId);
    const { fabricCanvas, gridSize } = this.state;
    const { currentPlan } = this.props;
    if (this.currentObjectOption.target.isEditing) return;

    let newOffset = activeSelection[offsetName] + stepLength;
    activeSelection[offsetName] = newOffset;
    newOffset = getPosition(activeSelection, currentPlan, pinToGrid, gridSize);

    activeSelection.set({
      [offsetName]: newOffset
    });
    activeSelection.setCoords();
    activeSelection[offsetName] = newOffset;
    fabricCanvas.renderAll();

    this.navigationTimeoutId = setTimeout(() => {
      if (this.isOnlyFrameCreation) this.isOnlyFrameCreation = false;
      this.objModified({ target: activeSelection });
    }, 1000);
  };

  controlStartMoveCanvas(event, fabricCanvas) {
    if (!fabricCanvas.prevActiveTool) {
      fabricCanvas.prevActiveTool = this.state.activeTool;
    }
    this.setState({ activeTool: TOOLBAR_ITEMS.MOVE_CANVAS_BUTTON }, () => {
      canCanvasMove = true;
      fabricCanvas.setCursor(CURSOR_STYLES.MOVE);
      event.preventDefault();
      event.stopPropagation();
    });
  }

  zoomControlByKeys(e) {
    if (e.ctrlKey) {
      if (e.keyCode === KeyCodes.PLUS || e.keyCode === KeyCodes.NUM_KEYBOARD_PLUS) {
        this.zoomIn(true);
      } else if (e.keyCode === KeyCodes.MINUS || e.keyCode === KeyCodes.NUM_KEYBOARD_MINUS) {
        this.zoomOut(true);
      } else return;
      e.preventDefault();
      e.stopPropagation();
    }
  }

  onKeyUp = e => {
    if (!isCanvasFocused || this.isModalOpen()) return;
    const { fabricCanvas } = this.state;
    const currentObject = fabricCanvas?.getActiveObject();
    switch (e.keyCode) {
      case KeyCodes.SPACE: {
        if (!currentObject?.isEditing) {
          this.controlFinishMoveCanvas(fabricCanvas);
        }
        break;
      }
      case KeyCodes.ESC: {
        this.resetCurrentActions(fabricCanvas);
        break;
      }
      case KeyCodes.DELETE:
      case KeyCodes.DEL: {
        if (!currentObject?.isEditing) {
          this.deletePlanObject();
        }
        break;
      }
      case KeyCodes.ENTER: {
        if (currentObject?.info?.regionId) {
          this.openUpdateRegionWindow(currentObject);
        }
        break;
      }
      case KeyCodes.C: {
        if (e.ctrlKey && !currentObject?.isEditing) {
          this.copyOrCutPlanObject(COPY_ACTION_TYPE);
        }
        break;
      }
      case KeyCodes.X: {
        if (e.ctrlKey && !currentObject?.isEditing) {
          this.copyOrCutPlanObject(CUT_ACTION_TYPE);
        }
        break;
      }
      case KeyCodes.V: {
        if (e.ctrlKey && !currentObject?.isEditing) {
          this.pastePlanObject();
        }
        break;
      }
      default:
        break;
    }
  };

  resetCurrentActions = (fabricCanvas, event = undefined) => {
    const { activeTool } = this.state;

    // Сброс выделения зоны
    if (this.prevActiveRegion) {
      this.setFrameForRegionPolygon(null, fabricCanvas);
    }
    // Сброс выделения
    if (activeTool === TOOLBAR_ITEMS.SELECT_ELEMENTS_BUTTON) {
      if (event?.target) {
        const deleteElement = this.deleteObjectRef.current.getRootDomNode();
        const isClickInsideDeleteElement = deleteElement
          ? deleteElement.contains(event.target)
          : false;
        if (isClickInsideDeleteElement) {
          isCanvasFocused = true;
          return;
        }
      }
      fabricCanvas.discardActiveObject();
      fabricCanvas.renderAll();
    }
    // Сброс текущего activeTool к SELECT_ELEMENTS_BUTTON
    else {
      // Отмена рисования зоны
      if (
        (activeTool === TOOLBAR_ITEMS.CREATE_REGION_RECT_BUTTON ||
          activeTool === TOOLBAR_ITEMS.CREATE_REGION_POLYGON_BUTTON) &&
        this.polygonLines.length
      ) {
        this.resetPolygonData(true, true, fabricCanvas);
      }
      if (event?.target) {
        const toolBarElement = this.toolBarRef.current;
        //Если кликнули на тулбар или попап настроек текста то не сбрасываем к SELECT_ELEMENTS_BUTTON
        const isClickInsideToolBarElement = toolBarElement
          ? toolBarElement.contains(event.target)
          : false;
        if (isClickInsideToolBarElement) {
          isCanvasFocused = true;
          return;
        }
        const isClickInsidePopupElement = this.textSettingsPopupRef.current
          ?.getPopupDomNode()
          ?.contains(event.target);
        if (isClickInsidePopupElement) {
          isCanvasFocused = true;
          return;
        }
      }

      this.setState({
        activeTool: TOOLBAR_ITEMS.SELECT_ELEMENTS_BUTTON,
        isChecked: this.state.isChecked.filter(it => it !== activeTool)
      });
    }
  };

  pastePlanObject = (coordinatePoint = null) => {
    const target = copiedObject;
    if (!target) return;
    const { fabricCanvas } = this.state;
    fabricCanvas.discardActiveObject();
    const copiedObjects = this.getCopiedObjectsByType(target);
    const canvasObjectsKeysBeforePaste = this.getCanvasObjectsKeys(fabricCanvas);
    if (currentPasteType === COPY_ACTION_TYPE) {
      if (Object.values(copiedObjects).length) {
        this.copyPasteObjectsHandler(copiedObjects, canvasObjectsKeysBeforePaste, coordinatePoint);
      }
    } else if (currentPasteType === CUT_ACTION_TYPE) {
      this.cutPasteObjectsHandler(copiedObjects, canvasObjectsKeysBeforePaste, coordinatePoint);
    }
  };

  copyOrCutPlanObject = actionType => {
    const { fabricCanvas } = this.state;
    const activeSelection = fabricCanvas.getActiveObject();
    if (!activeSelection) return;
    currentPasteType = actionType;
    this.onCopyOrCutPreparations(activeSelection, actionType);
    if (activeSelection.type === ACTIVE_SELECTION) {
      activeSelection.set({
        borderColor: `${actionType === CUT_ACTION_TYPE ? 'black' : 'blue'}`,
        borderDashArray: [5]
      });
      fabricCanvas.renderAll();
    }
  };

  cutPasteObjectsHandler = (
    { backgrounds, texts, regions, devices },
    canvasObjectsKeysBeforePaste,
    coordinatePoint = null
  ) => {
    try {
      const { doCutAndPasteObjectsOnPlan, currentPlan } = this.props;
      const allObjectsArray = [...backgrounds, ...texts, ...regions, ...devices];
      const pastedSingleObject = allObjectsArray.length === 1 ? allObjectsArray[0] : null;
      this.checkPastingObjectSize(currentPlan, pastedSingleObject);
      const objectsIdForSelection = new Set();
      const hasObjectsToPaste = backgrounds.length || texts.length;
      const { pasteObjects, deleteObjects } = hasObjectsToPaste
        ? this.getCutObjectsForPasting(
            { backgrounds, texts },
            objectsIdForSelection,
            coordinatePoint
          )
        : { pasteObjects: null, deleteObjects: null };
      const cutRegions = regions.length
        ? this.getCutRegionsForPasting(regions, objectsIdForSelection, coordinatePoint)
        : null;
      const cutDevices = devices.length
        ? this.getCutDevicesForPasting(devices, objectsIdForSelection, coordinatePoint)
        : null;
      const updateObjects = hasObjectsToPaste
        ? this.getObjectsForUpdating({ backgrounds, texts }, objectsIdForSelection, coordinatePoint)
        : null;

      //Объединение в группу объектов после вставки
      const callback = () => {
        if (currentPlan.id !== originalPlan.id) {
          this.newObjectSelection(canvasObjectsKeysBeforePaste);
        } else {
          this.updatedObjectSelection(objectsIdForSelection);
        }
      };
      copiedObject = null;
      if (deleteObjects || pasteObjects || cutRegions || cutDevices || updateObjects)
        doCutAndPasteObjectsOnPlan(
          { deleteObjects, pasteObjects, cutRegions, cutDevices, updateObjects },
          currentPlan.projectId,
          callback
        );
    } catch (e) {
      message.error(i18next.t(e.message));
    }
  };
  getCutRegionsForPasting = (cutRegions, objectsIdForSelection, coordinatePoint) => {
    const { currentPlan, regionsHash } = this.props;
    const pasteRegions = {};
    cutRegions.forEach(region => {
      const uniqKeys = region.info.regionKey;
      objectsIdForSelection.add(uniqKeys);
      const regionView = regionsHash[region.info.regionId];
      const { targetX, targetY } = this.getPasteByMouseCoordinate(coordinatePoint, region);
      let points = region.info.points;
      if (targetX !== region.left || targetY !== region.top) {
        const xOffset = targetX - region.left;
        const yOffset = targetY - region.top;
        points = points.map(({ x, y }) => ({ x: x + xOffset, y: y + yOffset }));
      }
      const cutRegion = regionView.planLayouts.find(layout => layout.id === uniqKeys);
      const indexOfCutRegion = regionView.planLayouts.indexOf(cutRegion);
      regionView.planLayouts[indexOfCutRegion] = {
        planId: currentPlan.id,
        points,
        id: uniqKeys
      };

      pasteRegions[regionView.id] = {
        regionId: regionView.id,
        regionLayout: regionView.planLayouts
      };
    });
    return Object.values(pasteRegions);
  };

  getObjectsForUpdating = ({ backgrounds, texts }, objectsIdForSelection, coordinatePoint) => {
    const { currentPlan } = this.props;
    if (currentPlan.id !== originalPlan.id) return null;
    const plan = { ...currentPlan };
    plan.backgrounds = plan.backgrounds.map(background => {
      const updatedBack = backgrounds.find(
        updatedBack => updatedBack.info.backgroundKey === background.id
      );
      if (updatedBack) {
        const key = updatedBack.info.backgroundKey;
        objectsIdForSelection.add(key);
        const { targetX, targetY } = this.getPasteByMouseCoordinate(coordinatePoint, updatedBack);
        background.topLeftPoint = { x: targetX, y: targetY };
      }
      return background;
    });
    plan.texts = plan.texts.map(text => {
      const updatedText = texts.find(updatedText => updatedText.info.textKey === text.id);
      if (updatedText) {
        const key = updatedText.info.textKey;
        objectsIdForSelection.add(key);
        const { targetX, targetY } = this.getPasteByMouseCoordinate(coordinatePoint, updatedText);
        text.x = targetX;
        text.y = targetY;
      }
      return text;
    });
    return plan;
  };

  getCutObjectsForPasting = ({ backgrounds, texts }, objectsIdForSelection, coordinatePoint) => {
    const { currentPlan } = this.props;
    if (currentPlan.id === originalPlan.id) return { pasteObjects: null, deleteObjects: null };
    const textsToDelete = [];
    const backgroundsToDelete = [];
    backgrounds.forEach(background => {
      const backgroundKey = background.info.backgroundKey;
      backgroundsToDelete.push(backgroundKey);
    });
    texts.forEach(text => {
      const textKey = text.info.textKey;
      textsToDelete.push(textKey);
    });
    const pasteTexts = texts.length
      ? this.getTextsForPasting(texts, coordinatePoint, objectsIdForSelection)
      : null;
    const pasteBackgrounds = backgrounds.length
      ? this.getBackgroundsForPasting(backgrounds, coordinatePoint, objectsIdForSelection)
      : null;
    return {
      pasteObjects: {
        projectId: currentPlan.projectId,
        planId: currentPlan.id,
        objects: { pasteBackgrounds, pasteTexts }
      },
      deleteObjects: {
        projectId: originalPlan.projectId,
        planId: originalPlan.id,
        objects: { texts: textsToDelete, backgrounds: backgroundsToDelete }
      }
    };
  };

  getCutDevicesForPasting = (devices, objectsIdForSelection, coordinatePoint) => {
    const { currentPlan, devicesHash } = this.props;
    const cutDevices = {};
    devices.forEach(device => {
      const uniqKeys = device.info.deviceKey;
      objectsIdForSelection.add(uniqKeys);
      const deviceView = devicesHash[device.info.deviceId];
      const { targetX, targetY } = this.getPasteByMouseCoordinate(coordinatePoint, device);
      const deviceLayout = deviceView.planLayouts.find(layout => layout.id === uniqKeys);
      const deviceLayoutIndex = deviceView.planLayouts.indexOf(deviceLayout);
      deviceView.planLayouts[deviceLayoutIndex] = {
        planId: currentPlan.id,
        coordinatePoint: { x: targetX, y: targetY },
        id: uniqKeys
      };
      cutDevices[deviceView.id] = {
        deviceId: deviceView.id,
        deviceLayout: deviceView.planLayouts,
        deviceRevision: deviceView.revision
      };
    });
    return Object.values(cutDevices);
  };

  onCopyOrCutPreparations = (activeSelection, actionType) => {
    const { currentPlan } = this.props;
    originalPlan = currentPlan;
    originalBackgroundsMap = new Map(this.backgroundsMap);
    const isActiveSelection = activeSelection.type === ACTIVE_SELECTION;
    copiedObject = isActiveSelection ? activeSelection._objects : [activeSelection];
    if (isActiveSelection) {
      const { left, top, height, width } = activeSelection;
      groupSelectionParams = { left, top, height, width };
    } else {
      groupSelectionParams = null;
    }
    if (this.isCopyableObjectType(activeSelection.type))
      message.success(i18next.t(`actionsOnPlan.${activeSelection.type}.${actionType}`));
  };

  copyPasteObjectsHandler = (
    { backgrounds, texts, regions, devices },
    canvasObjectsKeysBeforePaste,
    coordinatePoint = null
  ) => {
    try {
      const { doCopyAndPasteObjectsOnPlan, currentPlan } = this.props;
      const allObjectsArray = [...backgrounds, ...texts, ...regions, ...devices];
      const pastedSingleObject = allObjectsArray.length === 1 ? allObjectsArray[0] : null;
      this.checkPastingObjectSize(currentPlan, pastedSingleObject);
      const pastingTexts = texts.length ? this.getTextsForPasting(texts, coordinatePoint) : null;
      const pastingBackgrounds = backgrounds.length
        ? this.getBackgroundsForPasting(backgrounds, coordinatePoint)
        : null;
      const pastingRegions = regions.length
        ? this.getCopiedRegionsForPasting(regions, coordinatePoint)
        : null;
      const pastingDevices = devices.length
        ? this.getCopiedDevicesForPasting(devices, coordinatePoint)
        : null;
      const rebindDevices = () => {
        if (pastingDevices) this.rebindDevicesForNewRegions(canvasObjectsKeysBeforePaste);
      };
      const callback = () => {
        this.newObjectSelection(canvasObjectsKeysBeforePaste);
      };
      if (pastingTexts || pastingBackgrounds || pastingRegions || pastingDevices)
        doCopyAndPasteObjectsOnPlan(
          { pastingTexts, pastingBackgrounds, pastingRegions, pastingDevices },
          currentPlan.projectId,
          currentPlan.id,
          rebindDevices,
          callback
        );
    } catch (e) {
      message.error(i18next.t(e.message));
    }
  };

  checkPastingObjectSize = (currentPlan, object) => {
    const { xSize, ySize } = currentPlan;
    if (groupSelectionParams) {
      const { height, width } = groupSelectionParams;
      if (xSize < width || ySize < height) throw new Error('errors.invalidPastingObjectSize');
    } else if (object) {
      const { scaleX, scaleY, height, width } = object;
      if (xSize < width * scaleX || ySize < height * scaleY)
        throw new Error('errors.invalidPastingObjectSize');
    }
    return;
  };

  rebindDevicesForNewRegions = canvasObjectsKeysBeforePaste => {
    const { devicesHash, currentPlan, doChangeDeviceBindingToRegion } = this.props;
    const { fabricCanvas } = this.state;
    const isGroupRebinding = true;
    const devicesToRebind = [];

    fabricCanvas.getObjects().forEach(obj => {
      if (this.isDevice(obj) && !canvasObjectsKeysBeforePaste.has(obj.info.deviceId)) {
        devicesToRebind.push(obj);
      }
    });
    devicesToRebind.forEach(device => {
      const draggableDevice = devicesHash[device.info.deviceId];
      this.createDeviceClone({
        x: device.left,
        y: device.top
      });
      this.checkPlanDeviceIntersections(
        draggableDevice,
        {
          target: { ...device }
        },
        isGroupRebinding
      );
    });
    doChangeDeviceBindingToRegion(
      Object.values(this.devicesToUpdateOwnRegion),
      currentPlan.projectId
    );
    this.devicesToUpdateOwnRegion = {};
    this.devicesToUpdateLayout = {};
  };

  getPasteByMouseCoordinate = (coordinatePoint, target) => {
    let targetX = target.left;
    let targetY = target.top;
    if (coordinatePoint) {
      const {
        currentPlan: { xSize, ySize }
      } = this.props;
      const { x, y } = coordinatePoint;
      if (groupSelectionParams) {
        const { left, top, height, width } = groupSelectionParams;
        targetX -= left - x;
        if (x + width > xSize) targetX -= x + width - xSize;
        targetY -= top - y;
        if (y + height > ySize) targetY -= y + height - ySize;
      } else {
        const { scaleX, scaleY, height, width } = target;
        if (x + width * scaleX > xSize) targetX = xSize - width * scaleX - 1;
        else targetX = x;
        if (y + height * scaleY > ySize) targetY = ySize - height * scaleY - 1;
        else targetY = y;
      }
    }
    return { targetX, targetY };
  };

  getTextsForPasting = (texts, coordinatePoint, objectsIdForSelection = null) => {
    const plan = { ...originalPlan };
    const pastingTexts = [];

    texts.forEach(text => {
      const key = text.info.textKey;
      if (objectsIdForSelection) objectsIdForSelection.add(key);
      const parentText = plan.texts.find(b => b.id === key);
      const { info, ...newText } = parentText;
      const { targetX, targetY } = this.getPasteByMouseCoordinate(coordinatePoint, text);
      pastingTexts.push({ ...newText, x: targetX, y: targetY });
    });

    return pastingTexts;
  };

  getBackgroundsForPasting = (backgrounds, coordinatePoint, objectsIdForSelection = null) => {
    const plan = { ...originalPlan };
    const pastingBackgrounds = [];
    const newLayouts = [];
    backgrounds.forEach(background => {
      const key = background.info.backgroundKey;
      if (objectsIdForSelection) objectsIdForSelection.add(key);
      const updatedBackground = plan.backgrounds.find(b => b.id === key);
      const { targetX, targetY } = this.getPasteByMouseCoordinate(coordinatePoint, background);
      const { content, dataFormat, height, width, type } = originalBackgroundsMap.get(key);
      pastingBackgrounds.push({ content, dataFormat, height, type, width });
      newLayouts.push({ ...updatedBackground, topLeftPoint: { x: targetX, y: targetY } });
    });

    const callback = result => {
      result.backgrounds.forEach(b => this.backgroundsMap.set(b.id, b));
    };
    return {
      pastingBackgrounds,
      newLayouts,
      callback
    };
  };

  getCopiedRegionsForPasting = (regions, coordinatePoint) => {
    const { currentPlan, regionsHash } = this.props;
    let { maxRegionId } = this.props;
    const pastingRegions = {};
    regions.forEach(region => {
      const curRegionId = region.info.regionId;
      const curRegionView = regionsHash[curRegionId];
      const { regionId, regionIndex, id, regionKey, ...regionParams } = curRegionView.region;
      const newRegion = {
        ...regionParams,
        count: 1,
        index: maxRegionId,
        name: `${i18next.t('zone')} ${maxRegionId}`
      };
      const { targetX, targetY } = this.getPasteByMouseCoordinate(coordinatePoint, region);
      let points = region.info.points;
      if (targetX !== region.left || targetY !== region.top) {
        const xOffset = targetX - region.left;
        const yOffset = targetY - region.top;
        points = points.map(({ x, y }) => ({ x: x + xOffset, y: y + yOffset }));
      }
      const planLayouts = [{ planId: currentPlan.id, points }];
      pastingRegions[maxRegionId] = { planLayouts, newRegion };
      maxRegionId++;
    });
    return pastingRegions;
  };

  getCopiedDevicesForPasting = (devices, coordinatePoint) => {
    const { devicesHash, currentPlan, deviceTree } = this.props;
    const copiedDevices = {};
    const devicesLayouts = {};
    const occupiedLineAddresses = [];
    let isNoFreeLineNo = false;

    devices.forEach(device => {
      const originDeviceId = devicesHash[device.info.deviceId].id;
      let curDevice = devicesHash[device.info.deviceId];

      if (curDevice.embedded) {
        curDevice = devicesHash[curDevice.parentDeviceId];
      }

      const curDeviceId = curDevice.id;
      const parentDeviceId = curDevice.parentDeviceId
        ? curDevice.parentDeviceId
        : // '_' + curDevice.id нужно для идентификации копируемых устройств без parentDeviceId
          '_' + curDevice.id;

      const { targetX, targetY } = this.getPasteByMouseCoordinate(coordinatePoint, device);
      const curDeviceLayout = {
        coordinatePoint: { x: targetX, y: targetY },
        planId: currentPlan.id
      };

      if (copiedDevices[parentDeviceId]) {
        if (devicesLayouts[originDeviceId]) {
          devicesLayouts[originDeviceId].push(curDeviceLayout);
        } else {
          devicesLayouts[originDeviceId] = [curDeviceLayout];
          if (!copiedDevices[parentDeviceId].deviceIds.includes(curDeviceId))
            copiedDevices[parentDeviceId].deviceIds.push(curDeviceId);
          copiedDevices[parentDeviceId].numberOfChildren += curDevice.children?.length || 0;
        }
      } else {
        copiedDevices[parentDeviceId] = {
          numberOfChildren: curDevice.children?.length || 0,
          projectId: currentPlan.projectId,
          parentId: curDevice.parentDeviceId,
          deviceIds: [curDeviceId]
        };
        devicesLayouts[originDeviceId] = [curDeviceLayout];
      }
    });
    for (const parentDeviceId in copiedDevices) {
      let lineNo = null;
      let lineAddress = null;
      if (!copiedDevices[parentDeviceId].parentId) {
        if (!occupiedLineAddresses.length) {
          deviceTree.forEach(device => occupiedLineAddresses.push(device.lineAddress));
        }
        lineNo = Math.max(...occupiedLineAddresses) + 1;
        copiedDevices[parentDeviceId].newLineNo = 1;
        copiedDevices[parentDeviceId].newAddress = lineNo;
        occupiedLineAddresses.push(lineNo);
      } else {
        const lineAddressParentRanges = devicesHash[parentDeviceId].lineAddressRanges;
        for (let i = 0; i < lineAddressParentRanges.length; i++) {
          for (let j = 0; j < lineAddressParentRanges[i].length; j++) {
            if (
              lineAddressParentRanges[i][j].addressCount >=
              copiedDevices[parentDeviceId].numberOfChildren
            ) {
              lineNo = i;
              lineAddress = lineAddressParentRanges[i][j].firstAddress;
              break;
            }
          }
          if (lineNo) {
            copiedDevices[parentDeviceId].newLineNo = lineNo;
            copiedDevices[parentDeviceId].newAddress = lineAddress;
            break;
          }
        }
      }
      if (!lineNo) {
        isNoFreeLineNo = true;
        break;
      }
    }
    if (isNoFreeLineNo) {
      throw new Error('devices.errors.notEnoughFreeAddresses');
    } else return { copiedDevices: Object.values(copiedDevices), devicesLayouts };
  };

  getCopiedObjectsByType = target => {
    const objects = { texts: [], backgrounds: [], devices: [], regions: [] };
    target.forEach(obj => {
      if (this.isDevice(obj)) {
        objects.devices.push(obj);
      } else if (this.isRegion(obj)) {
        objects.regions.push(obj);
      } else if (this.isBackground(obj)) {
        objects.backgrounds.push(obj);
      } else if (this.isTextBox(obj)) {
        objects.texts.push(obj);
      }
    });
    return objects;
  };

  getCanvasObjectsKeys = fabricCanvas => {
    const canvasObjectsKeys = new Set();
    fabricCanvas.getObjects().forEach(obj => {
      if (!obj.info) return;
      if (this.isDevice(obj)) {
        canvasObjectsKeys.add(obj.info.deviceId);
      } else if (this.isRegion(obj)) {
        canvasObjectsKeys.add(obj.info.regionKey);
      } else if (this.isBackground(obj)) {
        canvasObjectsKeys.add(obj.info.backgroundKey);
      } else if (this.isTextBox(obj)) {
        canvasObjectsKeys.add(obj.info.textKey);
      }
    });
    return canvasObjectsKeys;
  };

  newObjectSelection = canvasObjectsKeysBeforePaste => {
    const { fabricCanvas } = this.state;
    const canvasObjects = fabricCanvas._objects;
    const objectsForSelection = [];
    canvasObjects.forEach(obj => {
      if (!obj.info) return;
      if (this.isDevice(obj)) {
        if (!canvasObjectsKeysBeforePaste.has(obj.info.deviceId)) objectsForSelection.push(obj);
      } else if (this.isRegion(obj)) {
        if (!canvasObjectsKeysBeforePaste.has(obj.info.regionKey)) objectsForSelection.push(obj);
      } else if (this.isBackground(obj)) {
        if (!canvasObjectsKeysBeforePaste.has(obj.info.backgroundKey))
          objectsForSelection.push(obj);
      } else if (this.isTextBox(obj)) {
        if (!canvasObjectsKeysBeforePaste.has(obj.info.textKey)) objectsForSelection.push(obj);
      }
    });
    this.createActiveSelection(objectsForSelection, fabricCanvas);
  };
  updatedObjectSelection = objectsIdForSelection => {
    const { fabricCanvas } = this.state;
    const canvasObjects = fabricCanvas._objects;
    const objectsForSelection = [];
    canvasObjects.forEach(obj => {
      if (!obj.info) return;
      if (this.isDevice(obj)) {
        if (objectsIdForSelection.has(obj.info.deviceKey)) objectsForSelection.push(obj);
      } else if (this.isRegion(obj)) {
        if (objectsIdForSelection.has(obj.info.regionKey)) objectsForSelection.push(obj);
      } else if (this.isBackground(obj)) {
        if (objectsIdForSelection.has(obj.info.backgroundKey)) objectsForSelection.push(obj);
      } else if (this.isTextBox(obj)) {
        if (objectsIdForSelection.has(obj.info.textKey)) objectsForSelection.push(obj);
      }
    });
    this.createActiveSelection(objectsForSelection, fabricCanvas);
  };

  createActiveSelection = (objectsForSelection, fabricCanvas) => {
    fabricCanvas.discardActiveObject();
    const selection = new fabric.ActiveSelection(objectsForSelection, {
      canvas: fabricCanvas
    });

    fabricCanvas.setActiveObject(selection);
    fabricCanvas.requestRenderAll();
  };

  createPasteArea = () => {
    let {
      fabricCanvas,
      lastRightClickCoords: { x, y }
    } = this.state;
    const {
      currentPlan: { xSize, ySize }
    } = this.props;

    let width = 1;
    let height = 1;
    let fill = 'grey';
    if (groupSelectionParams) {
      width = groupSelectionParams.width;
      height = groupSelectionParams.height;
    } else if (copiedObject) {
      width = copiedObject[0].width;
      height = copiedObject[0].height;
    }
    if (width + x > xSize) {
      x = xSize - width;
    }
    if (height + y > ySize) {
      y = ySize - height;
    }
    if (width > xSize || height > ySize) {
      fill = 'red';
    }
    const pastedArea = new Rect({
      left: x,
      top: y,
      width,
      height,
      strokeWidth: 1,
      fill,
      opacity: 0.2,
      selectable: false,
      hasControls: false,
      type: PASTE_AREA
    });
    fabricCanvas.add(pastedArea);
    pastedArea.bringToFront();
    this.setState({ pastedArea });
  };

  deletePasteArea = () => {
    const { fabricCanvas, pastedArea } = this.state;
    fabricCanvas.remove(pastedArea);
  };

  controlFinishMoveCanvas(fabricCanvas) {
    if (isMoveCanvasButtonActive) return;
    const activeTool = fabricCanvas.prevActiveTool
      ? fabricCanvas.prevActiveTool
      : TOOLBAR_ITEMS.SELECT_ELEMENTS_BUTTON;
    fabricCanvas.prevActiveTool = undefined;
    this.setState({ activeTool }, () => {
      canCanvasMove = false;
      fabricCanvas.setCursor(CURSOR_STYLES.DEFAULT);
    });
  }

  createSelection = options => {
    const { target } = options;
    if (canCanvasMove) {
      if (options.e) {
        options.e.preventDefault();
        options.e.stopPropagation();
      }
      return;
    }

    if (target.type === ACTIVE_SELECTION) {
      target.set({
        lockScalingFlip: true,
        lockRotation: true
      });
      target.setControlsVisibility({ mtr: false });
      this.isSelectionCreating = true;
      this.isGroupSelected = true;
    }
    this.currentObjectOption = options;
  };

  resetPolygonData = (lines, points, fabricCanvas) => {
    if (fabricCanvas) {
      removePlanObjects(fabricCanvas, this.polygonLines);
      fabricCanvas.figureTransformAction = 0;
    }
    if (lines) this.polygonLines = [];
    if (points) this.polygonPoints = [];
  };

  createDeviceClone = pos => {
    const { fabricCanvas } = this.state;
    this.removeDeviceClone();
    this.invisibleObject = new Rect({
      top: pos.y,
      left: pos.x,
      width: 1,
      height: 1,
      opacity: 0
    });
    fabricCanvas.add(this.invisibleObject);
    this.invisibleObject.selectable = false;
    this.invisibleObject.setCoords();
  };

  removeDeviceClone = () => {
    const { fabricCanvas } = this.state;
    if (this.invisibleObject) fabricCanvas.remove(this.invisibleObject);
  };

  mouseOverEvent = options => {
    if (this.isCanvasMove(options)) return;
    if (this.popupEl.current) {
      this.popupDelay = setTimeout(() => this.showPopup(options), 600);
    }
  };

  isCanvasMove(options) {
    if (canCanvasMove) {
      options.e.preventDefault();
      options.e.stopPropagation();
      return true;
    }
    return false;
  }

  mouseOutEvent = opt => {
    if (this.popupEl.current) {
      clearInterval(this.popupDelay);
      this.hidePopup();
      this.popupDelay = null;
    }
    if (!this.isCanvas(opt, this.props.currentPlan)) isCanvasFocused = false;
    this.clearCoordsIfMouseOutOfCanvas(opt);
  };

  clearCoordsIfMouseOutOfCanvas(opt) {
    let type = opt.target && opt.target.type;
    if (!type) mousePointer = null;
  }

  checkEditable = object => {
    const { isActiveProject, activeTool } = this.state;
    object.selectable = isActiveProject
      ? false
      : activeTool === TOOLBAR_ITEMS.SELECT_ELEMENTS_BUTTON;
    object.hoverCursor =
      (isActiveProject
        ? CURSOR_STYLES.DEFAULT
        : activeTool === TOOLBAR_ITEMS.SELECT_ELEMENTS_BUTTON
        ? CURSOR_STYLES.POINTER
        : CURSOR_STYLES.DEFAULT) || !canCanvasMove;
  };

  _getPointer = (event, grid) => {
    const { fabricCanvas, gridSize } = this.state;
    const { currentPlan } = this.props;
    if (!currentPlan || !fabricCanvas) return { x: 0, y: 0 };
    const _mouse = fabricCanvas.getPointer(event);
    return {
      x: getValidxPosition(_mouse.x, currentPlan.xSize, grid ? gridSize : null),
      y: getValidyPosition(_mouse.y, currentPlan.ySize, grid ? gridSize : null)
    };
  };

  isCanvas = (options, currentPlan) => {
    if (options.target) return true;
    const pointer = options.absolutePointer;
    if (!pointer) return false;
    return true;
  };

  controlDraggedCursor(options) {
    const { fabricCanvas } = this.state;
    const cursor = window.getComputedStyle(document.getElementsByTagName('body')[0]).cursor;
    fabricCanvas.setCursor(cursor);
    options.preventDefault();
    options.stopPropagation();
  }

  mouseMoveEvent = options => {
    const { activeTool, fabricCanvas } = this.state;
    const { currentPlan, draggableDeviceId } = this.props;
    const { e } = options;
    const _mouse = this._getPointer(e);
    this._mouseX = _mouse.x;
    this._mouseY = _mouse.y;

    if (draggableDeviceId) this.controlDraggedCursor(e);

    isCanvasFocused = this.isCanvas(options, currentPlan);
    if (this.controlCanvasMovement(fabricCanvas, e)) return;
    switch (activeTool) {
      case TOOLBAR_ITEMS.CREATE_REGION_POLYGON_BUTTON: {
        if (this.polygonLines.length) {
          const { pinToGrid, gridSize } = this.state;
          const { currentPlan } = this.props;
          const x2 = pinToGrid
            ? getValidxPosition(_mouse.x, currentPlan.xSize, gridSize)
            : _mouse.x;
          const y2 = pinToGrid
            ? getValidxPosition(_mouse.y, currentPlan.xSize, gridSize)
            : _mouse.y;
          const updatedLine = this.polygonLines[this.polygonLines.length - 1];
          updatedLine.set({ x2, y2 }).setCoords();
          fabricCanvas.renderAll();
        }
        break;
      }
      case TOOLBAR_ITEMS.CREATE_REGION_RECT_BUTTON: {
        if (this.polygonLines.length) {
          const { pinToGrid, gridSize } = this.state;
          const { currentPlan } = this.props;
          const _x = pinToGrid
            ? getValidxPosition(_mouse.x, currentPlan.xSize, gridSize)
            : _mouse.x;
          const _y = pinToGrid
            ? getValidxPosition(_mouse.y, currentPlan.xSize, gridSize)
            : _mouse.y;
          this.polygonLines.forEach((line, index) => {
            switch (index) {
              case 0: {
                line.set({ x2: _x }).setCoords();
                break;
              }
              case 1: {
                line.set({ x1: _x, x2: _x, y2: _y }).setCoords();
                break;
              }
              case 2: {
                line.set({ x2: _x, y1: _y, y2: _y }).setCoords();
                break;
              }
              case 3: {
                line.set({ y2: _y }).setCoords();
                break;
              }
              default:
                break;
            }
          });
          fabricCanvas.renderAll();
        }
        break;
      }
      case TOOLBAR_ITEMS.SELECT_ELEMENTS_BUTTON: {
        if (this.popupEl.current) {
          const xPos = e.clientX + 20;
          const popupWidth = this.popupEl.current.offsetWidth;
          // Проверяем выходит ли popup за пределы экрана, если да, то отображаем его слева от курсора
          if (xPos + popupWidth > this.screen.width) {
            this.popupEl.current.style.left = e.clientX - popupWidth - 20 + 'px';
          } else {
            this.popupEl.current.style.left = e.clientX + 20 + 'px';
          }
          this.popupEl.current.style.top = e.clientY + 20 + 'px';
        }
        break;
      }
      default:
        break;
    }
  };

  /** @returns {boolean} - true, если холст в движении, иначе false */
  controlCanvasMovement(canvas, event) {
    if (event.target) {
      mousePointer = new Point(event.layerX, event.layerY);
    }
    if (canCanvasMove || isMoveCanvasButtonActive) {
      if (this.isDragging) {
        this.moveCanvas(canvas, event);
      }
      canvas.setCursor(CURSOR_STYLES.MOVE);
      return true;
    }
    return false;
  }

  moveCanvas(canvas, event) {
    canvas.viewportTransform[4] += event.clientX - this.lastPosX;
    canvas.viewportTransform[5] += event.clientY - this.lastPosY;
    PlanEditor.updateObjectsCoords(canvas);
    canvas.renderAll();
    this.lastPosX = event.clientX;
    this.lastPosY = event.clientY;
  }

  stopPolygonDrawing = (options, needResetData = true) => {
    const { fabricCanvas } = this.state;
    if (this.polygonLines.length) {
      this.regionController.current.openPlanRegionModal();
      this.resetPolygonData(false, false, fabricCanvas);
    }
  };

  mouseUpEvent = options => {
    const { fabricCanvas } = this.state;
    this.isDragging = false;
    this.currentObjectDragDirection = '';
    this.isGroupSelected =
      fabricCanvas?.getActiveObject()?.type === ACTIVE_SELECTION ? true : false;
    this.setState({ canvasTarget: options.target });
  };

  mouseDownEvent = options => {
    const { activeTool, fabricCanvas, pinToGrid } = this.state;

    const { draggableDeviceId, devicesHash, currentPlan } = this.props;
    const { target } = options;
    const pointer = options.absolutePointer;

    if (target) {
      this.currentObjectOffsets = {
        top: target.top,
        left: target.left
      };
    }
    hideMenu({ id: CONTEXT_MENU_ID });
    if (pointer.x > currentPlan.xSize || pointer.y > currentPlan.ySize) return;
    if (canCanvasMove) {
      const evt = options.e;
      this.lastPosX = evt.clientX;
      this.lastPosY = evt.clientY;
      this.isDragging = true;
      options.e.preventDefault();
      options.e.stopPropagation();
      fabricCanvas.renderAll();
      return;
    }
    this.setState({ openTextEditorModal: false });
    const { x, y } = this._getPointer(options.e, pinToGrid);
    if (options.button === RIGHT_CLICK) {
      this.selectObject(fabricCanvas, options);
      this.setState({ lastRightClickCoords: { x, y } });
      return;
    }
    switch (activeTool) {
      case TOOLBAR_ITEMS.CREATE_REGION_POLYGON_BUTTON: {
        const invertedZoom = 1 / fabricCanvas.getZoom();
        const line = new Line([x, y, x, y], {
          strokeWidth: DEFAULT.CREATE_STROKE_WIDTH * invertedZoom,
          selectable: false,
          stroke: 'red'
        });
        line.hoverCursor = CURSOR_STYLES.DEFAULT;
        this.polygonPoints = [...this.polygonPoints, new Point(x, y)];
        this.polygonLines = [...this.polygonLines, line];
        fabricCanvas.add(line);
        break;
      }
      case TOOLBAR_ITEMS.CREATE_REGION_RECT_BUTTON: {
        if (this.polygonLines.length) {
          const p1 = this.polygonPoints[0];
          const p2 = new Point(x, y);
          this.polygonPoints = [p1, new Point(p1.x, p2.y), p2, new Point(p2.x, p1.y)];
          this.stopPolygonDrawing(options, false);
        } else {
          this.createRectSelection(fabricCanvas, x, y);
        }
        break;
      }
      case TOOLBAR_ITEMS.SELECT_ELEMENTS_BUTTON: {
        if (this.prevActiveRegion !== target) {
          this.setFrameForRegionPolygon(target, fabricCanvas);
        }
        if (target?.info?.deviceKey) {
          this.lastSelectedDevicePosition = { x: target.left, y: target.top };
        }

        if (draggableDeviceId) {
          // Ищем устройство в списке
          const draggableDevice = devicesHash[draggableDeviceId];
          // Если устройство найдено и его можно привязать к зоне
          if (draggableDevice && draggableDevice.attachableToRegion) {
            this.createDeviceClone({ x, y });
            this.checkNewPlanDeviceIntersections(draggableDevice, options);
          }
          // Если устройство найдено и нельзя привязать к зоне
          else if (draggableDevice && !draggableDevice.attachableToRegion) {
            // Добавляем устройство на план
            this.createPlanDevice(options);
          }
        }
        break;
      }
      case TOOLBAR_ITEMS.ADD_TEXT_BUTTON: {
        if (fabricCanvas.getActiveObject()) {
          this.setState({
            activeTool: TOOLBAR_ITEMS.SELECT_ELEMENTS_BUTTON,
            isChecked: this.state.isChecked.filter(it => it !== TOOLBAR_ITEMS.ADD_TEXT_BUTTON)
          });
        }
        break;
      }
      default:
        break;
    }
  };

  setFrameForRegionPolygon = (target, fabricCanvas) => {
    if (this.prevActiveRegion === target) return;
    if (this.prevActiveRegion) {
      this.prevActiveRegion.set({
        strokeWidth: 0,
        stroke: false,
        hasBorders: false,
        hasControls: false,
        cornerStyle: 'rect',
        controls: fabric.Object.prototype.controls
      });

      this.prevActiveRegion.setControlsVisibility({ mtr: false });
      this.prevActiveRegion.action = null;
    }
    if (target && this.isRegion(target)) {
      this.prevActiveRegion = target;
      const invertZoom = 1 / fabricCanvas.getZoom();
      target.set({
        strokeWidth: DEFAULT.SELECT_STROKE_WIDTH * invertZoom,
        stroke: DEFAULT.SELECTED_STROKE_COLOR
      });
      target._calcDimensions();
      target.setCoords();
    } else this.prevActiveRegion = null;
    this.isOnlyFrameCreation = true;
  };

  setPrevImageUnselectable = target => {
    if (this.prevActiveImage)
      this.prevActiveImage.set({ selectable: false, hoverCursor: CURSOR_STYLES.DEFAULT });
    if (target && this.isBackground(target)) {
      this.prevActiveImage = target;
    } else this.prevActiveImage = null;
  };

  createRectSelection = (canvas, x, y) => {
    for (let i = 0; i < 4; i++) {
      const invertedZoom = 1 / canvas.getZoom();
      const line = new Line([x, y, x, y], {
        strokeWidth: DEFAULT.CREATE_STROKE_WIDTH * invertedZoom,
        selectable: false,
        stroke: 'red'
      });
      line.hoverCursor = CURSOR_STYLES.DEFAULT;
      this.polygonLines.push(line);
      this.polygonPoints = [new Point(x, y)];
      canvas.add(line);
    }
  };

  selectObject(canvas, event) {
    const pointer = canvas.getPointer(event, true);
    const objects = canvas.getObjects();
    const activeSelection = canvas.getActiveObject();
    if (!(activeSelection && activeSelection.containsPoint(pointer))) {
      for (let i = objects.length - 1; i >= 0; i--) {
        const obj = objects[i];
        const isDevice = this.isDevice(obj) && obj.containsPoint(pointer);
        const isRegion = this.isRegion(obj) && obj.containsPoint(pointer);
        const isBackground = this.isBackground(obj) && obj.containsPoint(pointer);
        const isTextBox = this.isTextBox(obj) && obj.containsPoint(pointer);
        this.setFrameForRegionPolygon(obj, canvas);
        if (isRegion || isDevice || isBackground || isTextBox) {
          canvas.setActiveObject(obj);
          break;
        }
        if (i === 0) {
          canvas.discardActiveObject();
        }
      }
    }
    canvas.renderAll();
  }

  getPlanRegionsForAttachDevice = (planRegions, planDevices, device) => {
    const devices = planDevices.filter(
      planDevice => planDevice.info && planDevice.info.attachableToRegion
    );
    // Фильтруем зоны плана
    return planRegions.filter(
      region =>
        // Если совпадает подсистема
        (region.info && region.info.subsystem === device.subsytem) ||
        // Или не имеет на себе устройств
        !getTargetIntersectionsWithObjects(region, devices).length
    );
  };

  updateDeviceRegion = (device, regionId, isGroupChanging = false) => {
    if (isGroupChanging) {
      this.devicesToUpdateOwnRegion[device.id] = {
        regionId,
        deviceId: device.id,
        deviceRevision: device.revision
      };
    } else
      this.props.dispatch(
        newDeviceRegion({
          projectId: device.projectId,
          regionId,
          deviceId: device.id
        })
      );
  };

  deleteDeviceRegion = (device, isGroupChanging = false) => {
    if (isGroupChanging) {
      this.devicesToUpdateOwnRegion[device.id] = {
        // TODO Реализовать changeDeviceBindingToRegion на беке (=>)

        regionId: device.regionId, // удалить после =>
        // regionId: null, // раскомментировать после =>
        deviceId: device.id,
        deviceRevision: device.revision,
        isDeleting: true // удалить после => реализации changeDeviceBindingToRegion на беке
      };
    } else
      this.props.dispatch(
        deleteDeviceRegion({
          projectId: device.projectId,
          regionId: device.regionId,
          deviceId: device.id
        })
      );
  };

  /**
   * Отвязка устройства от зоны
   */

  deleteDeviceRegionConfirm = () => {
    const {
      draggableDeviceData: { device, options, target }
    } = this.state;

    const callBack = () => {
      this.deleteDeviceRegion(device);
      this.closeForm();
      this.removeDeviceClone();
      this.setState({ draggableDeviceData: {} });
    };

    if (target) this.updatePlanDevice(target, callBack);
    else this.createPlanDevice(options, callBack);
  };

  deleteDeviceRegionReject = () => {
    const {
      draggableDeviceData: { target, options }
    } = this.state;
    if (target) {
      this.updatePlanDevice(target);
    } else {
      this.createPlanDevice(options);
    }
    this.closeForm();
    this.removeDeviceClone();
  };

  deleteDeviceRegionCancel = () => {
    this.dropDraggableDevice();
    this.closeForm();
  };

  /**
   * Изменение зоны устройства
   */
  changeDeviceRegionConfirm = regionId => {
    const {
      draggableDeviceData: { device, options, target }
    } = this.state;

    const callback = () => {
      this.updateDeviceRegion(device, regionId);
      this.closeForm('changeDeviceRegion');
      this.removeDeviceClone();
      this.setState({ draggableDeviceData: {} });
    };

    if (target) this.updatePlanDevice(target, callback);
    else this.createPlanDevice(options, callback);
  };

  changeDeviceRegionReject = () => {
    const {
      draggableDeviceData: { target }
    } = this.state;
    if (target) this.updatePlanDevice(target);
    this.closeForm('changeDeviceRegion');
    this.removeDeviceClone();
  };

  changeDeviceRegionCancel = () => {
    this.dropDraggableDevice();
    this.closeForm('changeDeviceRegion');
    this.removeDeviceClone();
  };

  /**
   * Выбор зоны
   */
  selectDeviceRegionConfirm = regionId => {
    const {
      draggableDeviceData: { options, device, availableRegions, target }
    } = this.state;
    const selectedRegion = availableRegions.find(region => region.info.regionId === regionId);
    if (selectedRegion && selectedRegion.info.subsystem === device.subsystem) {
      if (device.regionId) {
        this.setState({
          draggableDeviceData: {
            device,
            regionIds: [selectedRegion.info.regionId],
            options,
            target
          }
        });
        this.openForm('changeDeviceRegion');
      } else {
        const callback = () => {
          this.updateDeviceRegion(device, regionId);
          this.setState({ draggableDeviceData: {} });
        };
        if (target) this.updatePlanDevice(target, callback);
        else this.createPlanDevice(options, callback);
      }
    }
    this.closeForm('selectDeviceRegion');
  };

  selectDeviceRegionCancel = () => {
    const {
      draggableDeviceData: { target }
    } = this.state;
    if (target) {
      this.resetPlanDeviceModifications(target);
    }
    this.closeForm('selectDeviceRegion');
  };

  /**
   * Расположить поверх зоны с другой подсистемой
   */

  forceDropDeviceConfirm = regionId => {
    const {
      draggableDeviceData: { options, target }
    } = this.state;
    if (target) {
      this.updatePlanDevice(target);
    } else {
      this.createPlanDevice(options);
    }
    this.closeForm();
  };

  forceDropDeviceCancel = () => {
    this.dropDraggableDevice();
    this.closeForm();
  };

  getTargetInGroupOffset = target => ({
    groupLeftOffset: target.group.left + target.group.width / 2,
    groupTopOffset: target.group.top + target.group.height / 2
  });

  getTargetOffsetAfterScale = target => ({
    scaleLeftOffset:
      this.selectedGroupScales.x * (target.left + target.group.width / 2) -
      (target.left + target.group.width / 2),
    scaleTopOffset:
      this.selectedGroupScales.y * (target.top + target.group.height / 2) -
      (target.top + target.group.height / 2)
  });

  getTargetScales = target => ({
    newScaleX: this.selectedGroupScales.x * target.scaleX,
    newScaleY: this.selectedGroupScales.y * target.scaleY
  });

  oneModifying = (target, planSize, isDeviceBindedToRegionInGroup) => {
    if (!target.info) return;
    let left = target.left;
    let top = target.top;
    let scaleX = target.scaleX;
    let scaleY = target.scaleY;
    if (target.group) {
      const isGroupModifying = true;

      const { groupLeftOffset, groupTopOffset } = this.getTargetInGroupOffset(target);
      left += groupLeftOffset;
      top += groupTopOffset;
      if (this.selectedGroupScales) {
        const { scaleLeftOffset, scaleTopOffset } = this.getTargetOffsetAfterScale(target);
        left += scaleLeftOffset;
        top += scaleTopOffset;
        const { newScaleX, newScaleY } = this.getTargetScales(target);
        scaleX = newScaleX;
        scaleY = newScaleY;
      }
      const targetInGroup = { info: target.info, left, top };
      if (target.info.regionKey) {
        this.updatePlanRegion(
          getPolygonPoints(target, fabric, top, left, planSize),
          target,
          isGroupModifying
        );
      } else if (target.info.deviceKey) {
        if (target.info.attachableToRegion && !isDeviceBindedToRegionInGroup) {
          const { devicesHash } = this.props;
          const draggableDevice = devicesHash[target.info.deviceId];
          this.createDeviceClone({
            x: left,
            y: top
          });
          this.checkPlanDeviceIntersections(
            draggableDevice,
            {
              target: { ...target, top, left }
            },
            isGroupModifying
          );
        } else {
          this.updatePlanDevice(targetInGroup, () => {}, isGroupModifying);
        }
      } else if (target.info.backgroundKey) {
        this.updatePlanBackground({ ...target, top, left, scaleX, scaleY }, isGroupModifying);
      } else if (target.info.textKey) {
        this.updateTexts({ ...target, top, left, scaleX, scaleY }, isGroupModifying);
      }
    } else {
      if (target.info.regionKey && !this.isOnlyFrameCreation) {
        this.updatePlanRegion(getPolygonPoints(target, fabric, top, left, planSize), target);
      } else if (target.info.deviceKey) {
        if (target.info.attachableToRegion) {
          const { devicesHash } = this.props;
          const draggableDevice = devicesHash[target.info.deviceId];
          this.createDeviceClone({ x: left, y: top });
          this.checkPlanDeviceIntersections(draggableDevice, { target });
        } else this.updatePlanDevice(target);
      } else if (target.info.backgroundKey) {
        this.updatePlanBackground(target);
      } else if (target.info.textKey) {
        this.updateTexts({ ...target, top, left, scaleX, scaleY });
      }
    }
  };

  // Метод обновления объекта
  objModified = options => {
    if (!options) return;
    const { target, action } = options;
    if (!target) return;
    const { _objects } = target;
    const { plan } = this.state;
    const planSize = { xSize: plan.xSize, ySize: plan.ySize };
    const isPolygonEditing = action === 'modifyPolygon';

    if (isPolygonEditing) {
      this.isOnlyFrameCreation = false;
    }
    if (target.hasOwnProperty('info') || isPolygonEditing) this.oneModifying(target, planSize);
    else if (_objects) {
      const {
        doUpdateRegionsPlanLayouts,
        doUpdateDevicesPlanLayouts,
        doUpdatePlan,
        project: { id },
        currentPlan
      } = this.props;

      if (action && action.includes('scale')) {
        this.selectedGroupScales = {
          x: options.target.scaleX,
          y: options.target.scaleY
        };
      }

      const listOfRegionIdInGroup = [];
      _objects.forEach(obj => {
        if (this.isRegion(obj)) listOfRegionIdInGroup.push(obj.info.regionId);
      });

      _objects.forEach(obj => {
        // Проверка на наличие устройства, привязанного к зоне, и этой зоны, в редактируемой группе
        const isDeviceBindedToRegionInGroup =
          listOfRegionIdInGroup.length &&
          this.isDevice(obj) &&
          obj.info.attachableToRegion &&
          listOfRegionIdInGroup.includes(obj.info.regionId);

        this.oneModifying(obj, planSize, isDeviceBindedToRegionInGroup);
      });

      if (Object.keys(this.regionsToUpdateLayout).length) {
        doUpdateRegionsPlanLayouts(Object.values(this.regionsToUpdateLayout), id);
        this.regionsToUpdateLayout = {};
      }

      if (Object.keys(this.devicesToUpdateLayout).length) {
        doUpdateDevicesPlanLayouts(Object.values(this.devicesToUpdateLayout), id);
        this.devicesToUpdateLayout = {};
      }

      const plans = { ...currentPlan };
      let isNeedToUpdatePlan = false;
      if (Object.keys(this.objectsToUpdateLayout.backgrounds).length) {
        isNeedToUpdatePlan = true;
        plans.backgrounds = plan.backgrounds.map(background =>
          this.objectsToUpdateLayout.backgrounds[background.id]
            ? this.objectsToUpdateLayout.backgrounds[background.id]
            : background
        );
      }
      if (Object.keys(this.objectsToUpdateLayout.texts).length) {
        isNeedToUpdatePlan = true;
        plans.texts = plan.texts.map(text =>
          this.objectsToUpdateLayout.texts[text.id]
            ? this.objectsToUpdateLayout.texts[text.id]
            : text
        );
      }

      if (isNeedToUpdatePlan) doUpdatePlan(plans, id);
      this.objectsToUpdateLayout = { backgrounds: {}, texts: {} };
    }
  };

  resetPlanDeviceModifications = target => {
    if (!this.lastSelectedDevicePosition || !target) return;
    target.set({
      top: this.lastSelectedDevicePosition.y,
      left: this.lastSelectedDevicePosition.x
    });
    target.setCoords();
  };

  // TODO: Объединить функции определения зоны для перемещения и размещения устройства
  checkPlanDeviceIntersections = (device, options, isGroupModifying = false) => {
    const { target } = options;
    if (!target) return;
    // Получаем зоны, с которыми устройство пересекается в текущем месте
    const regionsIntersectionsWithDevice = getTargetIntersectionsWithObjects(this.invisibleObject, [
      ...this.regionsGroup.values()
    ]);
    const intersectionsCount = regionsIntersectionsWithDevice.length;

    // Если устройство добавляется в пустое место плана
    if (!intersectionsCount) {
      // Если привязано к зоне, то спрашиваем про отвязку
      if (device.regionId) {
        // При групповом перемещении отвязываем без подтверждения
        if (isGroupModifying) {
          this.deleteDeviceRegion(device, isGroupModifying);
          this.updatePlanDevice(target, () => {}, isGroupModifying);
          target.info.regionId = null;
        } else {
          this.setState({
            draggableDeviceData: {
              device,
              target
            }
          });
          this.openForm('deleteDeviceRegion');
        }
      }
      // Если не привязано к зоне, то добавляем его на план
      else {
        this.updatePlanDevice(target, () => {}, isGroupModifying);
        if (isGroupModifying) target.info.regionId = null;
      }
    }
    // Если устройство добавляется в место, где есть зона(ы)
    else {
      // Если привязано к зоне
      if (device.regionId) {
        // Ищем зону, к которой привязано устройство
        const hasIntersectionsWithDeviceRegion = regionsIntersectionsWithDevice.find(
          planRegion => planRegion.info.regionId === device.regionId
        );
        // Если есть зона, к которой привязано устройство
        if (hasIntersectionsWithDeviceRegion) {
          // Размещаем устройство на плане
          this.updatePlanDevice(target, () => {}, isGroupModifying);
        }
        // Если нет зоны, к которой привязано устройство
        else {
          // Ищем зоны, к которым можно привязать устройство
          const availableRegionBySubsystemIds = [];
          const availableRegionForReplaceIds = [];
          const availableRegions = regionsIntersectionsWithDevice.filter(region => {
            if (region.info.subsystem === device.subsystem) {
              if (!availableRegionBySubsystemIds.includes(region.info.regionId))
                availableRegionBySubsystemIds.push(region.info.regionId);
              return true;
            } else {
              const devices = Array.from(this.planDevicesMap.values()).filter(
                dev => dev.info.regionId === region.info.regionId
              );
              if (!getTargetIntersectionsWithObjects(region, devices).length) {
                if (!availableRegionForReplaceIds.includes(region.info.regionId))
                  availableRegionForReplaceIds.push(region.info.regionId);
                return true;
              } else return false;
            }
          });
          const availableRegionsCount =
            availableRegionBySubsystemIds.length + availableRegionForReplaceIds.length;
          // Если нет доступных зон
          if (!availableRegionsCount) {
            if (isGroupModifying) {
              if (target) this.updatePlanDevice(target, () => {}, isGroupModifying);
            } else {
              this.setState(
                {
                  draggableDeviceData: {
                    device,
                    target
                  }
                },
                () => {
                  this.openForm('forceDropDevice');
                }
              );
            }
          } else if (availableRegionsCount > 0) {
            // Если есть одна доступная зона для привязки
            const availableSameSystemRegion = availableRegions.find(
              region => region.info.regionId && region.info.subsystem === device.subsystem
            );
            const isAnySameSubsystemRegion = availableSameSystemRegion ? true : false;
            if (isAnySameSubsystemRegion) {
              if (availableRegionsCount === 1) {
                if (isGroupModifying) {
                  this.updateDeviceRegion(
                    device,
                    availableRegionBySubsystemIds[0],
                    isGroupModifying
                  );
                  this.updatePlanDevice(target, () => {}, isGroupModifying);
                  target.info.regionId = availableRegionBySubsystemIds[0];
                } else {
                  this.setState({
                    draggableDeviceData: {
                      device,
                      regionIds: availableRegionBySubsystemIds,
                      target
                    }
                  });
                  this.openForm('changeDeviceRegion');
                }
              }
              // Если больше одной
              else if (availableRegionsCount > 1) {
                if (isGroupModifying) {
                  this.updateDeviceRegion(
                    device,
                    availableRegionBySubsystemIds[0],
                    isGroupModifying
                  );
                  this.updatePlanDevice(target, () => {}, isGroupModifying);
                  target.info.regionId = availableRegionBySubsystemIds[0];
                } else {
                  this.setState({
                    draggableDeviceData: {
                      device,
                      regionIds: availableRegionForReplaceIds.concat(availableRegionBySubsystemIds),
                      availableRegions,
                      target
                    }
                  });
                  this.openForm('selectDeviceRegion');
                }
              }
            } else {
              if (isGroupModifying) {
                this.deleteDeviceRegion(device, isGroupModifying);
                this.updatePlanDevice(target, () => {}, isGroupModifying);
                target.info.regionId = null;
              } else {
                this.dropDraggableDevice(target);
                message.error(i18next.t('errors.deviceInZoneWithDifferentSubsystem'));
              }
            }
          }
        }
      }
      // Если не привязано к зоне
      else {
        // Ищем зоны, к которым можно привязать устройство
        const availableRegionIds = [];
        const availableRegions = regionsIntersectionsWithDevice.filter(region => {
          if (region.info.subsystem === device.subsystem) {
            if (!availableRegionIds.includes(region.info.regionId))
              availableRegionIds.push(region.info.regionId);
            return true;
          } else return false;
        });
        const availableRegionsCount = availableRegionIds.length;
        // Если нет доступных зон
        if (availableRegionsCount === 0) {
          if (isGroupModifying) {
            if (target) this.updatePlanDevice(target, () => {}, isGroupModifying);
          } else {
            this.dropDraggableDevice(target);
            message.error(i18next.t('errors.deviceInZoneWithDifferentSubsystem'));
          }
        } else if (availableRegionsCount > 0) {
          const availableSameSystemRegion = availableRegions.find(
            region => region.info.regionId && region.info.subsystem === device.subsystem
          );
          const isAnySameSubsystemRegion = availableSameSystemRegion ? true : false;
          if (isAnySameSubsystemRegion) {
            // Если есть одна доступная зона для привязки
            if (availableRegionsCount === 1) {
              if (isGroupModifying) {
                this.updateDeviceRegion(
                  device,
                  availableRegions[0].info.regionId,
                  isGroupModifying
                );
                this.updatePlanDevice(target, () => {}, isGroupModifying);
                target.info.regionId = availableRegions[0].info.regionId;
              } else {
                const callback = () => {
                  this.updateDeviceRegion(device, availableRegions[0].info.regionId);
                };
                this.updatePlanDevice(target, callback);
              }
            }
            // Если есть больше одной доступной зоны для привязки
            else if (availableRegionsCount > 1) {
              // Если группа, то привязываем к старшей (самой старой) зоне
              if (isGroupModifying) {
                this.updateDeviceRegion(device, availableRegionIds[0], isGroupModifying);
                this.updatePlanDevice(target, () => {}, isGroupModifying);
                target.info.regionId = availableRegionIds[0];
              } else {
                this.setState({
                  draggableDeviceData: {
                    device,
                    regionIds: availableRegionIds,
                    availableRegions,
                    target
                  }
                });
                this.openForm('selectDeviceRegion');
              }
            }
          } else {
            if (isGroupModifying) {
              this.deleteDeviceRegion(device, isGroupModifying);
              this.updatePlanDevice(target, () => {}, isGroupModifying);
              target.info.regionId = null;
            } else {
              this.dropDraggableDevice(target);
              message.error(i18next.t('errors.deviceInZoneWithDifferentSubsystem'));
            }
          }
        }
      }
    }
  };

  checkNewPlanDeviceIntersections = (device, options) => {
    // Получаем зоны, с которыми устройство пересекается в текущем месте
    const regionsIntersectionsWithDevice = getTargetIntersectionsWithObjects(
      this.invisibleObject,
      this.state.fabricCanvas.getObjects().filter(o => o.info && o.info.regionKey)
    );
    const intersectionsCount = regionsIntersectionsWithDevice.length;

    // Если устройство добавляется в пустое место плана
    if (!intersectionsCount) {
      // Если привязано к зоне, то спрашиваем про отвязку
      if (device.regionId) {
        this.setState({
          draggableDeviceData: {
            device,
            options
          }
        });
        this.openForm('deleteDeviceRegion');
      }
      // Если не привязано к зоне, то добавляем его на план
      else this.createPlanDevice(options);
    }
    // Если устройство добавляется в место, где есть зона(ы)
    else {
      // Если привязано к зоне
      if (device.regionId) {
        // Ищем зону, к которой привязано устройство
        const hasIntersectionsWithDeviceRegion = regionsIntersectionsWithDevice.find(
          planRegion => planRegion.info.regionId === device.regionId
        );
        // Если есть зона, которой привязано устройство
        if (hasIntersectionsWithDeviceRegion) {
          // Размещаем устройство на плане
          this.createPlanDevice(options);
        }
        // Если нет зоны, к которой привязано устройство
        else {
          // Ищем зоны, к которым можно привязать устройство
          const availableRegionBySubsystemIds = [];
          const availableRegionForReplaceIds = [];
          const availableRegions = regionsIntersectionsWithDevice.filter(region => {
            if (region.info.subsystem === device.subsystem) {
              if (!availableRegionBySubsystemIds.includes(region.info.regionId))
                availableRegionBySubsystemIds.push(region.info.regionId);
              return true;
            } else {
              const devices = Array.from(this.planDevicesMap.values()).filter(
                dev => dev.info.regionId === region.info.regionId
              );
              if (!getTargetIntersectionsWithObjects(region, devices).length) {
                if (!availableRegionForReplaceIds.includes(region.info.regionId))
                  availableRegionForReplaceIds.push(region.info.regionId);
                return true;
              } else return false;
            }
          });
          const availableRegionsCount =
            availableRegionBySubsystemIds.length + availableRegionForReplaceIds.length;
          // Если нет доступных зон
          if (!availableRegionsCount) {
            this.setState(
              {
                draggableDeviceData: {
                  device,
                  options
                }
              },
              () => {
                this.openForm('forceDropDevice');
              }
            );
          } else if (availableRegionsCount > 0) {
            const availableSameSystemRegion = availableRegions.find(
              region => region.info.regionId && region.info.subsystem === device.subsystem
            );
            const isAnySameSubsystemRegion = availableSameSystemRegion ? true : false;
            // Если подсистема совпадает
            if (isAnySameSubsystemRegion) {
              // Если есть одна доступная зона для привязки
              if (availableRegionsCount === 1) {
                this.setState({
                  draggableDeviceData: {
                    device,
                    regionIds: availableRegionBySubsystemIds,
                    options
                  }
                });
                this.openForm('changeDeviceRegion');
              }
              // Если больше одной
              else if (availableRegionsCount > 1) {
                this.setState({
                  draggableDeviceData: {
                    device,
                    regionIds: availableRegionForReplaceIds.concat(availableRegionBySubsystemIds),
                    availableRegions,
                    options
                  }
                });
                this.openForm('selectDeviceRegion');
              }
              //Если подсистема не совпадает
            } else {
              this.dropDraggableDevice();
              message.error(i18next.t('errors.deviceInZoneWithDifferentSubsystem'));
            }
          }
        }
      }
      // Если не привязано к зоне
      else {
        // Ищем зоны, к которым можно привязать устройство
        const availableRegionIds = [];
        const availableRegions = regionsIntersectionsWithDevice.filter(region => {
          if (region.info.subsystem === device.subsystem) {
            if (!availableRegionIds.includes(region.info.regionId))
              availableRegionIds.push(region.info.regionId);
            return true;
          } else return false;
        });
        const availableRegionsCount = availableRegionIds.length;
        // Если нет доступных зон
        if (availableRegionsCount === 0) {
          this.dropDraggableDevice();
          message.error(i18next.t('errors.deviceInZoneWithDifferentSubsystem'));
        } else if (availableRegionsCount > 0) {
          const availableSameSystemRegion = availableRegions.find(
            region => region.info.regionId && region.info.subsystem === device.subsystem
          );
          const isAnySameSubsystemRegion = availableSameSystemRegion ? true : false;
          // Если подсистема совпадает
          if (isAnySameSubsystemRegion) {
            // Если есть одна доступная зона для привязки
            if (availableRegionsCount === 1) {
              this.createPlanDevice(options);
              this.updateDeviceRegion(device, availableRegions[0].info.regionId);
            }
            // Если есть больше одной зоны для привязки
            else if (availableRegionsCount > 1) {
              this.setState({
                draggableDeviceData: {
                  device,
                  regionIds: availableRegionIds,
                  availableRegions,
                  options
                }
              });
              this.openForm('selectDeviceRegion');
            }
          }
        } else {
          this.dropDraggableDevice();
          message.error(i18next.t('errors.deviceInZoneWithDifferentSubsystem'));
        }
      }
    }
  };

  dropDraggableDevice = (customTarget = undefined) => {
    const {
      draggableDeviceData: { target }
    } = this.state;
    let resultTarget = target ? target : customTarget;
    if (resultTarget) {
      this.resetPlanDeviceModifications(resultTarget);
    } else {
      const { doUpdateDraggableDevice } = this.props;
      doUpdateDraggableDevice(null);
    }
    this.setState({ draggableDeviceData: {} });
  };

  objMoving = options => {
    const { target } = options;
    if (this.isOnlyFrameCreation) this.isOnlyFrameCreation = false;
    const { pinToGrid, gridSize } = this.state;
    const { currentPlan } = this.props;
    if (options.e.shiftKey && !this.currentObjectDragDirection) {
      if (Math.abs(options.e.movementX) > Math.abs(options.e.movementY)) {
        this.currentObjectDragDirection = 'horizontal';
      } else {
        this.currentObjectDragDirection = 'vertical';
      }
    }
    switch (this.currentObjectDragDirection) {
      case 'horizontal': {
        target.set({
          top: this.currentObjectOffsets.top,
          left: getObjLeftPosition(target, currentPlan, pinToGrid, gridSize)
        });
        break;
      }
      case 'vertical': {
        target.set({
          top: getObjTopPosition(target, currentPlan, pinToGrid, gridSize),
          left: this.currentObjectOffsets.left
        });
        break;
      }
      default: {
        target.set({
          top: getObjTopPosition(target, currentPlan, pinToGrid, gridSize),
          left: getObjLeftPosition(target, currentPlan, pinToGrid, gridSize)
        });
        break;
      }
    }
  };

  setActiveTool = tool => {
    const { key, selectable, type } = tool;
    const { fabricCanvas, isChecked } = this.state;

    switch (type) {
      case 'flag': {
        this.setState({
          isChecked: isChecked.includes(key)
            ? isChecked.filter(item => item !== key)
            : [...isChecked, key],
          [key]: !this.state[key]
        });
        return;
      }
      case 'button': {
        if (!selectable) this.toolActions(key);
        else {
          this.clearMoveCanvasInfo(fabricCanvas);
          this.setState({ activeTool: key });
          switch (key) {
            case TOOLBAR_ITEMS.MOVE_CANVAS_BUTTON: {
              canCanvasMove = true;
              fabricCanvas.setCursor(CURSOR_STYLES.MOVE);
              isMoveCanvasButtonActive = true;
              break;
            }
            case TOOLBAR_ITEMS.ADD_TEXT_BUTTON: {
              if (!this.state.isChecked.includes(TOOLBAR_ITEMS.ADD_TEXT_BUTTON)) {
                this.setState({
                  isChecked: [...isChecked, key],
                  [key]: !this.state[key]
                });
              }
              break;
            }
            default:
              break;
          }
          if (
            key !== TOOLBAR_ITEMS.ADD_TEXT_BUTTON &&
            this.state.isChecked.includes(TOOLBAR_ITEMS.ADD_TEXT_BUTTON)
          ) {
            this.setState({
              isChecked: [...isChecked.filter(it => it !== TOOLBAR_ITEMS.ADD_TEXT_BUTTON)],
              [TOOLBAR_ITEMS.ADD_TEXT_BUTTON]: false
            });
          }
        }
        return;
      }
      default:
        return;
    }
  };

  clearMoveCanvasInfo(fabricCanvas) {
    canCanvasMove = false;
    isMoveCanvasButtonActive = false;
    fabricCanvas.setCursor(CURSOR_STYLES.DEFAULT);
  }

  toolActions = key => {
    switch (key) {
      case TOOLBAR_ITEMS.DELETE_PLAN_ELEMENT_BUTTON: {
        this.deletePlanObject();
        break;
      }
      case TOOLBAR_ITEMS.SET_BACKGROUND_BUTTON: {
        this.openForm('newBackground');
        break;
      }
      case TOOLBAR_ITEMS.ZOOM_IN_BUTTON: {
        this.zoomIn();
        break;
      }
      case TOOLBAR_ITEMS.ZOOM_OUT_BUTTON: {
        this.zoomOut();
        break;
      }
      case TOOLBAR_ITEMS.FIT_TO_WINDOW_BUTTON: {
        const { fabricCanvas, plan, inactiveTools, zoomMin } = this.state;
        PlanEditor.fitEditorSize(fabricCanvas, plan);
        const tools = PlanEditor.getZoomInactiveTools(
          fabricCanvas.getZoom(),
          inactiveTools,
          zoomMin
        );
        if (tools) this.setState({ inactiveTools: tools });
        break;
      }
      case TOOLBAR_ITEMS.MOVE_CANVAS_BUTTON: {
        canCanvasMove = true;
        break;
      }
      default:
        break;
    }
  };

  changeSelect = (tool, value) => {
    switch (tool.key) {
      case TOOLBAR_ITEMS.GRID_SHOW_FLAG: {
        const gridSize = parseInt(value, 10);
        this.setState({ gridSize });
        break;
      }
      case TOOLBAR_ITEMS.ADD_TEXT_BUTTON: {
        this.setState({ textConfigValues: value });
        break;
      }
      default:
        break;
    }
  };

  init = (props, fabricCanvas) => {
    const { regions, devices, currentPlan } = props;
    this.resetPolygonData(true, true, fabricCanvas);
    fabricCanvas.initialize(this.editor.current, {
      height: this.pane.current.offsetHeight,
      width: this.pane.current.offsetWidth,
      backgroundVpt: false,
      preserveObjectStacking: true
    });

    fabricCanvas.discardActiveObject();

    this.drawWorkSpace(fabricCanvas, currentPlan);
    if (currentPlan.backgrounds) {
      const notChanged = currentPlan.backgrounds.every(b => !!this.backgroundsMap.get(b.id));
      if (notChanged) {
        this.drawBackgrounds(fabricCanvas, currentPlan);
      } else {
        this.backgroundsMap.clear();
        this.loadBackgrounds(this.props.project, currentPlan, fabricCanvas);
      }
    }
    if (regions) this.drawRegions(fabricCanvas, regions, currentPlan);
    if (devices) this.drawDevices(fabricCanvas, devices, currentPlan);
    fabricCanvas.renderAll();
  };

  static initZoom(canvas, currentPlan, planConfig) {
    if (planConfig.zoom > 0) {
      const zoom = planConfig.zoom;
      canvas.zoomToPoint({ x: 0, y: 0 }, zoom);
      canvas.viewportTransform[4] = planConfig.x;
      canvas.viewportTransform[5] = planConfig.y;
      PlanEditor.updateObjectsCoords(canvas);
      PlanEditor.scaleCanvasObjects(canvas);
    } else {
      PlanEditor.fitEditorSize(canvas, currentPlan);
    }
  }

  getConfigZoom = () => {
    const { fabricCanvas, pinToGrid, isShowGrid, gridSize } = this.state;
    return {
      pinToGrid,
      isShowGrid,
      gridSize,
      zoom: fabricCanvas.getZoom(),
      x: fabricCanvas.viewportTransform[4],
      y: fabricCanvas.viewportTransform[5]
    };
  };

  static calculateMinZoom(canvas, plan) {
    const startZoom = PlanEditor.getStartZoom(canvas, plan);
    let mouseDelta = ZOOM.MOUSE_WHEEL_DELTA * (ZOOM.MIN / startZoom);
    return { zoomMin: startZoom, mouseZoom: mouseDelta };
  }

  static getStartZoom(canvas, plan) {
    const delta1 = canvas.width / (plan.xSize * 5);
    const delta2 = canvas.height / (plan.ySize * 5);
    return delta1 < delta2 ? delta1 : delta2;
  }

  getGridObject(fabricCanvas) {
    return fabricCanvas
      .getObjects()
      .find(object => object.info && object.info.gridKey === PlanObjects.GRID.key);
  }

  drawWorkSpace = (fabricCanvas, currentPlan) => {
    const { xSize, ySize } = currentPlan;
    const downLayer = new Rect({
      left: -3,
      top: 3,
      width: xSize,
      height: ySize,
      fill: 'white',
      hoverCursor: CURSOR_STYLES.DEFAULT,
      selectable: false,
      hasControls: false,
      type: WORK_SPACE
    });

    downLayer.set(
      'shadow',
      new fabric.Shadow({
        color: '#000000',
        blur: 30,
        offsetX: -5,
        offsetY: 5,
        opacity: 0.8
      })
    );

    fabricCanvas.add(downLayer);
    downLayer.moveTo(PlanObjects.WORKSPACE.layer);
  };

  /**
   * Функции отрисовки и отображения popup-а
   **/
  getPopupText = info => {
    let text;
    if (info.regionKey) text = `${info.regionIndex}. ${info.name}`;
    else if (info.deviceKey) text = `${info.name} (${info.addressPath})`;
    return text;
  };

  showPopup = options => {
    const { activeTool } = this.state;
    const { target } = options;
    if (
      this.popupEl.current &&
      target &&
      target.info &&
      activeTool === TOOLBAR_ITEMS.SELECT_ELEMENTS_BUTTON
    ) {
      const popupText = this.getPopupText(target.info);
      if (!popupText) return;
      this.popupEl.current.innerText = popupText;
      this.popupEl.current.style.display = 'block';
      const xPos = Number.parseInt(this.popupEl.current.style.left.split('px')[0], 10);
      const popupWidth = this.popupEl.current.offsetWidth;
      // Проверяем выходит ли popup за пределы экрана, если да, то отображаем его слева от курсора
      if (xPos + popupWidth > this.screen.width) {
        this.popupEl.current.style.left = xPos - 40 - popupWidth + 'px';
      }
    }
  };

  hidePopup = options => {
    if (this.popupEl.current) {
      this.popupEl.current.style.display = 'none';
    }
  };

  drawBackgrounds = (fabricCanvas, currentPlan) => {
    if (currentPlan.backgrounds.length) {
      currentPlan.backgrounds.forEach((background, index) => {
        this.buildBackgroundInfo(background, currentPlan, index);
        this.drawBackground(fabricCanvas, background, index, currentPlan);
      });
    }
  };

  drawBackground = (fabricCanvas, background, layer, currentPlan) => {
    const { topLeftPoint, info, scaleX, scaleY } = background;
    const containerData = {
      ...defaultFields,
      opacity: 1,
      left: topLeftPoint.x,
      top: topLeftPoint.y,
      info: info,
      scaleX,
      scaleY,
      hoverCursor: CURSOR_STYLES.DEFAULT
    };
    // Проверка на дубликат, если слой не успел очиститься или еще отрисовывается старый
    const duplicate = fabricCanvas
      .getObjects()
      .find(object => object.info && object.info.backgroundKey === info.backgroundKey);
    if (duplicate) fabricCanvas.remove(duplicate);
    this.drawImage(fabricCanvas, currentPlan, background, containerData, layer);
  };

  updateBackgrounds = (fabricCanvas, currentPlan) => {
    const backgroundsWillDelete = new Map(this.backgroundsMap);

    currentPlan.backgrounds.forEach((background, index) => {
      const object = this.findBackgroundById(fabricCanvas, background.id);
      this.buildBackgroundInfo(background, currentPlan, index);
      if (object) this.updateBackground(object, background, currentPlan, index);
      else this.drawBackground(fabricCanvas, background, index, currentPlan);
      backgroundsWillDelete.delete(background.id);
    });

    backgroundsWillDelete.forEach(background => {
      const bg = this.findBackgroundById(fabricCanvas, background.id);
      if (bg) fabricCanvas.remove(bg);
    });
  };

  buildBackgroundInfo(background, currentPlan, index) {
    background.info = {
      backgroundKey: background.id,
      isFirst: index === 0,
      isLast: index === currentPlan.backgrounds.length - 1,
      isOne: currentPlan.backgrounds.length === 1
    };
  }

  findBackgroundById(fabricCanvas, id) {
    return fabricCanvas.getObjects().find(obj => obj.info && obj.info.backgroundKey === id);
  }

  drawImage(fabricCanvas, currentPlan, background, containerData, layer) {
    const image = this.backgroundsMap.get(background.id);
    if (!image) {
      this.createImgLoadingSkeleton(fabricCanvas, background, layer, containerData);
      return;
    }
    const content = this.getImageUrl(image);
    fabric.Image.fromURL(content, img => {
      const scale = this.getImageScaleAccordingToPlan(currentPlan, img);
      // Если размеры изображения не заданы, то масштабируем под размер плана
      // Для старых типов фоновых рисунков
      if (background.width === 0 || background.height === 0) {
        containerData.scaleY = scale;
        containerData.scaleX = scale;
        background.scaleY = scale;
        background.scaleX = scale;
        background.width = img.width;
        background.height = img.height;
      }
      const imgLink = img.set({
        ...containerData
      });
      imgLink.setControlsVisibility({ mtr: false });
      fabricCanvas.add(imgLink);
      imgLink.moveTo(PlanObjects.BACKGROUND.layer + layer);
      this.checkEditable(imgLink);
    });
  }

  getImageScaleAccordingToPlan(currentPlan, img) {
    const scaleX = currentPlan.xSize / img.width;
    const scaleY = currentPlan.ySize / img.height;
    return scaleX > scaleY ? scaleY : scaleX;
  }

  createImgLoadingSkeleton(fabricCanvas, background, layer, data) {
    const options = {
      ...data,
      width: background.width * background.scaleX,
      height: background.height * background.scaleY,
      scaleX: 1,
      scaleY: 1,
      noScaleCache: true,
      lockScalingX: true,
      lockScalingY: true,
      lockUniScaling: true,
      objectCaching: false,
      stroke: 'black',
      strokeWidth: 1,
      fill: 'gray',
      opacity: 0.2,
      selectable: false,
      hasControls: false
    };
    const rect = new Rect(options);

    const url = this.getImageUrl({
      dataFormat: 'raw',
      type: 'image/svg+xml',
      content: getClockIcon(options.width, options.height)
    });
    fabric.Image.fromURL(url, (img, isError) => {
      if (!isError) {
        img.set({ ...options, visible: true, opacity: 0.4 });
        img.moveTo(PlanObjects.BACKGROUND.layer + layer);
        const container = new Group([rect, img], { info: options.info });
        fabricCanvas.add(container);
        container.moveTo(PlanObjects.BACKGROUND.layer + layer);
      }
    });
  }

  getImageUrl(image) {
    if (image.dataFormat === 'raw' && image.type === 'image/svg+xml') {
      const base64 = getBase64FromSvgString(image.content);
      return `data:image/svg+xml;base64,${base64}`;
    } else {
      return `data:${image.type};${image.dataFormat},${image.content}`;
    }
  }

  updateBackground = (canvasImg, newBackground, currentPlan, layer) => {
    let { topLeftPoint, scaleX, scaleY } = newBackground;
    const { fabricCanvas } = this.state;
    if (!canvasImg || canvasImg.info.backgroundKey !== newBackground.id) {
      if (canvasImg) fabricCanvas.remove(canvasImg);
      this.drawBackground(fabricCanvas, newBackground, layer, currentPlan);
    } else if (canvasImg && canvasImg.info.backgroundKey === newBackground.id) {
      let scale = 0;
      if (newBackground.width === 0 || newBackground.height === 0) {
        scale = this.getImageScaleAccordingToPlan(currentPlan, canvasImg);
      }
      const { left, top } = this.getObjectOffsets(topLeftPoint.x, topLeftPoint.y, canvasImg);

      canvasImg.set({
        top,
        left,
        info: newBackground.info
      });
      !canvasImg.group &&
        canvasImg.set({
          scaleX: scale ? scale : scaleX,
          scaleY: scale ? scale : scaleY
        });
      canvasImg.moveTo(PlanObjects.BACKGROUND.layer + layer);
    }
  };

  /**
   * Функции отрисовки зон
   **/
  getPlanRegions = (regions, currentPlanId) => {
    const newRegionsGroup = new Map();
    regions.forEach(regionView => {
      const { region } = regionView;
      regionView.planLayouts.forEach(layout => {
        if (layout.planId === currentPlanId) {
          const regionKey = layout.id;
          newRegionsGroup.set(regionKey, {
            ...layout,
            info: {
              ...layout,
              regionId: regionView.id,
              subsystem: region.subsystem,
              projectId: region.projectId,
              name: region.name,
              regionIndex: region.index,
              regionKey
            }
          });
        }
      });
    });
    return newRegionsGroup;
  };

  drawRegions = (fabricCanvas, regions, currentPlan) => {
    const regionsGroup = this.getPlanRegions(regions, currentPlan.id);
    regionsGroup.forEach(region => {
      this.drawRegion(fabricCanvas, region, currentPlan);
    });
    this.regionsGroup = regionsGroup;
  };

  drawRegion = (fabricCanvas, region) => {
    const { points } = region;
    const { fontFamily } = this.state.textConfigValues;
    const fontScale = 1.4;
    let minX = fabric.util.array.min(points, 'x');
    let minY = fabric.util.array.min(points, 'y');

    // создание зоны с названием через Subclassing (fabric.util.createClass) http://fabricjs.com/docs/fabric.util.html#.createClass

    const NamedPolygon = fabric.util.createClass(Polygon, {
      initialize: function(options) {
        options || (options = {});

        this.callSuper('initialize', options);
        this.set('label', options.label || '');
        this.set('textOffset', options.textOffset || { x: 0, y: 0 });
      },

      toObject: function() {
        return fabric.util.object.extend(this.callSuper('toObject'), {
          label: this.get('label')
        });
      },

      _render: function(canvasContext) {
        this.callSuper('_render', canvasContext);
        const fontSize = this.width / this.label.length;
        canvasContext.font = `${fontSize * fontScale}px ${fontFamily}`;
        canvasContext.fillStyle = '#ffffff';
        canvasContext.fillText(this.label, -this.textOffset.x, this.textOffset.y);
      }
    });
    const newRegion = new NamedPolygon([...points], {
      left: minX,
      top: minY
    });

    const textPrototype = new Text(region.info.name, {
      fontFamily: `${fontFamily}`,
      fontSize: `${(newRegion.width / region.info.name.length) * fontScale}`
    });

    newRegion.set({
      ...polygonFields,
      fill: SUBSYSTEMS[region.info.subsystem].color,
      info: region.info,
      label: `${region.info.name}`,
      textOffset: { x: textPrototype.width / 2, y: textPrototype.height / 4 }
    });
    newRegion.setCoords();
    this.checkEditable(newRegion);
    fabricCanvas.add(newRegion);
    fabricCanvas.moveTo(newRegion, PlanObjects.REGION.layer);
  };

  updateRegions = (canvas, newRegionsGroup) => {
    const lastRegions = new Map(newRegionsGroup);
    const needDelete = [];
    canvas.getObjects().forEach(obj => {
      if (this.isRegion(obj)) {
        const newRegion = newRegionsGroup.get(obj.info.regionKey);
        if (newRegion) this.updateRegion(newRegion, obj);
        else needDelete.push(obj);
        lastRegions.delete(obj.info.regionKey);
      }
    });
    needDelete.forEach(obj => canvas.remove(obj));
    lastRegions.forEach(obj => {
      this.drawRegion(canvas, obj);
    });
    this.regionsGroup = newRegionsGroup;
  };

  getObjectOffsets = (left, top, target) => {
    if (target.group) {
      const { groupLeftOffset, groupTopOffset } = this.getTargetInGroupOffset(target);
      left -= groupLeftOffset;
      top -= groupTopOffset;
      if (this.selectedGroupScales) {
        const { scaleLeftOffset, scaleTopOffset } = this.getTargetOffsetAfterScale(target);
        left -= scaleLeftOffset;
        top -= scaleTopOffset;
      }
    }
    return { left, top };
  };

  updateRegion = (newRegion, canvasRegion) => {
    const {
      points,
      info,
      info: { name }
    } = newRegion;
    const minLeftPoint = fabric.util.array.min(points, 'x');
    const minTopPoint = fabric.util.array.min(points, 'y');
    const { left, top } = this.getObjectOffsets(minLeftPoint, minTopPoint, canvasRegion);
    canvasRegion.set({ left, top, label: name, info });
  };

  /**
   * Функции отрисовки устройств
   **/

  getPlanDevices = (devices, currentPlanId) => {
    let newDevicesGroup = new Map();
    devices.forEach(deviceView => {
      if (!deviceView.planLayouts) return;
      deviceView.planLayouts.forEach(layout => {
        if (layout.planId === currentPlanId) {
          const deviceKey = layout.id;
          newDevicesGroup.set(deviceKey, {
            ...layout,
            info: {
              planId: layout.planId,
              name: deviceView.name,
              deviceKey,
              deviceId: deviceView.id,
              addressPath: deviceView.shortAddressPath,
              attachableToRegion: deviceView.attachableToRegion,
              regionId: deviceView.regionId,
              profileId: deviceView.deviceProfileId
            },
            texture: deviceView.textureMedia
          });
        }
      });
    });
    return newDevicesGroup;
  };

  drawDevices = (fabricCanvas, devices, currentPlan) => {
    this.devicesGroup = this.getPlanDevices(devices, currentPlan.id);
    this.devicesGroup.forEach(device => {
      this.drawDevice(fabricCanvas, device, currentPlan);
    });
  };

  drawDevice = (fabricCanvas, device, currentPlan) => {
    const { coordinatePoint, texture, info } = device;
    const invertedZoom = 1 / fabricCanvas.getZoom();
    const shape = new Rect({
      width: DEVICE_ICON_STANDARD_SIZE,
      height: DEVICE_ICON_STANDARD_SIZE,
      noScaleCache: true,
      lockScalingX: true,
      lockScalingY: true,
      perPixelTargetFind: true,
      lockUniScaling: true,
      objectCaching: false,
      stroke: 'black',
      strokeWidth: 2,
      fill: 'white'
    });
    const containerData = {
      noScaleCache: true,
      lockScalingX: true,
      lockScalingY: true,
      lockUniScaling: true,
      objectCaching: false,
      left: coordinatePoint.x,
      top: coordinatePoint.y,
      scaleX: DEVICE_ICON_STANDARD_SCALE * invertedZoom,
      scaleY: DEVICE_ICON_STANDARD_SCALE * invertedZoom,
      info: info,
      hasControls: false,
      borderColor: DEFAULT.SELECTED_STROKE_COLOR,
      borderScaleFactor: DEFAULT.SELECT_STROKE_WIDTH,
      originX: 'center',
      originY: 'center'
    };
    if (texture && texture.mediaType === 'SVG_CONTENT') {
      fabric.loadSVGFromString(texture.content, (objects, options) => {
        const renderer = [];
        const picture = fabric.util.groupSVGElements(objects, options);
        if (picture._objects) {
          shape.set({ top: -25, left: -25 });
          picture.forEachObject(object => object.set({ stroke: 'black', zIndex: 2 }));
          renderer.push(shape, ...picture._objects);
        } else {
          picture.set({
            noScaleCache: true,
            lockScalingX: true,
            lockScalingY: true,
            lockUniScaling: true,
            objectCaching: false,
            fill: 'black',
            stroke: 'black'
          });
          renderer.push(shape, picture);
        }
        if (renderer.length) {
          const container = new Group(renderer, containerData);
          container.type = DEVICE;
          this.checkEditable(container);
          fabricCanvas.add(container);
          container.bringToFront();
        }
      });
    } else {
      const renderer = new Text(info.name.substring(0, 2), {
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontSize: '24',
        fill: 'black',
        left: 8,
        top: 12
      });
      const container = new Group([shape, renderer], containerData);
      container.type = DEVICE;
      this.checkEditable(container);
      fabricCanvas.add(container);
      container.bringToFront();
    }
  };

  updateDevices = (canvas, newDevicesGroup, currentPlan) => {
    const lastDevices = new Map(newDevicesGroup);
    const needDelete = [];
    canvas.getObjects().forEach(obj => {
      if (this.isDevice(obj)) {
        const newDevice = newDevicesGroup.get(obj.info.deviceKey);

        //если профили не совпадают, то текстуру необходимо перерисовать
        if (newDevice && obj.info.profileId === newDevice.info.profileId) {
          lastDevices.delete(obj.info.deviceKey);
        } else if (newDevice) {
          needDelete.push(obj);
          return;
        }

        if (newDevice) this.updateDevice(newDevice, obj);
        else needDelete.push(obj);
      }
    });
    needDelete.forEach(obj => canvas.remove(obj));
    lastDevices.forEach(obj => {
      this.drawDevice(canvas, obj, currentPlan);
    });
    this.devicesGroup = newDevicesGroup;
  };

  updateDevice = (newDevice, updatedDevice) => {
    const { coordinatePoint, info } = newDevice;
    const { left, top } = this.getObjectOffsets(
      coordinatePoint.x,
      coordinatePoint.y,
      updatedDevice
    );
    updatedDevice.set({ top, left, info });
  };

  /**
   * Проверяет файлы изображений, получает размеры и отправляет на сервер
   * @param files - файлы изображений
   */
  createPlanBackground = files => {
    const { modalClose, currentPlan, project, dispatch } = this.props;

    const newBackgrounds = [];

    let index = 0;
    let file = files[index];
    let newBackground = {
      type: file.type,
      dataFormat: file.dataFormat,
      content: file.data
    };

    const callback = () => {
      newBackgrounds.push(newBackground);
      if (index === files.length - 1) {
        const callback = (result, error) => {
          if (error) {
            message.error(i18next.t('errors.loadBackgrounds'));
          } else result.backgrounds.forEach(b => this.backgroundsMap.set(b.id, b));
          modalClose('newBackground');
        };
        dispatch(addPlanBackgrounds(project.id, currentPlan.id, newBackgrounds, callback));
      } else {
        index++;
        file = files[index];
        newBackground = {
          type: file.type,
          dataFormat: file.dataFormat,
          content: file.data
        };
        this.validateImage(currentPlan, newBackground, file, callback);
      }
    };

    this.validateImage(currentPlan, newBackground, file, callback);
  };

  /** Проверяем сможет ли fabric загрузить изображения, заодно получаем размеры */
  validateImage(currentPlan, newBackground, file, callback) {
    const url = this.getImageUrl(newBackground);
    fabric.Image.fromURL(url, (img, isError) => {
      if (isError) {
        message.error(`${i18next.t('errors.loadBackground')}: ${file.name}`);
        callback();
        return;
      }
      newBackground.width = img.width;
      newBackground.height = img.height;
      callback();
    });
  }

  createPlanDevice = (options, callback) => {
    const {
      doUpdateDraggableDevice,
      doUpdateDevicePlanLayouts,
      project,
      currentPlanId,
      devicesHash
    } = this.props;
    let { draggableDeviceId } = this.props;
    const { fabricCanvas, pinToGrid, draggableDeviceData } = this.state;
    if (!draggableDeviceId && draggableDeviceData.device) {
      draggableDeviceId = draggableDeviceData.device.id;
    }
    fabricCanvas.discardActiveObject();

    const _mouse = this._getPointer(options.e, pinToGrid);
    const newLayout = {
      planId: currentPlanId,
      coordinatePoint: { x: _mouse.x, y: _mouse.y }
    };

    const updatedDeviceView = devicesHash[draggableDeviceId];
    updatedDeviceView.planLayouts.push(newLayout);
    doUpdateDraggableDevice(null);
    if (!callback) this.setState({ draggableDeviceData: {} });
    doUpdateDevicePlanLayouts(
      project.id,
      draggableDeviceId,
      updatedDeviceView.planLayouts,
      callback
    );
  };

  createPlanRegion = regionId => {
    const { doUpdateRegionPlanLayouts, currentPlan, regionsHash, project } = this.props;
    const regionView = regionsHash[regionId];
    const newLayout = {
      planId: currentPlan.id,
      points: filterSamePoints(this.polygonPoints)
    };
    const planLayouts = [...regionView.planLayouts, newLayout];
    doUpdateRegionPlanLayouts(project.id, regionView.id, planLayouts);
    this.regionController.current.closePlanRegionModal();
  };

  /**
   * Функции обновления объектов плана
   **/
  updatePlanBackground = (target, isGroupModifying) => {
    const { doUpdatePlan, currentPlan, project } = this.props;
    const key = target.info.backgroundKey;
    const plan = { ...currentPlan };
    const updatedBackground = plan.backgrounds.find(b => b.id === key);
    if (updatedBackground) {
      updatedBackground.topLeftPoint = { x: target.left, y: target.top };
      updatedBackground.scaleX = target.scaleX;
      updatedBackground.scaleY = target.scaleY;
      if (isGroupModifying) {
        this.objectsToUpdateLayout.backgrounds[updatedBackground.id] = updatedBackground;
      } else {
        doUpdatePlan(plan, project.id);
      }
    }
  };

  updatePlanRegion = (points, target, isGroupModifying = false) => {
    const { doUpdateRegionPlanLayouts, currentPlan, regionsHash, project } = this.props;
    const uniqKeys = target.info.regionKey;
    const regionView = regionsHash[target.info.regionId];
    const updatedRegionLayout = regionView.planLayouts.find(layout => layout.id === uniqKeys);
    const indexOfUpdatedRegionLayout = regionView.planLayouts.indexOf(updatedRegionLayout);
    regionView.planLayouts[indexOfUpdatedRegionLayout] = {
      planId: currentPlan.id,
      points: points,
      id: uniqKeys
    };
    if (isGroupModifying) {
      this.regionsToUpdateLayout[regionView.id] = {
        regionId: regionView.id,
        regionLayout: regionView.planLayouts
      };
    } else {
      doUpdateRegionPlanLayouts(project.id, regionView.id, regionView.planLayouts);
      this.regionController.current.closePlanRegionModal();
    }
  };

  updatePlanDevice = (target, callback, isGroupModifying = false) => {
    const { doUpdateDevicePlanLayouts, currentPlan, devicesHash, project } = this.props;
    const uniqKeys = target.info.deviceKey;
    const deviceView = devicesHash[target.info.deviceId];
    const deviceLayout = deviceView.planLayouts.find(layout => layout.id === uniqKeys);
    const deviceLayoutIndex = deviceView.planLayouts.indexOf(deviceLayout);
    deviceView.planLayouts[deviceLayoutIndex] = {
      planId: currentPlan.id,
      coordinatePoint: { x: target.left, y: target.top },
      id: uniqKeys
    };
    if (isGroupModifying) {
      this.devicesToUpdateLayout[deviceView.id] = {
        deviceId: deviceView.id,
        deviceLayout: deviceView.planLayouts,
        deviceRevision: deviceView.revision
      };
    } else {
      if (!callback) this.setState({ draggableDeviceData: {} });
      doUpdateDevicePlanLayouts(project.id, deviceView.id, deviceView.planLayouts, callback);
    }
  };

  updateTexts = (target, isGroupModifying = false) => {
    const { currentPlan, project, doUpdatePlanText } = this.props;
    const textObj = getPreparedFromCanvasTextObject(target);
    if (isGroupModifying) this.objectsToUpdateLayout.texts[textObj.id] = textObj;
    else doUpdatePlanText(project.id, currentPlan.id, textObj);
  };

  textChangeHandler = ({ target }) => {
    const { text } = target;
    if (text.length > MAX_TEXT_LENGTH) {
      const limitedText = text.substring(0, MAX_TEXT_LENGTH);
      target.set({ text: limitedText });
      target.info.text = limitedText;
      message.error(
        i18next.t('actionsOnPlan.textBox.characterLimit', { characterLimit: MAX_TEXT_LENGTH })
      );
    }
  };

  /**
   * Функции удаления объектов плана
   **/

  deletePlanObject = () => {
    const { fabricCanvas } = this.state;
    const planObject = fabricCanvas ? fabricCanvas.getActiveObject() : null;
    const modalParams = {
      onCancel: () => {},
      cancelText: i18next.t('buttons.cancel')
    };
    if (!this.modalOpened && planObject && planObject.info && !planObject.isMoving) {
      modalParams.title = i18next.t('deletingObject');
      modalParams.okText = i18next.t('buttons.delete');
      const { info } = planObject;
      this.modalOpened = true;
      if (planObject.info.regionKey) {
        const regionName = `${info.name} (${info.regionIndex})`;
        modalParams['content'] = this.buildDeleteModalContent(regionName);
        modalParams['onOk'] = () => this.deletePlanRegion(planObject);
      } else if (planObject.info.deviceKey) {
        const deviceName = `${info.name} (${info.addressPath ? info.addressPath : ''})`;
        modalParams['content'] = this.buildDeleteModalContent(deviceName);
        modalParams['onOk'] = () => this.deletePlanDevice(planObject);
      } else if (planObject.info.backgroundKey !== undefined) {
        modalParams['content'] = i18next.t('plans.deleteBackgroundFromPlan');
        modalParams['onOk'] = () => this.deletePlanBackground(planObject);
      } else if (planObject.info.textKey !== undefined) {
        modalParams['content'] = i18next.t('plans.deleteTextFromPlan');
        modalParams['onOk'] = () => this.deletePlanText(planObject);
      }
    } else if (
      !this.modalOpened &&
      planObject &&
      planObject.type === ACTIVE_SELECTION &&
      planObject._objects?.length &&
      !planObject.isMoving
    ) {
      const {
        doUpdateRegionsPlanLayouts,
        doUpdateDevicesPlanLayouts,
        doDeletePlanObjects,
        project: { id },
        currentPlan
      } = this.props;
      const { _objects } = planObject;
      const isGroupDeleting = true;
      this.modalOpened = true;
      modalParams.title = i18next.t('deletingObject', { context: 'plural' });
      modalParams.okText = i18next.t('buttons.deleteAll');

      let devicesNumber = 0;
      let regionsNumber = 0;
      let backgroundsNumber = 0;
      let textBoxesNumber = 0;
      _objects.forEach(obj => {
        if (obj.info.regionKey) {
          regionsNumber++;
          this.deletePlanRegion(obj, isGroupDeleting);
        } else if (obj.info.deviceKey) {
          devicesNumber++;
          this.deletePlanDevice(obj, isGroupDeleting);
        } else if (obj.info.backgroundKey) {
          backgroundsNumber++;
          this.deletePlanBackground(obj, isGroupDeleting);
        } else if (obj.info.textKey) {
          textBoxesNumber++;
          this.deletePlanText(obj, isGroupDeleting);
        }
      });
      const deviceMessage = devicesNumber
        ? `${i18next.t('type')}: ${i18next.t('device')} - ${i18next
            .t('quantity')
            .toLowerCase()}: ${devicesNumber}`
        : '';
      const regionMessage = regionsNumber
        ? `${i18next.t('type')}: ${i18next.t('zone')} - ${i18next
            .t('quantity')
            .toLowerCase()}: ${regionsNumber}`
        : '';
      const backgroundMessage = backgroundsNumber
        ? `${i18next.t('type')}: ${i18next.t('image')} - ${i18next
            .t('quantity')
            .toLowerCase()}: ${backgroundsNumber}`
        : '';
      const textsMessage = textBoxesNumber
        ? `${i18next.t('type')}: ${i18next.t('text')} - ${i18next
            .t('quantity')
            .toLowerCase()}: ${textBoxesNumber}`
        : '';

      const resultMessage = (
        <span>
          {deviceMessage + ' '} {regionMessage && deviceMessage ? <br /> : ''} {regionMessage}
          {regionMessage || deviceMessage ? <br /> : ''}
          {backgroundMessage}
          {regionMessage || deviceMessage || textsMessage ? <br /> : ''}
          {textsMessage}
        </span>
      );

      modalParams['onOk'] = () => {
        if (Object.keys(this.regionsToDeleteFromPlan).length)
          doUpdateRegionsPlanLayouts(Object.values(this.regionsToDeleteFromPlan), id);

        if (Object.keys(this.devicesToDeleteFromPlan).length)
          doUpdateDevicesPlanLayouts(Object.values(this.devicesToDeleteFromPlan), id);

        if (
          this.objectsToDeleteFromPlan.texts.length ||
          this.objectsToDeleteFromPlan.backgrounds.length
        )
          doDeletePlanObjects(id, currentPlan.id, this.objectsToDeleteFromPlan);
      };
      modalParams['content'] = this.buildDeleteModalContent(resultMessage);
    }
    if (modalParams['onOk']) {
      modalParams.afterClose = () => {
        this.modalOpened = false;
        this.regionsToDeleteFromPlan = {};
        this.devicesToDeleteFromPlan = {};
        this.objectsToDeleteFromPlan = { texts: [], backgrounds: [] };
        fabricCanvas.discardActiveObject();
        fabricCanvas.renderAll();
      };
      Modal.confirm(modalParams);
    }
  };

  buildDeleteModalContent = text => {
    return (
      <span>
        {i18next.t('buttons.delete')}
        <br /> <b>{text}</b> {i18next.t('plans.fromPlan')}?
      </span>
    );
  };

  deletePlanRegion = (region, isGroupDeleting = false) => {
    const { doUpdateRegionPlanLayouts, regionsHash, project } = this.props;
    const uniqKeys = region.info.regionKey;
    const regionView = regionsHash[region.info.regionId];
    regionView.planLayouts = regionView.planLayouts.filter(layout => {
      if (layout.id !== uniqKeys) return true;
      return false;
    });
    if (isGroupDeleting) {
      this.regionsToDeleteFromPlan[regionView.id] = {
        regionId: regionView.id,
        regionLayout: regionView.planLayouts
      };
    } else doUpdateRegionPlanLayouts(project.id, regionView.id, regionView.planLayouts);
  };

  deletePlanDevice = (target, isGroupDeleting = false) => {
    const { doUpdateDevicePlanLayouts, devicesHash, project } = this.props;
    const uniqKeys = target.info.deviceKey;
    const deviceView = devicesHash[target.info.deviceId];
    deviceView.planLayouts = deviceView.planLayouts.filter(layout => {
      if (layout.id !== uniqKeys) return true;
      return false;
    });
    if (isGroupDeleting) {
      this.devicesToDeleteFromPlan[target.info.deviceId] = {
        deviceId: target.info.deviceId,
        deviceLayout: deviceView.planLayouts,
        deviceRevision: deviceView.revision
      };
    } else doUpdateDevicePlanLayouts(project.id, target.info.deviceId, deviceView.planLayouts);
  };

  deletePlanBackground = (background, isGroupDeleting) => {
    const { doDeletePlanBackground, currentPlan, project } = this.props;
    const key = background.info.backgroundKey;
    const plan = { ...currentPlan };
    const deletingBackground = plan.backgrounds.find(b => b.id === key);
    const index = plan.backgrounds.indexOf(deletingBackground);
    if (index === -1) return;
    if (isGroupDeleting) {
      this.objectsToDeleteFromPlan.backgrounds.push(deletingBackground.id);
    } else {
      plan.backgrounds.splice(index, 1);
      doDeletePlanBackground(project.id, plan.id, key);
    }
  };

  deletePlanText = (text, isGroupDeleting) => {
    const { doDeletePlanText, currentPlan, project } = this.props;
    if (isGroupDeleting) {
      this.objectsToDeleteFromPlan.texts.push(text.info.textKey);
    } else {
      doDeletePlanText(project.id, currentPlan.id, text.info.textKey);
    }
  };

  static centerCoordinates(canvas) {
    const zoom = canvas.getZoom();
    return {
      x: fabric.util.invertTransform(canvas.viewportTransform)[4] + canvas.width / zoom / 2,
      y: fabric.util.invertTransform(canvas.viewportTransform)[5] + canvas.height / zoom / 2
    };
  }

  zoomIn = (byMouseCoordinates = false) => {
    const { fabricCanvas, inactiveTools, zoomMin } = this.state;

    let Coords = { x: fabricCanvas.width / 2, y: fabricCanvas.height / 2 };
    if (byMouseCoordinates && mousePointer) Coords = mousePointer;
    let zoom = fabricCanvas.getZoom() + ZOOM.STEP;
    if (zoom > ZOOM.MAX) zoom = ZOOM.MAX;

    const tools = PlanEditor.getZoomInactiveTools(zoom, inactiveTools, zoomMin);
    if (tools) this.setState({ inactiveTools: tools });

    fabricCanvas.zoomToPoint({ x: Coords.x, y: Coords.y }, zoom);
    PlanEditor.scaleCanvasObjects(fabricCanvas);
  };

  zoomOut = (byMouseCoordinates = false) => {
    const { fabricCanvas, zoomMin, inactiveTools } = this.state;

    let Coords = { x: fabricCanvas.width / 2, y: fabricCanvas.height / 2 };
    if (byMouseCoordinates && mousePointer) Coords = mousePointer;

    let zoom = fabricCanvas.getZoom() - ZOOM.STEP;
    if (zoom < zoomMin) zoom = zoomMin;

    const tools = PlanEditor.getZoomInactiveTools(zoom, inactiveTools, zoomMin);
    if (tools) this.setState({ inactiveTools: tools });

    fabricCanvas.zoomToPoint({ x: Coords.x, y: Coords.y }, zoom);
    PlanEditor.scaleCanvasObjects(fabricCanvas);
  };

  /**
   * Подгоняет холст под размер редактора, оставляя отступы по бокам равные ZOOM.PADDING
   */
  static fitEditorSize(fabricCanvas, currentPlan) {
    const currentWidth = currentPlan.xSize;
    const currentHeight = currentPlan.ySize;
    let diff;
    let Coords = PlanEditor.centerCoordinates(fabricCanvas);
    if (PlanEditor.isFillByWidth(fabricCanvas, currentPlan)) {
      diff = (fabricCanvas.width - ZOOM.PADDING * 2) / currentWidth;
      fabricCanvas.zoomToPoint({ x: Coords.x, y: Coords.y }, diff);
      fabricCanvas.viewportTransform[4] = ZOOM.PADDING / 2;
      const offset = (fabricCanvas.height - currentHeight * diff) / 2;
      fabricCanvas.viewportTransform[5] = offset;
    } else {
      diff = (fabricCanvas.height - ZOOM.PADDING * 2) / currentHeight;
      fabricCanvas.zoomToPoint({ x: Coords.x, y: Coords.y }, diff);
      fabricCanvas.viewportTransform[5] = ZOOM.PADDING / 2;
      const offset = (fabricCanvas.width - currentWidth * diff) / 2;
      fabricCanvas.viewportTransform[4] = offset;
    }

    PlanEditor.scaleCanvasObjects(fabricCanvas);
    PlanEditor.updateObjectsCoords(fabricCanvas);
  }

  resizeWorkSpace(fabricCanvas, currentPlan) {
    const workSpace = fabricCanvas.getObjects().find(object => object?.type === WORK_SPACE);
    fabricCanvas.remove(workSpace);
    this.drawWorkSpace(fabricCanvas, currentPlan);
    fabricCanvas.renderAll();
  }

  static updateObjectsCoords(canvas) {
    canvas.forEachObject(function(object) {
      object.setCoords();
    });
  }

  static isFillByWidth(canvas, plan) {
    const delta = canvas.width / plan.xSize;
    return plan.ySize * delta < canvas.height;
  }

  checkCanvasSize = () => {
    let { fabricCanvas } = this.state;
    if (!fabricCanvas) return;
    if (!this.pane || !this.pane.current) return;
    const pane = this.pane.current;
    if (pane.offsetHeight !== fabricCanvas.height || pane.offsetWidth !== fabricCanvas.width) {
      this.updateCanvasSize();
    }
  };

  onBackBackground = target => {
    const { doUpdatePlan, currentPlan, project } = this.props;
    const key = target.info.backgroundKey;
    const plan = { ...currentPlan, backgrounds: [...currentPlan.backgrounds] };
    const updatedBackground = plan.backgrounds.find(b => b.id === key);
    const bgs = plan.backgrounds;
    if (updatedBackground) {
      const index = plan.backgrounds.indexOf(updatedBackground);
      if (index === 0) return;
      [bgs[index - 1], bgs[index]] = [bgs[index], bgs[index - 1]];
      doUpdatePlan(plan, project.id);
    }
  };

  onForwardBackground = target => {
    const { doUpdatePlan, currentPlan, project } = this.props;
    const key = target.info.backgroundKey;
    const plan = { ...currentPlan, backgrounds: [...currentPlan.backgrounds] };
    const updatedBackground = plan.backgrounds.find(b => b.id === key);
    const bgs = plan.backgrounds;
    if (updatedBackground) {
      const index = plan.backgrounds.indexOf(updatedBackground);
      if (index === bgs.length - 1) return;
      [bgs[index], bgs[index + 1]] = [bgs[index + 1], bgs[index]];
      doUpdatePlan(plan, project.id);
    }
  };

  onCloseCreateRegionModal = () => {
    const { fabricCanvas } = this.state;
    this.resetPolygonData(true, true, fabricCanvas);
  };

  render() {
    const { currentPlan } = this.props;
    const projectId = this.props.project.id;
    const {
      activeTool,
      inactiveTools,
      fabricCanvas,
      isActiveProject,
      isChecked,
      draggableDeviceData,
      gridSize,
      pinToGrid,
      selectedRegionInfo,
      project,
      isShowGrid,
      textConfigValues,
      lastRightClickCoords,
      openTextEditorModal,
      canvasTarget
    } = this.state;
    const selectedObject = fabricCanvas ? fabricCanvas.getActiveObject() : null;
    return (
      <div className={styles.plan_editor}>
        <PlanEditorToolbar
          toolBarRef={this.toolBarRef}
          deleteObjectRef={this.deleteObjectRef}
          textSettingsPopupRef={this.textSettingsPopupRef}
          onButtonClick={this.setActiveTool}
          onChange={this.changeSelect}
          isChecked={isChecked}
          isActive={!isActiveProject}
          activeTool={activeTool}
          selectedObject={selectedObject}
          gridSize={gridSize}
          pinToGrid={pinToGrid}
          textConfigValues={textConfigValues}
          plan={currentPlan}
          inactiveTools={[!selectedObject ? TOOLBAR_ITEMS.DELETE_PLAN_ELEMENT_BUTTON : null]}
        />
        <GridController
          fabricCanvas={fabricCanvas}
          isShow={isShowGrid}
          currentPlan={currentPlan}
          gridSize={gridSize}
        />
        <TextController
          fabricCanvas={fabricCanvas}
          currentPlan={currentPlan}
          projectId={projectId}
          isActiveTool={activeTool === TOOLBAR_ITEMS.ADD_TEXT_BUTTON}
          isSelectable={activeTool === TOOLBAR_ITEMS.SELECT_ELEMENTS_BUTTON && !isActiveProject}
          textConfigValues={textConfigValues}
          openTextEditorModal={openTextEditorModal}
        />
        <NewPlanBackgroundForm
          plan={currentPlan}
          modalName="newBackground"
          onSubmit={this.createPlanBackground}
        />
        {/* Расположить поверх зоны с другой подсистемой */}
        <UpdateDeviceRegionForm
          modalName="forceDropDevice"
          actionType="FORCE"
          deviceData={draggableDeviceData}
          onForceDropDeviceConfirm={this.forceDropDeviceConfirm}
          onForceDropDeviceCancel={this.forceDropDeviceCancel}
        />
        {/* Отвязать устройство */}
        <UpdateDeviceRegionForm
          modalName="deleteDeviceRegion"
          actionType="DELETE"
          deviceData={draggableDeviceData}
          onDeleteDeviceRegionConfirm={this.deleteDeviceRegionConfirm}
          onDeleteDeviceRegionReject={this.deleteDeviceRegionReject}
          onDeleteDeviceRegionCancel={this.deleteDeviceRegionCancel}
        />
        {/* Перепривязать устройство к другой зоне */}
        <UpdateDeviceRegionForm
          actionType="CHANGE"
          modalName="changeDeviceRegion"
          deviceData={draggableDeviceData}
          onChangeDeviceRegionConfirm={this.changeDeviceRegionConfirm}
          onChangeDeviceRegionReject={this.changeDeviceRegionReject}
          onChangeDeviceRegionCancel={this.changeDeviceRegionCancel}
        />
        {/* Выбор зоны для привязки */}
        <UpdateDeviceRegionForm
          actionType="SELECT"
          modalName="selectDeviceRegion"
          deviceData={draggableDeviceData}
          onSelectDeviceRegionConfirm={this.selectDeviceRegionConfirm}
          onSelectDeviceRegionCancel={this.selectDeviceRegionCancel}
        />
        <PlanEditorContextMenu
          menuName={CONTEXT_MENU_ID}
          projectId={project ? project.id : ''}
          isActive={!isActiveProject}
          deletePlanObject={this.deletePlanObject}
          pastePlanObject={this.pastePlanObject}
          copyOrCutPlanObject={this.copyOrCutPlanObject}
          openChangeRegionModal={this.openUpdateRegionWindow}
          openEditRegionModal={this.openEditRegionModal}
          openEditTextBoxModal={this.openEditTextBoxModal}
          selectedObject={selectedObject}
          editView={this.editView}
          showInDevicesTree={this.showDeviceInTree}
          onBackBackground={this.onBackBackground}
          onForwardBackground={this.onForwardBackground}
          contextMenuCoords={lastRightClickCoords}
          addPoint={this.addPoint}
          mouseTarget={canvasTarget}
          hasObjectForPasting={!!copiedObject}
          removePoint={this.removePoint}
          showArea={this.createPasteArea}
          removePastedArea={this.deletePasteArea}
        />
        <PlanRegionController
          ref={this.regionController}
          onClose={this.onCloseCreateRegionModal}
          onSubmit={(regionId, isEditor) =>
            isEditor ? this.onUpdatePlanRegionLayout(regionId) : this.createPlanRegion(regionId)
          }
          regionInfo={selectedRegionInfo}
        />
        {/* Popup-элемент */}
        <div className={styles.popup} ref={this.popupEl} />
        <div ref={this.pane} className={styles.pane}>
          <div className={styles.canvas_wrapper}>
            <ContextMenuTrigger holdToDisplay={-1} collect={props => props} id={CONTEXT_MENU_ID}>
              <canvas ref={this.editor} id="c" />
            </ContextMenuTrigger>
          </div>
        </div>
        <ZoomToolbar
          onButtonClick={this.setActiveTool}
          isChecked={isChecked}
          inactiveTools={inactiveTools}
          hiddenTools={[TOOLBAR_ITEMS.ALWAYS_FIT_BY_WINDOW]}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const planHash = getCurrentProjectPlansHash(state);
  return {
    currentPlan: props.currentPlanId ? planHash[props.currentPlanId] : null,
    project: getCurrentProject(state),
    plans: getCurrentProjectPlans(state),
    planGroups: getCurrentProjectPlanGroups(state),
    regions: getCurrentProjectRegionViews(state),
    draggableDeviceId: state.widgets.draggableDeviceId,
    devices: getCurrentProjectDeviceList(state),
    devicesHash: getCurrentProjectDevicesHash(state),
    regionsHash: getCurrentProjectRegionsHash(state),
    maxRegionId: getNewRegionIdx(state),
    modals: state.modals,
    updateDevicesPlanLayoutsProgress: state.inProgress.updateDevicesPlanLayouts,
    deviceTree: getCurrentProjectDeviceTree(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch,
    modalOpen: bindActionCreators(modalOpen, dispatch),
    modalClose: bindActionCreators(modalClose, dispatch),
    doUpdatePlan: bindActionCreators(updatePlan, dispatch),
    doUpdateRegionPlanLayouts: bindActionCreators(updateRegionPlanLayouts, dispatch),
    doUpdateRegionsPlanLayouts: bindActionCreators(updateRegionsPlanLayouts, dispatch),
    doUpdateDevicePlanLayouts: bindActionCreators(updateDevicePlanLayouts, dispatch),
    doUpdateDevicesPlanLayouts: bindActionCreators(updateDevicesPlanLayouts, dispatch),
    doUpdatePlanText: bindActionCreators(updatePlanText, dispatch),
    doChangeDeviceBindingToRegion: bindActionCreators(changeDeviceBindingToRegion, dispatch),
    doCopyAndPasteObjectsOnPlan: bindActionCreators(copyAndPasteObjectsOnPlan, dispatch),
    doCutAndPasteObjectsOnPlan: bindActionCreators(cutAndPasteObjectsOnPlan, dispatch),
    doUpdateDraggableDevice: bindActionCreators(updateDraggableDevice, dispatch),
    doDeletePlanBackground: bindActionCreators(deletePlanBackground, dispatch),
    doDeletePlanObjects: bindActionCreators(deletePlanObjects, dispatch),
    doDeletePlanText: bindActionCreators(deletePlanText, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PlanEditor);
