import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button } from 'antd';

import { FormItem, Select } from 'components';
import ButtonGroup from 'components/Button/ButtonGroup';
import Modal from 'containers/Modal';
import { getCurrentProjectRegionsHash } from 'helpers/currentProject';
import i18next from 'i18next';
import { Form, Field, reduxForm } from 'redux-form';
import memoizeOne from 'memoize-one';

const ContentWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
  `,
  ButtonWrapper = styled.div`
    margin: 5px;
  `;

const FORM_NAME = 'UpdateDeviceRegionForm';

class UpdateDeviceRegionForm extends Component {
  static propTypes = {
    regions: PropTypes.object
  };

  onSelectDeviceRegionConfirm = data => {
    const { onSelectDeviceRegionConfirm } = this.props;
    if (onSelectDeviceRegionConfirm) onSelectDeviceRegionConfirm(data.region);
  };

  render() {
    const {
      modalName,
      actionType,
      deviceData,
      filteredRegions,
      /**
       * Возможные действия
       * с постфиксом :
       *    Confirm - Выполняет действие
       *    Reject - Отмена действия с последующим редактирование (по возможности) или частичное выполнение действия (по логической составляющей)
       *    Cancel - Отменить действие полностью (Отмена перемещений и выделения)
       */
      // Отвязка устройства от зоны
      onDeleteDeviceRegionConfirm,
      onDeleteDeviceRegionReject,
      onDeleteDeviceRegionCancel,
      // Сменить зону устройства
      onChangeDeviceRegionConfirm,
      onChangeDeviceRegionReject,
      onChangeDeviceRegionCancel,
      onSelectDeviceRegionCancel,
      // Размещение в зоне с другой подсистемой (без привязки к ней)
      onForceDropDeviceConfirm,
      onForceDropDeviceCancel,
      handleSubmit
    } = this.props;
    return (
      <Modal name={modalName} title={i18next.t('buttons.confirm')}>
        {deviceData && actionType === 'DELETE' && deviceData.device ? (
          <ContentWrapper>
            {`${i18next.t('plans.deviceAlreadyHasZone')}. ${i18next.t('questions.detach')}`}
            <ButtonGroup>
              <ButtonWrapper>
                <Button type="primary" onClick={onDeleteDeviceRegionConfirm}>
                  {i18next.t('buttons.yes')}
                </Button>
              </ButtonWrapper>
              <ButtonWrapper>
                <Button type="primary" onClick={onDeleteDeviceRegionReject}>
                  {i18next.t('buttons.no')}
                </Button>
              </ButtonWrapper>
              <ButtonWrapper>
                <Button type="primary" onClick={onDeleteDeviceRegionCancel}>
                  {i18next.t('buttons.cancel')}
                </Button>
              </ButtonWrapper>
            </ButtonGroup>
          </ContentWrapper>
        ) : null}
        {deviceData && actionType === 'FORCE' && deviceData.device ? (
          <ContentWrapper>
            {i18next.t('plans.tryToPlaceDeviceToOtherSubsystem')}
            <ButtonGroup>
              <ButtonWrapper>
                <Button type="primary" onClick={onForceDropDeviceConfirm}>
                  {i18next.t('buttons.yes')}
                </Button>
              </ButtonWrapper>
              <ButtonWrapper>
                <Button type="primary" onClick={onForceDropDeviceCancel}>
                  {i18next.t('buttons.cancel')}
                </Button>
              </ButtonWrapper>
            </ButtonGroup>
          </ContentWrapper>
        ) : null}
        {deviceData && actionType === 'CHANGE' && deviceData.device && deviceData.regionIds ? (
          <ContentWrapper>
            {i18next.t('plans.tryToPlaceDeviceToOtherZone')}
            <ButtonGroup>
              <ButtonWrapper>
                <Button
                  type="primary"
                  onClick={() => onChangeDeviceRegionConfirm(deviceData.regionIds[0])}
                >
                  {i18next.t('buttons.yes')}
                </Button>
              </ButtonWrapper>
              <ButtonWrapper>
                <Button type="primary" onClick={onChangeDeviceRegionReject}>
                  {i18next.t('buttons.no')}
                </Button>
              </ButtonWrapper>
              <ButtonWrapper>
                <Button type="primary" onClick={onChangeDeviceRegionCancel}>
                  {i18next.t('buttons.cancel')}
                </Button>
              </ButtonWrapper>
            </ButtonGroup>
          </ContentWrapper>
        ) : null}
        {deviceData && actionType === 'SELECT' && deviceData.regionIds ? (
          <Form onSubmit={handleSubmit(this.onSelectDeviceRegionConfirm)}>
            <ContentWrapper>
              {i18next.t('plans.tryToPlaceDeviceToSeveralZones')}
              <FormItem label={i18next.t('plans.availableZones')}>
                <Field id="region" name={'region'} component={Select} options={filteredRegions} />
              </FormItem>
              <ButtonGroup>
                <ButtonWrapper>
                  <Button type="primary" htmlType="submit">
                    {i18next.t('buttons.choose')}
                  </Button>
                </ButtonWrapper>
                <ButtonWrapper>
                  <Button type="primary" onClick={onSelectDeviceRegionCancel}>
                    {i18next.t('buttons.cancel')}
                  </Button>
                </ButtonWrapper>
              </ButtonGroup>
            </ContentWrapper>
          </Form>
        ) : null}
      </Modal>
    );
  }
}

UpdateDeviceRegionForm = reduxForm({ form: FORM_NAME, enableReinitialize: true })(
  UpdateDeviceRegionForm
);

const getFilteredRegions = memoizeOne((actionType, deviceData, regions) => {
  if (actionType !== 'SELECT' || !deviceData || !deviceData.regionIds) return [];
  return deviceData.regionIds.map(id => ({
    value: regions[id].id,
    label: `${regions[id].region.index}.${regions[id].name}`
  }));
});

const mapStateToProps = (state, props) => {
  const regions = getCurrentProjectRegionsHash(state);
  const filteredRegions = getFilteredRegions(props.actionType, props.deviceData, regions);
  return {
    regions,
    filteredRegions,
    initialValues: { region: filteredRegions.length ? filteredRegions[0].value : null }
  };
};

export default connect(mapStateToProps, null)(UpdateDeviceRegionForm);
