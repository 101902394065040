import React from 'react';

const Grid = ({ color = '#fff', width, height, x = '16', y = '16' }) => {
  return (
    <svg width={width || x} height={height || y} viewBox={`0 0 ${x} ${y}`}>
      <path
        fill={color}
        stroke="null"
        id="grid"
        d="m14.347404,0.499958l0,0l-3.457914,0l0,0l-1.152638,0l0,0l-3.473704,0l0,0l-1.152638,0l0,0l-3.457914,0l0,0c-0.631582,0 -1.152638,0.521056 -1.152638,1.152638l0,3.457914l0,0l0,1.152638l0,0l0,3.473704l0,0l0,1.152638l0,0l0,3.457914c0,0.631582 0.521056,1.152638 1.152638,1.152638l3.457914,0l1.152638,0l3.473704,0l1.152638,0l3.457914,0c0.631582,0 1.152638,-0.521056 1.152638,-1.152638l0,-12.694808c0,-0.631582 -0.521056,-1.152638 -1.152638,-1.152638zm0,1.152638l0,1.152638l0,2.305276l-3.457914,0l0,-3.457914l3.457914,0zm0,8.084256l-3.457914,0l0,-3.473704l3.457914,0l0,3.473704zm-4.610552,0l-3.473704,0l0,-3.473704l3.473704,0l0,3.473704zm0,-8.084256l0,3.457914l-3.473704,0l0,-3.457914l3.473704,0zm-8.084256,1.152638l0,-1.152638l3.457914,0l0,3.457914l-3.457914,0l0,-2.305276zm0,3.457914l3.457914,0l0,3.473704l-3.457914,0l0,-3.473704zm1.152638,8.084256l-1.152638,0l0,-1.152638l0,-2.305276l3.457914,0l0,3.457914l-2.305276,0zm3.457914,0l0,-3.457914l3.473704,0l0,3.457914l-3.473704,0zm6.931618,0l-2.305276,0l0,-3.457914l3.457914,0l0,2.305276l0,1.152638l-1.152638,0z"
      />
    </svg>
  );
};

export default Grid;
