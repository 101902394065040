import {
  isLoaded,
  PLANS_LOAD,
  PLAN_CREATE,
  PLAN_UPDATE,
  PLAN_DELETE,
  PROJECT_DELETE,
  PROJECT_LOAD_CURRENT,
  PROJECT_LOAD_ACTIVE,
  PROJECT_UPDATE_STATUS,
  PLAN_GROUP_DELETE,
  PLAN_DELETE_TEXT,
  PLAN_ADD_TEXT,
  COPY_AND_PASTE_TEXTS_ON_PLAN,
  PLAN_UPDATE_TEXT,
  PLAN_DELETE_OBJECTS,
  COPY_AND_PASTE_BACKGROUNDS_ON_PLAN
} from 'constants/actionTypes';

/*
 * state = plans: {
 *    <projectId>: {
 *      <planId>: {
 *          id: <string>,
 *          ...
 *      },
 *      ...
 *    },
 *    ...
 * }
 * (см. сущность, возвращаемую методом GET api/v1/projects/<projectId>/plans в тех. проекте)
 */
export default function plans(state = {}, { type, payload }) {
  switch (type) {
    case isLoaded(PLANS_LOAD, true): {
      const { projectId, plans } = payload;
      const newState = { ...state };
      newState[projectId] = {};
      plans.forEach(plan => {
        newState[projectId][plan.id] = plan;
      });
      return newState;
    }
    case isLoaded(PLAN_CREATE, true): {
      const { projectId, newPlan } = payload;
      if (!state[projectId]) return state;
      const newState = { ...state };
      newState[projectId] = { ...newState[projectId] };
      newState[projectId][newPlan.id] = newPlan;
      return newState;
    }
    case isLoaded(PLAN_DELETE, true): {
      const { planId, projectId } = payload;
      if (!state[projectId]) return state;
      if (!state[projectId][planId]) return state;
      const newState = { ...state };
      delete newState[projectId][planId];
      newState[projectId] = { ...newState[projectId] };
      return newState;
    }
    case isLoaded(PLAN_ADD_TEXT, true):
    case isLoaded(PLAN_UPDATE_TEXT, true):
    case isLoaded(PLAN_DELETE_TEXT, true):
    case isLoaded(COPY_AND_PASTE_BACKGROUNDS_ON_PLAN, true):
    case isLoaded(COPY_AND_PASTE_TEXTS_ON_PLAN, true):
    case isLoaded(PLAN_DELETE_OBJECTS, true):
    case isLoaded(PLAN_UPDATE, true): {
      const { updatedPlan, projectId } = payload;
      if (!state[projectId]) return state;
      if (!state[projectId][updatedPlan.id]) return state;
      const newState = { ...state };
      newState[projectId] = { ...newState[projectId] };
      newState[projectId][updatedPlan.id] = updatedPlan;
      return newState;
    }
    case isLoaded(PLAN_GROUP_DELETE, true): {
      const { projectId, updatedPlans = [] } = payload;
      if (!state[projectId] || !updatedPlans.length) return state;
      const newState = { ...state };
      newState[projectId] = { ...newState[projectId] };
      updatedPlans.forEach(updatedPlan => {
        newState[projectId][updatedPlan.id] = updatedPlan;
      });
      return newState;
    }
    case isLoaded(PROJECT_LOAD_CURRENT, true):
    case isLoaded(PROJECT_LOAD_ACTIVE, true):
    case isLoaded(PROJECT_UPDATE_STATUS, true): {
      const { project, plans } = payload;
      const newState = { ...state };
      newState[project.id] = {};
      plans.forEach(plan => {
        newState[project.id][plan.id] = plan;
      });
      return newState;
    }
    case isLoaded(PROJECT_DELETE, true): {
      const projectId = payload;
      if (!state[projectId]) return state;
      const newState = { ...state };
      delete newState[projectId];
      return newState;
    }
    default: {
      return state;
    }
  }
}
