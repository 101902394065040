import {
  PROJECT_LOAD_ACTIVE,
  PROJECT_UPDATE_STATUS,
  PROJECT_LOAD_CURRENT,
  isLoaded
} from 'constants/actionTypes';

/**
 * state = virtualStates: {
 *    <virtualStateId>: {
 *      id: <string>,
 *      ...
 *    }
 * }
 */
export default function virtualStates(state = null, { type, payload }) {
  switch (type) {
    case isLoaded(PROJECT_LOAD_ACTIVE, true): {
      const { virtualStates } = payload;
      const newState = {};
      virtualStates.forEach(virtualState => {
        newState[virtualState.id] = virtualState;
      });
      return newState;
    }
    case isLoaded(PROJECT_UPDATE_STATUS, true): {
      const { project, virtualStates } = payload;
      if (project.status === 'ACTIVE') {
        const newState = {};
        virtualStates.forEach(virtualState => {
          newState[virtualState.id] = virtualState;
        });
        return newState;
      } else {
        return null;
      }
    }
    case isLoaded(PROJECT_LOAD_CURRENT, true): {
      const { project, virtualStates } = payload;
      if (project.status === 'ACTIVE') {
        const newState = {};
        virtualStates.forEach(virtualState => {
          newState[virtualState.id] = virtualState;
        });
        return newState;
      } else {
        return state;
      }
    }
    default: {
      return state;
    }
  }
}
