/*
 * Библиотека шейпов
 * REST-CRUD-API здесь:
 * https://docs.google.com/document/d/19fDadv_CBaeP-RlnnSvsjpdx7BCqSeax4MlCHjeEW28/edit#heading=h.9qki9evdzqjb
 */

/**
 * Получить все актуальные библиотеки шейпов устройств
 */
export const DEVICE_SHAPE_LIBRARIES_LOAD = 'DEVICE_SHAPE_LIBRARIES_LOAD';
/**
 * Добавить новую библиотеку шейпов устройств
 */
export const DEVICE_SHAPE_LIBRARY_ADD = 'DEVICE_SHAPE_LIBRARY_ADD';
/**
 * Изменить библиотеку шейпов устройств
 */
export const DEVICE_SHAPE_LIBRARY_UPDATE = 'DEVICE_SHAPE_LIBRARY_UPDATE';
/**
 * Удалить библиотеку шейпов устройств
 */
export const DEVICE_SHAPE_LIBRARY_REMOVE = 'DEVICE_SHAPE_LIBRARY_REMOVE';
/**
 * Получить все шейпы актуальных библиотек
 */
export const DEVICE_SHAPES_LOAD = 'DEVICE_SHAPES_LOAD';
/**
 * Добавить новый шейп
 */
export const DEVICE_SHAPE_ADD = 'DEVICE_SHAPE_ADD';
/**
 * Изменить шейп
 */
export const DEVICE_SHAPE_UPDATE = 'DEVICE_SHAPE_UPDATE';
/**
 * Удалить шейп
 */
export const DEVICE_SHAPE_REMOVE = 'DEVICE_SHAPE_REMOVE';
/**
 * Выбрать библиотеку шейпов устройств или шейп (локальный экшен)
 */
export const DEVICE_SHAPE_LIBRARY_SELECT = 'DEVICE_SHAPE_LIBRARY_SELECT';
/**
 * Очистить выбор библиотеки/шейпа (локальный экшн)
 */
export const DEVICE_SHAPE_LIBRARY_REMOVE_SELECTION = 'DEVICE_SHAPE_LIBRARY_REMOVE_SELECTION';
