/**
 * Виртуальные счетчики.
 */

/**
 * Загрузить все виртуальные счетчики
 * REST-метод: GET api/v1/projects/<projectId>/virtual_counters
 */
export const VIRTUAL_COUNTERS_LOAD = 'VIRTUAL_COUNTERS_LOAD';
/**
 * Создать новый виртуальный счетчик
 * REST-метод: POST api/v1/projects/<projectId>/virtual_counters
 */
export const VIRTUAL_COUNTER_CREATE = 'VIRTUAL_COUNTER_CREATE';
/**
 * Обновить базовые параметры виртуального счетчика
 * REST-метод: PUT api/v1/projects/<projectId>/virtual_counters/<virtualCounterId>
 */
export const VIRTUAL_COUNTER_UPDATE = 'VIRTUAL_COUNTER_UPDATE';
/**
 * Удалить виртуальное счетчик
 * REST-метод: DELETE api/v1/projects/<projectId>/virtual_counters/<virtualCounterId>
 */
export const VIRTUAL_COUNTER_DELETE = 'VIRTUAL_COUNTER_DELETE';

export const VIRTUAL_COUNTERS_DELETE = 'VIRTUAL_COUNTERS_DELETE';

/**
 * Выбрать виртуальный счетчик (локальный action).
 * Payload: virtualCounterId: <string>.
 * Store: state.widgets.selectedVirtualCounterIds.
 */
export const VIRTUAL_COUNTER_SELECT = 'VIRTUAL_COUNTER_SELECT';


export const VIRTUAL_COUNTER_PASTE = 'VIRTUAL_COUNTER_PASTE';
