import { createAction } from 'redux-actions';

import {
  SOUND_NOTIFICATIONS_LOAD,
  SOUND_NOTIFICATION_SELECT,
  SOUND_NOTIFICATION_ADD,
  SOUND_NOTIFICATION_UPDATE,
  SET_CURRENT_NOTIFICATION
} from 'constants/actionTypes';

export const loadSoundNotifications = createAction(SOUND_NOTIFICATIONS_LOAD);

export const selectSoundNotification = createAction(
  SOUND_NOTIFICATION_SELECT,
  soundNotificationId => soundNotificationId
);

export const addSoundNotification = createAction(
  SOUND_NOTIFICATION_ADD,
  newSoundNotification => newSoundNotification
);

export const updateSoundNotification = createAction(
  SOUND_NOTIFICATION_UPDATE,
  updatedSoundNotification => updatedSoundNotification
);

export const setCurrentNotification = createAction(
  SET_CURRENT_NOTIFICATION,
  ({ subsystems, stateCategoryViews }) => ({ subsystems, stateCategoryViews })
);
