import {
  EVENTS_LOAD_LOG,
  EVENTS_LOAD_ARCHIVE,
  EVENTS_LOAD_TYPE_VIEWS,
  EVENTS_CLEAN_ARCHIVE,
  EVENTS_SET_ARCHIVE_FILTERS
} from 'constants/actionTypes';

import { createAction } from 'redux-actions';

export const loadLogEvents = createAction(
  EVENTS_LOAD_LOG,
  (logViewId, pageSize, pageNo, sort, filters) => ({
    logViewId,
    pageSize,
    pageNo,
    sort,
    filters
  })
);

export const loadArchiveEvents = createAction(
  EVENTS_LOAD_ARCHIVE,
  (pageSize, pageNo, sort, filters, requestCountForced = false) => ({
    pageSize,
    pageNo,
    sort,
    filters,
    requestCountForced
  })
);

export const loadEventTypeViews = createAction(EVENTS_LOAD_TYPE_VIEWS);

export const cleanArchiveEvents = createAction(EVENTS_CLEAN_ARCHIVE);

export const setArchiveEventsFilters = createAction(EVENTS_SET_ARCHIVE_FILTERS, filters => [
  ...filters
]);
