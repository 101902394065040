import {
  MODAL_OPEN,
  MODAL_CLOSE,
} from 'constants/actionTypes';

export default function modals(state = {}, action) {
  switch(action.type){
    case MODAL_OPEN:
      return {
        ...state,
        [action.payload]: true
      }
    case MODAL_CLOSE:
      return {
        ...state,
        [action.payload]: false
      }
    case '@@router/LOCATION_CHANGE': // При смене url закрыть все модалки
      return {}
    default:
      return state
  }
}
