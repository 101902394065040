import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Popconfirm } from 'antd';

import Icon from 'components/Icon';

import { logoutUser } from 'actions/login';
import i18next from 'i18next';

const Wrapper = styled.div`
  padding: 0 10px;
  display: flex;
  height: 35px;
  justify-content: center;
  align-items: center;
`;

const PopupText = styled.div`
  margin: 0 10px;
  color: white;
  font-size: 13px;
`;

const LogoutButton = styled.div`
  margin: 0 10px;
  color: white;
  font-size: 13px;
  cursor: pointer;
`;

class UserInfo extends Component {
  onLogout = () => {
    const { dispatch } = this.props;
    localStorage.clear();
    dispatch(logoutUser());
  };
  render() {
    return (
      <Wrapper>
        <div>
          <Icon name="user" color="#fff" />
        </div>
        <PopupText>{this.props.currentUserName && this.props.currentUserName}</PopupText>
        <Popconfirm
          placement="bottomRight"
          title={i18next.t('questions.logout')}
          onConfirm={this.onLogout}
          okText={i18next.t('buttons.ok')}
          cancelText={i18next.t('buttons.cancel')}
        >
          <LogoutButton>{i18next.t('logout')}</LogoutButton>
        </Popconfirm>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUserName: state.currentUser ? state.currentUser.fullName : 'unknown'
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
