import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popup from 'components/Popup';
import PopupItem from 'components/Popup/PopupItem';
import i18next from 'i18next';

const ACTIVE_SELECTION = 'activeSelection';
const WORK_SPACE = 'workSpace';

const CUT_ACTION_TYPE = 'cut';
const COPY_ACTION_TYPE = 'copy';
class PlanEditorContextMenu extends Component {
  render() {
    const {
      menuName,
      selectedObject,
      deletePlanObject,
      openChangeRegionModal,
      openEditRegionModal,
      openEditTextBoxModal,
      showInDevicesTree,
      onBackBackground,
      onForwardBackground,
      editView,
      isActive,
      contextMenuCoords,
      addPoint,
      hasObjectForPasting,
      pastePlanObject,
      copyOrCutPlanObject,
      mouseTarget,
      removePoint,
      showArea,
      removePastedArea
    } = this.props;
    if (!isActive) return null;

    const hasInfo = selectedObject && !!selectedObject.info;
    const isRegion = !!(hasInfo && selectedObject.info.regionKey);
    const isDevice = !!(hasInfo && selectedObject.info.deviceKey);
    const isImage = !!(hasInfo && selectedObject.info.backgroundKey);
    const isTextBox = !!(hasInfo && selectedObject.info.textKey);
    const isNotEditingTextBox = isTextBox && !selectedObject.isEditing;
    const isGroup = selectedObject && selectedObject.type === ACTIVE_SELECTION;
    const isEditableObject = isGroup || isRegion || isDevice || isImage || isNotEditingTextBox;
    const isWorkSpace = !selectedObject && mouseTarget?.type === WORK_SPACE;
    return (
      <Popup id={menuName}>
        <PopupItem visible={isRegion} onClick={() => openChangeRegionModal(selectedObject)}>
          {i18next.t('plans.menu.replaceZone')}
        </PopupItem>
        <PopupItem visible={isRegion} onClick={() => editView(selectedObject, true)}>
          {i18next.t('plans.menu.editShapeByPoints')}
        </PopupItem>
        <PopupItem visible={isRegion} onClick={() => openEditRegionModal(selectedObject)}>
          {i18next.t('plans.menu.editZoneParameters')}
        </PopupItem>
        <PopupItem
          visible={isRegion}
          disabled={selectedObject?.action !== 2}
          onClick={e => addPoint(selectedObject, contextMenuCoords, e)}
        >
          {i18next.t('plans.menu.addPoint')}
        </PopupItem>
        <PopupItem
          visible={isRegion}
          disabled={selectedObject?.action !== 2 || selectedObject.points?.length < 4}
          onClick={e => removePoint(selectedObject, contextMenuCoords, e)}
        >
          {i18next.t('plans.menu.removePoint')}
        </PopupItem>
        <PopupItem visible={isDevice} onClick={() => showInDevicesTree(selectedObject)}>
          {i18next.t('plans.menu.showInDeviceTree')}
        </PopupItem>
        <PopupItem visible={isTextBox} onClick={() => openEditTextBoxModal(selectedObject)}>
          {i18next.t('plans.settingUpText')}
        </PopupItem>
        <PopupItem
          visible={isImage && !selectedObject?.info.isOne}
          disabled={isImage && selectedObject?.info.isFirst}
          onClick={() => onBackBackground(selectedObject)}
        >
          {i18next.t('plans.menu.moveBack')}
        </PopupItem>
        <PopupItem
          visible={isImage && !selectedObject?.info.isOne}
          disabled={isImage && selectedObject?.info.isLast}
          onClick={() => onForwardBackground(selectedObject)}
        >
          {i18next.t('plans.menu.moveForward')}
        </PopupItem>
        <PopupItem
          visible={isEditableObject}
          disabled={!isEditableObject}
          onClick={() => copyOrCutPlanObject(CUT_ACTION_TYPE)}
        >
          {i18next.t('buttons.cut')}
        </PopupItem>
        <PopupItem
          visible={isEditableObject}
          disabled={!isEditableObject}
          onClick={() => copyOrCutPlanObject(COPY_ACTION_TYPE)}
        >
          {i18next.t('buttons.copy')}
        </PopupItem>
        <PopupItem
          visible={isWorkSpace}
          disabled={!hasObjectForPasting}
          onClick={() => pastePlanObject(contextMenuCoords)}
          onHover={() => showArea()}
          onLeave={() => removePastedArea()}
        >
          {i18next.t('buttons.paste')}
        </PopupItem>
        <PopupItem visible={isEditableObject} onClick={deletePlanObject}>
          {i18next.t('buttons.delete')}
        </PopupItem>
      </Popup>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return { dispatch: dispatch };
};

export default connect(null, mapDispatchToProps)(PlanEditorContextMenu);
