export const STATE_CATEGORIES = {
  EXTINCTION: 'Extinction',
  FIRE: 'Fire',
  FIRE1: 'Fire1',
  ALARM: 'Alarm',
  WARNING: 'Warning',
  MALFUNCTION: 'Malfunction',
  ERROR: 'Error',
  AUTO_OFF: 'AutoOff',
  IGNORE: 'Ignore',
  UNKNOWN: 'Unknown',
  RUN: 'Run',
  SERVICE: 'Service',
  ON_GUARD: 'OnGuard',
  OFF_GUARD: 'OffGuard',
  INFO: 'Info',
  NOTIFY_OFF: 'NotifyOff',
  NORMAL: 'Normal',
  NULL: 'Null'
};

export const FIRE_SUBSYSTEM_STATES_LIST_CAUSING_REDIRECTION = [
  STATE_CATEGORIES.FIRE,
  STATE_CATEGORIES.FIRE1,
  STATE_CATEGORIES.WARNING,
  STATE_CATEGORIES.EXTINCTION
];
