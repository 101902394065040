import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import SUBSYSTEMS from 'constants/subsystems';
import { FIRE_SUBSYSTEM_STATES_LIST_CAUSING_REDIRECTION } from 'constants/stateCategories';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { PATHS } from 'constants/routes';
import { ActiveSubsystem } from 'backendToTSTypes/ActiveSubsystem';

function RedirectToActivePlanController() {
  const subsystems = useSelector(
    (state: any) => state.activeProject.subsystems
  ) as ActiveSubsystem[];
  const priorityMode = useSelector((state: any) => state.activeProject.priorityMode) as boolean;
  const location = useLocation();
  const history = useHistory();
  const isRedirectedToPlanPage = useRef(false);

  useEffect(() => {
    if (isRedirectedToPlanPage.current || !priorityMode) return;
    const isOsPlanPage =
      location.pathname === PATHS.OP.PLAN || location.pathname === PATHS.OP.PLAN + '/';
    if (isOsPlanPage) {
      isRedirectedToPlanPage.current = true;
      return;
    }
    if (subsystems.length) {
      const fireSubsystem = subsystems.find(
        subsystem => subsystem.subsystem === SUBSYSTEMS.FIRE.id
      );
      if (!fireSubsystem?.counters) return;
      const isSystemHaveFireState = FIRE_SUBSYSTEM_STATES_LIST_CAUSING_REDIRECTION.some(
        stateName => fireSubsystem.counters[stateName]?.value
      );
      if (isSystemHaveFireState) {
        history.push(PATHS.OP.PLAN);
        isRedirectedToPlanPage.current = true;
      }
    }
  }, [subsystems, priorityMode, location.pathname, history]);
  return null;
}

export default React.memo(RedirectToActivePlanController);
