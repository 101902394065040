import { idHash } from './strings';

/**
 * Создать обертку над некоторой сущностью для построение табличного дерева
 *
 * @param {String} entityType строковое обозначение типа сущности
 * @param {String} entityId  строковый идентификатор
 * @param {Object} entity сама сущность (объект)
 * @param {Boolean} expanded раскрытие по умолчанию
 */
export function createTreeItem(initialized, entityType, entityId, entity, expanded = false) {
  const treeItem = {
    id: idHash(`${entityType}_${entityId}`),
    entityType,
    entity: { ...entity },
    children: []
  };
  if (!initialized) {
    treeItem.state = { expanded };
  }
  return treeItem;
}
