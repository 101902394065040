import { combineReducers } from 'redux';

import {
  MEDIAS_LOAD_ICONS,
  isLoaded,
  MEDIAS_LOAD_SVGS,
  MEDIAS_LOAD_SOUNDS,
  UPDATED_ENTITIES
} from 'constants/actionTypes';

function icons(state = [], action) {
  switch (action.type) {
    case isLoaded(MEDIAS_LOAD_ICONS, true): {
      return [...action.payload];
    }
    default:
      return state;
  }
}

function iconsHash(state = {}, action) {
  switch (action.type) {
    case isLoaded(MEDIAS_LOAD_ICONS, true): {
      const icons = action.payload;
      const iconsHash = {};
      icons.forEach(icon => (iconsHash[icon.id] = icon));
      return iconsHash;
    }
    default:
      return state;
  }
}

function svgsHash(state = {}, action) {
  switch (action.type) {
    case isLoaded(MEDIAS_LOAD_SVGS, true): {
      const svgs = action.payload;
      const svgsHash = {};
      svgs.forEach(svg => (svgsHash[svg.id] = svg));
      return svgsHash;
    }
    default:
      return state;
  }
}

function sounds(state = {}, { type, payload }) {
  switch (type) {
    case isLoaded(MEDIAS_LOAD_SOUNDS, true): {
      const sounds = payload;
      const newState = {};
      sounds.forEach(sound => (newState[sound.id] = sound));
      return newState;
    }
    case UPDATED_ENTITIES: {
      const { medias } = payload;
      if (!medias) return state;
      let stateUpdated = false;
      const newState = { ...state };
      if (medias.updated.length) {
        const updatedSounds = medias.updated.filter(media => media.mediaType === 'SOUND');
        if (updatedSounds.length) {
          updatedSounds.forEach(updatedSound => (newState[updatedSound.id] = updatedSound));
          stateUpdated = true;
        }
      }
      if (medias.created.length) {
        const createdSounds = medias.created.filter(media => media.mediaType === 'SOUND');
        if (createdSounds.length) {
          createdSounds.forEach(createdSound => (newState[createdSound.id] = createdSound));
          stateUpdated = true;
        }
      }
      if (medias.deletedIds.length) {
        medias.deletedIds.forEach(deletedMediaId => {
          if (newState[deletedMediaId]) {
            delete newState[deletedMediaId];
            stateUpdated = true;
          }
        });
      }

      return stateUpdated ? newState : state;
    }
    default:
      return state;
  }
}

export default combineReducers({
  icons,
  iconsHash,
  svgsHash,
  sounds
});
