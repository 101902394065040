import {
  PROJECTS_LOAD,
  PROJECT_CREATE,
  PROJECT_DELETE,
  PROJECT_UPDATE,
  PROJECT_LOAD_CURRENT,
  PROJECT_LOAD_ACTIVE,
  PROJECT_UPDATE_STATUS,
  PROJECT_SELECT,
  PROJECT_SET_CURRENT,
  PROJECT_IMPORT,
  PROJECT_EXPORT,
  PROJECT_VALIDATE,
  PROJECT_VALIDATE_MESSAGE_LOAD
} from 'constants/actionTypes';

import { createAction } from 'redux-actions';

export const createProject = createAction(PROJECT_CREATE, project => project);
export const deleteProject = createAction(PROJECT_DELETE, projectId => projectId);
export const updateProject = createAction(PROJECT_UPDATE, updatedProject => updatedProject);
export const loadCurrentProject = createAction(PROJECT_LOAD_CURRENT, projectId => projectId);
export const loadActiveProject = createAction(
  PROJECT_LOAD_ACTIVE,
  (canBeMissing = false) => canBeMissing
);
export const updateProjectStatus = createAction(PROJECT_UPDATE_STATUS, (projectId, statusName) => ({
  projectId,
  statusName
}));
export const projectsLoad = createAction(PROJECTS_LOAD);
export const selectProject = createAction(PROJECT_SELECT, project => project);
export const setCurrentProject = createAction(PROJECT_SET_CURRENT, projectId => projectId);
export const importProject = createAction(PROJECT_IMPORT, (fileName, version, importedFile) => ({
  fileName,
  version,
  importedFile
}));
export const projectExport = createAction(PROJECT_EXPORT, project => project);
export const validateProject = createAction(PROJECT_VALIDATE, (projectId, validate) => ({
  projectId,
  validate
}));
export const loadValidationMessages = createAction(
  PROJECT_VALIDATE_MESSAGE_LOAD,
  (projectId, pageNo, pageSize, isNeedLoadImportValidateMessages) => ({
    projectId,
    pageNo,
    pageSize,
    isNeedLoadImportValidateMessages
  })
);
