import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Form,
  Field,
  reduxForm,
  initialize,
  reset,
  propTypes,
  FormSection,
  formValueSelector,
  getFormInitialValues,
  getFormSyncErrors
} from 'redux-form';

import { DeleteOutlined } from '@ant-design/icons';

import { Button, Card } from 'antd';
import styled from 'styled-components';

import { ItemLayoutWithLabel, SubmitItemLayout, ItemLayoutWithoutLabel } from 'components/Form';

import Modal from 'containers/Modal';
import { getCurrentDriverAttributes } from 'helpers/drivers';
import { getCurrentProjectRegions } from 'helpers/currentProject';
import {
  FIRE_DEPENDENCY_FIELD_SETTINGS,
  getFireAlarmDependencyNames,
  getFireAlarmDependenciesTitles
} from 'helpers/regions';
import { Checkbox, Select, Input, FormItem } from 'components';
import memoizeOne from 'memoize-one';
import i18next from 'i18next';
import { DISPLAY_NONE_STYLE, DISPLAY_FLEX_STYLE } from 'constants/common';

const CardWrapper = styled.div`
  .ant-card-head {
    height: 60px;
    line-height: 20px;
    padding: 0 8px;
  }
  .ant-card-head-title {
    font-weight: 400;
    white-space: normal;
  }
`;

const FORM_NAME = 'fireRegionForm';

/**
 * Сброс значение формы
 * @param dispatch
 * @param values объект со значениями
 */
export function resetFormValues(dispatch, values) {
  const newValues = { ...values, count: 1 };
  dispatch(initialize(values.form ? values.form : FORM_NAME, newValues));
  dispatch(reset(values.form ? values.form : FORM_NAME));
}

class FireRegionForm extends Component {
  static propTypes = {
    ...propTypes,
    modalName: PropTypes.string,
    isEdit: PropTypes.bool,
    onSubmit: PropTypes.func,
    needToAddOnlyOneRegion: PropTypes.bool
  };

  static defaultProps = {
    needToAddOnlyOneRegion: true
  };

  fireAlarmDependencyNames = getFireAlarmDependencyNames();
  fireAlarmDependenciesTitles = getFireAlarmDependenciesTitles();

  getRegionsOptions = memoizeOne(regions => {
    if (!regions) return [];
    return regions.reduce((arr, current) => {
      if (!arr.length) arr.push({ value: '', label: i18next.t('no') });
      arr.push({ value: current.id, label: current.name });
      return arr;
    }, []);
  });

  getDependencyParamFieldByName(fieldName, visible, active) {
    const { fireOnFirstSensorRemainedOn, bindedRegionId, change, regions } = this.props;

    const fieldStyle = visible ? DISPLAY_FLEX_STYLE : DISPLAY_NONE_STYLE;
    switch (fieldName) {
      case 'fireEventCount':
        return (
          <FormItem
            {...ItemLayoutWithLabel}
            labelCol={{ span: 50 }}
            label={i18next.t('zones.numberOfSensorsForSwitchingToFire2')}
            style={fieldStyle}
            key={fieldName}
          >
            <Field
              name={fieldName}
              component={Input.Number}
              precision={0}
              min={2}
              max={64}
              disabled={!active}
            />
          </FormItem>
        );

      case 'fireOnFirstSensorRemainedOn':
        return (
          <FormItem {...ItemLayoutWithoutLabel} style={fieldStyle} key={fieldName}>
            <Field
              name={fieldName}
              component={Checkbox}
              disabled={!active}
              label={i18next.t('zones.repeatedFireSignalFromDevice')}
            />
          </FormItem>
        );

      case 'checkFireTimeout':
        return (
          <FormItem
            {...ItemLayoutWithLabel}
            label={i18next.t('zones.recheckTime')}
            style={fieldStyle}
            key={fieldName}
          >
            <Field
              name={fieldName}
              component={Input.Number}
              min={3}
              max={240}
              disabled={!active || !fireOnFirstSensorRemainedOn}
            />
          </FormItem>
        );

      case 'fireOnSecondSensorOn':
        return (
          <FormItem {...ItemLayoutWithoutLabel} style={fieldStyle} key={fieldName}>
            <Field
              name={fieldName}
              component={Checkbox}
              disabled={!active}
              label={i18next.t('zones.fireFromTwoDevices')}
            />
          </FormItem>
        );

      case 'bindedRegionId':
        return (
          <FormItem
            {...ItemLayoutWithLabel}
            label={i18next.t('zones.fireInAssociatedZone')}
            style={fieldStyle}
            key={fieldName}
          >
            <span style={{ display: 'flex' }}>
              <Field
                name={fieldName}
                component={Select}
                options={this.getRegionsOptions(regions)}
                disabled={!active}
                style={{ width: '200px' }}
                filterOption={(input, option) => {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              />
              <Button
                style={{ marginLeft: '10px' }}
                onClick={() => change(`dependencyParams.${fieldName}`, '')}
                disabled={!bindedRegionId}
              >
                <DeleteOutlined />
              </Button>
            </span>
          </FormItem>
        );
      default:
        return null;
    }
  }

  getFireAlarmDependencyFields(dependencyFiledSettings) {
    const { isViewMode } = this.props;
    return Object.keys(dependencyFiledSettings).map(fieldName => {
      const { visible, active } = dependencyFiledSettings[fieldName];
      return this.getDependencyParamFieldByName(fieldName, visible, active && !isViewMode);
    });
  }

  render() {
    const {
      modalName,
      modalProps,
      isEdit,
      isViewMode,
      driverAttributes,
      createRegionInProgress,
      updateRegionInProgress,
      createRegionAndConnectDeviceInProgress,
      fireAlarmDependency,
      initialValues,
      handleSubmit,
      dirty,
      valid,
      form,
      needToAddOnlyOneRegion,
      errors
    } = this.props;

    return (
      <Modal
        name={modalName}
        title={i18next.t('zones.fireZoneParameters')}
        width="900px"
        {...modalProps}
      >
        <Form onSubmit={handleSubmit}>
          {!isEdit || isViewMode ? (
            <FormItem
              {...ItemLayoutWithLabel}
              required={true}
              label={i18next.t('quantity')}>
              <Field
                name="count"
                component={Input.Number}
                precision={0}
                defaultValue={1}
                min={1}
                max={10000}
                disabled={needToAddOnlyOneRegion}
              />
            </FormItem>
          ) : null}
          <FormItem
            {...ItemLayoutWithLabel}
            required={true}
            label={i18next.t('zones.zoneNumber')}
            validateStatus={errors.index ? 'error' : ''}
            help={errors.index ? errors.index : ''}>
            <Field
              name="index"
              component={Input.Number}
              precision={0}
              min={1}
              max={65535}
              disabled={isViewMode}
            />
          </FormItem>

          <FormItem
            {...ItemLayoutWithLabel}
            required={true}
            label={i18next.t('name')}
            validateStatus={errors.name ? 'error' : ''}
            help={errors.name ? errors.name : ''}>
            <Field name="name" component={Input} maxLength={256} disabled={isViewMode} />
          </FormItem>
          <FormItem {...ItemLayoutWithLabel} label={i18next.t('description')}>
            <Field
              name="description"
              type="textarea"
              rows={4}
              maxLength={256}
              disabled={isViewMode}
              component={Input.TextArea}
            />
          </FormItem>
          <FormItem {...ItemLayoutWithLabel} style={DISPLAY_NONE_STYLE}>
            <Field name="subsystem" component={Input} />
          </FormItem>
          <FormItem
            {...ItemLayoutWithLabel}
            label={i18next.t('zones.switchingToFireState')}
            style={
              driverAttributes.fireAlarmDependencies.length > 1 ? DISPLAY_FLEX_STYLE : DISPLAY_NONE_STYLE
            }
          >
            <Field
              name="fireAlarmDependency"
              component={Select}
              options={driverAttributes.fireAlarmDependencies
                .map(dependency => ({
                  value: dependency,
                  label: this.fireAlarmDependencyNames[dependency] || ''
                }))
                .sort((item1, item2) => item1.value.localeCompare(item2.value))}
              onChange={(event, newValue) => {
                const { dispatch } = this.props;
                const dependencyFiledSettings = FIRE_DEPENDENCY_FIELD_SETTINGS[newValue];
                const dependencyParams = {};
                Object.keys(dependencyFiledSettings).forEach(fieldName => {
                  dependencyParams[fieldName] = dependencyFiledSettings[fieldName].defaultValue;
                });
                dispatch(initialize(form, { ...initialValues, dependencyParams }, true));
              }}
              disabled={isViewMode}
            />
          </FormItem>
          <FormSection name="dependencyParams">
            <CardWrapper>
              <Card
                title={this.fireAlarmDependenciesTitles[fireAlarmDependency]}
                style={{ width: '800px', marginLeft: '35px' }}
              >
                {fireAlarmDependency &&
                  this.getFireAlarmDependencyFields(
                    FIRE_DEPENDENCY_FIELD_SETTINGS[fireAlarmDependency]
                  )}
              </Card>
            </CardWrapper>
          </FormSection>

          <FormItem {...SubmitItemLayout}>
            <Button
              type="primary"
              htmlType="submit"
              loading={
                !!(
                  createRegionInProgress ||
                  updateRegionInProgress ||
                  createRegionAndConnectDeviceInProgress
                )
              }
              disabled={(isEdit && !dirty) || !valid || isViewMode}
            >
              {isEdit ? i18next.t('buttons.edit') : i18next.t('buttons.create')}
            </Button>
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

const validate = (values) => {
  //TODO: Ограничить количество зон для добавления в прибор(#6699)
  let result = {};
  if (!values.index || !Number.isInteger(values.index) || values.index < 1 || values.index > 65535){
    result.index = i18next.t('zones.zoneNumberNotSet') }
  if (!values.name){
    result.name = i18next.t('zones.zoneNameNotSet') }
  return result;
};

FireRegionForm = reduxForm({
  validate
})(FireRegionForm);

const mapStateToProps = (state, props) => {
  const form = props.form ? props.form : FORM_NAME;
  const getFormValues = formValueSelector(form);
  return {
    form,
    createRegionInProgress: state.inProgress.createRegion,
    updateRegionInProgress: state.inProgress.updateRegion,
    createRegionAndConnectDeviceInProgress: state.inProgress.createRegionAndConnectDevice,
    driverAttributes: getCurrentDriverAttributes(state),
    regions: getCurrentProjectRegions(state),
    selectedRegionIds: state.widgets.selectedRegionIds,
    fireAlarmDependency: getFormValues(state, 'fireAlarmDependency'),
    fireOnFirstSensorRemainedOn: getFormValues(
      state,
      'dependencyParams.fireOnFirstSensorRemainedOn'
    ),
    errors: getFormSyncErrors(form)(state),
    bindedRegionId: getFormValues(state, 'dependencyParams.bindedRegionId'),
    initialValues: getFormInitialValues(form)(state)
  };
};

FireRegionForm = connect(mapStateToProps, null)(FireRegionForm);

export default FireRegionForm;
