import {
  PROJECT_LOAD_ACTIVE,
  PROJECT_UPDATE_STATUS,
  DEVICES_LOAD_ACTIVE,
  ACTIVE_DEVICE_UPDATED,
  ACTIVE_DEVICES_UPDATED,
  ACTIVE_DEVICE_SET_CONFIG,
  PROJECT_LOAD_CURRENT,
  DEVICE_ADD_NOTE,
  isLoaded
} from 'constants/actionTypes';

import { createDeviceTree, updateOneDeviceTreeItem, updateDeviceTreeItems } from 'helpers/device';

const HASH = 'hash',
  TREE = 'tree';

export default function devices(state = null, { type, payload }) {
  switch (type) {
    case isLoaded(DEVICES_LOAD_ACTIVE, true): {
      const devices = payload;
      return createDeviceTree(devices);
    }
    case isLoaded(PROJECT_LOAD_ACTIVE, true): {
      const { devices } = payload;
      return createDeviceTree(devices);
    }
    case isLoaded(PROJECT_LOAD_CURRENT, true): {
      const { project, devices } = payload;
      if (project.status !== 'ACTIVE') return state;
      return createDeviceTree(devices);
    }
    case isLoaded(PROJECT_UPDATE_STATUS, true): {
      const { project, devices } = payload;
      if (project.status !== 'ACTIVE') return null;
      return createDeviceTree(devices);
    }
    case isLoaded(DEVICE_ADD_NOTE, true):
    case isLoaded(ACTIVE_DEVICE_SET_CONFIG, true): {
      const { device: updatedDevice } = payload;
      if (!state) return state;
      const devicesHash = { ...state };
      devicesHash[HASH] = { ...devicesHash[HASH] };
      devicesHash[TREE] = [...devicesHash[TREE]];

      updateOneDeviceTreeItem(devicesHash[HASH], devicesHash[TREE], updatedDevice);

      return devicesHash;
    }
    case ACTIVE_DEVICE_UPDATED: {
      const updatedDevice = payload;
      if (!state) return state;
      const devices = { ...state };
      devices[HASH] = { ...devices[HASH] };
      devices[TREE] = [...devices[TREE]];

      updateOneDeviceTreeItem(devices[HASH], devices[TREE], updatedDevice);

      return devices;
    }

    case ACTIVE_DEVICES_UPDATED: {
      const updatedDevices = payload;
      if (!state) return state;

      const devices = { ...state };
      devices[HASH] = { ...devices[HASH] };
      devices[TREE] = [...devices[TREE]];

      updateDeviceTreeItems(devices[HASH], devices[TREE], updatedDevices);
      return devices;
    }
    default:
      return state;
  }
}
