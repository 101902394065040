import React from 'react';
import { Tooltip } from 'antd';
import styled from 'styled-components';

const Title = styled.div`
  padding: 0 15px;
  color: ${p => (p.color ? p.color : 'white')};
  text-decoration: ${p => (p.textDecoration ? p.textDecoration : 'none')};
  font-size: 14px;
  max-width: var(--max-title-width, 500px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

TitleTooltip.defaultProps = {
  needTooltip: true
};

function TitleTooltip({ children, needTooltip, ...otherProps }) {
  return needTooltip ? (
    <Tooltip title={children}>
      <Title {...otherProps}>{children}</Title>
    </Tooltip>
  ) : (
    <Title {...otherProps}>{children}</Title>
  );
}

export default TitleTooltip;
