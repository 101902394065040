/**
 * Зоны.
 * Все REST-методы, на которые ссылаются ниже описанные action-ы, описаны в тех. проекте:
 * (https://docs.google.com/document/d/19fDadv_CBaeP-RlnnSvsjpdx7BCqSeax4MlCHjeEW28/edit#heading=h.83lhbzrbi7pd).
 */

/**
 * Загрузить все зоны открытого для редактирования проекта
 * REST-метод: GET api/v1/projects/<projectId>/regions
 */
export const REGIONS_LOAD = 'REGIONS_LOAD';
/**
 * Загрузить неизменяемые данные для работы с зонами
 * REST-метод: GET api/v1/region_dictionaries
 */
export const REGION_CONSTANTS_LOAD = 'REGION_CONSTANTS_LOAD';
/**
 * Обновить зону
 * REST-метод: PUT api/v1/projects/<projectId>/regions/<regionId>
 */
export const REGION_UPDATE = 'REGION_UPDATE';
/**
 * Уведомление об изменении зоны.
 * Экшен дожен срабатывать при получении сообщения по WS.
 */
export const ACTIVE_REGION_UPDATED = 'ACTIVE_REGION_UPDATED';
/**
 * Уведомление об изменении нескольких зон.
 * Экшен дожен срабатывать при получении сообщения по WS.
 */
export const ACTIVE_REGIONS_UPDATED = 'ACTIVE_REGIONS_UPDATED';

// Выбрать зоны в проекте
export const SELECT_REGIONS = 'SELECT_REGIONS';
/**
 * Создать зону
 * REST-метод: POST api/v1/projects/<projectId>/regions
 */
export const REGION_CREATE = 'REGION_CREATE';
/**
 * Удалить зону
 * REST-метод: DELETE api/v1/projects/<projectId>/regions/<regionId>
 */
export const REGION_DELETE = 'REGION_DELETE';
/**
 * Подключить устройство к зоне
 * REST-метод: PUT api/v1/projects/<projectId>/regions/<regionId>/devices/<deviceId>
 */
export const REGION_NEW_DEVICE = 'REGION_NEW_DEVICE';
/**
 * Подключить список устройство к зоне
 * REST-метод: PUT api/v1/projects/<projectId>/regions/<regionId>/devices
 */
export const REGION_NEW_DEVICE_LIST = 'REGION_NEW_DEVICE_LIST';
/**
 * Отключить устройство от зоны
 * REST-метод: DELETE api/v1/projects/<projectId>/regions/<regionId>/devices/<deviceId>
 */
export const REGION_DELETE_DEVICE = 'REGION_DELETE_DEVICE';
/**
 * Отключить список устройств от зоны
 * REST-метод: DELETE api/v1/projects/<projectId>/regions/<regionId>/devices
 */
export const REGION_DELETE_DEVICE_LIST = 'REGION_DELETE_DEVICE_LIST';

/**
 * Отключить все устройства от списка зон
 * REST-метод: DELETE api/v1/projects/<projectId>/regions/devices/?region_ids={regionIds}
 */
export const REGIONS_DELETE_ALL_DEVICE_LISTS = 'REGIONS_DELETE_DEVICES_LIST';
/**
 * Обновление расположения зон на плане
 * REST-метод: PUT /api/v1/projects/${projectId}/region_views/${regionId}?plan_layouts
 **/
export const REGION_UPDATE_PLAN_LAYOUTS = 'REGION_UPDATE_PLAN_LAYOUTS';
/**
 * Групповое обновление расположения зон на плане
 * REST-метод: PUT /api/v1/projects/${projectId}/region_views/${regionId}?plan_layouts
 **/
export const REGIONS_UPDATE_PLAN_LAYOUTS = 'REGIONS_UPDATE_PLAN_LAYOUTS';

/* Действия с зонами активного проекта, используемые в ОЗ */

// Выбрать активную зону в виджете отображения поддерева устройств по зонам:
export const REGION_VIEWER_UPDATE_CURRENT = 'REGION_VIEWER_UPDATE_CURRENT';
/**
 * Загрузить все зоны активного проекта
 * REST-метод: GET api/v1/projects/<projectId>/active_regions
 * Payload sagas: { projectId }
 * Payload reducers (см. описание метода в тех. проекте): [{
 *  id: <string>,
 *  name: <string>,
 *  description: <string>,
 *  index: <uint>,
 *  subsystem: <string>,
 *  generalStateCategoryView: {
 *    name: <string>,
 *    description: <string>,
 *    color: <string>,
 *    fontColor: <string>,
 *    iconName: <string>,
 *  }
 * }, …]
 * Store: state.activeProject.regions:[...]
 */
export const REGIONS_LOAD_ACTIVE = 'REGIONS_LOAD_ACTIVE';
/**
 * Постановка/снятие зоны на охрану
 * REST-метод: POST /api/v1/issues
 * Payload: {
 *   projectId: <string>,
 *   regionId: <string>,
 *   onGuard: <bool>
 * }
 */
export const REGION_CHANGE_GUARD_STATUS = 'REGION_CHANGE_GUARD_STATUS';
// Изменить фильтр зон по подсистемам
export const REGIONS_CHANGE_FILTER = 'REGIONS_CHANGE_FILTER';
// Создать зону и привязать к ней устройство
export const REGION_CREATE_AND_CONNECT_DEVICE = 'REGION_CREATE_AND_CONNECT_DEVICE';
// Скопировать и вставить зоны на план
export const COPY_AND_PASTE_REGIONS_ON_PLAN = 'COPY_AND_PASTE_REGIONS_ON_PLAN';
