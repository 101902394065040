import { takeEvery } from 'redux-saga/effects';
import { fetch, dispatchSuccess, dispatchFail } from 'helpers/request';
import { DRIVER_ATTRIBUTES_LOAD, DRIVER_DESCRIPTION_LOAD } from 'constants/actionTypeModules/other';

function* getDriverAttributes({ payload: { driverProfileId } }) {
  try {
    const response = yield fetch(`/driver/${driverProfileId}/attributes`);
    yield dispatchSuccess(DRIVER_ATTRIBUTES_LOAD, response);
  } catch (error) {
    yield dispatchFail(DRIVER_ATTRIBUTES_LOAD, error.message);
  }
}

function* getDriverDescription({ payload: { driverProfileId } }) {
  try {
    const response = yield fetch(`/driver/${driverProfileId}/description`);

    yield dispatchSuccess(DRIVER_DESCRIPTION_LOAD, response);
  } catch (error) {
    yield dispatchFail(DRIVER_DESCRIPTION_LOAD, error.message);
  }
}

export default function* driversSagas() {
  yield takeEvery(DRIVER_ATTRIBUTES_LOAD, getDriverAttributes);
  yield takeEvery(DRIVER_DESCRIPTION_LOAD, getDriverDescription);
}
