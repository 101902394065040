import React, { useEffect, useRef, useState } from 'react';

import Icon, {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  BoldOutlined,
  ItalicOutlined,
  SaveTwoTone,
  SettingFilled
} from '@ant-design/icons';
import { Button, Checkbox, Input, Popover, Toolbar, Tooltip } from 'components';
import styles from './PlanEditorToolbar.module.css';
import Unit from 'constants/unit.js';
import { convertUnit } from 'helpers/planEditor';
import i18next from 'i18next';
import { Colorpicker } from 'antd-colorpicker';
import { Column, Row } from 'components/Layout';
import { Radio, Select } from 'antd';
import { Option } from 'antd/es/mentions';
import { InputNumber } from 'components/Input';
import { isEqual } from 'lodash';
import ButtonGroup from 'antd/es/button/button-group';

export const TOOLBAR_ITEMS = {
  SELECT_ELEMENTS_BUTTON: 'selectElements',
  MOVE_CANVAS_BUTTON: 'moveCanvas',
  CREATE_REGION_RECT_BUTTON: 'createRegionRect',
  CREATE_REGION_POLYGON_BUTTON: 'createRegionPolygon',
  GRID_SHOW_FLAG: 'isShowGrid',
  PIN_TO_GRID_FLAG: 'pinToGrid',
  GRID_SIZE: 'gridSize',
  DELETE_PLAN_ELEMENT_BUTTON: 'deletePlanElement',
  SET_BACKGROUND_BUTTON: 'setBackground',
  ADD_TEXT_BUTTON: 'addText'
};

const getUnitNames = () =>
  new Map([
    [Unit.METERS, `(${i18next.t('meters')})`],
    [Unit.PIXELS, `(${i18next.t('pixels')})`]
  ]);

export const textFonts = {
  name: 'font',
  values: [
    'Times New Roman',
    'Helvetica',
    'Courier',
    'Arial Black',
    'Verdana',
    'Tahoma',
    'Impact',
    'Brush Script MT',
    'Comic Sans MS'
  ]
};

export const fontsStyle = {
  name: i18next.t('fontStyle'),
  icon: ItalicOutlined,
  values: { normal: false, italic: true }
};

export const fontsWeight = {
  name: i18next.t('fontWeight'),
  icon: BoldOutlined,
  values: { normal: false, bold: true }
};

export const textAligns = {
  name: 'textAlign',
  values: { left: AlignLeftOutlined, center: AlignCenterOutlined, right: AlignRightOutlined }
};

const pinToGridTool = {
  key: TOOLBAR_ITEMS.PIN_TO_GRID_FLAG,
  type: 'flag'
};

function Title({ text, isActive = false, onClickSave = {} }) {
  const color = isActive ? '' : '#9e9593';
  return (
    <div className={styles.title}>
      <span>{text}</span>
      <Tooltip title={i18next.t('buttons.save')}>
        <Button
          isActive={isActive}
          icon={() => <SaveTwoTone twoToneColor={color} style={{ fontSize: 22 }} />}
          onClick={onClickSave}
        />
      </Tooltip>
    </div>
  );
}

function GridPopoverContent({ plan, gridSize, pinToGrid, onChange = {}, onClick = {} }) {
  return (
    <div className={styles.popover_content}>
      <Input
        addonBefore={i18next.t('cellSize')}
        addonAfter={getUnitNames().get(plan.unit)}
        value={gridSize}
        step={plan.unit === Unit.PIXELS ? 1 : 0.1}
        precision={plan.unit === Unit.PIXELS ? 0 : 1}
        type={'number'}
        onChange={e => {
          onChange(Number(e.target.value));
        }}
      />
      <Checkbox checked={pinToGrid} onChange={e => onClick(e.target.checked)}>
        {i18next.t('plans.tools.lockObjectsToGrid')}
      </Checkbox>
    </div>
  );
}

function GridSettings({ tool, onChange, onButtonClick, gridSize, pinToGrid, plan }) {
  const initGridSize = convertUnit(Unit.PIXELS, plan.unit, gridSize);

  const [visible, setVisible] = useState(false);
  const [_gridSize, _setGridSize] = useState(initGridSize);
  const [_pinToGrid, _setPinToGrid] = useState(pinToGrid);

  const planSize = convertUnit(
    Unit.PIXELS,
    plan.unit,
    plan.xSize < plan.ySize ? plan.xSize : plan.ySize
  );
  const isValidGridSize = _gridSize < planSize && _gridSize > 0;
  const isActiveSaveButton =
    (_gridSize !== initGridSize || _pinToGrid !== pinToGrid) && isValidGridSize;

  return (
    <Popover
      className={styles.popover}
      content={
        <GridPopoverContent
          plan={plan}
          gridSize={_gridSize}
          pinToGrid={_pinToGrid}
          onChange={value => {
            let newValue = Math.floor(value * 10) / 10;
            if (Math.sign(newValue) !== -1) _setGridSize(newValue);
          }}
          onClick={value => _setPinToGrid(value)}
        />
      }
      placement="right"
      title={
        <Title
          text={i18next.t('plans.settingUpGrid')}
          isActive={isActiveSaveButton}
          onClickSave={() => {
            if (_gridSize !== initGridSize)
              onChange(tool, convertUnit(plan.unit, Unit.PIXELS, _gridSize));
            if (_pinToGrid !== pinToGrid) onButtonClick(pinToGridTool);
          }}
        />
      }
      trigger="click"
      visible={visible}
      onVisibleChange={visible => {
        if (visible) {
          _setGridSize(initGridSize);
          _setPinToGrid(pinToGrid);
        }
        setVisible(visible);
      }}
    >
      <SettingFilled className={styles.icon} />
      <div className={styles.triangle} />
    </Popover>
  );
}

function TextPopoverContent({
  fontSize,
  fontStyle,
  fontFamily,
  color,
  textAlign,
  fontWeight,
  setActualConfig
}) {
  const [_color, _setColor] = useState(color);
  const [_fontSize, _setFontSize] = useState(fontSize);
  const [_fontFamily, _setFontFamily] = useState(fontFamily);
  const [_fontStyle, _setFontStyle] = useState(fontStyle);
  const [_fontWeight, _setFontWeight] = useState(fontWeight);

  useEffect(() => {
    setActualConfig({
      fontSize: _fontSize,
      fontStyle: _fontStyle,
      fontFamily: _fontFamily,
      color: _color,
      textAlign,
      fontWeight: _fontWeight
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_color, _fontSize, _fontFamily, _fontStyle, _fontWeight]);

  return (
    <div className={styles.popover_content}>
      <Row style={{ alignItems: 'center' }}>
        <Column span={8}>
          <span>{i18next.t('fontSize')}:</span>
        </Column>
        <Column span={8} className={styles.column}>
          <InputNumber
            className={styles.font_size}
            value={_fontSize}
            step={1}
            onChange={val => _setFontSize(val)}
            max={250}
            min={1}
          />
        </Column>
      </Row>
      <Row style={{ alignItems: 'center' }}>
        <Column span={8}>
          <span>{i18next.t('fontColor')}: </span>
        </Column>
        <Column span={8} className={styles.column}>
          <Colorpicker
            blockStyles={{ width: '100%' }}
            popup
            value={_color}
            onColorResult={color => {
              _setColor(color.hex);
            }}
          />
        </Column>
      </Row>
      <Row style={{ alignItems: 'center' }}>
        <Column span={8}>
          <span>{i18next.t('font')}: </span>
        </Column>
        <Column span={8} className={styles.column}>
          <Select value={_fontFamily} onChange={nextFont => _setFontFamily(nextFont)}>
            {textFonts.values.map(item => {
              return <Option key={item}>{item}</Option>;
            })}
          </Select>
        </Column>
      </Row>
      <Row style={{ alignItems: 'center' }}>
        <Column span={8}>
          <span>{i18next.t('fontStyle')}: </span>
        </Column>
        <Column span={8} className={styles.column}>
          <ButtonGroup className={styles.font_style_container}>
            {getCheckBoxIconButton(fontsStyle, _fontStyle, _setFontStyle)}
            {getCheckBoxIconButton(fontsWeight, _fontWeight, _setFontWeight)}
          </ButtonGroup>
        </Column>
      </Row>
    </div>
  );
}

function getCheckBoxIconButton(styleObject, actualValueParam, setActualValueParam) {
  const actualValue = styleObject.values[actualValueParam];
  const Icon = styleObject.icon;
  return (
    <Icon
      title={styleObject.name}
      className={styles.font_style}
      style={{
        borderWidth: `${actualValue ? '0px' : '1px'}`,
        backgroundColor: `${actualValue ? 'silver' : 'inherit'}`
      }}
      onClick={() => {
        const keyToValueArray = Object.entries(styleObject.values).find(
          ([key, value]) => value === !actualValue
        );
        if (keyToValueArray[0]) {
          setActualValueParam(keyToValueArray[0]);
        }
      }}
    />
  );
}

export function getFontSettingRadioButtons(fontSetting) {
  return Object.entries(fontSetting.values).reduce((currentArray, [key, value]) => {
    currentArray.push(
      <Radio.Button value={key}>
        {<Icon component={value} style={{ padding: '3.5px' }} />}
      </Radio.Button>
    );
    return currentArray;
  }, []);
}

function TextSettings({ onChange, textConfigValues, tool, textSettingsPopupRef }) {
  const { fontSize, fontStyle, fontFamily, color, textAlign, fontWeight } = textConfigValues;
  const [isChanged, setIsChanged] = useState(false);
  const [actualConfig, setActualConfig] = useState({
    fontSize,
    fontStyle,
    fontFamily,
    color,
    fontWeight
  });

  const initConfig = useRef({ fontSize, fontStyle, fontFamily, color });
  useEffect(() => {
    setIsChanged(!isEqual(initConfig.current, actualConfig));
  }, [initConfig, actualConfig]);
  return (
    <Popover
      ref={textSettingsPopupRef}
      className={styles.popover}
      content={
        <TextPopoverContent
          fontSize={fontSize}
          fontStyle={fontStyle}
          fontFamily={fontFamily}
          color={color}
          textAlign={textAlign}
          fontWeight={fontWeight}
          setActualConfig={setActualConfig}
        />
      }
      placement="right"
      title={
        <Title
          text={i18next.t('plans.settingUpText')}
          isActive={isChanged}
          onClickSave={() => {
            onChange(tool, actualConfig);
          }}
        />
      }
      trigger="click"
    >
      <SettingFilled className={styles.icon} />
      <div className={styles.triangle} />
    </Popover>
  );
}

const getTools = () => [
  {
    key: TOOLBAR_ITEMS.SELECT_ELEMENTS_BUTTON,
    title: i18next.t('selection'),
    icon: 'cursor',
    customIcon: true,
    selectable: true,
    type: 'button'
  },
  {
    key: TOOLBAR_ITEMS.MOVE_CANVAS_BUTTON,
    title: i18next.t('plans.tools.moveButton'),
    icon: 'arrows',
    customIcon: true,
    selectable: true,
    type: 'button'
  },
  {
    key: TOOLBAR_ITEMS.CREATE_REGION_RECT_BUTTON,
    title: i18next.t('plans.tools.createRectZone'),
    icon: 'rect',
    customIcon: true,
    selectable: true,
    type: 'button'
  },
  {
    key: TOOLBAR_ITEMS.CREATE_REGION_POLYGON_BUTTON,
    title: i18next.t('plans.tools.createPolygonalZone'),
    icon: 'polygon',
    customIcon: true,
    selectable: true,
    type: 'button'
  },
  {
    key: TOOLBAR_ITEMS.ADD_TEXT_BUTTON,
    title: i18next.t('plans.tools.addText'),
    icon: 'text',
    customIcon: true,
    selectable: true,
    type: 'button',
    Custom: ({ Head, tool, isSwitchedOn, isActive, textConfigValues, ...other }) => (
      <div className={styles.grid_settings_item}>
        {Head(tool, isSwitchedOn)}
        {other.plan && isSwitchedOn && textConfigValues && isActive ? (
          <TextSettings tool={tool} textConfigValues={textConfigValues} {...other} />
        ) : null}
      </div>
    )
  },
  {
    key: TOOLBAR_ITEMS.GRID_SHOW_FLAG,
    title: i18next.t('plans.tools.showGrid'),
    icon: 'grid',
    customIcon: true,
    type: 'flag',
    Custom: ({ Head, tool, gridSize, pinToGrid, isSwitchedOn, isActive, ...other }) => (
      <div className={styles.grid_settings_item}>
        {Head(tool, isSwitchedOn)}
        {other.plan && gridSize && typeof pinToGrid === 'boolean' && isSwitchedOn && isActive ? (
          <GridSettings tool={tool} pinToGrid={pinToGrid} gridSize={gridSize} {...other} />
        ) : null}
      </div>
    )
  },
  {
    key: TOOLBAR_ITEMS.SET_BACKGROUND_BUTTON,
    title: i18next.t('plans.addingNewImages'),
    icon: 'gallery',
    customIcon: true,
    selectable: false,
    type: 'button'
  },
  {
    key: TOOLBAR_ITEMS.DELETE_PLAN_ELEMENT_BUTTON,
    title: i18next.t('buttons.deleteObject'),
    icon: 'delete',
    customIcon: false,
    selectable: false,
    type: 'button',
    outerRefName: 'deleteObjectRef'
  }
];
const PlanEditorToolbar = props => (
  <Toolbar name={'PlanEditorToolbar'} tools={getTools()} {...props} />
);

export default PlanEditorToolbar;
