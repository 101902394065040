import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import NavLink from 'components/Nav/NavLink';
import { Tab } from 'components/Tabs';
import { Badge } from 'components';

import { setTimestamp } from 'actions/widgets';
import { usePrevious } from 'customHooks/usePrevious';
import { PATHS } from 'constants/routes';
import i18next from 'i18next';

const OperativeTask = ({ isAvailableActiveProject, newEvents, user, dispatch }) => {
  const [newEventsCounter, setEventCount] = useState(0);
  const location = useLocation();
  const isOsPlanPage =
    location.pathname === PATHS.OP.PLAN || location.pathname === PATHS.OP.PLAN + '/';

  const oldEvents = usePrevious(newEvents);

  const onOperatingSystemClick = () => {
    if (isAvailableActiveProject) {
      setEventCount(0);
    }
  };

  //Считает новые события и устанавливает timestamp для выделения новых событий в таблице
  useEffect(() => {
    if (!newEvents || !newEvents.length) return;
    if (newEvents !== oldEvents && !isOsPlanPage) {
      let startTime = 0;
      const count = newEvents.reduce((sum, event) => {
        const userInfo = event.userEventInfo ? event.userEventInfo : null;
        if (!userInfo || userInfo.idRef !== user.id) {
          if (!newEventsCounter && (startTime === 0 || event.received < startTime)) {
            startTime = event.received;
          }
          return sum + 1;
        } else return sum;
      }, 0);
      setEventCount(val => val + count);
      if (startTime) dispatch(setTimestamp('lastEventTime', startTime));
    }
  }, [dispatch, isOsPlanPage, newEvents, newEventsCounter, oldEvents, user.id]);

  //Сбрасываем счетчик если проект не активирован
  useEffect(() => {
    if ((isOsPlanPage || !isAvailableActiveProject) && newEventsCounter) {
      setEventCount(0);
    }
  }, [isAvailableActiveProject, newEventsCounter, isOsPlanPage]);

  return (
    <NavLink
      isActive={(match, location) => location.pathname.includes('/op/')}
      to="/op/plan"
      disabled={!isAvailableActiveProject}
      activeClassName="active"
    >
      <Badge count={newEventsCounter} overflowCount={99} offset={[-7, 12]} size={'small'}>
        <Tab
          className={isAvailableActiveProject ? '' : 'disabled'}
          onClick={onOperatingSystemClick}
        >
          {i18next.t('operationalTask.name')}
        </Tab>
      </Badge>
    </NavLink>
  );
};

const mapStateToProps = state => {
  return {
    newEvents: state.activeProject.logEvents.newEvents,
    user: state.currentUser.id ? state.currentUser : false,
    isAvailableActiveProject: getIsAvailableActiveProject(state.activeProject.activeProject)
  };
};

const getIsAvailableActiveProject = activeProject => {
  return activeProject && activeProject.status === 'ACTIVE';
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OperativeTask);
