import React, { Component } from 'react';

import styled from 'styled-components';

const DeviceWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  top: 2px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 250px;
  & div {
    margin-right: 7px;
  }
  opacity: ${p => (p.isActive ? '1' : '0.4')};
`;
/**
 * @property isActive активен ли элемент
 *
 */
export default class Device extends Component {
  static defaultProps = {
    isActive: true
  };

  render() {
    const { children, ...restProps } = this.props;
    return <DeviceWrapper {...restProps}>{children}</DeviceWrapper>;
  }
}
