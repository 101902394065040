import React from 'react';

import styled from 'styled-components';

const StatusImgWrapper = styled.div`
  position: relative;
  left: ${props => (props.left ? `${props.left}` : '0px')};
  top: ${props => (props.top ? `${props.top}` : '0px')};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: ${props => (props.size ? `${props.size}` : '23px')};
`;

class StatusImg extends React.Component {
  render() {
    const { size, left, top, name } = this.props;
    const s = size ? size : 23;
    return (
      <StatusImgWrapper size={s} left={left} top={top}>
        <img width={s} height={s} src={`/public/img/status/${name}.svg`} alt={name} />
      </StatusImgWrapper>
    );
  }
}

export default StatusImg;
