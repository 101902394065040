import { createSelector } from 'reselect';

import SUBSYSTEMS from 'constants/subsystems';
import { EMPTY_OBJECT, EMPTY_ARRAY } from 'constants/common';
import { findDevicesByDeviceCategory, getAllRootAddresses } from './device';
import { CATEGORY } from 'constants/device';

const HASH = 'hash',
  TREE = 'tree',
  GROUPS = 'groups',
  PANELS = 'panels';

/**
 * Получить текущий проект.
 * @param {String} state.currentProjectId
 * @param {Object{}} state.projects
 * @return {Object}
 */
export const getCurrentProject = createSelector(
  ({ currentProjectId }) => currentProjectId,
  ({ projects }) => projects,
  (currentProjectId, projects) => {
    return currentProjectId && projects[currentProjectId]
      ? projects[currentProjectId]
      : EMPTY_OBJECT;
  }
);

/**
 * Проверить, что текущий проект - активен.
 * @param {String} state.currentProjectId
 * @param {Object{}} state.projects
 * @return {bool|null}
 */
export function checkCurrentProjectIsActive({ currentProjectId, projects }) {
  return currentProjectId && projects[currentProjectId]
    ? projects[currentProjectId].status === 'ACTIVE'
    : null;
}

export const getCurrentProjectDevicesHash = createSelector(
  ({ currentProjectId }) => currentProjectId,
  ({ devices }) => devices,
  (currentProjectId, devices) =>
    !!currentProjectId && !!devices && !!devices[currentProjectId]
      ? devices[currentProjectId][HASH]
      : EMPTY_OBJECT
);

/**
 * Получить список устройств текущего проекта.
 * @param {String} state
 * @return {Array}
 */
export const getCurrentProjectDeviceList = createSelector(
  getCurrentProjectDevicesHash,
  devicesHash =>
    Object.values(devicesHash).map(device => {
      const { children, ...justDevice } = device;
      return justDevice;
    })
);

export const getEnabledControlDevices = createSelector(
  [(state, devices) => findDevicesByDeviceCategory(state, CATEGORY.CONTROL.id, devices)],
  controlDevices => controlDevices.filter(device => device.usableVirtualStates && !device.disabled)
);

/**
 * Получить дерево устройств текущего проекта.
 * @param {Object[]} state.devices
 * @return {Array}
 */
export const getCurrentProjectDeviceTree = createSelector(
  ({ currentProjectId }) => currentProjectId,
  ({ devices }) => devices,
  (currentProjectId, devices) =>
    currentProjectId
      ? devices[currentProjectId]
        ? devices[currentProjectId][TREE]
        : EMPTY_ARRAY
      : EMPTY_ARRAY
);

export const getRootDeviceAddresses = createSelector(
  getCurrentProjectDeviceTree, //
  deviceTree => getAllRootAddresses(deviceTree)
);

export const getCurrentProjectRegionsHash = createSelector(
  ({ currentProjectId }) => currentProjectId,
  ({ regions }) => regions,
  (currentProjectId, regions) => {
    if (!currentProjectId || !regions[currentProjectId]) return EMPTY_OBJECT;
    return regions[currentProjectId];
  }
);

/**
 * Получить зоны (без отображений) текущего проекта.
 * @param {String} state.currentProjectId
 * @param {Object[]} state.regions
 * @return {Array}
 */
export const getCurrentProjectRegions = createSelector(getCurrentProjectRegionsHash, regionsHash =>
  Object.values(regionsHash)
    .map(regionView => ({ ...regionView.region }))
    .sort((regionA, regionB) => regionA.index - regionB.index)
);

/**
 * Получить зоны (без отображений) текущего проекта сгруппированные по подсистемам
 * @param {Object} state
 * @return {Object}
 */
export const getCurrentProjectRegionsGroupedBySubsystem = createSelector(
  getCurrentProjectRegions,
  (regions = []) =>
    regions.reduce((hash, region) => {
      if (!hash[region.subsystem]) {
        hash[region.subsystem] = [];
      }
      hash[region.subsystem].push(region);
      return hash;
    }, {})
);

/**
 * Получить отображения зон текущего проекта.
 * @param {String} state.currentProjectId
 * @param {Object[]} state.regions
 * @return {Array}
 */
export const getCurrentProjectRegionViews = createSelector(
  getCurrentProjectRegionsHash,
  regionsHash => Object.values(regionsHash)
);

/**
 * Получить зоны текущего проекта отфильтрованные по подсистеме
 * @param {Object} state - redux-store
 * @param {String} subsystem - Название подсистемы
 * @return {Array} - список зон
 */
export const getCurrentProjectRegionsBySubsystem = (state, subsystem) => {
  const currentProjectRegions = [...getCurrentProjectRegions(state)].sort(function(a, b) {
    return SUBSYSTEMS[a.subsystem].index - SUBSYSTEMS[b.subsystem].index;
  });
  if (!subsystem || subsystem === 'ALL') return currentProjectRegions;
  return currentProjectRegions.filter(region => region.subsystem === subsystem);
};

/**
 * Получить список помещений текущего проекта
 * @param {String} state
 * @return {Array}
 */
export const getCurrentProjectPlans = createSelector(getCurrentProjectPlansHash, devicesHash =>
  Object.values(devicesHash)
);

export function getCurrentProjectPlansHash({ currentProjectId, plans }) {
  if (!plans[currentProjectId]) return EMPTY_OBJECT;
  return plans[currentProjectId];
}

/**
 * Получить группы планов помещений текущего проекта.
 * @param {String} state
 * @return {Array}
 */
export const getCurrentProjectPlanGroups = createSelector(
  getCurrentProjectPlanGroupsHash,
  devicesHash => Object.values(devicesHash)
);

export function getCurrentProjectPlanGroupsHash({ currentProjectId, planGroups }) {
  if (!planGroups[currentProjectId]) return EMPTY_OBJECT;
  return planGroups[currentProjectId];
}

/**
 * Получить сценарии текущего проекта
 * @param {String} state.currentProjectId
 * @param {Array[]} state.scenarios
 * @return {Array}
 */
export const getCurrentProjectScenarios = createSelector(
  getCurrentProjectScenariosHash,
  scenariosHash =>
    Object.values(scenariosHash).sort(
      (prevScenario, nextScenario) => prevScenario.index - nextScenario.index
    )
);

export function getCurrentProjectScenariosHash({ currentProjectId, scenarios }) {
  if (!currentProjectId || !scenarios[currentProjectId]) return EMPTY_OBJECT;
  return scenarios[currentProjectId];
}

/**
 * Получить список групп панелей индикаторов
 * @param {String} state
 * @return {Array}
 */
export const getCurrentProjectIndicatorGroupsList = createSelector(
  getCurrentProjectIndicatorGroupsHash,
  groupsHash => Object.values(groupsHash)
);
/**
 * Получить хеш групп панелей индикаторов
 * @param {String} state
 * @return {Object}
 */
export function getCurrentProjectIndicatorGroupsHash({ currentProjectId, indicators }) {
  if (!indicators[GROUPS][currentProjectId]) return EMPTY_OBJECT;
  return indicators[GROUPS][currentProjectId];
}

/**
 * Получить хеш панелей индикаторов
 * @param {String} state
 * @return {Object}
 */
export function getCurrentProjectIndicatorPanelsHash({ currentProjectId, indicators }) {
  if (!indicators[PANELS][currentProjectId]) return EMPTY_OBJECT;
  return indicators[PANELS][currentProjectId];
}

export const getCurrentProjectIndicatorPanelsInSelectedGroup = createSelector(
  [
    getCurrentProjectIndicatorPanelsHash,
    ({ widgets: { selectedIndicatorGroupId } }) => selectedIndicatorGroupId
  ],
  (indicatorPanels, selectedIndicatorGroupId) =>
    Object.values(indicatorPanels).filter(
      indicatorPanel => indicatorPanel.indicatorGroupId === selectedIndicatorGroupId
    )
);

/**
 * Получить список виртуальных состояний
 * @param {String} state
 * @return {Array}
 */
export const getCurrentProjectVirtualStates = createSelector(
  getCurrentProjectVirtualStateHash,
  virtualStateHash => Object.values(virtualStateHash)
);

/**
 * Получить список классов состояния
 * @param {String} state
 * @return {Array}
 */
export const getStateCategoriesList = createSelector(getStateCategoriesHash, stateCategories =>
  Object.values(stateCategories)
);

/**
 * Получить список виртуальных счетчиков
 * @param {String} - state
 * @return {Array} - список объектов счетчиков
 */
export const getCurrentProjectVirtualCounters = createSelector(
  getCurrentProjectVirtualCountersHash,
  virtualCounter => Object.values(virtualCounter)
);

export function getCurrentProjectVirtualCountersHash({ currentProjectId, virtualCounters }) {
  if (!virtualCounters[currentProjectId]) return EMPTY_OBJECT;
  return virtualCounters[currentProjectId];
}

export function getCurrentProjectVirtualStateHash({ currentProjectId, virtualStates }) {
  if (!virtualStates[currentProjectId]) return EMPTY_OBJECT;
  return virtualStates[currentProjectId];
}

export function getStateCategoriesHash({ stateCategoryViewsHash }) {
  return stateCategoryViewsHash;
}
