import { PROJECT_SELECT, PROJECT_DELETE, isLoaded } from 'constants/actionTypes';

const defaultState = {
  selected: {}
};

export default function projects(state = defaultState, action) {
  switch (action.type) {
    case isLoaded(PROJECT_DELETE, true): {
      const newState = state;
      newState.selected = newState.selected.id === action.payload ? {} : newState.selected;
      return newState;
    }
    case PROJECT_SELECT: {
      return {
        ...state,
        selected: action.payload
      };
    }
    default:
      return state;
  }
}
