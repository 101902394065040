import { createSelector } from 'reselect';
import { filterEntities } from 'helpers/filtration';
import { getCurrentUser } from 'helpers/user';

import { getActiveScenariosFilter } from 'helpers/widgets';

const HASH = 'hash',
  TREE = 'tree',
  GROUPS = 'groups',
  PANELS = 'panels';

/**
 * Получить список устройств активного проекта
 * @param {Object} state
 * @return {Array|null}
 */
export const getActiveDeviceList = createSelector(
  getActiveDevicesHash, //
  devicesHash =>
    devicesHash
      ? Object.values(devicesHash).map(device => {
          const { children, ...justDevice } = device;
          return justDevice;
        })
      : null
);

/**
 * Получить хеш устройств активного проекта
 * @param {Object} state
 * @return {Object|null}
 */
export function getActiveDevicesHash({ activeProject: { devices } }) {
  if (!devices) return null;
  return devices[HASH];
}

/**
 * Присутствуют ли на планах устройства
 * @param {Object} state
 * @return {Object} - список id планов с флагом
 */
export const isActiveDevicesPlanLayouts = createSelector(getActiveDeviceList, devices =>
  devices
    ? devices.reduce((previousValue, currentValue) => {
        if (currentValue.planLayouts?.length) {
          currentValue.planLayouts.forEach(planLayout => {
            if (!!planLayout.planId && !previousValue[planLayout.planId]) {
              previousValue[planLayout.planId] = true;
            }
          });
        }
        return previousValue;
      }, {})
    : {}
);

/**
 * Получить дерево устройств активного проекта
 * @param {Object{}} state.activeProject.devices
 * @return {Array|null}
 */
export function getActiveDeviceTree({ activeProject: { devices } }) {
  if (!devices) return null;
  return devices[TREE];
}

/**
 * Получить id активного проекта
 * @param {Object} state.activeProject.project
 * @return {Object|null}
 */
export function getActiveProjectId({ activeProject: { project } }) {
  if (!project) return null;
  return project.id;
}

/**
 * Получить хеш зон активного проекта
 *
 * @param {Object} state
 * @return {Object}
 */
export const getActiveRegionsHash = ({ activeProject: { project, regions } }) =>
  !project || !project.id || !regions ? {} : regions;

/**
 * Получить список зон активного проекта
 * @param {Object} state
 * @return {Array}
 */
export const getActiveRegions = createSelector(getActiveRegionsHash, regionsHash =>
  regionsHash ? Object.values(regionsHash) : []
);

/**
 * Получить активный проект
 * @param {Object} state.activeProject.project
 * @return {Object}
 */
export function getProject({ activeProject: { project } }) {
  return project;
}

/**
 * Получить активный проект
 * @param {Object} state.activeProject.activeProject
 * @return {Object}
 */
export function getActiveProject({ activeProject: { activeProject } }) {
  return activeProject;
}

/**
 * Получить хеш задач
 * @param {Array} state.activeProject.issues
 * @return {Array}
 */
export const getIssuesHash = ({ activeProject: { issues } }) => {
  return issues;
};

/**
 * Получить список задач
 * @param {Array} state.activeProject.issues
 * @return {Array}
 */
export const getIssuesList = createSelector(getIssuesHash, issuesHash =>
  issuesHash
    ? Object.values(issuesHash).sort(
        (issueA, issueB) => issueB.createDateTime - issueA.createDateTime
      )
    : []
);

/**
 * Получить список текстур
 * @param {Object{}} state.activeProject.textures
 * @return {Object{}}
 */
export function getTextures({ activeProject: { textures } }) {
  return textures;
}

/**
 * Получить список таймеров пожаротушения
 * @param {Object{}} state.activeProject.countdowns
 * @return {Object{}}
 */
export function getCountdowns({ activeProject: { countdowns } }) {
  return countdowns;
}

/**
 * Получить список устройств активного проекта, у которых есть зона
 * @param {Object{}} state
 * @return {Array|null}
 */
export const getActiveDeviceListWithRegionId = createSelector(getActiveDeviceList, devicesList => {
  return devicesList ? devicesList.filter(device => device.regionId) : null;
});

/**
 * Получить список сценариев
 * @param {Object{}} state
 * @return {Array}
 */
export const getActiveScenarios = createSelector(getActiveScenariosHash, scenariosHash => {
  return Object.values(scenariosHash);
});

export const getHandleModeScenariosMap = createSelector(
  state => state.activeProject.CPIU.handleModeScenarios,
  scenariosList => {
    if (!scenariosList.length) return null;
    const resultMap = {};
    for (const scenario of scenariosList) {
      resultMap[scenario.id] = scenario;
    }
    return resultMap;
  }
);

export const getActiveScenariosByFilter = createSelector(
  [getActiveScenariosHash, getActiveScenariosFilter],
  (scenariosHash, filter) => {
    if (filter === 'ALL') return Object.values(scenariosHash);
    return Object.values(scenariosHash).filter(scenario => scenario.scenarioPurpose === filter);
  }
);

/**
 * Получить хеш сценариев активного проекта
 * @param {Object} state
 * @return {Array}
 */
export function getActiveScenariosHash({ activeProject: { scenarios } }) {
  if (!scenarios) return {};
  return scenarios;
}

/**
 * Получить список групп панелей индикаторов
 * @param {String} state
 * @return {Array}
 */
export const getActiveIndicatorGroupsList = createSelector(
  getActiveIndicatorGroupsHash,
  groupsHash => Object.values(groupsHash)
);
/**
 * Получить хеш групп панелей индикаторов активного проекта
 * @param {String} state
 * @return {Object}
 */
export function getActiveIndicatorGroupsHash({ activeProject: { indicators } }) {
  if (!indicators[GROUPS]) return {};
  return indicators[GROUPS];
}

/**
 * Получить хеш панелей индикаторов активного проекта
 * @param {String} state
 * @return {Object}
 */
export function getActiveIndicatorPanelsHash({ activeProject: { indicators } }) {
  if (!indicators[PANELS]) return {};
  return indicators[PANELS];
}

export const getActiveIndicatorPanelsInSelectedGroup = createSelector(
  [
    getActiveIndicatorPanelsHash,
    ({ widgets: { selectedIndicatorGroupId } }) => selectedIndicatorGroupId
  ],
  (indicatorPanels, selectedIndicatorGroupId) =>
    Object.values(indicatorPanels).filter(
      indicatorPanel => indicatorPanel.indicatorGroupId === selectedIndicatorGroupId
    )
);

/**
 * Получить список виртуальных состояний активного проекта
 * @param {Object} state
 * @return {Array}
 */
export const getActiveVirtualStates = createSelector(getActiveVirtualStatesHash, regionsHash =>
  regionsHash ? Object.values(regionsHash) : []
);

function getActiveVirtualStatesHash({ activeProject: { project, virtualStates } }) {
  if (!project || !project.id || !virtualStates) return {};
  return virtualStates;
}

/**
 * Получить список помещений активного проекта
 * @param {Object} state
 * @return {Array}
 */
export const getActivePlans = createSelector(getActivePlansHash, plansHash =>
  plansHash ? Object.values(plansHash) : []
);

/**
 * Получить хеш помещений активного проекта
 *
 * @param {Object} state
 * @return {Object}
 */
export function getActivePlansHash({ activeProject: { project, plans } }) {
  if (!project || !project.id || !plans) return {};
  return plans;
}

/**
 * Получить список помещений активного проекта
 * @param {Object} state
 * @return {Array}
 */
export const getActivePlanGroups = createSelector(getActivePlanGroupsHash, planGroupsHash =>
  planGroupsHash ? Object.values(planGroupsHash) : []
);

function getActivePlanGroupsHash({ activeProject: { project, planGroups } }) {
  if (!project || !project.id || !planGroups) return {};
  return planGroups;
}

export const getFilteredScenarios = createSelector(
  getCurrentUser,
  getActiveScenariosByFilter,
  filterEntities
);
