import { isLoaded } from 'constants/actionTypes';
import { DRIVER_ATTRIBUTES_LOAD, DRIVER_DESCRIPTION_LOAD } from 'constants/actionTypeModules/other';

/**
 * state = {
 *   <driverProfileId>: {
 *     attributes: {
 *       ...
 *     }
 *     description: {
 *       ...
 *     }
 *   }
 * }
 */
export default function drivers(state = {}, { type, payload }) {
  switch (type) {
    case isLoaded(DRIVER_ATTRIBUTES_LOAD, true): {
      const newState = { ...state };
      const receivedAttributes = payload;
      const currentDriverInfo = newState[receivedAttributes.id] || {};
      const newDriverInfo = { ...currentDriverInfo, attributes: receivedAttributes };
      newState[receivedAttributes.id] = newDriverInfo;
      return newState;
    }
    case isLoaded(DRIVER_DESCRIPTION_LOAD, true): {
      const newState = { ...state };
      const receivedDriverDescr = payload;
      const currentDriverInfo = newState[receivedDriverDescr.id] || {};
      const newDriverInfo = { ...currentDriverInfo, description: receivedDriverDescr };
      newState[receivedDriverDescr.id] = newDriverInfo;
      return newState;
    }
    default:
      return state;
  }
}
