import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { connectRouter } from 'connected-react-router';

import { DATA_FETCHED, PLAN_UPDATE } from 'constants/actionTypes';

import activeProject from './activeProject';
import admin from './admin';

import currentProjectId from './currentProjectId';
import currentUser from './currentUser';
import drivers from './drivers';

import deviceProfileViews from './deviceProfileViews';
import devices from './devices';
import deviceShapeLibrary from './deviceShapeLibrary';
import dictionaries from './dictionaries';

import errors from './errors';
import eventTypeViews from './eventTypeViews';

import indicators from './indicators';
import inProgress from './inProgress';

import lastLoginConfirmation from './lastLoginConfirmation';
import license from './license';

import medias from './medias';
import modals from './modals';
import monitorableValueProfiles from './monitorableValueProfiles';

import planGroups from './planGroups';
import plans from './plans';
import projects from './projects';

import regionConstants from './regionConstants';
import regions from './regions';

import scenarios from './scenarios';
import scenarioConstants from './scenarioConstants';
import skud from './skud';
import soundNotifications from './soundNotifications';
import { stateCategoryViewsHash, statesHash, stateViewsHash } from './states';

import virtualStates from './virtualStates';
import virtualCounters from './virtualCounters';

import widgets from './widgets';
import systemInfo from './systemInfo';

function page(state = {}, action) {
  switch (action.type) {
    case DATA_FETCHED:
      return { ...state, ...action.payload };
    case PLAN_UPDATE: {
      return {
        ...state,
        plan: action.payload
      };
    }
    case '@@redux-form/DESTROY': {
      return state;
    }
    default: {
      return state;
    }
  }
}

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    activeProject,
    admin,
    currentProjectId,
    currentUser,
    drivers,
    deviceProfileViews,
    devices,
    deviceShapeLibrary,
    dictionaries,
    errors,
    eventTypeViews,
    form, // redux-form
    indicators,
    inProgress,
    lastLoginConfirmation,
    license,
    medias,
    modals,
    monitorableValueProfiles,
    page,
    planGroups,
    plans,
    projects,
    regionConstants,
    regions,
    scenarios,
    scenarioConstants,
    skud,
    soundNotifications,
    stateCategoryViewsHash,
    statesHash,
    stateViewsHash,
    virtualStates,
    virtualCounters,
    widgets,
    systemInfo
  });
export default createRootReducer;
