import { all } from 'redux-saga/effects';
import projects from './projects';
import devices from './devices';
import regions from './regions';
import scenarios from './scenarios';
import virtualStates from './virtualStates';
import virtualCounters from './virtualCounters';
import events from './events';
import authorization from './authorization';
import users from './users';
import userGroups from './userGroups';
import plans from './plans';
import medias from './medias';
import monitorableValues from './monitorableValues';
import issues from './issues';
import dictionaries from './dictionaries';
import deviceShapeLibrary from './deviceShapeLibrary';
import states from './states';
import logViews from './logViews';
import indicators from './indicators';
import skud from './skud';
import soundNotifications from './soundNotifications';
import license from './license';
import controlPanelUsers from './controlPanelUsers';
import drivers from './drivers';
import systemInfo from './systemInfo';
import copyAndPaste from './planObjectsCopyAndPaste';
import CPIU from './CPIU';

export default function* rootSaga() {
  yield all([
    projects(),
    devices(),
    regions(),
    scenarios(),
    virtualStates(),
    virtualCounters(),
    events(),
    authorization(),
    users(),
    userGroups(),
    plans(),
    medias(),
    monitorableValues(),
    issues(),
    dictionaries(),
    deviceShapeLibrary(),
    states(),
    logViews(),
    indicators(),
    skud(),
    license(),
    soundNotifications(),
    controlPanelUsers(),
    drivers(),
    copyAndPaste(),
    systemInfo(),
    CPIU()
  ]);
}
