import React, { useState } from 'react';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import Draggable from 'react-draggable';
import styles from './styles.module.css';

const MIN_COLUMN_WIDTH = 20;

export const TableHeader = ({
  onSizeChanged,
  setDefaultSizes,
  columns,
  height,
  onSort,
  isFirstColumnSortable,
  minColumnWidth
}) => {
  const [draggingHeader, setDraggingHeader] = useState(null);
  const [draggingRange, setDraggingRange] = useState(0);
  const isSortableColumn = index => index !== 0 || isFirstColumnSortable;
  return (
    <div
      className={styles.columns_header}
      /* Скрытая функция. Нужна ли явная кнопка? */
      onDoubleClick={setDefaultSizes}
    >
      {columns.map((column, index) => {
        let newSortOrder = 'descend';
        if (column.sortOrder === 'descend') newSortOrder = 'ascend';
        if (column.sortOrder === 'ascend') newSortOrder = null;
        const isShortTitle = column.width < (column.minWidth || MIN_COLUMN_WIDTH);
        return (
          <div
            key={`head_cell_${column.dataIndex}`}
            style={{
              width: `${column.width || minColumnWidth}px`,
              position: 'relative',
              textAlign: 'center',
              borderRight: '1px solid #e8e8e8',
              padding: `${onSort ? '2px 20px 2px 0px' : '0px'}`
            }}
          >
            <div
              onClick={e => {
                isSortableColumn(index) &&
                  onSort &&
                  onSort(undefined, undefined, {
                    field: column.dataIndex,
                    order: newSortOrder
                  });
              }}
              className={isSortableColumn(index) && onSort ? styles.columns_header_cell : ''}
            >
              <Title
                popup={isShortTitle && column.title}
                title={isShortTitle && column.title ? '...' : column.title}
              />
              {isSortableColumn(index) && onSort && (
                <div style={{ display: 'grid', position: 'absolute', top: '3px', right: '5px' }}>
                  <CaretUpOutlined
                    style={{
                      marginBottom: '-3px',
                      display: 'block',
                      fontSize: '11px',
                      color: `${column.sortOrder === 'descend' ? '#1890ff' : 'unset'}`
                    }}
                  />
                  <CaretDownOutlined
                    style={{
                      display: 'block',
                      fontSize: '11px',
                      color: `${column.sortOrder === 'ascend' ? '#1890ff' : 'unset'}`
                    }}
                  />
                </div>
              )}
            </div>
            {index !== columns.length - 1 && (
              <Draggable
                axis="x"
                onDrag={(e, { deltaX }) => {
                  if (!draggingHeader) setDraggingHeader(column.key);
                  const resultDiff = draggingRange + deltaX;
                  if (column.width + resultDiff < MIN_COLUMN_WIDTH) return;
                  if (columns[index + 1].width - resultDiff < MIN_COLUMN_WIDTH) return;
                  setDraggingRange(prevRange => prevRange + deltaX);
                }}
                onStop={() => {
                  onSizeChanged(column.key, columns[index + 1].key, draggingRange);
                  setDraggingHeader(null);
                  setDraggingRange(0);
                }}
                position={{ x: 0 }}
              >
                <span
                  style={{
                    zIndex: 5,
                    height: `${height}px`,
                    right: `${-3 - draggingRange}px`,
                    width: `${draggingHeader === column.key ? '4px' : ''}`,
                    backgroundColor: `${draggingHeader === column.key ? '#f6f2f2' : ''}`,
                    boxShadow: `${draggingHeader === column.key ? '0px 0px 4px 1px #e8e8e8' : ''}`
                  }}
                  className={styles.column_dragger}
                />
              </Draggable>
            )}
          </div>
        );
      })}
    </div>
  );
};

const Title = ({ title, popup }) =>
  typeof title === 'string' ? (
    <span title={popup} style={{ verticalAlign: 'super' }}>
      {title}
    </span>
  ) : (
    <span style={{ color: '#0000ff00', verticalAlign: 'super' }}>{'_'}</span>
  );
