/**
 * Журналы представлений
 */
// Загрузить список представлений
export const LOG_VIEWS_LOAD = 'LOG_VIEWS_LOAD';
// Выбор представления
export const LOG_VIEW_SELECT = 'LOG_VIEW_SELECT';
// Добавить представление
export const LOG_VIEW_ADD = 'LOG_VIEW_ADD';
// Обновить представление
export const LOG_VIEW_UPDATE = 'LOG_VIEW_UPDATE';
// Удалить Представление
export const LOG_VIEW_DELETE = 'LOG_VIEW_DELETE';
