import { createSelector } from 'reselect';
import { getCurrentProjectDeviceList, getCurrentProjectDevicesHash } from 'helpers/currentProject';
import { getCurrentDriverAttributes } from 'helpers/drivers';
import { cloneDeep, isEmpty } from 'lodash';
import memoizeOne from 'memoize-one';
import { CONTROL_DEVICE_DATABASE_OPTION_TYPES } from 'constants/device';
import { getDeviceProfileViewsHash } from 'helpers/deviceProfileViews';
import { filterDevicesLeavingParents, getDevicesTree, isSlaveMpt } from 'helpers/device';
import { selectIndicatorByIndicatorsPanel } from 'actions/panelIndicators';
import { selectIndicatorsPage } from 'actions/indicators';
import {
  INDICATOR_PANEL_TYPE,
  INDICATOR_DEFAULT_VALUES,
  SINGLE_DIRECTION_ID
} from 'constants/indicators';
import { EMPTY_OBJECT } from 'constants/common';
import { CATEGORY } from 'constants/device';
import { updateDeviceSpecificSettings } from 'actions/devices';

const HASH = 'hash';

export function saveGroupIdToStorage(projectId, groupId) {
  let storage = localStorage.getItem('indicators');
  storage = storage ? JSON.parse(storage) : {};
  storage[projectId] = { groupId };
  storage = JSON.stringify(storage);
  localStorage.setItem('indicators', storage);
}

export function savePanelIdToStorage(projectId, groupId, panelId) {
  let storage = localStorage.getItem('indicators');
  storage = storage ? JSON.parse(storage) : {};
  storage[projectId] = { groupId, panelId };
  storage = JSON.stringify(storage);
  localStorage.setItem('indicators', storage);
}

export function removeIndicatorIdsFromStorageByProjectId(projectId) {
  let storage = localStorage.getItem('indicators');
  if (storage) {
    storage = JSON.parse(storage);
    if (storage[projectId]) {
      storage[projectId] = undefined;
      storage = JSON.stringify(storage);
      localStorage.setItem('indicators', storage);
    }
  }
}

export function removePanelIdFromStorageByProjectId(projectId) {
  let storage = localStorage.getItem('indicators');
  if (storage) {
    storage = JSON.parse(storage);
    if (storage[projectId]) {
      storage[projectId]['panelId'] = undefined;
      storage = JSON.stringify(storage);
      localStorage.setItem('indicators', storage);
    }
  }
}

export const getRowAndColNum = (num, isGridOrientationVertical, numberColumns, numberRows) => {
  if (isGridOrientationVertical) {
    if (num < numberRows) return { rowNum: num, colNum: 0 };
    return { rowNum: num % numberRows, colNum: Math.floor(num / numberRows) };
  } else {
    if (num < numberColumns) return { rowNum: 0, colNum: num };
    return { rowNum: Math.floor(num / numberColumns), colNum: num % numberColumns };
  }
};

export const coordsToNumber = (
  rowIndex,
  columnIndex,
  isGridOrientationVertical,
  numberColumns,
  numberRows
) => {
  if (isGridOrientationVertical) {
    return numberRows * columnIndex + rowIndex;
  } else {
    return numberColumns * rowIndex + columnIndex;
  }
};

export const getSelectedIndicatorDeviceId = createSelector(
  state => state.widgets.indicatorsPanel.selectedDeviceId,
  selectedDeviceId => selectedDeviceId
);

export const getSelectedIndicatorDevice = createSelector(
  getCurrentProjectDevicesHash,
  getSelectedIndicatorDeviceId,
  (deviceHash, selectedDeviceId) => {
    return deviceHash[selectedDeviceId];
  }
);

export const getEnabledLineControlDevices = createSelector(
  ({ currentProjectId }) => currentProjectId,
  ({ devices }) => devices,
  getCurrentDriverAttributes,
  getSelectedIndicatorDevice,
  (currentProjectId, devices, attributes, currentDevice) => {
    const currentProjectDevicesHash =
      !!currentProjectId && !!devices && !!devices[currentProjectId]
        ? devices[currentProjectId][HASH]
        : EMPTY_OBJECT;
    const currentProjectDeviceList = Object.values(currentProjectDevicesHash).map(device => {
      const { children, ...justDevice } = device;
      return justDevice;
    });
    const findDevicesByDeviceCategory = !!currentProjectDeviceList
      ? currentProjectDeviceList.filter(device => device.deviceCategory === CATEGORY.CONTROL.id)
      : [];
    const enabledControlDevices = findDevicesByDeviceCategory.filter(
      device => device.usableVirtualStates && !device.disabled
    );
    return (
      (Object.keys(attributes).length &&
        !attributes.crateTopology &&
        !!currentDevice &&
        enabledControlDevices.filter(device => device.lineNo === currentDevice.lineNo)) ||
      enabledControlDevices
    );
  }
);

export const getDeviceIdsFromIndicatorPanels = memoizeOne(specificSettings => {
  const ids = new Set();
  for (const page of specificSettings.indicatorsPages) {
    for (const indicator of page.indicators) {
      if (indicator.indicatorType === INDICATOR_DEFAULT_VALUES.DEVICE.indicatorType) {
        ids.add(...indicator.entityIds);
      }
    }
  }
  return Array.from(ids);
});

const isAttachableDeviceToIndicator = (
  device,
  selectedIndicatorDevice,
  deviceProfileViewsHash,
  pduPtDevices,
  isBindingRow
) => {
  const isAttach =
    selectedIndicatorDevice &&
    deviceProfileViewsHash[selectedIndicatorDevice.deviceProfileId] &&
    deviceProfileViewsHash[
      selectedIndicatorDevice.deviceProfileId
    ].deviceProfile.outputDeviceProfileIds.includes(device.deviceProfileId) &&
    deviceProfileViewsHash[device.deviceProfileId] &&
    !device.disabled;
  if (!isAttach) return false;
  const isPduPt =
    selectedIndicatorDevice.specificSettings.panelType === INDICATOR_PANEL_TYPE.PDU_PT;
  if (isPduPt) {
    /* Проверка, что данное устройство еще не привязано к какой-либо панели ПДУ-ПТ. */
    for (const pduPtDevice of pduPtDevices) {
      for (const page of pduPtDevice.specificSettings.indicatorsPages) {
        for (const indicator of page.indicators) {
          if (
            indicator.indicatorType === INDICATOR_DEFAULT_VALUES.DEVICE.indicatorType &&
            indicator.entityIds.includes(device.id)
          )
            return false;
        }
      }
    }
  }
  if (isBindingRow) {
    return (
      isAttach &&
      deviceProfileViewsHash[
        device.deviceProfileId
      ].deviceProfile.controlDeviceDatabaseOptionTypes.includes(
        CONTROL_DEVICE_DATABASE_OPTION_TYPES.DEVICE_BIND_ROW_INDICATOR_PANELS
      )
    );
  } else {
    return (
      isAttach &&
      !deviceProfileViewsHash[
        device.deviceProfileId
      ].deviceProfile.controlDeviceDatabaseOptionTypes.includes(
        CONTROL_DEVICE_DATABASE_OPTION_TYPES.DEVICE_BIND_ROW_INDICATOR_PANELS
      ) &&
      (isPduPt ? !isSlaveMpt(device) : true)
    );
  }
};

const getPduPtDevices = createSelector(getCurrentProjectDeviceList, devices => {
  return devices.filter(
    device => device.specificSettings?.panelType === INDICATOR_PANEL_TYPE.PDU_PT
  );
});

export const getAttachableToIndicatorDeviceTree = createSelector(
  getCurrentProjectDeviceList,
  getSelectedIndicatorDevice,
  getDeviceProfileViewsHash,
  getPduPtDevices,
  (state, isBindingRow) => isBindingRow,
  (devices, selectedIndicatorDevice, deviceProfileViewsHash, pduPtDevices, isBindingRow) => {
    return getDevicesTree(
      filterDevicesLeavingParents(devices, device =>
        isAttachableDeviceToIndicator(
          device,
          selectedIndicatorDevice,
          deviceProfileViewsHash,
          pduPtDevices,
          isBindingRow
        )
      ),
      item => {
        item.unavailable = !isAttachableDeviceToIndicator(
          item,
          selectedIndicatorDevice,
          deviceProfileViewsHash,
          pduPtDevices,
          isBindingRow
        );
        item.state = { expanded: true };
        return item;
      }
    );
  }
);

export const checkAndGoToNextIndicatorPage = (
  currentDevice,
  selectedIndicatorNum,
  selectedPageNo,
  dispatch
) => {
  if (selectedIndicatorNum + 1 < currentDevice.specificSettings.numberIndicators)
    dispatch(selectIndicatorByIndicatorsPanel([selectedIndicatorNum + 1]));
  else if (currentDevice.specificSettings.numberOfPages > selectedPageNo + 1) {
    dispatch(selectIndicatorByIndicatorsPanel([0]));
    dispatch(selectIndicatorsPage(selectedPageNo + 1));
  }
};

export function getCurrentIndicatorDevicesId(currentDevice, selectedIndicatorNums, selectedPageNo) {
  if (!currentDevice || !currentDevice.specificSettings) return [];
  const page = currentDevice.specificSettings.indicatorsPages.find(
    p => p.number === selectedPageNo
  );
  if (!page) return [];
  const indicators = page.indicators.filter(ind => selectedIndicatorNums.includes(ind.number));
  return (
    indicators &&
    indicators.reduce((prev, cur) => {
      if (cur.indicatorType === INDICATOR_DEFAULT_VALUES.DEVICE.indicatorType) {
        prev.push(...cur.entityIds);
      }
      return prev;
    }, [])
  );
}

export const getOrCreateIndicatorPage = (device, pageNo) => {
  let page = device.specificSettings.indicatorsPages.find(page => page.number === pageNo);
  if (!page) {
    page = { indicators: [], number: pageNo };
    device.specificSettings.indicatorsPages.push(page);
  }
  return page;
};

export const getOrCreateIndicator = (page, indicatorNo) => {
  let indicator = page.indicators.find(ind => ind.number === indicatorNo);
  if (!indicator) {
    indicator = {};
    page.indicators.push(indicator);
  }
  return indicator;
};

export const getDirectionDevices = createSelector(
  getCurrentProjectDeviceList,
  (state, currentDevice) => currentDevice,
  (devices, currentDevice) => {
    if (!currentDevice) return [];
    return devices
      .filter(
        device =>
          currentDevice.id !== device.id &&
          currentDevice.parentDeviceId === device.parentDeviceId &&
          currentDevice.lineNo === device.lineNo &&
          device.specificSettings &&
          device.specificSettings.supportedDirection
      )
      .sort((dev1, dev2) => dev1.lineAddress - dev2.lineAddress);
  }
);

/**
 * Применить настройки дублирования к устройствам (панелям "с направлениями").
 *
 * @param {String} currentProjectId
 * @param {Array} directionDevices
 * @param {Number} directionId
 * @param {Object} directionNoByDeviceId
 * @param {Object} prevDirectionNoByDeviceId
 * @param {Number} pageNo
 * @param {Array} entityIds
 * @param {Object} entityParams
 * @param {Function} dispatch
 */
export const applyDirectionDuplication = (
  currentProjectId,
  directionDevices,
  directionId,
  directionNoByDeviceId,
  prevDirectionNoByDeviceId,
  pageNo,
  entityIds,
  entityParams,
  dispatch
) => {
  const updatedSpecificSettingsById = {};
  for (const device of directionDevices) {
    const newDevice = cloneDeep(device);
    const indicatorNo = directionNoByDeviceId[newDevice.id];
    const prevIndicatorNo = prevDirectionNoByDeviceId[newDevice.id];
    const page = getOrCreateIndicatorPage(newDevice, pageNo);
    if (Number.isInteger(indicatorNo)) {
      const indicator = getOrCreateIndicator(page, indicatorNo);
      if (isEmpty(indicator)) {
        indicator.number = indicatorNo;
        indicator.mainIndicatorNumber = indicatorNo;
        Object.assign(indicator, INDICATOR_DEFAULT_VALUES.DEVICE);
      }
      indicator.directionId = directionId;
      indicator.entityIds = entityIds;
      indicator.entityParams = entityParams;
      indicator.indicatorDirectionNoByDeviceId = directionNoByDeviceId;

      updatedSpecificSettingsById[newDevice.id] = newDevice.specificSettings;
    }
    if (Number.isInteger(prevIndicatorNo) && prevIndicatorNo !== indicatorNo) {
      const prevIndicator = getOrCreateIndicator(page, prevIndicatorNo);
      prevIndicator.directionId = SINGLE_DIRECTION_ID;
      delete prevIndicator.indicatorDirectionNoByDeviceId;

      updatedSpecificSettingsById[newDevice.id] = newDevice.specificSettings;
    }
  }
  if (!isEmpty(updatedSpecificSettingsById)) {
    dispatch(updateDeviceSpecificSettings(currentProjectId, null, updatedSpecificSettingsById));
  }
};
