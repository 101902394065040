export const SET_CPIU_FILTERS_VISIBILITY = 'SET_CPIU_FILTERS_VISIBILITY';
export const SET_CPIU_PIN_TO_PANEL_STATUS = 'SET_CPIU_PIN_TO_PANEL_STATUS';
export const SET_CPIU_LOGIC_ENTITIES = 'SET_CPIU_LOGIC_ENTITIES';
export const SET_CPIU_ENTITIES_BY_PLAN_ID = 'SET_CPIU_ENTITIES_BY_PLAN_ID';
export const SET_CPIU_CURRENT_SCENARIO = 'SET_CPIU_CURRENT_SCENARIO';
export const SET_CPIU_AUTO_RESET_SCENARIO_STATUS = 'SET_CPIU_AUTO_RESET_SCENARIO_STATUS';
export const SET_CPIU_QUICK_ACCESS_SCENARIOS = 'SET_CPIU_QUICK_ACCESS_SCENARIOS';
export const SET_LAST_ENTERED_PASSWORD_DATA = 'SET_LAST_ENTERED_PASSWORD_DATA';
export const SET_SCENARIO_NAME_BLINKING = 'SET_SCENARIO_NAME_BLINKING';
export const GET_CPIU_HANDLE_MODE_SCENARIOS = 'GET_CPIU_HANDLE_MODE_SCENARIOS';
export const UPDATE_CPIU_DEVICE = 'UPDATE_CPIU_DEVICE';
