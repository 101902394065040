import React from 'react';

const ExportConfig = ({ color = '#FFF', x = '16', y = '16' }) => {
  return (
    <svg width="28px" height="28px" viewBox={`0 0 ${x} ${y}`}>
      <g>
        <rect
          id="svg_7"
          height="0.513423"
          width="6.941476"
          y="11.339726"
          x="4.529262"
          fill={color}
        />
        <path
          transform="rotate(180 8,7.24876880645752) "
          id="svg_1"
          d="m11.269721,7.41727c-0.10553,-0.10552 -0.27496,-0.10552 -0.38049,0l-2.61882,2.62031l0,-6.22602c0,-0.14863 -0.12039,-0.26901 -0.26902,-0.26901c-0.14863,0 -0.26901,0.12038 -0.26901,0.26901l0,6.22602l-2.62031,-2.62031c-0.10553,-0.10552 -0.27496,-0.10552 -0.38049,0c-0.10553,0.10553 -0.10553,0.27496 0,0.38049l3.07808,3.07808c0.10553,0.10553 0.27496,0.10553 0.38049,0l3.07808,-3.07808c0.10553,-0.10404 0.10553,-0.27496 0.00149,-0.38049z"
          fill={color}
        />
        <path
          id="svg_6"
          d="m15.96299,5.65887l-0.58657,-1.46008l-2.0256,-0.07482c-0.03468,-0.00127 -0.06774,-0.0158 -0.09225,-0.04068l-1.22728,-1.24851c-0.02451,-0.02506 -0.03867,-0.05847 -0.03904,-0.09371l-0.02724,-2.02577l-1.44047,-0.62453l-1.48859,1.38635c-0.02488,0.02343 -0.05811,0.0365 -0.09262,0.0365l-0.00036,0l-1.74502,-0.00708c-0.03541,-0.00018 -0.06955,-0.01417 -0.09498,-0.03905l-1.45463,-1.42739l-1.45246,0.5795l-0.06791,2.0392c-0.00128,0.03524 -0.01634,0.06884 -0.04159,0.09335l-1.2556,1.21311c-0.02506,0.02415 -0.05811,0.03776 -0.09298,0.03831l-2.03195,0.02706l-0.61199,1.44737l1.38017,1.48896c0.02361,0.02542 0.03669,0.05883 0.03633,0.0937l-0.01418,1.7452c-0.00036,0.03505 -0.01415,0.06919 -0.03903,0.09443l-1.42014,1.44755l0.58639,1.45954l2.02542,0.07482c0.03468,0.00145 0.06755,0.01562 0.09189,0.0405l1.22727,1.24143c0.02524,0.02561 0.0394,0.05992 0.0394,0.0957c0,0.03542 0.01634,1.63769 0.02034,2.0374l1.43993,0.60509l1.49658,-1.37363c0.02506,-0.02306 0.05793,-0.03578 0.09207,-0.03578l0.00109,0l1.73794,0.01417c0.03469,0.00037 0.06846,0.0138 0.0937,0.03832l1.46208,1.4145l1.45282,-0.5735l0.06774,-2.03886c0.00109,-0.03504 0.01598,-0.06846 0.04086,-0.09297l1.24852,-1.22019c0.02524,-0.02452 0.05884,-0.03831 0.0937,-0.03868l2.03267,-0.02724l0.61818,-1.43375l-1.38018,-1.49567c-0.02379,-0.02561 -0.0365,-0.05885 -0.03614,-0.09335l0.01417,-1.70251c0,-0.07174 0,-0.08972 1.45954,-1.58431zm-8.03409,8.05607c-3.14971,0 -5.71193,-2.56223 -5.71193,-5.71212c0,-3.14553 2.56223,-5.70486 5.71193,-5.70486c3.14571,0 5.70486,2.55933 5.70486,5.70486c0,3.14989 -2.55915,5.71212 -5.70486,5.71212z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default ExportConfig;
