import React from 'react';

const Expand = ({ color = "#FFF", x = "15", y = "14" }) => {
  return (
    <svg width={x} height={y} viewBox={`0 0 ${x} ${y}`}>
      <path fill={color} fillRule="evenodd" d="M9.334 0h4.083c.319 0 .583.263.583.583v4.083a.587.587 0 0 1-.583.583.58.58 0 0 1-.41-.172l-1.313-1.313L8.668 6.79l-.21.091-.209-.09-1.04-1.04-.09-.21.09-.209 3.027-3.026L8.923.993A.58.58 0 0 1 9.333 0zM5.332 7.209l.21-.091.21.09 1.04 1.04.09.21-.09.21-3.027 3.025 1.313 1.314c.108.109.172.255.172.409 0 .32-.264.584-.583.584H.584A.588.588 0 0 1 0 13.416V9.333c0-.319.264-.583.584-.583.154 0 .3.064.41.172l1.313 1.313L5.332 7.21z"/>
    </svg>
  )
}

export default Expand;