import Unit from 'constants/unit';

/**
 * fabric.js пока не имеет достаточного функционала для определения точных коллизий полигонов.
 * Пока что за это отвечает библиотека polybooljs
 */
import PolyBool from 'polybooljs';
import { fabric } from 'fabric';
import message from 'components/message';
import { TEXT_BOX } from 'containers/Widgets/PlanEditor/TextController';
import i18next from 'i18next';
/**
 * Удаляет одинаковые точки
 * @param {Array} points
 * @return {Array}
 */
function filterSamePoints(points) {
  return points.filter((point, index) =>
    index === 0 ? 1 : points[index - 1].x !== point.x || points[index - 1].y !== point.y
  );
}

/**
 * Изменяет возможность выделения объекта
 * @param {Object} fabricCanvas
 * @param {boolean} state
 */
function setObjectSelectable(fabricCanvas, state = false) {
  fabricCanvas.forEachObject(object => {
    if (object.info && object.info.gridKey !== 'grid') {
      object.selectable = state;
      object.hoverCursor = object.selectable ? 'pointer' : 'default';
    }
  });
}

/**
 * Возвращает y-положение с учетом размеров плана и сетки
 * @param {Object} target
 * @param {Object} plan
 * @param {Object} gridState
 * @param {Object} gridSize
 * @return {number}
 */
function getObjTopPosition(target, plan, gridState, gridSize) {
  const { top, height, scaleY } = target;
  if (target.type === TEXT_BOX) return top;

  const minTop = 0,
    maxTop = plan.ySize - height * scaleY;
  const newTop = gridState ? Math.round(top / gridSize) * gridSize : top;
  return top > minTop ? (top > maxTop ? maxTop : newTop > maxTop ? maxTop : newTop) : minTop;
}

/**
 * Возвращает x-положение с учетом размеров плана и сетки
 * @param {Object} target
 * @param {Object} plan
 * @param {Object} gridState
 * @param {Object} gridSize
 * @return {number}
 */
function getObjLeftPosition(target, plan, gridState, gridSize) {
  const { left, width, scaleX } = target;
  if (target.type === TEXT_BOX) return left;
  const minLeft = 0,
    maxLeft = plan.xSize - width * scaleX;
  const newLeft = gridState ? Math.round(left / gridSize) * gridSize : left;
  return left > minLeft
    ? left > maxLeft
      ? maxLeft
      : newLeft > maxLeft
      ? maxLeft
      : newLeft
    : minLeft;
}

/**
 * Возвращает точки для многоугольников
 * @param {Object} target
 * @param {Object} fabric
 * @return {Array}
 */
function getPolygonPoints(target, fabric, top, left, planSize) {
  const matrix = target.calcTransformMatrix();
  const points = target.points.map(p => fabric.util.transformPoint(p, matrix, true));

  return points.map(point => {
    let x = point.x - (fabric.util.array.min(points, 'x') - left);
    let y = point.y - (fabric.util.array.min(points, 'y') - top);
    if (planSize) {
      if (x < 0) x = 0;
      else if (x >= planSize.xSize) x = planSize.xSize;
      if (y < 0) x = 0;
      else if (y >= planSize.ySize) y = planSize.ySize;
    }
    return {
      x,
      y
    };
  });
}

/**
 * Возвращает смежные объекты
 * @param {Object} target - целевой объект
 * @param {Array} objects - список объектов для проверки пересечений объектов холста
 * @return {Array} - список объектов, с которыми найдены пересечения целевого объекта
 */
function getTargetIntersectionsWithObjects(target, objects) {
  return objects.filter(object => {
    if (!object.points) return false;
    const result = PolyBool.intersect(
      { regions: [object.points.map(p => [p.x, p.y])], inverted: false },
      {
        regions: [
          [
            [target.left, target.top],
            [target.left + target.width, target.top],
            [target.left + target.width, target.top + target.height],
            [target.left, target.top + target.height]
          ]
        ],
        inverted: false
      }
    );
    return result.regions.length;
  });
}

/**
 * Возвращает валидные x-координаты
 * @param {number} x
 * @param {number} maxX
 * @return {number}
 */
function getValidxPosition(x, maxX, grid) {
  if (grid) {
    if (x < 0) return 0;
    if (x > maxX) return maxX;
    return Math.round(x / grid) * grid;
  } else return x < 0 ? 0 : x > maxX ? maxX : x;
}

/**
 * Возвращает валидные y-координаты
 * @param {number} y
 * @param {number} maxY
 * @return {number}
 */
function getValidyPosition(y, maxY, grid) {
  if (grid) {
    if (y < 0) return 0;
    if (y > maxY) return maxY;
    return Math.round(y / grid) * grid;
  } else return y < 0 ? 0 : y > maxY ? maxY : y;
}

/**
 * Удаляет объекты плана
 * @param {Object} fabricCanvas
 * @param {Array} objects
 */
function removePlanObjects(fabricCanvas, objects) {
  objects.forEach(object => fabricCanvas.remove(object));
}

/**
 * Конвертирует единицы измерения плана
 */
function convertUnit(unitFrom, unitTo, value) {
  const pixelsInOneMeter = 100;
  if (unitFrom === Unit.PIXELS && unitTo === Unit.METERS) {
    return value / pixelsInOneMeter;
  } else if (unitFrom === Unit.METERS && unitTo === Unit.PIXELS) {
    return value * pixelsInOneMeter;
  }
  return value;
}

const getBase64FromSvgString = svgString => {
  const domElement = new DOMParser().parseFromString(svgString, 'image/svg+xml');
  const serializedSvg = new XMLSerializer().serializeToString(domElement);
  return window.btoa(unescape(encodeURIComponent(serializedSvg)));
};

function snap(value, gridSize) {
  return Math.round(value / gridSize) * gridSize;
}

function controlScaling(event, plan, gridSize, pinToGrid) {
  const { transform } = event;
  const { target } = transform;

  const targetWidth = target.width * target.scaleX;
  const targetHeight = target.height * target.scaleY;

  const Snap = {
    width: snap(targetWidth, gridSize),
    height: snap(targetHeight, gridSize)
  };

  const threshold = gridSize;

  const Distance = {
    // разница между текущим параметром и параметром с привязкой к сетке
    width: Math.abs(targetWidth - Snap.width),
    height: Math.abs(targetHeight - Snap.height)
  };

  const centerPoint = target.getCenterPoint();

  const anchorY = transform.originY;
  const anchorX = transform.originX;

  const anchorPoint = target.translateToOriginPoint(centerPoint, anchorX, anchorY);

  const attrs = {
    scaleX: target.scaleX,
    scaleY: target.scaleY
  };

  if (pinToGrid)
    // eslint-disable-next-line default-case
    switch (transform.corner) {
      case 'tl':
      case 'br':
      case 'tr':
      case 'bl':
        if (Distance.width < threshold) {
          attrs.scaleX = Snap.width / target.width;
        }

        if (Distance.height < threshold) {
          attrs.scaleY = Snap.height / target.height;
        }

        break;
      case 'mt':
      case 'mb':
        if (Distance.height < threshold) {
          attrs.scaleY = Snap.height / target.height;
        }

        break;
      case 'ml':
      case 'mr':
        if (Distance.width < threshold) {
          attrs.scaleX = Snap.width / target.width;
        }

        break;
    }

  if (target.top < 0) {
    attrs.scaleY = target.aCoords.bl.y / target.height;
  } else if (target.top + target.height * target.scaleY > plan.ySize) {
    attrs.scaleY = (plan.ySize - event.target.top) / event.target.height;
  }
  if (target.left < 0) {
    attrs.scaleX = target.aCoords.br.x / target.width;
  } else if (target.left + target.width * target.scaleX > plan.xSize) {
    attrs.scaleX = (plan.xSize - event.target.left) / event.target.width;
  }

  if (attrs.scaleX !== target.scaleX || attrs.scaleY !== target.scaleY) {
    target.set(attrs);
    target.setPositionByOrigin(anchorPoint, anchorX, anchorY);
  }
}

function polygonPositionHandler(dim, finalMatrix, fabricObject) {
  const x = fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x,
    y = fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y;
  return fabric.util.transformPoint(
    new fabric.Point(x, y),
    fabric.util.multiplyTransformMatrices(
      fabricObject.canvas.viewportTransform,
      fabricObject.calcTransformMatrix(),
      null
    ),
    false
  );
}

function actionHandler(eventData, transform, x, y, currentPlan, isGridEnabled, gridSize) {
  const polygon = transform.target,
    currentControl = polygon.controls[polygon.__corner],
    mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center'),
    polygonBaseSize = polygon._getNonTransformedDimensions(),
    size = polygon._getTransformedDimensions(0, 0);
  let newX = (mouseLocalPosition.x * polygonBaseSize.x) / size.x + polygon.pathOffset.x;
  let newY = (mouseLocalPosition.y * polygonBaseSize.y) / size.y + polygon.pathOffset.y;
  newX = getValidxPosition(newX, currentPlan.xSize, isGridEnabled ? gridSize : null);
  newY = getValidyPosition(newY, currentPlan.ySize, isGridEnabled ? gridSize : null);
  const finalPointPosition = { x: newX, y: newY };
  if (currentControl) polygon.points[currentControl.pointIndex] = finalPointPosition;
  return true;
}

function anchorWrapper(anchorIndex, fn, currentPlan, isGridEnabled, gridSize) {
  return function(eventData, transform, x, y) {
    const fabricObject = transform.target,
      absolutePoint = fabric.util.transformPoint(
        new fabric.Point(
          fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x,
          fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y
        ),
        fabricObject.calcTransformMatrix()
      ),
      actionPerformed = fn(eventData, transform, x, y, currentPlan, isGridEnabled, gridSize);
    fabricObject._setPositionDimensions({});
    const polygonBaseSize = fabricObject._getNonTransformedDimensions();
    let newX = (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) / polygonBaseSize.x;
    let newY = (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) / polygonBaseSize.y;
    fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
    return actionPerformed;
  };
}

/**
 * Позволяет редактировать границы полигона
 * В св-вах полигона должно быть отключено кэширование иначе работать будет криво
 * http://fabricjs.com/custom-controls-polygon
 */
function editPolygon(canvas, currentPlan, isGridEnabled, gridSize, clickCoords) {
  const polygon = canvas.getActiveObject();
  if (!polygon) return;
  if (!polygon.action) {
    resetPolygonControl(polygon, canvas, true);
    polygon.action = 1;
  } else if (polygon.action === 1) {
    polygon.action = 2;
    polygon.cornerStyle = 'circle';
    polygon.hasBorders = false;
    polygon.hasControls = true;
    recalculatePointControls(polygon, currentPlan, isGridEnabled, gridSize);
  } else if (polygon.action === 2) resetPolygonControl(polygon, canvas);
  canvas.figureTransformAction = polygon.action;
  canvas.requestRenderAll();
}

const getIndexesByDistanceToPoint = (polygon, point) => {
  const pointIndexByDistance = {};
  polygon.points.forEach((cur, index) => {
    pointIndexByDistance[point.distanceFrom(cur)] = { cur, index };
  });
  return pointIndexByDistance;
};

function addPointToPolygon(
  polygon,
  pointIndexByDistance,
  newPoint,
  currentPlan,
  isGridEnabled,
  gridSize,
  canvas
) {
  const minDistance = Math.min.apply(null, Object.keys(pointIndexByDistance));
  const newPoints = [...polygon.points];
  const index = pointIndexByDistance[minDistance].index;
  newPoints.splice(index, 0, newPoint);
  polygon.set({ points: newPoints });
  recalculatePointControls(polygon, currentPlan, isGridEnabled, gridSize);
  canvas.fire('object:modified', { target: polygon });
  canvas.requestRenderAll();
}

function removePointFromPolygon(
  polygon,
  pointIndexByDistance,
  currentPlan,
  isGridEnabled,
  gridSize,
  canvas
) {
  const newPoints = [...polygon.points];
  if (newPoints.length <= 3) {
    message.error(i18next.t('errors.minRegionPoints', { count: 3 }));
    return;
  }

  const minDistance = Math.min.apply(null, Object.keys(pointIndexByDistance));
  const index = pointIndexByDistance[minDistance].index;
  newPoints.splice(index, 1);
  polygon.set({ points: newPoints });
  recalculatePointControls(polygon, currentPlan, isGridEnabled, gridSize);
  canvas.fire('object:modified', { target: polygon });
  canvas.requestRenderAll();
}

function recalculatePointControls(polygon, currentPlan, isGridEnabled, gridSize) {
  const lastControl = polygon.points.length - 1;
  polygon.controls = polygon.points.reduce(function(acc, point, index) {
    const anchorIndex = index > 0 ? index - 1 : lastControl;
    acc['p' + index] = new fabric.Control({
      positionHandler: polygonPositionHandler,
      actionHandler: anchorWrapper(
        anchorIndex,
        actionHandler,
        currentPlan,
        isGridEnabled,
        gridSize
      ),
      actionName: 'modifyPolygon',
      pointIndex: index
    });
    return acc;
  }, {});
}

function resetPolygonControl(object, fabricCanvas, hasControls = false) {
  if (!object.isPolygon) return;
  object.cornerStyle = 'rect';
  object.controls = fabric.Object.prototype.controls;
  object.hasBorders = hasControls;
  object.hasControls = hasControls;
  //пока что убрал вращение фигур, из-за расхождений отображений с PIXI
  object.setControlsVisibility({ mtr: false });
  if (!hasControls && object.action === 1) {
    applyTransformation(object, fabricCanvas);
  }
  object.action = null;
}

/**
 *  Применяет сделанные пользователем трансформации к точкам полигона
 */
function applyTransformation(target, fabricCanvas) {
  const matrix = target.calcTransformMatrix();
  target.points = target.points.map(p => fabric.util.transformPoint(p, matrix));
  target.flipY = target.flipX = false;
  target.scaleY = target.scaleX = 1;
  target.translateY = target.translateX = 0;
  target.skewY = target.skewX = 0;
  target.angle = 0;
  fabricCanvas.renderAll();
}

/**
 * Отрисока иконки меню у объекта
 */
function renderIcon(pathToImage) {
  return function renderIcon(ctx, left, top, fabricObject) {
    const size = this.cornerSize;
    const icon = new Image();
    icon.src = pathToImage;
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
    ctx.drawImage(icon, -size / 2, -size / 2, size, size);
    ctx.restore();
  };
}
/**
 * Дополнительная функциональная кнопка (копирования) для объектов плана
 */
function cloneButton(mouseHandler) {
  return new fabric.Control({
    x: -0.5,
    y: -0.5,
    offsetY: -16,
    offsetX: -16,
    cursorStyle: 'pointer',
    mouseUpHandler: mouseHandler,
    render: renderIcon('/public/img/plan/action/copyAction.svg'),
    cornerSize: 24
  });
}

function getPreparedFromCanvasTextObject(canvasObject) {
  if (!canvasObject?.info?.textKey) return {};
  let {
    text,
    width,
    height,
    fontSize,
    angle,
    fontStyle,
    fontFamily,
    fontWeight,
    fill,
    info,
    left,
    top
  } = canvasObject;
  const textObj = {
    textAlign: 'left',
    scaleX: 1,
    scaleY: 1,
    underline: false,
    width,
    height,
    text,
    x: left,
    y: top,
    angle,
    fontSize,
    fontStyle,
    fontFamily,
    fontWeight,
    color: fill
  };
  textObj.textKey = info.textKey;
  textObj.id = info.textKey;
  return textObj;
}

export {
  filterSamePoints,
  setObjectSelectable,
  getObjTopPosition,
  getObjLeftPosition,
  getPolygonPoints,
  getTargetIntersectionsWithObjects,
  getValidxPosition,
  getValidyPosition,
  removePlanObjects,
  convertUnit,
  getBase64FromSvgString,
  controlScaling,
  editPolygon,
  addPointToPolygon,
  removePointFromPolygon,
  getIndexesByDistanceToPoint,
  cloneButton,
  getPreparedFromCanvasTextObject
};
