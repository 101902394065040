import React from 'react';

const Minimize = ({ color = "#FFF", x = "15", y = "14", isActive = true }) => {
  return (
    <svg width={x} height={y} viewBox={`0 0 ${x} ${y}`}>
      <path
        id="minimize"
        fill={color ? color : '#fff'} 
        fillRule="evenodd"
        fillOpacity={isActive ? '1' : '.5'}
        d="M11.785 6.881H7.702a.587.587 0 0 1-.583-.583V2.215a.58.58 0 1 1 .994-.41l1.312 1.312L12.45.091 12.66 0l.209.09 1.04 1.04.09.21-.09.21-3.027 3.025 1.313 1.313a.58.58 0 0 1-.41.993zM1.55 13.91L1.34 14l-.21-.09-1.04-1.04-.09-.21.09-.21 3.027-3.025L1.804 8.11a.583.583 0 0 1-.172-.41c0-.319.264-.583.583-.583h4.083c.32 0 .584.264.584.584v4.083a.588.588 0 0 1-.584.583.583.583 0 0 1-.41-.172l-1.313-1.313-3.025 3.026z"/>
    </svg>
  )
}

export default Minimize;