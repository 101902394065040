import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import Menu from 'components/Menu';
import Title from 'components/Title';

import ButtonGroup from 'components/Button/ButtonGroup';
import { ButtonWrapper } from 'components/Button/styles';
import { Switch, Popconfirm, Tooltip } from 'antd';

import { updateProjectStatus, validateProject } from 'actions/projects';
import styled from 'styled-components';
import { checkCurrentProjectIsActive } from 'helpers/currentProject';
import { getActiveProject } from 'helpers/activeProject';
import i18next from 'i18next';
import { updateLoginConfirmation } from 'actions/login';

export const ACTIVATED_PROJECT_STATUS_COLORS = {
  DEFAULT: 'Gold',
  INACTIVE: 'Gold',
  ACTIVE: 'Lime',
  FAILURE: 'Red',
  WARNING: 'Gold'
};
const ACTIVATED_PROJECT_TOGGLE_COLOR = 'Grey';
const ACTIVATED_PROJECT_TEXT_COLOR = 'Black';

export const VALIDATE_MESSAGE_LEVEL_TO_NOTIF_TYPE = {
  WARNING: 'WARNING',
  ERROR: 'ERROR',
  DEFAULT: 'INFO'
};

const SwitchWrapper = styled.div`
  & .ant-switch-checked {
    ${props => (props.color === 'default' ? '' : `color: ${props.color}`)};
    ${props =>
      props.backgroundColor === 'default' ? '' : `background-color: ${props.backgroundColor}`};
  }
  & .ant-switch-inner {
    ${props => (props.color === 'default' ? '' : `color: ${props.color}`)};
    ${props =>
      props.backgroundColor === 'default' ? '' : `background-color: ${props.backgroundColor}`};
  }
  & .ant-switch {
    ${props => (props.color === 'default' ? '' : `color: ${props.color}`)};
    ${props =>
      props.backgroundColor === 'default' ? '' : `background-color: ${props.backgroundColor}`};
  }

  & .ant-switch:after {
    background-color: ${props => props.toggleColor};
    ${props => (props.toggleColor === 'default' ? '' : `background-color: ${props.toggleColor}`)};
  }

  & .ant-switch-loading,
  .ant-switch-disabled {
    opacity: 1;
  }
`;

class CurrentProjectMenu extends Component {
  BUILD_PROJECT_STATUS_TITLE = i18next.t('projects.statusTitles.BUILD');

  ACTIVATED_PROJECT_STATUS_TITLES = {
    DEFAULT: i18next.t('projects.statusTitles.INACTIVE'),
    INACTIVE: i18next.t('projects.statusTitles.INACTIVE'),
    ACTIVE: i18next.t('projects.statusTitles.ACTIVE'),
    FAILURE: i18next.t('projects.statusTitles.FAILURE')
  };

  doProjectStatusChange(checked) {
    const { currentProject, dispatch } = this.props;
    dispatch(updateProjectStatus(currentProject.id, checked ? 'ACTIVE' : 'BUILD'));
    if (!checked) dispatch(updateLoginConfirmation(true));
  }

  performProjectValidation(checked) {
    const { currentProject, dispatch } = this.props;
    dispatch(validateProject(currentProject.id, checked));
  }

  render() {
    const {
      currentProject,
      activeProjectStatus,
      projectStatus,
      updateProjectStatusInProgress,
      projectStatusColor
    } = this.props;

    let isProjectActive = false;
    if (currentProject.id && currentProject.status === 'ACTIVE') {
      isProjectActive = true;
    }
    const projectName = currentProject.id
      ? currentProject.name + ` (${i18next.t('version')}: ${currentProject.version} )`
      : '';

    return (
      <Menu isActive={!!currentProject.id}>
        <ButtonGroup>
          <Title>{projectName}</Title>
          <Tooltip
            title={i18next.t(
              projectStatusColor !== ACTIVATED_PROJECT_STATUS_COLORS.ACTIVE
                ? 'projects.projectContainsErrors'
                : 'projects.projectContainsNoErrors'
            )}
          />
        </ButtonGroup>
        <ButtonGroup>
          <Title>{currentProject.description || ''}</Title>
        </ButtonGroup>
        <ButtonGroup>
          <ButtonWrapper isActive={true}>
            <Popconfirm
              placement="bottomRight"
              title={i18next.t('projects.changeStatus') + ' ?'}
              onConfirm={() => {
                this.doProjectStatusChange(!isProjectActive);
              }}
              okText={i18next.t('buttons.yes')}
              cancelText={i18next.t('buttons.cancel')}
              disabled={updateProjectStatusInProgress}
            >
              <div>
                <Tooltip
                  title={
                    projectStatus === 'BUILD'
                      ? this.BUILD_PROJECT_STATUS_TITLE
                      : this.ACTIVATED_PROJECT_STATUS_TITLES[activeProjectStatus] ||
                        this.ACTIVATED_PROJECT_STATUS_TITLES.DEFAULT
                  }
                  placement="left"
                >
                  <SwitchWrapper
                    backgroundColor={
                      projectStatus === 'BUILD'
                        ? 'default'
                        : ACTIVATED_PROJECT_STATUS_COLORS[activeProjectStatus] ||
                          ACTIVATED_PROJECT_STATUS_COLORS.DEFAULT
                    }
                    color={projectStatus === 'BUILD' ? 'default' : ACTIVATED_PROJECT_TEXT_COLOR}
                    toggleColor={
                      projectStatus === 'BUILD' ? 'default' : ACTIVATED_PROJECT_TOGGLE_COLOR
                    }
                  >
                    <Switch
                      checked={isProjectActive}
                      checkedChildren={
                        updateProjectStatusInProgress || activeProjectStatus === 'INACTIVE' ? (
                          <div>{i18next.t('loading')}</div>
                        ) : (
                          <div>{i18next.t('projects.statusActivated')}</div>
                        )
                      }
                      loading={updateProjectStatusInProgress}
                      unCheckedChildren={
                        updateProjectStatusInProgress || activeProjectStatus === 'INACTIVE' ? (
                          <div>{i18next.t('loading')}</div>
                        ) : (
                          <div>{i18next.t('projects.statusInDevelopment')}</div>
                        )
                      }
                    />
                  </SwitchWrapper>
                </Tooltip>
              </div>
            </Popconfirm>
          </ButtonWrapper>
        </ButtonGroup>
      </Menu>
    );
  }
}

const getProjectStatus = createSelector(
  checkCurrentProjectIsActive,
  ({ activeProject: { project } }) => project,
  (currentProjectIsActive, currentProject) =>
    currentProjectIsActive && currentProject ? currentProject.status : 'BUILD'
);

const mapStateToProps = createSelector(
  ({ activeProject: { activeProject } }) => (activeProject ? activeProject.status : 'BUILD'),
  getProjectStatus,
  getActiveProject,
  ({ inProgress: { updateProjectStatus } }) => updateProjectStatus,
  (activeProjectStatus, projectStatus, activeProject, updateProjectStatusInProgress) => ({
    activeProjectStatus,
    projectStatus,
    activeProject,
    updateProjectStatusInProgress
  })
);

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentProjectMenu);
