/** Виртуальные индикаторы */
// Добавить группу панелей индикаторов
export const INDICATOR_GROUP_ADD = 'INDICATOR_GROUP_ADD';
// Удалить группу панелей индикаторов
export const INDICATOR_GROUP_DELETE = 'INDICATOR_GROUP_DELETE';
// Обновить текущую группу панелей индикаторов
export const INDICATOR_GROUP_SELECT = 'INDICATOR_GROUP_SELECT';
// Обновить группу панелей индикаторов
export const INDICATOR_GROUP_UPDATE = 'INDICATOR_GROUP_UPDATE';

// Добавить панель индикаторов
export const INDICATOR_PANEL_ADD = 'INDICATOR_PANEL_ADD';
// Удалить панель индикаторов
export const INDICATOR_PANEL_DELETE = 'INDICATOR_PANEL_DELETE';
// Обновить панель индикаторов
export const INDICATOR_PANEL_UPDATE = 'INDICATOR_PANEL_UPDATE';
// Обновить текущую панель индикаторов
export const INDICATOR_PANEL_SELECT = 'INDICATOR_PANEL_SELECT';

// Обновить индикатор
export const INDICATOR_UPDATE = 'INDICATOR_UPDATE';
// Обновить текущий индикатор
export const INDICATOR_SELECT = 'INDICATOR_SELECT';
// Cброс выбранного индикатора
export const INDICATOR_RESET_SELECTED = 'INDICATOR_RESET_SELECTED';
// Выбор группу и панели
export const INDICATOR_GROUP_AND_PANEL_SELECT = 'INDICATOR_GROUP_AND_PANEL_SELECT';

// Изменить состояние обхода для устройств индикатора
export const INDICATOR_CHANGE_DEVICES_POLLING_STATE = 'INDICATOR_CHANGE_DEVICES_POLLING_STATE';
// Изменить состояние обхода для устройств зон индикатора
export const INDICATOR_CHANGE_REGIONS_POLLING_STATE = 'INDICATOR_CHANGE_REGIONS_POLLING_STATE';
// Поставить/снять на охрану зоны индикатора
export const INDICATOR_CHANGE_REGIONS_GUARD = 'INDICATOR_CHANGE_REGIONS_GUARD';
// Выполнить действие со сценариями индикатора
export const INDICATOR_PERFORM_SCENARIO_ACTION = 'INDICATOR_PERFORM_SCENARIO_ACTION';
// Выполнить действие с устройствами индикатора
export const INDICATOR_PERFORM_DEVICE_ACTION = 'INDICATOR_PERFORM_DEVICE_ACTION'
// Выбор страницы индикатора
export const INDICATOR_PAGE_SELECT = 'INDICATOR_PAGE_SELECT';

