import React, { Component } from 'react';
import { Select } from 'antd';
const { Option } = Select;
export default class RegionSelect extends Component {
  render() {
    const { regions, ...restProps } = this.props;

    return (
      <Select className="regionSelector" {...restProps}>
        {regions.map(region => {
          return (
            <Option value={region.id} key={region.id}>
              {region.id ? `${region.index || region.region.index}.${region.name}` : region.name}
            </Option>
          );
        })}
      </Select>
    );
  }
}
