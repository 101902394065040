import { setLocalHostUser } from 'actions/users';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const LOCAL_HOST_NAME = 'localhost';
const LOCAL_HOST_IP = '127.0.0.1';
/**
 * проверка: находится ли пользователь на localHost хост или нет
 */
const IsLocalHostController = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      LOCAL_HOST_NAME === window.location.hostname ||
      LOCAL_HOST_IP === window.location.hostname
    ) {
      dispatch(setLocalHostUser(true));
      return;
    }
    dispatch(setLocalHostUser(false));
  }, [dispatch]);

  return null;
};

export default IsLocalHostController;
