import React, { useEffect } from 'react';
import { setIsScenarioNameBlinking } from 'actions/CPIU';
import { TreeItemDeviceView } from 'backendToTSTypes/TreeItemDeviceView';
import { useSelector, useDispatch } from 'react-redux';

const BLINKING_TIME_BEFORE_RESET_SEC = 5;

/**
 * осуществляет сброс управления сценарием через SCENARIO_CONTROL_RESET_TIME_MS
 * отвечает за мигание названия сценария перед сбросом
 */
const CPIUActiveScenarioController = () => {
  const specialData = useSelector((state: any) => state.activeProject.CPIU.device?.specialData) as
    | TreeItemDeviceView['specialData']
    | null;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!specialData) return;
    if (
      specialData.entityId &&
      !specialData.autoResetTimePaused &&
      (specialData.autoResetTime || 0) < BLINKING_TIME_BEFORE_RESET_SEC
    ) {
      dispatch(setIsScenarioNameBlinking(true));
      return;
    }
    dispatch(setIsScenarioNameBlinking(false));
  }, [specialData, dispatch]);

  return null;
};

export default React.memo(CPIUActiveScenarioController);
