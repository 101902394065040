import {
  LOG_VIEW_SELECT,
  LOG_VIEW_DELETE,
  isLoaded,
  LOG_VIEWS_LOAD,
  LOG_VIEW_ADD,
  LOG_VIEW_UPDATE
} from 'constants/actionTypes';

const selectedLogView = (state = null, { type, payload }) => {
  switch (type) {
    case LOG_VIEW_SELECT: {
      const { logView } = payload;
      if (!logView) return null;
      return { ...logView };
    }
    case isLoaded(LOG_VIEWS_LOAD, true): {
      if (!state || !state.id) return state;
      const selectedLogViewId = state.id;
      const logViews = payload;
      const selectedLogView = logViews.find(logView => logView.id === selectedLogViewId);
      if (selectedLogView) return { ...selectedLogView };
      else return null;
    }
    case isLoaded(LOG_VIEW_ADD, true): {
      const addedLogViews = payload;
      return { ...addedLogViews[0] };
    }
    case isLoaded(LOG_VIEW_UPDATE, true): {
      if (!state || !state.id) return state;
      const selectedLogViewId = state.id;
      const updatedLogViews = payload;
      const newSelectedLogView = updatedLogViews.find(logView => logView.id === selectedLogViewId);
      if (newSelectedLogView) return { ...newSelectedLogView };
      else return state;
    }
    case isLoaded(LOG_VIEW_DELETE, true): {
      if (!state || !state.id) return state;
      const selectedLogViewId = state.id;
      const deletedLogViewIds = payload;
      if (deletedLogViewIds.includes(selectedLogViewId)) return null;
      else return state;
    }
    default: {
      return state;
    }
  }
};

export default selectedLogView;
