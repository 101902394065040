import React, { PureComponent } from 'react';

export default class Widget extends PureComponent {
  render() {
    const { children, ...restProps } = this.props;
    return (
      <div
        style={{ display: 'flex', flex: 1, flexDirection: 'column', background: 'white' }}
        {...restProps}
      >
        {children}
      </div>
    );
  }
}
