import { combineReducers } from 'redux';

import {
  UPDATE_SELECTED_DEVICE_ID,
  INDICATOR_SELECTED_NUMBER,
  INDICATOR_PAGE_SELECT
} from 'constants/actionTypes';

function selectedDeviceId(state = '', { type, payload }) {
  switch (type) {
    case UPDATE_SELECTED_DEVICE_ID: {
      return payload;
    }
    default:
      return state;
  }
}

function selectedIndicators(state = [0], { type, payload }) {
  switch (type) {
    case INDICATOR_SELECTED_NUMBER: {
      return payload;
    }
    case INDICATOR_PAGE_SELECT: {
      return [0];
    }
    default:
      return state;
  }
}

function selectedIndicatorPage(state = 0, { type, payload }) {
  switch (type) {
    case INDICATOR_PAGE_SELECT: {
      return payload;
    }
    default: {
      return state;
    }
  }
}
export default combineReducers({
  selectedDeviceId,
  selectedIndicators,
  selectedIndicatorPage
});
