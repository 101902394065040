import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { changeRegionsFilter } from 'actions/widgets';

/**
 * Компонент, осуществляющий сохранение отдельных элементов redux-store в локальном хранилище.
 */
class LocalStorageSaver extends PureComponent {
  componentDidMount() {
    const { dispatch, ...dataItems } = this.props;
    Object.keys(dataItems).forEach(key => {
      this.props[key].restore(dispatch, key);
    });
  }

  componentDidUpdate(prevProps) {
    /*eslint no-unused-vars: ["warn", { "varsIgnorePattern": "dispatch" }]*/
    const { dispatch, ...dataItems } = this.props;
    Object.keys(dataItems).forEach(key => {
      const item = this.props[key];
      if (prevProps[key].value !== item.value) localStorage.setItem(key, item.value);
    });
  }

  render() {
    return null;
  }
}

/*
 * В props должны быть объекты вида:
 *  {
 *    value: <данные из redux-store>,
 *    restor: <функция, забирающая данные из локального хранилища и отправляющая в redux-store>
 *  }
 * Их ключи должны совпадать с ключами, под которыми они будут сохраняться в локальном хранилище.
 */
const mapStateToProps = createSelector(
  /* Добавляем сюда данные из redux-store, которые надо хранить в локальном хранилище. */
  ({ widgets: { regionsFilter } }) => regionsFilter,

  /* Здесь связываем данные из redux-store с функцией восстановления. */
  regionsFilter => ({
    regionsFilter: {
      value: regionsFilter,
      restore: (dispatch, key) => {
        const restoredValue = localStorage.getItem(key);
        if (restoredValue) dispatch(changeRegionsFilter(restoredValue));
      }
    }
  })
);

const mapDispatchToProps = dispatch => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(LocalStorageSaver);
