import React, { Component } from 'react';
import { Colorpicker } from 'antd-colorpicker';

export default class ColorPicker extends Component {
  render() {
    const { input, ...restProps } = this.props;
    const onChange = input ? input.onChange : null;
    const value = input ? input.value : null;

    return <Colorpicker onChange={onChange} value={value} {...restProps} />;
  }
}