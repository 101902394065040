import {
  EVENTS_LOAD_ARCHIVE,
  EVENTS_SET_ARCHIVE_FILTERS,
  EVENTS_CLEAN_ARCHIVE,
  isLoaded
} from 'constants/actionTypes';

const INITIAL_STATE = {
  events: null,
  totalEventsCount: 0
};

export default function archiveEvents(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case isLoaded(EVENTS_LOAD_ARCHIVE, true): {
      const { events, totalEventsCount } = payload;
      return {
        events,
        /* в action.payload eventsCount может отсутствовать */
        totalEventsCount: Number.isInteger(totalEventsCount)
          ? totalEventsCount
          : state.totalEventsCount
      };
    }
    case EVENTS_SET_ARCHIVE_FILTERS:
    case EVENTS_CLEAN_ARCHIVE: {
      return {
        ...state,
        events: null
      };
    }
    default:
      return state;
  }
}
