/**
 * Сценарии.
 * Все REST-методы, на которые ссылаются ниже описанные action-ы, описаны в тех. проекте:
 * (https://docs.google.com/document/d/19fDadv_CBaeP-RlnnSvsjpdx7BCqSeax4MlCHjeEW28/edit#heading=h.bgq2qvxpr9r1).
 * Все входные параметры (payload), поступающие в sagas, совпадают с параметрами соответствующих REST-методов.
 * Все входные параметры (payload), поступающие в reducers, совпадают с ответом соответствующих REST-методов.
 */

/**
 * Загрузить неизменяемые данные функционала сценариев
 * REST-методы: GET api/v1/scenario_dictionaries, GET api/v1/scenario_trigger_types, GET api/v1/scenario_action_types
 */
export const SCENARIO_CONSTANTS_LOAD = 'SCENARIO_CONSTANTS_LOAD';
/**
 * Загрузить все сценарии открытого для редактирования проекта
 * REST-метод: GET api/v1/projects/<projectId>/scenarios
 */
export const SCENARIOS_LOAD = 'SCENARIOS_LOAD';

/** Загрузить полную информацию о сценарии. */
export const SCENARIO_GET_FULL = 'SCENARIO_GET_FULL';

/**
 * Создать новый сценарий в открытом для редактирования проекте
 * REST-метод: POST api/v1/projects/<projectId>/scenarios
 */
export const SCENARIO_CREATE = 'SCENARIO_CREATE';
/**
 * Обновить базовые параметры сценария
 * REST-метод: PUT api/v1/projects/<projectId>/scenarios/<scenarioId>?basic_params
 */
export const SCENARIO_UPDATE_BASIC_PARAMS = 'SCENARIO_UPDATE_BASIC_PARAMS';
/**
 * Обновить расширенные параметры (флаги) сценария
 * REST-метод: PUT api/v1/projects/<projectId>/scenarios/<scenarioId>?advanced_params
 */
export const SCENARIO_UPDATE_ADVANCED_PARAMS = 'SCENARIO_UPDATE_ADVANCED_PARAMS';
/**
 * Удалить сценарий
 * REST-метод: DELETE api/v1/projects/<projectId>/scenarios/<scenarioId>
 */
export const SCENARIO_DELETE = 'SCENARIO_DELETE';
/**
 * Добавить в сценарий исполнительный блок времени
 * REST-метод: POST api/v1/projects/<projectId>/scenarios/<scenarioId>/time_line_blocks
 */
export const SCENARIO_ADD_TL_BLOCK = 'SCENARIO_ADD_TL_BLOCK';
/**
 * Изменить блок времени сценария
 * REST-метод: PUT api/v1/projects/<projectId>/scenarios/<scenarioId>/time_line_blocks/<int blockNo>
 */
export const SCENARIO_UPDATE_TL_BLOCK = 'SCENARIO_UPDATE_TL_BLOCK';
/**
 * Удалить блок времени сценария
 * REST-метод: DELETE api/v1/projects/<projectId>/scenarios/<scenarioId>/time_line_blocks/
 *  <int blockNo>
 */
export const SCENARIO_REMOVE_TL_BLOCK = 'SCENARIO_REMOVE_TL_BLOCK';
/**
 * Изменить логику запуска сценария
 * REST-метод: PUT api/v1/projects/<projectId>/scenarios/<scenarioId>?start_logic
 */
export const SCENARIO_UPDATE_START_LOGIC = 'SCENARIO_UPDATE_START_LOGIC';
/**
 * Изменить логику остановки сценария
 * REST-метод: PUT api/v1/projects/<projectId>/scenarios/<scenarioId>?stop_logic
 */
export const SCENARIO_UPDATE_STOP_LOGIC = 'SCENARIO_UPDATE_STOP_LOGIC';

/**
 * Выбрать сценарий (локальный action).
 * Payload: scenarioId: <string>.
 * Store: state.widgets.currentScenarioId.
 */
export const SCENARIO_SELECT = 'SCENARIO_SELECT';
/**
 * Выбрать блок времени сценария (локальный action).
 * Payload: timeLineBlockNo: <number>.
 * Store: state.widgets.currentScenarioTLBlockNo.
 */
export const SCENARIO_SELECT_TL_BLOCK = 'SCENARIO_SELECT_TL_BLOCK';
/**
 * Выбрать уровень логики сценария (локальный action).
 * Payload: subLogicKey: <string 'root'| '0,1,1,0,3,...' >.
 * Store: state.widgets.currentSubLogicKey.
 */
export const SCENARIO_SELECT_SUB_LOGIC = 'SCENARIO_SELECT_SUB_LOGIC';
/**
 * Установить фильтр сценариев в ОЗ
 * Payload: filter: <string>
 * Store: state.widgets.activeScenariosFilter
 **/
export const ACTIVE_SCENARIOS_SET_FILTER = 'ACTIVE_SCENARIOS_SET_FILTER';
/**
 * Выполнить действие со сценарием
 * payload: {
 *  scenarioNo: <number>
 *  actionId: <string>
 * }
 */
export const PERFORM_SCENARIO_ACTION = 'PERFORM_SCENARIO_ACTION';
// Активный сценарий обновлен (вызывается из обработчика WebSockets)
export const ACTIVE_SCENARIO_UPDATED = 'ACTIVE_SCENARIO_UPDATED';
// Активные сценарии обновлены (вызывается из обработчика WebSockets)
export const ACTIVE_SCENARIOS_UPDATED = 'ACTIVE_SCENARIOS_UPDATED';
// Выполнить действие на компьютере
export const SCENARIO_PERFORM_COMPUTER_ACTION = 'SCENARIO_PERFORM_COMPUTER_ACTION';
// Запустить отсчет таймера (действие на компьютере)
export const SCENARIO_START_TIMER = 'SCENARIO_START_TIMER';
export const SCENARIOS_UPDATED = 'SCENARIOS_UPDATED';