import {
  INDICATOR_GROUP_ADD,
  INDICATOR_GROUP_SELECT,
  INDICATOR_GROUP_DELETE,
  INDICATOR_RESET_SELECTED,
  INDICATOR_GROUP_AND_PANEL_SELECT,
  isLoaded
} from 'constants/actionTypes';
import { saveGroupIdToStorage } from 'helpers/indicators';

export default function selectedIndicatorGroupId(state = null, { type, payload }) {
  switch (type) {
    case isLoaded(INDICATOR_GROUP_ADD, true): {
      const { newIndicatorGroup, projectId } = payload;
      saveGroupIdToStorage(projectId, newIndicatorGroup.id);
      return newIndicatorGroup.id;
    }
    case isLoaded(INDICATOR_GROUP_DELETE, true): {
      const { nextIndicatorGroupId, projectId } = payload;
      saveGroupIdToStorage(projectId, nextIndicatorGroupId);
      return nextIndicatorGroupId;
    }
    case INDICATOR_GROUP_AND_PANEL_SELECT: {
      const { groupId } = payload;
      return groupId;
    }
    case INDICATOR_GROUP_SELECT: {
      const currentIndicatorGroupId = payload;
      if (!state) return currentIndicatorGroupId;
      if (state === currentIndicatorGroupId) return state;
      return currentIndicatorGroupId;
    }
    case INDICATOR_RESET_SELECTED: {
      return null;
    }
    default: {
      return state;
    }
  }
}
