import fetch from 'isomorphic-fetch';
import message from 'components/message';
import i18next from 'i18next';

const apiUrl = process.env.REACT_APP_API_URL;
const protocol = process.env.REACT_APP_SECURITY_ON === 'true' ? 'https' : 'http';
const address = apiUrl ? `${protocol}://${apiUrl}` : '';
export const restApi = address ? `${address}/api/v1` : '/api/v1';

const ErrorTypes = {
  SYSTEM: 'SYSTEM',
  CUSTOM_SYSTEM: 'CUSTOM_SYSTEM'
};

const onSuccess = response => {
  if (response.ok) {
    return hasJson(response)
      ? parse(response)
      : isByteStream(response)
      ? parseByteStream(response)
      : {};
  } else {
    if (hasJson(response)) {
      return parse(response).then(json => {
        let error = new Error();
        error.response = json;
        error.type = ErrorTypes.CUSTOM_SYSTEM;
        throw error;
      });
    } else {
      /* Если в ответе не json */
      return response.text().then(text => {
        let error = new Error();
        error.response = response;
        error.text = text;
        error.type = ErrorTypes.SYSTEM;
        throw error;
      });
    }
  }
};

const hasJson = response => {
  const contentType = response.headers.get('content-type');
  return contentType && contentType.includes('application/json');
};

const isByteStream = response => {
  const contentType = response.headers.get('content-type');
  const contentDisposition = response.headers.get('Content-Disposition');
  return (
    contentType &&
    contentDisposition &&
    contentType.includes('application/octet-stream') &&
    contentDisposition.includes('attachment; filename=')
  );
};

const parseByteStream = response => {
  let filename;
  const content = response.headers.get('Content-Disposition');
  if (content && content.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(content);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
      filename = decodeURI(filename);
      filename = filename.replace(/[+]/g, ' ');
    }
  }
  return response.blob().then(blob => {
    return { content: blob, filename: filename };
  });
};

const parse = response => {
  return response.text().then(text => {
    if (!text || !text.length) return {};
    return JSON.parse(text);
  });
};

const onError = data => {
  const { response, text, type } = data;
  if (type === ErrorTypes.SYSTEM) {
    const key = Math.random();
    message.expandableError(key, i18next.t('errors.systemError'), text, response.status);
    throw getError(response, i18next.t('errors.systemError'), ErrorTypes.SYSTEM);
  } else if (type === ErrorTypes.CUSTOM_SYSTEM) {
    const messages = parseServerMessages(response);
    throw getError(response, messages, ErrorTypes.CUSTOM_SYSTEM);
  } else if (data.status) {
    const message = data.status.toString() + ': ' + data.statusText;
    message.error(message);
    throw getError(response, message);
  } else if (data.message) {
    message('error', data.message);
    throw getError(response, data.message);
  } else {
    message('error', i18next.t('errors.noResponseFromServer'));
    throw getError(response, i18next.t('errors.noResponseFromServer'));
  }
};

const getError = (response, message, type) => {
  let error = new Error();
  error.response = response;
  error.message = message;
  if (type) error.type = type;
  return error;
};

const parseServerMessages = messages => {
  const parseMessages = [];
  if (!messages) return parseMessages;
  if (messages.tag !== undefined) messages = [messages];
  for (let msg of messages) {
    const params =
      msg.parameters.length > 0
        ? msg.parameters.reduce((previousValue, currentItem, index) => {
            previousValue['param' + (index + 1)] = currentItem;
            return previousValue;
          }, {})
        : undefined;
    const text = i18next.t([`serverMessages.${msg.tag}`, msg.defaultValue || ''], params);
    message(msg.type.toLowerCase(), text || i18next.t('errors.unknownError'));
    parseMessages.push(text);
  }
  return parseMessages;
};

const fetchJson = (...args) => {
  const [url, headers = {}] = args;
  return fetch(restApi + url, {
    credentials: 'include',
    headers
  })
    .then(onSuccess)
    .catch(onError);
};

export const fetchRequest = (...args) => {
  const [url, type = 'POST', body = {}, headers = {}] = args;
  return fetch(restApi + url, {
    method: type,
    credentials: 'include',
    headers: { 'Content-Type': 'application/json', ...headers },
    body: parseRequestBody(headers, body)
  })
    .then(onSuccess)
    .catch(onError);
};

const parseRequestBody = (headers, body) => {
  if (headers['Content-Type']?.includes('application/octet-stream')) {
    return body;
  }
  return JSON.stringify(body);
};

export const fetchLoginRequest = (...args) => {
  const [url, type = 'POST'] = args;
  return fetch(address + url, {
    method: type,
    credentials: 'include'
  }).then(response => {
    const isOk = response.status === 200;
    const error = response.headers.get('error');
    if (!isOk) throw new Error(i18next.t(`serverMessages.${error}`));
    return { status: isOk };
  });
};

export const fetchLogoutRequest = (...args) => {
  const [url, type = 'GET'] = args;
  return fetch(address + url, {
    method: type,
    credentials: 'include'
  }).then(response => {
    return response.status === 200;
  });
};

export default fetchJson;
