import React from 'react';

const ImportProject = ({ color = '#FFF', x = '16', y = '16' }) => {
  return (
    <svg width="28px" height="28px" viewBox={`0 0 ${x} ${y}`}>
      <g>
        <rect
          stroke="null"
          fill={color}
          x="4.529262"
          y="11.339726"
          width="6.941476"
          height="0.513423"
          id="svg_7"
        />
        <path
          stroke="null"
          fill={color}
          d="m11.269719,7.417272c-0.105526,-0.105525 -0.274963,-0.105525 -0.380487,0l-2.618822,2.620309l0,-6.226019c0,-0.148628 -0.12039,-0.269016 -0.269018,-0.269016c-0.148628,0 -0.269016,0.120388 -0.269016,0.269016l0,6.226019l-2.620308,-2.620309c-0.105526,-0.105525 -0.274961,-0.105525 -0.380487,0c-0.105526,0.105526 -0.105526,0.274963 0,0.380487l3.078082,3.078082c0.105525,0.105525 0.274961,0.105525 0.380487,0l3.07808,-3.078082c0.105526,-0.104039 0.105526,-0.274961 0.001487,-0.380487z"
          id="svg_1"
        />
        <path
          fill={color}
          d="m15.962986,5.658871l-0.586571,-1.460082l-2.025598,-0.074819c-0.034681,-0.001272 -0.067735,-0.015796 -0.092251,-0.040676l-1.227274,-1.248518c-0.024515,-0.025061 -0.038676,-0.05847 -0.039039,-0.093704l-0.027241,-2.02577l-1.44047,-0.624533l-1.488594,1.38635c-0.024878,0.023427 -0.058105,0.036497 -0.092615,0.036497l-0.000362,0l-1.74502,-0.007075c-0.035407,-0.000183 -0.069551,-0.014171 -0.094976,-0.039051l-1.454631,-1.427388l-1.452461,0.579495l-0.067916,2.039205c-0.001272,0.035234 -0.016341,0.068835 -0.041585,0.09335l-1.255604,1.213103c-0.025061,0.024153 -0.058105,0.037768 -0.092977,0.038312l-2.031947,0.02706l-0.611996,1.447373l1.380174,1.488956c0.023608,0.025425 0.036688,0.058834 0.036324,0.093705l-0.014171,1.745201c-0.000364,0.035043 -0.014159,0.069188 -0.039039,0.094431l-1.420132,1.447545l0.58639,1.459545l2.025416,0.074821c0.034681,0.001443 0.067554,0.015614 0.091887,0.040494l1.227274,1.241433c0.025242,0.025606 0.039403,0.059923 0.039403,0.095703c0,0.035416 0.016341,1.637688 0.020337,2.037397l1.439925,0.605092l1.496587,-1.373632c0.025061,-0.023064 0.057924,-0.035781 0.092068,-0.035781l0.001091,0l1.737934,0.014171c0.034691,0.000364 0.068462,0.013797 0.093704,0.038314l1.462079,1.414501l1.452824,-0.573501l0.067735,-2.038851c0.001091,-0.035043 0.015977,-0.06846 0.040857,-0.092977l1.24852,-1.220188c0.025242,-0.024516 0.058842,-0.038314 0.093704,-0.038677l2.032673,-0.027241l0.618174,-1.433747l-1.380174,-1.495679c-0.023789,-0.025606 -0.036507,-0.058843 -0.036143,-0.093342l0.014171,-1.702516c0,-0.071742 0,-0.089719 1.459535,-1.584307zm-8.034083,8.056064c-3.149708,0 -5.711937,-2.562229 -5.711937,-5.71212c0,-3.145529 2.562229,-5.704852 5.711937,-5.704852c3.145712,0 5.704852,2.559323 5.704852,5.704852c0,3.149891 -2.559141,5.71212 -5.704852,5.71212z"
          id="svg_6"
        />
      </g>
    </svg>
  );
};

export default ImportProject;
