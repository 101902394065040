import { takeEvery } from 'redux-saga/effects';
import {
  SKUD_ENTITIES,
  getAddActionType,
  getUpdateActionType,
  getRemoveActionType,
  WORK_SCHEDULE_DAY_ADD,
  WORK_SCHEDULE_DAY_UPDATE,
  WORK_SCHEDULE_DAY_REMOVE,
  ACCESS_GRANTED_TIME_ADD,
  ACCESS_GRANTED_TIME_UPDATE,
  ACCESS_GRANTED_TIME_REMOVE,
  SKUD_WRITE_DB_TO_CONTROL_DEVICES
} from 'constants/actionTypes';
import { fetch, request, dispatchFail, dispatchSuccess } from 'helpers/request';
import {
  ACCESS_KEY_START_READ_VALUE,
  ACCESS_KEY_STOP_READ_VALUE,
  SKUD_OBSERVER_GET,
  SKUD_OBSERVER_SET_AUTOUPDATE
} from 'constants/actionTypeModules/skud';
import { SKUD_AUTOUPDATE_OBSERVER_ID } from 'constants/skud';
import i18next from "i18next";

const SKUD_ENTITY_NAMES = Object.keys(SKUD_ENTITIES);

const addSkudEntity = (entityName, entityPath) => {
  return function*({ payload }) {
    const { projectId, entityData } = payload;
    try {
      yield request(`/projects/${projectId}/${entityPath}`, 'POST', entityData);
    } catch (error) {
      yield dispatchFail(getAddActionType(entityName), error);
    }
  };
};

const updateSkudEntity = (entityName, entityPath) => {
  return function*({ payload }) {
    const { projectId, entityId, entityData } = payload;
    try {
      yield request(`/projects/${projectId}/${entityPath}/${entityId}`, 'PUT', entityData);
    } catch (error) {
      yield dispatchFail(getUpdateActionType(entityName), error);
    }
  };
};

const removeSkudEntity = (entityName, entityPath) => {
  return function*({ payload }) {
    const { projectId, entityId } = payload;
    try {
      yield request(`/projects/${projectId}/${entityPath}/${entityId}`, 'DELETE');
    } catch (error) {
      yield dispatchFail(getRemoveActionType(entityName), error);
    }
  };
};

function* addWorkScheduleDay({ payload }) {
  const { projectId, workScheduleId, day } = payload;
  try {
    yield request(
      `/projects/${projectId}/${SKUD_ENTITIES.workSchedules.entityPath}/${workScheduleId}/days`,
      'POST',
      day
    );
  } catch (error) {
    yield dispatchFail(WORK_SCHEDULE_DAY_ADD, error);
  }
}

function* updateWorkScheduleDay({ payload }) {
  const { projectId, workScheduleId, dayNo, day } = payload;
  try {
    yield request(
      `/projects/${projectId}/${SKUD_ENTITIES.workSchedules.entityPath}/${workScheduleId}/days/${dayNo}`,
      'PUT',
      day
    );
  } catch (error) {
    yield dispatchFail(WORK_SCHEDULE_DAY_UPDATE, error);
  }
}

function* removeWorkScheduleDay({ payload }) {
  const { projectId, workScheduleId, dayNo } = payload;
  try {
    yield request(
      `/projects/${projectId}/${SKUD_ENTITIES.workSchedules.entityPath}/${workScheduleId}/days/${dayNo}`,
      'DELETE'
    );
  } catch (error) {
    yield dispatchFail(WORK_SCHEDULE_DAY_REMOVE, error);
  }
}

function* addAccessGrantedTime({ payload }) {
  const { projectId, workScheduleId, dayNo, accessGrantedTime } = payload;
  try {
    yield request(
      `/projects/${projectId}/${SKUD_ENTITIES.workSchedules.entityPath}/${workScheduleId}/days/${dayNo}/access_granted_times`,
      'POST',
      accessGrantedTime
    );
  } catch (error) {
    yield dispatchFail(ACCESS_GRANTED_TIME_ADD, error);
  }
}

function* updateAccessGrantedTime({ payload }) {
  const { projectId, workScheduleId, dayNo, timeNo, accessGrantedTime } = payload;
  try {
    yield request(
      `/projects/${projectId}/${SKUD_ENTITIES.workSchedules.entityPath}/${workScheduleId}/days/${dayNo}/access_granted_times/${timeNo}`,
      'PUT',
      accessGrantedTime
    );
  } catch (error) {
    yield dispatchFail(ACCESS_GRANTED_TIME_UPDATE, error);
  }
}

function* removeAccessGrantedTime({ payload }) {
  const { projectId, workScheduleId, dayNo, timeNo } = payload;
  try {
    yield request(
      `/projects/${projectId}/${SKUD_ENTITIES.workSchedules.entityPath}/${workScheduleId}/days/${dayNo}/access_granted_times/${timeNo}`,
      'DELETE'
    );
  } catch (error) {
    yield dispatchFail(ACCESS_GRANTED_TIME_REMOVE, error);
  }
}

function* writeSkudDBToControlDevices({ payload: { projectId, controlDeviceIds } }) {
  const requestBody = {
    action: 'WRITE_SKUD_DATABASE',
    projectId,
    parameters: { controlDeviceIds }
  };
  try {
    yield request('/issues', 'POST', requestBody);

    /* Если success - то ничего не делаем, созданные задачи придут по WS */
  } catch (error) {
    yield dispatchFail(SKUD_WRITE_DB_TO_CONTROL_DEVICES, error);
  }
}

function* startReadAccessKeyValue({ payload: { projectId, deviceId, accessKeyId } }) {
  const requestBody = {
    action: 'START_READ_ACCESS_KEY',
    projectId,
    deviceId,
    parameters: { accessKeyId }
  };
  try {
    const response = yield request('/issues', 'POST', requestBody);

    yield dispatchSuccess(ACCESS_KEY_START_READ_VALUE, response);
  } catch (error) {
    yield dispatchFail(ACCESS_KEY_START_READ_VALUE, error);
  }
}

function* stopReadAccessKeyValue({ payload: { projectId, deviceId } }) {
  const requestBody = {
    action: 'STOP_READ_ACCESS_KEY',
    projectId,
    deviceId
  };
  try {
    const response = yield request('/issues', 'POST', requestBody);

    yield dispatchSuccess(ACCESS_KEY_STOP_READ_VALUE, response);
  } catch (error) {
    yield dispatchFail(ACCESS_KEY_STOP_READ_VALUE, error);
  }
}

function* getSkudObserver() {
  try {
    const response = yield fetch(`/entity_observers/skud/${SKUD_AUTOUPDATE_OBSERVER_ID}`);

    if (!response || !response.id) throw new Error(i18next.t('messages.emptyAnswer'));

    yield dispatchSuccess(SKUD_OBSERVER_GET, response);
  } catch (error) {
    yield dispatchFail(SKUD_OBSERVER_GET, error);
  }
}

function* setSkudAutoUpdate({ payload: { autoUpdateOn } }) {
  try {
    const response = yield request(
      `/entity_observers/skud/${SKUD_AUTOUPDATE_OBSERVER_ID}?autoUpdateOn=${autoUpdateOn}`,
      'PUT'
    );

    yield dispatchSuccess(SKUD_OBSERVER_SET_AUTOUPDATE, response);
  } catch (error) {
    yield dispatchFail(SKUD_OBSERVER_SET_AUTOUPDATE, error);
  }
}

export default function* skud() {
  for (let i = 0; i < SKUD_ENTITY_NAMES.length; ++i) {
    const entityName = SKUD_ENTITY_NAMES[i];
    const { entityPath } = SKUD_ENTITIES[entityName];
    yield takeEvery(getAddActionType(entityName), addSkudEntity(entityName, entityPath));
    yield takeEvery(getUpdateActionType(entityName), updateSkudEntity(entityName, entityPath));
    yield takeEvery(getRemoveActionType(entityName), removeSkudEntity(entityName, entityPath));
  }
  yield takeEvery(WORK_SCHEDULE_DAY_ADD, addWorkScheduleDay);
  yield takeEvery(WORK_SCHEDULE_DAY_UPDATE, updateWorkScheduleDay);
  yield takeEvery(WORK_SCHEDULE_DAY_REMOVE, removeWorkScheduleDay);
  yield takeEvery(ACCESS_GRANTED_TIME_ADD, addAccessGrantedTime);
  yield takeEvery(ACCESS_GRANTED_TIME_UPDATE, updateAccessGrantedTime);
  yield takeEvery(ACCESS_GRANTED_TIME_REMOVE, removeAccessGrantedTime);
  yield takeEvery(SKUD_WRITE_DB_TO_CONTROL_DEVICES, writeSkudDBToControlDevices);
  yield takeEvery(ACCESS_KEY_START_READ_VALUE, startReadAccessKeyValue);
  yield takeEvery(ACCESS_KEY_STOP_READ_VALUE, stopReadAccessKeyValue);
  yield takeEvery(SKUD_OBSERVER_GET, getSkudObserver);
  yield takeEvery(SKUD_OBSERVER_SET_AUTOUPDATE, setSkudAutoUpdate);
}
