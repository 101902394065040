import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Select } from 'antd';

import Button from 'components/Button';
import Menu from 'components/Menu';
import ButtonGroup from 'components/Button/ButtonGroup';
import styles from './Toolbar.module.css';
const { Option } = Select;
const textStyle = `margin: 0;`;

/**
 * TODO: Переделать поле action на строчную функцию
 *       и внутри родительского компонента вызывать ее через eval()
 */

/**
 * Описание набора инструментов
 *
 * tools: [
 *   [
 *     {
 *       key: <string> - Уникальный ключ
 *       title: <string> - Заголовок для тултипа
 *       icon: <string> - Название иконки из списка в проекте или antd
 *       customIcon: <bool> - Если true, то иконка берется из списка в проекте, иначе antd
 *       selectable: <bool> - Если true, то может быть нажата, иначе работает как чекбокс
 *       type: <string (button|flag|options)>
 *       action: <string> - Название функции, которая будет вызвана внутри родительского компонента
 *       options: <array> - Список допустимых значений для Select,
 *       altTitle: <string> - Альтернативный текст для тултипа (если type === 'flag')
 *       hotKeys: <array> - Коды горячих клавиш
 *       hotKeysAlt: <array> - Альтернативные коды горячих клавиш
 *     },
 *     ...
 *   ],
 *   ...
 * ]
 */

class Toolbar extends React.Component {
  /**
   * Набор инструментов, разбитый по группам
   *
   * @property {string} activeTool - Текущий инструмент
   * @property {func} onButtonClick - Смена текущего инструмента
   * @property {bool} isActive - Если меню активно
   * @property {bool} isVertical - Должно ли меню отображаться вертикально
   * @property {object} selectedObject - Выбранный объект, с которым происходит взаимодействие
   * @property {array} isChecked - Список нажатых кнопок (type === 'flag')
   * @property {array} hiddenElements - Список инструментов, которые нужно скыть
   * @property {func} onChange - Вызывает функцию указаную в параметре action
   * @property {array} tools - Список инструментов (описан выше)
   * @property {array} tools - Список идентификаторов неактивных инструментов
   */
  static propTypes = {
    activeTool: PropTypes.string,
    onButtonClick: PropTypes.func,
    isActive: PropTypes.bool,
    isVertical: PropTypes.bool,
    selectedObject: PropTypes.object,
    isChecked: PropTypes.array,
    hiddenTools: PropTypes.array,
    onChange: PropTypes.func,
    tools: PropTypes.array,
    inactiveTools: PropTypes.array
  };

  static defaultProps = {
    activeTool: '',
    onButtonClick: () => {},
    isActive: true,
    isVertical: true,
    selectedObject: null,
    isChecked: [],
    hiddenTools: [],
    onChange: () => {},
    tools: [],
    inactiveTools: []
  };

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown);
    document.addEventListener('keyup', this.onKeyUp);
  }

  pressedKeys = [];

  onKeyDown = e => {
    const { tools, onButtonClick } = this.props;
    if (tools && tools.length) {
      //Проверяем, нажаты ли горячие клавиши
      if (this.pressedKeys.indexOf(e.keyCode) === -1) this.pressedKeys.push(e.keyCode);
      tools.forEach(toolGroup => {
        if (toolGroup && toolGroup.length) {
          toolGroup.forEach(tool => {
            if (!this.checkHotKeys(e, tool, onButtonClick, tool.hotKeys)) {
              this.checkHotKeys(e, tool, onButtonClick, tool.hotKeysAlt);
            }
          });
        }
      });
    }
  };

  checkHotKeys(event, tool, onButtonClick, hotKeys) {
    if (hotKeys && hotKeys.length) {
      for (let i = 0; i < hotKeys.length; i++) {
        if (this.pressedKeys[i] !== hotKeys[i]) return false;
      }
      onButtonClick({ key: tool.key, selectable: tool.selectable, type: tool.type });
      event.preventDefault();
      return true;
    } else return false;
  }

  onKeyUp = e => {
    const index = this.pressedKeys.indexOf(e.keyCode);
    if (index !== -1) {
      this.pressedKeys.splice(index, 1);
    }
  };

  getDivider(key) {
    const style = this.props.isVertical
      ? { width: 24, height: 0, margin: '0px 0px 4px', border: '1px solid white', opacity: 0.6 }
      : { width: 0, height: 24, margin: '0px 4px', border: '1px solid white', opacity: 0.6 };
    return <div key={key} style={style} />;
  }

  getClassName(tool, activeTool, isSwitchedOn, isActive, isActiveTool) {
    const inactiveStyle = !isActive || !isActiveTool ? styles.inactiveToolbar : '';
    return `${inactiveStyle} ${
      (tool.type === 'flag' && isSwitchedOn) || (tool.key === activeTool && isActive)
        ? styles.checked
        : styles.usual
    }`;
  }

  renderTool = (tool, isSwitchedOn, otherProps) => {
    const {
      activeTool,
      onButtonClick,
      isActive,
      selectedObject,
      isChecked,
      hiddenTools,
      onChange,
      toolsParams,
      isVertical,
      inactiveTools,
      ...restProps
    } = this.props;
    const isActiveTool = !inactiveTools.includes(tool.key);

    if (hiddenTools.includes(tool.key)) {
      return null;
    } else if (tool.key && tool.type)
      return (
        <Tooltip
          ref={tool.outerRefName ? otherProps[tool.outerRefName] : null}
          placement={'right'}
          key={tool.key}
          title={tool.type === 'flag' ? (!isSwitchedOn ? tool.title : tool.altTitle) : tool.title}
        >
          {tool.type === 'button' || tool.type === 'flag' ? (
            <Button
              customIcon={tool.customIcon}
              icon={tool.icon ? tool.icon : null}
              onClick={() =>
                onButtonClick({ key: tool.key, selectable: tool.selectable, type: tool.type })
              }
              className={this.getClassName(tool, activeTool, isSwitchedOn, isActive, isActiveTool)}
              isActive={isActive && isActiveTool}
              css={textStyle}
            />
          ) : (
            <Select
              size="small"
              defaultValue={`${restProps[tool.key] || ''}`}
              value={`${restProps[tool.key] || ''}`}
              onChange={value => onChange({ value, key: tool.key, action: tool.action })}
              disabled={!isActive || !isActiveTool}
            >
              {tool.options.map((option, index) => (
                <Option key={index} value={`${option.value}`}>
                  {`${option.text || option.value}`}
                </Option>
              ))}
            </Select>
          )}
        </Tooltip>
      );
    return this.getDivider(tool.key);
  };

  renderToolsGroup = (group, otherProps) => {
    const style = {
      justifyContent: 'start',
      flexDirection: this.props.isVertical ? 'column' : 'row'
    };
    return (
      <ButtonGroup key={this.props.name} isVertical={this.props.isVertical} style={style}>
        {group.map((tool, index) => {
          const isSwitchedOn = otherProps.isChecked.includes(tool.key);
          return tool.Custom ? (
            <tool.Custom
              key={tool.key + index}
              tool={tool}
              Head={this.renderTool}
              isSwitchedOn={isSwitchedOn}
              {...otherProps}
            />
          ) : (
            this.renderTool(tool, isSwitchedOn, otherProps)
          );
        })}
      </ButtonGroup>
    );
  };

  render = () => {
    const { isActive, tools, isVertical, style, toolBarRef } = this.props;
    return (
      <Menu
        toolBarRef={toolBarRef}
        className={styles.toolbar}
        isActive={isActive}
        isVertical={isVertical}
        style={style}
      >
        {this.renderToolsGroup(tools, this.props)}
      </Menu>
    );
  };

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
    document.removeEventListener('keyup', this.onKeyUp);
  }
}

export default Toolbar;
