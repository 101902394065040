import {
  InfoCircleOutlined,
  FireOutlined,
  BellOutlined,
  WarningOutlined,
  CloseCircleOutlined,
  StopOutlined,
  QuestionCircleOutlined,
  PlayCircleOutlined,
  SafetyCertificateOutlined,
  CloseSquareOutlined,
  CheckCircleOutlined,
  MinusCircleOutlined,
  SettingOutlined,
  ClockCircleOutlined,
  AlertOutlined
} from '@ant-design/icons';
import { STATE_CATEGORIES } from 'constants/stateCategories';

export const getIconByStateCategory = id => {
  switch (id) {
    case STATE_CATEGORIES.EXTINCTION:
      return AlertOutlined;
    case STATE_CATEGORIES.FIRE:
    case STATE_CATEGORIES.FIRE1:
      return FireOutlined;
    case STATE_CATEGORIES.ALARM:
      return BellOutlined;
    case STATE_CATEGORIES.WARNING:
      return WarningOutlined;
    case STATE_CATEGORIES.AUTO_OFF:
      return ClockCircleOutlined;
    case STATE_CATEGORIES.ERROR:
      return StopOutlined;
    case STATE_CATEGORIES.MALFUNCTION:
      return CloseCircleOutlined;
    case STATE_CATEGORIES.IGNORE:
      return MinusCircleOutlined;
    case STATE_CATEGORIES.UNKNOWN:
      return QuestionCircleOutlined;
    case STATE_CATEGORIES.RUN:
      return PlayCircleOutlined;
    case STATE_CATEGORIES.SERVICE:
      return SettingOutlined;
    case STATE_CATEGORIES.ON_GUARD:
      return SafetyCertificateOutlined;
    case STATE_CATEGORIES.OFF_GUARD:
      return CloseSquareOutlined;
    case STATE_CATEGORIES.INFO:
      return InfoCircleOutlined;
    case STATE_CATEGORIES.NORMAL:
      return CheckCircleOutlined;
    default:
      return null;
  }
};

export function getClockIcon(width, height) {
  return `<svg aria-hidden="true" focusable="false" x="0px" y="0px" width="${width}px" height="${height}px" 
  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="-250 -250 884 1012">
  <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393
   8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200
    200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12
     12v141.7l66.8 48.6c5.4 3.9 6.511.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"></path></svg>`;
}
