import { combineReducers } from 'redux';

import {
  isLoaded,
  DEVICE_SHAPE_LIBRARIES_LOAD,
  DEVICE_SHAPE_LIBRARY_ADD,
  DEVICE_SHAPE_LIBRARY_UPDATE,
  DEVICE_SHAPE_LIBRARY_REMOVE,
  DEVICE_SHAPES_LOAD,
  DEVICE_SHAPE_ADD,
  DEVICE_SHAPE_UPDATE,
  DEVICE_SHAPE_REMOVE
} from 'constants/actionTypes';

/*
 * state = {
 *    <deviceShapeLibraryId>: [{id: <string>, ... (см. метод GET api/v1/device_shape_libraries в тех. проекте)}, ...],
 *    <deviceShapeLibraryId>: [{id: <string>, ... ],
 *    ...
 * }
 */
function deviceShapeLibrariesHash(state = {}, action) {
  switch (action.type) {
    case isLoaded(DEVICE_SHAPE_LIBRARIES_LOAD, true): {
      const deviceShapeLibraries = action.payload;
      const deviceShapeLibrariesHash = {};
      deviceShapeLibraries.forEach(
        deviceShapeLibrary => (deviceShapeLibrariesHash[deviceShapeLibrary.id] = deviceShapeLibrary)
      );
      return deviceShapeLibrariesHash;
    }

    case isLoaded(DEVICE_SHAPE_LIBRARY_UPDATE, true):
    case isLoaded(DEVICE_SHAPE_LIBRARY_ADD, true): {
      const receivedDeviceShapeLibraries = action.payload;
      const deviceShapeLibrariesHash = { ...state };
      receivedDeviceShapeLibraries.forEach(deviceShapeLibrary => {
        deviceShapeLibrariesHash[deviceShapeLibrary.id] = deviceShapeLibrary;
      });
      
      return deviceShapeLibrariesHash;
    }


    case isLoaded(DEVICE_SHAPE_LIBRARY_REMOVE, true): {
      const removedDeviceShapeLibraryIds = action.payload;
      const deviceShapeLibrariesHash = { ...state };
      removedDeviceShapeLibraryIds.forEach(deviceShapeLibraryId => {
        delete deviceShapeLibrariesHash[deviceShapeLibraryId];
      });
      return deviceShapeLibrariesHash;
    }

    default:
      return state;
  }
}

/*
 * state = {
 *    <deviceShapeId>: [{id: <string>, ... (см. метод GET api/v1/device_shapes в тех. проекте)}, ...],
 *    <deviceShapeId>: [{id: <string>, ... ],
 *    ...
 * }
 */
function deviceShapesHash(state = {}, action) {
  switch (action.type) {
    case isLoaded(DEVICE_SHAPES_LOAD, true): {
      const deviceShapes = action.payload;
      const deviceShapesHash = {};
      deviceShapes.forEach(deviceShape => (deviceShapesHash[deviceShape.id] = deviceShape));
      return deviceShapesHash;
    }

    case isLoaded(DEVICE_SHAPE_UPDATE, true):
    case isLoaded(DEVICE_SHAPE_ADD, true): {
      const receivedDeviceShapes = action.payload;
      const deviceShapesHash = { ...state };
      receivedDeviceShapes.forEach(deviceShape => {
        deviceShapesHash[deviceShape.id] = deviceShape;
      });
      
      return deviceShapesHash;
    }

    case isLoaded(DEVICE_SHAPE_REMOVE, true): {
      const removedDeviceShapeIds = action.payload;
      const deviceShapesHash = { ...state };
      removedDeviceShapeIds.forEach(deviceShapeId => {
        delete deviceShapesHash[deviceShapeId];
      });
      return deviceShapesHash;
    }

    default:
      return state;
  }
}

export default combineReducers({
  deviceShapeLibrariesHash,
  deviceShapesHash
});
