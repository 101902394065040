import moment from 'moment';
import { getSettings }  from 'constants/settings';

export function getDateFormat(date){
  return moment(date).format(getSettings().timeFormat);
}

export function getDateTimestamp(timestamp){
  return moment(timestamp).format(getSettings().timeFormat);
}

export function getCurrentTime(){
  return moment().format(getSettings().timeFormat);
}
