import React from 'react';

const Logo = ({ color = "#FFF", x = "13", y = "20" }) => {
  return (
    <svg width={x} height={y} viewBox={`0 0 ${x} ${y}`}>
      <path fill={color} fillRule="evenodd" d="M5.442 19.988H0V0h12.087v4.334H5.442v3.814h6.128v4.334H5.442v7.506z" />
    </svg>
  )
}

export default Logo;