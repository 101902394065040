import { useEffect } from 'react';
import { localStorage } from 'helpers/storage';
import { useIsMounted } from 'customHooks/useIsMounted';
import useWindowEvent from 'customHooks/useWindowEvent';

export type UseStorageItemsType = {
  key: string;
  onSet: (value: any) => void;
  onCheck: (value: any) => boolean;
  value: any;
  defaultValue: any;
};

export type UseStorageParamsType = {
  current: {
    items: UseStorageItemsType[];
    key: string | undefined;
  };
};

const getStorageKey = (key: string, projectId: string | undefined) =>
  `${key}${projectId ? '_' + projectId : ''}`;

/**
 * Хук, позволяющий загружать из и сохранять в LocalStorage
 * данные. Принимает в качестве аргумента объект типа @type {UseStorageParamsType},
 * созданный в функции-хелпера @name getStorageItem.
 * Загрузка данны будет происходить при моунте компонента, сохранение
 * происходит при размаунте компонента или при событии 'unload'
 */
export const useStorage = (storageItems: UseStorageParamsType) => {
  const isComponentMounted = useIsMounted();
  function saveToStorage() {
    storageItems.current.items.forEach(({ value, key }) => {
      if (value === undefined) return;
      const storageKey = getStorageKey(key, storageItems.current.key);
      localStorage.setItem(storageKey, JSON.stringify(value), false);
    });
  }

  useEffect(() => {
    storageItems.current.items.forEach(({ key, onSet, defaultValue, onCheck }) => {
      const storageKey = getStorageKey(key, storageItems.current.key);
      const storageData = localStorage.getItem(storageKey);
      const parsedStorageData = storageData ? JSON.parse(storageData) : defaultValue;
      if (parsedStorageData && onCheck(parsedStorageData)) onSet(parsedStorageData);
    });
    // eslint-disable-next-line
  }, [storageItems.current.key]);

  useWindowEvent('unload', saveToStorage);

  useEffect(
    () => () => {
      if (!isComponentMounted.current) saveToStorage();
    },
    // eslint-disable-next-line
    []
  );
};
