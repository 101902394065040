import {
  PROJECT_CREATE,
  PROJECT_DELETE,
  PROJECTS_LOAD,
  PROJECT_UPDATE,
  PROJECTS_UPDATE,
  PROJECT_UPDATE_STATUS,
  isLoaded,
  PROJECT_LOAD_ACTIVE,
  PROJECT_LOAD_CURRENT,
  PROJECT_IMPORT,
  PROJECT_VALIDATE,
  PROJECT_VALIDATE_MESSAGE_LOAD,
  PROJECT_VALIDATE_DELETE
} from 'constants/actionTypes';

import { VALIDATE_MESSAGE_LEVEL_TO_NOTIF_TYPE } from 'containers/Menus/CurrentProjectMenu';

import { removeIndicatorIdsFromStorageByProjectId } from 'helpers/indicators';

/*
 * state = projects: {
 *  <projectId>: {
 *      id: <string>,
 *      name: <string>,
 *      ...,
 *      entitiesLoaded: <boolean> - были ли загружены сущности проекта (устройств, зон и т.д.)
 *  },
 *  <projectId>: {
 *    ...
 *  },
 *  ...
 * }
 */
export default function projects(state = {}, action) {
  switch (action.type) {
    case isLoaded(PROJECTS_LOAD, true): {
      const projects = action.payload;
      const newState = {};
      projects.forEach(project => {
        newState[project.id] = { ...project, entitiesLoaded: false };
      });
      return newState;
    }
    case isLoaded(PROJECT_CREATE, true): {
      const createdProject = action.payload;
      const newState = { ...state };
      newState[createdProject.id] = { ...createdProject, entitiesLoaded: false };
      return newState;
    }
    case isLoaded(PROJECT_IMPORT, true): {
      const importedProject = action.payload;
      const newState = { ...state };
      newState[importedProject.id] = { ...importedProject, entitiesLoaded: false };
      return newState;
    }
    case isLoaded(PROJECT_UPDATE, true): {
      const updatedProject = action.payload;
      const newState = { ...state };
      newState[updatedProject.id] = {
        ...updatedProject,
        entitiesLoaded: newState[updatedProject.id].entitiesLoaded
      };
      return newState;
    }
    case PROJECTS_UPDATE: {
      const updatedProjects = action.payload;
      const newState = { ...state };
      updatedProjects.forEach(updatedProject => {
        if (newState[updatedProject.id]) newState[updatedProject.id] = updatedProject;
      });

      return newState;
    }
    case isLoaded(PROJECT_UPDATE_STATUS, true):
    case isLoaded(PROJECT_LOAD_CURRENT, true):
    case isLoaded(PROJECT_LOAD_ACTIVE, true): {
      const { project: loadedProject } = action.payload;
      const newState = { ...state };
      newState[loadedProject.id] = { ...loadedProject, entitiesLoaded: true };
      return newState;
    }
    case isLoaded(PROJECT_DELETE, true): {
      const projectId = action.payload;
      const newState = { ...state };
      removeIndicatorIdsFromStorageByProjectId(projectId);
      delete newState[projectId];
      return newState;
    }
    case isLoaded(PROJECT_VALIDATE, true): {
      const project = action.payload;
      const newState = { ...state };
      newState[project.id] = {
        ...project,
        entitiesLoaded: newState[project.id].entitiesLoaded
      };
      return newState;
    }
    case isLoaded(PROJECT_VALIDATE_MESSAGE_LOAD, true): {
      const {
        importValidateMessages,
        validateMessages,
        totalImportValidateMessagesCount,
        totalValidateMessagesCount,
        projectId
      } = action.payload;
      const newState = { ...state };
      let hasError = false,
        hasWarning = false;

      if (validateMessages) {
        hasError = !!validateMessages.find(
          message => message.level === VALIDATE_MESSAGE_LEVEL_TO_NOTIF_TYPE.ERROR
        );
        hasWarning = !!validateMessages.find(
          message => message.level === VALIDATE_MESSAGE_LEVEL_TO_NOTIF_TYPE.WARNING
        );
      }
      newState[projectId] = {
        ...newState[projectId],
        validateMessages: {
          importMessages: {
            currentPageMessages: importValidateMessages,
            totalValidateMessagesCount: totalImportValidateMessagesCount
          },
          statusMessages: {
            currentPageMessages: validateMessages,
            totalValidateMessagesCount: totalValidateMessagesCount
          },
          hasError,
          hasWarning
        }
      };
      return newState;
    }
    case isLoaded(PROJECT_VALIDATE_DELETE, true): {
      const { validateMessageIds, projectId } = action.payload;

      if(!state || !state[projectId] || !state[projectId].validateMessages) return state;
      const newState = { ...state };

      const newStatusValidateMessages = [
        ...newState[projectId].validateMessages.statusMessages.currentPageMessages
      ];

      const newImportValidateMessages = [
        ...newState[projectId].validateMessages.importMessages.currentPageMessages
      ];
      for (let i = 0; i < newStatusValidateMessages.length; i++) {
        if (validateMessageIds.includes(newStatusValidateMessages[i].id)) {
          newStatusValidateMessages.splice(i, 1);
          i--;
        }
      }
      const newTotalValidateMessagesCount =
        newState[projectId].validateMessages.statusMessages.totalValidateMessagesCount +
        newStatusValidateMessages.length -
        newState[projectId].validateMessages.statusMessages.currentPageMessages.length;

      for (let i = 0; i < newImportValidateMessages.length; i++) {
        if (validateMessageIds.includes(newImportValidateMessages[i].id)) {
          newImportValidateMessages.splice(i, 1);
          i--;
        }
      }
      const newTotalImportValidateMessagesCount =
        newState[projectId].validateMessages.importMessages.totalValidateMessagesCount +
        newImportValidateMessages.length -
        newState[projectId].validateMessages.importMessages.currentPageMessages.length;

      let hasError = false,
        hasWarning = false;
      if (newStatusValidateMessages) {
        hasError = !!newStatusValidateMessages.find(
          message => message.level === VALIDATE_MESSAGE_LEVEL_TO_NOTIF_TYPE.ERROR
        );
        hasWarning = !!newStatusValidateMessages.find(
          message => message.level === VALIDATE_MESSAGE_LEVEL_TO_NOTIF_TYPE.WARNING
        );
      }

      newState[projectId] = {
        ...newState[projectId],
        validateMessages: {
          importMessages: {
            currentPageMessages: newImportValidateMessages,
            totalValidateMessagesCount: newTotalImportValidateMessagesCount
          },
          statusMessages: {
            currentPageMessages: newStatusValidateMessages,
            totalValidateMessagesCount: newTotalValidateMessagesCount
          },
          hasError,
          hasWarning
        }
      };
      return newState;
    }
    default:
      return state;
  }
}
