import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Popup from 'components/Popup';
import PopupItem from 'components/Popup/PopupItem';
import { createSelector } from 'reselect';
import { getDevicesByPollingState, getDevicesByRegionId } from 'helpers/device';
import { getActiveDeviceList } from 'helpers/activeProject';
import { setDevicePollingState } from 'actions/devices';
import { setArchiveEventsFilters } from 'actions/events';
import { push } from 'connected-react-router';
import { PATHS } from 'constants/routes';
import { CATEGORY } from 'constants/device';
import { ENTITY_TYPE } from 'constants/entityType';
import SUBSYSTEMS from 'constants/subsystems';
import { changeRegionGuardStatus } from 'actions/regions';
import i18next from 'i18next';
import { getCurrentUser, hasRole } from '../../helpers/user';
import Permissions from '../../constants/permissions';

class PlanEditorContextMenu extends Component {
  static propTypes = {
    currentEntityInfo: PropTypes.object
  };

  static defaultProps = {
    currentEntityInfo: {}
  };

  setDevicePollingState = isPolling => {
    const { project, currentEntityInfo, dispatch } = this.props;
    if (currentEntityInfo)
      dispatch(setDevicePollingState(project.id, currentEntityInfo.entityId, null, isPolling));
  };

  showArchiveEvents = () => {
    const { dispatch, currentEntityInfo } = this.props;
    if (!currentEntityInfo) return;
    let id;
    if (currentEntityInfo.entityType === ENTITY_TYPE.REGION) {
      id = 'regionIds';
    } else if (currentEntityInfo.entityType === ENTITY_TYPE.DEVICE) {
      //prettier-ignore
      id = currentEntityInfo.deviceCategory === CATEGORY.CONTROL.id ? 'controlDeviceIds' : 'deviceIds';
    }

    const filter = { id, value: [currentEntityInfo.entityId] };
    dispatch(setArchiveEventsFilters([filter]));
    dispatch(push(PATHS.OP.LOG));
  };

  changeRegionDevicesPollingState = () => {
    const { dispatch, project, currentEntityInfo, hasDisabledDevices } = this.props;
    const id = currentEntityInfo.entityId;
    dispatch(setDevicePollingState(project.id, null, id, hasDisabledDevices));
  };

  changeGuardStatus = () => {
    const { dispatch, project, currentEntityInfo } = this.props;
    const id = currentEntityInfo.entityId;
    dispatch(changeRegionGuardStatus(project.id, id, !currentEntityInfo.onGuard));
  };

  render() {
    const { menuName, currentEntityInfo, deviceProfile, hasDisabledDevices, currentUser } = this.props;
    if (!currentEntityInfo || !currentEntityInfo.entityId) return null;
    const entityIsFireRegion = currentEntityInfo.entityType === ENTITY_TYPE.REGION &&
      currentEntityInfo.subsystem === SUBSYSTEMS.FIRE.id;
    const entityIsSecurityRegion = currentEntityInfo.entityType === ENTITY_TYPE.REGION &&
      currentEntityInfo.subsystem === SUBSYSTEMS.SECURITY.id;
    const entityIsDevice = currentEntityInfo.entityType === ENTITY_TYPE.DEVICE;

    return (
      <Popup id={menuName}>
        <PopupItem visible={entityIsSecurityRegion || entityIsFireRegion}
                   onClick={this.changeRegionDevicesPollingState}>
          {i18next.t(hasDisabledDevices ? 'zones.enableAllSensors' : 'zones.disableAllSensors')}
        </PopupItem>
        <PopupItem
          visible={entityIsSecurityRegion}
          onClick={this.changeGuardStatus}
        >
          {i18next.t(currentEntityInfo.onGuard ? 'zones.disarm' : 'zones.secure')}
        </PopupItem>
        <PopupItem
          visible={entityIsDevice || entityIsSecurityRegion || entityIsFireRegion}
          onClick={this.showArchiveEvents}
          isLocked={!hasRole(currentUser, Permissions.EVENTS_READ)}
        >
          {i18next.t('monitoring.showEvents')}
        </PopupItem>
        <PopupItem visible={entityIsDevice}
                   disabled={deviceProfile ? !deviceProfile.ignorable : true}
                   onClick={() => this.setDevicePollingState(!currentEntityInfo.statePolling)}>
          {i18next.t(currentEntityInfo.statePolling ? 'monitoring.deviceTreeContext.disable' : 'monitoring.deviceTreeContext.enable')}
        </PopupItem>
      </Popup>
    );
  }
}

const getCurrentEntityInfo = state => state.widgets.currentEntityInfo;

const getRegionDevices = createSelector(
  getActiveDeviceList,
  state => getCurrentEntityInfo(state).entityId,
  (activeDevices, regionId) => (regionId ? getDevicesByRegionId(activeDevices, regionId) : [])
);

const hasDisabledDevices = createSelector(getRegionDevices, devices => {
  if (!devices) return false;
  if (!devices.length) return false;
  const disabledDevices = getDevicesByPollingState(devices, false);
  return !!disabledDevices.length;
});

const mapStateToProps = state => {
  const currentEntityInfo = state.widgets.currentEntityInfo;
  return {
    project: state.activeProject.project,
    currentEntityInfo,
    currentUser: getCurrentUser(state),
    deviceProfile:
      currentEntityInfo && currentEntityInfo.entityType === 'DEVICE'
        ? state.deviceProfileViews[currentEntityInfo.deviceProfileId].deviceProfile
        : null,
    hasDisabledDevices:
      currentEntityInfo && currentEntityInfo.entityType === 'REGION'
        ? hasDisabledDevices(state)
        : false
  };
};

const mapDispatchToProps = dispatch => {
  return { dispatch: dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanEditorContextMenu);
