import {
  MODAL_OPEN,
  MODAL_CLOSE,
} from 'constants/actionTypes';

import { createActions } from 'redux-actions';

export const {
  modalOpen, 
  modalClose, 
} = createActions({
  [MODAL_OPEN]: name => name,
  [MODAL_CLOSE]: name => name,
});