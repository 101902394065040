import React from 'react';

const Gallery = ({ color = '#fff', width, height, x = '16', y = '16' }) => {
  return (
    <svg width={width || x} height={height || y} viewBox={`0 0 ${x} ${y}`}>
      <path
        fill={color}
        stroke="null"
        d="m13.96008,15.946774l-11.920161,0c-1.098645,0 -1.986693,-0.886069 -1.986693,-1.984702l0,-11.922152c0,-1.100636 0.888049,-1.986693 1.986693,-1.986693l11.920161,0c1.098645,0 1.986693,0.886058 1.986693,1.986693l0,11.922152c0,1.098634 -0.888049,1.984702 -1.986693,1.984702l0,0zm0,-13.410184c0,-0.274161 -0.220519,-0.496671 -0.496671,-0.496671l-10.92682,0c-0.276152,0 -0.496671,0.22251 -0.496671,0.496671l0,10.92682c0,0.274161 0.220519,0.496671 0.496671,0.496671l10.92682,0c0.276152,0.001991 0.496671,-0.220519 0.496671,-0.49469l0,-10.9288l0,0zm-9.933467,7.450103l4.966739,-4.966739l2.980035,2.980046l0,3.975378l-7.946774,0l0,-1.988685l0,0zm0.993352,-3.973387c-0.550323,0 -0.993352,-0.44304 -0.993352,-0.993352c0,-0.550312 0.443029,-0.993341 0.993352,-0.993341c0.546341,0 0.993341,0.443029 0.993341,0.993341c0,0.550323 -0.447,0.993352 -0.993341,0.993352l0,0z"
        id="gallery"
      />
    </svg>
  );
};

export default Gallery;
