import { setLogicEntities } from 'actions/CPIU';
import {
  ActiveScenarioView,
  ScenarioLogicBlock,
  ScenarioTimeLineBlock,
  TriggerEntityType
} from 'backendToTSTypes/ScenarioBasicParams';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isSpecialEntityType } from '../localHelpers/isSpecialEntityType';
import { getActiveScenarios } from 'helpers/activeProject';

export type ScenarioEntitiesMapType = Record<string, string[]>;
export type SpecialScenarioEntitiesType = 'DEVICE' | 'REGION' | 'CONTROL_DEVICE';
type ScenarioEntitiesType = Record<TriggerEntityType, ScenarioEntitiesMapType>;
type ScenarioSpecialEntitiesType = Pick<ScenarioEntitiesType, SpecialScenarioEntitiesType>;
type EntitiesByScenariosMapType = Record<TriggerEntityType, Record<string, Set<string>>>;
type SpecialEntitiesByScenariosMapType = Pick<
  EntitiesByScenariosMapType,
  SpecialScenarioEntitiesType
>;

export const specialEntitiesScenarioTypes: SpecialScenarioEntitiesType[] = [
  'DEVICE',
  'REGION',
  'CONTROL_DEVICE'
];

const useScenarioLogicEntity = (activeProjectId: string | null) => {
  const activeScenariosList = useSelector((state: any) =>
    getActiveScenarios(state)
  ) as ActiveScenarioView[];
  const isLoaded = useRef(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!activeProjectId) {
      if (!isLoaded.current) return;
      isLoaded.current = false;
      return;
    }
    const scenarioEntities = {
      REGION: {},
      DEVICE: {},
      CONTROL_DEVICE: {}
    };

    if (isLoaded.current || !activeScenariosList || !activeScenariosList.length) return;
    const prepareEntities: ScenarioSpecialEntitiesType = {
      REGION: {},
      DEVICE: {},
      CONTROL_DEVICE: {}
    };
    for (const scenario of activeScenariosList) {
      updateScenarioEntitiesByLogic(prepareEntities, scenario.startLogic, scenario.id);
      updateScenarioEntitiesByLogic(prepareEntities, scenario.stopLogic, scenario.id);
      updateScenarioEntitiesByTimeLine(prepareEntities, scenario.timeLineBlocks, scenario.id);
    }
    reducePreparedEntities(prepareEntities);
    updateScenarioByEntityId(prepareEntities, scenarioEntities);
    dispatch(
      setLogicEntities({
        regionsByScenariosMap: scenarioEntities.REGION,
        devicesByScenariosMap: Object.assign(
          scenarioEntities.DEVICE,
          scenarioEntities.CONTROL_DEVICE
        )
      })
    );
    isLoaded.current = true;
  }, [activeScenariosList, activeProjectId, dispatch]);
};

function reducePreparedEntities(prepareEntities: ScenarioSpecialEntitiesType) {
  for (const entityName in prepareEntities) {
    if (isSpecialEntityType(entityName))
      for (const scenarioId in prepareEntities[entityName]) {
        prepareEntities[entityName][scenarioId] = Array.from(
          new Set(prepareEntities[entityName][scenarioId])
        );
      }
  }
}

function updateScenarioByEntityId(
  prepareEntities: ScenarioSpecialEntitiesType,
  scenarioEntities: SpecialEntitiesByScenariosMapType
) {
  for (const entityName in prepareEntities) {
    if (isSpecialEntityType(entityName))
      for (const scenarioId in prepareEntities[entityName]) {
        for (const entityId of prepareEntities[entityName][scenarioId]) {
          if (scenarioEntities[entityName][entityId])
            scenarioEntities[entityName][entityId].add(scenarioId);
          else scenarioEntities[entityName][entityId] = new Set([scenarioId]);
        }
      }
  }
}

function updateScenarioEntitiesByLogic(
  entity: ScenarioSpecialEntitiesType,
  scenarioLogic: ScenarioLogicBlock,
  scenarioId: string
) {
  if (!scenarioLogic?.entityType) return;
  if (isSpecialEntityType(scenarioLogic.entityType)) {
    if (entity[scenarioLogic.entityType][scenarioId]) {
      entity[scenarioLogic.entityType][scenarioId] = entity[scenarioLogic.entityType][
        scenarioId
      ].concat(scenarioLogic.entityIds);
    } else {
      entity[scenarioLogic.entityType][scenarioId] = scenarioLogic.entityIds;
    }
  }
  if (scenarioLogic.subLogics.length)
    for (const subLogic of scenarioLogic.subLogics) {
      updateScenarioEntitiesByLogic(entity, subLogic, scenarioId);
    }
}

function updateScenarioEntitiesByTimeLine(
  entity: ScenarioSpecialEntitiesType,
  timeLineBlocks: ScenarioTimeLineBlock[],
  scenarioId: string
) {
  if (!timeLineBlocks.length) return;
  for (const timeLineBlock of timeLineBlocks) {
    for (const action of timeLineBlock.actions) {
      const actionType = action.entityType;
      if (isSpecialEntityType(actionType)) {
        if (entity[actionType][scenarioId]) {
          entity[actionType][scenarioId].push(action.entityId);
        } else {
          entity[actionType][scenarioId] = [action.entityId];
        }
      }
    }
  }
}

export default useScenarioLogicEntity;
