import React from 'react';

const Layouts = ({ color = '#5FA2DD', x = '16', y = '16' }) => {
  return (
    <svg width={x} height={y} viewBox={`0 0 ${x} ${y}`}>
      <g stroke="null" id="layouts">
        <rect
          fill={color}
          stroke="null"
          id="l1"
          height="7.07726"
          width="7.39997"
          y="0.25316"
          x="8.34687"
        />
        <rect
          fill={color}
          stroke="null"
          id="l2"
          height="7.72268"
          width="7.39997"
          y="8.02416"
          x="8.34687"
        />
        <rect
          fill={color}
          stroke="null"
          id="l3"
          height="15.49369"
          width="7.39997"
          y="0.25316"
          x="0.25316"
        />
      </g>
    </svg>
  );
};

export default Layouts;
