export default function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
}

// Преобразовывает объект в http строку параметров
export function objToQueryString(obj) {
  return (
    '?' +
    Object.keys(obj)
      .map(function(key) {
        return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
      })
      .join('&')
  );
}
