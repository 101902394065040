import React from 'react';

const Notification = ({ color = '#5FA2DD', x = '16', y = '16' }) => {
  return (
    <svg width={x} height={y} viewBox={`0 0 ${x} ${y}`}>
      <g stroke="null" id="svg_5">
        <path
          fill={color}
          stroke="null"
          id="svg_1"
          d="m13.502019,10.000725l0,-2.301659a5.569663,5.569663 0 0 0 -3.296116,-5.095275l0,-0.19854a2.206781,2.206781 0 1 0 -4.411805,0l0,0.209082a5.569663,5.569663 0 0 0 -3.296116,5.095275l0,2.301659a2.045138,2.045138 0 0 0 0.690498,3.9708l2.252463,0a2.654814,2.654814 0 0 0 2.538852,1.881738l0.038654,0a2.654814,2.654814 0 0 0 2.538852,-1.89228l2.25422,0a2.045138,2.045138 0 0 0 0.690498,-3.9708zm-5.502897,-8.40369a0.799431,0.799431 0 0 1 0.771319,0.597377a5.364095,5.364095 0 0 0 -1.542638,0a0.799431,0.799431 0 0 1 0.771319,-0.597377zm0,1.948503a4.130687,4.130687 0 0 1 4.097304,4.153527l0,2.178669l-8.194608,0l0,-2.178669a4.130687,4.130687 0 0 1 4.097304,-4.155284l0,0.001757zm0.019327,10.91443l-0.038654,0a1.242193,1.242193 0 0 1 -0.985672,-0.488444l2.009998,0a1.242193,1.242193 0 0 1 -0.985672,0.486687l0,0.001757zm4.793072,-1.89228l-9.624798,0a0.641302,0.641302 0 0 1 0,-1.282604l9.624798,0a0.641302,0.641302 0 1 1 0,1.282604z"
        />
        <path
          fill={color}
          stroke="null"
          id="svg_2"
          d="m10.810308,9.608916a0.702797,0.702797 0 0 0 0.702797,-0.702797l0,-1.458303a0.702797,0.702797 0 0 0 -1.405593,0l0,1.458303a0.702797,0.702797 0 0 0 0.702797,0.702797z"
        />
      </g>
    </svg>
  );
};

export default Notification;
