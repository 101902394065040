import { LOCAL_STORAGE } from 'constants/keys.js';
import message from 'components/message.js';
import i18next from "i18next";

export const localStorage = {
  /**
   *
   * @param key - ключ записываемых данных
   * @param value - данные
   * @param isSecondAttempt - используется для рекурсии
   */
  setItem: function(key, value, isSecondAttempt) {
    try {
      window.localStorage.setItem(key, value);
    } catch (e) {
      //Если повторная запись так же не удалась, выдаем пользователю ошибку
      if (isSecondAttempt) {
        message.error(i18next.t('errors.saveToBrowserMemory'));
      } else {
        //Если произошла ошибка то очищаем данные, которыми можно пожертвовать и пробуем записать снова
        clearUnimportantStorageData(
          () => {
            window.localStorage.setItem(key, value, true);
          },
          e => {
            message.error(i18next.t('errors.saveToBrowserMemory'));
          }
        );
      }
    }
  },
  getItem: function(key) {
    return window.localStorage.getItem(key);
  },
  keys: LOCAL_STORAGE
};

export const clearUnimportantStorageData = function(onSuccess, onError) {
  const importantKeys = [
    LOCAL_STORAGE.CURRENT_USER,
    LOCAL_STORAGE.MUTED,
    LOCAL_STORAGE.REDUX_TAB_SYNC
  ];

  try {
    for (let i = 0; i < window.localStorage.length; ) {
      const key = window.localStorage.key(i);
      if (!importantKeys.includes(key)) {
        window.localStorage.removeItem(key);
      } else i++;
    }
    if (onSuccess) onSuccess();
  } catch (e) {
    if (onError) onError(e);
  }
};
