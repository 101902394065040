import { takeEvery } from 'redux-saga/effects';
import message from 'components/message';

import {
  PROJECTS_LOAD,
  PROJECT_CREATE,
  PROJECT_DELETE,
  PROJECT_UPDATE,
  PROJECT_LOAD_CURRENT,
  PROJECT_LOAD_ACTIVE,
  PROJECT_UPDATE_STATUS,
  PROJECT_SYNC_STATUS,
  PROJECT_IMPORT,
  PROJECT_EXPORT,
  PROJECT_VALIDATE,
  PROJECT_VALIDATE_MESSAGE_LOAD
} from 'constants/actionTypes';

import { fetch, request, dispatchSuccess, dispatchFail } from 'helpers/request';
import fileSaver from 'file-saver';
import i18next from 'i18next';

function* loadProjects() {
  try {
    const response = yield fetch('/projects/');

    yield dispatchSuccess(PROJECTS_LOAD, response);
  } catch (error) {
    yield dispatchFail(PROJECTS_LOAD, error);
  }
}

function* createProject(action) {
  try {
    const newProject = yield request('/projects/', 'POST', action.payload);
    yield dispatchSuccess(PROJECT_CREATE, newProject);
    message('success', i18next.t('messages.projectCreated'));
  } catch (error) {
    yield dispatchFail(PROJECT_CREATE, error);
  }
}

function* updateProject(action) {
  const updatedProject = action.payload;
  try {
    const response = yield request(`/projects/${updatedProject.id}`, 'PUT', updatedProject);

    yield dispatchSuccess(PROJECT_UPDATE, response);
    message('success', i18next.t('messages.projectUpdated'));
  } catch (error) {
    yield dispatchFail(PROJECT_UPDATE, error.message);
  }
}

function* deleteProject(action) {
  try {
    const response = yield request(`/projects/${action.payload}`, 'DELETE');
    if (response) {
      yield dispatchSuccess(PROJECT_DELETE, action.payload);
      message('success', i18next.t('messages.projectDeleted'));
    }
  } catch (error) {
    yield dispatchFail(PROJECT_DELETE, error);
  }
}

function* loadActiveProject(action) {
  try {
    const response = yield fetch(`/projects/active?canBeMissing=${action.payload}`);

    yield dispatchSuccess(PROJECT_LOAD_ACTIVE, {
      ...response,
      activeProject: response.activeProject || {},
      subsystems: response.subsystems || [],
      indicatorGroups: response.indicatorPanelGroups || [],
      indicatorPanels: response.indicatorPanels || [],
      countdowns: response.countdowns || {}
    });
  } catch (error) {
    yield dispatchFail(PROJECT_LOAD_ACTIVE, error.message);
  }
}

function* updateProjectStatus(action) {
  const { projectId, statusName } = action.payload;
  try {
    const response = yield request(`/projects/${projectId}?status=${statusName}`, 'PUT');

    yield dispatchSuccess(PROJECT_UPDATE_STATUS, {
      ...response,
      activeProject: response.activeProject || {},
      subsystems: response.subsystems || [],
      indicatorGroups: response.indicatorPanelGroups,
      indicatorPanels: response.indicatorPanels
    });
  } catch (error) {
    yield dispatchFail(PROJECT_UPDATE_STATUS, error.message);
  }
}

function* importProject({ type, payload }) {
  const { importedFile, version, fileName } = payload;
  try {
    let response = null;
    if (version === 'NT') {
      response = yield request(`/projects/importNt`, 'POST', importedFile);
    } else if (version === 'NG') {
      response = yield request(`/projects/importNg?project_name=${fileName}`, 'POST', importedFile);
    }else if (version === 'NTCompressed') {
      response = yield request(`/projects/importCompressed`, 'POST', importedFile, {
        'Content-Type': 'application/octet-stream;charset=UTF-8'
      });
    }
    yield dispatchSuccess(type, response);
    message('success', i18next.t('messages.projectImported'));
  } catch (error) {
    yield dispatchFail(type, error);
  }
}

function* projectExport(action) {
  const projectId = action.payload;
  try {
    const { content, filename } = yield request(`/projects/${projectId}/export`, 'POST');
    fileSaver.saveAs(content, filename + '.fsnt');
    yield dispatchSuccess(PROJECT_EXPORT, filename);
    message('success', i18next.t('messages.projectExported'));
  } catch (error) {
    yield dispatchFail(PROJECT_EXPORT, error);
  }
}

/**
 * Получение проекта
 * @param {string} actionType - actionType, с которым должна завершиться обработка запроса
 * @param {boolean|undefined} sync - Флаг означающий, что вызов был выполнен для синхронизации с другими вкладками браузера
 */
function loadProject(actionType, sync) {
  return function*({ type, payload }) {
    const projectId = payload;
    try {
      const response = yield fetch(`/projects/${projectId}/`);

      yield dispatchSuccess(actionType, {
        ...response,
        activeProject: response.activeProject || {},
        subsystems: response.subsystems || [],
        indicatorGroups: response.indicatorPanelGroups,
        indicatorPanels: response.indicatorPanels,
        sync
      });
    } catch (error) {
      yield dispatchFail(actionType, error.message);
    }
  };
}

function* validateProject(action) {
  const { projectId, validate } = action.payload;
  try {
    const response = yield request(`/projects/${projectId}?validate=${validate}`, 'PUT');
    yield dispatchSuccess(PROJECT_VALIDATE, response);
  } catch (error) {
    yield dispatchFail(PROJECT_VALIDATE, error.message);
  }
}

function* loadProjectValidateMessage(action) {
  const { pageSize, pageNo, projectId, isNeedLoadImportValidateMessages } = action.payload;

  try {
    const countValidateMessages = yield fetch(
      `/projects/${projectId}/validate_messages/count?importMessage=false`
    );
    const validateMessages = yield fetch(
      `/projects/${projectId}/validate_messages?size=${pageSize}&page=${pageNo}&sort=level&importMessage=false`
    );

    if (isNeedLoadImportValidateMessages) {
      const importValidateMessages = yield fetch(
        `/projects/${projectId}/validate_messages?size=${pageSize}&page=${pageNo}&sort=level&importMessage=true`
      );

      const countImportValidateMessages = yield fetch(
        `/projects/${projectId}/validate_messages/count?importMessage=true`
      );
      yield dispatchSuccess(PROJECT_VALIDATE_MESSAGE_LOAD, {
        importValidateMessages: importValidateMessages,
        validateMessages: validateMessages,
        totalImportValidateMessagesCount: countImportValidateMessages,
        totalValidateMessagesCount: countValidateMessages,
        projectId: projectId
      });
    } else {
      yield dispatchSuccess(PROJECT_VALIDATE_MESSAGE_LOAD, {
        importValidateMessages: [],
        validateMessages: validateMessages,
        totalValidateMessagesCount: countValidateMessages,
        totalImportValidateMessagesCount: 0,
        projectId: projectId
      });
    }
  } catch (error) {
    yield dispatchFail(PROJECT_VALIDATE_MESSAGE_LOAD, error.message);
  }
}

export default function* projects() {
  yield takeEvery(PROJECTS_LOAD, loadProjects);
  yield takeEvery(PROJECT_CREATE, createProject);
  yield takeEvery(PROJECT_DELETE, deleteProject);
  yield takeEvery(PROJECT_UPDATE, updateProject);
  yield takeEvery(PROJECT_LOAD_CURRENT, loadProject(PROJECT_LOAD_CURRENT));
  yield takeEvery(PROJECT_LOAD_ACTIVE, loadActiveProject);
  yield takeEvery(PROJECT_UPDATE_STATUS, updateProjectStatus);
  yield takeEvery(PROJECT_IMPORT, importProject);
  yield takeEvery(PROJECT_EXPORT, projectExport);
  yield takeEvery(PROJECT_SYNC_STATUS, loadProject(PROJECT_UPDATE_STATUS, true));
  yield takeEvery(PROJECT_VALIDATE, validateProject);
  yield takeEvery(PROJECT_VALIDATE_MESSAGE_LOAD, loadProjectValidateMessage);
}
