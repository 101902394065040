import React from 'react';
import Modal from 'containers/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm, Form, Field, reset, formValueSelector } from 'redux-form';
import { Button } from 'antd';
import { modalClose } from 'actions/modals';
import { Input, FormItem } from 'components';
import { performScenarioAction } from 'actions/scenarios';
import { setCurrentScenario } from 'actions/CPIU';
import {
  changeIndicatorDevicesPollingState,
  changeIndicatorRegionsPollingState,
  changeIndicatorRegionsGuard,
  performIndicatorScenarioAction,
  performIndicatorDeviceAction
} from 'actions/indicators';
import i18next from 'i18next';

export const PASSWORD_FORM_NAME = 'PASSWORD_FORM_NAME';
const selector = formValueSelector(PASSWORD_FORM_NAME);

const tailLayout = {
  wrapperCol: { offset: 10, span: 24 }
};

const Fields = {
  PASSWORD: 'password'
};

const PasswordModalForm = ({ modalName, handleSubmit }) => {
  const dispatch = useDispatch();
  const password = useSelector(state => selector(state, Fields.PASSWORD));
  const stateForm = useSelector(state => state.form[PASSWORD_FORM_NAME]?.values || null);
  const onCancel = () => {
    dispatch(modalClose(modalName));
    dispatch(reset(modalName));
  };
  const onSubmit = () => {
    if (!stateForm) {
      onCancel();
      return;
    }
    switch (stateForm.actionType) {
      case 'scenarioControl': {
        const { actionId, scenarioNo, activeProjectId } = stateForm;
        dispatch(performScenarioAction(activeProjectId, scenarioNo, actionId, password));
        onCancel();
        break;
      }
      case 'sentScenarioToCPIU': {
        const { currentScenarioId } = stateForm;
        dispatch(setCurrentScenario(currentScenarioId, password));
        onCancel();
        break;
      }
      case 'performIndicatorDeviceAction': {
        const { actionParams = [] } = stateForm;
        dispatch(performIndicatorDeviceAction(...actionParams, password));
        onCancel();
        break;
      }
      case 'performIndicatorScenarioAction': {
        const { actionParams = [] } = stateForm;
        dispatch(performIndicatorScenarioAction(...actionParams, password));
        onCancel();
        break;
      }
      case 'changeIndicatorRegionsPollingState': {
        const { actionParams = [] } = stateForm;
        dispatch(changeIndicatorRegionsPollingState(...actionParams, password));
        onCancel();
        break;
      }
      case 'changeIndicatorRegionsGuard': {
        const { actionParams = [] } = stateForm;
        dispatch(changeIndicatorRegionsGuard(...actionParams, password));
        onCancel();
        break;
      }
      case 'changeIndicatorDevicesPollingState': {
        const { actionParams = [] } = stateForm;
        dispatch(changeIndicatorDevicesPollingState(...actionParams, password));
        onCancel();
        break;
      }
      default: {
        onCancel();
      }
    }
  };

  return (
    <Modal
      name={modalName}
      title={i18next.t('messages.enterPassword')}
      width={300}
      onClose={() => dispatch(reset(modalName))}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormItem label={i18next.t('password')} key={'password'} required={true}>
          <Field name="password" component={Input.Password} />
        </FormItem>
        <FormItem {...tailLayout} style={{ marginBottom: '0px' }}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!password?.length}
            style={{ margin: '5px' }}
          >
            {i18next.t('buttons.ok')}
          </Button>
          <Button type="primary" style={{ margin: '5px' }} onClick={onCancel}>
            {i18next.t('buttons.cancel')}
          </Button>
        </FormItem>
      </Form>
    </Modal>
  );
};

const PasswordModal = reduxForm({ form: PASSWORD_FORM_NAME })(PasswordModalForm);

export default PasswordModal;
