import React from 'react';

const Fire = ({ color = "#FFF" }) => {
  return (
    <g>
      <g transform="translate(788 738)">
        <mask id="a"><path d="M-866-1062H251v414H-866v-414z" fill={color} /></mask>
        <g mask="url(#a)"><path d="M-866-1062H251v414H-866v-414z" fill={color} opacity="0" /><use xlinkHref="#fire" transform="translate(-780.049 -733.491)" fill="#F00" /></g>
      </g>
      <defs><path
        id="fire"
        d="M2.793 16.855c.165.087.339-.102.238-.26-.548-.852-1.067-2.287-.24-4.135C4.169 9.38 5.008 7.783 5.008 7.783s.446 1.863 1.655 3.519c1.164 1.593 1.801 3.598.774 5.267-.096.157.074.342.238.258 1.271-.65 2.697-1.955 2.858-4.55.06-.794-.03-1.904-.475-3.305-.572-1.777-1.275-2.605-1.682-2.961a.182.182 0 0 0-.3.149c.118 1.916-.603 2.403-1.013 1.307-.164-.438-.26-1.195-.26-2.117 0-1.535-.445-3.115-1.427-4.4a4.983 4.983 0 0 0-.897-.912.182.182 0 0 0-.293.158c.076 1.04.007 4.023-2.609 7.586C-.795 11.087.124 13.625.45 14.312c.624 1.318 1.494 2.091 2.343 2.543z" /></defs>
    </g>
  )
}

export default Fire;