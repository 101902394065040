import Stomp from 'stompjs';
import message from 'components/message';
import { getCurrentUser } from 'helpers/user';

import {
  SOCKET_CLIENT_START,
  SOCKET_CLIENT_RESTART,
  SOCKET_CLIENT_STOP,
  ACTIVE_DEVICE_UPDATED,
  ACTIVE_DEVICES_UPDATED,
  ACTIVE_REGION_UPDATED,
  ACTIVE_REGIONS_UPDATED,
  ACTIVE_SCENARIO_UPDATED,
  ACTIVE_SCENARIOS_UPDATED,
  SUBSYSTEM_STATUS_UPDATED,
  PROJECT_ACTIVE_STATUS_UPDATED,
  COUNTDOWNS_UPDATE,
  UPDATED_ENTITIES,
  DEVICE_SHAPE_LIBRARY_ADD,
  DEVICE_SHAPE_LIBRARY_UPDATE,
  DEVICE_SHAPE_LIBRARY_REMOVE,
  DEVICE_SHAPE_ADD,
  DEVICE_SHAPE_UPDATE,
  DEVICE_SHAPE_REMOVE,
  isLoaded,
  SKUD_ENTITIES,
  getAddActionType,
  getUpdateActionType,
  getRemoveActionType,
  DEVICE_COMPARISON_CONTROL_ENTITIES,
  ACCESS_KEY_VALUE_READ,
  LICENSE_UPDATE_PERMISSIONS,
  SCENARIO_PERFORM_COMPUTER_ACTION,
  ISSUES_CREATED,
  ISSUES_UPDATED,
  ISSUES_DELETED,
  PROJECT_UPDATE,
  DEVICES_UPDATED,
  SCENARIOS_UPDATED,
  PROJECTS_UPDATE,
  LOG_VIEW_ADD,
  LOG_VIEW_UPDATE,
  LOG_VIEW_DELETE
} from 'constants/actionTypes';
import i18next from 'i18next';

let stompClient = null;

const socketTypes = {
  treeItemActiveDeviceView: ACTIVE_DEVICE_UPDATED,
  treeItemActiveDeviceViews: ACTIVE_DEVICES_UPDATED,
  aggregatedActiveRegionView: ACTIVE_REGION_UPDATED,
  aggregatedActiveRegionViews: ACTIVE_REGIONS_UPDATED,
  aggregatedActiveScenarioView: ACTIVE_SCENARIO_UPDATED,
  activeScenarioViews: ACTIVE_SCENARIOS_UPDATED,
  subsystemView: SUBSYSTEM_STATUS_UPDATED,
  activeProject: PROJECT_ACTIVE_STATUS_UPDATED,
  countdowns: COUNTDOWNS_UPDATE,
  updatedEntities: UPDATED_ENTITIES,
  controlDeviceEntitiesComparision: DEVICE_COMPARISON_CONTROL_ENTITIES,
  accessKeyValue: ACCESS_KEY_VALUE_READ,
  licenseRestrictions: LICENSE_UPDATE_PERMISSIONS,
  scenarioComputerAction: SCENARIO_PERFORM_COMPUTER_ACTION,
  project: isLoaded(PROJECT_UPDATE, true),
  projects: PROJECTS_UPDATE,
  updatedDevices: DEVICES_UPDATED,
  updatedScenarios: SCENARIOS_UPDATED
};

/* Маршрутизация содержимого updatedEntities на частные экшены */
const updatedEntitiesRoutes = {
  deviceShapeLibraries: {
    created: isLoaded(DEVICE_SHAPE_LIBRARY_ADD, true),
    updated: isLoaded(DEVICE_SHAPE_LIBRARY_UPDATE, true),
    deletedIds: isLoaded(DEVICE_SHAPE_LIBRARY_REMOVE, true)
  },
  deviceShapes: {
    created: isLoaded(DEVICE_SHAPE_ADD, true),
    updated: isLoaded(DEVICE_SHAPE_UPDATE, true),
    deletedIds: isLoaded(DEVICE_SHAPE_REMOVE, true)
  },
  logViews: {
    created: isLoaded(LOG_VIEW_ADD, true),
    updated: isLoaded(LOG_VIEW_UPDATE, true),
    deletedIds: isLoaded(LOG_VIEW_DELETE, true)
  },
  issues: {
    created: ISSUES_CREATED,
    updated: ISSUES_UPDATED,
    deletedIds: ISSUES_DELETED
  }
};
Object.keys(SKUD_ENTITIES).forEach(entityName => {
  updatedEntitiesRoutes[entityName] = {};
  updatedEntitiesRoutes[entityName].created = isLoaded(getAddActionType(entityName), true);
  updatedEntitiesRoutes[entityName].updated = isLoaded(getUpdateActionType(entityName), true);
  updatedEntitiesRoutes[entityName].deleted = isLoaded(getRemoveActionType(entityName), true);
});

const onMessage = (store, response) => {
  let data = JSON.parse(response.body);
  if (socketTypes[data.contentType]) {
    const action = { type: socketTypes[data.contentType], payload: data.content };
    switch (action.type) {
      case UPDATED_ENTITIES: {
        Object.keys(action.payload).forEach(updatedEntitiesKey => {
          if (updatedEntitiesRoutes[updatedEntitiesKey]) {
            Object.keys(updatedEntitiesRoutes[updatedEntitiesKey]).forEach(actionTypeKey => {
              if (action.payload[updatedEntitiesKey][actionTypeKey]) {
                const payload = action.payload[updatedEntitiesKey][actionTypeKey];
                if (
                  (Array.isArray(payload) && payload.length > 0) ||
                  Object.keys(payload).length > 0
                ) {
                  store.dispatch({
                    type: updatedEntitiesRoutes[updatedEntitiesKey][actionTypeKey],
                    payload
                  });
                }
              }
            });
          } else if (
            action.payload[updatedEntitiesKey] &&
            Object.keys(action.payload[updatedEntitiesKey]).length
          ) {
            const payload = {};
            payload[updatedEntitiesKey] = action.payload[updatedEntitiesKey];
            store.dispatch({
              type: UPDATED_ENTITIES,
              payload,
              currentUser: getCurrentUser(store.getState())
            });
          }
        });
        break;
      }
      default:
        store.dispatch(action);
    }
  } else console.error('Unknown content type received by WebSockets. Data: ', data);
  response.ack();
};

const startSocketClient = store => {
  let socketStarted = false;
  if (stompClient != null) return;
  const apiUrl = process.env.REACT_APP_API_URL;
  const socketsApi =
    (process.env.REACT_APP_SECURITY_ON === 'true' ? 'wss' : 'ws') +
    '://' +
    (apiUrl ? apiUrl : `${window.location.hostname}:${window.location.port}`) +
    '/api/v1/ws';
  let socket = new WebSocket(socketsApi);
  stompClient = Stomp.over(socket);
  stompClient.debug = null;
  stompClient.heartbeat.outgoing = 10000;
  stompClient.heartbeat.incoming = 10000;

  stompClient.connect({}, frame => {
    socketStarted = true;
    stompClient.subscribe('/api/v1/ws', res => onMessage(store, res));
  });

  socket.onclose = function() {
    const time = Number(process.env.REACT_APP_NOTIFY_LOADING_DURATION) * 1000;
    if (window.location.pathname.includes('/login')) return;
    if (socketStarted) {
      store.dispatch({ type: SOCKET_CLIENT_RESTART }); //Переподключение при потере соединения
      message('warning', i18next.t('messages.reconnecting'));
    } else {
      message('loading', i18next.t('messages.restoringCommunicationWithServer'));
      setTimeout(() => {
        store.dispatch({ type: SOCKET_CLIENT_RESTART }); //Переподключение при потере соединения
      }, time);
    }
  };
};

function stopSocketClient(store) {
  if (stompClient != null) {
    stompClient.disconnect();
    stompClient = null;
  }
  store.dispatch({ type: isLoaded(SOCKET_CLIENT_STOP, true) });
}

const socketMiddleware = store => next => action => {
  switch (action.type) {
    case SOCKET_CLIENT_RESTART:
      stopSocketClient(store);
      startSocketClient(store);
      break;
    case SOCKET_CLIENT_START:
      startSocketClient(store);
      break;
    case SOCKET_CLIENT_STOP:
      stopSocketClient(store);
      break;
    default:
      break;
  }

  return next(action);
};

export default socketMiddleware;
