/**
 * Типы задач.
 * */
export const ISSUE_ACTIONS = {
  WRITE_CONFIG_TO_DEVICE: 'WRITE_CONFIG_TO_DEVICE',
  READ_CONFIG_FROM_DEVICE: 'READ_CONFIG_FROM_DEVICE',
  RESET_STATE_ON_DEVICE: 'RESET_STATE_ON_DEVICE',
  RESET_STATE_ON_ALL_DEVICES: 'RESET_STATE_ON_ALL_DEVICES',
  WRITE_CONTROL_DEVICE_DATABASE: 'WRITE_CONTROL_DEVICE_DATABASE',
  WRITE_ALL_CONTROL_DEVICES_DATABASE: 'WRITE_ALL_CONTROL_DEVICES_DATABASE',
  ENABLE_DEVICE_POLLING_STATE: 'ENABLE_DEVICE_POLLING_STATE',
  DISABLE_DEVICE_POLLING_STATE: 'DISABLE_DEVICE_POLLING_STATE',
  DISABLE_REGION_DEVICES_POLLING_STATE: 'DISABLE_REGION_DEVICES_POLLING_STATE',
  ENABLE_REGION_GUARD: 'ENABLE_REGION_GUARD',
  DISABLE_REGION_GUARD: 'DISABLE_REGION_GUARD',
  SET_CONTROL_DEVICE_TIME: 'SET_CONTROL_DEVICE_TIME',
  SET_CONTROL_DEVICE_USER_PASSWORD: 'SET_CONTROL_DEVICE_USER_PASSWORD',
  RESET_CONTROL_DEVICE: 'RESET_CONTROL_DEVICE',
  UPDATE_CONTROL_DEVICE_FIRMWARE: 'UPDATE_CONTROL_DEVICE_FIRMWARE',
  UPDATE_ALL_CONTROL_DEVICE_FIRMWARE: 'UPDATE_ALL_CONTROL_DEVICE_FIRMWARE',
  READ_EVENTS: 'READ_EVENTS',
  PERFORM_DEVICE_ACTION: 'PERFORM_DEVICE_ACTION',
  PERFORM_SCENARIO_ACTION: 'PERFORM_SCENARIO_ACTION',
  ENABLE_INDICATOR_REGIONS_GUARD: 'ENABLE_INDICATOR_REGIONS_GUARD',
  DISABLE_INDICATOR_REGIONS_GUARD: 'DISABLE_INDICATOR_REGIONS_GUARD',
  ENABLE_INDICATOR_REGIONS_POLLING_STATE: 'ENABLE_INDICATOR_REGIONS_POLLING_STATE',
  DISABLE_INDICATOR_REGIONS_POLLING_STATE: 'DISABLE_INDICATOR_REGIONS_POLLING_STATE',
  ENABLE_INDICATOR_DEVICES_POLLING_STATE: 'ENABLE_INDICATOR_DEVICES_POLLING_STATE',
  DISABLE_INDICATOR_DEVICES_POLLING_STATE: 'DISABLE_INDICATOR_DEVICES_POLLING_STATE',
  PERFORM_INDICATOR_SCENARIO_ACTION: 'PERFORM_INDICATOR_SCENARIO_ACTION',
  PERFORM_INDICATOR_DEVICE_ACTION: 'PERFORM_INDICATOR_DEVICE_ACTION',
  READ_CONTROL_DEVICE_DATABASE: 'READ_CONTROL_DEVICE_DATABASE',
  WRITE_SKUD_DATABASE: 'WRITE_SKUD_DATABASE',
  UPDATE_SKUD_DATABASE: 'UPDATE_SKUD_DATABASE',
  START_READ_ACCESS_KEY: 'START_READ_ACCESS_KEY',
  STOP_READ_ACCESS_KEY: 'STOP_READ_ACCESS_KEY'
};