import {
  PROJECT_UPDATE_STATUS,
  ACTIVE_DEVICE_UPDATED,
  ACTIVE_DEVICES_UPDATED,
  CURRENT_ENTITY_INFO_UPDATE,
  ACTIVE_REGION_UPDATED,
  ACTIVE_REGIONS_UPDATED,
  PLAN_SELECTED,
  isLoaded
} from 'constants/actionTypes';

/*
 * state = {
 *   entityType: 'REGION',
 *   entityId: <string>,
 *   subsystem: <SECURITY | FIRE | SKUD>,
 *   onGuard: <boolean>
 * }
 *
 * или
 *
 * state = {
 *   entityType: 'DEVICE',
 *   entityId: <string>,
 *   deviceProfileId: <string>,
 *   deviceCategory: <SENSOR | EXECUTIVE | CONTROL | VIRTUAL_CONTAINER>,
 *   statePolling: <boolean>
 * }
 *
 */
export default function currentEntityInfo(state = null, { type, payload }) {
  switch (type) {
    case ACTIVE_REGION_UPDATED: {
      const updatedRegion = payload;
      if (state) {
        if (state.entityType === 'REGION' && state.entityId === updatedRegion.id) {
          return { ...state, onGuard: updatedRegion.onGuard };
        }
      }
      return state;
    }

    case ACTIVE_REGIONS_UPDATED: {
      const updatedRegions = payload;
      if (state && state.entityType === 'REGION') {
        for (let i = 0; i < updatedRegions.length; ++i) {
          const updatedRegion = updatedRegions[i];
          if (state.entityId === updatedRegion.id) {
            return { ...state, onGuard: updatedRegion.onGuard };
          }
        }
      }
      return state;
    }

    case ACTIVE_DEVICE_UPDATED: {
      const updatedDevice = payload;
      if (state) {
        if (state.entityType === 'DEVICE' && state.entityId === updatedDevice.id) {
          return { ...state, statePolling: updatedDevice.statePolling };
        }
      }
      return state;
    }

    case ACTIVE_DEVICES_UPDATED: {
      const updatedDevices = payload;
      if (state && state.entityType === 'DEVICE') {
        for (let i = 0; i < updatedDevices.length; ++i) {
          const updatedDevice = updatedDevices[i];
          if (state.entityId === updatedDevice.id) {
            return { ...state, statePolling: updatedDevice.statePolling };
          }
        }
      }
      return state;
    }

    case CURRENT_ENTITY_INFO_UPDATE: {
      const entityInfo = payload;
      if (entityInfo && entityInfo.entityId) {
        if (!state) return { ...entityInfo };
        if (state) {
          const { entityType, entityId } = state;
          if (entityType !== entityInfo.entityType || entityId !== entityInfo.entityId)
            return { ...entityInfo };
        }
        return state;
      } else {
        return null;
      }
    }
    case PLAN_SELECTED: {
      return null;
    }
    case isLoaded(PROJECT_UPDATE_STATUS, true): {
      return null;
    }
    default:
      return state;
  }
}
