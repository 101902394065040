import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Input, Form, Alert } from 'antd';
import { loginUser } from 'actions/login';
import StatusImg from '../../components/Device/StatusImg';
import styles from '../../components/Form/styles.module.css';

const layout = {
  labelCol: { span: 24 }
};

const buttonItemLayout = {
  wrapperCol: {
    span: 1,
    offset: 6
  }
};
class Login extends Component {
  handleSubmit = data => {
    const userData = {
      username: data.userName,
      password: data.password
    };
    this.props.dispatch(loginUser(userData));
  };

  render() {
    const { errorMessage } = this.props;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          right: '25%',
          position: 'absolute'
        }}
      >
        {errorMessage && (
          <Alert
            className={styles.error}
            style={{ marginBottom: 24 }}
            message={errorMessage}
            type="error"
            closeIcon={<StatusImg name={'error'} size={23} />}
            closable
          />
        )}
        <Form
          {...layout}
          style={{ width: 200 }}
          onFinish={this.handleSubmit}
          className="login-form"
        >
          <Form.Item
            label={i18next.t('userName')}
            name={'userName'}
            rules={[{ required: true, message: i18next.t('messages.enterLogin') }]}
          >
            <Input size="large" prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} />
          </Form.Item>
          <Form.Item
            label={i18next.t('password')}
            name={'password'}
            rules={[{ required: true, message: i18next.t('messages.enterPassword') }]}
          >
            <Input.Password
              size="large"
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
            />
          </Form.Item>
          <Form.Item {...buttonItemLayout}>
            <button className={styles.submit_button} type="submit">
              {i18next.t('buttons.signIn')}
            </button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    errorMessage: state.errors.loginUser
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
