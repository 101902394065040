import React, { Component } from 'react';
import { connect } from 'react-redux';
import { notification } from 'antd';

import { startScenarioTimer } from 'actions/scenarios';

class ScenarioComputerAction extends Component {

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { computerAction } = this.props;
    if (computerAction && computerAction !== prevProps.computerAction) {
      this.performComputerAction(computerAction);
    }
  }

  performComputerAction = computerAction => {
    setTimeout(() => {
      switch (computerAction.command) {
        case 'SHOW_MESSAGE': {
          const { message: notificationMessage, title } = computerAction;
          const args = {
            message: <b>{title}</b>,
            description: notificationMessage,
            duration: 0
          };
          notification.info(args);
          break;
        }
        case 'COUNTDOWN': {
          const { dispatch } = this.props;
          dispatch(startScenarioTimer(computerAction));
          break;
        }
        default:
          break;
      }
    }, computerAction.timeDelaySec * 1000);
  };

  render() {
    return null;
  }
}

const mapStateToProps = state => {
  return {
    computerAction: state.activeProject.scenarioComputerAction
  };
};

const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioComputerAction);
