import React, { PureComponent } from 'react';
import { CloseOutlined, SoundOutlined } from '@ant-design/icons';

const WRAPPER_STYLES = {
  display: 'flex',
  position: 'relative',
  width: 40,
  height: 35,
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer'
};
const MUTED_ICON_STYLES = {
  position: 'absolute',
  fontSize: 22,
  color: 'red'
};

const SOUND_MUTE_ICON_STYLES = {
  fontSize: 22,
  color: 'lightslategray'
};

export default class SoundButton extends PureComponent {
  render() {
    const { onClick, isMute, iconColor = '#fff' } = this.props;
    return (
      <div style={WRAPPER_STYLES} onClick={onClick}>
        <SoundOutlined
          style={isMute ? SOUND_MUTE_ICON_STYLES : { fontSize: 22, color: iconColor }}
        />
        {isMute ? <CloseOutlined style={MUTED_ICON_STYLES} /> : null}
      </div>
    );
  }
}
