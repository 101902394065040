import { combineReducers } from 'redux';

import {
  PROJECT_LOAD_CURRENT,
  PROJECT_LOAD_ACTIVE,
  PROJECT_UPDATE_STATUS,
  isLoaded
} from 'constants/actionTypes';

/**
 * state = groups: {
 *    <indicatorGroupId>: {
 *        id: <string>,
 *        ...
 *    }
 * }
 */
function groups(state = null, { type, payload }) {
  switch (type) {
    case isLoaded(PROJECT_LOAD_CURRENT, true): {
      const { project, indicatorGroups } = payload;
      if (project.status === 'ACTIVE') {
        const newState = {};
        indicatorGroups.forEach(indicatorGroup => {
          newState[indicatorGroup.id] = indicatorGroup;
        });
        return newState;
      } else {
        return state;
      }
    }
    case isLoaded(PROJECT_LOAD_ACTIVE, true): {
      const { indicatorGroups } = payload;
      const newState = {};
      if (indicatorGroups.length) {
        indicatorGroups.forEach(indicatorGroup => {
          newState[indicatorGroup.id] = indicatorGroup;
        });
      }
      return newState;
    }
    case isLoaded(PROJECT_UPDATE_STATUS, true): {
      const { project, indicatorGroups } = payload;
      if (project.status === 'ACTIVE') {
        const newState = {};
        indicatorGroups.forEach(indicatorGroup => {
          newState[indicatorGroup.id] = indicatorGroup;
        });
        return newState;
      } else {
        return {};
      }
    }
    default: {
      return state;
    }
  }
}

/**
 * state = panels: {
 *    <indicatorPanelId>: {
 *        id: <string>,
 *        ...
 *    }
 * }
 */
function panels(state = null, { type, payload }) {
  switch (type) {
    case isLoaded(PROJECT_LOAD_CURRENT, true): {
      const { project, indicatorPanels } = payload;
      if (project.status === 'ACTIVE') {
        const newState = {};
        indicatorPanels.forEach(indicatorPanel => {
          newState[indicatorPanel.id] = indicatorPanel;
        });
        return newState;
      } else {
        return state;
      }
    }
    case isLoaded(PROJECT_LOAD_ACTIVE, true): {
      const { indicatorPanels } = payload;
      const newState = {};
      if (indicatorPanels.length) {
        indicatorPanels.forEach(indicatorPanel => {
          newState[indicatorPanel.id] = indicatorPanel;
        });
      }
      return newState;
    }
    case isLoaded(PROJECT_UPDATE_STATUS, true): {
      const { project, indicatorPanels } = payload;
      if (project.status === 'ACTIVE') {
        const newState = {};
        indicatorPanels.forEach(indicatorPanel => {
          newState[indicatorPanel.id] = indicatorPanel;
        });
        return newState;
      } else {
        return {};
      }
    }
    default:
      return state;
  }
}

export default combineReducers({
  groups,
  panels
});
