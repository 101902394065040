import { SET_PRIORITY_MODE } from 'constants/actionTypes';

/**
 * Состояние функции перемещения на план с наиболее приоритетным событием.
 * @param payload:
 * true => вкл
 * false => выкл
 */
export default function priorityMode(
  state = null,
  { type, payload }: { type: string; payload: boolean }
) {
  switch (type) {
    case SET_PRIORITY_MODE: {
      return payload;
    }
    default: {
      return state;
    }
  }
}
