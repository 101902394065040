import { takeEvery } from 'redux-saga/effects';

import {
  MEDIAS_LOAD_ICONS,
  MEDIAS_LOAD_SVGS,
  MEDIAS_LOAD_SOUNDS,
  MEDIA_ADD,
  MEDIA_UPDATE,
  MEDIA_DELETE
} from 'constants/actionTypes';

import { fetch, dispatchSuccess, dispatchFail, request } from 'helpers/request';

function* loadIcons(action) {
  try {
    const response = yield fetch(`/medias/?type=ICON`);

    yield dispatchSuccess(MEDIAS_LOAD_ICONS, response);
  } catch (error) {
    yield dispatchFail(MEDIAS_LOAD_ICONS, error.message);
  }
}

function* loadSvgs(action) {
  try {
    const response = yield fetch(`/medias/?type=SVG_CONTENT`);

    yield dispatchSuccess(MEDIAS_LOAD_SVGS, response);
  } catch (error) {
    yield dispatchFail(MEDIAS_LOAD_SVGS, error.message);
  }
}

function* loadSounds({ type, payload }) {
  try {
    const response = yield fetch(`/medias/?type=SOUND`);

    yield dispatchSuccess(type, response);
  } catch (error) {
    yield dispatchFail(type, error.message);
  }
}

function* addMedia({ type, payload }) {
  const newMedia = { ...payload };
  try {
    yield request(`/medias`, 'POST', newMedia);
  } catch (error) {
    yield dispatchFail(type, error.message);
  }
}

function* updateMedia({ type, payload }) {
  const updatedMedia = { ...payload };
  try {
    yield request(`/medias/${updatedMedia.id}`, 'PUT', updatedMedia);
  } catch (error) {
    yield dispatchFail(type, error.message);
  }
}

function* deleteMedia({ type, payload }) {
  const mediaId = payload;
  try {
    yield request(`/medias/${mediaId}`, 'DELETE');
  } catch (error) {
    yield dispatchFail(type, error.message);
  }
}

export default function* mediasSagas() {
  yield takeEvery(MEDIAS_LOAD_ICONS, loadIcons);
  yield takeEvery(MEDIAS_LOAD_SVGS, loadSvgs);
  yield takeEvery(MEDIAS_LOAD_SOUNDS, loadSounds);
  yield takeEvery(MEDIA_ADD, addMedia);
  yield takeEvery(MEDIA_UPDATE, updateMedia);
  yield takeEvery(MEDIA_DELETE, deleteMedia);
}
