import { takeEvery } from 'redux-saga/effects';

import {
  SOUND_NOTIFICATIONS_LOAD,
  SOUND_NOTIFICATION_ADD,
  SOUND_NOTIFICATION_UPDATE
} from 'constants/actionTypes';

import { fetch, dispatchSuccess, dispatchFail, request } from 'helpers/request';

function* loadSoundNotifications({ type, payload }) {
  try {
    const response = yield fetch(`/sound_notifications`);

    yield dispatchSuccess(type, response);
  } catch (error) {
    yield dispatchFail(type, error.message);
  }
}

function* addSoundNotification({ type, payload }) {
  const newSoundNotification = { ...payload };
  try {
    yield request(`/sound_notifications`, 'POST', newSoundNotification);
  } catch (error) {
    yield dispatchFail(type, error.message);
  }
}

function* updateSoundNotification({ type, payload }) {
  const updatedSoundNotification = { ...payload };
  try {
    yield request(
      `/sound_notifications/${updatedSoundNotification.id}`,
      'PUT',
      updatedSoundNotification
    );
  } catch (error) {
    yield dispatchFail(type, error.message);
  }
}

export default function* soundNotificationsSagas() {
  yield takeEvery(SOUND_NOTIFICATIONS_LOAD, loadSoundNotifications);
  yield takeEvery(SOUND_NOTIFICATION_ADD, addSoundNotification);
  yield takeEvery(SOUND_NOTIFICATION_UPDATE, updateSoundNotification);
}
