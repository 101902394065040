/**
 * Виртуальные состояния.
 * Все REST-методы, на которые ссылаются ниже описанные action-ы, описаны в тех. проекте:
 * (https://docs.google.com/document/d/19fDadv_CBaeP-RlnnSvsjpdx7BCqSeax4MlCHjeEW28/edit#heading=h.juw4erwqtpom).
 * Все входные параметры (payload), поступающие в sagas, совпадают с параметрами соответствующих REST-методов.
 * Все входные параметры (payload), поступающие в reducers, совпадают с ответом соответствующих REST-методов.
 */

/**
 * Загрузить все виртуальные состояния открытого для редактирования проекта
 * REST-метод: GET api/v1/projects/<projectId>/virtual_states
 */
export const VIRTUAL_STATES_LOAD = 'VIRTUAL_STATES_LOAD';
/**
 * Создать новое виртуальное состояние в открытом для редактирования проекте
 * REST-метод: POST api/v1/projects/<projectId>/virtual_states
 */
export const VIRTUAL_STATE_CREATE = 'VIRTUAL_STATE_CREATE';
/**
 * Обновить базовые параметры виртуального состояния
 * REST-метод: PUT api/v1/projects/<projectId>/virtual_states/<virtualStateId>
 */
export const VIRTUAL_STATE_UPDATE = 'VIRTUAL_STATE_UPDATE';
/**
 * Удалить виртуальное состояние
 * REST-метод: DELETE api/v1/projects/<projectId>/virtual_states/<virtualStateId>
 */
export const VIRTUAL_STATE_DELETE = 'VIRTUAL_STATE_DELETE';

export const VIRTUAL_STATES_DELETE = 'VIRTUAL_STATES_DELETE';

/**
 * Выбрать виртуальное состояние (локальный action).
 * Payload: virtualStateId: <string>.
 * Store: state.widgets.selectedVirtualStateIds.
 */
export const VIRTUAL_STATE_SELECT = 'VIRTUAL_STATE_SELECT';

export const VIRTUAL_STATE_PASTE = 'VIRTUAL_STATE_PASTE';
