import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import sagas from 'sagas';
import { routerMiddleware } from 'connected-react-router';

import socketMiddleware from 'middlewares/socketMiddleware';
import { storageMiddleware } from 'middlewares/storageMiddleware';

import createRootReducer from 'reducers';

const reduxDevtoolsOn = process.env.REACT_APP_REDUX_DEVTOOLS_ON === 'true';
const isDevToolsEnabled = reduxDevtoolsOn && window && window.__REDUX_DEVTOOLS_EXTENSION__;

export default function configureStore(history, initialState = {}) {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    createRootReducer(history),
    initialState,
    compose(
      applyMiddleware(
        routerMiddleware(history),
        socketMiddleware,
        sagaMiddleware,
        storageMiddleware()
      ),
      isDevToolsEnabled ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    )
  );

  sagaMiddleware.run(sagas);

  if (module.hot) {
    module.hot.accept('../reducers', () => {
      store.replaceReducer(createRootReducer(history));
    });
  }

  return store;
}
