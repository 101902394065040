import { createSelector } from 'reselect';

import {
  getCurrentProjectScenariosHash,
  getCurrentProjectDevicesHash
} from 'helpers/currentProject';
import { getActiveDevicesHash } from './activeProject';
import { EMPTY_OBJECT, EMPTY_ARRAY } from 'constants/common';
import memoizeOne from 'memoize-one';
import { Option } from 'components/Select';
import Device from 'components/Device/Device';
import DeviceImg from 'components/Device/DeviceImg';
import React from 'react';
import i18next from 'i18next';
import { FILTER_TYPES } from 'components/Filters';

export const ScenarioFilterNames = {
  SCENARIO_TYPE: 'Type',
  SCENARIO_PURPOSE: 'Purpose',
  DEVICES: 'Devices',
  SCENARIO_REGIONS: 'REGION',
  SCENARIO_DEVICES: 'DEVICE'
};

export function getAllScenarioFilters(component, customFilterNames, customScenarioTypes) {
  const filters = [];
  if (!customFilterNames || customFilterNames.includes(ScenarioFilterNames.SCENARIO_TYPE))
    filters.push({
      name: i18next.t('type'),
      key: ScenarioFilterNames.SCENARIO_TYPE,
      type: FILTER_TYPES.SELECT,
      getEntities: () => {
        const { scenarioDictionaries } = component.props;
        return customScenarioTypes || scenarioDictionaries.types || [];
      },
      check: (filter, node) => filter.active.includes(node.scenarioType),
      customOptions: types => {
        return types.map(type => (
          <Option key={type} value={type} label={type}>
            <span>{i18next.t(`scenarios.types.names.${type}`)}</span>
          </Option>
        ));
      }
    });
  if (!customFilterNames || customFilterNames.includes(ScenarioFilterNames.SCENARIO_PURPOSE))
    filters.push({
      name: i18next.t('purpose'),
      key: ScenarioFilterNames.SCENARIO_PURPOSE,
      type: FILTER_TYPES.SELECT,
      getEntities: () => {
        const { scenarioDictionaries } = component.props;
        return scenarioDictionaries.purposes ? scenarioDictionaries.purposes : [];
      },
      check: (filter, node) => filter.active.includes(node.scenarioPurpose),
      customOptions: purposes => {
        return purposes.map(purpose => (
          <Option key={purpose} value={purpose} label={purpose}>
            <span>{i18next.t(`scenarios.purposes.names.${purpose}`)}</span>
          </Option>
        ));
      }
    });
  if (!customFilterNames || customFilterNames.includes(ScenarioFilterNames.DEVICES))
    filters.push({
      name: i18next.t('controlDevice', { context: 'plural' }),
      key: ScenarioFilterNames.DEVICES,
      type: FILTER_TYPES.SELECT,
      getEntities: () => component.props.controlDevices,
      check: (filter, node) => {
        const deviceList = Object.values(component.props.devices) || [];
        const controlDeviceList = component.props.controlDevices;
        for (let id of filter.active) {
          const controlDev = controlDeviceList.find(dev => dev.id === id);
          const regExp = new RegExp('^' + controlDev.fullAddressPath, 'g');
          const devices = deviceList.filter(dev => regExp.test(dev.fullAddressPath));
          if (devices.find(dev => node.basicParams.controlDeviceId === dev.id)) return true;
        }
        return false;
      },
      customOptions: memoizeOne(entities => {
        return entities.map(dev => (
          <Option key={dev.id} value={dev.id} label={dev.name}>
            {dev.iconMedia ? (
              <Device>
                <DeviceImg size={15} name={dev.iconMedia.path} />
                <span>{dev.name}</span>
                <span> ({dev.fullAddressPath})</span>
              </Device>
            ) : null}
          </Option>
        ));
      })
    });
  return filters;
}

export const parseLogicText = memoizeOne(text => {
  return text.replace(/<[\w\d]+>/g, str => i18next.t('scenarios.conditions.' + str));
});

function replaceDeviceIds(sentence, devicesHash) {
  return sentence.replace(/#{(.*)}|@{(.*)}/g, (match, deviceIdToMiddle, deviceIdToFull) => {
    const device = devicesHash[deviceIdToMiddle || deviceIdToFull];
    if (device && deviceIdToMiddle) return device.middleAddressPath;
    if (device && deviceIdToFull) return device.fullAddressPath;
    return '';
  });
}

function replaceDeviceIdsFromTimeLineTreeAndAddKeys(treeItems, devicesHash, parentKey) {
  const newTreeItems = treeItems.map((item, index) => {
    return {
      ...item,
      key: `${parentKey}.${index}`,
      description: replaceDeviceIds(item.description, devicesHash)
    };
  });
  newTreeItems.forEach(item => {
    if (item.children && item.children.length)
      item.children = replaceDeviceIdsFromTimeLineTreeAndAddKeys(
        item.children,
        devicesHash,
        item.key
      );
  });
  return newTreeItems;
}

export function prepareScenarioToShow(scenario, devicesHash) {
  if (!scenario.advancedParams) {
    scenario.advancedParams = EMPTY_OBJECT;
    scenario.timeLineBlocksTree = EMPTY_ARRAY;
  }

  scenario.timeLineBlocksTree = replaceDeviceIdsFromTimeLineTreeAndAddKeys(
    scenario.timeLineBlocksTree,
    devicesHash,
    scenario.id
  );

  if (scenario.advancedParams.startLogicSentence || scenario.advancedParams.stopLogicSentence) {
    scenario.advancedParams = { ...scenario.advancedParams };
    if (scenario.advancedParams.startLogicSentence) {
      scenario.advancedParams.startLogicSentence = replaceDeviceIds(
        scenario.advancedParams.startLogicSentence,
        devicesHash
      );
      scenario.advancedParams.startLogicSentence = parseLogicText(
        scenario.advancedParams.startLogicSentence
      );
    }
    if (scenario.advancedParams.stopLogicSentence) {
      scenario.advancedParams.stopLogicSentence = replaceDeviceIds(
        scenario.advancedParams.stopLogicSentence,
        devicesHash
      );
      scenario.advancedParams.stopLogicSentence = parseLogicText(
        scenario.advancedParams.stopLogicSentence
      );
    }
  }
}

/**
 * Получить выбранный объект сценария
 */
export const getSelectedScenario = createSelector(
  ({ currentProjectId }) => currentProjectId,
  ({ widgets: { currentScenarioId } }) => currentScenarioId,
  ({ scenarios }) => scenarios,
  getCurrentProjectDevicesHash,
  (currentProjectId, currentScenarioId, scenarios, devicesHash) => {
    if (!currentScenarioId || !currentProjectId) return null;
    if (!scenarios[currentProjectId]) return null;
    if (!scenarios[currentProjectId][currentScenarioId]) return null;
    const scenario = { ...scenarios[currentProjectId][currentScenarioId] };
    prepareScenarioToShow(scenario, devicesHash);

    return scenario;
  }
);

export const getSelectedActiveScenario = createSelector(
  ({ widgets: { currentScenarioId } }) => currentScenarioId,
  ({ activeProject: { scenarios } }) => scenarios,
  getActiveDevicesHash,
  (currentScenarioId, scenarios, devicesHash) => {
    if (!currentScenarioId) return null;
    if (!scenarios || !Object.keys(scenarios)) return null;
    const scenario = { ...scenarios[currentScenarioId] };
    prepareScenarioToShow(scenario, devicesHash);

    return scenario;
  }
);

/**
 * Получить исполнительные сценарии
 * @param {String} projectId идентификатор проекта
 * @param {Object[]} projectScenarios список сценариев всех проектов
 * @returns {Object[]} список сценариев
 */
export const getExecutiveScenarios = createSelector(getCurrentProjectScenariosHash, scenariosHash =>
  Object.values(scenariosHash).filter(
    scenario => scenario.basicParams.enabled && scenario.scenarioPurpose === 'EXEC_BY_INVOKE'
  )
);

export function getTLBlocksInitialExpanding(selectedScenario) {
  const expandedRowKeys = [];
  if (selectedScenario.timeLineBlocksTree) {
    selectedScenario.timeLineBlocksTree.forEach((timeLineBlockL1, idx) => {
      expandedRowKeys.push(timeLineBlockL1.key);
      if (timeLineBlockL1.children) {
        timeLineBlockL1.children.forEach(timeLineBlockL2 => {
          expandedRowKeys.push(timeLineBlockL2.key);
        });
      }
    });
  }
  return expandedRowKeys;
}
