import { combineReducers } from 'redux';
import * as actionTypes from 'constants/actionTypes';

function errorReduxer(actionName) {
  return (state = null, action) => {
    switch (action.type) {
      case actionTypes.isLoaded(actionName, false): {
        return (' ' + action.payload).slice(1);
      }
      case actionName:
      case actionTypes.isLoaded(actionName, true): {
        return null;
      }
      default:
        return state;
    }
  };
}

function loadActiveProjectErrorReduxer(state = null, action) {
  switch (action.type) {
    case actionTypes.isLoaded(actionTypes.PROJECT_LOAD_ACTIVE, false): {
      return (' ' + action.payload).slice(1);
    }
    case actionTypes.PROJECT_UPDATE_STATUS:
    case actionTypes.isLoaded(actionTypes.PROJECT_LOAD_ACTIVE, true): {
      return null;
    }
    default:
      return state;
  }
}

function getFullDevice(state = null, action) {
  switch (action.type) {
    case actionTypes.isLoaded(actionTypes.DEVICE_GET_FULL, false): {
      return (' ' + action.payload).slice(1);
    }

    case actionTypes.DEVICE_UPDATE_CURRENT:
    case actionTypes.isLoaded(actionTypes.DEVICE_GET_FULL, true): {
      return null;
    }

    default:
      return state;
  }
}

function getFullScenario(state = null, action) {
  switch (action.type) {
    case actionTypes.isLoaded(actionTypes.SCENARIO_GET_FULL, false): {
      return '' + action.payload;
    }

    case actionTypes.SCENARIO_SELECT:
    case actionTypes.isLoaded(actionTypes.SCENARIO_GET_FULL, true): {
      return null;
    }

    default:
      return state;
  }
}

export default combineReducers({
  // ADD
  addScenarioTLBlock: errorReduxer(actionTypes.SCENARIO_ADD_TL_BLOCK),
  // CHANGE
  changeRegionGuardStatus: errorReduxer(actionTypes.REGION_CHANGE_GUARD_STATUS),
  // CHECK
  checkLoginConfirmation: errorReduxer(actionTypes.LOGIN_CHECK_CONFIRMATION),
  // CREATE
  createVirtualState: errorReduxer(actionTypes.VIRTUAL_STATE_CREATE),
  createVirtualCounter: errorReduxer(actionTypes.VIRTUAL_COUNTER_CREATE),
  createDevice: errorReduxer(actionTypes.DEVICE_CREATE),
  createDeviceCopies: errorReduxer(actionTypes.DEVICES_COPY_AND_PASTE),
  createRegion: errorReduxer(actionTypes.REGION_CREATE),
  createRegionAndConnectDevice: errorReduxer(actionTypes.REGION_CREATE_AND_CONNECT_DEVICE),
  createScenario: errorReduxer(actionTypes.SCENARIO_CREATE),
  createProject: errorReduxer(actionTypes.PROJECT_CREATE),
  createPlan: errorReduxer(actionTypes.PLAN_CREATE),
  createPlanGroup: errorReduxer(actionTypes.PLAN_GROUP_CREATE),
  createControlPanelUser: errorReduxer(actionTypes.CREATE_CONTROL_PANEL_USER),
  // DELETE
  deleteDevice: errorReduxer(actionTypes.DEVICE_DELETE),
  deleteScenario: errorReduxer(actionTypes.SCENARIO_DELETE),
  deleteVirtualState: errorReduxer(actionTypes.VIRTUAL_STATE_DELETE),
  // DOWNLOAD
  downloadDeviceConfig: errorReduxer(actionTypes.DEVICE_DOWNLOAD_CONFIG),
  // GET
  getDeviceProfileViews: errorReduxer(actionTypes.DEVICE_GET_PROFILE_VIEWS),
  getMonitorableValueProfiles: errorReduxer(actionTypes.MONITORABLE_VALUES_GET_PROFILES),
  getMonitorableValuesHistory: errorReduxer(actionTypes.MONITORABLE_VALUES_GET_DEVICE_HISTORY),
  getFullDevice,
  getFullScenario,
  // IMPORT
  importConfig: errorReduxer(actionTypes.DEVICE_IMPORT_CONTOL_CONFIG),
  // LOAD
  loadCurrentProject: errorReduxer(actionTypes.PROJECT_LOAD_CURRENT),
  loadActiveProject: loadActiveProjectErrorReduxer,
  loadActiveDevices: errorReduxer(actionTypes.DEVICES_LOAD_ACTIVE),
  loadScenarioConstants: errorReduxer(actionTypes.SCENARIO_CONSTANTS_LOAD),
  loadScenarios: errorReduxer(actionTypes.SCENARIOS_LOAD),
  loadRegions: errorReduxer(actionTypes.REGIONS_LOAD),
  loadLogEvents: errorReduxer(actionTypes.EVENTS_LOAD_LOG),
  loadArchiveEvents: errorReduxer(actionTypes.EVENTS_LOAD_ARCHIVE),
  loadEventTypeViews: errorReduxer(actionTypes.EVENTS_LOAD_TYPE_VIEWS),
  loadActiveRegions: errorReduxer(actionTypes.REGIONS_LOAD_ACTIVE),
  loadUsers: errorReduxer(actionTypes.USERS_LOAD),
  loadRegionConstants: errorReduxer(actionTypes.REGION_CONSTANTS_LOAD),
  loadPlans: errorReduxer(actionTypes.PLANS_LOAD),
  loadPlanGroups: errorReduxer(actionTypes.PLAN_GROUPS_LOAD),
  loadActivePlans: errorReduxer(actionTypes.PLANS_LOAD_ACTIVE),
  loadActivePlanGroups: errorReduxer(actionTypes.PLAN_GROUPS_LOAD_ACTIVE),
  loadStateCategories: errorReduxer(actionTypes.STATE_CATEGORY_VIEWS_LOAD),
  loadStates: errorReduxer(actionTypes.STATES_LOAD),
  loadStateViews: errorReduxer(actionTypes.STATE_VIEWS_LOAD),
  loadVirtualStates: errorReduxer(actionTypes.VIRTUAL_STATES_LOAD),
  loadDevices: errorReduxer(actionTypes.DEVICES_LOAD),
  loadIssues: errorReduxer(actionTypes.ISSUES_LOAD),
  loadPermissions: errorReduxer(actionTypes.PERMISSIONS_LOAD),
  loadDeviceShapeLibraries: errorReduxer(actionTypes.DEVICE_SHAPE_LIBRARIES_LOAD),
  loadDeviceShapes: errorReduxer(actionTypes.DEVICE_SHAPES_LOAD),
  // REMOVE
  removeScenarioTLBlock: errorReduxer(actionTypes.SCENARIO_REMOVE_TL_BLOCK),
  // SET
  setDeviceConfig: errorReduxer(actionTypes.DEVICE_SET_CONFIG),
  setActiveDeviceConfig: errorReduxer(actionTypes.ACTIVE_DEVICE_SET_CONFIG),
  setDeviceProperties: errorReduxer(actionTypes.DEVICE_SET_PROPERTIES),
  // UPDATE
  updateProjectStatus: errorReduxer(actionTypes.PROJECT_UPDATE_STATUS),
  updateRegion: errorReduxer(actionTypes.REGION_UPDATE),
  updateDeviceType: errorReduxer(actionTypes.DEVICE_UPDATE_TYPE),
  updateDeviceAddressPath: errorReduxer(actionTypes.DEVICE_UPDATE_ADDRESS_PATH),
  updateDevicePlanLayouts: errorReduxer(actionTypes.DEVICE_UPDATE_PLAN_LAYOUTS),
  updateVirtualState: errorReduxer(actionTypes.VIRTUAL_STATE_UPDATE),
  updateScenarioStopLogic: errorReduxer(actionTypes.SCENARIO_UPDATE_STOP_LOGIC),
  updateScenarioStartLogic: errorReduxer(actionTypes.SCENARIO_UPDATE_START_LOGIC),
  updateScenarioBasicParams: errorReduxer(actionTypes.SCENARIO_UPDATE_BASIC_PARAMS),
  updateScenarioTLBlock: errorReduxer(actionTypes.SCENARIO_UPDATE_TL_BLOCK),
  updateScenarioAdvancedParams: errorReduxer(actionTypes.SCENARIO_UPDATE_ADVANCED_PARAMS),
  updateProject: errorReduxer(actionTypes.PROJECT_UPDATE),
  updateControlPanelUsers: errorReduxer(actionTypes.UPDATE_CONTROL_PANEL_USERS),
  regionUpdatePlanLayouts: errorReduxer(actionTypes.REGION_UPDATE_PLAN_LAYOUTS),
  // UPLOAD
  uploadDeviceControlDatabase: errorReduxer(actionTypes.DEVICE_UPLOAD_CONTROL_DATABASE),
  uploadDeviceConfig: errorReduxer(actionTypes.DEVICE_UPLOAD_CONFIG),
  // LOGIN
  loginUser: errorReduxer(actionTypes.LOGIN_REQUEST)
});
