import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { getSelectedScenario, getSelectedActiveScenario } from 'helpers/scenarios';
import { getFullScenario } from 'actions/scenarios';
import { checkCurrentProjectIsActive } from 'helpers/currentProject';

class ScenarioDetailsLoader extends PureComponent {
  componentDidUpdate(prevProps) {
    const {
      selectedScenario,
      getFullScenarioInProgress,
      getFullScenarioError,
      dispatch
    } = this.props;

    if (
      !!selectedScenario &&
      selectedScenario.id &&
      selectedScenario !== prevProps.selectedScenario &&
      !getFullScenarioInProgress &&
      !getFullScenarioError &&
      (!selectedScenario.advancedParams || !Object.keys(selectedScenario.advancedParams).length)
    ) {
      dispatch(getFullScenario(selectedScenario.projectId, selectedScenario.id));
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => {
  let selectedScenario = null;
  const currentProjectIsActive = checkCurrentProjectIsActive(state);
  if (currentProjectIsActive === true) selectedScenario = getSelectedActiveScenario(state);
  else if (currentProjectIsActive === false) selectedScenario = getSelectedScenario(state);
  return {
    selectedScenario,
    getFullScenarioInProgress: state.inProgress.getFullScenario,
    getFullScenarioError: state.errors.getFullScenario
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch: dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioDetailsLoader);
