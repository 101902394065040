import {
  DEVICE_EXPORT_CONTOL_CONFIG,
  DEVICE_COMPARISON_CONTROL_ENTITIES,
  MODAL_CLOSE,
  isLoaded
} from 'constants/actionTypes';
import { MODAL_NAMES } from 'constants/modals';

export default function exportProjectComparisonData(state = {}, { type, payload }) {
  switch (type) {
    case isLoaded(DEVICE_EXPORT_CONTOL_CONFIG, true): {
      const { issue } = payload;
      if (issue) {
        const newState = {};
        newState[issue.id] = {};
        return newState;
      }
      return state;
    }
    case DEVICE_COMPARISON_CONTROL_ENTITIES: {
      const { issueId } = payload;
      if (state[issueId]) {
        const newState = {};
        newState[issueId] = payload;
        return newState;
      }
      return state;
    }
    case '@@router/LOCATION_CHANGE': {
      return {};
    }
    case MODAL_CLOSE: {
      if (payload === MODAL_NAMES.DEVICE_CONFIG_DIFF) return {};
      return state;
    }
    default:
      return state;
  }
}
