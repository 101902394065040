import { combineReducers } from 'redux';
import {
  DEVICE_SHAPE_ADD,
  DEVICE_SHAPE_LIBRARY_ADD,
  DEVICE_SHAPE_LIBRARY_REMOVE,
  DEVICE_SHAPE_LIBRARY_REMOVE_SELECTION,
  DEVICE_SHAPE_LIBRARY_SELECT,
  DEVICE_SHAPE_LIBRARY_UPDATE,
  DEVICE_SHAPE_REMOVE,
  DEVICE_SHAPE_UPDATE,
  isLoaded
} from 'constants/actionTypes';
import { createDeviceShapeLibraryTreeItem, createDeviceShapeTreeItem } from 'helpers/deviceShapeLibrary';
import { ENTITY_TYPE } from 'constants/deviceShapeLibrary';

function selectedTreeItem(state = null, action) {
  switch (action.type) {
    case DEVICE_SHAPE_LIBRARY_SELECT: {
      const selectedTreeItem = action.payload;
      return { ...selectedTreeItem, byUser: true };
    }

    case DEVICE_SHAPE_LIBRARY_REMOVE_SELECTION: {
      return null;
    }

    case isLoaded(DEVICE_SHAPE_LIBRARY_ADD, true): {
      const addedDeviceShapeLibraries = action.payload;
      const firstAddedLibrary = addedDeviceShapeLibraries[0];
      return createDeviceShapeLibraryTreeItem(firstAddedLibrary.id, firstAddedLibrary);
    }

    case isLoaded(DEVICE_SHAPE_ADD, true): {
      const addedDeviceShapes = action.payload;
      const firstAddedShape = addedDeviceShapes[0];
      return createDeviceShapeTreeItem(firstAddedShape.id, firstAddedShape);
    }

    case isLoaded(DEVICE_SHAPE_LIBRARY_UPDATE, true): {
      const updatedDeviceShapeLibraries = action.payload;
      const firstUpdatedLibrary = updatedDeviceShapeLibraries[0];
      return createDeviceShapeLibraryTreeItem(firstUpdatedLibrary.id, firstUpdatedLibrary);
    }

    case isLoaded(DEVICE_SHAPE_UPDATE, true): {
      const updatedDeviceShapes = action.payload;
      const firstUpdatedShape = updatedDeviceShapes[0];
      return createDeviceShapeTreeItem(firstUpdatedShape.id, firstUpdatedShape);
    }

    case isLoaded(DEVICE_SHAPE_LIBRARY_REMOVE, true): {
      const removedDeviceShapeLibraryIds = action.payload;
      const selectedTreeItem = state;
      if (
        selectedTreeItem &&
        selectedTreeItem.entityType === ENTITY_TYPE.DEVICE_SHAPE_LIBRARY &&
        removedDeviceShapeLibraryIds.indexOf(selectedTreeItem.entity.id) >= 0
      ) {
        return null;
      } else {
        return state;
      }
    }

    case isLoaded(DEVICE_SHAPE_REMOVE, true): {
      const removedDeviceShapeIds = action.payload;
      const selectedTreeItem = state;
      if (
        selectedTreeItem &&
        selectedTreeItem.entityType === ENTITY_TYPE.DEVICE_SHAPE &&
        removedDeviceShapeIds.indexOf(selectedTreeItem.entity.id) >= 0
      ) {
        return null;
      } else {
        return state;
      }
    }

    default:
      return state;
  }
}

export default combineReducers({
  selectedTreeItem
});
