export function getViewMode({
  widgets: {
    deviceTree: { viewMode }
  }
}) {
  return viewMode;
}

export function getCurrentRegion({
  widgets: {
    deviceTree: { currentRegion }
  }
}) {
  return currentRegion;
}

export function getRegions({
  widgets: {
    deviceTree: { regions }
  }
}) {
  if (!regions) return [];
  return regions;
}

export function getCurrentDevice({ widgets: { currentDevice } }) {
  return currentDevice;
}

export function getActiveScenariosFilter({ widgets: { activeScenariosFilter } }) {
  return activeScenariosFilter;
}
