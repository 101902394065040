import { select, takeEvery, all } from 'redux-saga/effects';

import {
  PROJECT_VALIDATE_DELETE,
  REGION_CHANGE_GUARD_STATUS,
  REGION_CONSTANTS_LOAD,
  REGION_CREATE,
  REGION_CREATE_AND_CONNECT_DEVICE,
  REGION_DELETE,
  REGION_DELETE_DEVICE,
  REGION_DELETE_DEVICE_LIST,
  REGION_NEW_DEVICE,
  REGION_NEW_DEVICE_LIST,
  REGION_UPDATE,
  REGION_UPDATE_PLAN_LAYOUTS,
  REGIONS_UPDATE_PLAN_LAYOUTS,
  REGIONS_DELETE_ALL_DEVICE_LISTS,
  REGIONS_LOAD,
  REGIONS_LOAD_ACTIVE
} from 'constants/actionTypes';

import { dispatchFail, dispatchSuccess, fetch, request } from 'helpers/request';
import i18next from 'i18next';

function* createRegion(action) {
  const { projectId, newRegion, count = 1 } = action.payload;
  try {
    let path;
    if (count > 1) {
      path = `/projects/${projectId}/region_views/multiple?count=${count}`;
    } else {
      path = `/projects/${projectId}/region_views/`;
    }
    const response = yield request(path, 'POST', newRegion);
    if (response && !response.length) throw new Error(i18next.t('errors.noResponseFromServer'));

    yield dispatchSuccess(REGION_CREATE, { projectId, newRegions: response });
  } catch (error) {
    yield dispatchFail(REGION_CREATE, error.message);
  }
}

function* updateRegion(action) {
  const { projectId, updatedRegion } = action.payload;
  try {
    const response = yield request(
      `/projects/${projectId}/region_views/${updatedRegion.id}`,
      'PUT',
      updatedRegion
    );

    yield dispatchSuccess(REGION_UPDATE, { projectId, updatedRegion: response });
  } catch (error) {
    yield dispatchFail(REGION_UPDATE, error.message);
  }
}

function* createRegionAndConnectDevice({ type, payload }) {
  const { projectId, region, deviceId } = payload;
  try {
    const createdRegions = yield request(`/projects/${projectId}/region_views`, 'POST', region);
    const deviceRevision = yield select(state => state.devices[projectId].hash[deviceId].revision);

    const updatedDevices = yield request(
      `/projects/${projectId}/regions/${createdRegions[0].id}/devices/${deviceId}`,
      'PUT',
      {},
      { ETag: deviceRevision }
    );
    yield dispatchSuccess(type, { projectId, newRegions: createdRegions, updatedDevices });
  } catch (err) {
    yield dispatchFail(type, err.message);
  }
}

function* connectNewDeviceToRegion({ type, payload }) {
  const { projectId, regionId, deviceId } = payload;
  const deviceRevision = yield select(state => state.devices[projectId].hash[deviceId].revision);
  try {
    const response = yield request(
      `/projects/${projectId}/regions/${regionId}/devices/${deviceId}`,
      'PUT',
      {},
      { ETag: deviceRevision }
    );

    yield dispatchSuccess(REGION_NEW_DEVICE, { projectId, updatedDevices: response });
  } catch (error) {
    yield dispatchFail(type, error.message);
  }
}

function* disconnectDeviceFromRegion({ type, payload }) {
  const { projectId, regionId, deviceId } = payload;
  const deviceRevision = yield select(state => state.devices[projectId].hash[deviceId].revision);
  try {
    const response = yield request(
      `/projects/${projectId}/regions/${regionId}/devices/${deviceId}`,
      'DELETE',
      {},
      { ETag: deviceRevision }
    );

    yield dispatchSuccess(REGION_DELETE_DEVICE, { projectId, device: response });
  } catch (error) {
    yield dispatchFail(type, error.message);
  }
}

function* deleteRegion(action) {
  const { projectId, regionId, regionIds } = action.payload;
  try {
    const ids = regionId ? [regionId] : regionIds;
    const response = yield request(`/projects/${projectId}/region_views/`, 'DELETE', ids);
    if (response.removedValidateMessageIds && response.removedValidateMessageIds.length) {
      yield dispatchSuccess(PROJECT_VALIDATE_DELETE, {
        projectId,
        validateMessageIds: response.removedValidateMessageIds
      });
    }
    yield dispatchSuccess(REGION_DELETE, {
      projectId,
      regionIds: ids,
      updatedScenarios: response.updatedScenarios,
      updatedDevices: response.updatedDevices,
      updatedIndicatorPanels: response.indicatorPanels,
      updatedRegions: response.updatedRegions
    });
  } catch (error) {
    yield dispatchFail(REGION_DELETE, error.message);
  }
}

function* loadActiveRegions(action) {
  const { projectId } = action.payload;
  try {
    const response = yield fetch(`/projects/${projectId}/active_regions/`);
    if (!Array.isArray(response)) {
      throw new Error(i18next.t('errors.incorrectResponseReceived', { response: response }));
    }
    yield dispatchSuccess(REGIONS_LOAD_ACTIVE, response);
  } catch (error) {
    yield dispatchFail(REGIONS_LOAD_ACTIVE, error.message);
  }
}

function* changeRegionGuardStatus(action) {
  const { projectId, regionId, onGuard } = action.payload;
  const newIssue = {
    action: (onGuard === true ? 'ENABLE' : 'DISABLE') + '_REGION_GUARD',
    projectId,
    parameters: {
      regionId: regionId
    }
  };
  try {
    yield request(`/issues`, 'POST', newIssue);

    yield dispatchSuccess(REGION_CHANGE_GUARD_STATUS, null);
  } catch (error) {
    yield dispatchFail(REGION_CHANGE_GUARD_STATUS, error.message);
  }
}

function* loadRegionConstants(action) {
  try {
    const dictionaries = yield fetch(`/region_dictionaries`);
    yield dispatchSuccess(REGION_CONSTANTS_LOAD, dictionaries);
  } catch (error) {
    yield dispatchFail(REGION_CONSTANTS_LOAD, error.message);
  }
}
function* loadRegions(action) {
  const { projectId } = action.payload;
  try {
    const regionViews = yield fetch(`/projects/${projectId}/region_views`);
    if (!Array.isArray(regionViews)) {
      throw new Error(i18next.t('errors.responseNotArray'));
    }

    yield dispatchSuccess(REGIONS_LOAD, { projectId, regionViews });
  } catch (error) {
    yield dispatchFail(REGIONS_LOAD, error.message);
  }
}

function* updatePlanLayouts(action) {
  const { projectId, regionId, planLayouts } = action.payload;
  try {
    const response = yield request(
      `/projects/${projectId}/region_views/${regionId}?plan_layouts`,
      'PUT',
      planLayouts
    );

    yield dispatchSuccess(REGION_UPDATE, { projectId, updatedRegion: response });
  } catch (error) {
    yield dispatchFail(REGION_UPDATE_PLAN_LAYOUTS, error.message);
  }
}

export function* groupUpdatePlanLayouts(action) {
  const { regionsProperties, projectId } = action.payload;
  try {
    // TODO Реализовать функционал на беке, принимающий массив зон со следующими полями {regionLayout:{points:{x:Float,y:Float}[],planId:String}[], regionId:String}[]
    // Бек должен возвращать массив этих же зон со всеми их полями
    //----------------------------раскомментить после реализации метода на беке----------------------------

    // const response = yield request(
    //   `/projects/${projectId}/region_views/plan_layouts`,
    //   'PATCH',
    //   regionsProperties
    // );

    // yield dispatchSuccess(REGIONS_UPDATE_PLAN_LAYOUTS, { projectId, updatedRegions: response });

    //----------------------------раскомментить после реализации метода на беке----------------------------

    //----------------------------удалить после реализации метода на беке----------------------------
    const response = yield all([
      ...regionsProperties.map(region =>
        request(
          `/projects/${projectId}/region_views/${region.regionId}?plan_layouts`,
          'PUT',
          region.regionLayout
        )
      )
    ]);
    yield dispatchSuccess(REGIONS_UPDATE_PLAN_LAYOUTS, { projectId, updatedRegions: response });
    //----------------------------удалить после реализации метода на беке----------------------------
  } catch (error) {
    yield dispatchFail(REGIONS_UPDATE_PLAN_LAYOUTS, error.message);
  }
}

function* connectDeviceListToRegion({ type, payload }) {
  const { projectId, regionId, deviceIds } = payload;
  try {
    const response = yield request(
      `/projects/${projectId}/regions/${regionId}/devices`,
      'PUT',
      deviceIds
    );
    yield dispatchSuccess(type, { projectId, updatedDevices: response });
  } catch (error) {
    yield dispatchFail(type, error.message);
  }
}

function* disconnectDeviceListFromRegion({ type, payload }) {
  const { projectId, regionId, deviceIds } = payload;
  try {
    let path = `/projects/${projectId}/regions/${regionId}/devices?device_ids=${deviceIds}`;
    const response = yield request(path, 'DELETE');
    yield dispatchSuccess(type, { projectId, updatedDevices: response });
  } catch (error) {
    yield dispatchFail(type, error.message);
  }
}

function* disconnectDeviceListFromRegions({ type, payload }) {
  const { projectId, regionIds } = payload;
  try {
    let path = `/projects/${projectId}/regions/devices?region_ids=${regionIds}`;

    const response = yield request(path, 'DELETE');
    yield dispatchSuccess(type, { projectId, updatedDevices: response });
  } catch (error) {
    yield dispatchFail(type, error.message);
  }
}

export default function* projects() {
  yield takeEvery(REGION_CREATE, createRegion);
  yield takeEvery(REGION_DELETE, deleteRegion);
  yield takeEvery(REGION_NEW_DEVICE, connectNewDeviceToRegion);
  yield takeEvery(REGION_DELETE_DEVICE, disconnectDeviceFromRegion);
  yield takeEvery(REGION_UPDATE, updateRegion);
  yield takeEvery(REGIONS_LOAD_ACTIVE, loadActiveRegions);
  yield takeEvery(REGION_CHANGE_GUARD_STATUS, changeRegionGuardStatus);
  yield takeEvery(REGION_CONSTANTS_LOAD, loadRegionConstants);
  yield takeEvery(REGIONS_LOAD, loadRegions);
  yield takeEvery(REGION_UPDATE_PLAN_LAYOUTS, updatePlanLayouts);
  yield takeEvery(REGIONS_UPDATE_PLAN_LAYOUTS, groupUpdatePlanLayouts);
  yield takeEvery(REGION_NEW_DEVICE_LIST, connectDeviceListToRegion);
  yield takeEvery(REGION_DELETE_DEVICE_LIST, disconnectDeviceListFromRegion);
  yield takeEvery(REGIONS_DELETE_ALL_DEVICE_LISTS, disconnectDeviceListFromRegions);
  yield takeEvery(REGION_CREATE_AND_CONNECT_DEVICE, createRegionAndConnectDevice);
}
