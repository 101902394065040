import {
  SCENARIO_PERFORM_COMPUTER_ACTION,
  PROJECT_UPDATE_STATUS,
  isLoaded
} from 'constants/actionTypes';

export default function regions(state = null, { type, payload }) {
  switch (type) {
    case SCENARIO_PERFORM_COMPUTER_ACTION: {
      const newState = {...payload};
      newState.id = newState.name + newState.message + newState.countdownTimeSec;
      return newState;
    }
    case isLoaded(PROJECT_UPDATE_STATUS, true): {
      return null;
    }

    default:
      return state;
  }
}
