import { KeyCodes } from 'constants/keyboard';

export function getSelectedItemsByPressedKeys(
  entities,
  selectedIds,
  lastSelectedId,
  pressedKeys,
  newEntity
) {
  let newIds = new Set(selectedIds);
  if (pressedKeys.has(KeyCodes.CTRL)) {
    if (newIds.has(newEntity.id)) newIds.delete(newEntity.id);
    else newIds.add(newEntity.id);
  } else if (pressedKeys.has(KeyCodes.SHIFT)) {
    const idByIndexMap = new Map();
    if (newIds.size) {
      entities.forEach((it, index) => {
        if (it.id === newEntity.id) idByIndexMap.set(newEntity.id, index);
        else if (it.id === lastSelectedId) idByIndexMap.set(lastSelectedId, index);
        else if (newIds.has(it.id)) idByIndexMap.set(it.id, index);
      });
    }
    const lastIndex = idByIndexMap.get(lastSelectedId);
    const currentIndex = idByIndexMap.get(newEntity.id);
    const min = lastIndex > currentIndex ? currentIndex : lastIndex;
    const max = lastIndex > currentIndex ? lastIndex : currentIndex;

    if (newIds.has(newEntity.id)) newIds.delete(lastSelectedId);
    else newIds.add(lastSelectedId);
    const needAdd = !newIds.has(newEntity.id);
    for (let index = min; index <= max; index++) {
      const id = entities[index].id;
      if (needAdd) newIds.add(id);
      else newIds.delete(id);
    }
  } else return [newEntity.id];
  return Array.from(newIds.keys());
}
