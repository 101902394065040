import { combineReducers } from 'redux';

import {
  UPDATE_SELECTED_DEVICES_IDS,
  CREATE_CONTROL_PANEL_USER,
  LOAD_CONTROL_PANEL_USERS,
  UPDATE_CONTROL_PANEL_USERS,
  DELETE_CONTROL_PANEL_USERS,
  isLoaded
} from 'constants/actionTypes';

function loadedUsers(state = new Map(), { type, payload }) {
  switch (type) {
    case isLoaded(CREATE_CONTROL_PANEL_USER, true): {
      const user = payload;
      const newState = new Map(state);
      newState.set(user.id, user);
      return newState;
    }
    case isLoaded(LOAD_CONTROL_PANEL_USERS, true): {
      const newState = new Map();
      payload.forEach(user => newState.set(user.id, user));
      return newState;
    }
    case isLoaded(DELETE_CONTROL_PANEL_USERS, true): {
      const usersIds = payload;
      let newState = new Map(state);
      usersIds.forEach(id => newState.delete(id));
      return newState;
    }
    case isLoaded(UPDATE_CONTROL_PANEL_USERS, true): {
      const users = payload;
      let newState = new Map(state);
      users.forEach(user => newState.set(user.id, user));
      return newState;
    }
    default:
      return state;
  }
}

function selectedDevicesIds(state = [], { type, payload }) {
  switch (type) {
    case UPDATE_SELECTED_DEVICES_IDS: {
      return [...payload];
    }
    default:
      return state;
  }
}

export default combineReducers({
  loadedUsers,
  selectedDevicesIds
});
