import React from 'react';
import styled from 'styled-components';

const VirtualizedWrapper = styled.div`
  flex: 1;
  .ReactVirtualized__Table__headerRow {
    background-color: lightgray;
    font-weight: normal;
    font-size: 11px !important;
  }
  .ReactVirtualized__Table__headerColumn {
    display: flex;
    justify-content: center;
    margin: 1px !important;
  }
  .ReactVirtualized__Table__Grid {
    background-color: white;
  }
  .ReactVirtualized__Table__row:first-child {
    border-top: 1px solid lightgray;
  }
  .ReactVirtualized__Table__rowColumn {
    line-height: 26px;
  }
  .ReactVirtualized__Table__row {
    margin: 0 !important;
    border-bottom: 1px solid lightgray;
    & > .ReactVirtualized__Table__rowColumn:nth-child(1) {
      border-right: 1px solid lightgray;
    }
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const WrapperWithoutPadding = styled.div`
  display: flex;
  flex: 1;
  background-color: white;
  flex-direction: column;
`;

const WrapperWithPadding = styled.div`
  display: flex;
  flex: 1;
  padding: 0 14px;
  background-color: white;
  flex-direction: column;
  overflow-y: auto;
`;

const WhiteFormWrapper = styled.div`
  height: 100%;
  background-color: white;
  .ant-legacy-form-item {
    margin-top: 10px;
    margin-bottom: 0;
  }
  .ant-legacy-form-item-control {
    line-height: 1.6;
  }
`;

const ScrollableContainerWithoutHeader = styled.div`
  flex: 1;
  height: calc(100% - ${p => p.headerHeight || 0}px);
  overflow-y: scroll;
`;

const WrapperWithLabel = ({ label, children }) => {
  return (
    <div className="wrapper-with-label">
      <span className="wrapper-label">{label}</span>
      {children}
    </div>
  );
};

const DeviceTreeWrapper = styled.div`
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
`;

export {
  Column,
  Row,
  WrapperWithoutPadding,
  WrapperWithPadding,
  VirtualizedWrapper,
  WhiteFormWrapper,
  WrapperWithLabel,
  DeviceTreeWrapper,
  ScrollableContainerWithoutHeader
};
