import React from 'react';
import { createSelector } from 'reselect';
import PropTypes from 'prop-types';
import { getCurrentProjectRegions } from './currentProject';
import SecurityRegionForm, {
  resetFormValues as resetSecFormValues
} from 'containers/Forms/SecurityRegionForm';
import SUBSYSTEMS from 'constants/subsystems';
import SkudRegionForm, {
  resetFormValues as resetSkudFormValues
} from 'containers/Forms/SkudRegionForm';
import FireRegionForm, {
  resetFormValues as resetFireFormValues
} from 'containers/Forms/FireRegionForm';
import memoizeOne from 'memoize-one';
import Device from 'components/Device/Device';
import DeviceImg from 'components/Device/DeviceImg';
import { Option } from 'components/Select';
import i18next from 'i18next';
import { FILTER_TYPES } from 'components/Filters';

const FilterNames = {
  REGIONS_TYPES: 'Types',
  DEVICES: 'Devices'
};

export const getFireAlarmDependencyNames = () => ({
  SIMPLE: i18next.t('zones.fireType.SIMPLE'),
  TYPE_A: i18next.t('zones.fireType.TYPE_A'),
  TYPE_B: i18next.t('zones.fireType.TYPE_B'),
  TYPE_C: i18next.t('zones.fireType.TYPE_C')
});

export const getFireAlarmDependenciesTitles = () => ({
  SIMPLE: i18next.t('zones.fireTypeDescription.SIMPLE'),
  TYPE_A: i18next.t('zones.fireTypeDescription.TYPE_A'),
  TYPE_B: i18next.t('zones.fireTypeDescription.TYPE_B'),
  TYPE_C: i18next.t('zones.fireTypeDescription.TYPE_C')
});

export function getAllRegionsFilters(component) {
  return [
    {
      name: i18next.t('zoneType', { context: 'plural' }),
      key: FilterNames.REGIONS_TYPES,
      type: FILTER_TYPES.INPUT,
      getEntities: () => component.state.filterItems,
      check: (filter, node) => {
        for (let id of filter.active) {
          if (id === node.subsystem) return true;
          else if (node.subsystem === SUBSYSTEMS.FIRE.id && id === node.fireAlarmDependency) {
            return true;
          }
        }
        return false;
      }
    },
    {
      name: i18next.t('controlDevice', { context: 'plural' }),
      key: FilterNames.DEVICES,
      type: FILTER_TYPES.INPUT,
      getEntities: () => {
        return component.props.controlDevices;
      },
      check: (filter, node) => {
        const deviceList = component.props.deviceList;
        const controlDeviceList = component.props.controlDevices;
        for (let id of filter.active) {
          const controlDev = controlDeviceList.find(dev => dev.id === id);
          const devices = deviceList.filter(dev =>
            dev.fullAddressPath.startsWith(controlDev.fullAddressPath)
          );
          if (devices.find(dev => dev.regionId === node.id)) return true;
        }
        return false;
      },
      customOptions: memoizeOne(entities => {
        return entities.map(dev => (
          <Option key={dev.id} value={dev.id} label={dev.name}>
            {dev.iconMedia ? (
              <Device>
                <DeviceImg size={15} name={dev.iconMedia.path} />
                <span>{dev.name}</span>
                <span> ({dev.fullAddressPath})</span>
              </Device>
            ) : null}
          </Option>
        ));
      })
    }
  ];
}

export const FIRE_DEPENDENCY_FIELD_SETTINGS = {
  SIMPLE: {
    fireEventCount: { visible: true, active: true, defaultValue: 2 },
    fireOnFirstSensorRemainedOn: { visible: false },
    checkFireTimeout: { visible: false },
    fireOnSecondSensorOn: { visible: false },
    bindedRegionId: { visible: false }
  },
  TYPE_A: {
    fireEventCount: { visible: false },
    fireOnFirstSensorRemainedOn: { visible: true, active: false, defaultValue: true },
    checkFireTimeout: { visible: true, active: false, defaultValue: 60 },
    fireOnSecondSensorOn: { visible: true, active: false, defaultValue: true },
    bindedRegionId: { visible: true, active: false }
  },
  TYPE_B: {
    fireEventCount: { visible: false },
    fireOnFirstSensorRemainedOn: { visible: true, active: true, defaultValue: true },
    checkFireTimeout: { visible: true, active: true, defaultValue: 30 },
    fireOnSecondSensorOn: { visible: true, active: true, defaultValue: true },
    bindedRegionId: { visible: true, active: true }
  },
  TYPE_C: {
    fireEventCount: { visible: false },
    fireOnFirstSensorRemainedOn: { visible: true, active: false, defaultValue: false },
    checkFireTimeout: { visible: true, active: false },
    fireOnSecondSensorOn: { visible: true, active: false, defaultValue: true },
    bindedRegionId: { visible: true, active: true }
  }
};

export const getNewRegionIdx = createSelector(getCurrentProjectRegions, regions => {
  let maxIdx = 0;
  regions.forEach(region => {
    if (maxIdx < region.index) maxIdx = region.index;
  });
  return maxIdx + 1;
});

/**
 * Базовый объект редактора зоны.
 */
class RegionEditorBase {
  constructor(subsystem, formName = '') {
    this.subsystem = subsystem;
    this.formName = formName;
  }

  getTitle() {
    return this.subsystem.name;
  }

  /**
   * @param {Object} props Должен содержать индекс новой зоны newRegionIdx
   */
  getDefaultValues(props) {
    PropTypes.checkPropTypes(
      { newRegionIdx: PropTypes.number.isRequired },
      props,
      'prop',
      'RegionEditorBase'
    );

    const { newRegionIdx } = props;
    return {
      index: newRegionIdx,
      name: `${i18next.t('zone')} ${newRegionIdx}`,
      subsystem: this.subsystem.id,
      inputTimeout: 0,
      outputTimeout: 0,
      autoRelock: 0,
      silenceAlarm: false
    };
  }

  getCreateModalName() {
    return `${this.formName}_create${this.subsystem.id}Region`;
  }

  /* abstract */
  getModalComponent() {}

  /* abstract */
  setModalValues(dispatch, values) {}

  getCreateModal(onSubmit, modalProps = {}, otherProps = {}) {
    const RegionComponent = this.getModalComponent();
    const modalName = this.getCreateModalName();
    return (
      <RegionComponent
        key={modalName}
        modalName={modalName}
        form={this.formName}
        onSubmit={onSubmit}
        modalProps={modalProps}
        {...otherProps}
      />
    );
  }

  getUpdateModalName() {
    return `${this.formName}_update${this.subsystem.id}Region`;
  }

  getUpdateModal(onSubmit, modalProps = {}, otherProps) {
    const RegionComponent = this.getModalComponent();
    return (
      <RegionComponent
        modalName={this.getUpdateModalName()}
        onSubmit={onSubmit}
        isEdit={true}
        form={this.formName}
        modalProps={modalProps}
        {...otherProps}
      />
    );
  }
}

/**
 * Объект редактора охранной зоны.
 */
class SecurityRegionEditor extends RegionEditorBase {
  getDefaultValues(props) {
    return { ...super.getDefaultValues(props), securityRegionType: 'DEFAULT' };
  }

  getModalComponent() {
    return SecurityRegionForm;
  }

  setModalValues(dispatch, values) {
    values.form = this.formName;
    resetSecFormValues(dispatch, values);
  }
}

/**
 * Объект редактора зоны СКУД.
 */
class SkudRegionEditor extends RegionEditorBase {
  getModalComponent() {
    return SkudRegionForm;
  }

  setModalValues(dispatch, values) {
    values.form = this.formName;
    resetSkudFormValues(dispatch, values);
  }
}

/**
 * Объект редактора пожарной зоны.
 */
class FireRegionEditor extends RegionEditorBase {
  /**
   * @param {Object} props Должен содержать объект атрибутов драйвера driverAttributes
   */
  getDefaultValues(props) {
    PropTypes.checkPropTypes(
      { driverAttributes: PropTypes.object.isRequired },
      props,
      'prop',
      'FireRegionEditor'
    );

    const {
      driverAttributes: { fireAlarmDependencies }
    } = props;

    const fireParams = {};
    if (fireAlarmDependencies && fireAlarmDependencies.length > 0) {
      /* По умолчанию тип зависимости - первый в отсортированном списке. */
      fireParams.fireAlarmDependency = fireAlarmDependencies[0];
      for (const dependency of fireAlarmDependencies) {
        if (dependency.localeCompare(fireParams.fireAlarmDependency) < 0)
          fireParams.fireAlarmDependency = dependency;
      }
      const dependencyFiledSettings =
        FIRE_DEPENDENCY_FIELD_SETTINGS[fireParams.fireAlarmDependency];
      fireParams.dependencyParams = {};
      Object.keys(dependencyFiledSettings).forEach(fieldName => {
        fireParams.dependencyParams[fieldName] = dependencyFiledSettings[fieldName].defaultValue;
      });
    }
    return {
      ...super.getDefaultValues(props),
      ...fireParams
    };
  }

  getModalComponent() {
    return FireRegionForm;
  }

  setModalValues(dispatch, values) {
    values.form = this.formName;
    resetFireFormValues(dispatch, values);
  }

  render() {
    return null;
  }
}

/**
 * Создает и возвращает новые объекты редакторов зон с уникальными именами форм.
 *
 * Использовать функцию необходимо в каждом отдельном компоненте,
 * который предоставляет функционал создания или редактирования зон.
 * Вызывать метод необходимо один раз в течении жизни такого компонента
 * (в конструкторе или в componentDidMount) и далее использовать возвращенную
 * карту редакторов для управления формами редактирования зон.
 *
 * @returns {[p: string]: SecurityRegionEditor|SkudRegionEditor|FireRegionEditor} - редакторы зон
 * по подсистемам
 */
export function createRegionEditors() {
  return {
    [SUBSYSTEMS.SECURITY.id]: new SecurityRegionEditor(
      SUBSYSTEMS.SECURITY,
      `securityRegionForm${Math.floor(Math.random() * 1024)}`
    ),
    [SUBSYSTEMS.SKUD.id]: new SkudRegionEditor(
      SUBSYSTEMS.SKUD,
      `skudRegionForm${Math.floor(Math.random() * 1024)}`
    ),
    [SUBSYSTEMS.FIRE.id]: new FireRegionEditor(
      SUBSYSTEMS.FIRE,
      `FireRegionForm${Math.floor(Math.random() * 1024)}`
    )
  };
}
