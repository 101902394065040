import { SET_LOCAL_HOST_USER } from 'constants/actionTypes';

export default function isLocalHostUser(
  state = false,
  { type, payload }: { type: string; payload: boolean }
) {
  switch (type) {
    case SET_LOCAL_HOST_USER: {
      return payload;
    }
    default: {
      return state;
    }
  }
}
