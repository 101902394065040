import React from 'react';

import { Modal } from 'antd';
import i18next from 'i18next';
import { StopOutlined } from '@ant-design/icons';

export default function CustomModal(props) {
  const { onClose, children, title, isVisible, ...restProps } = props;
  return (
    <Modal title={title} visible={isVisible} onCancel={onClose} footer={null} {...restProps}>
      {children}
    </Modal>
  );
}

CustomModal.confirm = Modal.confirm;
CustomModal.error = Modal.error;
CustomModal.accessDenied = () =>
  Modal.error({
    title: i18next.t('errors.accessDenied'),
    icon: <StopOutlined />,
    content: <p>{i18next.t('errors.accessDeniedFull')}</p>
  });
