import { MONITORABLE_VALUES_GET_PROFILES, isLoaded } from 'constants/actionTypes';

export default function monitorableValuesProfiles(state = [], action) {
  switch (action.type) {
    case isLoaded(MONITORABLE_VALUES_GET_PROFILES, true): {
      const profiles = action.payload;
      const profilesHash = {};
      profiles.forEach(profile => profilesHash[profile.id] = profile)
      return profilesHash;
    }

    default:
      return state;
  }
}
