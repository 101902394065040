import { EVENT_TABLE_COLUMNS } from "components/EventTable";

export const ENTITY_TYPE = {
  ROOT: 'ROOT',
  EMPLOYEE: 'EMPLOYEE',
  CARDS_FOLDER: 'CARDS_FOLDER',
  PASSW_FOLDER: 'PASSW_FOLDER',
  CARD: 'CARD',
  PASSWORD: 'PASSWORD',
  NEW_EMPLOYEE: 'NEW_EMPLOYEE',
  NEW_CARD: 'NEW_CARD',
  NEW_PASSWORD: 'NEW_PASSWORD',
  WORK_SCHEDULE: 'WORK_SCHEDULE',
  NEW_WORK_SCHEDULE: 'NEW_WORK_SCHEDULE',
  WORK_SCHEDULE_DAY: 'WORK_SCHEDULE_DAY',
  NEW_WORK_SCHEDULE_DAY: 'NEW_WORK_SCHEDULE_DAY',
  DAYS_FOLDER: 'DAYS_FOLDER',
  ACCESS_GRANTED_TIME: 'ACCESS_GRANTED_TIME',
  NEW_ACCESS_GRANTED_TIME: 'NEW_ACCESS_GRANTED_TIME',
  GRANTED_TIMES_FOLDER: 'GRANTED_TIMES_FOLDER'
};

export const WORK_SCHEDULE_TYPES_HASH = {
  WEEK: { name: 'Неделя', id: 'WEEK' },
  MONTH: { name: 'Месяц', id: 'MONTH' },
  OTHER: { name: 'Другое', id: 'OTHER' }
};

export const WORK_SCHEDULE_TYPES = Object.keys(WORK_SCHEDULE_TYPES_HASH).map(
  key => WORK_SCHEDULE_TYPES_HASH[key]
);

export const SKUD_LOG = {
  id: 'skud',
  name: 'События СКУД',
  active: true,
  recordCount: 1000,
  nLastDays: 100,
  builtIn: true,
  subsystems: ['SKUD']
};

export const SKUD_LOG_COLUMNS = [
  EVENT_TABLE_COLUMNS.OCCURRED,
  EVENT_TABLE_COLUMNS.RECEIVED,
  EVENT_TABLE_COLUMNS.EMPLOYEE,
  EVENT_TABLE_COLUMNS.DESCRIPTION,
  EVENT_TABLE_COLUMNS.OBJECT,
  EVENT_TABLE_COLUMNS.CONTROL_DEVICE,
  EVENT_TABLE_COLUMNS.REGION
];

export const SKUD_AUTOUPDATE_OBSERVER_ID = 'SKUD_UPDATE_CHECKER';
