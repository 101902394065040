export const CURSOR_STYLES = {
  NONE: 'none',
  COPY: 'copy',
  POINTER: 'pointer',
  DEFAULT: 'default',
  MOVE: 'move',
  INHERIT: 'inherit',
  CELL: 'cell',
  AUTO: 'auto',
  NS_RESIZE: 'ns-resize'
};