import {
  ISSUES_LOAD,
  ISSUES_DELETED,
  ISSUES_CREATED,
  ISSUES_UPDATED,
  isLoaded
} from 'constants/actionTypes';

/*
 * state = issues: {
 *   id: {id: <string>, ... (см. метод GET api/v1/issues в тех. проекте)},
 *   ...
 * }
 */
export default function issues(state = null, action) {
  switch (action.type) {
    case isLoaded(ISSUES_LOAD, true): {
      const newState = {};
      const loadedIssues = action.payload;
      loadedIssues.forEach(issue => {
        newState[issue.id] = issue;
      });
      return newState;
    }

    case ISSUES_CREATED:
    case ISSUES_UPDATED: {
      const newIssues = action.payload;
      if (!newIssues.length) return state;

      let newState = {};
      if (state) newState = { ...state };

      newIssues.forEach(issue => {
        newState[issue.id] = issue;
      });
      return newState;
    }

    case ISSUES_DELETED: {
      const deletedIssueIds = action.payload;
      if (!state) return state;
      if (!deletedIssueIds.length) return state;
      const newState = { ...state };
      deletedIssueIds.forEach(deletedIssueId => {
        delete newState[deletedIssueId];
      });
      return newState;
    }

    default:
      return state;
  }
}
