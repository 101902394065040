import React from 'react';
import PropTypes from 'prop-types';
import styles from './Spin.module.css';
import { Spin as BasicSpin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import i18next from 'i18next';

export const defaultSpinTip = i18next.t('loading');
export const defaultSpinIndicator = <LoadingOutlined spin />;
export const defaultSpinProps = {
  size: 'small',
  style: null,
  tip: defaultSpinTip,
  indicator: defaultSpinIndicator
};

CenterAlignedSpin.propsTypes = {
  size: PropTypes.string,
  indicator: PropTypes.object,
  style: PropTypes.object,
  tip: PropTypes.string
};
CenterAlignedSpin.defaultProps = defaultSpinProps;

Spin.propsTypes = CenterAlignedSpin.propsTypes;

/** Спинер без стилевой обертки. */
export function Spin({ ...restProps }) {
  return <BasicSpin {...restProps} />;
}

/**
 * Спинер со стилевой оберткой, выполняющей центрирование.
 *
 * Также данному компоненту по умолчанию прописаны свойства defaultSpinProps.
 */
export default function CenterAlignedSpin({ ...restProps }) {
  return (
    <div className={styles['center-aligned']}>
      <BasicSpin {...restProps} />
    </div>
  );
}
