import React, { Component } from 'react';
import styled from 'styled-components';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

// eslint-disable-next-line no-unused-vars
import New from './New';

import Input from './Input';

const FormWrapper = styled.div`
  .ant-form-item {
    margin-bottom: 10px;
  }
  .ant-btn-primary {
    margin-top: 20px;
    background-color: #5fa2dd;
    border-color: #5fa2dd;
  }
  ${p => p.css};
`;

/** @deprecated
 *  Используйте компонент {@link New} */
export default class StyledForm extends Component {
  render() {
    const { children, css, ...restProps } = this.props;
    return (
      <FormWrapper css={css}>
        <Form {...restProps}>{children}</Form>
      </FormWrapper>
    );
  }
}

export const ItemLayoutWithLabel = {
  labelCol: {
    sm: { span: 9 }
  },
  wrapperCol: {
    sm: { span: 13 }
  }
};

export const ItemLayoutWithoutLabel = {
  wrapperCol: {
    sm: { offset: 9, span: 13 }
  }
};

export const SubmitItemLayout = {
  wrapperCol: {
    sm: { offset: 18, span: 6 }
  }
};

function FormItemUpLabel(props) {
  return <Form.Item className={'form_item'} labelCol={{ span: 24 }} {...props} />;
}

/** @deprecated
 *  Используйте компонент {@link New} */
export const FormItem = FormItemUpLabel;

/** @deprecated
 *  Используйте компонент {@link New} */
export const FormInput = Input;
