import React from 'react';

const Time = ({ color = "#5FA2DD", x = "16", y = "16" }) => {
  return (
    <svg width={x} height={y} viewBox={`0 0 ${x} ${y}`}>
      <path fill={color} id="time" fillRule="evenodd" d="M0 7.726a7.726 7.726 0 1 1 15.452 0A7.726 7.726 0 0 1 0 7.726zm7.79 1.47h3.276a.534.534 0 1 0 0-1.07H8.26v-4.92a.534.534 0 1 0-1.069 0v5.455a.534.534 0 0 0 .554.533l.013-.002.015.002a.107.107 0 0 0 .018.001z" />
    </svg>
  )
}

export default Time;
