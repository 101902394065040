import React from 'react';

const Cursor = ({ color = '#fff', width, height, x = '16', y = '16' }) => {
  return (
    <svg width={width || x} height={height || y} viewBox={`0 0 ${x} ${y}`}>
      <path
        fill={color}
        stroke="null"
        id="cursor"
        d="m4.116425,14.959782c-0.199045,0 -0.447851,-0.049898 -0.646896,-0.099795c-0.746419,-0.249488 -1.293793,-0.997952 -1.293793,-1.796313l-0.298568,-10.029416c0,-0.698566 0.348329,-1.397133 0.945464,-1.746416c0.597135,-0.349283 1.393315,-0.349283 1.99045,0l8.558936,5.18935c0,0 0,0 0,0c0.696658,0.399181 1.044986,1.197542 0.895703,1.995904s-0.746419,1.44703 -1.542599,1.596723l-4.329229,0.848259l-2.886153,3.343139c-0.298568,0.449078 -0.845941,0.698566 -1.393315,0.698566zm-0.248806,-11.925525l0.298568,9.879723l3.035437,-3.542729c0.149284,-0.149693 0.348329,-0.299386 0.547374,-0.349283l4.578036,-0.898157l-8.459414,-5.089554z"
      />
    </svg>
  );
};

export default Cursor;
