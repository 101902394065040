import React, { createRef } from 'react';
import Tree, { renderers } from 'react-virtualized-tree';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { localStorage } from 'helpers/storage';

import { Cell, Row } from './styles';
import { KeyCodes } from 'constants/keyboard';
import { ContextMenuTrigger } from 'react-contextmenu';
import memoizeOne from 'memoize-one';
import { ContentNotFound } from 'components';
import styles from './styles.module.css';
import { getSelectedItemsByPressedKeys } from 'helpers';
import i18next from 'i18next';
import { showMenu } from 'react-contextmenu/modules/actions';
import { FILTER_TYPES } from 'components/Filters';
import { TreeHeader } from './TreeHeader';

const { Expandable } = renderers;
// Список классов, при клике на которые блокируются иные действия
const IGNORABLE_CLASS_NAMES = {
  'ant-table-row-expand-icon': true,
  'ant-input': true,
  'react-contextmenu-item': true,
  lm_drag_handle: true,
  'ant-checkbox-input': true
};

// Список классов, для обработки на пустое место дерева
const EMPTY_PLACE_CLASS_NAMES = {
  ReactVirtualized__Grid: true,
  ReactVirtualized__List: true
};

const EXPANDED_ICON_CLASS_NAME = 'ant-table-row-expand-icon';
const EXPAND_ICONS_CLASS_NAMES = {
  expanded: `${EXPANDED_ICON_CLASS_NAME} ant-table-row-expand-icon-expanded`,
  collapsed: `${EXPANDED_ICON_CLASS_NAME} ant-table-row-expand-icon-collapsed`,
  lastChild: 'tree-node-expand-icon'
};
const CELL_CLASS_NAME = 'cell-content';
const EXPAND_ALL = 'expandAllNodes';
const COLLAPSE_ALL = 'collapseAllNodes';
const COMPONENT_ID = 'tree';

const ROW_HEIGHT = 25;
const TABLE_COLUMN_MIN_WIDTH = 50;

const pressedKeys = new Set();

export const EVENT_INITIATOR = {
  NONE: '',
  COMPONENT_INITIALIZE: 'initialize',
  HANDLE: 'handle',
  SCROLLER: 'scroller',
  NEW_NODE: 'node'
};

/** Событие, которое должно выполнится при следующем обновлении компонента
 * Пока что используется только для инициализации клика по элементам дерева
 *  Может содержать поля:
 *  type -   по-умолчанию 'clickByItems'
 *  initiator - по-умолчанию EVENT_INITIATOR.NONE
 *  isFinished - по-умолчанию false
 */
let delayedEvent = { initiator: EVENT_INITIATOR.COMPONENT_INITIALIZE, isFinished: false };

/**
 * Древовидная таблица.
 * Компонент дерева требует stateful-компонент,
 * поэтому библиотечные компоненты должны находиться
 * в функции render.
 *
 * ВНИМАНИЕ: в данном компоненте не должен использоваться метод
 * shouldComponentUpdate, потому что ему зачастую передаются render-методы для отрисовки ячеек,
 * которые зависят от данных, не передаваемых в данный компонент и которые он не может учитывать
 * в силу своей общности. Чтобы выполнить перерисовку дерева по изменению неизвестных ему props,
 * родительский компонент должен их передать в явном виде. Реализация PureComponent выполнит
 * перерисовку по любому изменившемуся props.
 *
 * @param {String} uniqueKey - уникальный идентификатор дерева (по нему будут сохраняться параметры)
 * @property {Array} columns - колонки таблицы
 * @property {Array} nodes - дерево с полями:
 *  id - обязательное поле, должно быть числом. Со строками вроде работает, но в библиотеке есть проверка scrollToId на число;
 *  state: { expanded: true | false } - необязательное поле, управляет раскрытием узла
 * @property {Object} entitiesHash - хеш сущностей дерева
 * @property {Number, undefined} initialExpandDeep - исходная глубина развернутости (если не задано, то по умолчанию 3)
 * @property {Array} rowSelections - список идентификаторов выделенных узлов ([node.id])
 * @property {Function} onRowClick - обработчик клика по строке, аргумент - соответствующий строке узел (node)
 * @property {Function} onRowDoubleClick - обработчик двойного клика по строке, аргумент - соответствующий строке узел (node)
 * @property {Function} onArrowNavigationStopped - колбек, вызывающий диспатч в родительском компоненте при остановки навигации стрелками,
 * аргументом принимает id выбранного элемента (string)
 * @property {Function} onDelete - колбек, вызывающий в родительском компоненте логику удаления выбранного элемента
 * @property {Function} onCopy - колбек, вызывающий в родительском компоненте логику копирования выбранного элемента
 * @property {Function} onCut - колбек, вызывающий в родительском компоненте логику вырезания выбранного элемента
 * @property {Function} onPaste - колбек, вызывающий в родительском компоненте логику вставки выбранного элемента
 * @property {Boolean} isSaveSelectedItems - сохранять ли выделенные элементы
 * @property {Boolean} isSaveExpandedItems - сохранять ли состояние развернутых элементов
 * @property {Boolean} isMultipleSelection - разрешить ли множественное выделение
 * @property {Boolean} isSaveScrollPosition - разрешить ли сохранять позицию скрола
 * @property {Boolean} needToControlElements - разрешить ли контролировать состояние элементов самим компонентом дерева
 * @property {Boolean} isExtensibleTree - могут ли быть добавлены/удалены узлы дерева
 *  (выделение элементов, сохранение, состояний). Если false, то эта задача ложится на родителя
 * @property {Object} filters - фильтры для дерева устройств
 * @property {Boolean} scrollToAlignment - выравнивание элемента при автоматическом скроле
 */
export default class CustomTree extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onKeyDownTimeoutId = undefined;
    this.rowCounter = 0;
    this.isOnKeyDownProgress = false;
    this.expandedTree = [];
    this.treeWrapper = createRef();
    this.isCellEditing = false;
    this.leftScroll = 0;
    this.state = {
      nodes: [],
      selectedItems: [],
      LastSelectedItem: null,
      expandedItems: [],
      scrollToId: null,
      filters: null,
      counters: { allItems: 0, filtered: 0 }
    };
  }

  static propTypes = {
    uniqueKey: PropTypes.string,
    columns: PropTypes.array,
    nodes: PropTypes.array,
    initialExpandDeep: PropTypes.number,
    onRowClick: PropTypes.func,
    onRowDoubleClick: PropTypes.func,
    entitiesHash: PropTypes.object,
    contextMenuName: PropTypes.string,
    isSaveSelectedItems: PropTypes.bool,
    isSaveExpandedItems: PropTypes.bool,
    isMultipleSelection: PropTypes.bool,
    isSaveScrollPosition: PropTypes.bool,
    needToControlElements: PropTypes.bool,
    isExtensibleTree: PropTypes.bool,
    filters: PropTypes.array,
    scrollToAlignment: PropTypes.string
  };

  static defaultProps = {
    initialExpandDeep: 3,
    onRowClick: () => {},
    onRowDoubleClick: () => {},
    entitiesHash: {},
    contextMenuName: null,
    isSaveSelectedItems: false,
    isSaveExpandedItems: false,
    isMultipleSelection: false,
    isSaveScrollPosition: false,
    needToControlElements: false,
    isExtensibleTree: false,
    scrollToAlignment: 'center'
  };

  saveDataToStorage() {
    const {
      uniqueKey,
      isSaveExpandedItems,
      isSaveSelectedItems,
      needToControlElements
    } = this.props;
    const { selectedItems, expandedItems, filters, sizes } = this.state;
    const obj = {};
    if (filters) obj.filters = filters;
    if (!uniqueKey || !needToControlElements) return;
    obj.selectedItems = isSaveSelectedItems ? selectedItems : [];
    obj.expandedItems = isSaveExpandedItems ? expandedItems : [];
    obj.sizes = sizes && sizes;
    localStorage.setItem(uniqueKey, JSON.stringify(obj));
  }

  componentDidMount = () => {
    const counters = { allItems: 0, filtered: 0 };
    const {
      nodes,
      isSaveExpandedItems,
      needToControlElements,
      isSaveScrollPosition,
      entitiesHash,
      initialExpandDeep,
      columns
    } = this.props;
    delayedEvent = {
      initiator: EVENT_INITIATOR.COMPONENT_INITIALIZE,
      isFinished: false
    };
    const initData = getPreparedDataFromStorage(this.props, this.state);
    const scrollToId =
      isSaveScrollPosition && initData.selectedItems.length ? initData.selectedItems[0] : null;

    let updatedNodes = [];
    if (nodes && nodes.length) {
      const filters = prepareFilters(this.props.filters);
      const isInit =
        !initData.expandedItems.length || !isSaveExpandedItems || !needToControlElements;
      if (isInit) {
        updatedNodes = initNodes(
          nodes,
          0,
          initData.expandedItems,
          initialExpandDeep,
          filters,
          counters
        );
      } else {
        updatedNodes = updateNodes(
          this.state.nodes,
          nodes,
          initData.expandedItems,
          filters,
          counters
        );
      }
    }

    window.onbeforeunload = e => this.saveDataToStorage(); // Пробуем сохранить данные при перезагрузке страницы
    window.addEventListener('keydown', this.onKeyDownGlobal);
    window.addEventListener('keypress', this.onKeyPressGlobal);
    window.addEventListener('keyup', this.onKeyUpGlobal);
    window.addEventListener('click', this.clickOutOfTree);
    const element = document.getElementById(COMPONENT_ID);
    if (element) {
      element.addEventListener('contextmenu', this.onRightClick);
      element.addEventListener('click', this.onClickEmptyBlock);
    }
    const countSizes = initData.sizes ? initData.sizes : {};
    if (!initData.sizes) {
      let totalWidth = 0;
      const treeWidth = this.props.width;

      columns.forEach(({ key, width }, index) => {
        if (columns.length - 1 === index) {
          let widthDiff = treeWidth - totalWidth;
          if (widthDiff < TABLE_COLUMN_MIN_WIDTH) widthDiff = TABLE_COLUMN_MIN_WIDTH;
          countSizes[key] = { width: widthDiff };
          return;
        }
        const colWidth = width ? width : TABLE_COLUMN_MIN_WIDTH;
        countSizes[key] = {
          width: colWidth
        };
        totalWidth += colWidth;
      });
    }

    this.setState({
      _entitiesHash: entitiesHash,
      counters,
      scrollToId,
      nodes: updatedNodes,
      selectedItems: initData.selectedItems,
      expandedItems: initData.expandedItems,
      sizes: countSizes
    });
    this.tree = document.getElementsByClassName('ReactVirtualized__Grid ReactVirtualized__List')[0];

    if (this.tree) {
      this.leftScroll = this.tree.scrollLeft;
      this.tree.addEventListener('scroll', this.syncHeaderScroll);
    }

    // создаём список(массив) элементов для навигации с помощью стрелок
    this.expandedTree = this.expandTree();

    // делам фокус на дереве, если в localStorage есть запись о раннее выбранном элементе,
    // в этом случае, делает возможным сразу начать использовать навигацию по дереву устройств

    !this.isCellEditing &&
      initData.selectedItems.length &&
      this.treeWrapper &&
      this.treeWrapper.current &&
      this.treeWrapper.current.focus();
  };

  syncHeaderScroll = e => {
    if (this.leftScroll === this.tree.scrollLeft) return;
    this.leftScroll = this.tree.scrollLeft;
    if (this.props.innerRef.current?.style)
      this.props.innerRef.current.style.left = `${-this.leftScroll}px`;
  };

  onKeyPressGlobal = event => pressedKeys.add(event.keyCode);
  onKeyDownGlobal = event => pressedKeys.add(event.keyCode);
  onKeyUpGlobal = event => pressedKeys.delete(event.keyCode);
  onRightClick = event => {
    if (!Object.keys(EMPTY_PLACE_CLASS_NAMES).some(cName => event.target.classList.contains(cName)))
      return;
    const { pageX: clickX, pageY: clickY } = event;
    showMenu({
      position: { x: clickX, y: clickY },
      target: this.elem,
      id: this.props.contextMenuName
    });
  };

  onClickEmptyBlock = event => {
    if (!Object.keys(EMPTY_PLACE_CLASS_NAMES).some(cName => event.target.classList.contains(cName)))
      return;
    const { onRowClick } = this.props;
    onRowClick([], EVENT_INITIATOR.HANDLE);
    this.setState({ selectedItems: [] });
  };

  clickOutOfTree = e => {
    if (this.treeWrapper.current && this.props.treeWindow) {
      const withinTreeWindow = e.composedPath().includes(this.props.treeWindow.current);
      if (withinTreeWindow && !this.isCellEditing) {
        this.treeWrapper.current.focus();
        !this.props.isActiveWindow &&
          this.props.setIsActiveWindow &&
          this.props.setIsActiveWindow(true);
      } else {
        this.props.setIsActiveWindow && this.props.setIsActiveWindow(false);
      }
    }
  };

  onKeyDown = event => {
    if (this.isCellEditing) return;
    const { onArrowNavigationStopped, entitiesHash } = this.props;
    let { selectedItems, nodes, expandedItems } = this.state;
    const counters = { allItems: 0, filtered: 0 };
    const filters = { childChecked: undefined };
    switch (event.keyCode) {
      case KeyCodes.ARROW_DOWN:
      case KeyCodes.ARROW_UP:
      case KeyCodes.PAGE_UP:
      case KeyCodes.PAGE_DOWN: {
        event.preventDefault(); // предотвращает вертикальный скролл
        if (!this.isOnKeyDownProgress) {
          this.isOnKeyDownProgress = true;
          this.onKeyDownTimeoutId && clearTimeout(this.onKeyDownTimeoutId);

          const curSelectedItemKey = selectedItems.at(-1);
          const indexOfSelectedItem = this.expandedTree.indexOf(curSelectedItemKey);

          //  Вычисляем количество элементов, пропускаемое при нажатии Page down или Page up,
          //  оно же количество элементов после которых сработает скрол
          //  зависит от высоты окна дерева устройств
          //  предполагается, что это половина видимых устройств

          const numberOfSkippingElements =
            (this.treeWrapper.current &&
              Math.floor(this.treeWrapper.current.clientHeight / (ROW_HEIGHT * 2))) ||
            5;

          const nextItemIndex = this.getNextItemIndex(
            event.keyCode,
            numberOfSkippingElements,
            indexOfSelectedItem
          );

          const nextItem = this.expandedTree[nextItemIndex];
          switch (event.keyCode) {
            case KeyCodes.ARROW_DOWN:
            case KeyCodes.ARROW_UP: {
              if (event.keyCode === KeyCodes.ARROW_DOWN) this.rowCounter++;
              else this.rowCounter--;
              this.setState({ selectedItems: [nextItem] });

              if (this.rowCounter !== 0 && this.rowCounter % numberOfSkippingElements === 0) {
                this.setState({ scrollToId: nextItem });
                this.rowCounter = 0;
              } else if (nextItemIndex === 0 || nextItemIndex === this.expandedTree.length - 1) {
                this.setState({ scrollToId: nextItem });
              }
              break;
            }
            case KeyCodes.PAGE_DOWN:
            case KeyCodes.PAGE_UP: {
              this.setState({ selectedItems: [nextItem], scrollToId: nextItem });
              break;
            }
            default:
              break;
          }

          setTimeout(() => {
            this.isOnKeyDownProgress = false;
          }, 100);

          if (this.onKeyDownTimeoutId) {
            clearTimeout(this.onKeyDownTimeoutId);
          }

          this.onKeyDownTimeoutId = setTimeout(() => {
            onArrowNavigationStopped && onArrowNavigationStopped(entitiesHash[nextItem]);
          }, 300);
        }
        break;
      }
      case KeyCodes.ARROW_RIGHT: {
        event.preventDefault(); // предотвращает горизонтальный скролл
        if (!expandedItems.includes(selectedItems.at(-1))) {
          expandedItems.push(selectedItems.at(-1));
          const newNodes = updateNodes(nodes, nodes, expandedItems, filters, counters);
          this.setState({ nodes: newNodes, expandedItems });
          this.expandedTree = this.expandTree();
        }
        break;
      }
      case KeyCodes.ARROW_LEFT: {
        event.preventDefault(); // предотвращает горизонтальный скролл
        let newExpandedItems = [];
        if (expandedItems[0] === EXPAND_ALL) {
          this.expandedTree = this.expandTree();
          newExpandedItems = this.expandedTree.filter(item => selectedItems.at(-1) !== item);
          const newNodes = updateNodes(nodes, nodes, newExpandedItems, filters, counters);
          this.setState({ nodes: newNodes, expandedItems: newExpandedItems });
        } else {
          newExpandedItems = expandedItems.filter(item => selectedItems.at(-1) !== item);
          if (newExpandedItems.length !== expandedItems.length) {
            const newNodes = updateNodes(nodes, nodes, newExpandedItems, filters, counters);
            this.setState({ nodes: newNodes, expandedItems: newExpandedItems });
          }
        }
        break;
      }
      default:
        return;
    }
  };

  onKeyUp = event => {
    if (this.isCellEditing) return;
    const {
      onKeyUp,
      onArrowNavigationStopped,
      entitiesHash,
      onDelete,
      onCopy,
      onCut,
      onPaste
    } = this.props;
    switch (event.keyCode) {
      // ESC,HOME,END,DELETE, Ctrl(C,X,V) добавлены в onKeyUp для исключения необходимости
      // обрабатывать залипание клавиш т.к. нет функционала на зажатие этих клавиш

      case KeyCodes.ESC: {
        this.setState({ selectedItems: [] });
        break;
      }
      case KeyCodes.HOME: {
        const nextItem = this.expandedTree[0];
        this.setState({ selectedItems: [nextItem], scrollToId: nextItem });
        onArrowNavigationStopped && onArrowNavigationStopped(entitiesHash[nextItem]);
        break;
      }
      case KeyCodes.END: {
        const nextItem = this.expandedTree.at(-1);
        this.setState({ selectedItems: [nextItem], scrollToId: nextItem });
        onArrowNavigationStopped && onArrowNavigationStopped(entitiesHash[nextItem]);
        break;
      }
      case KeyCodes.DELETE: {
        if (onDelete) {
          const setNextFocusedItem = () => {
            this.treeWrapper.current.focus();
            this.onKeyDown({ preventDefault: () => {}, keyCode: KeyCodes.ARROW_UP });
          };
          onDelete(setNextFocusedItem);
        }
        break;
      }
      case KeyCodes.C: {
        onCopy && event.ctrlKey && onCopy();
        break;
      }
      case KeyCodes.X: {
        onCut && event.ctrlKey && onCut();
        break;
      }
      case KeyCodes.V: {
        onPaste && event.ctrlKey && onPaste();
        break;
      }
      default:
        return;
    }
    if (onKeyUp) onKeyUp(event);
  };

  onContextMenu = (e, rowData) => {
    if (!this.state.selectedItems.includes(rowData.id)) {
      this.clickByItem(e, rowData);
    }
  };

  clickByItem = (e, rowData) => {
    if (IGNORABLE_CLASS_NAMES[e.target.classList[0]]) return;
    if (rowData.unavailable) return;
    let selectedItems = this.state.selectedItems;
    const { isMultipleSelection, onRowClick, needToControlElements } = this.props;
    if (!needToControlElements) {
      onRowClick(rowData, EVENT_INITIATOR.HANDLE);
      return;
    }
    if (!rowData.id) return;
    if (typeof e.target.className === 'string' && e.target.className.includes('editable-cell')) {
      this.isCellEditing = true;
    } else {
      this.isCellEditing = false;
    }
    if (
      (!pressedKeys.has(KeyCodes.SHIFT) && !pressedKeys.has(KeyCodes.CTRL)) ||
      !isMultipleSelection
    ) {
      if (selectedItems.length !== 1 || selectedItems[0] !== rowData.id) {
        selectedItems = [rowData.id];
        delayedEvent.id = rowData.id;
      }
    } else {
      let nodes = this.state.nodes;
      if (pressedKeys.has(KeyCodes.SHIFT)) nodes = this.getSortedNodeListFromTree(nodes);
      selectedItems = getSelectedItemsByPressedKeys(
        nodes,
        selectedItems,
        this.lastSelectedItem,
        pressedKeys,
        rowData
      );
    }

    if (pressedKeys.has(KeyCodes.SHIFT) || pressedKeys.has(KeyCodes.CTRL)) {
      e.nativeEvent.preventDefault();
      e.nativeEvent.stopPropagation();
    }

    if (isEqual(selectedItems, this.state.selectedItems)) return;
    const nodes = this.getNodesByIds(selectedItems);
    if (isMultipleSelection) {
      onRowClick(nodes, EVENT_INITIATOR.HANDLE);
    } else {
      onRowClick(rowData, EVENT_INITIATOR.HANDLE);
    }

    this.lastSelectedItem = rowData.id;
    delayedEvent.initiator = EVENT_INITIATOR.HANDLE;
    this.setState({ selectedItems });
  };

  getNodesByIds(ids) {
    const { entitiesHash } = this.props;
    const nodes = [];
    if (!ids.length || !entitiesHash) return nodes;
    ids.forEach(id => {
      const node = entitiesHash[id];
      if (node) nodes.push(node);
    });
    return nodes;
  }

  getSortedNodeList(nodes, list = []) {
    for (const node of nodes) {
      list.push(node);
      if (node.children && node.children.length) {
        this.getSortedNodeList(node.children, list);
      }
    }
    return list;
  }

  getSortedNodeListFromTree = memoizeOne(nodes => this.getSortedNodeList(nodes));

  onDoubleClick = (e, rowData) => {
    if (IGNORABLE_CLASS_NAMES[e.target.classList[0]]) return;
    if (!rowData || rowData.unavailable) return;
    this.props.onRowDoubleClick(rowData);
  };

  handleChange = nodes => {
    const expandedItems = this.enumNodes(nodes);
    this.setState({ nodes, expandedItems });
  };

  enumNodes(nodes) {
    let expandedNodes = [];
    for (const node of nodes) {
      if (node.state && node.state.expanded === true) {
        expandedNodes.push(node.id);
      }
      if (node.children) {
        expandedNodes.push(...this.enumNodes(node.children));
      }
    }
    return expandedNodes;
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedItems, expandedItems, filters, counters, sizes } = this.state;
    const {
      setIsActiveWindow,
      isActiveWindow,
      width,
      columns,
      parentSelection,
      isInactiveTab
    } = this.props;
    if (!isInactiveTab && parentSelection && !parentSelection.length && selectedItems.length)
      this.setState({
        selectedItems: []
      });
    if (
      expandedItems.length !== prevState.expandedItems.length ||
      expandedItems.length === 1 || // сработает если expandedItems[0]==='expandAllNodes' или 'collapseAllNodes';
      counters.allItems === prevState.counters.allItems ||
      filters !== prevState.filters
    ) {
      this.expandedTree = this.expandTree();
    }
    if (
      !isActiveWindow &&
      prevState.selectedItems.length &&
      prevState.selectedItems.at(-1) !== selectedItems.at(-1)
    ) {
      setIsActiveWindow && setIsActiveWindow(true);
    } else if (!selectedItems.length && prevState.selectedItems.length) {
      setIsActiveWindow && setIsActiveWindow(false);
    } else if (selectedItems.length && !prevState.selectedItems.length) {
      setIsActiveWindow && setIsActiveWindow(true);
    }
    if (!delayedEvent.isFinished) {
      this.notifyComponentsAboutNewStorageDataIfNeed();
      this.initItemClick(selectedItems, delayedEvent.eventInitiator);
      delayedEvent.isFinished = true;
      delayedEvent.id = selectedItems[0] ? selectedItems[0] : '';
      this.lastSelectedItem = delayedEvent.id;
    }
    if (prevProps.width !== width) {
      const countSizes = {};
      let totalWidth = 0;
      const treeWidth = width;

      columns.forEach(({ key }, index) => {
        if (columns.length - 1 === index) {
          const diff = treeWidth - totalWidth;
          const lastWidth = diff < TABLE_COLUMN_MIN_WIDTH ? TABLE_COLUMN_MIN_WIDTH : diff;
          countSizes[key] = { width: lastWidth };
          return;
        }
        const colWidth = sizes[key] ? sizes[key].width : TABLE_COLUMN_MIN_WIDTH;
        countSizes[key] = {
          width: colWidth
        };
        totalWidth += colWidth;
      });
      this.setState({
        sizes: countSizes
      });
    }
  }

  notifyComponentsAboutNewStorageDataIfNeed() {
    if (delayedEvent.initiator === EVENT_INITIATOR.COMPONENT_INITIALIZE) {
      const { dataGotFromStorage } = this.props;
      if (dataGotFromStorage && delayedEvent.storageData) {
        dataGotFromStorage(delayedEvent.storageData);
        delayedEvent.storageData = null;
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    let { _entitiesHash, expandedItems, selectedItems, _scrollToId, counters } = state;
    const { entitiesHash, uniqueKey, initialExpandDeep } = props;
    const newState = {};
    const filters = prepareFilters(props.filters);

    if (entitiesHash !== _entitiesHash) {
      //В настоящий момент условие выполнится только при переводе из неактивного проекта в активный
      // при нажатии на кнопку "Активный проект", так как в данном случае компонент не пересоздается
      if (state._uniqueKey && uniqueKey !== state._uniqueKey) {
        delayedEvent = { initiator: EVENT_INITIATOR.COMPONENT_INITIALIZE };
        const initData = getPreparedDataFromStorage(props, state);
        expandedItems = initData.expandedItems;
        selectedItems = initData.selectedItems;
        selectedItems = initData.sizes;
        Object.assign(newState, initData);
      }
      clearCounters(counters);
      //prettier-ignore
      const data = calculateNodesDifferences(
        props,
        state,
        selectedItems,
        expandedItems,
        filters,
        counters
      );
      expandedItems = data.expandedItems;
      Object.assign(newState, data);
    } else if (props.filters && filters !== state.filters) {
      clearCounters(counters);
      const nodes = updateNodes(props.nodes, props.nodes, expandedItems, filters, counters);
      Object.assign(newState, { nodes });
    } else if (props.nodes !== state._nodes) {
      clearCounters(counters);
      // Так как некоторые компоненты сами управляют состоянием и кол-вом нод
      newState.nodes = initNodes(
        props.nodes,
        0,
        expandedItems,
        initialExpandDeep,
        filters,
        counters
      );
    }
    //Управление скролом
    if (isScrollIdChangedByParent(props, state)) {
      Object.assign(newState, getScrollToIdInfo(props, state, expandedItems, filters, counters));
      delayedEvent.id = '';
    } else if (!newState.scrollToId) {
      //Сбрасываем позицию скрола при следующем обновлении, чтобы не было внезапных прыжков к текущему элементу впоследствии
      if (state.scrollToId && state.scrollToId === _scrollToId) newState.scrollToId = null;
      else newState.scrollToId = state.scrollToId;
    }

    newState.filters = filters;
    //Сохраняем ссылки на объекты для сравнения
    newState._scrollToId = newState.scrollToId;
    newState._propsScrollToId = props.scrollToId;
    newState._entitiesHash = entitiesHash;
    newState._nodes = props.nodes;
    newState._uniqueKey = uniqueKey;
    newState.counters = counters;
    return newState;
  }

  checkNewEntities(newList, oldEntitiesHash) {
    return newList.filter(e => !oldEntitiesHash[e.id]);
  }

  initItemClick(selectedItems, eventInitiator) {
    const { needToControlElements, isMultipleSelection } = this.props;
    if (needToControlElements) {
      let sendElements = [];
      const nodes = this.getNodesByIds(selectedItems);
      if (nodes.length > 0) {
        sendElements = isMultipleSelection ? nodes : nodes[0];
      }
      /* Почему-то если инициализировать сразу отправку выбранного ус-ва, то иногда возникает глюк
        при перезагрузке страницы, когда дерево схлопывается в 1 элемент, который был выделен ранее,
        так же это поведение вызывает ошибки у других компонентов */
      setTimeout(() => {
        this.props.onRowClick(sendElements, eventInitiator);
      }, 100);
    }
  }

  componentWillUnmount = () => {
    this.saveDataToStorage();
    window.removeEventListener('keydown', this.onKeyDownGlobal);
    window.removeEventListener('keypress', this.onKeyPressGlobal);
    window.removeEventListener('keyup', this.onKeyUpGlobal);
    window.removeEventListener('click', this.clickOutOfTree);
    pressedKeys.clear();
  };

  getRowClassName = node => {
    const { selectedItems } = this.state;
    const { rowSelections, needToControlElements } = this.props;
    const items = needToControlElements ? selectedItems : rowSelections ? rowSelections : [];
    if (node.unavailable) return styles.unavailable_row;
    return items.includes(node.id)
      ? this.props.isActiveWindow
        ? styles.selected_row_active
        : styles.selected_row
      : '';
  };

  collapseAllNodes = () => {
    const { filters, counters } = this.state;
    const { initialExpandDeep } = this.props;

    clearCounters(counters);
    this.setState({
      nodes: initNodes(this.props.nodes, 0, [COLLAPSE_ALL], initialExpandDeep, filters, counters),
      expandedItems: [COLLAPSE_ALL],
      counters
    });
    this.props.setIsActiveWindow && this.props.setIsActiveWindow(true);
  };

  expandAllNodes = () => {
    const { nodes, filters, counters } = this.state;
    clearCounters(counters);
    this.setState({
      nodes: updateNodes(nodes, this.props.nodes, [EXPAND_ALL], filters, counters),
      expandedItems: [EXPAND_ALL],
      counters
    });
    this.props.setIsActiveWindow && this.props.setIsActiveWindow(true);
  };

  RowRenderer = props => {
    const { node, width, minRowWidth } = props;
    const { columns, entitiesHash } = this.props;
    const { sizes } = this.state;
    return (
      <Row
        key={node.id}
        width={width}
        rowWidth={minRowWidth}
        entitiesHash={entitiesHash}
        node={node}
        onClick={e => this.clickByItem(e, node)}
        onContextMenu={e => this.onContextMenu(e, node)}
        onDoubleClick={e => this.onDoubleClick(e, node)}
        className={this.getRowClassName(node)}
        steps={node.deepness}
      >
        {(rowData, treeItem) => {
          return columns.map((col, index) => (
            <Cell
              key={col.key}
              width={sizes[col.key].width}
              hasExpandedIcon={treeItem.children && treeItem.children.length}
              steps={index === 0 ? node.deepness : null}
            >
              <div
                style={{ width: `${sizes[col.key].width}px` || '100%' }}
                className={CELL_CLASS_NAME}
              >
                {index === 0 ? (
                  <Expandable node={node} {...props} iconsClassNameMap={EXPAND_ICONS_CLASS_NAMES}>
                    {this.getRenderData(col, rowData, treeItem)}
                  </Expandable>
                ) : (
                  this.getRenderData(col, rowData, treeItem)
                )}
              </div>
            </Cell>
          ));
        }}
      </Row>
    );
  };

  getRenderData(col, node, treeItem) {
    return col.render ? col.render(node[col.dataIndex], node, treeItem, col) : node[col.dataIndex];
  }

  getTreeHeight = () => {
    const { menuStyles } = this.props;
    return `calc(100% ${menuStyles && menuStyles.height ? menuStyles.height : ROW_HEIGHT + 'px'})`;
  };

  expandTree = () => {
    const { expandedItems, nodes } = this.state;
    if (!(expandedItems && nodes?.length)) return [];
    const expandedTree = [];
    nodes.forEach(node =>
      (function expander(node) {
        if (!node.unavailable) expandedTree.push(node.id);
        if (expandedItems[0] === EXPAND_ALL && node.children && node.children.length) {
          node.children.forEach(child => {
            expander(child);
          });
        } else if (node.children && node.children.length && expandedItems.includes(node.id)) {
          node.children.forEach(child => {
            expander(child);
          });
        }
      })(node)
    );
    return expandedTree;
  };

  getNextItemIndex = (keyCode, numberOfSkippingElements, indexOfSelectedItem) => {
    let stepSize = 1;
    let nextItemIndex = 0;

    if (keyCode === KeyCodes.ARROW_DOWN || keyCode === KeyCodes.PAGE_DOWN) {
      if (keyCode === KeyCodes.PAGE_DOWN) {
        stepSize = numberOfSkippingElements;
      }
      if (indexOfSelectedItem + stepSize < this.expandedTree.length) {
        nextItemIndex = indexOfSelectedItem + stepSize;
      } else if (indexOfSelectedItem === this.expandedTree.length - 1) {
        nextItemIndex = 0;
      } else {
        nextItemIndex = this.expandedTree.length - 1;
      }
    } else {
      if (keyCode === KeyCodes.ARROW_UP) {
        stepSize = -1;
      } else {
        stepSize = -numberOfSkippingElements;
      }
      if (indexOfSelectedItem === 0) {
        nextItemIndex = this.expandedTree.length - 1;
      } else if (indexOfSelectedItem + stepSize < 0) {
        nextItemIndex = 0;
      } else {
        nextItemIndex = indexOfSelectedItem + stepSize;
      }
    }
    return nextItemIndex;
  };

  render() {
    const { nodes, scrollToId, filters, counters, sizes } = this.state;
    const { columns } = this.props;
    if (!nodes.length) {
      const text = i18next.t(
        filters.length ? 'messages.noItemsFoundByFilters' : 'messages.noItemsFound'
      );
      return <ContentNotFound text={text} />;
    }
    const minRowWidth = columns.reduce((prev, next) => {
      const _next = next.width ? next.width : TABLE_COLUMN_MIN_WIDTH;
      return prev + _next;
    }, 0);
    const { contextMenuName, width, scrollToAlignment } = this.props;
    const RowRenderer = this.RowRenderer;
    let dragToolsHeight = ROW_HEIGHT;
    if (this.treeWrapper.current?.scrollHeight) {
      const scrollHeight = this.treeWrapper.current.scrollHeight;
      dragToolsHeight =
        counters.allItems * ROW_HEIGHT < scrollHeight
          ? counters.allItems * ROW_HEIGHT + ROW_HEIGHT
          : scrollHeight;
    }
    return (
      <div
        className={styles.wrapper}
        onKeyDown={this.onKeyDown}
        onKeyUp={this.onKeyUp}
        onKeyPress={this.props.onKeyPress}
        id={COMPONENT_ID}
      >
        <div
          ref={this.treeWrapper}
          tabIndex={0}
          className={styles.tree}
          style={{ height: this.getTreeHeight() }}
        >
          <TreeHeader
            innerRef={this.props.innerRef}
            minRowWidth={minRowWidth}
            onSizeChanged={(
              currentColumnKey,
              nextColumnKey,
              draggingRange,
              currentColumnWidth,
              nextColumnWidth
            ) => {
              const newSizes = { ...sizes };
              newSizes[currentColumnKey].width = currentColumnWidth + draggingRange;
              newSizes[nextColumnKey].width = nextColumnWidth - draggingRange;
              this.setState({ sizes: newSizes });
            }}
            columns={columns}
            sizes={sizes}
            height={dragToolsHeight}
            minColumnWidth={TABLE_COLUMN_MIN_WIDTH}
          />
          <Tree
            classNames={styles.tree}
            nodes={nodes}
            onChange={this.handleChange}
            scrollToId={scrollToId}
            width={width}
            scrollToAlignment={scrollToAlignment}
          >
            {({ style, node, ...rest }) => (
              <div style={style} className={styles.row}>
                {contextMenuName ? (
                  <ContextMenuTrigger
                    holdToDisplay={-1}
                    record={node}
                    treeItem={node}
                    collect={props => props}
                    disable={node.unavailable}
                    id={contextMenuName}
                  >
                    <RowRenderer node={node} width={width} minRowWidth={minRowWidth} {...rest} />
                  </ContextMenuTrigger>
                ) : (
                  <RowRenderer
                    key={node.id}
                    node={node}
                    width={width}
                    minRowWidth={minRowWidth}
                    {...rest}
                  />
                )}
              </div>
            )}
          </Tree>
        </div>
        <Footer counters={counters} />
      </div>
    );
  }
}

function Footer({ counters = {} }) {
  return (
    <div className={styles.footer}>
      <span>{`${i18next.t('totalItems')}:  ${counters.allItems}`}</span>
      <span>{`${
        counters.allItems !== counters.filtered
          ? `${i18next.t('filtered')}: ${counters.filtered}`
          : ''
      }`}</span>
    </div>
  );
}

function getPreparedDataFromStorage(props, state) {
  const { rowSelections, needToControlElements } = props;
  let { selectedItems, expandedItems, sizes } = state;
  const storageData = getDataFromStorage(props);

  // Кладем данные в объект события, чтобы передать их потом компонентам на верхний уровень в didUpdate
  delayedEvent.storageData = storageData;

  if (!needToControlElements) return { selectedItems, expandedItems };
  if (rowSelections && rowSelections.length) {
    selectedItems.push(...rowSelections);
  } else {
    selectedItems = storageData.selectedItems;
  }
  expandedItems = storageData.expandedItems;
  sizes = storageData.sizes;
  return { selectedItems, expandedItems, sizes };
}

function getDataFromStorage(props) {
  const { uniqueKey } = props;
  if (!uniqueKey) return { selectedItems: [], expandedItems: [] };
  const item = localStorage.getItem(uniqueKey);
  let obj = item ? JSON.parse(item) : {};
  if (!(obj instanceof Object)) obj = {};
  if (!obj.selectedItems || !obj.selectedItems.length) obj.selectedItems = [];
  if (!obj.expandedItems || !obj.expandedItems.length) obj.expandedItems = [];
  if (!obj.filters || !obj.filters.length) obj.filters = [];
  return obj;
}

function isScrollIdChangedByParent(props, state) {
  return (
    props.scrollToId &&
    state._propsScrollToId !== props.scrollToId &&
    delayedEvent.id !== props.scrollToId
  );
}

function getScrollToIdInfo(props, state, expandedItems, filters, counters) {
  const { entitiesHash, scrollToId, nodes } = props;
  const parentsIds = getParentsIds(scrollToId, entitiesHash);
  // Реализован фильтр для добавления только отсутствующих йд родителей в expandedItems,
  // чтобы исключить добавление уже существующий йд в expandedItems при каждом рендере
  const filteredParents = parentsIds.length
    ? parentsIds.filter(expandedItem => !expandedItems.includes(expandedItem))
    : [];
  const expanded = [...expandedItems, ...filteredParents];
  delayedEvent = {
    initiator: EVENT_INITIATOR.SCROLLER
  };
  const prevNodes = filters && filters.length ? props.nodes : state.nodes;
  clearCounters(counters);
  return {
    nodes: updateNodes(prevNodes, nodes, expanded, filters, counters),
    scrollToId,
    expandedItems: expanded,
    selectedItems: [scrollToId]
  };
}

function getParentsIds(nodeId, nodeHash, parentIds = []) {
  const node = nodeHash[nodeId];
  if (node && node.parentDeviceId) {
    parentIds.push(node.parentDeviceId);
    return getParentsIds(node.parentDeviceId, nodeHash, parentIds);
  } else return parentIds;
}

/**
 * Инициализация элементов дерева
 * @param {Array|null} nodes - Узлы дерева
 * @param {Number|undefined|null} deep - Текущий уровень дерева
 * @param {Array|undefined} expandedRows - Список ключей дерева, которые нужно раскрыть
 * @param {Number} initialExpandDeep - глубина дерева, до которой нужно раскрыть элементы
 * @param {Array|undefined} filters - фильтры дерева
 * @param {Object|undefined} counters - счетчики для статистики
 **/
function initNodes(nodes, deep = 0, expandedRows = [], initialExpandDeep = 0, filters, counters) {
  const newNodes = [];
  if (deep === 0) filters.childChecked = undefined;
  //Флаг, показывающий, удовлетворяет ли хотя бы один из потомков условиям фильтров
  let childChecked = false;
  nodes.forEach(node => {
    filters.childChecked = false;
    counters.allItems = counters.allItems + 1;
    if (node.children && node.children.length) {
      if (
        deep < initialExpandDeep ||
        expandedRows[0] === COLLAPSE_ALL ||
        expandedRows.includes(node.key)
      ) {
        const expanded = expandedRows[0] !== COLLAPSE_ALL;
        if (expanded) expandedRows.push(node.id);
        node = {
          ...node,
          state: { expanded },
          //prettier-ignore
          children: initNodes(node.children, 1 + deep, expandedRows, initialExpandDeep, filters, counters)
        };
      }
    }

    const currentChecked = checkWithFilters(node, filters);
    // Проверяем соответствие фильтрам текущего элемента, родителя и потомков
    if (currentChecked || filters.childChecked === true) childChecked = true;
    if ((filters.childChecked === false || !node.children) && !currentChecked) {
      return;
    }

    counters.filtered = counters.filtered + 1;
    newNodes.push(node);
  });
  filters.childChecked = deep ? childChecked : undefined;
  return newNodes;
}

/**
 * Обновление элементов дерева
 * @param {Array|null} prev - Прошлое состояние дерева
 * @param {Array|null} next - Новое состояние дерева
 * @param {Array|undefined} expandedRows - Список ключей дерева, которые нужно раскрыть
 * @param {Array|undefined} filters - фильтры дерева
 * @param {Object|undefined} counters - счетчики для статистики
 **/
function updateNodes(prev, next, expandedRows = [], filters, counters) {
  const newNodes = [];
  const isRoot = filters.childChecked === undefined;
  filters.childChecked = false;

  //Флаг, показывающий, удовлетворяет ли хотя бы один из потомков условиям фильтров
  let childChecked = false;
  next.forEach((node, index) => {
    const updatedNode =
      prev[index] && prev[index].id === node.id
        ? prev[index]
        : prev.find(prevNode => prevNode.id === node.id);
    let children = [];
    if (updatedNode) {
      const newNode = { ...updatedNode, ...node };
      const currentChecked = checkWithFilters(newNode, filters);
      if (node.children) {
        //prettier-ignore
        children = updateNodes(updatedNode.children || [], node.children, expandedRows, filters, counters);
      }
      newNode.children = children;

      // Проверяем соответствие фильтрам текущего элемента, родителя и потомков
      if (currentChecked || filters.childChecked === true) childChecked = true;
      if ((filters.childChecked === false || !node.children) && !currentChecked) {
        return;
      }

      if (expandedRows && expandedRows[0] === EXPAND_ALL) newNode.state = { expanded: true };
      else if (expandedRows.includes(newNode.id)) newNode.state = { expanded: true };
      else newNode.state = { expanded: false };

      if (currentChecked) counters.filtered = counters.filtered + 1;
      counters.allItems = counters.allItems + 1;
      newNodes.push(newNode);
    } else {
      const currentChecked = checkWithFilters(node, filters);
      let haveNew = false;
      //если есть новые элементы, то раскрываем их
      if (expandedRows.includes(node.id)) haveNew = true;
      if (node.children)
        children = updateNodes([], node.children || [], expandedRows, filters, counters);

      // Проверяем соответствие фильтрам текущего элемента, родителя и потомков
      if (currentChecked || filters.childChecked === true) childChecked = true;
      if ((filters.childChecked === false || !node.children) && !currentChecked) {
        return;
      }

      if (currentChecked) counters.filtered = counters.filtered + 1;
      counters.allItems = counters.allItems + 1;
      newNodes.push({ ...node, state: { expanded: haveNew }, children });
    }
  });
  filters.childChecked = isRoot ? undefined : childChecked;
  return newNodes;
}

/**
 * Возвращает только те объекты, в которых есть активные фильтры
 */
const prepareFilters = memoizeOne(filters => {
  if (!filters || !filters.length) return [];
  return filters.filter(f => (f.active && f.active.length) || f.type === FILTER_TYPES.CHECKBOX);
});

function checkWithFilters(node, filters) {
  if (!filters || !filters.length) return true;
  for (const filter of filters) {
    if (!filter.check || !filter.check(filter, node)) return false;
  }
  return true;
}

function recalculateSelectedItems(entitiesHash, selectedItems) {
  const newItems = [];
  let needUpdate = false;
  if (Array.isArray(selectedItems))
    selectedItems.forEach(item => {
      if (entitiesHash[item]) newItems.push(item);
      else needUpdate = true;
    });
  return { items: newItems, needUpdate };
}

/**
 * Проверяет изменения в узлах дерева
 * Если появились новые элементы, то раскрывает их, при необходимости
 * Если исчезли некоторые элементы, то чистит выделенные ids
 */
function calculateNodesDifferences(props, state, selectedItems, expandedItems, filters, counters) {
  const { nodes, _entitiesHash } = state;
  const { entitiesHash, isExtensibleTree } = props;
  let newEntities = [];
  if (isExtensibleTree && _entitiesHash)
    for (const key in entitiesHash) {
      if (entitiesHash.hasOwnProperty(key)) {
        const currentDev = entitiesHash[key];
        const prevDev = _entitiesHash[currentDev.id];
        if (!prevDev) newEntities.push(currentDev);
      }
    }
  let { items, needUpdate } = recalculateSelectedItems(entitiesHash, selectedItems);
  const newExpandedItems = [...expandedItems];
  if (newEntities.length > 0) {
    items = [newEntities[0].id]; // Выделяем первый из добавленных элементов
    needUpdate = true;
    const ids = newEntities.map(e => e.id);
    const filtered = newEntities.filter(e => !ids.includes(e.parentDeviceId));
    if (filtered[0]?.parentDeviceId) newExpandedItems.push(filtered[0].parentDeviceId); // раскрываем родителя
    if (filtered.length === 1) newExpandedItems.push(filtered[0].id);
  }
  if (needUpdate) delayedEvent = { initiator: EVENT_INITIATOR.NEW_NODE };
  const prevNodes = filters && filters.length ? props.nodes : nodes;
  clearCounters(counters);
  return {
    nodes: updateNodes(prevNodes, props.nodes, newExpandedItems, filters, counters),
    selectedItems: items,
    scrollToId: newEntities.length && items[0] ? items[0] : null,
    expandedItems: newExpandedItems
  };
}

function clearCounters(counters) {
  counters.allItems = 0;
  counters.filtered = 0;
}
