import { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActiveRegionsHashType } from 'backendToTSTypes/AggregatedActiveRegionView';
import { ActiveDevicesHashType } from 'backendToTSTypes/TreeItemDeviceView';
import { getActiveDevicesHash } from 'helpers/activeProject';
import {
  EntitiesByPlanIdMapType,
  ScenariosListByEntitiesIdMapType
} from 'reducers/activeProject/CPIU';
import { setEntitiesByPlanId } from 'actions/CPIU';

const useEntitiesByPlanId = (activeProjectId: string | null) => {
  const entitiesMaps = useSelector(
    (state: any) => state.activeProject.CPIU.logicEntities
  ) as ScenariosListByEntitiesIdMapType;
  const regions = useSelector((state: any) => state.activeProject.regions) as ActiveRegionsHashType;
  const devices = useSelector((state: any) => getActiveDevicesHash(state)) as ActiveDevicesHashType;
  const dispatch = useDispatch();
  const isLoaded = useRef(false);

  useEffect(() => {
    if (!activeProjectId) {
      if (!isLoaded.current) return;
      isLoaded.current = false;
      return;
    }
    if (isLoaded.current || !entitiesMaps || !devices || !regions) return;

    const entitiesByPlanIdsMap: EntitiesByPlanIdMapType = {};
    const { devicesByScenariosMap, regionsByScenariosMap } = entitiesMaps;

    Object.keys(devicesByScenariosMap).forEach(deviceId => {
      devices[deviceId].planLayouts.forEach(planLayout => {
        if (entitiesByPlanIdsMap[planLayout.planId]?.devicesIds)
          entitiesByPlanIdsMap[planLayout.planId].devicesIds.push(deviceId);
        else entitiesByPlanIdsMap[planLayout.planId] = { devicesIds: [deviceId], regionsIds: [] };
      });
    });

    Object.keys(regionsByScenariosMap).forEach(regionId => {
      regions[regionId].planLayouts.forEach(planLayout => {
        if (entitiesByPlanIdsMap[planLayout.planId]?.regionsIds)
          entitiesByPlanIdsMap[planLayout.planId].regionsIds.push(regionId);
        else
          entitiesByPlanIdsMap[planLayout.planId] = {
            devicesIds: [],
            regionsIds: [regionId]
          };
      });
    });
    dispatch(setEntitiesByPlanId(entitiesByPlanIdsMap));
    isLoaded.current = true;
  });
};

export default useEntitiesByPlanId;
