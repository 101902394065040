import fetch from 'isomorphic-fetch';
import { restApi } from '../helpers/fetch';
import { takeEvery } from 'redux-saga/effects';
import { SERVER_TIME_LOAD } from '../constants/actionTypeModules/other';
import { dispatchSuccess } from 'helpers/request';

function* loadServerTime() {
  const currentTimeMs = yield fetch(restApi + `/system_info/time/`, {
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' }
  })
    .then(response => {
      if (response.ok) {
        if (response.redirected) throw Error('Redirected');
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.indexOf('application/json') !== -1) {
          return response.json().then(json => json);
        }
      }
    })
    .catch(() => {
      //Если прыопала связь с сервером, то перезагружаем страницу один раз
      //Для отслеживания ошибок типа net::ERR_CERT_AUTHORITY_INVALID
      window.location.reload();
    });
  yield dispatchSuccess(SERVER_TIME_LOAD, currentTimeMs);
}

export default function* systemInfo() {
  yield takeEvery(SERVER_TIME_LOAD, loadServerTime);
}
