import { PROJECT_UPDATE_STATUS, PROJECT_SYNC_STATUS, isLoaded } from 'constants/actionTypes';
const STORAGE_KEY = 'redux-tab-sync';
const sourceId = Math.floor(Math.random() * 10000);

// Список экшенов, данные которых синхронизируются между окнами браузера
const syncedActionTypes = {
  [isLoaded(PROJECT_UPDATE_STATUS, true)]: PROJECT_SYNC_STATUS
};

function wrapAction(action) {
  return {
    action,
    sourceId,
    time: Date.now()
  };
}

export function storageMiddleware() {
  return () => next => action => {
    if (syncedActionTypes[action.type]) {
      const newAction = { type: syncedActionTypes[action.type] };
      switch (action.type) {
        case isLoaded(PROJECT_UPDATE_STATUS, true): {
          if (!action.payload.sync) {
            newAction.payload = action.payload.project.id;
          }
          break;
        }
        default: {
          break;
        }
      }
      if (newAction.payload) {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(wrapAction(newAction)));
      }
    }

    next(action);
  };
}

export function createOnStorage(store) {
  return () => {
    const wrappedAction = JSON.parse(localStorage.getItem(STORAGE_KEY));
    if (wrappedAction && wrappedAction.sourceId !== sourceId) {
      store.dispatch(wrappedAction.action);
    }
  };
}
