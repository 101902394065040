import React, { Component } from 'react';

import Menu from 'components/Menu';

import UserInfo from './UserInfo';

import UserTime from 'components/UserTime';
import HeaderNav from 'containers/Navs/HeaderNav';
import AppInfo from 'containers/AppInfo';
import SoundSystemNotifications from 'containers/SoundSystemNotifications';
import StorageController from './StorageController';

import * as s from './styles';
import EventsNotifier from './EventsNotifier';
import CountdownWidget from 'containers/Widgets/CountdownWidget';
import Manual from 'containers/Header/Manual';
import Certificate from 'containers/Header/Certificate';

export default class Header extends Component {
  render() {
    return (
      <s.HeaderWrapper>
        <Menu isActive={true} color="#25374B">
          <s.HeaderLeft>
            <HeaderNav />
          </s.HeaderLeft>
          <s.HeaderRight>
            <AppInfo />
            <UserTime />
            <SoundSystemNotifications />
            <Manual />
            <Certificate />
            <EventsNotifier />
            <CountdownWidget />
            <StorageController />
            <UserInfo />
          </s.HeaderRight>
        </Menu>
      </s.HeaderWrapper>
    );
  }
}
