import { isLoaded, SERVER_TIME_LOAD } from '../constants/actionTypes';

export default function systemInfo(state = { serverTime: 0, serverAndClientTimeDelta: 0 }, action) {
  switch (action.type) {
    case isLoaded(SERVER_TIME_LOAD, true): {
      const serverTime = action.payload;
      return { serverTime, serverAndClientTimeDelta: Date.now() - serverTime };
    }
    default:
      return state;
  }
}
