import React from 'react';

const Virtual = ({ color = "#5FA2DD", x = "19", y = "18" }) => {
  return (
    <svg width={x} height={y} viewBox={`0 0 ${x} ${y}`}>
      <path fill={color} id="virtual" fillRule="evenodd" d="M3 0a3 3 0 0 0-3 3v11.05a3 3 0 0 0 3 3h11.05a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3zm9.803 10.128V6.92h-2.695V4.26H6.942v2.66H4.246v3.208h2.696v2.696h3.166v-2.696h2.695z"/>
    </svg>
  )
}

export default Virtual;