import {
  PROJECT_LOAD_ACTIVE,
  PROJECT_UPDATE_STATUS,
  PLANS_LOAD_ACTIVE,
  PROJECT_LOAD_CURRENT,
  PLAN_FIND_DEVICE,
  isLoaded,
  PLAN_SELECTED
} from 'constants/actionTypes';

export default function plans(state = null, action) {
  switch (action.type) {
    case isLoaded(PLANS_LOAD_ACTIVE, true): {
      const plans = action.payload;
      const newState = {};
      plans.forEach(plan => {
        newState[plan.id] = plan;
      });
      return newState;
    }
    case isLoaded(PROJECT_LOAD_ACTIVE, true): {
      const { plans } = action.payload;
      const newState = {};
      plans.forEach(plan => {
        newState[plan.id] = plan;
      });
      return newState;
    }
    case isLoaded(PROJECT_LOAD_CURRENT, true): {
      const { project, plans } = action.payload;
      if (project.status === 'ACTIVE') {
        const newState = {};
        plans.forEach(plan => {
          newState[plan.id] = plan;
        });
        return newState;
      } else {
        return state;
      }
    }
    case isLoaded(PROJECT_UPDATE_STATUS, true): {
      const { project, plans } = action.payload;
      if (project.status === 'ACTIVE') {
        const newState = {};
        plans.forEach(plan => {
          newState[plan.id] = plan;
        });
        return newState;
      } else {
        return null;
      }
    }
    default:
      return state;
  }
}

export function findDeviceOnPlan(state = null, { type, payload }) {
  switch (type) {
    case PLAN_FIND_DEVICE: {
      return payload;
    }
    default:
      return state;
  }
}

export function selectedPlanId(state = '', { type, payload }) {
  switch (type) {
    case PLAN_SELECTED: {
      return payload;
    }
    default:
      return state;
  }
}
