import React from 'react';

const Polygon = ({ color = '#fff', width, height, x = '16', y = '16' }) => {
  return (
    <svg width={width || x} height={height || y} viewBox={`0 0 ${x} ${y}`}>
      <g>
        <path
          fill={color}
          stroke="null"
          id="polygon"
          d="m12.707545,14.992138l-9.042418,0l-2.793301,-8.612679l7.305557,-5.318016l7.323463,5.318016l-2.793301,8.612679zm-7.753202,-1.790578l6.44608,0l1.987541,-6.123776l-5.210581,-3.796025l-5.210581,3.796025l1.987541,6.123776z"
        />
        <text
          xmlns="http://www.w3.org/2000/svg"
          textAnchor="start"
          fontFamily="Helvetica, Arial, sans-serif"
          fontSize="11"
          id="polygon_text"
          y="11.814903"
          x="5.5"
          fillOpacity="null"
          strokeOpacity="null"
          strokeWidth="0"
          stroke={color}
          fill={color}
        >
          z
        </text>
      </g>
    </svg>
  );
};

export default Polygon;
