import { createSelector } from 'reselect';
import { getActiveDeviceList, getActiveRegions, getActiveProjectId } from './activeProject';
import { createTreeItem } from 'helpers/tree';
import { ENTITY_TYPE } from 'constants/skud';
import { getDeviceProfileViewsHash } from './deviceProfileViews';
import SUBSYSTEMS from 'constants/subsystems';

const numProto = Number;
numProto.prototype.toTimeFormatString = function() {
  const str = this.toString();
  return str.length === 2 ? str : '0' + str;
};

/**
 * Получить хеш сотрудников активного проекта
 *
 * @param {Object} state
 * @return {Object, null}
 */
export const getActiveEmployeesHash = createSelector(
  getActiveProjectId,
  ({ skud: { employees } }) => employees,
  (projectId, employees) => (projectId ? employees[projectId] : null)
);

/**
 * Получить хеш ключей доступа активного проекта
 *
 * @param {Object} state
 * @return {Object, null}
 */
export const getActiveAccessKeysHash = createSelector(
  getActiveProjectId,
  ({ skud: { accessKeys } }) => accessKeys,
  (projectId, accessKeys) => (projectId ? accessKeys[projectId] : null)
);

/**
 * Получить список устройств - точек доступа активного проекта
 * @param {Object} state
 * @return {Array}
 */
export const getActiveAccessPointDevices = createSelector(
  getDeviceProfileViewsHash,
  getActiveDeviceList,
  (deviceProfileViewsHash, activeDeviceList) =>
    deviceProfileViewsHash && activeDeviceList
      ? activeDeviceList.filter(
          device =>
            deviceProfileViewsHash[device.deviceProfileId] &&
            deviceProfileViewsHash[device.deviceProfileId].deviceProfile.accessPoint
        )
      : []
);

/**
 * Получить список СКУД-зон активного проекта
 * @param {Object} state
 * @return {Array}
 */
//prettier-ignore
export const getActiveSkudRegions = createSelector(
  getActiveRegions,
  regions => regions.filter(region => region.subsystem === SUBSYSTEMS.SKUD.id)
);

/**
 * Получить хеш графиков работы активного проекта
 *
 * @param {Object} state
 * @return {Object,null}
 */
export const getActiveWorkSchedulesHash = createSelector(
  getActiveProjectId,
  ({ skud: { workSchedules } }) => workSchedules,
  (activeProjectId, workSchedules) => (activeProjectId ? workSchedules[activeProjectId] : null)
);

/**
 * Создать идентификатор рабочего дня и элемент дерева.
 *
 * @param {String} workScheduleId
 * @param {Number} dayNo
 * @param {Object} day
 * @param {Boolean} expanded
 */
export const createWorkScheduleDayIdAndTreeItem = (workScheduleId, dayNo, day, expanded) => {
  const dayId = `${workScheduleId}_${dayNo}`;
  const dayTreeItem = createTreeItem(
    false,
    ENTITY_TYPE.WORK_SCHEDULE_DAY,
    dayId,
    {
      ...day,
      id: dayId,
      workScheduleId,
      dayNo,
      name: `${dayNo + 1}`
    },
    expanded
  );
  return [dayId, dayTreeItem];
};

/**
 * Создать идентификатор интервала прохода и элемент дерева.
 *
 * @param {String} workScheduleId
 * @param {Number} dayNo
 * @param {String} dayId
 * @param {Number} timeNo
 * @param {Object} accessGrantedTime
 * @param {Boolean} expanded
 */
export const createAccessGrantedTimeIdAndTreeItem = (
  workScheduleId,
  dayNo,
  dayId,
  timeNo,
  accessGrantedTime,
  expanded
) => {
  const timeId = `${dayId}_${timeNo}`;
  const timeTreeItem = createTreeItem(false, ENTITY_TYPE.ACCESS_GRANTED_TIME, timeId, {
    ...accessGrantedTime,
    id: timeId,
    workScheduleId,
    dayId,
    dayNo,
    timeNo,
    name: `${Math.floor(accessGrantedTime.from / 60).toTimeFormatString()}:${(
      accessGrantedTime.from % 60
    ).toTimeFormatString()} - ${Math.floor(accessGrantedTime.to / 60).toTimeFormatString()}:${(
      accessGrantedTime.to % 60
    ).toTimeFormatString()}`
  });
  return [timeId, timeTreeItem];
};
