import React from 'react';
import { createSelector } from 'reselect';
import { OptGroup, Option } from 'components/Select';

import { getCurrentProjectDeviceList } from './currentProject';
import { ADDRESS_TYPE, CATEGORY, CRATE_UNIT_TYPE, SETTINGS_TYPE } from 'constants/device';
import memoizeOne from 'memoize-one';
import Device from 'components/Device/Device';
import DeviceImg from 'components/Device/DeviceImg';
import i18next from 'i18next';
import { getActiveDeviceList } from 'helpers/activeProject';
import { FILTER_TYPES } from 'components/Filters';
import { EMPTY_OBJECT } from 'constants/common';

export const WRITABLE_DEVICE_CATEGORY = ['SENSOR', 'EXECUTIVE'];
export const CHANGEABLE_CONFIG_DEVICE_CATEGORY = ['SENSOR', 'EXECUTIVE', 'VIRTUAL_CONTAINER'];
export const UNREADABLE_DEVICE_CATEGORY = ['CONTAINER', 'TRUNK', 'VIRTUAL_CONTAINER'];

const MAX_LINE_NO = 250;
const VIRTUAL_CONTAINER_LINE_NO = 256;

export const FilterNames = {
  DEVICE_TYPES: 'DeviceTypes',
  REGIONS: 'Regions',
  SUBSYSTEMS: 'Subsystems',
  CATEGORIES: 'Categories',
  STATE_CATEGORIES: 'StateCategories',
  PLANS: 'Plans',
  ADDRESS: 'Address',
  CHECKED: 'Checked'
};

export const DefaultDevicesFilterNames = [
  FilterNames.ADDRESS,
  FilterNames.CATEGORIES,
  FilterNames.SUBSYSTEMS,
  FilterNames.REGIONS,
  FilterNames.DEVICE_TYPES,
  FilterNames.PLANS
];

/**
 * Возвращает список всех фильтров, применяемых к устройствам
 * @param component - текущий компонент родитель
 * @returns {Array} - список всех фильтров, применяемых к устройствам, где
 * каждый объект соответствует {@see src/components/ExpandableTable/Table.js:15}
 * {component} должен иметь в пропсах соответствующие сущности для каждого фильтра
 */
function getAllDevicesFilters(component) {
  return [
    {
      name: i18next.t('address'),
      key: FilterNames.ADDRESS,
      type: FILTER_TYPES.INPUT,
      helpInfo: () => {
        return (
          <div>
            <b>{i18next.t('devices.addrInfo.1')}</b>
            <br />
            <b>x.x.x</b>
            <span>{i18next.t('devices.addrInfo.2')}</span>
            <br />
            <b>x.x.</b>
            <span>{i18next.t('devices.addrInfo.3')}</span>
            <br />
            <b>.x.x</b>
            <span>{i18next.t('devices.addrInfo.4')}</span>
            <br />
            <b>.x.x.</b>
            <span>{i18next.t('devices.addrInfo.5')}</span>
          </div>
        );
      },
      getEntities: () => {},
      /**
       * Проверяет, соответствует ли fullAddressPath устройства введенному адресу
       * Если пользователь ввел адрес типа '1.3.3', то проверка выполняется полным совпадением строки,
       * если ввел адрес типа '1.3.', то ищутся устройства, у которых начало адреса совпадает с введенным значением
       * если ввел адрес типа '.1.3', то ищутся устройства, у которых конец адреса совпадает с введенным значением
       * если ввел адрес типа '.1.3.', то ищутся устройства, у которых середина адреса совпадает с введенным значением
       */
      check: (filter, node) => {
        if (node.fullAddressPath) {
          return filter.active.find(addr => {
            const fullAddress = node.fullAddressPath.replace(/\./g, ',');
            addr = addr.replace(/\./g, ',');
            const hasMarkInStart = /^,/.test(addr);
            const hasMarkInEnd = /,$/.test(addr);

            return (
              (!hasMarkInStart && hasMarkInEnd && new RegExp('^' + addr, 'g').test(fullAddress)) ||
              (!hasMarkInEnd && hasMarkInStart && new RegExp(addr + '$', 'g').test(fullAddress)) ||
              (hasMarkInStart && hasMarkInEnd && fullAddress.includes(addr)) ||
              (!hasMarkInStart && !hasMarkInEnd && fullAddress === addr)
            );
          });
        } else return false;
      }
    },
    {
      name: i18next.t('classOfStates', { context: 'plural' }),
      key: FilterNames.STATE_CATEGORIES,
      type: FILTER_TYPES.SELECT,
      getEntities: () => component.props.stateCategoryViewsHash,
      check: (filter, node) => {
        if (node.generalStateCategoryView && node.generalStateCategoryView.id) {
          if (filter.active.includes(node.generalStateCategoryView.id)) return true;
        }
        if (node.activeStateViews && node.activeStateViews.length) {
          for (let view of node.activeStateViews) {
            if (filter.active.includes(view.stateCategoryId)) return true;
          }
        }
        return false;
      }
    },
    {
      name: i18next.t('deviceType', { context: 'plural' }),
      key: FilterNames.DEVICE_TYPES,
      type: FILTER_TYPES.SELECT,
      getEntities: () => component.props.deviceProfileViewsHash,
      check: (filter, node) => filter.active.includes(node.name),
      customOptions: memoizeOne(entities => {
        const groups = getDeviceGroupsForFilters(
          component.props.deviceGroups,
          Object.values(entities)
        );
        return Object.values(groups).map(group => (
          <OptGroup key={group.id} label={i18next.t(`devices.groups.${group.id}`)}>
            {group.children.map(dev => {
              return (
                <Option key={dev.id} value={dev.name} label={dev.name}>
                  {dev.iconMedia ? (
                    <Device>
                      <DeviceImg size={15} name={dev.iconMedia.path} />
                      <span>{dev.name}</span>
                    </Device>
                  ) : null}
                </Option>
              );
            })}
          </OptGroup>
        ));
      })
    },
    {
      name: i18next.t('zone', { context: 'plural' }),
      key: FilterNames.REGIONS,
      type: FILTER_TYPES.SELECT,
      getEntities: () => component.props.regionsHash,
      check: (filter, node) => filter.active.includes(node.regionId)
    },
    {
      name: i18next.t('subsystem', { context: 'plural' }),
      key: FilterNames.SUBSYSTEMS,
      type: FILTER_TYPES.SELECT,
      getEntities: () => {
        if (!component.props.subsystems) return {};
        return component.props.subsystems.reduce((data = {}, current) => {
          data[current.id || current.subsystem] = current;
          return data;
        }, {});
      },
      check: (filter, node) => {
        return node.customSubsystem
          ? filter.active.includes(node.customSubsystem)
          : filter.active.includes(node.subsystem);
      }
    },
    {
      name: i18next.t('category', { context: 'plural' }),
      key: FilterNames.CATEGORIES,
      type: FILTER_TYPES.SELECT,
      getEntities: () => getDeviceCategoriesForFilters(component.props.deviceProfileViewsHash),
      check: (filter, node) => filter.active.includes(node.deviceCategory)
    },
    {
      name: i18next.t('plan', { context: 'plural' }),
      key: FilterNames.PLANS,
      type: FILTER_TYPES.SELECT,
      getEntities: () => component.props.plansHash,
      check: (filter, node) => {
        if (node.planLayouts && node.planLayouts.length) {
          return !!node.planLayouts.find(view => filter.active.includes(view.planId));
        } else return false;
      }
    },
    {
      name: i18next.t('indicators.hideAttachedDevices', { context: 'plural' }),
      key: FilterNames.CHECKED,
      type: FILTER_TYPES.CHECKBOX,
      getEntities: () => {},
      check: (filter, node) => {
        return !filter.active || !component.props.checkedDevicesIds.includes(node.id);
      }
    }
  ];
}

const getDeviceCategoriesForFilters = memoizeOne(deviceProfileViewsHash => {
  if (!deviceProfileViewsHash) return EMPTY_OBJECT;

  const categories = new Set();
  Object.values(deviceProfileViewsHash).forEach(profile =>
    categories.add(profile.deviceProfile.deviceCategory)
  );
  const categoriesHash = {};
  categories.forEach(category => {
    categoriesHash[category] = {
      id: category,
      name: i18next.t('devices.categories.' + category)
    };
  });
  return categoriesHash;
});

function getDeviceGroupsForFilters(deviceGroups, profileViews) {
  const group = [];
  const devices = new Set(); // проверяем дубликаты по названиям
  if (!deviceGroups) return group;
  deviceGroups.forEach(id => {
    const profileViewsByGroup = profileViews.filter(profile => {
      if (
        profile.deviceProfile.hidden ||
        devices.has(profile.name) ||
        profile.deviceProfile.deviceCategory === CATEGORY.CONTAINER.id
      )
        return false;
      if (profile.deviceProfile.deviceGroup === id) {
        devices.add(profile.name);
        return true;
      } else return false;
    });
    if (profileViewsByGroup && profileViewsByGroup.length)
      group.push({ id, children: profileViewsByGroup });
  });

  return group;
}

export function getDevicesFilters(component, filterNames = DefaultDevicesFilterNames) {
  return getAllDevicesFilters(component).filter(f => filterNames.includes(f.key));
}

/**
 * Отфильтровать спсок устройств, оставив родителей для дальнейшего формирования частичного дерева устройств
 * @param {Array} devices список устройств
 * @param {Function} filterCallback callback-функция для фильтрацииустройств
 * @returns {Array} отфильтрованный список устройств и их родителей
 */
export function filterDevicesLeavingParents(devices, filterCallback) {
  const filteredDevices = devices.filter(filterCallback);
  if (filteredDevices) {
    const paths = new Set(),
      filteredDevicesWithParents = [];
    // Получаем пути устройств в текущей зоне
    filteredDevices.forEach(device => {
      if (device.addressPath) {
        const addressPath = device.addressPath.split(',');
        let path = '';
        for (let i = 0; i + 1 < addressPath.length; i += 2) {
          if (i > 0) path += ',' + addressPath[i];
          path += ',' + addressPath[i + 1];
          paths.add(path);
        }
      }
    });
    // Получаем устройства зоны из полного дерева
    devices.forEach(device => {
      if (paths.has(device.addressPath)) filteredDevicesWithParents.push(device);
    });
    return filteredDevicesWithParents;
  } else {
    return [];
  }
}

function calculateNonRootDeviceFullAddressPath(parentDevice, device) {
  device.fullAddressPath = parentDevice.fullAddressPath;

  switch (device.addressType) {
    case ADDRESS_TYPE.GENERIC:
    default:
      if (parentDevice.deviceCategory !== CATEGORY.TRUNK.id)
        device.fullAddressPath += `.${device.lineNo}`;
      device.fullAddressPath += `.${device.lineAddress}`;
      break;
    case ADDRESS_TYPE.INTERNAL:
      device.fullAddressPath += `.${device.lineAddress}`;
      break;
    case ADDRESS_TYPE.LACK:
      device.fullAddressPath += `.${device.name}`;
      break;
  }
}

function calculateNonRootDeviceMiddleAddressPath(parentDevice, device) {
  switch (device.addressType) {
    case ADDRESS_TYPE.GENERIC:
    default:
      if (device.crateUnitType === CRATE_UNIT_TYPE.FAP) {
        device.middleAddressPath = `${parentDevice.lineAddress}.${device.lineAddress}`;
      } else {
        device.middleAddressPath = `${parentDevice.lineAddress}.${device.lineNo}.${device.lineAddress}`;
      }
      break;
    case ADDRESS_TYPE.INTERNAL:
      device.middleAddressPath = `${parentDevice.lineAddress}.${device.lineAddress}`;
      break;
    case ADDRESS_TYPE.LACK:
      device.middleAddressPath = `${parentDevice.lineAddress}`;
      break;
  }
}

function calculateNonRootDeviceShortAddressPath(parentDevice, device) {
  switch (device.addressType) {
    case ADDRESS_TYPE.GENERIC:
    default:
      if (device.deviceCategory !== CATEGORY.CONTAINER.id) {
        if (parentDevice.deviceCategory === CATEGORY.TRUNK.id) {
          device.shortAddressPath = `${device.lineAddress}`;
        } else {
          device.shortAddressPath = `${device.lineNo}.${device.lineAddress}`;
        }
      } else {
        const nChilds = device.children.length;
        device.shortAddressPath = `${device.lineNo}.${device.lineAddress} - ${
          device.lineNo
        }.${device.lineAddress + nChilds - 1}`;
      }
      break;
    case ADDRESS_TYPE.INTERNAL:
      device.shortAddressPath = `${device.lineAddress}`;
      break;
    case ADDRESS_TYPE.LACK:
      device.shortAddressPath = '';
      break;
  }
}

function calculateNonRootDeviceAddressPaths(device, devicesMap) {
  const parentDevice = devicesMap[device.parentDeviceId];
  if (!parentDevice) return;
  let deepParentDevice = parentDevice;
  while (
    deepParentDevice &&
    deepParentDevice.deviceCategory === CATEGORY.CONTAINER.id &&
    deepParentDevice.parentDeviceId
  )
    deepParentDevice = devicesMap[deepParentDevice.parentDeviceId];

  calculateNonRootDeviceFullAddressPath(deepParentDevice, device);
  calculateNonRootDeviceMiddleAddressPath(deepParentDevice, device);
  calculateNonRootDeviceShortAddressPath(parentDevice, device);
}

export function calculateDeviceAddressPaths(device, devicesMap) {
  if (!device.isRoot) calculateNonRootDeviceAddressPaths(device, devicesMap);
  else {
    device.fullAddressPath = `${device.lineAddress}`;
    device.middleAddressPath = `${device.lineAddress}`;
    device.shortAddressPath = `${device.lineAddress}`;
  }
}

/**
 * Сворачивание списка устройств в дерево устройств с заполнением карты по идентификаторам.
 *
 * @export
 * @param {Array} devicesList (IN) список устройств
 * @param {Function} [callback = item => item] (IN) callback для модификации устройств
 * @param {Object} devicesMap (IN/OUT) карта устройств
 * @param {Array} devicesTree (IN/OUT) дерево устройств. Если оно передается в функцию, то оно дополняется, а карта должна содержать элементы этого дерева.
 * @returns {Array} Дерево устройств (массив с полем children у элементов).
 */
export function getDevicesTree(
  devicesList,
  callback = item => item,
  devicesMap = {},
  devicesTree = []
) {
  if (!devicesList || devicesList.length === 0) return devicesTree;
  for (let i = 0; i < devicesList.length; ++i) {
    if (devicesList[i].hidden) continue;

    const device = callback({ ...devicesList[i] });
    device.key = device.id;
    const parentId = device.parentDeviceId;
    const virtualContainerId = device.virtualContainerId;
    if (!!devicesMap[device.key] && devicesMap[device.key].key) {
      /* Эта ветка работает при изменении устройства. */

      if (
        devicesMap[device.key].virtualContainerId &&
        devicesMap[device.key].virtualContainerId !== virtualContainerId
      ) {
        const oldVContainerId = devicesMap[device.key].virtualContainerId;
        const vContainerChildren = devicesMap[oldVContainerId].children;
        devicesMap[oldVContainerId].children = vContainerChildren.filter(
          childDevice => childDevice.key !== device.key
        );
      }
      if (virtualContainerId && devicesMap[device.key].virtualContainerId !== virtualContainerId) {
        insertChild(devicesMap, virtualContainerId, device);
      }
      if (
        device.addressPath !== devicesMap[device.key].addressPath &&
        parentId &&
        devicesMap[parentId]
      )
        devicesMap[parentId].sorted = false;
      if (isDeviceTruncated(device)) {
        /* У неполного объекта устройства нижеперечисленные поля могут присутствовать, но быть пустыми.
          Необходимо оставить старые значения до прихода полного объекта.
          */
        delete device.aggregatedPropertyViews;
        delete device.configPropertyViews;
        delete device.inheritedActiveStateViewsByDeviceId;
      }
      Object.assign(devicesMap[device.key], device);
    } else {
      /* Эта ветка работает при добавлении устройства и при первоначальном построении дерева. */

      if (!devicesMap[device.key]) devicesMap[device.key] = device;
      // эта ветка сработает при построении дерева, если родительское устройство оказалось по порядку после своих потомков
      else devicesMap[device.key] = { ...device, ...devicesMap[device.key] };
      if (!parentId) {
        devicesTree.push(devicesMap[device.key]);
        devicesMap[device.key].isRoot = true;
      } else {
        insertChild(devicesMap, parentId, device);
      }
      if (virtualContainerId) {
        insertChild(devicesMap, virtualContainerId, device);
      }
    }
  }
  sortDeviceTree(devicesTree);
  return devicesTree;
}

function insertChild(devicesMap, parentId, device) {
  if (devicesMap[parentId] && !devicesMap[parentId].children) {
    devicesMap[parentId].children = [];
  }
  if (!devicesMap[parentId]) {
    devicesMap[parentId] = { children: [] };
  }
  devicesMap[parentId].children.push(devicesMap[device.key]);
  devicesMap[parentId].sorted = false;
}

/**
 * Возвращает развернутое дерево
 * @param {Array} devices
 * @return {Array}
 */
export function getFlatTree(tree, list = [], parentIds = []) {
  const deviceList = list;
  tree.forEach(node => {
    const treeNode = { ...node };
    deviceList.push(treeNode);
    treeNode['parentIds'] = parentIds;
    if (node.children) {
      getFlatTree(node.children, deviceList, [...parentIds, node.id]);
    }
  });
  return deviceList;
}

/**
 * Сортировка дерева устройств по адресному пути
 * @param {Array} rootDevices Дерево устройств
 * @param {Boolean} recursive Рекурсивная сортировка
 */
export function sortDeviceTree(rootDevices, recursive = true, sortRoot = true) {
  if (sortRoot)
    rootDevices.sort((dev1, dev2) => {
      const dev1AddressNumbers = dev1.addressPath.split(',');
      virtualContainerLining(dev1, dev1AddressNumbers);
      const dev2AddressNumbers = dev2.addressPath.split(',');
      virtualContainerLining(dev2, dev2AddressNumbers);
      if (dev1AddressNumbers.length !== dev2AddressNumbers.length)
        return dev1AddressNumbers.length - dev2AddressNumbers.length;

      if (dev1AddressNumbers.length === 0) return 0;

      const dev1Address = dev1AddressNumbers[dev1AddressNumbers.length - 1];
      const dev2Address = dev2AddressNumbers[dev2AddressNumbers.length - 1];
      if (dev1AddressNumbers.length === 1) return dev1Address - dev2Address;

      var dev1LineNo = dev1AddressNumbers[dev1AddressNumbers.length - 2];
      var dev2LineNo = dev2AddressNumbers[dev2AddressNumbers.length - 2];

      if (dev1LineNo === dev2LineNo) return dev1Address - dev2Address;

      if (dev1LineNo >= MAX_LINE_NO) dev1LineNo -= 2 * MAX_LINE_NO;
      if (dev2LineNo >= MAX_LINE_NO) dev2LineNo -= 2 * MAX_LINE_NO;

      return dev1LineNo - dev2LineNo;
    });

  if (recursive) {
    rootDevices.forEach(rootDevice => {
      if (rootDevice.children && rootDevice.children.length) {
        const toSortRoot = !rootDevice.sorted && !!rootDevice.children[0].addressPath;
        sortDeviceTree(rootDevice.children, true, toSortRoot);
        if (toSortRoot) rootDevice.sorted = true;
      }
    });
  }
}

function virtualContainerLining(device, devAddressNumbers) {
  if (device.deviceCategory === 'VIRTUAL_CONTAINER') {
    devAddressNumbers[devAddressNumbers.length - 2] = VIRTUAL_CONTAINER_LINE_NO;
  }
}

/**
 * Фильтрация списка устройств по определенной зоне
 *
 * @param {Object[]} devices
 * @param {string} regionId
 * @returns {Object[]} устройства, привязанные к определенной зоне
 */
export function getDevicesByRegionId(devices, regionId) {
  return devices.filter(device => {
    return device.regionId === regionId;
  });
}

/**
 * Получить все устройства с указанной категорией.
 * @param {object} store
 * @param {string} deviceCategory
 * @param {object} devices
 */
export const findDevicesByDeviceCategory = createSelector(
  [
    (store, deviceCategory, devices) => (!!devices ? devices : getCurrentProjectDeviceList(store)),
    (store, deviceCategory, devices) => deviceCategory
  ],
  (devices, deviceCategory) =>
    !!devices ? devices.filter(device => device.deviceCategory === deviceCategory) : []
);

/**
 * Получить устройства активного проекта, категория которых не входит в список указанных категорий.
 *
 * @param {object} store
 * @param {array} deviceCategories
 */
export const findActiveDevicesByCategoryNotIn = createSelector(
  [
    getActiveDeviceList, //
    (state, categories) => categories
  ],
  (devices, categories) =>
    !!devices ? devices.filter(device => !categories.includes(device.deviceCategory)) : []
);

/**
 * Получить все вкл./выкл. устройства
 * @param {array} devices
 * @param {bool} state
 **/
export const getDevicesByPollingState = createSelector(
  (devices = [], state) => devices.filter(device => device.statePolling === state),
  devices => devices
);

/**
 * Получить профиль устройства
 * @param {object} profiles
 * @param {object} device
 **/
export function getDeviceProfile(profilesHash, device) {
  const profileView =
    device && device.id && profilesHash ? profilesHash[device.deviceProfileId] : null;
  return profileView ? profileView.deviceProfile : null;
}

/**
 * Получить список устройств, у которых есть наблюдаемые параметры
 * @param {array} devices
 * @param {object} deviceProfileViewsHash
 */
export function getMonitorableDevices(devices, deviceProfileViewsHash) {
  const monitorableDevices = devices.filter(
    device =>
      deviceProfileViewsHash[device.deviceProfileId] &&
      deviceProfileViewsHash[device.deviceProfileId].deviceProfile.customMonitorableValues.length
  );
  return monitorableDevices;
}

/**
 * Получить список наблюдаемых параметров
 * @param {array} devices - Отсортированный список устройств по наличию наблюдаемых параметров
 * @param {object} deviceProfileViewsHash
 */
export function getMonitorableValues(devices, deviceProfileViewsHash) {
  const monitorableValues = {};
  devices.forEach(device => {
    deviceProfileViewsHash[device.deviceProfileId].deviceProfile.customMonitorableValues.forEach(
      value => {
        if (!monitorableValues[value.profile.id]) monitorableValues[value.profile.id] = 1;
      }
    );
  });
  return Object.keys(monitorableValues);
}

/**
 * Создает полное дерево устройств
 * @param {Array} devices - Список устройств
 * @return {{tree: Array, hash: Object}} - Дерево и хэш (карта) устройств.
 */
export function createDeviceTree(devices) {
  const devicesMap = {};
  const deviceTree = getDevicesTree(devices, d => d, devicesMap);
  Object.values(devicesMap).forEach(device => calculateDeviceAddressPaths(device, devicesMap));

  return { tree: deviceTree, hash: devicesMap };
}

/**
 * Функция добавления устройств в дерево без перестроения всего дерева.

 * @param {Object} devicesMap - Карта устройств
 * @param {Array} devicesTree - Дерево устройств
 * @param {Array} addedDevices - Добавленные устройства
 */
export function addDeviceTreeItems(devicesMap, devicesTree, addedDevices) {
  getDevicesTree(addedDevices, d => d, devicesMap, devicesTree);
  addedDevices.forEach(addedDevice => {
    const addedDevInTree = devicesMap[addedDevice.id];
    if (addedDevInTree) calculateDeviceAddressPaths(addedDevInTree, devicesMap);
  });
}

/**
 * Функция обновления нескольких элементов дерева устройств без перестроения дерева.
 *
 * @param {Object} devicesMap - Карта устройств
 * @param {Array} devicesTree - Дерево устройств
 * @param {Array} updatedDevices - Обновленные устройства
 */
export function updateDeviceTreeItems(devicesMap, devicesTree, updatedDevices) {
  getDevicesTree(updatedDevices, d => d, devicesMap, devicesTree);
  updatedDevices.forEach(updatedDevice => {
    const updatedDevInTree = devicesMap[updatedDevice.id];
    if (updatedDevInTree) calculateDeviceAddressPaths(updatedDevInTree, devicesMap);
  });
}

/**
 * Функция обновления одного элемента дерева устройств без перестроения дерева.
 *
 * @param {Object} devicesMap - Карта устройств
 * @param {Array} devicesTree - Дерево устройств
 * @param {Object} updatedDevice - Обновленное устройство
 */
export function updateOneDeviceTreeItem(devicesMap, devicesTree, updatedDevice) {
  if (updatedDevice) {
    getDevicesTree([updatedDevice], d => d, devicesMap, devicesTree);
    const updatedDevInTree = devicesMap[updatedDevice.id];
    if (updatedDevInTree) calculateDeviceAddressPaths(updatedDevInTree, devicesMap);
  }
}

/**
 * Удаление элементов дерева устройств
 * Обновляет дерево НА МЕСТЕ!
 *
 * @param {Object} devicesMap - Карта устройств
 * @param {Array} devicesTree Дерево устройств
 * @param {Array} removedDeviceIds Идентификаторы удаляемых устройств
 *
 */
export function deleteDeviceTreeItems(devicesMap, devicesTree, removedDeviceIds) {
  const removedDevicesByParentIds = {};
  const removedRootDeviceIds = [];
  removedDeviceIds.forEach(removedDeviceId => {
    const removedDevice = devicesMap[removedDeviceId];
    if (!removedDevice) return; // continue

    const parentId = removedDevice.parentDeviceId;
    if (parentId) {
      if (removedDevicesByParentIds[parentId])
        removedDevicesByParentIds[parentId].push(removedDevice.id);
      else removedDevicesByParentIds[parentId] = [removedDevice.id];
    } else removedRootDeviceIds.push(removedDevice.id);

    const virtualContainerId = removedDevice.virtualContainerId;
    if (virtualContainerId) {
      if (removedDevicesByParentIds[virtualContainerId])
        removedDevicesByParentIds[virtualContainerId].push(removedDevice.id);
      else removedDevicesByParentIds[virtualContainerId] = [removedDevice.id];
    }
  });

  /* Удалять устройства надо от нижних узлов к верхним */
  const parentIds = Object.keys(removedDevicesByParentIds).sort(
    (id1, id2) => (devicesMap[id2].addressLevel || 0) - (devicesMap[id1].addressLevel || 0)
  );

  parentIds.forEach(parentId => {
    devicesMap[parentId].children = devicesMap[parentId].children.filter(
      device => !removedDevicesByParentIds[parentId].includes(device.id)
    );
    removedDevicesByParentIds[parentId].forEach(deviceId => delete devicesMap[deviceId]);
  });
  removedRootDeviceIds.forEach(devId => {
    const removingDevice = devicesMap[devId];
    devicesTree.splice(devicesTree.indexOf(removingDevice), 1);
    delete devicesMap[devId];
  });
}

/**
 * Проверяет устройство на неисправность
 * @param {Object} device
 * @return {Object|undefined}
 */
export function checkMalfunction(device) {
  if (
    device.generalStateCategoryView &&
    (device.generalStateCategoryView.id === 'Malfunction' ||
      device.generalStateCategoryView.id === 'Service')
  ) {
    return device;
  }
}

export function getDeviceIdsGroupedByRegionId(devices = []) {
  const groupedDeviceId = {};
  devices.forEach(device => {
    if (device.regionId) {
      if (!groupedDeviceId[device.regionId]) groupedDeviceId[device.regionId] = [];
      groupedDeviceId[device.regionId].push(device.id);
    }
  });
  return groupedDeviceId;
}

export function getAllRootAddresses(deviceTree) {
  const rootAddresses = [];
  if (deviceTree && deviceTree.length) {
    deviceTree.forEach(device => rootAddresses.push(device.lineAddress));
  }
  return rootAddresses;
}

/**
 * Определяет, является ли устройство индикаторной панелью
 * @param device - текущее устройство
 * @returns {boolean|boolean} true если устройство является индикаторной панелью
 */
export function isIndicatorPanel(device) {
  return (
    device &&
    device.specificSettings instanceof Object &&
    device.specificSettings.type === SETTINGS_TYPE.INDICATORS_PANEL
  );
}

/**
 * Определяет, является ли устройство пультом управления
 * @param device - текущее устройство
 * @returns {boolean|boolean} true если устройство является пультом управления
 */
export function isControlPanel(device) {
  return (
    !!device &&
    !!device.specificSettings &&
    (device.specificSettings.type === SETTINGS_TYPE.CONTROL_PANEL ||
      device.specificSettings.type === SETTINGS_TYPE.INDICATORS_PANEL)
  );
}

/**
 * Определяет, является ли устройство ведомым МПТ
 * @param device - текущее устройство
 * @returns {boolean|boolean} true если устройство является ведомым МПТ
 */
export function isSlaveMpt(device) {
  return (
    device &&
    device.specificSettings instanceof Object &&
    device.specificSettings.type === SETTINGS_TYPE.MPT &&
    device.specificSettings.slave
  );
}

/**
 * Сгенерировать строку с именами родительских устройств.
 *
 * @param {*} devices Карта устройств
 * @param {String} childDeviceId Идентификатор устройства
 * @param {String} tail Присоединяемый хвост
 */
export function generateDeviceParentTreeNames(devices, childDeviceId, tail = '') {
  const childDevice = devices[childDeviceId];
  const parentDevice =
    childDevice && childDevice.parentDeviceId ? devices[childDevice.parentDeviceId] : null;

  return `${
    parentDevice
      ? `${generateDeviceParentTreeNames(
          devices,
          parentDevice.id,
          `${parentDevice.name} ${
            parentDevice.shortAddressPath ? `(${parentDevice.shortAddressPath})` : ''
          } ${tail ? `\\ ${tail}` : ''}`
        )}`
      : tail
  }`;
}

/**
 * Проверка является ли объект устройства обрезанным (не полным)
 *
 * @param {*} device
 * @returns true если объект обрезан
 */
export function isDeviceTruncated(device) {
  return device.truncated;
}
