import React, { Suspense } from 'react';
import { Spin } from 'antd';
import styles from './CustomSuspense.module.css';

const CustomSuspenseContainer = ({ children }) => {
  return (
    <Suspense
      fallback={
        <div className={styles.spin_container}>
          <Spin className={styles.spin} size="large" />
        </div>
      }
    >
      {children}
    </Suspense>
  );
};
export default CustomSuspenseContainer;
