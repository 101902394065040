import styled, { css } from 'styled-components';

const activeStyle = css`
  cursor: pointer;
  &:hover {
    background: var(--hover-tool-color);
  }
`;

const noActiveStyle = css`
  cursor: not-allowed;
  opacity: var(--button-opacity-inactive, 0.5);
  background: var(--button-inactive-color, none);
`;

export const ButtonWrapper = styled.div`
  position: relative;
  line-height: 1.3;
  text-align: center;
  opacity: var(--button-opacity, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${p => (p.height ? `${p.height}px` : '35px')};
  padding: 0 10px;
  color: ${p => (p.fontColor ? p.fontColor : 'white')};
  font-weight: ${p => (p.fontWeight ? p.fontWeight : 'normal')} !important;
  & i {
    font-size: ${p => (p.iconSize ? `${p.iconSize}px` : '18px')};
  }

  ${props => (props.isActive ? activeStyle : noActiveStyle)};
`;

export const Text = styled.span`
  color: white;
  ${p => p.css};
  ${p =>
    !p.iconPlacement || p.iconPlacement === 'left' ? 'margin-left: 10px;' : 'margin-right: 10px;'};
`;
