export const PATHS = {
  PROJECTS: '/projects',
  INDICATORS_PANEL: 'indicators_panels_settings',
  CONTROL_PANEL_USERS: 'control_panel_users',
  INDICATOR: 'indicator',
  SCENARIOS: 'scenarios',
  VIRTUAL_OBJECTS: 'virtual',
  REGIONS: 'regions',
  PLAN: 'plan',
  OP: {
    LOG: '/op/log',
    INDICATORS: '/op/indicator',
    SCENARIOS: '/op/scenarios',
    DEVICE_PARAMETERS: '/op/device_parameters',
    DISABLED_DEVICES: '/op/disabled_devices',
    PLAN: '/op/plan'
  }
};

export default PATHS;

export function buildRouteByProject(projectId, path) {
  return `/projects/${projectId}/${path}/`;
}
