import { SOUND_NOTIFICATION_SELECT } from 'constants/actionTypes';

export default function selectedSoundNotificationId(state = null, { type, payload }) {
  switch (type) {
    case SOUND_NOTIFICATION_SELECT: {
      return payload;
    }
    default: {
      return state;
    }
  }
}
