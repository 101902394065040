import React from 'react';

const Settings = ({ color = "#5FA2DD", x = "16", y = "16" }) => {
  return (
    <svg width={x} height={y} viewBox={`0 0 ${x} ${y}`}>
        <path id="settings" fill={color} fillRule="evenodd" d="M15.435 6.718c.227 0 .49.169.515.394a8.009 8.009 0 0 1-.004 1.82.446.446 0 0 1-.468.392l-.099-.003a1.79 1.79 0 0 0-1.636 1.085c-.29.689-.109 1.491.448 1.995a.444.444 0 0 1 .047.608c-.38.474-.815.905-1.294 1.28a.447.447 0 0 1-.603-.05c-.476-.523-1.323-.72-1.982-.451a1.778 1.778 0 0 0-1.094 1.706.445.445 0 0 1-.396.458 7.904 7.904 0 0 1-1.8-.005.445.445 0 0 1-.393-.467 1.774 1.774 0 0 0-1.082-1.737c-.66-.276-1.516-.08-1.995.449a.445.445 0 0 1-.609.048 7.938 7.938 0 0 1-1.265-1.28.445.445 0 0 1 .048-.603 1.779 1.779 0 0 0 .448-1.978A1.755 1.755 0 0 0 .575 9.282c-.23 0-.5-.167-.525-.393a8.026 8.026 0 0 1 .003-1.82c.028-.23.209-.4.463-.392.781 0 1.464-.424 1.74-1.082.29-.69.11-1.492-.447-1.996a.445.445 0 0 1-.048-.608 7.953 7.953 0 0 1 1.295-1.28.446.446 0 0 1 .603.051c.476.524 1.327.718 1.98.451A1.78 1.78 0 0 0 6.736.506.445.445 0 0 1 7.131.05a7.98 7.98 0 0 1 1.8.004.444.444 0 0 1 .393.468 1.777 1.777 0 0 0 1.08 1.737c.662.276 1.519.08 1.996-.449a.445.445 0 0 1 .608-.049c.465.373.892.804 1.267 1.28a.444.444 0 0 1-.049.603 1.782 1.782 0 0 0-.447 1.979 1.777 1.777 0 0 0 1.656 1.096zm-10.09 1.3a2.671 2.671 0 0 0 2.668 2.668 2.671 2.671 0 0 0 2.668-2.669A2.671 2.671 0 0 0 8.013 5.35a2.671 2.671 0 0 0-2.669 2.668z"/>
    </svg>
  )
}

export default Settings;
