import React from 'react';
import PropTypes from 'prop-types';
import Tree from './Tree';
import memoizeOne from 'memoize-one';
import { ArrowsAltOutlined, VerticalAlignMiddleOutlined } from '@ant-design/icons';
import { Button, Tooltip, Filters } from 'components';

import styles from './styles.module.css';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { FILTER_TYPES } from 'components/Filters';
import { getActiveValue } from 'helpers/filters';

/* При изменении поменять также margin-bottom класса tree_wrapper. */
const WRAPPER_MARGIN_BOTTOM = 35;
/**
 * Расширяемая таблица данных
 *
 * @property {Array} filters - список фильтров для дерева {@link Filters}
 * @property needToShowUpperMenu - нужно ли отображать меню над таблицей
 * @property upperMenuElements - список элементов, которые нужно добавить в верхнее меню
 * @property upperMenuStyles - дополнительные стили для верхнего меню
 * @property onSetFilters - вызывается когда были установлены фильтры
 */
class Table extends React.PureComponent {
  static propsTypes = {
    needToShowUpperMenu: PropTypes.bool,
    resizable: PropTypes.bool,
    filters: PropTypes.array,
    upperMenuElements: PropTypes.object,
    upperMenuStyles: PropTypes.object,
    width: PropTypes.number
  };

  static defaultProps = {
    needToShowUpperMenu: true,
    resizable: true,
    upperMenuStyles: { height: '35px' }
  };

  constructor(props) {
    super(props);

    this.state = {
      filters: props.filters ? props.filters : []
    };

    this.header = React.createRef();
    this.tree = React.createRef();
    this.treeWindow = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    let { filters, width } = props;
    let newFilters = state.filters;
    if (filters && filters !== state._filters) {
      newFilters = Table.mergeFilters(state.filters, filters);
    }
    if (!width) width = state.width;

    return { filters: newFilters, _filters: filters, width };
  }

  /**
   * Объединяет фильтры из props и state при изменении последних
   */
  static mergeFilters = memoizeOne((stateFilters, propsFilters) => {
    const filters = [...propsFilters];
    filters.forEach(filter => {
      const sFilter = stateFilters.find(f => f.key === filter.key);
      if (sFilter && sFilter.active && sFilter.active.length) {
        const active = [...(filter.active ? filter.active : []), ...sFilter.active];
        filter.active = active.filter((value, index, self) => self.indexOf(value) === index);
      }
    });
    return filters;
  });

  collapseAllNodes = () => {
    if (this.tree.current) this.tree.current.collapseAllNodes();
  };

  expandAllNodes = () => {
    if (this.tree.current) this.tree.current.expandAllNodes();
  };

  onSaveFilters = filtersChangesMap => {
    const { filters } = this.state;
    const { onSetFilters } = this.props;

    const newData = [...filters];
    newData.forEach(filter => {
      filter.active = getActiveValue(filtersChangesMap, filter);
    });
    this.setState({ filters: newData }, () => {
      if (onSetFilters) onSetFilters(newData);
    });
  };

  replaceFilters = data => {
    if (!data || !data.filters || !data.filters.length) return;
    const { filters } = this.state;
    const { onSetFilters } = this.props;

    let newFilters = [];
    if (!filters || !filters.length) {
      newFilters = data.filters;
    } else {
      filters.forEach(filter => {
        const currentFilter = data.filters.find(f => f.key === filter.key);
        const filterCopy = { ...filter };
        if (
          currentFilter &&
          currentFilter.active &&
          (currentFilter.active.length || currentFilter.type === FILTER_TYPES.CHECKBOX)
        ) {
          switch (filter.type) {
            case FILTER_TYPES.SELECT:
            case FILTER_TYPES.INPUT:
              filterCopy.active = [
                ...(filterCopy.active ? filterCopy.active : []),
                ...currentFilter.active
              ];
              filterCopy.active = filterCopy.active.filter((item, i, ar) => ar.indexOf(item) === i);
              break;
            case FILTER_TYPES.CHECKBOX:
              filterCopy.active = false;
              filterCopy.active = !!currentFilter.active;
              break;
            default:
              break;
          }
        } else if (!filterCopy.active) {
          switch (filter.type) {
            case FILTER_TYPES.SELECT:
            case FILTER_TYPES.INPUT:
              filterCopy.active = [];
              break;
            case FILTER_TYPES.CHECKBOX:
              filterCopy.active = false;
              break;
            default:
              break;
          }
        }
        newFilters.push(filterCopy);
      });
    }
    this.setState({ filters: newFilters }, () => {
      if (onSetFilters) onSetFilters(newFilters);
    });
  };

  setIsActiveWindow = value => {
    this.setState({ isActiveWindow: value });
  };

  render() {
    let propsStyles = {};
    const {
      columns,
      height,
      forceWidth,
      needToShowUpperMenu,
      upperMenuStyles,
      upperMenuElements,
      className
    } = this.props;
    const { filters, width } = this.state;
    if (height || width) {
      if (height) propsStyles.height = height - WRAPPER_MARGIN_BOTTOM;
      if (width) propsStyles.width = width;
      if (forceWidth) propsStyles.width = '100%';
    } else propsStyles = null;

    return (
      <div
        ref={this.treeWindow}
        className={`${className ? className : ''} ${styles.tree_wrapper}`}
        style={propsStyles}
      >
        <UpperMenu
          filters={this.state.filters}
          isEnabled={needToShowUpperMenu}
          onCollapseBtnClick={this.collapseAllNodes}
          onExpandBtnClick={this.expandAllNodes}
          onSaveFilters={this.onSaveFilters}
          customElements={upperMenuElements}
          styles={upperMenuStyles}
        />
        <Tree
          id={'tree'}
          innerRef={this.header}
          ref={this.tree}
          {...this.props}
          columns={columns}
          filters={filters}
          menuStyles={upperMenuStyles}
          dataGotFromStorage={this.replaceFilters}
          isSaveExpandedItems={true}
          setIsActiveWindow={this.setIsActiveWindow}
          isActiveWindow={this.state.isActiveWindow}
          treeWindow={this.treeWindow}
        />
      </div>
    );
  }

  componentWillUnmount = () => {
    if (this.body) this.body.removeEventListener('scroll', this.syncHeaderScroll);
  };
}

const MenuIconStyles = { fontSize: 20 };

function MenuButton({ icon, tooltipText, ...rest }) {
  return (
    <Tooltip title={tooltipText}>
      <Button icon={icon} iconStyles={MenuIconStyles} {...rest} />
    </Tooltip>
  );
}

function UpperMenu({ isEnabled, onSaveFilters, customElements, filters, ...rest }) {
  return isEnabled ? (
    <div className={styles.upper_menu} style={rest.styles}>
      {!customElements?.length ? customElements : null}
      {customElements?.length ? customElements.map(e => e) : null}
      <div className={styles.upper_menu} style={rest.styles}>
        {!!filters?.length ? <Filters filters={filters} onSaveFilters={onSaveFilters} /> : null}
        <MenuButton
          icon={ArrowsAltOutlined}
          tooltipText={i18next.t('buttons.expandAll')}
          onClick={rest.onExpandBtnClick}
        />
        <MenuButton
          icon={VerticalAlignMiddleOutlined}
          tooltipText={i18next.t('buttons.collapseAll')}
          onClick={rest.onCollapseBtnClick}
        />
      </div>
    </div>
  ) : null;
}

const mapDispatchToProps = dispatch => ({ dispatch: dispatch });

export default connect(null, mapDispatchToProps)(Table);
