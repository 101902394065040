import { takeEvery } from 'redux-saga/effects';

import {
  LOG_VIEWS_LOAD,
  LOG_VIEW_ADD,
  LOG_VIEW_UPDATE,
  LOG_VIEW_DELETE
} from 'constants/actionTypes';

import { fetch, request, dispatchSuccess, dispatchFail } from 'helpers/request';

function* loadLogViews({ type }) {
  try {
    const response = yield fetch(`/log_views`);
    yield dispatchSuccess(type, response);
  } catch (err) {
    yield dispatchFail(type, err.message);
  }
}

function* addLogView({ type, payload: logView }) {
  try {
    yield request(`/log_views`, 'POST', logView);
  } catch (err) {
    yield dispatchFail(type, err.message);
  }
}

function* updateLogView({ type, payload: updatedLogView }) {
  try {
    yield request(`/log_views/${updatedLogView.id}`, 'PUT', updatedLogView);
  } catch (err) {
    yield dispatchFail(type, err.message);
  }
}

function* deleteLogView({ type, payload: logViewId }) {
  try {
    yield request(`/log_views/${logViewId}`, 'DELETE');
    yield dispatchSuccess(type);
  } catch (err) {
    yield dispatchFail(type, err.message);
  }
}

export default function* logViewsSaga() {
  yield takeEvery(LOG_VIEWS_LOAD, loadLogViews);
  yield takeEvery(LOG_VIEW_ADD, addLogView);
  yield takeEvery(LOG_VIEW_UPDATE, updateLogView);
  yield takeEvery(LOG_VIEW_DELETE, deleteLogView);
}
