export const LOCAL_STORAGE = {
  CONTROL_PANEL_KEYS: {
    SELECTED_DEVICE: 'controlPanelSelectedDevice'
  },
  PLAN_EDITOR_KEYS: {
    CONFIGS: 'planEditorConfigs'
  },
  PLAN_VIEWER_KEYS: {
    CONFIGS: 'planViewerConfigs',
    PRIORITY_MOD: 'priorityMod'
  },
  WIDGETS: {
    VIRTUAL_OBJECT: {
      STATES: 'editors.virtualObjects.states',
      COUNTERS: 'editors.virtualObjects.counters'
    }
  },
  ACTIVE_DEVICES_TREE: 'activeDevicesTree',
  CONFIG_DEVICES_TREE: 'configDevicesTree',
  PROJECT_DEVICES_TREE: 'projectDevicesTree',
  CONTROL_PANEL_DEVICES_TREE: 'controlPanelDevisesTree',
  SKUD_TREE: 'SkudTree',
  WORK_SCHEDULES_TREE: 'WorkSchedulesTree',
  APP_VERSION: 'appVersion',
  SUBSYSTEMS_INFO_FILTERS: 'subsystemsInfoFilters',
  MUTED: 'muted',
  CURRENT_USER: 'currentUser',
  REDUX_TAB_SYNC: 'redux-tab-sync' // контролируется redux
};
