import React from 'react';
import { ButtonWrapper, Text } from './styles';

import { Icon as LegacyIcon } from '@ant-design/compatible';
import CustomIcon from 'components/Icon';
import { Tooltip, Modal } from 'components';
import i18next from 'i18next';
import styles from './styles.module.css';
import { LockOutlined } from '@ant-design/icons';

export default class CustomButton extends React.Component {
  static defaultProps = {
    isActive: true
  };

  onClick = e => {
    const { onClick, isLocked, isActive } = this.props;
    if (isLocked) {
      Modal.accessDenied();
      e.preventDefault();
      e.stopPropagation();
    } else if (isActive && onClick) onClick(e);
  };

  ButtonContainer = ({
    isActive,
    children,
    onClick,
    icon,
    customIcon,
    iconStyles = {},
    css,
    iconPlacement,
    ...restProps
  }) => {
    const Icon = icon;
    return (
      <ButtonWrapper isActive={isActive} onClick={this.onClick} {...restProps}>
        {children && iconPlacement === 'right' ? (
          <Text css={css} iconPlacement={iconPlacement}>
            {children}
          </Text>
        ) : null}
        {customIcon ? (
          <CustomIcon name={icon} {...iconStyles} />
        ) : typeof icon === 'string' ? (
          <LegacyIcon type={icon} style={iconStyles} />
        ) : !!Icon ? (
          <Icon style={iconStyles} />
        ) : null}
        {children && (iconPlacement === 'left' || !iconPlacement) ? (
          <Text css={css} iconPlacement={iconPlacement}>
            {children}
          </Text>
        ) : null}
      </ButtonWrapper>
    );
  };

  render() {
    const { isLocked } = this.props;
    if (isLocked) {
      return (
        <Tooltip
          placement="bottom"
          className={styles.tooltip}
          color={'#B00020'}
          title={<span className={styles.tooltip_title}>{i18next.t('errors.accessDenied')}</span>}
        >
          <div className={styles.locked_button}>
            <this.ButtonContainer {...this.props} isActive={true} />
            <LockOutlined className={styles.lock_icon} />
          </div>
        </Tooltip>
      );
    } else {
      return <this.ButtonContainer {...this.props} />;
    }
  }
}
