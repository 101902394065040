import React, { PureComponent } from 'react';

import { Tooltip } from 'antd';
import i18next from 'i18next';
import { ENTITY_TYPE } from 'constants/entityType';
import memoizeOne from 'memoize-one';
import { getDateTimestamp } from 'helpers/time';

export const EVENT_TABLE_COLUMNS = {
  INDEX: Symbol('INDEX'),
  OCCURRED: Symbol('OCCURRED'),
  RECEIVED: Symbol('RECEIVED'),
  DESCRIPTION: Symbol('DESCRIPTION'),
  OBJECT: Symbol('OBJECT'),
  DEVICE: Symbol('DEVICE'),
  VIRTUAL_OBJECT: Symbol('VIRTUAL_OBJECT'),
  SCENARIO: Symbol('SCENARIO'),
  CONTROL_DEVICE: Symbol('CONTROL_DEVICE'),
  REGION: Symbol('REGION'),
  USER: Symbol('USER'),
  EMPLOYEE: Symbol('EMPLOYEE'),
  USER_AND_EMPLOYEE: Symbol('USER_AND_EMPLOYEE')
};

export class ColorRow extends PureComponent {
  render() {
    const { stateCategoryId, className, ...restProps } = this.props;
    return <div className={`event-cell ${stateCategoryId} ${className}`} {...restProps} />;
  }
}

const renderColorRow = (rowData, cellText) => {
  return <ColorRow stateCategoryId={rowData.stateCategoryId}>{cellText}</ColorRow>;
};

const renderDeviceInfo = (deviceInfo, device) => (
  <div>
    <div key={'tooltip-title-description'}>{`${deviceInfo.description} (${
      device ? device.fullAddressPath : `...${deviceInfo.address}`
    })`}</div>
    {deviceInfo.states
      ? deviceInfo.states.map((deviceState, index) => <div key={index}>{deviceState}</div>)
      : null}
    {deviceInfo.monitorableValueViews && deviceInfo.monitorableValueViews.length > 0
      ? deviceInfo.monitorableValueViews.map(valueView => (
          <div key={valueView.id}>{`${valueView.name}: ${valueView.value} ${valueView.unit}`}</div>
        ))
      : null}
  </div>
);

function renderDescriptionCell(text, record, devicesHash) {
  const regionInfo = record.regionEventInfo;
  const deviceInfo = record.deviceEventInfo;
  const scenarioInfo = record.scenarioEventInfo;
  const employeeInfo = record.employeeEventInfo;
  const controlDeviceInfo = record.controlDeviceEventInfo;
  const projectInfo = record.projectEventInfo;
  const sourceOfMessage = record.sourceOfMessage;
  const virtualObjectEventInfo = record.virtualObjectEventInfo;
  const controlDeviceStates = controlDeviceInfo ? controlDeviceInfo.states : null;
  const lineNo = record?.lineEventInfo?.lineNo;
  if (
    regionInfo ||
    deviceInfo ||
    scenarioInfo ||
    employeeInfo ||
    controlDeviceStates ||
    projectInfo ||
    virtualObjectEventInfo ||
    lineNo
  ) {
    const projectEventTitle = projectInfo
      ? ` "${projectInfo.name}" (v ${projectInfo.version})`
      : '';
    return (
      <Tooltip
        title={() => (
          <div>
            {record.name}
            {regionInfo ? (
              <div key={'tooltip-title-regionInfo'}>
                <br />
                <div>{i18next.t('admin.logs.infoAboutZone')}:</div>
                <div>{regionInfo.description || regionInfo.name}</div>
                {regionInfo.states
                  ? regionInfo.states.map(regionState => <div>{regionState}</div>)
                  : null}
              </div>
            ) : null}
            {deviceInfo ? (
              <div key={'tooltip-title-deviceInfo'}>
                <br />
                <div>{i18next.t('admin.logs.infoAboutDevice')}:</div>
                {renderDeviceInfo(deviceInfo, devicesHash ? devicesHash[deviceInfo.idRef] : null)}
              </div>
            ) : null}
            {scenarioInfo ? (
              <div key={'tooltip-title-scenarioInfo'}>
                <br />
                <div>{i18next.t('admin.logs.infoAboutScenario')}:</div>
                <div>{scenarioInfo.description || scenarioInfo.name}</div>
                {scenarioInfo.states
                  ? scenarioInfo.states.map(scenarioState => <div>{scenarioState}</div>)
                  : null}
              </div>
            ) : null}
            {employeeInfo ? (
              <div key={'tooltip-title-employeeInfo'}>
                <br />
                <div>{i18next.t('admin.logs.infoAboutEmployee')}:</div>
                {`${employeeInfo.lastName} ${employeeInfo.firstName} ${employeeInfo.middleName}`}
              </div>
            ) : null}
            {controlDeviceStates ? (
              <div key={'tooltip-title-controlDeviceStates'}>
                <br />
                <div>{i18next.t('admin.logs.controlDeviceState')}:</div>
                {controlDeviceStates.map((deviceState, index) => (
                  <div key={index}>{deviceState}</div>
                ))}
              </div>
            ) : null}
            {projectInfo ? (
              <div key={'tooltip-title-projectInfo'}>
                <div>
                  {i18next.t('projects.projectName')}: {projectInfo.name}
                </div>
                <div>
                  {i18next.t('version')}: {projectInfo.version}
                </div>
              </div>
            ) : null}
            {sourceOfMessage ? (
              <div key={'tooltip-title-sourceOfMessage'}>
                <div>{i18next.t('monitoring.log.sourcesMessage.name')}:</div>
                <div>{getSourceOfMessageForRender(sourceOfMessage)}</div>
              </div>
            ) : null}
            {virtualObjectEventInfo &&
            virtualObjectEventInfo.entityType === ENTITY_TYPE.VIRTUAL_COUNTER ? (
              <div key={'tooltip-title-virtualObjectEventInfo'}>
                <div>
                  {i18next.t('monitoring.log.virtualCounterChange', {
                    value: virtualObjectEventInfo.virtualCounterValue
                  })}
                </div>
              </div>
            ) : null}
            {lineNo && (
              <div key={'tooltip-title-lineNo'}>
                <div>{i18next.t('monitoring.log.infoAboutLine')}:</div>
                <div>{i18next.t('monitoring.log.lineNo', { value: lineNo })}</div>
              </div>
            )}
          </div>
        )}
        placement="leftTop"
      >
        <ColorRow stateCategoryId={record.stateCategoryId}>
          {record.name + projectEventTitle}
        </ColorRow>
      </Tooltip>
    );
  } else {
    return (
      <ColorRow stateCategoryId={record.stateCategoryId} title={record.name}>
        {record.name}
      </ColorRow>
    );
  }
}

function renderControlDeviceCell(text, record, devicesHash) {
  const controlDeviceInfo = record.controlDeviceEventInfo;
  if (controlDeviceInfo) {
    const controlDeviceStates = controlDeviceInfo ? controlDeviceInfo.states : null;
    const rawEventData = controlDeviceInfo.rawEventDataHex;
    let controlDevice = null;
    if (devicesHash) controlDevice = devicesHash[controlDeviceInfo.idRef];
    const controlDeviceAddress = controlDevice
      ? controlDevice.fullAddressPath
      : `...${controlDeviceInfo.address}`;
    return (
      <Tooltip
        title={() => (
          <div key="controlDeviceInfo">
            <div>
              <div>{`${controlDeviceInfo.description} (${controlDeviceAddress})`}</div>
            </div>
            {controlDeviceStates && (
              <div>
                <br />
                <div>{i18next.t('admin.logs.controlDeviceState')}:</div>
                {controlDeviceStates.map((deviceState, index) => (
                  <div key={index}>{deviceState}</div>
                ))}
              </div>
            )}
            {rawEventData && (
              <div>
                <br />
                <div>{i18next.t('admin.logs.serviceInfo')}:</div>
                <div style={{ whiteSpace: 'pre-line' }}>{rawEventData}</div>
              </div>
            )}
          </div>
        )}
        placement="leftTop"
      >
        <ColorRow stateCategoryId={record.stateCategoryId}>
          {`${controlDeviceInfo.name}, ${controlDeviceAddress}`}
        </ColorRow>
      </Tooltip>
    );
  } else {
    return <ColorRow stateCategoryId={record.stateCategoryId} />;
  }
}

function renderAddressDeviceCell(text, record, devicesHash) {
  const deviceInfo = record.deviceEventInfo;
  if (deviceInfo) {
    let device = null;
    if (devicesHash) device = devicesHash[deviceInfo.idRef];
    return (
      <Tooltip
        title={() => <div key={'tooltip-title'}>{renderDeviceInfo(deviceInfo, device)}</div>}
        placement="leftTop"
      >
        <ColorRow stateCategoryId={record.stateCategoryId}>
          {`${deviceInfo.name}, ${deviceInfo.address}`}
        </ColorRow>
      </Tooltip>
    );
  } else {
    return <ColorRow stateCategoryId={record.stateCategoryId} />;
  }
}

function renderRegionCell(text, record) {
  const regionInfo = record.regionEventInfo;
  if (regionInfo) {
    return (
      <Tooltip
        title={() => (
          <div>
            <div>{regionInfo.description || regionInfo.name}</div>
            {regionInfo.states
              ? regionInfo.states.map(regionState => <div>{regionState}</div>)
              : null}
          </div>
        )}
        placement="leftTop"
      >
        <ColorRow stateCategoryId={record.stateCategoryId}>{regionInfo.name}</ColorRow>
      </Tooltip>
    );
  } else {
    return <ColorRow stateCategoryId={record.stateCategoryId} />;
  }
}

function renderScenarioCell(text, record) {
  const scenarioInfo = record.scenarioEventInfo;
  if (scenarioInfo) {
    return (
      <Tooltip
        title={() => (
          <div>
            <div>{scenarioInfo.description || scenarioInfo.name}</div>
            {scenarioInfo.states
              ? scenarioInfo.states.map(scenarioState => <div>{scenarioState}</div>)
              : null}
          </div>
        )}
        placement="leftTop"
      >
        <ColorRow stateCategoryId={record.stateCategoryId}>
          {record.scenarioEventInfo
            ? `${record.scenarioEventInfo.globalNo}. ${record.scenarioEventInfo.name}`
            : null}
        </ColorRow>
      </Tooltip>
    );
  } else {
    return (
      <ColorRow stateCategoryId={record.stateCategoryId}>
        {record.scenarioEventInfo
          ? `${record.scenarioEventInfo.globalNo}. ${record.scenarioEventInfo.name}`
          : null}
      </ColorRow>
    );
  }
}

function renderUserCell(record) {
  return (
    <Tooltip
      title={
        <div>
          <div>{i18next.t('monitoring.log.systemUser')}:</div>
          <div>{record.userEventInfo ? `${record.userEventInfo.name}` : null}</div>
        </div>
      }
      placement="leftTop"
    >
      <ColorRow stateCategoryId={record.stateCategoryId}>
        {record.userEventInfo ? `${record.userEventInfo.name}` : null}
      </ColorRow>
    </Tooltip>
  );
}

function renderEmployeeCell(record) {
  const employeeInfo = record.employeeEventInfo;
  if (employeeInfo) {
    return (
      <Tooltip
        title={
          <div>
            <div>{i18next.t('monitoring.log.employee')}:</div>
            <div>{`${employeeInfo.lastName} ${employeeInfo.firstName} ${employeeInfo.middleName}`}</div>
          </div>
        }
        placement="leftTop"
      >
        <ColorRow stateCategoryId={record.stateCategoryId}>{employeeInfo.name}</ColorRow>
      </Tooltip>
    );
  } else {
    return <ColorRow stateCategoryId={record.stateCategoryId} />;
  }
}

function renderInitiatorCell(record) {
  const sourceOfMessage = record.sourceOfMessage;
  if (sourceOfMessage) {
    return (
      <Tooltip
        title={
          <div>
            <div>{i18next.t('monitoring.log.sourcesMessage.name')}:</div>
            <div>{getSourceOfMessageForRender(sourceOfMessage)}</div>
          </div>
        }
        placement="leftTop"
      >
        <ColorRow stateCategoryId={record.stateCategoryId}>
          {getSourceOfMessageForRender(sourceOfMessage)}
        </ColorRow>
      </Tooltip>
    );
  } else {
    return <ColorRow stateCategoryId={record.stateCategoryId} />;
  }
}

const getColumnParams = memoizeOne(i18nextStore => ({
  [EVENT_TABLE_COLUMNS.INDEX]: {
    key: 'index',
    dataIndex: 'index',
    title: '№',
    width: 50,
    minWidth: 50
  },
  [EVENT_TABLE_COLUMNS.OCCURRED]: {
    key: 'occurred',
    dataIndex: 'occurred',
    title: i18next.t('monitoring.log.deviceTime'),
    width: 150,
    minWidth: 120
  },
  [EVENT_TABLE_COLUMNS.RECEIVED]: {
    key: 'received',
    dataIndex: 'received',
    title: i18next.t('monitoring.log.systemTime'),
    width: 150,
    minWidth: 130
  },
  [EVENT_TABLE_COLUMNS.DESCRIPTION]: {
    key: 'name',
    dataIndex: 'name',
    title: i18next.t('description'),
    width: 300,
    minWidth: 150
  },
  [EVENT_TABLE_COLUMNS.CONTROL_DEVICE]: {
    key: 'controlDeviceEventInfo',
    dataIndex: 'controlDeviceEventInfo.address',
    title: i18next.t('controlDevice'),
    width: 120,
    minWidth: 70
  },
  [EVENT_TABLE_COLUMNS.DEVICE]: {
    key: 'deviceEventInfo',
    dataIndex: 'deviceEventInfo.address',
    title: i18next.t('device'),
    width: 120,
    minWidth: 90
  },
  [EVENT_TABLE_COLUMNS.REGION]: {
    key: 'regionEventInfo',
    dataIndex: 'regionEventInfo.name',
    title: i18next.t('zone'),
    width: 180,
    minWidth: 60
  },
  [EVENT_TABLE_COLUMNS.VIRTUAL_OBJECT]: {
    key: 'virtualObjectEventInfo',
    dataIndex: 'virtualObjectEventInfo.name',
    title: i18next.t('virtualObject'),
    width: 180,
    minWidth: 155
  },
  [EVENT_TABLE_COLUMNS.SCENARIO]: {
    key: 'scenarioEventInfo',
    dataIndex: 'scenarioEventInfo.name',
    title: i18next.t('scenario'),
    width: 180,
    minWidth: 85
  },
  [EVENT_TABLE_COLUMNS.OBJECT]: {
    key: 'objectEventInfo',
    dataIndex: 'deviceEventInfo.address',
    title: i18next.t('object'),
    width: 180,
    minWidth: 100
  },
  [EVENT_TABLE_COLUMNS.USER]: {
    key: 'userEventInfo',
    dataIndex: 'userEventInfo.name',
    title: i18next.t('user'),
    minWidth: 110
  },
  [EVENT_TABLE_COLUMNS.EMPLOYEE]: {
    key: 'employeeEventInfo',
    dataIndex: 'employeeEventInfo.name',
    title: i18next.t('employee'),
    minWidth: 90
  },
  [EVENT_TABLE_COLUMNS.USER_AND_EMPLOYEE]: {
    key: 'userAndEmployee',
    dataIndex: 'employeeEventInfo.name',
    title: i18next.t('monitoring.log.userOrEmployee'),
    minWidth: 150
  }
}));

const getColumnRenderers = memoizeOne(devicesHash => ({
  [EVENT_TABLE_COLUMNS.INDEX]: {
    render: (text, rowData, index) => renderColorRow(rowData, `${index + 1}`)
  },
  [EVENT_TABLE_COLUMNS.OCCURRED]: {
    render: (text, rowData) =>
      renderColorRow(rowData, rowData.occurred ? getDateTimestamp(rowData.occurred) : '')
  },
  [EVENT_TABLE_COLUMNS.RECEIVED]: {
    render: (text, rowData) =>
      renderColorRow(rowData, rowData.received ? getDateTimestamp(rowData.received) : '')
  },
  [EVENT_TABLE_COLUMNS.DESCRIPTION]: {
    render: (text, rowData) => renderDescriptionCell(text, rowData, devicesHash)
  },
  [EVENT_TABLE_COLUMNS.CONTROL_DEVICE]: {
    render: (text, rowData) => renderControlDeviceCell(text, rowData, devicesHash)
  },
  [EVENT_TABLE_COLUMNS.DEVICE]: {
    render: (text, rowData) => renderAddressDeviceCell(text, rowData, devicesHash)
  },
  [EVENT_TABLE_COLUMNS.REGION]: {
    render: renderRegionCell
  },
  [EVENT_TABLE_COLUMNS.VIRTUAL_OBJECT]: {
    render: (text, rowData) =>
      renderColorRow(
        rowData,
        rowData.virtualObjectEventInfo ? rowData.virtualObjectEventInfo.name : ''
      )
  },
  [EVENT_TABLE_COLUMNS.SCENARIO]: {
    render: renderScenarioCell
  },
  [EVENT_TABLE_COLUMNS.OBJECT]: {
    render: (text, rowData, index) => {
      if (rowData.deviceEventInfo) return renderAddressDeviceCell(text, rowData, devicesHash);
      else if (rowData.virtualObjectEventInfo)
        return renderColorRow(rowData, rowData.virtualObjectEventInfo.name);
      else if (rowData.scenarioEventInfo) return renderScenarioCell(text, rowData);
      else return renderColorRow(rowData, '');
    }
  },
  [EVENT_TABLE_COLUMNS.USER]: {
    render: (text, rowData) => renderUserCell(rowData)
  },
  [EVENT_TABLE_COLUMNS.EMPLOYEE]: {
    render: (text, rowData) => renderEmployeeCell(rowData)
  },
  [EVENT_TABLE_COLUMNS.USER_AND_EMPLOYEE]: {
    render: (text, rowData) =>
      rowData.sourceOfMessage
        ? renderInitiatorCell(rowData)
        : rowData.employeeEventInfo
        ? renderEmployeeCell(rowData)
        : renderUserCell(rowData)
  }
}));

const htmlColumnRenderers = {
  [EVENT_TABLE_COLUMNS.INDEX]: {
    render: (text, rowData, index) => index
  },
  [EVENT_TABLE_COLUMNS.OCCURRED]: {
    render: (text, rowData) => (rowData.occurred ? getDateTimestamp(rowData.occurred) : '')
  },
  [EVENT_TABLE_COLUMNS.RECEIVED]: {
    render: (text, rowData) => (rowData.received ? getDateTimestamp(rowData.received) : '')
  },
  [EVENT_TABLE_COLUMNS.DESCRIPTION]: {
    render: (text, rowData) => rowData.name
  },
  [EVENT_TABLE_COLUMNS.CONTROL_DEVICE]: {
    render: (text, rowData) =>
      rowData.controlDeviceEventInfo && rowData.controlDeviceEventInfo.name !== ''
        ? `${rowData.controlDeviceEventInfo.name}, ${rowData.controlDeviceEventInfo.address}`
        : ''
  },
  [EVENT_TABLE_COLUMNS.DEVICE]: {
    render: (text, rowData) =>
      rowData.deviceEventInfo
        ? `${rowData.deviceEventInfo.name}, ${rowData.deviceEventInfo.address}`
        : ''
  },
  [EVENT_TABLE_COLUMNS.REGION]: {
    render: (text, rowData) => (rowData.regionEventInfo ? `${rowData.regionEventInfo.name}` : '')
  },
  [EVENT_TABLE_COLUMNS.VIRTUAL_OBJECT]: {
    render: (text, rowData) =>
      rowData.virtualObjectEventInfo ? rowData.virtualObjectEventInfo.name : ''
  },
  [EVENT_TABLE_COLUMNS.SCENARIO]: {
    render: (text, rowData) => (rowData.scenarioEventInfo ? rowData.scenarioEventInfo.name : '')
  },
  [EVENT_TABLE_COLUMNS.OBJECT]: {
    render: (text, rowData) => {
      if (rowData.deviceEventInfo)
        return `${rowData.deviceEventInfo.name}, ${rowData.deviceEventInfo.address}`;
      else if (rowData.virtualObjectEventInfo) return rowData.virtualObjectEventInfo.name;
      else if (rowData.scenarioEventInfo) return rowData.scenarioEventInfo.name;
      else return '';
    }
  },
  [EVENT_TABLE_COLUMNS.USER]: {
    render: (text, rowData) => (rowData.userEventInfo ? rowData.userEventInfo.name : '')
  },
  [EVENT_TABLE_COLUMNS.EMPLOYEE]: {
    render: (text, rowData) => (rowData.employeeEventInfo ? rowData.employeeEventInfo.name : '')
  },
  [EVENT_TABLE_COLUMNS.USER_AND_EMPLOYEE]: {
    render: (text, rowData) =>
      rowData.sourceOfMessage ||
      (rowData.employeeEventInfo
        ? rowData.employeeEventInfo.name
        : rowData.userEventInfo
        ? rowData.userEventInfo.name
        : '')
  }
};

export const getColumns = memoizeOne((columnIds, devicesHash, sorter, htmlRender = false) =>
  columnIds.map((columnId, idx) => {
    const currentColumnParams = getColumnParams(i18next.store)[columnId];
    return {
      ...currentColumnParams,
      ...(htmlRender ? htmlColumnRenderers[columnId] : getColumnRenderers(devicesHash)[columnId]),
      /*
       * Первая колонка - номер строки, по ней не нужна сортировка.
       * В остальных случаях ориентируемся на аргумент sorter.
       */
      sorter: idx > 0 ? !!sorter : undefined,
      sortOrder: sorter && sorter.field === currentColumnParams.dataIndex ? sorter.order : false
    };
  })
);

function getSourceOfMessageForRender(sourceOfMessage) {
  return i18next.exists(`monitoring.log.sourcesMessage.${sourceOfMessage}`)
    ? i18next.t(`monitoring.log.sourcesMessage.${sourceOfMessage}`)
    : sourceOfMessage;
}
