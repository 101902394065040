import { UPDATED_ENTITIES, CHANGE_SOUND_CONDITION } from 'constants/actionTypes';

/**
 * @property {boolean} state - состояние контроллера звука:
 * true - звук включен,
 * false - звук выключен
 *  */

export default function soundCondition(state = true, { type, payload }) {
  switch (type) {
    case UPDATED_ENTITIES: {
      const { events } = payload;
      if (events?.length) {
        const isAnyNewEventsForcePlayingSound = events.some(
          event => event.forcePlayingSound === true
        );
        if (isAnyNewEventsForcePlayingSound) return true;
      }
      return state;
    }
    case CHANGE_SOUND_CONDITION: {
      const { newCondition } = payload;
      return newCondition;
    }
    default:
      return state;
  }
}
