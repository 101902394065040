import {
  STATE_CATEGORY_VIEWS_LOAD, STATES_LOAD, STATE_VIEWS_LOAD,
} from 'constants/actionTypes';

import { createAction } from 'redux-actions';

export const loadStateCategoryViews = createAction(STATE_CATEGORY_VIEWS_LOAD);

export const loadStates = createAction(STATES_LOAD);

export const loadStateViews = createAction(STATE_VIEWS_LOAD);
