/* Планы помещений и группы планов */

/**
 * Загрузить все планы помещений активного проекта.
 *
 * Payload: {
 *  projectId: <string>
 * }
 *
 * Store: state.activeProject.plans
 **/
export const PLANS_LOAD_ACTIVE = 'PLANS_LOAD_ACTIVE';
/**
 * Загрузить все группы помещений активного проекта.
 *
 * Payload: {
 *  projectId: <string>
 * }
 *
 * Store: state.activeProject.plan_groups
 **/
export const PLAN_GROUPS_LOAD_ACTIVE = 'PLAN_GROUPS_LOAD_ACTIVE';
/**
 * Загрузить все группы планов текущего проекта.
 *
 * Payload: {
 *  projectId: <string>
 * }
 *
 * REST-метод: GET /api/v1/projects/{projectId}/plans/
 *
 * Store: state.plan_groups
 **/
export const PLANS_LOAD = 'PLANS_LOAD';
/**
 * Загрузить все группы планов текущего проекта.
 *
 * Payload: {
 *  projectId: <string>
 * }
 *
 * REST-метод: GET /api/v1/projects/{projectId}/plan_groups/
 *
 * Store: state.plan_groups
 **/
export const PLAN_GROUPS_LOAD = 'PLAN_GROUPS_LOAD';
/**
 * Создание группы планов
 * REST-метод: POST /api/v1/projects/{projectId}/plan_groups/
 **/
export const PLAN_GROUP_CREATE = 'PLAN_GROUP_CREATE';
/**
 * Изменение параметров группы (только название и описание можно изменить)
 * REST-метод: PUT /api/v1/projects/{projectId}/plan_groups/<planGroupId>
 **/
export const PLAN_GROUP_UPDATE = 'PLAN_GROUP_UPDATE';
/**
 * Удаление группы планов
 * REST-метод: DELETE /api/v1/projects/{projectId}/plan_groups/<planGroupId>
 **/
export const PLAN_GROUP_DELETE = 'PLAN_GROUP_DELETE';
/**
 * Создание плана
 * REST-метод: POST /api/v1/projects/{projectId}/plans
 **/
export const PLAN_CREATE = 'PLAN_CREATE';

/**
 * Копирование и вставка фоновых изображений плана
 * REST-метод: POST /api/v1/projects/{projectId}/plans/{planId}/backgrounds
 **/
export const COPY_AND_PASTE_BACKGROUNDS_ON_PLAN = 'COPY_AND_PASTE_BACKGROUNDS_ON_PLAN';
/**
 * Изменение плана
 * REST-метод: PUT /api/v1/projects/{projectId}/plans/{planId}
 **/
export const PLAN_UPDATE = 'PLAN_UPDATE';
/**
 * Удаление плана
 * REST-метод: DELETE /api/v1/projects/{projectId}/plans/{planId}
 **/
export const PLAN_DELETE = 'PLAN_DELETE';

/**
 * Получение всех фоновых изображений плана
 * REST-метод: GET /api/v1/projects/{projectId}/plans/{planId}/backgrounds
 **/
export const PLAN_LOAD_BACKGROUNDS = 'PLAN_GET_BACKGROUNDS';

/**
 * Добавление фоновых изображений плана
 * REST-метод: POST /api/v1/projects/{projectId}/plans/{planId}/backgrounds
 **/
export const PLAN_ADD_BACKGROUNDS = 'PLAN_ADD_BACKGROUNDS';

/**
 * Удаление фонового изображения плана
 * REST-метод: DELETE /api/v1/projects/{projectId}/plans/{planId}/background/{imageId}
 **/
export const PLAN_DELETE_BACKGROUND = 'PLAN_DELETE_BACKGROUND';

/**
 * Удаление объектов плана (за исключением, зон и устройств)
 * REST-метод: DELETE /api/v1/projects/${projectId}/plans/${planId}/objects/${objectsIds}
 **/
export const PLAN_DELETE_OBJECTS = 'PLAN_DELETE_OBJECTS';

/** Найти устройство на плане */
export const PLAN_FIND_DEVICE = 'PLAN_FIND_DEVICE';

/** Выбор активного плана */
export const PLAN_SELECTED = 'PLAN_SELECTED';

/** Добавление текста на план */
export const PLAN_ADD_TEXT = 'PLAN_ADD_TEXT';

/** Копирование текста на плане */
export const COPY_AND_PASTE_TEXTS_ON_PLAN = 'COPY_AND_PASTE_TEXTS_ON_PLAN';
/** Копирование и вставка объектов на плане */
export const COPY_AND_PASTE_OBJECTS_ON_PLAN = 'COPY_AND_PASTE_OBJECTS_ON_PLAN';
/** Вырезание и вставка объектов на плане */
export const CUT_AND_PASTE_OBJECTS_ON_PLAN = 'CUT_AND_PASTE_OBJECTS_ON_PLAN';
/** Обновление текста на плане */
export const PLAN_UPDATE_TEXT = 'PLAN_UPDATE_TEXT';
/** удаление текста с плана */
export const PLAN_DELETE_TEXT = 'PLAN_DELETE_TEXT';

/**
 * См. описание REST-методов по группам и планам
 * https://docs.google.com/document/d/19fDadv_CBaeP-RlnnSvsjpdx7BCqSeax4MlCHjeEW28/edit#heading=h.kphw4ohe6rcn
 **/
