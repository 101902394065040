import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { clearUnimportantStorageData, localStorage } from 'helpers/storage';

/**
 * Компонент сравнивает версию приложения с сохраненной версией, если версии не совпадают,
 *  то очищает локальное хранилище, чтобы избежать различных конфликтов
 * @param appInfo - информация о приложении, содержащая версию
 */
function StorageController({ appInfo }) {
  const [appVersion, setAppVersion] = useState(null);

  useEffect(() => {
    const version = localStorage.getItem(localStorage.keys.APP_VERSION);
    if (version) setAppVersion(version);
    else setAppVersion('');
  }, []);

  useEffect(() => {
    if (appInfo.version && ((appVersion && appVersion !== appInfo.version) || appVersion === '')) {
      clearUnimportantStorageData(() => {
        setAppVersion(appInfo.version);
        localStorage.setItem(localStorage.keys.APP_VERSION, appInfo.version);
      });
    }
  }, [appInfo.version, appVersion]);

  return null;
}

const mapStateToProps = state => {
  return {
    appInfo: state.dictionaries.application
  };
};

export default connect(mapStateToProps, null)(StorageController);
