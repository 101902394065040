
import React from 'react';

const Text = ({ color = '#fff', width = 512, height = 512, x = 512, y = 512 }) => {
  return (
    <svg width="512" height="512" viewBox="0 0 512 512">
      <polygon
        fill={color}
        points="440 168 440 88 72 88 72 168 104 168 136 168 136 120 224 120 224 400 168 400 168 432 344 432 344 400 288 400 288 120 376 120 376 168 408 168 440 168"
        className="ci-primary"
      />
    </svg>
  );
};

export default Text;