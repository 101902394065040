import { createAction } from 'redux-actions';

import {
  INDICATOR_GROUP_ADD,
  INDICATOR_GROUP_DELETE,
  INDICATOR_GROUP_SELECT,
  INDICATOR_GROUP_UPDATE,
  INDICATOR_PANEL_ADD,
  INDICATOR_PANEL_DELETE,
  INDICATOR_PANEL_UPDATE,
  INDICATOR_PANEL_SELECT,
  INDICATOR_UPDATE,
  INDICATOR_SELECT,
  INDICATOR_RESET_SELECTED,
  INDICATOR_CHANGE_DEVICES_POLLING_STATE,
  INDICATOR_CHANGE_REGIONS_POLLING_STATE,
  INDICATOR_CHANGE_REGIONS_GUARD,
  INDICATOR_PERFORM_SCENARIO_ACTION,
  INDICATOR_PERFORM_DEVICE_ACTION,
  INDICATOR_GROUP_AND_PANEL_SELECT,
  INDICATOR_PAGE_SELECT
} from 'constants/actionTypes';

export const addIndicatorGroup = createAction(
  INDICATOR_GROUP_ADD,
  (projectId, newIndicatorGroup) => ({ projectId, newIndicatorGroup })
);
export const deleteIndicatorGroup = createAction(
  INDICATOR_GROUP_DELETE,
  (projectId, indicatorGroupId, nextIndicatorGroupId) => ({
    projectId,
    indicatorGroupId,
    nextIndicatorGroupId
  })
);
export const selectIndicatorGroup = createAction(
  INDICATOR_GROUP_SELECT,
  indicatorGroupId => indicatorGroupId
);
export const updateIndicatorGroup = createAction(
  INDICATOR_GROUP_UPDATE,
  (projectId, indicatorGroupId, updatedIndicatorGroup) => ({
    projectId,
    indicatorGroupId,
    updatedIndicatorGroup
  })
);

export const addIndicatorPanel = createAction(
  INDICATOR_PANEL_ADD,
  (projectId, newIndicatorPanel) => ({ projectId, newIndicatorPanel })
);
export const deleteIndicatorPanel = createAction(
  INDICATOR_PANEL_DELETE,
  (projectId, indicatorPanelId) => ({ projectId, indicatorPanelId })
);
export const updateIndicatorPanel = createAction(
  INDICATOR_PANEL_UPDATE,
  (projectId, updatedIndicatorPanel) => ({ projectId, updatedIndicatorPanel })
);
export const selectIndicatorPanel = createAction(
  INDICATOR_PANEL_SELECT,
  indicatorPanelId => indicatorPanelId
);

export const updateIndicator = createAction(
  INDICATOR_UPDATE,
  (projectId, indicatorPanelId, updatedIndicator) => ({
    projectId,
    indicatorPanelId,
    updatedIndicator
  })
);
export const selectIndicator = createAction(INDICATOR_SELECT, indicator => indicator);
export const resetSelectedIndicator = createAction(INDICATOR_RESET_SELECTED);

export const changeIndicatorDevicesPollingState = createAction(
  INDICATOR_CHANGE_DEVICES_POLLING_STATE,
  (projectId, indicatorPanelId, indicator, state, password) => ({
    projectId,
    indicatorPanelId,
    indicator,
    state,
    password
  })
);
export const changeIndicatorRegionsPollingState = createAction(
  INDICATOR_CHANGE_REGIONS_POLLING_STATE,
  (projectId, indicatorPanelId, indicator, state, password) => ({
    projectId,
    indicatorPanelId,
    indicator,
    state,
    password
  })
);
export const changeIndicatorRegionsGuard = createAction(
  INDICATOR_CHANGE_REGIONS_GUARD,
  (projectId, indicatorPanelId, indicator, state, password) => ({
    projectId,
    indicatorPanelId,
    indicator,
    state,
    password
  })
);
export const performIndicatorScenarioAction = createAction(
  INDICATOR_PERFORM_SCENARIO_ACTION,
  (projectId, indicatorPanelId, indicator, actionId, password) => ({
    projectId,
    indicatorPanelId,
    indicator,
    actionId,
    password
  })
);
export const performIndicatorDeviceAction = createAction(
  INDICATOR_PERFORM_DEVICE_ACTION,
  (projectId, indicatorPanelId, indicator, actionClass, password) => ({
    projectId,
    indicatorPanelId,
    indicator,
    actionClass,
    password
  })
);

export const selectIndicatorGroupAndPanel = createAction(
  INDICATOR_GROUP_AND_PANEL_SELECT,
  (groupId, panelId) => ({ groupId, panelId })
);

export const selectIndicatorsPage = createAction(INDICATOR_PAGE_SELECT, pageNo => pageNo);
