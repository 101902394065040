import {
  PROJECT_LOAD_ACTIVE,
  PROJECT_UPDATE_STATUS,
  ACTIVE_SCENARIO_UPDATED,
  ACTIVE_SCENARIOS_UPDATED,
  PROJECT_LOAD_CURRENT,
  isLoaded
} from 'constants/actionTypes';

/**
 * state = scenarios: {
 *   <scenarioId>: {
 *    id: <string>,
 *    ... (см. метод GET api/v1/projects/<projectId>/active_scenarios в тех. проекте)
 *   },
 *   ...
 * }
 **/
export default function scenarios(state = null, { type, payload }) {
  switch (type) {
    case isLoaded(PROJECT_LOAD_ACTIVE, true): {
      const { scenarios } = payload;
      const newState = {};
      scenarios.forEach(scenario => {
        newState[scenario.id] = scenario;
      });
      return newState;
    }
    case isLoaded(PROJECT_LOAD_CURRENT, true): {
      const { project, scenarios } = payload;
      if (project.status === 'ACTIVE') {
        const newState = {};
        scenarios.forEach(scenario => {
          newState[scenario.id] = scenario;
        });
        return newState;
      } else {
        return state;
      }
    }
    case isLoaded(PROJECT_UPDATE_STATUS, true): {
      const { project, scenarios } = payload;
      if (project.status === 'ACTIVE') {
        const newState = {};
        scenarios.forEach(scenario => {
          newState[scenario.id] = scenario;
        });
        return newState;
      } else {
        return null;
      }
    }

    case ACTIVE_SCENARIO_UPDATED: {
      const updatedScenario = payload;
      if (!state) return null;
      if (!state[updatedScenario.id]) return state;

      const scenariosHash = { ...state };
      scenariosHash[updatedScenario.id] = updatedScenario;
      return scenariosHash;
    }

    case ACTIVE_SCENARIOS_UPDATED: {
      const updatedScenarios = payload;
      if (!state) return null;
      if (updatedScenarios.length === 0) return state;

      const scenariosHash = { ...state };
      updatedScenarios.forEach(updatedScenario => {
        if (scenariosHash[updatedScenario.id]) scenariosHash[updatedScenario.id] = updatedScenario;
      });
      return scenariosHash;
    }

    default: {
      return state;
    }
  }
}
