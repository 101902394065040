import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import { ConnectedRouter } from 'connected-react-router';
import { Switch, Route } from 'react-router';

import { createBrowserHistory } from 'history';

import { loadCurrentUserInfo } from 'actions/login';

import configureStore from 'store/configureStore';
import { createOnStorage } from 'middlewares/storageMiddleware';
import Layout from './Layout';
import AppLanguageController from './AppLanguageController';

const history = createBrowserHistory();

const store = configureStore(history);
const onStorage = createOnStorage(store);
window.addEventListener('storage', onStorage);
const securityOn = process.env.REACT_APP_SECURITY_ON === 'true';

export const AppContext = React.createContext({ store });

class App extends Component {
  constructor(props) {
    super(props);

    this.locale = null;
  }

  componentDidMount() {
    if (securityOn) {
      store.dispatch(loadCurrentUserInfo());
    }
  }

  languageApplied = lng => {
    this.initAntdLocale(lng);
    this.forceUpdate();
  };

  initAntdLocale(lng) {
    switch (lng) {
      case 'ru-RU':
        this.locale = require(`antd/lib/locale-provider/ru_RU`).default;
        break;
      default:
      case 'en-US':
        this.locale = require(`antd/lib/locale-provider/en_US`).default;
        break;
    }
  }

  render() {
    return (
      <Provider store={store}>
        <AppLanguageController languageApplied={this.languageApplied} />
        {this.locale ? (
          <ConfigProvider locale={this.locale}>
            <ConnectedRouter history={history}>
              <Switch>
                <Route path="/" component={Layout} />
              </Switch>
            </ConnectedRouter>
          </ConfigProvider>
        ) : null}
      </Provider>
    );
  }
}

export default App;
