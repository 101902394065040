import React from 'react';

const Shield = ({ color = "#5FA2DD", x = "15", y = "16" }) => {
  return (
    <svg width={x} height={y} viewBox={`0 0 ${x} ${y}`} >
      <path fill={color} fillRule="evenodd" d="M14.745 3.343a.727.727 0 0 0-.662-.713C11.003 2.342 8.49.697 7.663.095a.493.493 0 0 0-.58 0C6.254.697 3.742 2.342.662 2.63a.728.728 0 0 0-.66.713c-.038 2.429.352 10.674 7.184 12.509a.72.72 0 0 0 .37 0c6.833-1.834 7.223-10.08 7.188-12.509z"/>
    </svg>
  )
}

export default Shield;
