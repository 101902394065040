import {
  DEVICE_TREE_CHANGE_VIEW_MODE,
  CURRENT_ENTITY_INFO_UPDATE,
  REGIONS_CHANGE_FILTER,
  ADD_FILTER,
  SET_FILTERS,
  SET_TIMESTAMP,
  SET_PRIORITY_MODE
} from 'constants/actionTypes';

import { createAction } from 'redux-actions';

export const deviceTreeChangeViewMode = createAction(DEVICE_TREE_CHANGE_VIEW_MODE, mode => mode);

export const updateCurrentEntityInfo = createAction(
  CURRENT_ENTITY_INFO_UPDATE,
  entityInfo => entityInfo
);
export const changeRegionsFilter = createAction(REGIONS_CHANGE_FILTER, filter => filter);

/**
 * @param name - название фильтра
 * @param value - массив с идентификаторами для фильтрации
 */
export const addFilter = createAction(ADD_FILTER, (name, values) => ({ name, values }));

/**
 * @param filtersMap - Map<String, Array>, где
 *   key - название фильтра, value - массив с идентификаторами для фильтрации
 */
export const setFilters = createAction(SET_FILTERS, filtersMap => filtersMap);

/**
 * Устанавливает временную метку для последующего использования
 * @param name {String} - название метки времени
 * @param value {Number} - время
 */
export const setTimestamp = createAction(SET_TIMESTAMP, (name, value) => ({ name, value }));

/**
 * Переключает режим перехода на страницу с планом при появлении событий
 * @param newState {Booleans} - название метки времени
 */
export const setPriorityMode = createAction(SET_PRIORITY_MODE, newState => newState);
