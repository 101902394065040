import {
  DEVICE_TREE_CHANGE_VIEW_MODE,
  REGION_VIEWER_UPDATE_CURRENT,
  PROJECT_UPDATE_STATUS,
  ACTIVE_REGION_UPDATED,
  ACTIVE_REGIONS_UPDATED,
  PROJECT_LOAD_ACTIVE,
  isLoaded
} from 'constants/actionTypes';

export const ViewModes = {
  ALL: 'all',
  REGIONS: 'regions'
};

const defaultState = {
  viewMode: ViewModes.ALL,
  currentRegion: {},
  regions: []
};

export default function deviceTree(state = defaultState, { type, payload }) {
  switch (type) {
    case DEVICE_TREE_CHANGE_VIEW_MODE: {
      const newViewMode = payload;
      if (newViewMode === ViewModes.ALL) {
        return { ...state, viewMode: newViewMode, currentRegion: {} };
      }
      return {
        ...state,
        currentRegion: {},
        viewMode: newViewMode
      };
    }
    case isLoaded(PROJECT_UPDATE_STATUS, true): {
      return {
        ...state,
        regions: payload.regions
      };
    }
    case REGION_VIEWER_UPDATE_CURRENT: {
      return {
        ...state,
        currentRegion: payload
      };
    }

    case ACTIVE_REGION_UPDATED: {
      const updatedRegion = payload;
      const regions = [...state.regions];
      for (let i = 0; i < regions.length; ++i) {
        if (regions[i].id === updatedRegion.id) {
          regions[i] = updatedRegion;
          break;
        }
      }
      return {
        ...state,
        regions
      };
    }

    case ACTIVE_REGIONS_UPDATED: {
      const updatedRegions = payload;
      const regions = [...state.regions];
      for (let i = 0; i < regions.length; ++i) {
        const updatedRegion = updatedRegions.find(
          updatedRegion => updatedRegion.id === regions[i].id
        );
        if (updatedRegion) regions[i] = updatedRegion;
      }
      return {
        ...state,
        regions
      };
    }

    case isLoaded(PROJECT_LOAD_ACTIVE, true): {
      return {
        ...state,
        regions: payload.regions
      };
    }
    default:
      return state;
  }
}
