import { Button, message as antdMessage } from 'antd';
import { SaveTwoTone } from '@ant-design/icons';
import React, { useState } from 'react';
import { Alert, Space } from './index';
import { saveAs } from 'file-saver';
import Moment from 'moment';
import styles from './message.module.css';
import i18next from 'i18next';

const errorDuration = Number(process.env.REACT_APP_NOTIFY_ERROR_DURATION);
const loadingDuration = Number(process.env.REACT_APP_NOTIFY_LOADING_DURATION);
const successDuration = Number(process.env.REACT_APP_NOTIFY_SUCCESS_DURATION);

const message = (type, text, duration) => {
  if (!duration) {
    if (type === 'error') duration = errorDuration;
    else if (type === 'loading') duration = loadingDuration;
    else duration = successDuration;
  }

  antdMessage[type]({ content: text, duration: Number(duration), style: { userSelect: 'text' } });
};

message.error = function(text, duration) {
  if (!duration) duration = errorDuration;
  antdMessage.error(text, duration);
};

message.success = function(text, duration) {
  if (!duration) duration = successDuration;
  antdMessage.success(text, duration);
};

message.loading = function(text, duration) {
  if (!duration) duration = loadingDuration;
  antdMessage.loading(text, duration);
};

message.info = antdMessage.info;
message.warning = antdMessage.warning;
message.destroy = antdMessage.destroy;

/**
 * Ошибка, которая может содержать в себе большие дополнительные данные (stacktrace и т.д.)
 * @param key {String | Number} - идентификатор сообщения об ошибке {Опционально}
 * @param header {String} - заголовок ошибки
 * @param text {String} - тело ошибки
 * @param code {Number} - код ошибки {Опционально}
 * @param onClose - вызывается при закрытии сообщения с ошибкой {Опционально}
 */
message.expandableError = (key, header = '', text = '', code = 0, onClose) => {
  const config = {
    key,
    icon: <div />,
    duration: 0,
    content: (
      <ServerErrorContent
        code={code}
        text={text}
        header={header}
        onClose={e => {
          message.destroy(key);
          if (onClose) onClose(e);
        }}
      />
    )
  };
  message.error(config);
};

const ServerErrorContent = ({ header, text = '', code, onClose }) => {
  const [isDetailsShoved, setShovedDetails] = useState(false);

  const strStart = '<body>';
  const strEnd = '</body>';
  //Если это HTML и имеется тело, то избавляемся от обертки
  const startIndex = text.indexOf(strStart) + strStart.length;
  const endIndex = text.lastIndexOf(strEnd);
  let str = text;
  if (startIndex !== -1 && endIndex !== -1) {
    str = text.substr(startIndex, endIndex);
  }

  return (
    <div
      onDoubleClick={() => {
        if (str) setShovedDetails(!isDetailsShoved);
      }}
    >
      <Alert
        message={
          <span>
            <>{header}</> <b>{code ? i18next.t('code', { code: code }) : ''}</b>
          </span>
        }
        onClose={onClose}
        description={
          isDetailsShoved ? (
            <div>
              <div
                className={styles.details}
                dangerouslySetInnerHTML={{ __html: '<div>' + str + '</div>' }}
              />
              <Space className={styles.buttons_group}>
                <Button size="small" danger onClick={() => setShovedDetails(!isDetailsShoved)}>
                  {i18next.t('buttons.hideDetails')}
                </Button>
                <Button size="small" type="primary" onClick={() => saveText(text, code)}>
                  {i18next.t('buttons.save')}
                </Button>
              </Space>
            </div>
          ) : null
        }
        type="error"
        showIcon={!isDetailsShoved}
        action={
          <Space className={styles.details_btn_group}>
            {str ? (
              <Button size="small" danger onClick={() => setShovedDetails(!isDetailsShoved)}>
                {isDetailsShoved ? '^' : i18next.t('buttons.showDetails') + ' v'}
              </Button>
            ) : null}
            {str && !isDetailsShoved ? (
              <SaveTwoTone
                className={styles.save_icon}
                twoToneColor="#3787C4"
                onClick={() => saveText(text, code)}
              />
            ) : null}
          </Space>
        }
        closable
      />
    </div>
  );
};

const saveText = (text, code) => {
  const blob = new Blob([text], { type: 'text/plain;charset=utf-8' });
  const now = new Moment().format('DD-MM-YYYY-hh:mm:ss(a)');
  saveAs(blob, `${now}_report_error` + (code ? `(${code})` : '') + '.html');
};

export default message;
