import {
  VIRTUAL_STATES_LOAD,
  VIRTUAL_STATE_CREATE,
  VIRTUAL_STATE_UPDATE,
  VIRTUAL_STATE_DELETE,
  VIRTUAL_STATES_DELETE,
  isLoaded,
  PROJECT_DELETE,
  PROJECT_LOAD_CURRENT,
  PROJECT_LOAD_ACTIVE,
  PROJECT_UPDATE_STATUS,
  DEVICE_IMPORT_CONTOL_CONFIG, VIRTUAL_STATE_PASTE
} from 'constants/actionTypes';

/**
 * state = virtualStates: {
 *    <projectId>: {
 *        <virtualStateId>: {
 *          id: <string>,
 *          ...
 *       }
 *    }
 * }
 * (см. метод GET api/v1/projects/<projectId>/virtual_states в тех. проекте)
 */
export default function virtualStates(state = {}, action) {
  switch (action.type) {
    case isLoaded(VIRTUAL_STATES_LOAD, true): {
      const { projectId, virtualStates } = action.payload;
      const newState = { ...state };
      if (!newState[projectId]) {
        newState[projectId] = {};
      }
      newState[projectId] = {};
      virtualStates.forEach(virtualState => {
        newState[projectId][virtualState.id] = virtualState;
      });
      return newState;
    }
    case isLoaded(DEVICE_IMPORT_CONTOL_CONFIG, true): {
      const {
        projectId,
        virtualStates
      } = action.payload;
      const createdVirtualStates = virtualStates?.created;
      if (!state[projectId] || !createdVirtualStates) {
        return state;
      }
      const newState = { ...state };
      newState[projectId] = { ...newState[projectId] };
      if (createdVirtualStates && createdVirtualStates.length) {
        createdVirtualStates.forEach(
          createdVirtualState => (newState[projectId][createdVirtualState.id] = createdVirtualState)
        );
      }
      return newState;
    }
    case isLoaded(VIRTUAL_STATE_PASTE, true):
    case isLoaded(VIRTUAL_STATE_CREATE, true): {
      const { projectId, newVirtualStates } = action.payload;
      if (!state[projectId]) return state;
      const newState = { ...state };
      newState[projectId] = { ...state[projectId] };
      newVirtualStates.forEach(vs => (newState[projectId][vs.id] = vs));
      return newState;
    }
    case isLoaded(VIRTUAL_STATE_DELETE, true): {
      const { virtualStateId, projectId } = action.payload;
      if (!state[projectId]) return state;
      if (!state[projectId][virtualStateId]) return state;
      const newState = { ...state };
      newState[projectId] = { ...state[projectId] };
      delete newState[projectId][virtualStateId];
      return newState;
    }
    case isLoaded(VIRTUAL_STATES_DELETE, true): {
      const { virtualStateIds, projectId } = action.payload;
      if (!state[projectId]) return state;
      const newState = { ...state };
      newState[projectId] = { ...state[projectId] };
      if (virtualStateIds && virtualStateIds.length) {
        virtualStateIds.forEach(virtualStateId => {
          delete newState[projectId][virtualStateId];
        });
      }
      return newState;
    }
    case isLoaded(VIRTUAL_STATE_UPDATE, true): {
      const { updatedVirtualStates, projectId } = action.payload;
      if (!state[projectId]) return state;
      const newState = { ...state };
      for (const updatedVirtualState of updatedVirtualStates) {
        newState[projectId] = { ...state[projectId] };
        newState[projectId][updatedVirtualState.id] = updatedVirtualState;
      }
      return newState;
    }
    case isLoaded(PROJECT_LOAD_CURRENT, true):
    case isLoaded(PROJECT_LOAD_ACTIVE, true):
    case isLoaded(PROJECT_UPDATE_STATUS, true): {
      const { project, virtualStates } = action.payload;
      const newState = { ...state };
      if (!newState[project.id]) {
        newState[project.id] = {};
      }
      newState[project.id] = {};
      virtualStates.forEach(virtualState => {
        newState[project.id][virtualState.id] = virtualState;
      });
      newState[project.id] = { ...newState[project.id] };
      return newState;
    }
    case isLoaded(PROJECT_DELETE, true): {
      const projectId = action.payload;
      if (!state[projectId]) return state;
      const newState = { ...state };
      delete newState[projectId];
      return newState;
    }
    default: {
      return state;
    }
  }
}
