import {
  DEVICE_SHAPE_ADD,
  DEVICE_SHAPE_LIBRARIES_LOAD,
  DEVICE_SHAPE_LIBRARY_ADD,
  DEVICE_SHAPE_LIBRARY_REMOVE,
  DEVICE_SHAPE_LIBRARY_REMOVE_SELECTION,
  DEVICE_SHAPE_LIBRARY_SELECT,
  DEVICE_SHAPE_LIBRARY_UPDATE,
  DEVICE_SHAPE_REMOVE,
  DEVICE_SHAPE_UPDATE,
  DEVICE_SHAPES_LOAD
} from 'constants/actionTypes';
import { createAction } from 'redux-actions';

export const loadDeviceShapeLibraries = createAction(DEVICE_SHAPE_LIBRARIES_LOAD);

export const addDeviceShapeLibrary = createAction(DEVICE_SHAPE_LIBRARY_ADD, deviceShapeLibrary => ({
  deviceShapeLibrary
}));

export const updateDeviceShapeLibrary = createAction(
  DEVICE_SHAPE_LIBRARY_UPDATE,
  (deviceShapeLibraryId, deviceShapeLibrary) => ({ deviceShapeLibraryId, deviceShapeLibrary })
);

export const removeDeviceShapeLibrary = createAction(
  DEVICE_SHAPE_LIBRARY_REMOVE,
  deviceShapeLibraryId => ({ deviceShapeLibraryId })
);

export const loadDeviceShapes = createAction(DEVICE_SHAPES_LOAD);

export const addDeviceShape = createAction(DEVICE_SHAPE_ADD, deviceShape => ({deviceShape}));

export const updateDeviceShape = createAction(
  DEVICE_SHAPE_UPDATE,
  (deviceShapeId, deviceShape) => ({
    deviceShapeId,
    deviceShape
  })
);

export const removeDeviceShape = createAction(DEVICE_SHAPE_REMOVE, deviceShapeId => ({
  deviceShapeId
}));

export const selectTreeItem = createAction(DEVICE_SHAPE_LIBRARY_SELECT, treeItem => treeItem);

export const removeTreeItem = createAction(DEVICE_SHAPE_LIBRARY_REMOVE_SELECTION);
