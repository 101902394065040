import { createAction } from 'redux-actions';
import {
  SET_CPIU_PIN_TO_PANEL_STATUS,
  UPDATE_CPIU_DEVICE,
  SET_CPIU_LOGIC_ENTITIES,
  GET_CPIU_HANDLE_MODE_SCENARIOS,
  SET_CPIU_FILTERS_VISIBILITY,
  SET_CPIU_ENTITIES_BY_PLAN_ID,
  SET_CPIU_CURRENT_SCENARIO,
  SET_CPIU_QUICK_ACCESS_SCENARIOS,
  SET_CPIU_AUTO_RESET_SCENARIO_STATUS,
  SET_SCENARIO_NAME_BLINKING
} from 'constants/actionTypes';

export const setScenarioFiltersVisibility = createAction(
  SET_CPIU_FILTERS_VISIBILITY,
  isVisible => isVisible
);

export const setPinCPIUToPanel = createAction(
  SET_CPIU_PIN_TO_PANEL_STATUS,
  isPinToPanel => isPinToPanel
);

export const setCPIUDevice = createAction(UPDATE_CPIU_DEVICE, CPIU => CPIU);

export const setQuickAccessScenariosList = createAction(
  SET_CPIU_QUICK_ACCESS_SCENARIOS,
  filters => filters
);

export const setLogicEntities = createAction(
  SET_CPIU_LOGIC_ENTITIES,
  logicEntities => logicEntities
);

export const setEntitiesByPlanId = createAction(SET_CPIU_ENTITIES_BY_PLAN_ID, entities => entities);

export const setCurrentScenario = createAction(
  SET_CPIU_CURRENT_SCENARIO,
  (scenarioId, password) => ({
    scenarioId,
    password
  })
);

export const setAutoResetScenarioStatus = createAction(
  SET_CPIU_AUTO_RESET_SCENARIO_STATUS,
  specialData => specialData
);

export const setIsScenarioNameBlinking = createAction(
  SET_SCENARIO_NAME_BLINKING,
  isBlinking => isBlinking
);

export const loadHandleModeScenarios = createAction(
  GET_CPIU_HANDLE_MODE_SCENARIOS,
  projectId => projectId
);
