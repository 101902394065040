import { FILTER_TYPES } from 'components/Filters';


export const getActiveValue = (filtersChangesMap, filter) => {
  if (filtersChangesMap.has(filter.key)) {
    return filtersChangesMap.get(filter.key);
  } else {
    switch (filter.type) {
      case FILTER_TYPES.SELECT:
      case FILTER_TYPES.INPUT:
        return [];
      case FILTER_TYPES.CHECKBOX:
        return false;
      default:
        return [];
    }
  }
}

export function getFiltersCount(filters) {
  if (!filters || !filters.length) return 0;
  return filters.reduce((sum, filter) => {
    switch (filter.type) {
      case FILTER_TYPES.CHECKBOX:
        if (filter.active) sum += 1;
        break;
      case FILTER_TYPES.INPUT:
      case FILTER_TYPES.SELECT:
        sum += filter.active && !filter.hidden ? filter.active.length : 0;
        break;
      default:
        break;
    }
    return sum;
  }, 0);
}