import { SOUND_SELECT, MEDIA_DELETE, UPDATED_ENTITIES, isLoaded } from 'constants/actionTypes';

const selectedSound = (state = null, { type, payload }) => {
  switch (type) {
    case SOUND_SELECT: {
      const { sound } = payload;
      if (!sound) return null;
      return { ...sound };
    }
    case isLoaded(MEDIA_DELETE, true): {
      return null;
    }
    case UPDATED_ENTITIES: {
      const { medias } = payload;
      if (!medias || !state) return state;
      if (medias.updated.length && state) {
        const updatedSound = medias.updated.find(media => media.id === state.id);
        if (updatedSound) return { ...updatedSound };
      }
      if (medias.created.length) {
        if (medias.created[0].mediaType === 'SOUND') return medias.created[0];
      }
      if (medias.deletedIds.length) {
        return null;
      }
      return state;
    }
    default: {
      return state;
    }
  }
};

export default selectedSound;
