import { ENTITY_TYPE } from 'constants/deviceShapeLibrary';
import { createTreeItem } from './tree';

export function createDeviceShapeLibraryTreeItem(entityId, entity) {
  return createTreeItem(false, ENTITY_TYPE.DEVICE_SHAPE_LIBRARY, entityId, entity);
}

export function createDeviceShapeTreeItem(entityId, entity) {
  return createTreeItem(false, ENTITY_TYPE.DEVICE_SHAPE, entityId, entity);
}
