import React from 'react';
import styled from 'styled-components';
import {
  CloudOutlined,
  DesktopOutlined,
  UsbOutlined,
  EnvironmentFilled,
  EnvironmentOutlined
} from '@ant-design/icons';

import Checkbox from 'components/Checkbox';
import i18next from 'i18next';

const DeviceImgWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  & .draggable {
    cursor: move;
  }
`;
class DeviceImg extends React.Component {
  DEVICE_PLACED = {
    ICON: EnvironmentFilled,
    MSG: i18next.t('placedOnPlan'),
    COLOR: 'teal'
  };
  DEVICE_NOT_PLACED = {
    ICON: EnvironmentOutlined,
    MSG: i18next.t('notPlacedOnPlan'),
    COLOR: 'grey'
  };

  getStatus() {
    const { generalStateCategoryView } = this.props;

    if (generalStateCategoryView)
      return (
        <img
          width="15px"
          src={`/public/img/status/${generalStateCategoryView.iconName}`}
          alt={`${generalStateCategoryView.name}`}
        />
      );
    else return null;
  }

  getPlanAttachment() {
    const { attachedIconEnabled, planLayouts, record, onDragStart } = this.props;
    if (attachedIconEnabled) {
      const isAttachableDevice =
        typeof planLayouts !== 'undefined' &&
        record.id &&
        record.canPlacementOnPlan &&
        !record.disabled;
      const placedOnPlan = isAttachableDevice && planLayouts.length;
      const Icon = placedOnPlan ? this.DEVICE_PLACED.ICON : this.DEVICE_NOT_PLACED.ICON;
      return (
        <Icon
          title={placedOnPlan ? this.DEVICE_PLACED.MSG : this.DEVICE_NOT_PLACED.MSG}
          style={{
            fontSize: '16px',
            margin: '0 0 0 5px',
            opacity: `${isAttachableDevice ? 1 : '0.3'}`,
            color: `${placedOnPlan ? this.DEVICE_PLACED.COLOR : this.DEVICE_NOT_PLACED.COLOR}`
          }}
          onClick={() => {
            if (isAttachableDevice) onDragStart();
          }}
        />
      );
    } else return null;
  }

  getIconContent() {
    const { name } = this.props;
    switch (name) {
      case 'usb.ico': {
        return <UsbOutlined />;
      }

      case 'network.ico': {
        return <CloudOutlined />;
      }

      case 'computer.ico': {
        return <DesktopOutlined />;
      }

      default:
        return (
          <img
            style={{ width: '16px', margin: '0px 0px 0px 5px' }}
            draggable={false}
            src={`/public/img/device/${name}`}
            alt={`${name}`}
          />
        );
    }
  }

  getDeviceSwitcher() {
    const { record, onChangeDeviceSwitcher, showDeviceSwitcher } = this.props;
    if (!showDeviceSwitcher) return null;
    const { disabled, disableNotAllowed, id } = record;
    return (
      <Checkbox
        checked={!disabled}
        disabled={disableNotAllowed || !id}
        onChange={e => onChangeDeviceSwitcher(id, !e.target.checked)}
      />
    );
  }

  render() {
    let size = this.props.size ? this.props.size : 23;

    return (
      <DeviceImgWrapper size={size}>
        {this.getStatus()}
        {this.getDeviceSwitcher()}
        {this.getPlanAttachment()} {this.getIconContent()}
      </DeviceImgWrapper>
    );
  }
}

export default DeviceImg;
