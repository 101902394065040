import React from 'react';

const Arrows = ({ color = '#fff', width, height, x = '12', y = '12' }) => {
  return (
    <svg width={width || x} height={height || y} viewBox={`2 2 ${x} ${y}`}>
      <path
        stroke="null"
        id="svg_arrows"
        d="m12.431678,7.117092l-1.528843,-0.883057l0,1.241198l-2.378068,0l0,-2.378068l1.241197,0l-0.882907,-1.529291l-0.883057,-1.529439l-0.882908,1.529439l-0.883056,1.529291l1.241197,0l0,2.378068l-2.378068,0l0,-1.241198l-1.52929,0.883057l-1.529439,0.882907l1.529439,0.883057l1.52929,0.882908l0,-1.241198l2.378068,0l0,2.378068l-1.241197,0l0.883056,1.528843l0.882908,1.529887l0.883057,-1.529887l0.882907,-1.528843l-1.241197,0l0,-2.378068l2.378068,0l0,1.241198l1.528843,-0.882908l1.529886,-0.883057"
        strokeOpacity="null"
        strokeLinecap="null"
        strokeLinejoin="null"
        strokeWidth="null"
        fill={color}
      />
    </svg>
  );
};

export default Arrows;
