import React from 'react';

import { Select } from 'antd';

SelectToForm.defaultProps = {
  value: '',
  notFoundContent: null
};

SelectToForm.Option = Select.Option;

export const Option = Select.Option;
export const OptGroup = Select.OptGroup;

export default function SelectToForm(props) {
  let { input, children, options, onChange, value, mode, ...restProps } = props;

  if (input) {
    if (input.onChange) onChange = input.onChange;
    if (input.value != null) value = input.value;
  }
  if(!value && (mode === 'tags' || mode === 'multiple')) value = []

  return (
    <Select
      onChange={onChange}
      value={value}
      options={options}
      // если virtual={true} и элементов === 8, то возникает глюк, появляется скролл которого не должно быть
      // При этом он исчезает через пару секунд, но если его попробовать прокрутить мышкой возникает переполнение стека вызовов
      virtual={!!(children && children.length > 8) || !!(options && options.length > 8)}
      mode={mode}
      {...restProps}
    >
      {children}
    </Select>
  );
}
