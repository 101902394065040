import React from 'react';

const User = ({ color = '#F0F0F0', x = '16', y = '16' }) => {
  return (
    <svg width={x} height={y} viewBox={`0 0 ${x} ${y}`}>
      <g stroke="null" id="user">
        <path
          fill={color}
          stroke="null"
          id="u1"
          d="m7.99967,7.57107c1.87376,0 3.37561,-2.12483 3.37561,-4.01676c0,-1.89193 -1.51914,-3.42574 -3.3929,-3.42574s-3.3929,1.53381 -3.3929,3.42574c0.00022,1.89193 1.53644,4.01676 3.41019,4.01676z"
        />
        <path
          fill={color}
          stroke="null"
          id="u2"
          d="m9.2557,9.08562l-2.52322,0c-2.68499,0 -4.8617,2.17365 -4.8617,4.85513l0.0116,0.57417c0,0.74907 0.6081,1.3565 1.35847,1.3565l9.52989,0c0.75016,0 1.35847,-0.60744 1.35847,-1.3565l-0.0116,-0.57417c-0.00022,-2.68149 -2.17671,-4.85513 -4.86192,-4.85513z"
        />
      </g>
    </svg>
  );
};

export default User;
