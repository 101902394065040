import { dropRight } from 'lodash'; //Удаляет n последних элементов из массива

import {
  EVENTS_LOAD_LOG,
  UPDATED_ENTITIES,
  LOG_VIEWS_LOAD,
  isLoaded,
  LOG_VIEW_ADD,
  LOG_VIEW_UPDATE,
  LOG_VIEW_DELETE
} from 'constants/actionTypes';
import { SKUD_LOG } from 'constants/skud';

import { filterEntities } from 'helpers/filtration';
import i18next from 'i18next';

const MAX_LOG_EVENTS = 1000;

const DEFAULT_LOG_VIEW = {
  id: 'all',
  name: i18next.t('admin.logs.eventLog'),
  position: -999,
  active: true,
  recordCount: MAX_LOG_EVENTS,
  isRoot: true,
  builtIn: true
};

const INITIAL_STATE = {
  logViews: [DEFAULT_LOG_VIEW, SKUD_LOG],
  events: {},
  newEvents: [] //Новые события (выделяемые в общем журнале в течении нескольких секунд)
};

export default function logEvents(state = INITIAL_STATE, { type, payload, currentUser }) {
  switch (type) {
    case isLoaded(LOG_VIEWS_LOAD, true): {
      const logViews = payload;
      const newState = { ...state };
      newState.logViews = [DEFAULT_LOG_VIEW, ...logViews];
      newState.logViews.sort(({ position: a }, { position: b }) => a - b);
      return newState;
    }
    case isLoaded(LOG_VIEW_ADD, true): {
      const addedLogViews = payload;
      const newState = { ...state };
      newState.logViews = [...state.logViews, ...addedLogViews];
      newState.logViews.sort(({ position: a }, { position: b }) => a - b);
      return newState;
    }
    case isLoaded(LOG_VIEW_UPDATE, true): {
      const updatedLogViews = payload;
      const newState = { ...state };
      newState.logViews = state.logViews.map(oldLogView => {
        const newLogView = updatedLogViews.find(logView => logView.id === oldLogView.id);
        if (newLogView) return newLogView;
        else return oldLogView;
      });
      return newState;
    }
    case isLoaded(LOG_VIEW_DELETE, true): {
      const deletedLogViewIds = payload;
      const newState = { ...state };
      newState.logViews = state.logViews.filter(
        oldLogView => !deletedLogViewIds.includes(oldLogView.id)
      );
      newState.events = {...state.events};
      deletedLogViewIds.forEach(logViewId => delete newState.events[logViewId]);
      return newState;
    }
    case isLoaded(EVENTS_LOAD_LOG, true): {
      const { logViewId, events } = payload;
      const newState = { ...state };
      newState.events[logViewId] = events;
      newState.newEvents = [];
      return newState;
    }
    case UPDATED_ENTITIES: {
      const { events } = payload;
      if (events) {
        const newState = { ...state };
        newState.newEvents = [];

        let isUpdatedState = false;
        // Если были обновлены события
        if (events && events.length) {
          isUpdatedState = true;
          let newEvents = events.reverse();
          // Фильтруем события по меткам фильтрации
          if (currentUser && currentUser.filterTagsOn)
            newEvents = filterEntities(currentUser, newEvents);
          // Делаем реверс, т.к. события отсортированны от старого к новому
          const activeLogViews = newState.logViews.filter(logView => logView.active); // Список активных журналов
          // Пробегаем все активные журналы и добавляем подходящие события в них
          activeLogViews.forEach(activeLogView => {
            // Фильтруем события
            const filteredEvents = activeLogView.isRoot
              ? [...newEvents] // В журнал попадают все события
              : newEvents.filter(event => {
                  const { subsystems, stateCategoryIds } = activeLogView;
                  const { subsystem, stateCategoryId } = event;
                  if (subsystems && subsystems.length) {
                    if (!subsystem) return false;
                    if (subsystems.indexOf(subsystem) === -1) return false;
                  }
                  if (stateCategoryIds && stateCategoryIds.length) {
                    if (!stateCategoryId) return false;
                    if (stateCategoryIds.indexOf(stateCategoryId) === -1) return false;
                  }
                  return true;
                });
            // Если есть подходящие события
            if (filteredEvents.length && newState.events[activeLogView.id]) {
              const maxRecordCount = activeLogView.recordCount || MAX_LOG_EVENTS; // Максимальное количество записей в журнале
              // Если кол-во новых событий больше, чем вмещает журнал
              if (filteredEvents.length > maxRecordCount) {
                newState.events[activeLogView.id] = dropRight(
                  filteredEvents,
                  filteredEvents.length - maxRecordCount
                );
              }
              // Если журнал переполнен
              else if (
                newState.events[activeLogView.id].length >
                maxRecordCount - filteredEvents.length
              ) {
                newState.events[activeLogView.id] = [
                  ...filteredEvents,
                  ...dropRight(
                    newState.events[activeLogView.id],
                    newState.events[activeLogView.id].length -
                      maxRecordCount +
                      filteredEvents.length
                  )
                ];
              } else {
                newState.events[activeLogView.id] = [
                  ...filteredEvents,
                  ...newState.events[activeLogView.id]
                ];
              }
            }
          });

          newState.newEvents = newEvents;
        }
        return isUpdatedState ? newState : state;
      }
      return state;
    }

    default:
      return state;
  }
}
