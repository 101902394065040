import { SCENARIO_CONSTANTS_LOAD, isLoaded } from 'constants/actionTypes';

/*
 * state = scenarioConstants: {
 *    dictionaries: {
 *      (см. метод GET api/v1/scenario_dictionaries в тех. проекте)
 *    },
 *    triggerTypes: [
 *      (см. метод GET api/v1/scenario_trigger_types в тех. проекте)
 *    ],
 *    actionTypes: [
 *      (см. метод GET api/v1/scenario_action_types в тех. проекте)
 *    ]
 *  }
 */
export default function scenarioConstants(state = {}, action) {
  switch (action.type) {
    case isLoaded(SCENARIO_CONSTANTS_LOAD, true): {
      const constants = action.payload;
      return {
        ...constants,
        actionTypes: constants.actionTypes.sort((a, b) => b.severity - a.severity)
      };
    }

    default:
      return state;
  }
}
