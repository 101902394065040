import React from 'react';

const Tone = ({ color = '#5FA2DD', x = '16', y = '16' }) => {
  return (
    <svg width={x} height={y} viewBox={`0 0 ${x} ${y}`}>
      <g>
        <path
          stroke="null"
          id="svg_1"
          fill={color}
          d="m14.155976,0.076004l-8.86965,0c-0.456037,0 -0.825806,0.36977 -0.825806,0.825806l0,9.70993c-0.372086,-0.104794 -0.775822,-0.133935 -1.195383,-0.062915c-1.193646,0.202254 -2.120579,1.199436 -2.234444,2.404661c-0.152462,1.613593 1.11259,2.97051 2.694533,2.97051c1.495097,0 2.707078,-1.21198 2.707078,-2.707078l0.000965,0l0,-10.029329c0,-0.456037 0.36977,-0.825806 0.825806,-0.825806l4.91624,0c0.456037,0 0.825806,0.36977 0.825806,0.825806l0,4.046238c-0.331365,-0.092056 -0.687625,-0.124286 -1.057781,-0.079126c-1.248841,0.152269 -2.244479,1.170294 -2.363168,2.422803c-0.152848,1.613786 1.112397,2.971088 2.694533,2.971088c1.381812,0 2.521035,-1.035587 2.685849,-2.372818l0.021229,0l0,-9.273965c0,-0.456037 -0.36977,-0.825806 -0.825806,-0.825806z"
        />
      </g>
    </svg>
  );
};

export default Tone;
