import { put, call } from 'redux-saga/effects';
import fetchJson, { fetchRequest } from 'helpers/fetch';
import { isLoaded } from 'constants/actionTypes';

const fetch = (...args) => call(fetchJson, ...args);
const request = (...args) => call(fetchRequest, ...args);
const dispatch = (type, payload) => put({ type, payload });
const dispatchSuccess = (type, payload) => dispatch(isLoaded(type, true), payload);
const dispatchFail = (type, payload) => dispatch(isLoaded(type, false), payload);

export { fetch, request, dispatch, dispatchSuccess, dispatchFail };
