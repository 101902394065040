import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Field, reduxForm, initialize, reset, propTypes,getFormSyncErrors } from 'redux-form';

import { Button } from 'antd';

import { ItemLayoutWithLabel, SubmitItemLayout } from 'components/Form';
import Modal from 'containers/Modal';
import SUBSYSTEMS from 'constants/subsystems';

import { Input, FormItem } from 'components';
import i18next from 'i18next';
import { DISPLAY_NONE_STYLE } from 'constants/common';
import { getCurrentProjectRegions } from '../../helpers/currentProject';

const FORM_NAME = 'skudRegionForm';

/**
 * Сброс значение формы
 * @param dispatch
 * @param values объект со значениями
 */
export function resetFormValues(dispatch, values) {
  const newValues = { ...values, count: 1 };
  dispatch(initialize(values.form ? values.form : FORM_NAME, newValues));
  dispatch(reset(values.form ? values.form : FORM_NAME));
}

class SkudRegionForm extends Component {
  static propTypes = {
    ...propTypes,
    modalName: PropTypes.string,
    isEdit: PropTypes.bool,
    onSubmit: PropTypes.func,
    needToAddOnlyOneRegion: PropTypes.bool
  };

  static defaultProps = {
    needToAddOnlyOneRegion: true
  };

  render() {
    const {
      modalName,
      modalProps,
      isEdit,
      isViewMode,
      createRegionInProgress,
      updateRegionInProgress,
      createRegionAndConnectDeviceInProgress,
      handleSubmit,
      dirty,
      valid,
      needToAddOnlyOneRegion,
      errors
    } = this.props;

    return (
      <Modal name={modalName} title={i18next.t('zones.PACSZoneParameters')} {...modalProps}>
        <Form onSubmit={handleSubmit}>
          {!isEdit || isViewMode ? (
            <FormItem
              {...ItemLayoutWithLabel}
              required={true}
              label={i18next.t('quantity')}>
              <Field
                name="count"
                component={Input.Number}
                precision={0}
                defaultValue={1}
                min={1}
                max={10000}
                disabled={needToAddOnlyOneRegion}
              />
            </FormItem>
          ) : null}
          <FormItem
            {...ItemLayoutWithLabel}
            required={true}
            label={i18next.t('zones.zoneNumber')}
            validateStatus={errors.index ? 'error' : ''}
            help={errors.index ? errors.index : ''}>
            <Field
              name="index"
              component={Input.Number}
              precision={0}
              min={1}
              max={65535}
              disabled={isViewMode}
            />
          </FormItem>
          <FormItem
            {...ItemLayoutWithLabel}
            required={true}
            label={i18next.t('name')}
            validateStatus={errors.name ? 'error' : ''}
            help={errors.name ? errors.name : ''}>
            <Field name="name" component={Input} maxLength={256} disabled={isViewMode} />
          </FormItem>
          <FormItem {...ItemLayoutWithLabel} label={i18next.t('description')}>
            <Field
              name="description"
              component={Input.TextArea}
              rows={4}
              maxLength={256}
              disabled={isViewMode}
            />
          </FormItem>
          <FormItem {...ItemLayoutWithLabel} style={DISPLAY_NONE_STYLE}>
            <Field
              name="subsystem"
              component={Input}
              props={{ value: SUBSYSTEMS.SKUD.id }}
              disabled={isViewMode}
            />
          </FormItem>

          <FormItem {...SubmitItemLayout}>
            <Button
              type="primary"
              htmlType="submit"
              loading={
                !!(
                  createRegionInProgress ||
                  updateRegionInProgress ||
                  createRegionAndConnectDeviceInProgress
                )
              }
              disabled={(isEdit && !dirty) || !valid || isViewMode}
            >
              {isEdit ? i18next.t('buttons.edit') : i18next.t('buttons.create')}
            </Button>
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

const validate = (values) => {
  let result = {};
  //TODO: Ограничить количеств зон для добавления в прибор(#6699)
  if (!values.index || !Number.isInteger(values.index) || values.index < 1 || values.index > 65535){
    result.index = i18next.t('zones.zoneNumberNotSet') }
  if (!values.name){
    result.name = i18next.t('zones.zoneNameNotSet') }
  return result;
};

SkudRegionForm = reduxForm({
  validate
})(SkudRegionForm);

const mapStateToProps = (state, props) => {
  const form = props.form ? props.form : FORM_NAME;
  return {
    form,
    createRegionInProgress: state.inProgress.createRegion,
    updateRegionInProgress: state.inProgress.updateRegion,
    errors: getFormSyncErrors(form)(state),
    regions: getCurrentProjectRegions(state),
    createRegionAndConnectDeviceInProgress: state.inProgress.createRegionAndConnectDevice
  };
};

SkudRegionForm = connect(mapStateToProps, null)(SkudRegionForm);

export default SkudRegionForm;
