import React from 'react';

const Connected = ({ color = "#5FA2DD", x = "16", y = "16" }) => {
  return (
    <svg width={x} height={y} viewBox={`0 0 ${x} ${y}`}>
      <path fill={color} fillRule="evenodd" d="M11.256 0c.835 0 1.62.325 2.21.915L14.82 2.27c.59.59.915 1.375.915 2.21s-.325 1.62-.915 2.21l-4.066 4.065c-.59.59-1.375.916-2.21.916-.834 0-1.618-.325-2.209-.916L4.981 9.4a.25.25 0 0 1 0-.354l1.355-1.355a.25.25 0 0 1 .354 0l1.355 1.355a.705.705 0 0 0 1.001 0l4.066-4.065a.71.71 0 0 0 0-1.002l-1.355-1.355a.704.704 0 0 0-.501-.207.703.703 0 0 0-.5.207L9.303 4.075a.25.25 0 0 1-.304.04 3.56 3.56 0 0 0-2.068-.481.25.25 0 0 1-.177-.427L9.046.915A3.106 3.106 0 0 1 11.256 0zM7.691 6.69a.703.703 0 0 0-.5-.207.702.702 0 0 0-.501.207l-4.065 4.065a.705.705 0 0 0 0 1.002l1.354 1.355a.703.703 0 0 0 .501.207c.19 0 .367-.074.501-.207l1.45-1.45a.25.25 0 0 1 .304-.04 3.59 3.59 0 0 0 2.069.48.25.25 0 0 1 .177.428L6.69 14.82c-.59.59-1.376.916-2.21.916-.835 0-1.62-.325-2.21-.915L.917 13.466A3.103 3.103 0 0 1 0 11.256c0-.834.325-1.619.916-2.21L4.98 4.981a3.104 3.104 0 0 1 2.21-.915c.834 0 1.619.325 2.209.915l1.355 1.355a.25.25 0 0 1 0 .354L9.4 8.045a.25.25 0 0 1-.354 0L7.691 6.69z"/>
    </svg>
  )
}

export default Connected;