import { combineReducers } from 'redux';
import {
  SKUD_TREE_ITEM_SELECT,
  ACCESS_KEY_VALUE_READ,
  ACCESS_KEY_START_READ_VALUE,
  ACCESS_KEY_STOP_READ_VALUE,
  getAddActionType,
  isLoaded,
  getUpdateActionType,
  getRemoveActionType
} from 'constants/actionTypes';
import { createTreeItem } from 'helpers/tree';
import { ENTITY_TYPE } from 'constants/skud';

function selectedTreeItem(state = null, { type, payload }) {
  switch (type) {
    case SKUD_TREE_ITEM_SELECT: {
      const selectedTreeItem = payload;
      return { ...selectedTreeItem, byUser: true };
    }

    case isLoaded(getUpdateActionType('employees'), true): {
      const updatedEmployees = payload;
      const selectedTreeItem = state;
      const firstUpdatedEmployee = updatedEmployees[0];
      if (
        selectedTreeItem &&
        selectedTreeItem.entityType === ENTITY_TYPE.EMPLOYEE &&
        selectedTreeItem.entity.id === firstUpdatedEmployee.id
      )
        return createTreeItem(
          false,
          ENTITY_TYPE.EMPLOYEE,
          firstUpdatedEmployee.id,
          firstUpdatedEmployee
        );
      return state;
    }

    case isLoaded(getAddActionType('employees'), true): {
      const addedEmployees = payload;
      const selectedTreeItem = state;
      const firstAddedEmployee = addedEmployees[0];
      if (selectedTreeItem && selectedTreeItem.entityType === ENTITY_TYPE.NEW_EMPLOYEE)
        return createTreeItem(
          false,
          ENTITY_TYPE.EMPLOYEE,
          firstAddedEmployee.id,
          firstAddedEmployee
        );
      return state;
    }

    case isLoaded(getUpdateActionType('accessKeys'), true): {
      const receivedKeys = payload;
      const selectedTreeItem = state;
      const firstKey = receivedKeys[0];
      if (
        selectedTreeItem &&
        (selectedTreeItem.entityType === ENTITY_TYPE.CARD ||
          selectedTreeItem.entityType === ENTITY_TYPE.PASSWORD) &&
        firstKey.id === selectedTreeItem.entity.id
      )
        return createTreeItem(false, firstKey.keyType, firstKey.id, firstKey);

      return state;
    }

    case isLoaded(getAddActionType('accessKeys'), true): {
      const receivedKeys = payload;
      const selectedTreeItem = state;
      const firstKey = receivedKeys[0];
      if (
        selectedTreeItem &&
        (selectedTreeItem.entityType === ENTITY_TYPE.NEW_CARD ||
          selectedTreeItem.entityType === ENTITY_TYPE.NEW_PASSWORD)
      )
        return createTreeItem(false, firstKey.keyType, firstKey.id, firstKey);

      return state;
    }

    case isLoaded(getRemoveActionType('employees'), true): {
      const removedEmployees = payload;
      const selectedTreeItem = state;
      if (
        selectedTreeItem &&
        selectedTreeItem.entityType === ENTITY_TYPE.EMPLOYEE &&
        removedEmployees.find(employee => employee.id === selectedTreeItem.entity.id)
      ) {
        return null;
      } else {
        return state;
      }
    }

    case isLoaded(getRemoveActionType('accessKeys'), true): {
      const removedKeys = payload;
      const selectedTreeItem = state;
      if (
        selectedTreeItem &&
        (selectedTreeItem.entityType === ENTITY_TYPE.CARD ||
          selectedTreeItem.entityType === ENTITY_TYPE.PASSWORD) &&
        removedKeys.find(accessKey => accessKey.id === selectedTreeItem.entity.id)
      ) {
        return null;
      } else {
        return state;
      }
    }

    default:
      return state;
  }
}

function readAccessKeyValueInfo(state = null, { type, payload }) {
  switch (type) {
    case ACCESS_KEY_VALUE_READ:
      return { ...payload };

    case SKUD_TREE_ITEM_SELECT:
    case isLoaded(getUpdateActionType('employees'), true):
    case isLoaded(getAddActionType('employees'), true):
    case isLoaded(getRemoveActionType('employees'), true):
    case isLoaded(getUpdateActionType('accessKeys'), true):
    case isLoaded(getAddActionType('accessKeys'), true):
    case isLoaded(getRemoveActionType('accessKeys'), true):
    case isLoaded(ACCESS_KEY_START_READ_VALUE, false):
    case ACCESS_KEY_STOP_READ_VALUE:
      return null;
    case isLoaded(ACCESS_KEY_START_READ_VALUE, true):
      return 'WAITING';

    default:
      return state;
  }
}

export default combineReducers({
  selectedTreeItem,
  readAccessKeyValueInfo
});
