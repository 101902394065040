import {
  isLoaded,
  PROJECT_SET_CURRENT,
  PROJECT_DELETE
} from 'constants/actionTypes';

export default function currentProjectId(state = null, action) {
  switch (action.type) {
    case PROJECT_SET_CURRENT: {
      const projectId = action.payload;
      return (' ' + projectId).slice(1);
    }
    case isLoaded(PROJECT_DELETE, true): {
      const projectId = state;
      const deletedProjectId = action.payload;
      if (projectId === deletedProjectId) {
        return null;
      } else {
        return state;
      }
    }

    default:
      return state;
  }
}
