import {
  MONITORABLE_VALUES_GET_PROFILES,
  MONITORABLE_VALUES_GET_DEVICE_HISTORY,
  MONITORABLE_VALUES_RESET_HISTORY
} from 'constants/actionTypes';
import { createAction } from 'redux-actions';

export const getMonitorableValueProfiles = createAction(MONITORABLE_VALUES_GET_PROFILES);

export const getMonitorableValuesHistory = createAction(
  MONITORABLE_VALUES_GET_DEVICE_HISTORY,
  (projectId, deviceId, params) => ({ projectId, deviceId, params })
);

export const resetMonitorableValuesHistory = createAction(MONITORABLE_VALUES_RESET_HISTORY);
