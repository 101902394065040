import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import isSvg from 'is-svg';
import { parse } from 'svg-parser';
import { optimize } from 'svgo';
import imageCompression from 'browser-image-compression';

import Modal from 'containers/Modal';
import { Alert, FormItem } from 'components';

import { convertUnit } from 'helpers/unit';
import { getBase64FromSvgString } from 'helpers/planEditor';
import Unit from 'constants/unit';
import { Field, Form, reduxForm } from 'redux-form';
import styles from './NewPlanBackgroundForm.module.css';
import i18next from 'i18next';

const MAX_FILE_SIZE_B = 5_000_000,
  FORM_NAME = 'loadBackground';

const FormItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 }
};

const SubmitItemLayout = {
  wrapperCol: { offset: 10, span: 10 }
};

let filesCount = 0;

class BackgroundForm extends Component {
  static propTypes = {
    modalName: PropTypes.string,
    modals: PropTypes.object,
    onSubmit: PropTypes.func
  };

  fileErrorMessages = {
    content: i18next.t('errors.fileStructureNotMatchFormat'),
    size: i18next.t('errors.fileSizeNoMore', { size: '5' }),
    format: i18next.t('errors.allowedFileExtensions'),
    empty: i18next.t('messages.chooseFile'),
    invalidFile: i18next.t('errors.invalidFile'),
    maxImagesCount: i18next.t('plans.errorMaxImagesCount')
  };

  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      errors: []
    };
  }

  componentDidMount() {
    const { plan } = this.props;
    if (plan && plan.supportedImageFormats) {
      this.acceptedFormats = plan.supportedImageFormats.reduce((previousValue, item) => {
        return previousValue + `.${item}, `;
      }, '');
    }
  }

  handleSubmit = e => {
    const { fileList } = this.state;
    const { onSubmit, plan } = this.props;
    setTimeout(() => {
      prepareData(fileList, plan).then(data => {
        onSubmit(data);
      });
    }, 500);
    this.setState({ loading: true });
    e.preventDefault();
  };

  resetData = () => {
    filesCount = 0;
    this.setState({
      fileList: [],
      errors: [],
      loading: false
    });
  };

  handleFileUpload = event => {
    const { file } = event;
    const { plan } = this.props;

    const ext = file.name.split('.').pop();
    const isVector = ext === 'svg';
    if (file.size > MAX_FILE_SIZE_B) {
      this.setError(this.fileErrorMessages.size);
      return;
    } else if (!plan.supportedImageFormats.includes(ext)) {
      this.setError(`${this.fileErrorMessages.format} (${this.acceptedFormats})`);
      return;
    }
    const reader = new FileReader();

    const component = this;
    const maxImagesCount = this.fileErrorMessages.maxImagesCount;
    reader.onload = function(data) {
      let result;
      try {
        if (plan.backgrounds.length + filesCount + 1 > plan.maxImagesCount) {
          throw new Error(`${maxImagesCount} ${plan.maxImagesCount}`);
        }
        if (isVector) {
          result = checkVectorImage(data.currentTarget.result);
        }
      } catch (e) {
        component.setError(e.message);
        return;
      }
      const uid = -Math.random();
      component.setFileData({
        key: uid,
        uid: uid,
        name: file.name,
        dataFormat: 'base64',
        status: 'done',
        origin: file,
        isVector,
        type: file.type,
        data: isVector ? result : data.currentTarget.result
      });
    };
    this.setError();
    if (isVector) reader.readAsText(file);
    else reader.readAsDataURL(file);
  };

  setError = (...errors) => {
    this.setState({ errors: errors ? [...errors] : [] });
  };

  setFileData = newFile => {
    const fileList = [...this.state.fileList];
    fileList.push(newFile);
    filesCount++;
    this.setState({ fileList });
  };

  onRemoveFileFromList = file => {
    const { fileList } = this.state;
    const index = fileList.indexOf(file);
    if (index !== -1) {
      const copy = [...fileList];
      filesCount--;
      copy.splice(index, 1);
      this.setState({ fileList: copy });
    }
  };

  render() {
    const { modalName, submitting } = this.props;
    const { fileList, errors, loading } = this.state;
    return (
      <Modal
        name={modalName}
        afterClose={this.resetData}
        title={i18next.t('plans.addingNewImages')}
      >
        <Form onSubmit={this.handleSubmit}>
          <FormItem {...FormItemLayout} required={true} label={i18next.t('file')}>
            <Field
              name={'upload'}
              component={Upload}
              className={styles.upload}
              customRequest={this.handleFileUpload}
              onRemove={this.onRemoveFileFromList}
              accept={this.acceptedFormats}
              id="uploader"
              fileList={fileList}
              multiple
            >
              <Button>
                <UploadOutlined /> {i18next.t('messages.chooseOneOrMoreFiles')}
              </Button>
            </Field>
          </FormItem>
          <FormItem {...SubmitItemLayout}>
            <Button
              type="primary"
              disabled={loading || !fileList.length}
              loading={loading || submitting}
              htmlType="submit"
            >
              {loading ? i18next.t('processing') : i18next.t('buttons.add')}
            </Button>
          </FormItem>
        </Form>
        {errors.map(error => (
          <Alert message={i18next.t('error')} description={error} type="error" showIcon closable />
        ))}
      </Modal>
    );
  }
}

async function prepareData(fileList, plan) {
  for (let file of fileList) {
    if (file.isVector) {
      file.data = readAsVectorImage(file.data);
    } else {
      const options = {
        maxSizeMB: MAX_FILE_SIZE_B / 1_000_000,
        maxWidthOrHeight: plan.xSize > plan.ySize ? plan.xSize : plan.ySize
      };
      const compressed = await imageCompression(file.origin, options);
      const text = await readFileAsDataURL(compressed);
      file.data = readAsRasterImage(text);
    }
  }
  return fileList;
}

function readFileAsDataURL(file) {
  return new Promise(resolve => {
    const fileReader = new FileReader();
    fileReader.onload = e => resolve(fileReader.result);
    fileReader.readAsDataURL(file);
  });
}

function checkVectorImage(imageStr) {
  if (!isSvg(imageStr)) throw new Error(this.fileErrorMessages.content);
  const object = parse(imageStr);
  for (const data of object.children) {
    const attributes = data.properties;
    if (!attributes) throw new Error(this.fileErrorMessages.invalidFile);

    if (!attributes.xmlns || attributes.xmlns !== 'http://www.w3.org/2000/svg')
      throw new Error(this.fileErrorMessages.invalidFile);
    if (typeof attributes.width === 'string') {
      const newWidth = convertUnitToPixels(attributes.width);
      imageStr = imageStr.replace(attributes.width, newWidth);
    }
    if (typeof attributes.width === 'string') {
      const newHeight = convertUnitToPixels(attributes.height);
      imageStr = imageStr.replace(attributes.height, newHeight);
    }
  }
  return imageStr;
}

const readAsVectorImage = imageStr => {
  const optimizedStr = optimize(imageStr, {
    multipass: false
  });
  imageStr = optimizedStr.data;
  return getBase64FromSvgString(imageStr);
};

function convertUnitToPixels(data) {
  const unit = data.replace(/[0-9.]/g, '');
  const value = parseFloat(data);
  return convertUnit(unit, Unit.PIXELS, value);
}

function readAsRasterImage(imageStr) {
  const index = imageStr.indexOf(',');
  return imageStr.substr(index + 1);
}

const mapStateToProps = state => {
  return {
    modals: state.modals
  };
};

BackgroundForm = reduxForm({
  form: FORM_NAME
})(BackgroundForm);

export default connect(mapStateToProps, null)(BackgroundForm);
