import {
  VIRTUAL_COUNTERS_LOAD,
  VIRTUAL_COUNTER_CREATE,
  VIRTUAL_COUNTER_UPDATE,
  VIRTUAL_COUNTER_DELETE,
  VIRTUAL_COUNTERS_DELETE,
  isLoaded,
  PROJECT_DELETE,
  DEVICE_IMPORT_CONTOL_CONFIG,
  VIRTUAL_COUNTER_PASTE,
  PROJECT_LOAD_CURRENT,
  PROJECT_LOAD_ACTIVE,
  PROJECT_UPDATE_STATUS
} from 'constants/actionTypes';

/**
 * state = virtualCounters: {
 *    <projectId>: {
 *        <virtualCounterId>: {
 *          id: <string>,
 *          ...
 *       }
 *    }
 * }
 */
export default function virtualCounters(state = {}, action) {
  switch (action.type) {
    case isLoaded(VIRTUAL_COUNTERS_LOAD, true): {
      const { projectId, virtualCounters } = action.payload;
      const newState = { ...state };
      if (!newState[projectId]) {
        newState[projectId] = {};
      }
      newState[projectId] = {};
      virtualCounters.forEach(virtualCounter => {
        newState[projectId][virtualCounter.id] = virtualCounter;
      });
      return newState;
    }
    case isLoaded(DEVICE_IMPORT_CONTOL_CONFIG, true): {
      const {
        projectId,
        virtualCounters
      } = action.payload;
      const createdVirtualCounters = virtualCounters?.created;
      if (!state[projectId] || !createdVirtualCounters) {
        return state;
      }
      const newState = { ...state };
      newState[projectId] = { ...newState[projectId] };
      if (createdVirtualCounters && createdVirtualCounters.length) {
        createdVirtualCounters.forEach(
          createdVirtualCounter =>
            (newState[projectId][createdVirtualCounter.id] = createdVirtualCounter)
        );
      }
      return newState;
    }
    case isLoaded(VIRTUAL_COUNTER_PASTE, true):
    case isLoaded(VIRTUAL_COUNTER_CREATE, true): {
      const { projectId, newVirtualCounters } = action.payload;
      if (!state[projectId]) return state;
      const newState = { ...state };
      newState[projectId] = { ...state[projectId] };
      newVirtualCounters.forEach(vc => (newState[projectId][vc.id] = vc));
      return newState;
    }
    case isLoaded(VIRTUAL_COUNTER_DELETE, true): {
      const { virtualCounterId, projectId } = action.payload;
      if (!state[projectId]) return state;
      if (!state[projectId][virtualCounterId]) return state;
      const newState = { ...state };
      newState[projectId] = { ...state[projectId] };
      delete newState[projectId][virtualCounterId];
      return newState;
    }
    case isLoaded(VIRTUAL_COUNTERS_DELETE, true): {
      const { virtualCounterIds, projectId } = action.payload;
      if (!state[projectId]) return state;
      const newState = { ...state };
      newState[projectId] = { ...newState[projectId] };
      if (virtualCounterIds && virtualCounterIds.length) {
        virtualCounterIds.forEach(virtualCounterId => {
          delete newState[projectId][virtualCounterId];
        });
      }
      return newState;
    }
    case isLoaded(VIRTUAL_COUNTER_UPDATE, true): {
      const { updatedVirtualCounters, projectId } = action.payload;
      if (!state[projectId]) return state;
      const newState = { ...state };
      for (const updatedVirtualCounter of updatedVirtualCounters) {
        newState[projectId][updatedVirtualCounter.id] = updatedVirtualCounter;
        newState[projectId] = { ...newState[projectId] };
      }
      return newState;
    }
    case isLoaded(PROJECT_LOAD_CURRENT, true):
    case isLoaded(PROJECT_LOAD_ACTIVE, true):
    case isLoaded(PROJECT_UPDATE_STATUS, true): {
      const { project, virtualCounters } = action.payload;
      const newState = { ...state };
      if (!newState[project.id]) {
        newState[project.id] = {};
      }
      newState[project.id] = {};
      virtualCounters.forEach(virtualCounter => {
        newState[project.id][virtualCounter.id] = virtualCounter;
      });
      newState[project.id] = { ...newState[project.id] };
      return newState;
    }
    case isLoaded(PROJECT_DELETE, true): {
      const projectId = action.payload;
      if (!state[projectId]) return state;
      const newState = { ...state };
      delete newState[projectId];
      return newState;
    }
    default: {
      return state;
    }
  }
}
