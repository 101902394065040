import { useEffect, useRef } from 'react';

/**
 * Хук, позволяющий определить в функциональном компоненте React
 * демонтируется ли компонент или нет в любой момент времени.
 * Для корректной работы необходимо использовать перед всеми другими
 * хуками и эффектами в компоненте
 */
export function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
}
