//Категория устройства по функциональному назначению

export const CATEGORY = {
  CONTROL: { id: 'CONTROL' },
  SENSOR: { id: 'SENSOR' },
  EXECUTIVE: { id: 'EXECUTIVE' },
  TRANSPORT: { id: 'TRANSPORT' },
  OTHER: { id: 'OTHER' },
  CONTAINER: { id: 'CONTAINER' },
  ADDRESSED_CONTAINER: {id: 'ADDRESSED_CONTAINER'},
  VIRTUAL_CONTAINER: { id: 'VIRTUAL_CONTAINER' },
  SMART_CONTAINER: { id: 'SMART_CONTAINER' },
  TRUNK: { id: 'TRUNK' }
};

/* Тип адресации. */
export const ADDRESS_TYPE = {
  /** Общий: линия и адрес */
  GENERIC: 'GENERIC',
  /** Внутренний: только адрес, линия подставляется */
  INTERNAL: 'INTERNAL',
  /** Отсутствует: адрес генерируется, линия подставляется */
  LACK: 'LACK'
};

//Имена настроек устройств
export const SETTINGS_TYPE = {
  INDICATORS_PANEL: 'OIP',
  CONTROL_PANEL: 'OCP',
  MPT: 'MPT',
  ADDRESS_TAG: 'AM',
  BUILTIN_START_LOGIC: 'builtinStartLogic',
  CPIU: "CPIU"
};

//Тип модуля в крейте
export const CRATE_UNIT_TYPE = {
  NONE: 'NONE',
  FAP: 'FAP',
  PCU: 'PCU',
  NET: 'NET',
  LAN: 'LAN',
  MMI: 'MMI',
  REMOTE_CONTROL: 'REMOTE_CONTROL',
  PMU: 'PMU',
  REL: 'REL'
};

// Группы устройств
export const DEVICE_GROUP = {
  INTERFACE_MODULE: 'INTERFACE_MODULE',
  CONTROL_AND_DISPLAY_MODULE: 'CONTROL_AND_DISPLAY_MODULE',
  CONTROL_DEVICE: 'CONTROL_DEVICE',
  FIRE_NOTIFIER: 'FIRE_NOTIFIER',
  SECURITY_NOTIFIER: 'SECURITY_NOTIFIER',
  ADDRESS_TAG: 'ADDRESS_TAG',
  EXECUTIVE_DEVICE: 'EXECUTIVE_DEVICE',
  POWER_SUPPLY: 'POWER_SUPPLY',
  CONTAINER: 'CONTAINER',
  FIRE_EXTINGUISHER: 'FIRE_EXTINGUISHER',
  RADIO_DEVICE: 'RADIO_DEVICE',
  SKUD: 'SKUD',
  MANAGE_PANEL: 'MANAGE_PANEL',
  TRUNKS: 'TRUNKS',
  OTHER: 'OTHER'
};

// Тип параметра устройства
export const PROPERTY_TYPE = {
  ICON: 'icon',
  TEXT: 'text',
  FLAG: 'flag',
  DATE: 'date',
  TIME: 'time',
  INTEGER: 'integer',
  FLOAT: 'float',
  SECTION: 'section',
  ENTITY_ID: 'entityId',
  VERSION: 'version'
};

export const MAX_LINE_DEVICES_COUNT = 250;
export const DEFAULT_VALUE_DEVICE_PROPERTY = {
  HOSTNAME: 'hostname'
};

export const CONTROL_DEVICE_DATABASE_OPTION_TYPES = {
  EXECUTIVE_HAS_FIRST_ADDRESS_BIT: 'EXECUTIVE_HAS_FIRST_ADDRESS_BIT',
  EXECUTIVE_HAS_ADDRESS_OFFSET: 'EXECUTIVE_HAS_ADDRESS_OFFSET',
  EXECUTIVE_HAS_ON_OFF_DESCRIPTIONS: 'EXECUTIVE_HAS_ON_OFF_DESCRIPTIONS',
  EXECUTIVE_HAS_DEVICE_FAILURE_FEATURE: 'EXECUTIVE_HAS_DEVICE_FAILURE_FEATURE',
  EXECUTIVE_HAS_TWO_REGIONS_FIRE_FEATURE: 'EXECUTIVE_HAS_TWO_REGIONS_FIRE_FEATURE',
  RELAY_HAS_TRIPLE_BUILTIN_LOGIC_FEATURE: 'RELAY_HAS_TRIPLE_BUILTIN_LOGIC_FEATURE',
  EXECUTIVE_HAS_VIRTUAL_TYPE: 'EXECUTIVE_HAS_VIRTUAL_TYPE',
  EXECUTIVE_HAS_DIRECTION_NUMBER: 'EXECUTIVE_HAS_DIRECTION_NUMBER',
  EXECUTIVE_HAS_REGION: 'EXECUTIVE_HAS_REGION',
  EXECUTIVE_HAS_VIRTUAL_TYPE_CONFIG_PROPERTY: 'EXECUTIVE_HAS_VIRTUAL_TYPE_CONFIG_PROPERTY',
  DEVICE_HAS_ADDITIONAL_FIRST_INTERNAL_RELAY_TYPE: 'DEVICE_HAS_ADDITIONAL_FIRST_INTERNAL_RELAY_TYPE',
  DEVICE_HAS_ADDITIONAL_SECOND_INTERNAL_RELAY_TYPE: 'DEVICE_HAS_ADDITIONAL_SECOND_INTERNAL_RELAY_TYPE',
  DEVICE_HAS_ADDITIONAL_THIRD_INTERNAL_RELAY_TYPE: 'DEVICE_HAS_ADDITIONAL_THIRD_INTERNAL_RELAY_TYPE',
  DEVICE_HAS_ADDITIONAL_INTERNAL_OUTPUT_TYPE: 'DEVICE_HAS_ADDITIONAL_INTERNAL_OUTPUT_TYPE',
  DEVICE_HAS_ADDITIONAL_FIRST_OUTPUT_PMU_TYPE: 'DEVICE_HAS_ADDITIONAL_FIRST_OUTPUT_PMU_TYPE',
  DEVICE_HAS_ADDITIONAL_SECOND_OUTPUT_PMU_TYPE: 'DEVICE_HAS_ADDITIONAL_SECOND_OUTPUT_PMU_TYPE',
  DEVICE_HAS_ADDITIONAL_THIRD_OUTPUT_PMU_TYPE: 'DEVICE_HAS_ADDITIONAL_THIRD_OUTPUT_PMU_TYPE',
  DEVICE_HAS_ADDITIONAL_REL_OUTPUT_TYPE: 'DEVICE_HAS_ADDITIONAL_REL_OUTPUT_TYPE',
  DEVICE_BIND_ROW_INDICATOR_PANELS: 'DEVICE_BIND_ROW_INDICATOR_PANELS',
  DEVICE_HAS_MRO2M_OPT: 'DEVICE_HAS_MRO2M_OPT',
  SENSOR_HAS_VIRTUAL_TYPE: 'SENSOR_HAS_VIRTUAL_TYPE',
  SENSOR_HAS_EXT_UNDELIVERABLE_CONFIG: 'SENSOR_HAS_EXT_UNDELIVERABLE_CONFIG',
  SENSOR_HAS_FEP_BIT: 'SENSOR_HAS_FEP_BIT',
  SENSOR_HAS_FIRST_ADDRESS_BIT: 'SENSOR_HAS_FIRST_ADDRESS_BIT',
  SENSOR_HAS_ADDRESS_OFFSET: 'SENSOR_HAS_ADDRESS_OFFSET',
  SENSOR_HAS_FOUR_STATE_DESCRIPTIONS: 'SENSOR_HAS_FOUR_STATE_DESCRIPTIONS',
  SENSOR_HAS_ASSOCIATED_TIMELINE_BLOCKS: 'SENSOR_HAS_ASSOCIATED_TIMELINE_BLOCKS',
  SENSOR_ARBITRARY_DATA_HAS_REGION: 'SENSOR_ARBITRARY_DATA_HAS_REGION',
  DEVICE_HAS_ADDITIONAL_FIRE_BUTTON_TYPE: 'DEVICE_HAS_ADDITIONAL_FIRE_BUTTON_TYPE',
  DEVICE_HAS_ADDITIONAL_SECURITY_BUTTON_TYPE: 'DEVICE_HAS_ADDITIONAL_SECURITY_BUTTON_TYPE',
  DEVICE_CONFIG_HAS_CRC: 'DEVICE_CONFIG_HAS_CRC',
  DEVICE_IS_MPT: 'DEVICE_IS_MPT',
  DEVICE_HAS_EXTRA_CONFIG: 'DEVICE_HAS_EXTRA_CONFIG',
  VIRTUAL_CONTAINER_HAS_CONFIG: 'VIRTUAL_CONTAINER_HAS_CONFIG'
}
