import {
  INDICATOR_PANEL_ADD,
  INDICATOR_PANEL_SELECT,
  INDICATOR_GROUP_SELECT,
  INDICATOR_PANEL_DELETE,
  INDICATOR_GROUP_DELETE,
  INDICATOR_RESET_SELECTED,
  INDICATOR_GROUP_AND_PANEL_SELECT,
  isLoaded
} from 'constants/actionTypes';

import { savePanelIdToStorage, removePanelIdFromStorageByProjectId } from 'helpers/indicators';

export default function selectedIndicatorPanelId(state = null, { type, payload }) {
  switch (type) {
    case isLoaded(INDICATOR_PANEL_ADD, true): {
      const {
        projectId,
        newIndicatorPanel: { indicatorGroupId: groupId, id: panelId }
      } = payload;
      savePanelIdToStorage(projectId, groupId, panelId);
      return panelId;
    }
    case INDICATOR_RESET_SELECTED:
    case INDICATOR_GROUP_SELECT:
    case isLoaded(INDICATOR_GROUP_DELETE, true): {
      return null;
    }
    case isLoaded(INDICATOR_PANEL_DELETE, true): {
      const { projectId } = payload;
      removePanelIdFromStorageByProjectId(projectId);
      return null;
    }
    case INDICATOR_GROUP_AND_PANEL_SELECT: {
      const { panelId } = payload;
      return panelId;
    }
    case INDICATOR_PANEL_SELECT: {
      const selectedIndicatorPanelId = payload;
      if (!state) return selectedIndicatorPanelId;
      if (state === selectedIndicatorPanelId) return state;
      return selectedIndicatorPanelId;
    }
    default: {
      return state;
    }
  }
}
