import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { modalClose } from 'actions/modals';
import { connect } from 'react-redux';

import Modal from 'components/Modal';
import styled from 'styled-components';

const ModalContentWrapper = styled.div`
  .hidden-field {
    display: none !important;
  }
`;

class ModalRedux extends Component {
  static propTypes = {
    onKeyDown: PropTypes.func
  };

  static defaultProps = {
    onKeyDown: () => {}
  };

  render() {
    const {
      onModalClose,
      children,
      name,
      title,
      modals,
      onClose,
      onKeyDown,
      ...restProps
    } = this.props;
    return (
      <div onKeyDown={onKeyDown}>
        <Modal
          onClose={() => {
            onModalClose(name);
            if (onClose) onClose();
          }}
          title={title}
          isVisible={modals[name] || false}
          {...restProps}
        >
          <ModalContentWrapper className="ant-modal-wrapper">{children}</ModalContentWrapper>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    modals: state.modals
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onModalClose: name => {
      dispatch(modalClose(name));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalRedux);
