import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getCurrentProjectRegions } from 'helpers/currentProject';
import PlanRegionForm from './PlanRegionForm';
import { getNewRegionIdx, createRegionEditors } from 'helpers/regions';
import { getCurrentDriverAttributes } from 'helpers/drivers';
import { modalOpen, modalClose } from 'actions/modals';
import { bindActionCreators } from 'redux';
import { checkCompletion } from 'helpers/form';
import { createRegion, updateRegion } from 'actions/regions';
import { getAvailableSubsystems } from 'helpers/subsystems';
import { createSelector } from 'reselect';
import SUBSYSTEMS from 'constants/subsystems';

const PLAN_REGION_MODAL_NAME = 'planRegion';

/**
 *  Контролирует действия пользователя на плане, связанные с зонами
 */
class PlanRegionController extends React.Component {
  static propTypes = {
    regions: PropTypes.array,
    modalName: PropTypes.string,
    onSubmit: PropTypes.func
  };

  state = {
    isEditorForm: false
  };

  regionSelectorRef = React.createRef();

  regionEditors = createRegionEditors();

  openCreateRegionDialog = subsystem => {
    const { dispatch, openModal } = this.props;
    const regionEditor = this.regionEditors[subsystem];
    regionEditor.setModalValues(dispatch, { ...regionEditor.getDefaultValues(this.props) });
    openModal(regionEditor.getCreateModalName());
    this.setState({ openedCreateModal: regionEditor.getCreateModalName() });
  };

  openUpdateRegionDialog = regionId => {
    const { dispatch, openModal, regions } = this.props;
    const currentRegion = regions.find(item => item.id === regionId);
    if (!currentRegion) return;

    const subsystem = currentRegion.subsystem;
    const regionEditor = this.regionEditors[subsystem];
    regionEditor.setModalValues(dispatch, { ...currentRegion });
    this.setState({ openedUpdateModal: regionEditor.getUpdateModalName() });
    openModal(regionEditor.getUpdateModalName());
  };

  static getDerivedStateFromProps(props, state) {
    const { closeModal, modals, createRegionCompleted } = props;
    const { openedCreateModal } = state;
    if (createRegionCompleted && openedCreateModal && modals[openedCreateModal]) {
      closeModal(openedCreateModal);
      return { openedCreateModal: null };
    }
    return null;
  }

  createNewRegion = values => {
    const { currentProjectId, dispatch } = this.props;
    this.setState({ newRegionIndex: values.index });
    dispatch(createRegion(values, currentProjectId));
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { newRegionIndex, openedCreateModal } = this.state;
    if (newRegionIndex && !openedCreateModal && prevState.openedCreateModal) {
      this.regionSelectorRef.current.selectRegionByRegionIndex(newRegionIndex);
      this.setState({ newRegionIndex: '' });
    }
  }

  updateRegion = values => {
    const { currentProjectId, currentRegion, dispatch, closeModal } = this.props;
    const { openedUpdateModal } = this.state;
    if (!values) return;

    const updatedRegion = {
      updatedRegion: {
        ...currentRegion,
        ...values
      },
      projectId: currentProjectId
    };
    dispatch(updateRegion(updatedRegion));
    closeModal(openedUpdateModal);
    this.setState({ openedUpdateModal: null });
  };

  closePlanRegionModal = (needFormRegionAutomaticOpen = false) => {
    this.needFormRegionAutomaticOpen = needFormRegionAutomaticOpen;
    this.props.closeModal(PLAN_REGION_MODAL_NAME);
  };

  /**
   *  Открывает модальное окно привязки / смены зоны для выбранной фигуры на плане
   * @param isEditorForm - true, если нужна форма смены зоны. По-умолчанию форма привязки зоны
   */
  openPlanRegionModal = (isEditorForm = false) => {
    this.setState({ isEditorForm }, () => this.props.openModal(PLAN_REGION_MODAL_NAME));
    if (isEditorForm) this.regionSelectorRef.current.selectRegion(this.props.regionInfo.regionId);
    this.needFormRegionAutomaticOpen = false;
  };

  render = () => {
    const { isEditorForm } = this.state;
    const { subsystems } = this.props;
    return (
      <div>
        {subsystems.map((subsystem, idx) => {
          const regionEditor = this.regionEditors[subsystem.id];
          if (!regionEditor) return null;
          return (
            <div key={idx}>
              {regionEditor.getCreateModal(this.createNewRegion, {
                afterClose: () => {
                  if (this.needFormRegionAutomaticOpen) {
                    this.openPlanRegionModal(isEditorForm);
                  }
                }
              })}
              {regionEditor.getUpdateModal(this.updateRegion, {
                afterClose: () => {
                  if (this.needFormRegionAutomaticOpen) this.openPlanRegionModal(isEditorForm);
                }
              })}
            </div>
          );
        })}
        <PlanRegionForm
          onClose={this.props.onClose}
          modalName={PLAN_REGION_MODAL_NAME}
          isEditorForm={isEditorForm}
          {...this.props}
          ref={this.regionSelectorRef}
          onNewRegionButtonClick={() => {
            if (subsystems.length === 1) {
              this.closePlanRegionModal(true);
              this.openCreateRegionDialog(subsystems[0].id);
            }
          }}
          onEditRegionButtonClick={value => {
            this.closePlanRegionModal(true);
            this.openUpdateRegionDialog(value);
          }}
          createSubmenu={
            subsystems.length > 1
              ? subsystems.map(subsystem => {
                  return {
                    isActive: true,
                    title: subsystem.name,
                    onClick: () => {
                      this.closePlanRegionModal(true);
                      this.openCreateRegionDialog(subsystem.id);
                    }
                  };
                })
              : []
          }
        />
      </div>
    );
  };
}

const getAvailableForRegionSubsystems = createSelector([getAvailableSubsystems], subsystems =>
  subsystems.filter(
    subsystem =>
      subsystem.id === SUBSYSTEMS.FIRE.id ||
      subsystem.id === SUBSYSTEMS.SECURITY.id ||
      subsystem.id === SUBSYSTEMS.SKUD.id
  )
);

const mapStateToProps = state => ({
  subsystems: getAvailableForRegionSubsystems(state),
  regions: getCurrentProjectRegions(state),
  driverAttributes: getCurrentDriverAttributes(state),
  newRegionIdx: getNewRegionIdx(state),
  currentProjectId: state.currentProjectId,
  createRegionCompleted: checkCompletion(state.inProgress.createRegion, state.errors.createRegion),
  updateRegionCompleted: checkCompletion(state.inProgress.updateRegion, state.errors.updateRegion),
  modals: state.modals
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    openModal: bindActionCreators(modalOpen, dispatch),
    closeModal: bindActionCreators(modalClose, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  PlanRegionController
);
