import React, { Component, lazy } from 'react';
import { Route, Redirect } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import theme from 'constants/theme';
import { connect } from 'react-redux';

import CustomSuspenseContainer from './CustomSuspenseContainer';
import Header from 'containers/Header';
import LoginPage from 'containers/Pages/Login';
import ScenarioComputerAction from 'containers/Widgets/ScenariosViewer/ScenarioComputerAction';

import 'antd/dist/antd.css';
import './globalStyles.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-tree/lib/main.css';
import LocalStorageSaver from './LocalStorageSaver';
import { getCurrentDriverAttributes } from 'helpers/drivers';
import MainLoader from 'loaders/index';

const securityOn = process.env.REACT_APP_SECURITY_ON === 'true';

const OP = lazy(() => import('containers/Pages/OP/'));
const ProjectsPage = lazy(() => import('containers/Pages/Project'));
const ConfigPage = lazy(() => import('containers/Pages/Config'));
const SkudPage = lazy(() => import('./Pages/Skud/SkudPage'));
const AdminPage = lazy(() => import('containers/Pages/Admin/AdminPage'));

const LayoutWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

class Layout extends Component {
  redirectPath = window.location.pathname;

  render() {
    const { currentUser, currentUserIsLoaded, driverAttributes } = this.props;
    const skudAvailable = driverAttributes && driverAttributes.skudSubsystem;

    if (!currentUser && securityOn) {
      return (
        <ThemeProvider theme={theme}>
          <LayoutWrapper
            onContextMenu={e => {
              e.preventDefault();
            }}
          >
            <ContentWrapper id="mainContent">
              <Route path="/login" component={LoginPage} />
              <Redirect from="/" to="/login" />
            </ContentWrapper>
          </LayoutWrapper>
        </ThemeProvider>
      );
    } else {
      return (
        <ThemeProvider theme={theme}>
          {!securityOn || (securityOn && currentUserIsLoaded && currentUser) ? (
            <LayoutWrapper>
              <MainLoader />
              <LocalStorageSaver />
              <Header />
              <ContentWrapper id="mainContent">
                <CustomSuspenseContainer>
                  <Route path="/projects" component={ProjectsPage} />
                  <Route path="/op" component={OP} />
                  <Route path="/config" component={ConfigPage} />
                  {skudAvailable && <Route path="/skud" component={SkudPage} />}
                  <Route path="/admin" component={AdminPage} />
                  <Route path="/" exact render={() => <Redirect from="/*" to="/projects" />} />
                  <Route
                    path="/login"
                    exact
                    render={() => (
                      <Redirect
                        from="/*"
                        to={
                          this.redirectPath && this.redirectPath !== '/login'
                            ? this.redirectPath
                            : '/projects'
                        }
                      />
                    )}
                  />
                </CustomSuspenseContainer>
              </ContentWrapper>
              <ScenarioComputerAction />
            </LayoutWrapper>
          ) : null}
        </ThemeProvider>
      );
    }
  }
}

const checkCurrentUserIsLoaded = state => {
  return state.inProgress.requestCurrentUser === false || state.inProgress.requestLogin === false;
};

const mapStateToProps = state => {
  return {
    currentUserIsLoaded: checkCurrentUserIsLoaded(state),
    currentUser: state.currentUser.id ? state.currentUser : false,
    activeProject: state.activeProject.activeProject,
    driverAttributes: getCurrentDriverAttributes(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
