import { combineReducers } from 'redux';
import {
  WORK_SCHEDULES_TREE_ITEM_SELECT,
  isLoaded,
  getUpdateActionType,
  getAddActionType,
  getRemoveActionType
} from 'constants/actionTypes';
import { createTreeItem } from 'helpers/tree';
import { ENTITY_TYPE } from 'constants/skud';
import {
  createWorkScheduleDayIdAndTreeItem,
  createAccessGrantedTimeIdAndTreeItem
} from 'helpers/skud';

function selectedTreeItem(state = null, { type, payload }) {
  switch (type) {
    case WORK_SCHEDULES_TREE_ITEM_SELECT: {
      const selectedTreeItem = payload;
      return { ...selectedTreeItem, byUser: true };
    }

    case isLoaded(getAddActionType('workSchedules'), true): {
      const addedWorkSchedules = payload;
      const selectedTreeItem = state;
      const firstAddedWorkSchedule = addedWorkSchedules[0];
      if (selectedTreeItem && selectedTreeItem.entityType === ENTITY_TYPE.NEW_WORK_SCHEDULE)
        return createTreeItem(
          false,
          ENTITY_TYPE.WORK_SCHEDULE,
          firstAddedWorkSchedule.id,
          firstAddedWorkSchedule
        );
      else return state;
    }

    case isLoaded(getUpdateActionType('workSchedules'), true): {
      const updatedWorkSchedules = payload;
      const firstUpdatedWorkSchedule = updatedWorkSchedules[0];
      if (state) {
        const selectedTreeItem = state;
        if (
          selectedTreeItem.entityType === ENTITY_TYPE.WORK_SCHEDULE &&
          selectedTreeItem.entity.id === firstUpdatedWorkSchedule.id
        ) {
          return createTreeItem(
            false,
            ENTITY_TYPE.WORK_SCHEDULE,
            firstUpdatedWorkSchedule.id,
            firstUpdatedWorkSchedule
          );
        }
        if (
          selectedTreeItem.entityType === ENTITY_TYPE.WORK_SCHEDULE_DAY &&
          selectedTreeItem.entity.workScheduleId === firstUpdatedWorkSchedule.id
        ) {
          if (selectedTreeItem.entity.dayNo < firstUpdatedWorkSchedule.days.length)
            return {
              ...selectedTreeItem,
              entity: {
                ...selectedTreeItem.entity,
                ...firstUpdatedWorkSchedule.days[selectedTreeItem.entity.dayNo]
              }
            };
          else {
            return null;
          }
        }
        if (
          selectedTreeItem.entityType === ENTITY_TYPE.NEW_WORK_SCHEDULE_DAY &&
          selectedTreeItem.entity.workScheduleId === firstUpdatedWorkSchedule.id
        ) {
          const dayNo = firstUpdatedWorkSchedule.days.length - 1;
          const [, dayTreeItem] = createWorkScheduleDayIdAndTreeItem(
            firstUpdatedWorkSchedule.id,
            dayNo,
            firstUpdatedWorkSchedule.days[dayNo]
          );
          return dayTreeItem;
        }
        if (
          selectedTreeItem.entityType === ENTITY_TYPE.ACCESS_GRANTED_TIME &&
          selectedTreeItem.entity.workScheduleId === firstUpdatedWorkSchedule.id &&
          selectedTreeItem.entity.dayNo < firstUpdatedWorkSchedule.days.length
        ) {
          const day = firstUpdatedWorkSchedule.days[selectedTreeItem.entity.dayNo];
          if (day.accessGrantedTimes.length) {
            if (selectedTreeItem.entity.timeNo < day.accessGrantedTimes.length) {
              return {
                ...selectedTreeItem,
                entity: {
                  ...selectedTreeItem.entity,
                  ...day.accessGrantedTimes[selectedTreeItem.entity.timeNo]
                }
              };
            } else {
              return null;
            }
          } else {
            return null;
          }
        }
        if (
          selectedTreeItem.entityType === ENTITY_TYPE.NEW_ACCESS_GRANTED_TIME &&
          selectedTreeItem.entity.workScheduleId === firstUpdatedWorkSchedule.id &&
          selectedTreeItem.entity.dayNo < firstUpdatedWorkSchedule.days.length
        ) {
          const dayNo = selectedTreeItem.entity.dayNo;
          const day = firstUpdatedWorkSchedule.days[dayNo];
          const [dayId] = createWorkScheduleDayIdAndTreeItem(
            firstUpdatedWorkSchedule.id,
            dayNo,
            day
          );
          const timeNo = day.accessGrantedTimes.length - 1;
          const accessGrantedTime = day.accessGrantedTimes[timeNo];
          const [, timeTreeItem] = createAccessGrantedTimeIdAndTreeItem(
            firstUpdatedWorkSchedule.id,
            dayNo,
            dayId,
            timeNo,
            accessGrantedTime
          );
          return timeTreeItem;
        }
      }
      return state;
    }

    case isLoaded(getRemoveActionType('workSchedules'), true): {
      const removedWorkSchedules = payload;
      const selectedTreeItem = state;
      if (
        selectedTreeItem &&
        selectedTreeItem.entityType === ENTITY_TYPE.WORK_SCHEDULE &&
        removedWorkSchedules.find(workSchedule => workSchedule.id === selectedTreeItem.entity.id)
      ) {
        return null;
      } else {
        return state;
      }
    }

    default:
      return state;
  }
}

export default combineReducers({
  selectedTreeItem
});
